import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/it";
import Utils from "../../Utils";
import DocumentType from "../../enums/DocumentType";
import firebase from "firebase/compat/app";

import MyTable from "../../components/admin/MyTable";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer";
import DateUtils from "../../services/DateUtils";
import { Table } from "bloomer/lib/elements/Table";

var moment = require("moment");

export default class DContactReport extends Component {
  state = {
    contactDocs: [],
    startDate: moment(new Date()).add(-365, "days"),
    endDate: moment(new Date()),
    totPezzi: 0,
    totAcquisto: 0,
    totVendita: 0,
    contactReports: [],
    filteredDocs: [],
    filteredReports: [],
    searchValue: "",
    searchValue2: "",
    searchFields: ["coupon_code", "brand"]
  };

  componentWillReceiveProps(p) {
    console.log("aa props", p.docs);
    this.setState({ contactDocs: p.docs, contactReports: p.reports }, () => {
      this.applyFilters();
    });
  }

  componentDidMount() {
    this.setState(
      { contactDocs: this.props.docs, contactReports: this.props.reports },
      () => {
        this.applyFilters();
      }
    );
  }

  async onDatesChange(startDate, endDate) {
    console.log("onDateChange");
    this.setState({ startDate, endDate, calculating: true });

    if (!startDate || !endDate) return;

    startDate = startDate.utcOffset(0);
    endDate = endDate.utcOffset(0);
    endDate.set({ hour: 24, minute: 0, second: 0, millisecond: 0 });
    startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    this.setState({ startDate, endDate }, () => this.applyFilters());
  }

  async applyFilters() {
    const {
      startDate,
      endDate,
      contactReports,
      searchValue,
      searchValue2
    } = this.state;

    const { contactDocs } = this.state;
    console.log("AA docs before", contactDocs);
    if (!contactDocs) return;
    let totPezzi = 0;
    let totAcquisto = 0;
    let totVendita = 0;

    const filteredDocs = contactDocs
      .filter(
        d =>
          !!d.date &&
          d.date.seconds >= startDate.valueOf() / 1000 &&
          d.date.seconds <= endDate.valueOf() / 1000
      )
      .filter(d => d.type === DocumentType.DISTINTA);
    console.log("AA filteredDocs", filteredDocs);

    filteredDocs.forEach(d => {
      let totalPriceSell = 0;
      let totalPriceBuy = 0;
      d.products.forEach(p => {
        const price_sell = p.price_sell || 0;
        totalPriceSell += p.quantity * price_sell;
        const price_buy = p.price_buy || 0;
        totalPriceBuy += p.quantity * price_buy;
      });

      d.totalPriceSell = totalPriceSell;
      d.totalPriceBuy = totalPriceBuy;

      totPezzi += d.numeroPezzi;
      totAcquisto += d.totalPriceBuy;
      totVendita += d.totalPriceSell;
    });

    const filteredReports = contactReports
      .filter(
        d =>
          !!d.purchase_date &&
          d.purchase_date.seconds >= startDate.valueOf() / 1000 &&
          d.purchase_date.seconds <= endDate.valueOf() / 1000
      )
      .filter(p => this.checkSearchFied(searchValue, p))
      .filter(p => this.checkSearchFied(searchValue2, p));
    console.log("AA filteredReports", filteredReports);

    this.setState({
      totPezzi,
      totAcquisto,
      totVendita,
      filteredDocs,
      filteredReports
    });
  }

  renderOnlineTable() {
    const columns = [
      {
        Header: "Data",
        accessor: "purchase_date",
        type: "dateTimestamp"
      },
      {
        Header: "Order",
        accessor: "order_id"
      },
      {
        Header: "Uid",
        accessor: "uid"
      },
      {
        Header: "Coupon",
        accessor: "coupon_code"
      },
      {
        Header: "Qty",
        accessor: "quantity_shipped"
      },
      {
        Header: "Fatturato",
        accessor: "order_total_amount",
        type: "currency"
      }
    ];

    return (
      <div>
        <h4
          className="title has-text-black"
          style={{ marginTop: "40px", marginBottom: "-40px" }}
        >
          ONLINE
        </h4>
        {
          <MyTable
            data={this.state.filteredReports}
            hideButtons
            columns={columns}
            {...this.props}
          />
        }
      </div>
    );
  }
  renderOfflineTable() {
    const columns = [
      {
        Header: "Data",
        accessor: "date",
        type: "dateTimestamp"
      },
      {
        Header: "Numero",
        accessor: "codeNumber"
      },
      {
        Header: "Causale",
        accessor: "causale"
      },
      {
        Header: "Tipo",
        accessor: "type"
      },
      {
        Header: "Pezzi",
        accessor: "numeroPezzi"
      },
      {
        Header: "Prezzo Vendita",
        accessor: "totalPriceSell",
        type: "currency"
      },
      {
        Header: "Fatturato",
        accessor: "totalPrice",
        type: "currency"
      }
    ];
    const data = this.state.filteredDocs.filter(d => d.causale === "Vendita");
    return (
      <div>
        <h4
          className="title has-text-black"
          style={{ marginTop: "40px", marginBottom: "-40px" }}
        >
          OFFLINE
        </h4>
        {<MyTable data={data} hideButtons columns={columns} {...this.props} />}
      </div>
    );
  }

  renderOmaggiTable() {
    const columns = [
      {
        Header: "Data",
        accessor: "date",
        type: "dateTimestamp"
      },
      {
        Header: "Numero",
        accessor: "codeNumber"
      },
      {
        Header: "Causale",
        accessor: "causale"
      },
      {
        Header: "Tipo",
        accessor: "type"
      },
      {
        Header: "Pezzi",
        accessor: "numeroPezzi"
      },
      {
        Header: "Costo Materiale",
        accessor: "totalPriceBuy",
        type: "currency"
      },
      {
        Header: "Fatturato",
        accessor: "totalPrice",
        type: "currency"
      }
    ];
    const data = this.state.filteredDocs.filter(d => d.causale === "Omaggio");
    return (
      <div>
        <h4
          className="title has-text-black"
          style={{ marginTop: "40px", marginBottom: "-40px" }}
        >
          OMAGGI
        </h4>
        {<MyTable data={data} hideButtons columns={columns} {...this.props} />}
      </div>
    );
  }

  renderTotali() {
    const { totAcquisto, filteredReports } = this.state;

    let totPezzi = 0;
    this.state.filteredDocs
      .filter(d => d.causale === "Omaggio")
      .forEach(f => {
        totPezzi += f.numeroPezzi;
      });

    let fatturatoOnline = 0;
    let ordiniOnline = 0;
    let pezziOnline = 0;
    filteredReports.forEach(f => {
      fatturatoOnline += f.order_total_amount;
      pezziOnline += f.quantity_shipped;
      ordiniOnline++;
    });

    let fatturatoOffline = 0;
    let ordiniOffline = 0;
    let pezziOffline = 0;
    this.state.filteredDocs
      .filter(d => d.causale === "Vendita")
      .forEach(f => {
        fatturatoOffline += f.totalPrice;
        pezziOffline += f.numeroPezzi;
        ordiniOffline++;
      });

    return (
      <div className="p-10">
        <h1>Totale pezzi materiale inviato: {totPezzi}</h1>
        <h1>Costo materiale inviato: {Utils.stringToCurrency(totAcquisto)}</h1>
        <h1>Num utilizzo coupon: {ordiniOnline}</h1>
        <div className="separator-line-5" />
        <Columns>
          <Column>
            <h1>Fatturato Online: {Utils.stringToCurrency(fatturatoOnline)}</h1>
            <h1>
              Fatturato Offline: {Utils.stringToCurrency(fatturatoOffline)}
            </h1>
            <h1>
              Fatturato Totale:{" "}
              {Utils.stringToCurrency(fatturatoOnline + fatturatoOffline)}
            </h1>
          </Column>
          <Column>
            <h1>Ordini Online: {ordiniOnline}</h1>
            <h1>Ordini Offline: {ordiniOffline}</h1>
            <h1>Ordini Totale: {ordiniOnline + ordiniOffline}</h1>
          </Column>
          <Column>
            <h1>Pezzi Online: {pezziOnline}</h1>
            <h1>Pezzi Offline: {pezziOffline}</h1>
            <h1>Pezzi Totale: {pezziOnline + pezziOffline}</h1>
          </Column>
        </Columns>
      </div>
    );
  }

  checkSearchFied(value, p) {
    let found = false;
    this.state.searchFields.forEach(s => {
      if (
        !found &&
        String(!!p[s] && p[s].toString().toLowerCase()).includes(
          value.toLowerCase()
        )
      )
        found = true;
    });
    return found;
  }

  searchData(e) {
    const { value } = e.target;
    this.setState({ searchValue: value }, () => this.applyFilters());
  }
  searchData2(e) {
    const { value } = e.target;
    this.setState({ searchValue2: value }, () => this.applyFilters());
  }

  renderSearch() {
    const { searchFields } = this.state;
    let searchPlaceholder = "";
    if (!!searchFields) {
      searchFields.forEach(s => {
        searchPlaceholder = searchPlaceholder + " " + s;
      });
    }
    return (
      <div className="p-10">
        <div>
          Filtro1:{" "}
          <input
            className="search-field reports-search"
            value={this.state.filterAll}
            onChange={e => this.searchData(e)}
            placeholder={searchPlaceholder}
          />
        </div>
        <div>
          Filtro2:{" "}
          <input
            className="search-field reports-search"
            value={this.state.filterAll}
            onChange={e => this.searchData2(e)}
            placeholder={searchPlaceholder}
          />
        </div>
      </div>
    );
  }

  renderRiepilogo() {
    let startMonth = moment(DateUtils.timestampToDate(this.state.startDate));
    const endMonth = moment(DateUtils.timestampToDate(this.state.endDate));
    const monthDifference = endMonth.diff(startMonth, "months", true);

    const months = [];

    for (let i = 0; i <= monthDifference; i++) {
      const monthReports = this.state.filteredReports.filter(r => {
        const m = moment(DateUtils.timestampToDate(r.purchase_date)).format(
          "MMMM YY"
        );

        return startMonth.format("MMMM YY") === m;
      });

      const monthDocs = this.state.filteredDocs.filter(r => {
        const m = moment(DateUtils.timestampToDate(r.date)).format("MMMM YY");

        return startMonth.format("MMMM YY") === m;
      });

      months.push({
        month: startMonth.format("MMMM YY"),
        offline: monthDocs,
        online: monthReports
      });
      startMonth.add(1, "M");
    }

    return (
      <div>
        <h4 className="title has-text-black" style={{ marginTop: "40px" }}>
          RIEPILOGO
        </h4>
        <div className="float-left">
          <ContactRiepilogoMese header />
        </div>
        <div className="scrollcontainer">
          {months.map(m => {
            return <ContactRiepilogoMese month={m} {...this.props} />;
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#75759047",
            height: "82px",
            padding: "17px"
          }}
        >
          <DateRangePicker
            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
            isOutsideRange={() => false}
            displayFormat="DD MMM YYYY"
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) =>
              this.onDatesChange(startDate, endDate)
            } // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            startDatePlaceholderText={"Data inizio"}
            endDatePlaceholderText={"Data fine"}
          />
          <div
            style={{
              display: "flex",
              float: "right",
              padding: "5px",
              marginTop: "-17px",
              textAlign: "right"
            }}
          >
            {this.renderSearch()}
          </div>
        </div>

        {this.renderTotali()}
        {this.renderOnlineTable()}
        {this.renderOfflineTable()}
        {this.renderOmaggiTable()}

        {this.renderRiepilogo()}
      </div>
    );
  }
}

class ContactRiepilogoMese extends React.Component {
  render() {
    const { month, header } = this.props;
    console.log("CC month", month);

    let totPezzi = 0;
    let fatturatoOnline = 0;
    let ordiniOnline = 0;
    let pezziOnline = 0;
    let fatturatoOffline = 0;
    let ordiniOffline = 0;
    let pezziOffline = 0;
    let totCostoMateriale = 0;

    if (!header) {
      month.offline
        .filter(d => d.causale === "Omaggio")
        .forEach(f => {
          totPezzi += f.numeroPezzi;
        });

      month.online.forEach(f => {
        fatturatoOnline += f.order_total_amount;
        pezziOnline += f.quantity_shipped;
        ordiniOnline++;
      });

      month.offline
        .filter(d => d.causale === "Vendita")
        .forEach(f => {
          fatturatoOffline += f.totalPrice;
          pezziOffline += f.numeroPezzi;
          ordiniOffline++;
        });

      month.offline
        .filter(d => d.causale === "Omaggio")
        .forEach(f => {
          totCostoMateriale += f.totalPriceBuy;
        });
    }
    return (
      <div className="affiliates-table d-ib">
        <Table isBordered isStriped>
          <thead>
            <tr className="title">
              <th colspan="2" className="has-text-centered">
                {header ? "MESE" : month.month}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="ta-c">
                {header ? "utilizzi coupon" : ordiniOnline}{" "}
              </td>
            </tr>
            <tr>
              <td className="ta-c">
                {header
                  ? "fatturato Online"
                  : Utils.stringToCurrency(fatturatoOnline)}{" "}
              </td>
            </tr>
            <tr>
              <td className="ta-c">
                {header
                  ? "fatturato Offline"
                  : Utils.stringToCurrency(fatturatoOffline)}{" "}
              </td>
            </tr>

            <tr>
              <td className="ta-c">
                {header
                  ? "fatturato Totale"
                  : Utils.stringToCurrency(
                      fatturatoOffline + fatturatoOnline
                    )}{" "}
              </td>
            </tr>
            <tr>
              <td className="ta-c">
                {header
                  ? "costo materiale"
                  : Utils.stringToCurrency(totCostoMateriale)}{" "}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
