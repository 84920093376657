import React, { Component } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Column, Columns, Input, Field, Button } from "bloomer";
import Utils from "../Utils";
import DateUtils from "../services/DateUtils";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/it";
import Loader from "../components/Loader";
import DReportMap from "./DReportMap";
import { parse } from "@fortawesome/fontawesome-svg-core";
import Decimal from "decimal.js";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
// import { CohortBasic } from "cohort-graph-react";
import ReactCohortGraph from "react-cohort-graph";
import { faWizardsOfTheCoast } from "@fortawesome/free-brands-svg-icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Heading,
  Progress,
  SkeletonCircle,
  SkeletonText,
  Spinner
} from "@chakra-ui/react";
var moment = require("moment");

const colors = [
  "#29B129",
  "#29ADB1",
  "#981b3b",
  "#B14129",
  "#4229B1",
  "#8E29B1",
  "#5B304F",
  "#A1D40F",
  "#D4A10F",
  "#D1551E",
  "#ffc63a",
  "#c85103",
  "#6c000f",
  "#ff6a80",
  "#ffb6c1",
  "#0d89c5",
  "#5ac9ff",
  "#5041ae",
  "#350865",
  "#9b1cc7",
  "#bdcfee",
  "#eedcbd",
  "#e8214b",
  "#a6d81a",
  "#6ed173",
  "#20b2aa",
  "#ff6666",
  "#f3294b",
  "#29B129",
  "#29ADB1",
  "#981b3b",
  "#B14129",
  "#4229B1",
  "#8E29B1",
  "#5B304F",
  "#A1D40F",
  "#D4A10F",
  "#D1551E",
  "#ffc63a",
  "#c85103",
  "#6c000f",
  "#ff6a80",
  "#ffb6c1",
  "#0d89c5",
  "#5ac9ff",
  "#5041ae",
  "#350865",
  "#9b1cc7",
  "#bdcfee",
  "#eedcbd",
  "#e8214b",
  "#a6d81a",
  "#6ed173",
  "#20b2aa",
  "#ff6666",
  "#f3294b"
];
export default class DReportGrafici extends Component {
  state = {
    loading: false,
    amazonToWoo: 0,
    amazonToEbay: 0,
    ebayToAmazon: 0,
    ebayToWoo: 0,
    wooToAmazon: 0,
    wooToEbay: 0,
    retentionDays: 15
  };

  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    console.log("AA grafici stats", this.state.stats);

    this.setState({
      ...this.state.stats,
      loading: true,
      timer: setTimeout(async () => {
        this.loadData(this.props);
      }, 1500)
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state.stats,
      loading: true,
      timer: setTimeout(async () => {
        this.loadData(this.props);
      }, 1500)
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  loadData(p) {
    const { data, clientiTotaliAggregate, clienti, lifeTimeValue } = p;
    if (!data) {
      return this.setState({ loading: false });
    }

    console.log(
      "YY clienti",
      clientiTotaliAggregate.length,
      clienti.length,
      clientiTotaliAggregate,
      clienti
    );
    let ebay = 0;
    let amazon = 0;
    let woo = 0;

    let aggregation = {};
    const country = [];

    data.forEach(d => {
      let date = DateUtils.timestampToDate(d.purchase_date);
      let month = DateUtils.timestampToDate(d.purchase_date).getMonth();
      let dates = DateUtils.timestampToDate(d.purchase_date);

      month = moment(date).format("MMMM YY");
      d.dates = moment(date)
        .startOf("month")
        .format("YYYY-MM-DD hh:mm");

      // console.log("AA f.date", f.dates);
      d.month = month;
      if (!country[d.country]) country[d.country] = 0;
      country[d.country] = country[d.country] + 1;

      if (d.piattaforma == "EBAY") ebay = ebay + 1;
      else if (d.piattaforma == "AMAZON") amazon = amazon + 1;
      else if (d.piattaforma == "WOO") woo = woo + 1;

      // Create new group
      if (!aggregation[d.month])
        aggregation[d.month] = {
          dates: d.dates,
          fatturato: 0,
          fatturato_medio: 0,
          fatturato_cliente: 0,
          num_ordini: 0,
          clientiUnici: [],
          quantity: 0,
          quantityCliente: 0,
          clienti: [],
          prodotti: []
        };

      let clientiUnici = aggregation[d.month].clientiUnici;
      // console.log("AA clienti before", email);

      clientiUnici.push(d.codCliente);
      // console.log("AA clienti push", clientiUnici);

      let clienti = aggregation[d.month].clienti;
      if (!clienti[d.codCliente]) {
        clienti[d.codCliente] = {
          num_ordini: 0
        };
      }
      const val = clientiTotaliAggregate[d.codCliente];
      const ordini_storico = !!val ? val.num_ordini : 0;
      const ordini = clienti[d.codCliente].ordini || [];
      ordini.push({
        ordine: d.uid,
        order_id: d.order_id,
        date: d.purchase_date
      });
      const num_ordini = [...new Set(ordini.map(o => o.order_id))].length;

      clienti[d.codCliente] = {
        num_ordini: num_ordini,
        ordini: ordini,
        ordini_storico
      };

      let prodotti = aggregation[d.month].prodotti;
      if (!prodotti[d.cod]) {
        prodotti[d.cod] = {
          name: d.product,
          quantity: 0,
          price_medio: 0,
          fatturato: 0
        };
      }
      const quantity = prodotti[d.cod].quantity + d.quantity_shipped;
      const fatturato = new Decimal(prodotti[d.cod].fatturato)
        .plus(d.fatturato)
        .toFixed(2);
      const price_medio = parseFloat(fatturato / quantity).toFixed(2);

      // console.log("AA Fatturato medio", prodotti[d.cod], quantity, fatturato, price_medio, d.quantity_shipped, d.fatturato);
      prodotti[d.cod] = {
        name: d.product,
        quantity,
        fatturato,
        price_medio
      };

      const ordiniAggregation = aggregation[d.month].ordini || [];
      ordiniAggregation.push({
        ordine: d.uid,
        order_id: d.order_id,
        date: d.purchase_date
      });
      const num_ordini_aggr = [
        ...new Set(ordiniAggregation.map(o => o.order_id))
      ].length;

      // Append to group
      aggregation[d.month] = {
        dates: d.dates,
        fatturato: aggregation[d.month].fatturato + d.fatturato,
        ordini: ordiniAggregation,
        fatturato_medio:
          (aggregation[d.month].fatturato + d.fatturato) / num_ordini_aggr,
        num_ordini: num_ordini_aggr,
        clientiUnici: clientiUnici,
        quantity: aggregation[d.month].quantity + d.quantity_shipped,
        clienti,
        prodotti
      };
    });
    console.log("AA aggregations", aggregation);
    console.log("AA country", country);

    const fatturatoData = [];
    const fatturatoMedioData = [];
    const fatturatoMedioCliente = [];
    const clientiData = [];
    const pezziMedioData = [];
    const recurrentData = [];
    const recurrentDataAbsolute = [];
    const lifeTimeData = [];
    const distribuzioneOrdiniClienteObject = [];
    const distribuzioneOrdiniCliente = [];
    const distribuzioneCrossPlatformObject = [];
    const distribuzioneCrossPlatform = [];
    const crossingDiPiattaforma = [];
    const countryData = [];
    const countryLabels = [];
    const countryBackgroundColors = [];
    const nuoviClienti = [];

    Object.keys(aggregation).forEach(function(month) {
      const a = aggregation[month];
      const clientiUnici = [...new Set(a.clientiUnici)];

      const fatturato_cliente = a.fatturato / clientiUnici.length;
      const quantityCliente = a.quantity / clientiUnici.length;
      let recurrent = 0;

      const mediaPesata = [];
      let length = 0;

      Object.keys(a.clienti).forEach(function(codCliente) {
        const c = a.clienti[codCliente];
        if (c.num_ordini != c.ordini_storico) recurrent = recurrent + 1;

        //AA lifeTimeValue on all order history -> take from props
        // if (!mediaPesata[c.num_ordini]) mediaPesata[c.num_ordini] = { num_ordini: c.num_ordini, total: 0 };
        // mediaPesata[c.num_ordini] = { num_ordini: c.num_ordini, total: mediaPesata[c.num_ordini].total + 1 };

        length = length + 1;
      });

      //AA lifeTimeValue on all order history -> take from props
      // let lifeTimeValue = 0;
      // mediaPesata.forEach(m => {
      //   lifeTimeValue = lifeTimeValue + m.num_ordini * m.total;
      // });

      // lifeTimeValue = lifeTimeValue / length;

      fatturatoData.push({ x: a.dates, y: a.fatturato.toFixed(2) });
      fatturatoMedioData.push({ x: a.dates, y: a.fatturato_medio.toFixed(2) });
      fatturatoMedioCliente.push({
        x: a.dates,
        y: fatturato_cliente.toFixed(2)
      });
      clientiData.push({ x: a.dates, y: clientiUnici.length });
      pezziMedioData.push({ x: a.dates, y: quantityCliente });
      recurrentData.push({
        x: a.dates,
        y: ((recurrent / clientiUnici.length) * 100).toFixed(2)
      });
      recurrentDataAbsolute.push({ x: a.dates, y: recurrent });
      nuoviClienti.push({ x: a.dates, y: clientiUnici.length - recurrent });
      lifeTimeData.push({
        x: a.dates,
        y: ((a.fatturato / clientiUnici.length) * lifeTimeValue).toFixed(2)
      });
    });

    const retention = [];
    const retentionCohort = [];
    this.props.clienti.forEach(c => {
      if (c.piattaforme.length > 1) {
        this.calculateChangePlatform(c);
      }

      if (!distribuzioneOrdiniClienteObject[c.num_ordini])
        distribuzioneOrdiniClienteObject[c.num_ordini] = {
          num_ordini: c.num_ordini,
          clienti: 0
        };
      distribuzioneOrdiniClienteObject[c.num_ordini] = {
        num_ordini: c.num_ordini,
        clienti: distribuzioneOrdiniClienteObject[c.num_ordini].clienti + 1
      };

      if (!distribuzioneCrossPlatformObject[c.num_piattaforme])
        distribuzioneCrossPlatformObject[c.num_piattaforme] = {
          num_piattaforme: c.num_piattaforme,
          clienti: 0
        };
      distribuzioneCrossPlatformObject[c.num_piattaforme] = {
        num_piattaforme: c.num_piattaforme,
        clienti: distribuzioneCrossPlatformObject[c.num_piattaforme].clienti + 1
      };

      if (!retention[0]) retention[0] = { day: 0, value: 0 };
      retention[0] = { day: 0, value: retention[0].value + 1 };

      const firstMonth = moment(
        DateUtils.timestampToDate(c.ordini[0].date)
      ).format("MMMM YY");
      if (!retentionCohort[firstMonth]) retentionCohort[firstMonth] = {};
      let clienti = retentionCohort[firstMonth].clientiUnici || [];
      clienti.push(c);
      const clientiUnici = [...new Set(clienti)];

      retentionCohort[firstMonth] = { clientiUnici };

      //retention
      if (c.ordini.length > 1) {
        let index = 0;
        c.ordini.forEach(o => {
          const month = moment(DateUtils.timestampToDate(o.date)).format(
            "MMMM YY"
          );
          // console.log("BB date month", c.buyer_name, month);

          if (!retentionCohort[month]) retentionCohort[month] = {};
          let clienti = retentionCohort[month].clientiUnici || [];

          c.ordini_storico_details = !!clientiTotaliAggregate[c.codCliente]
            ? clientiTotaliAggregate[c.codCliente].ordini
            : c.ordini;
          if (c.ordini_storico > c.num_ordini) {
            //cliente returning
            console.log("YY cliente returning", c);
          }
          clienti.push(c);
          const clientiUnici = [...new Set(clienti)];

          retentionCohort[month] = { clientiUnici };

          // console.log("AA date order", DateUtils.timestampToDate(o.date));
          const orderDate = moment(DateUtils.timestampToDate(o.date));
          if (index > 0) {
            const thisDate = moment(
              DateUtils.timestampToDate(c.ordini[index - 1].date)
            );
            const nextDate = thisDate
              .clone()
              .add(this.state.retentionDays, "days");
            // console.log("AA date this", thisDate);
            // console.log("AA date nexr", nextDate);

            // console.log("AA date differenza", nextDate.diff(orderDate, "days"));
            if (nextDate >= orderDate) {
              // console.log("AA date OKKK", c);
              if (!retention[index])
                retention[index] = { day: index, value: 0 };
              const clienti = retention[index].clienti || [];
              clienti.push(c);
              retention[index] = {
                day: index,
                value: retention[index].value + 1,
                clienti
              };
            }
          }
          index++;
        });
      }
    });

    console.log("aa retention", retention);
    console.log("bb retentionCohort", retentionCohort);
    console.log(
      "AA crossing",
      this.state.wooToAmazon,
      this.state.wooToEbay,
      this.state.amazonToEbay,
      this.state.amazonToWoo,
      this.state.ebayToWoo,
      this.state.ebayToAmazon
    );
    crossingDiPiattaforma.push({ x: "WOO->AMAZON", y: this.state.wooToAmazon });
    crossingDiPiattaforma.push({ x: "WOO->EBAY", y: this.state.wooToEbay });
    crossingDiPiattaforma.push({
      x: "AMAZON->EBAY",
      y: this.state.amazonToEbay
    });
    crossingDiPiattaforma.push({ x: "AMAZON->WOO", y: this.state.amazonToWoo });
    crossingDiPiattaforma.push({ x: "EBAY->WOO", y: this.state.ebayToWoo });
    crossingDiPiattaforma.push({
      x: "EBAY->AMAZON",
      y: this.state.ebayToAmazon
    });

    Object.keys(distribuzioneOrdiniClienteObject).forEach(function(num_ordini) {
      const d = distribuzioneOrdiniClienteObject[num_ordini];
      distribuzioneOrdiniCliente.push({ x: d.num_ordini, y: d.clienti });
    });

    Object.keys(distribuzioneCrossPlatformObject).forEach(function(
      num_piattaforme
    ) {
      const d = distribuzioneCrossPlatformObject[num_piattaforme];
      distribuzioneCrossPlatform.push({ x: d.num_piattaforme, y: d.clienti });
    });
    console.log(
      "AA distribuzioneOrdiniCliente",
      distribuzioneOrdiniCliente,
      distribuzioneOrdiniClienteObject
    );

    let i = 0;
    Object.keys(country).forEach(function(c) {
      countryData.push(country[c]);
      countryLabels.push(c);
      countryBackgroundColors.push(colors[i]);
      i++;
    });

    this.setState({
      aggregation,
      nuoviClienti,
      countryData,
      countryLabels,
      countryBackgroundColors,
      crossingDiPiattaforma,
      distribuzioneCrossPlatform,
      distribuzioneOrdiniCliente,
      lifeTimeData,
      recurrentData,
      recurrentDataAbsolute,
      fatturatoData,
      fatturatoMedioData,
      fatturatoMedioCliente,
      clientiData,
      pezziMedioData,
      amazon,
      ebay,
      woo,
      retention,
      retentionCohort,
      loading: false
    });
  }

  calculateChangePlatform(cliente) {
    let {
      amazonToWoo,
      amazonToEbay,
      ebayToAmazon,
      ebayToWoo,
      wooToAmazon,
      wooToEbay
    } = this.state;

    let index = 0;
    cliente.piattaforme.forEach(p => {
      if (index > 0) {
        if (p == "AMAZON" && cliente.piattaforme[index - 1] == "EBAY")
          ebayToAmazon++;
        else if (p == "WOO" && cliente.piattaforme[index - 1] == "EBAY")
          ebayToWoo++;
        else if (p == "WOO" && cliente.piattaforme[index - 1] == "AMAZON")
          amazonToWoo++;
        else if (p == "EBAY" && cliente.piattaforme[index - 1] == "AMAZON")
          amazonToEbay++;
        else if (p == "EBAY" && cliente.piattaforme[index - 1] == "WOO")
          wooToEbay++;
        else if (p == "AMAZON" && cliente.piattaforme[index - 1] == "WOO")
          wooToAmazon++;
      }

      index++;
    });

    this.setState({
      amazonToWoo,
      amazonToEbay,
      ebayToAmazon,
      ebayToAmazon,
      ebayToWoo,
      wooToAmazon,
      wooToEbay
    });
  }

  changeRetentionDays(d) {
    this.setState({ retentionDays: d }, () => this.loadData(this.props));
  }

  renderSkeletonText(v, t) {
    return (
      <>
        <Box my={5}>
          <Heading size="sm" w="fit-content" textAlign={"center"} m={"auto"}>
            {t}
          </Heading>

          <Progress value={v} />
        </Box>
        <Box padding="6" boxShadow="lg" bg="white">
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        </Box>
      </>
    );
  }

  render() {
    // console.log("AA dataForMonth", this.state.stats.data);
    if (this.state.loading) return this.renderSkeletonText(50, "Loading data");
    return (
      <div>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md">Fatturato</Heading> <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <MLineGraph
                label="Fatturato"
                data={this.state.fatturatoData}
                backgroundColor="#981b3b"
                currency
              />
              <Flex w={"50%"}>
                <MLineGraph
                  label="FatturatoMedioOrdine"
                  data={this.state.fatturatoMedioData}
                  backgroundColor="#181b33"
                  currency
                />
                <MLineGraph
                  label="FatturatoMedioCliente"
                  data={this.state.fatturatoMedioCliente}
                  backgroundColor="#381b33"
                  currency
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md">Clienti - New e Recurrent</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Flex w={"50%"}>
                <MLineGraph
                  label="Clienti"
                  data={this.state.clientiData}
                  backgroundColor="#24753a"
                />

                <MLineGraph
                  label="Pezzi Medi"
                  data={this.state.pezziMedioData}
                  backgroundColor="#cfcf42"
                />
              </Flex>
              <Flex w={"50%"}>
                <MLineGraph
                  label="% Recurrent"
                  data={this.state.recurrentData}
                  backgroundColor="#cfcf42"
                />

                <MLineGraph
                  label="Recurrent assoluti"
                  data={this.state.recurrentDataAbsolute}
                  backgroundColor="#cfcf42"
                />
                <MLineGraph
                  label="Nuovi Clienti"
                  data={this.state.nuoviClienti}
                  backgroundColor="#cfcf42"
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md">Life Time Value e Distribuzione</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <>
                <Flex w={"50%"}>
                  <MLineGraph
                    label="LifeTime Value"
                    data={this.state.lifeTimeData}
                    backgroundColor="#cfcf42"
                    currency
                  />
                  <MLineGraph
                    label="Distribuzione acquisti per clienti"
                    data={this.state.distribuzioneOrdiniCliente}
                    backgroundColor="#cfcf42"
                    bar
                    noTimes
                    showLabels
                    xText={"num ordini"}
                    yText={"num clienti"}
                  />
                </Flex>
                <Flex w={"50%"}>
                  <MLineGraph
                    label="Distribuzione acquisti crossplatform"
                    data={this.state.distribuzioneCrossPlatform}
                    backgroundColor="#cfcf42"
                    bar
                    noTimes
                    showLabels
                    xText={"num piattaforme"}
                    yText={"num clienti"}
                  />
                  <MLineGraph
                    label="Distribuzione crossing  di piattaforma"
                    data={this.state.crossingDiPiattaforma}
                    backgroundColor="#cfcf42"
                    bar
                    noTimes
                    showLabels
                    xText={"piattaforme"}
                    yText={"num clienti"}
                  />
                </Flex>
              </>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md">Retention</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <MRetentionGraph
                data={this.state.retention}
                days={this.state.retentionDays}
                onDaysChange={d => this.changeRetentionDays(d)}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md">Cohorti</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <MRetentionCohortGraph
                retentionCohort={this.state.retentionCohort}
                {...this.props}
              />
              <MRetentionCohortGraph
                retentionCohort={this.state.retentionCohort}
                one_month
                {...this.props}
              />
              <MRetentionCohortGraph
                retentionCohort={this.state.retentionCohort}
                one_month
                all_time
                {...this.props}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md">Prodotti</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <MLineGraphProdotti
                xText={""}
                yText={"Quantità"}
                datasets={this.state.aggregation}
                qty
              />
              <MLineGraphProdotti
                xText={""}
                yText={"Variazione prezzo medio"}
                datasets={this.state.aggregation}
                currency
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md">Piattaforme e Nazioni</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Flex w={"50%"}>
                <MPieGraph
                  label="Piattaforme"
                  data={[this.state.amazon, this.state.ebay, this.state.woo]}
                  labels={["Amazon", "Ebay", "SITI"]}
                  backgroundColor={["red", "blue", "yellow"]}
                />
                <MPieGraph
                  label="Nazioni"
                  data={this.state.countryData}
                  labels={this.state.countryLabels}
                  backgroundColor={this.state.countryBackgroundColors}
                />
              </Flex>

              <DReportMap {...this.props} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
}

class MRetentionGraph extends React.Component {
  state = {
    dataset: []
  };
  componentDidMount() {
    this.setDataset(this.props.data);
  }
  componentWillReceiveProps(p) {
    this.setDataset(p.data);
  }

  changeDisplay() {
    this.setState({ percent: !this.state.percent }, () =>
      this.setDataset(this.props.data)
    );
  }
  setDataset(data) {
    if (!data) return;

    const { percent } = this.state || this.props;
    const dataset = [];

    let index = 0;
    let normValue = 1;
    Object.keys(data).forEach(function(day) {
      const d = data[day];
      if (index == 0) normValue = d.value;
      const v = percent ? ((d.value / normValue) * 100).toFixed(2) : d.value;
      const startDay = d.day * 15;
      const endDay = (d.day + 1) * 15;
      dataset.push({ x: "day" + startDay + "-" + endDay, y: v });
      index++;
    });

    this.setState({ dataset });
    console.log("AA ret dataset", dataset);
  }

  changeRetentionDays() {}
  renderDropDown() {
    return (
      <Dropdown isActive={this.state.retentionDaysDropdownActive}>
        <DropdownTrigger>
          <Field>
            <Input
              className={"blocked"}
              name="days"
              autoComplete="off"
              placeholder=""
              value={this.state.retentionDays}
            />
          </Field>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownContent className="elements-dropdown">
            <DropdownItem
              key={15}
              className="ta-l"
              onClick={() => this.changeRetentionDays}
            >
              15
            </DropdownItem>
            );
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
    );
  }
  render() {
    return (
      <div>
        {/* {this.renderDropDown()} */}
        <Button
          onClick={() => this.changeDisplay()}
          className="is-primary float-right"
        >
          {this.state.percent ? "unità" : "percentuale"}
        </Button>
        <MLineGraph
          label="Retention"
          data={this.state.dataset}
          backgroundColor="#cfcf42"
          noTimes
          showLabels
          xText={"giorno"}
          yText={this.state.percent ? "percentuale" : "unità"}
        />
      </div>
    );
  }
}

export class MRetentionCohortGraph extends React.Component {
  state = {
    dataCohort: [],
    percent: true,
    loading: true
  };
  componentDidMount() {
    console.log("WW componentDidMount", this.props?.percent);
    this.setState({ percent: this.props?.percent });

    this.setDataset(this.props);
  }
  componentWillReceiveProps(p) {
    console.log("WW rendering componentWillReceiveProps", p?.percent);
    this.setState({ percent: p?.percent });
    this.setDataset(p);
  }

  changeDisplay() {
    this.setState({ percent: !this.state.percent }, () =>
      this.setDataset(this.props)
    );
  }
  async setDataset(p) {
    this.setState({
      loading: true,
      dataCohort: [],
      timer: setTimeout(async () => {
        this.loadData(p);
      }, 1500)
    });
  }

  loadData(p) {
    console.log("ww cohort setDataset", p);

    const data = p?.retentionCohort;
    const dataFull = p?.retentionCohortFull;
    if (!data) return;

    let startMonth = moment(DateUtils.timestampToDate(p.startDate));
    const endMonth = moment(DateUtils.timestampToDate(p.endDate));
    const monthDifference = endMonth.diff(startMonth, "months", true);
    const months = {};

    console.log(
      "ww cohort",
      p.selectedYear,
      data,
      p.startDate,
      p.endDate,
      monthDifference
    );

    const mo = p.one_month ? 1 : 3; //trimestrale

    for (let i = 0; i <= monthDifference / mo; i++) {
      const m = startMonth.clone();
      let clientiFirstMonth = !!data[m.format("MMMM YY")]
        ? [...data[m.format("MMMM YY")].clientiUnici]
        : [];
      console.log("vv clientiFirstMonth", clientiFirstMonth);

      //prendi solo i clienti nuovi per quel mese
      clientiFirstMonth = clientiFirstMonth.filter(c => {
        if (!c.ordini_storico_details)
          c.ordini_storico_details = c.ordiniReali || c.ordini;
        const ordersOld = c.ordini_storico_details.filter(o => {
          const dateOrder = moment(DateUtils.timestampToDate(o.date));
          return dateOrder.diff(startMonth, "months") < 0;
        });
        // console.log("vv ordersOld", startMonth, ordersOld);

        return ordersOld.length === 0;
      });

      const m1 = m.format("MMMM YY");
      console.log("CC clientiFirstMonth m", m1);
      console.log(
        "CC clientiFirstMonth m",
        m1,
        !!data[m1] ? data[m1].clientiUnici.length : 0
      );

      if (p.filterFirstMonth)  clientiFirstMonth = p.filterFirstMonth(clientiFirstMonth, startMonth);
      console.log("CC clientiFirstMonth m", m , clientiFirstMonth.length);

      const m2 = m.add(1, "M").format("MMMM YY");
      const m3 = m.add(1, "M").format("MMMM YY"); 
      let clienti2 = !!data[m2] ? [...data[m2].clientiUnici] : [];
      if (p.filterFirstMonth)    clienti2 = p.filterFirstMonth(clienti2, startMonth.clone().add(1, "M"));
       console.log("CC clientiFirstMonth m2", m2, clienti2.length);

      if (!p.one_month) clientiFirstMonth.push(...clienti2);
      let clienti3 = !!data[m3] ? [...data[m3].clientiUnici] : [];
      if (p.filterFirstMonth)    clienti3 = p.filterFirstMonth(clienti3, startMonth.clone().add(2, "M"));
      console.log("CC clientiFirstMonth m3", m3, clienti3.length);

      if (!p.one_month) clientiFirstMonth.push(...clienti3);

      clientiFirstMonth = [...new Set(clientiFirstMonth)];
      console.log("CC clientiFirstMonth before", clientiFirstMonth);
     
      console.log("CC clientiFirstMonth after", clientiFirstMonth);

      this.populateData(
        startMonth.clone(),
        endMonth,
        data,
        mo,
        clientiFirstMonth,
        p
      );

      let recurrentOnAllPeriod = 0;
      clientiFirstMonth.forEach(c => {
        const ordersNew = c.ordini.filter(o => {
          const dateOrder = moment(DateUtils.timestampToDate(o.date));
          // console.log("TT order dateorder", dateOrder.format("MMMM YY"), startMonth.format("MMMM YY"), dateOrder.diff(startMonth, "months"));
          // if (dateOrder.diff(startMonth, "months") > 0) console.log("ORDINE VECCHIO->NUOVO", startMonth.format("MMMM YY"), dateOrder.format("MMMM YY"));

          return dateOrder.diff(startMonth, "months") > 0;
        });
        // console.log("TT orders", c.ordini, m, moment(DateUtils.timestampToDate(c.ordini[0].date)).format("MMMM YY"), ordersNew, ordersOld);
        if (ordersNew.length > 0) recurrentOnAllPeriod++;
      });
      console.log("TT recurrentOnAllPeriod", mo, m1, recurrentOnAllPeriod);

      const mm = startMonth.format("MMMM YY");
      const d = this.state.dataCohort[i] || [];
      console.log("CC d", d,mm);

      const monthArray = [];
      if (this.props.all_time) {
        monthArray.push(clientiFirstMonth.length - 1);
        monthArray.push(recurrentOnAllPeriod);
      } else {
        d.forEach(dd => {
          monthArray.push(dd.clienti);
        });
      }

      months[mm.toString().replace(" ", "-")] = monthArray;
      startMonth.add(mo, "M");
    }

    console.log("CC cohort", this.state.dataCohort);
    console.log("CC months", months);
    this.setState({ months, loading: false });
  }

  populateData(startMonth, endMonth, data, mo, clientiFirstMonth, p) {
    const { dataCohort } = this.state;

    const monthDifference = endMonth.diff(startMonth, "months", true);
    const array = [];
    const m = startMonth.format("MMMM YY");
    console.log("CC nexrt month", startMonth.format("MMMM YY"), monthDifference, data, data[m]);
    console.log("ww mesi", startMonth.format("MMMM YY") );

    for (let i = 0; i < monthDifference / mo; i++) {
      // console.log("CC current month", startMonth.format("MMMM YY"));
      const m = startMonth.format("MMMM YY");
      let clienti = !!data[m] ? [...data[m].clientiUnici] : [];

      if (!p.one_month) {
        const m2 = startMonth.clone().add(1, "M").format("MMMM YY");
        const m3 = startMonth.clone().add(2, "M").format("MMMM YY");

        let clienti2 = !!data[m2] ? [...data[m2].clientiUnici] : [];
        if (p.filterFirstMonth)    clienti2 = p.filterFirstMonth(clienti2, startMonth.clone().add(1, "M"));

        console.log("CC clienti month 2", m2, clienti2.length, startMonth.clone().add(1, "M").format("MMMM YY"));
        clienti.push(...clienti2);
        let clienti3 = !!data[m3] ? [...data[m3].clientiUnici] : [];
        if (p.filterFirstMonth)    clienti3 = p.filterFirstMonth(clienti3, startMonth.clone().add(2, "M"));

        console.log("CC clienti month 3", m3, clienti3.length,startMonth.clone().add(2, "M").format("MMMM YY"));
        clienti.push(...clienti3); 

        console.log("ww mesi", m,m2,m3 );

      }
      console.log("CC clienti tot 3 mesi", clienti);

      const clientiUnique = [...new Set(clienti)];
      clientiUnique.sort((a, b) => {
        if (a.buyer_name > b.buyer_name) return 1;
        if (a.buyer_name < b.buyer_name) return -1;
        return 0;
      });

      //Questo era il vecchio metodo per i kpi grafici
      // var filtered = clientiUnique.filter(function(e) {
      //   return this.indexOf(e) > 0;
      // }, clientiFirstMonth);

      // var filtered = clientiUnique
      //   .map(c => c.codCliente)
      //   .filter(
      //     function(e) {
      //       return this.indexOf(e) > 0;
      //     },
      //     clientiFirstMonth.map(c => c.codCliente)
      //   );

      // var filtered = clientiFirstMonth.filter(
      //   ar => !clientiUnique.find(rm => rm.codCliente === ar.codCliente)
      // );

      console.log("CC prima del inserimento", clientiFirstMonth.length, clientiUnique.length);

      var filtered = clientiUnique.filter(function(objFromA) {
        return clientiFirstMonth.find(function(objFromB) {
          return objFromA.codCliente === objFromB.codCliente;
        });
      });

      array.push({
        month: m,
        clienti: p.calculateOnField
          ? p.calculateOnField(filtered, m, p.one_month)
          : filtered.length
      });
      console.log("CC array", array);
      console.log(
        "CC utenti",
        clientiFirstMonth
          .sort((a, b) => {
            if (a.buyer_name > b.buyer_name) return 1;
            if (a.buyer_name < b.buyer_name) return -1;
            return 0;
          })
          .map(c => c.codCliente),
        // clienti,
        clientiUnique.map(c => c.codCliente),
        filtered
      );

      // console.log("CC utenti", m, filtered.length);
      startMonth.add(mo, "M");
    }
    dataCohort.push(array);
    this.setState(dataCohort);
  }

  render() {
    const { months } = this.state;

    const data = {
      months: months
    };
    console.log("WW rendering", data, months, this.state.loading);

    if (this.state.loading)
      return (
        <Center>
          <Spinner mt={10} />
        </Center>
      );
    else
      return (
        <div className="mtb-80">
          {this.props.title && <Heading size="sm">{this.props.title}</Heading>}
          {this.props.one_month ? (
            this.props.all_time ? (
              <h1 className="bold ta-c">ANALISI COHORTE SUL PERIODO</h1>
            ) : (
              <h1 className="bold ta-c">ANALISI COHORTE A 1 MESE</h1>
            )
          ) : (
            <h1 className="bold ta-c">ANALISI COHORTE A 3 MESI</h1>
          )}
          {/* <Button onClick={() => this.changeDisplay()} className="is-primary float-right">
          {this.state.percent ? "percentuale" : "clienti"}
        </Button> */}
          <ReactCohortGraph
            width={1000}
            data={data}
            defaultValueType={this.state.percent}
          />
        </div>
      );
  }
}

class MLineGraph extends React.Component {
  state = {
    stats: {
      data: {
        labels: [],
        datasets: []
      },
      optionsCurrency: {
        scales: {
          xAxes: [
            {
              title: "Periodo",
              type: "time",
              time: {
                unit: "month"
              }
            }
          ],
          yAxes: [
            {
              title: "Guadagno",
              ticks: {
                beginAtZero: true,
                userCallback: (value, index, values) =>
                  `${Number(value).toFixed(2)}€`
              }
            }
          ]
        }
      },
      options: {
        scales: {
          xAxes: [
            {
              title: "Periodo",
              type: "time",
              time: {
                unit: "month"
              }
            }
          ],
          yAxes: [
            {
              title: "Guadagno",
              ticks: {
                beginAtZero: true,
                userCallback: (value, index, values) =>
                  `${Number(value).toFixed(1)}`
              }
            }
          ]
        }
      }
    }
  };

  componentDidMount() {
    if (!this.props.data) return;
    this.setDataset(
      this.props.label,
      this.props.data,
      this.props.backgroundColor
    );
  }
  componentWillReceiveProps(p) {
    this.setDataset(p.label, p.data, p.backgroundColor);
  }
  setDataset(label, data, backgroundColor) {
    const dataset = {
      label,
      backgroundColor,
      borderColor: backgroundColor,
      lineTension: 0.2,
      fill: false,
      data: data
    };

    const { stats } = this.state;
    stats.data.datasets = [];
    stats.data.datasets.push(dataset);
    if (this.props.showLabels) {
      stats.data.labels = [];
      data.forEach(d => {
        stats.data.labels.push(d.x.toString());
      });
    }

    this.setState({ stats });
  }

  render() {
    let options = !!this.props.currency
      ? this.state.stats.optionsCurrency
      : this.state.stats.options;
    if (this.props.noTimes) options = null;
    if (this.props.xText) {
      options = {
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.props.yText
              }
            }
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.props.xText
              }
            }
          ]
        }
      };
    }

    return !!this.props.bar ? (
      <Bar data={this.state.stats.data} options={options} />
    ) : (
      <Line data={this.state.stats.data} options={options} />
    );
  }
}

class MLineGraphProdotti extends React.Component {
  state = {
    legend: true,
    stats: {
      data: {
        labels: [],
        datasets: []
      }
    }
  };

  componentDidMount() {
    if (!this.props.datasets) return;
    this.setDatasets(this.props.datasets, this.props);
  }
  componentWillReceiveProps(p) {
    this.setDatasets(this.props.datasets, this.props);
  }

  setDatasets(datasets, p) {
    const { stats } = this.state;
    stats.data.datasets = [];
    console.log("AA prodotti dataset", datasets);
    console.log("AA props", this.props);

    const prodottiArray = [];

    Object.keys(datasets).forEach(function(month) {
      const data = datasets[month];
      const prodotti = data.prodotti;

      Object.keys(prodotti).forEach(function(cod) {
        const prodotto = data.prodotti[cod];

        // console.log("AA mese prodotto", data.dates, prodotto);

        if (!prodottiArray[cod]) {
          prodottiArray[cod] = {
            cod,
            name: prodotto.name,
            date: []
          };
        }

        const date = prodottiArray[cod].date;
        const val = !!p.qty ? prodotto.quantity : prodotto.price_medio;
        date.push({ dates: data.dates, quantity: val });

        prodottiArray[cod] = {
          cod,
          name: prodotto.name,
          date
        };
      });
    });

    let i = 0;
    Object.keys(prodottiArray).forEach(function(cod) {
      const prod = prodottiArray[cod];

      const prodData = [];
      prod.date.forEach(d => {
        // console.log("AA d", d);
        prodData.push({ x: d.dates, y: d.quantity });
      });

      const color = colors[i];
      const dataset = {
        label: prod.name,
        backgroundColor: color,
        borderColor: color,
        lineTension: 0.2,
        fill: false,
        data: prodData,
        hidden: i == 0 ? false : true
      };

      // if (i < 10)
      stats.data.datasets.push(dataset);
      i++;
    });

    console.log("AA prodottiarray", prodottiArray);
    console.log("AA stats.data.datasets", stats.data.datasets);
    this.setState({ stats });
  }

  render() {
    const optionsCurrency = {
      legend: {
        display: this.state.legend,
        position: "bottom"
      },
      scales: {
        xAxes: [
          {
            title: "Periodo",
            type: "time",
            time: {
              unit: "month"
            }
          }
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.props.yText
            },
            title: "Guadagno",
            ticks: {
              beginAtZero: true,
              userCallback: (value, index, values) =>
                `${Number(value).toFixed(2)}€`
            }
          }
        ]
      }
    };
    const options = {
      legend: {
        display: this.state.legend,
        position: "bottom"
      },
      scales: {
        xAxes: [
          {
            title: "Periodo",
            type: "time",
            time: {
              unit: "month"
            }
          }
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.props.yText
            },
            title: "Guadagno",
            ticks: {
              beginAtZero: true,
              userCallback: (value, index, values) =>
                `${Number(value).toFixed(1)}`
            }
          }
        ]
      }
    };
    let optionsToRender = !!this.props.currency ? optionsCurrency : options;

    return (
      <div>
        <Button
          onClick={() => this.setState({ legend: !this.state.legend })}
          className="is-primary float-right"
        >
          {this.state.legend ? "NASCONDI LEGENDA" : "MOSTRA LEGENDA"}
        </Button>{" "}
        <Line data={this.state.stats.data} options={optionsToRender} />
      </div>
    );
  }
}

class MPieGraph extends React.Component {
  state = {
    stats: {
      data: {
        labels: [],
        datasets: []
      }
    }
  };

  render() {
    return (
      <Pie
        data={{
          labels: this.props.labels || null,
          datasets: [
            {
              data: this.props.data,
              backgroundColor: this.props.backgroundColor || null
            }
          ]
        }}
      />
    );
  }
}
