import React, { Component } from "react";
import MyTable from "../../components/admin/MyTable";
import firebase from "firebase/compat/app";

import { Button } from "bloomer/lib/elements/Button";
import { Modal } from "bloomer/lib/components/Modal/Modal";
import { ModalBackground } from "bloomer/lib/components/Modal/ModalBackground";
import { ModalCard } from "bloomer/lib/components/Modal/Card/ModalCard";
import { ModalCardHeader } from "bloomer/lib/components/Modal/Card/ModalCardHeader";
import { ModalCardTitle } from "bloomer/lib/components/Modal/Card/ModalCardTitle";
import { ModalCardBody } from "bloomer/lib/components/Modal/Card/ModalCardBody";
import { Field, Input, Columns } from "bloomer";
import { TextArea } from "bloomer/lib/elements/Form/TextArea";
import { Column } from "bloomer/lib/grid/Column";
import DateUtils from "../../services/DateUtils";
import Utils from "../../Utils";
import { Label } from "bloomer/lib/elements/Form/Label";

export default class DContactCoupons extends Component {
  state = {
    coupons: [],
    modalActive: false,
    modifyRecord: false,
    modifyRow: 0,
    sconto1: 0,
    sconto2: 0,
    commissione1: 0,
    commissione2: 0
  };

  componentDidMount() {
    this.setState({ coupons: this.props.contact.coupons || [] });
  }

  onModify(u, idx) {
    console.log("AA onModify", idx);

    this.setState({
      modifyRecord: true,
      code: u.code,
      sconto1: u.sconto1,
      sconto2: u.sconto2,
      commissione1: u.commissione1,
      commissione2: u.commissione2,
      modalActive: true,
      modifyRow: idx
    });
  }

  onDelete(u, idx) {
    console.log("AA onDelete");

    this.props.modals.addModal(
      "Confermi eliminazione?",
      <div />,
      [
        {
          label: "ESCI",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "ELIMINA",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDelete(idx);
        }
      }
    );
  }

  confirmDelete(idx) {
    const { coupons } = this.state;

    coupons.splice(idx, 1);
    this.setState({ coupons }, () => this.updateTable(coupons));

    console.log("AA onDelete confkirm", coupons);
  }

  updateTable(coupons) {
    const { contact } = this.props;

    firebase
      .firestore()
      .collection("contacts")
      .doc(contact.uid)
      .update({ coupons: coupons });
  }

  addContact() {
    const {
      coupons,
      modifyRecord,
      modifyRow,
      code,
      sconto1,
      sconto2,
      commissione1,
      commissione2
    } = this.state;

    if (modifyRecord) {
      const c = coupons[modifyRow];
      c.code = code;
      c.sconto1 = sconto1;
      c.sconto2 = sconto2;
      c.commissione1 = commissione1;
      c.commissione2 = commissione2;
    } else {
      coupons.push({ code, sconto1, sconto2, commissione1, commissione2 });
    }
    console.log("AA onDelete confkirm", coupons);

    this.setState({ coupons, modalActive: false }, () =>
      this.updateTable(coupons)
    );
  }

  handleChange(e) {
    const s = {};
    s[e.target.name] = e.target.value;
    this.setState(s);
  }

  render() {
    const { coupons } = this.state;

    const columns = [
      {
        Header: "Coupon",
        accessor: "code"
      },
      {
        Header: "Sconto",
        accessor: "sconto1"
      },
      {
        Header: "Sconto2",
        accessor: "sconto2"
      },
      {
        Header: "Commissione",
        accessor: "commissione1"
      },
      {
        Header: "Commissione2",
        accessor: "commissione2"
      }
    ];
    return (
      <div className="admin-panels">
        {
          <MyTable
            data={coupons}
            columns={columns}
            showDelete
            onDelete={(u, idx) => this.onDelete(u, idx)}
            onModify={(u, idx) => this.onModify(u, idx)}
            {...this.props}
          />
        }
        <Button
          className="is-primary mt-20"
          onClick={() =>
            this.setState({ modalActive: true, modifyRecord: false })
          }
        >
          AGGIUNGI COUPON
        </Button>

        <Modal
          className="warehouse-modal has-text-centered"
          isActive={this.state.modalActive}
        >
          <ModalBackground
            onClick={() => this.setState({ modalActive: false })}
          />
          <ModalCard>
            <ModalCardHeader className="no-pb">
              <ModalCardTitle className="has-text-centered no-pl mb-10">
                {this.state.modifyRecord ? "Modifica Coupon" : "Nuovo Coupon"}
              </ModalCardTitle>
            </ModalCardHeader>
            <ModalCardBody>
              <Label>Codice</Label>
              <Field className="mb-10">
                <Input
                  name="code"
                  placeholder=""
                  value={this.state.code}
                  type="input"
                  onChange={e => {
                    this.handleChange(e);
                  }}
                />
              </Field>
              <Columns>
                <Column>
                  <Label>Sconto1</Label>
                  <Field className="mb-10">
                    <Input
                      name="sconto1"
                      placeholder=""
                      value={this.state.sconto1}
                      type="input"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
                <Column>
                  <Label>Sconto2</Label>
                  <Field className="mb-10">
                    <Input
                      name="sconto2"
                      placeholder=""
                      value={this.state.sconto2}
                      type="input"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <Label>Commissione1</Label>
                  <Field className="mb-10">
                    <Input
                      name="commissione1"
                      placeholder=""
                      value={this.state.commissione1}
                      type="input"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
                <Column>
                  <Label>Commissione2</Label>
                  <Field className="mb-10">
                    <Input
                      name="commissione2"
                      placeholder=""
                      value={this.state.commissione2}
                      type="input"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
              </Columns>
            </ModalCardBody>
            <Columns>
              <Column>
                <Button
                  className="is-primary  "
                  onClick={() => {
                    this.setState({ modalActive: false });
                  }}
                >
                  CANCELLA
                </Button>
              </Column>
              <Column>
                <Button
                  className="is-primary  "
                  onClick={() => {
                    this.addContact();
                  }}
                >
                  Ok
                </Button>
              </Column>
            </Columns>
          </ModalCard>
        </Modal>
      </div>
    );
  }
}
