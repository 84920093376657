import React, { Component } from "react";
import { Container, Columns, Column } from "bloomer";
import { withAdminCtx } from "../../contexts/AdminContext";
import Loader from "../../components/Loader";
import MyTable from "../../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { Button } from "bloomer/lib/elements/Button";
import { withWareCtx } from "../../contexts/WarehouseContext";
import firebase from "firebase/compat/app";

import { withModalsCtx } from "../../contexts/ModalsContext";
import AdminNavbar from "../../components/admin/AdminNavbar";
import { Label } from "bloomer/lib/elements/Form/Label";
import { ExportToCsv } from "export-to-csv-file";
import Utils from "../../Utils";

class DElements extends Component {
  state = {
    loaded: false
  };

  constructor(props) {
    super(props);
    this.getElementProducts = this.getElementProducts.bind(this);
    this.renderUsedOn = this.renderUsedOn.bind(this);
  }

  componentWillReceiveProps(p) {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  }

  onFilter(filteredData) {
    this.setState({ filteredData });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.admin.elements.length != nextProps.admin.elements.length)
      return true;
    else return false;
  }

  export() {
    let data = [];
    const { elements } = this.props.admin;
    elements.forEach(o => {
      const d = {
        nome: o.name,
        giacenza: o.quantity,
        unitaMisura: o.unitmeasure,
        codice: o.code || "",
        fornitore: o.supplier
      };

      data.push(d);
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      filename: "MyLab Materie Prime",
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Materie Prime",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  onGiacenzaDetails(p) {
    this.props.history.push("elementHistory/" + p.uid);
  }

  onModify(p, id, e) {
    if (e.metaKey) {
      window.open("elementDetails/" + p.uid, "_blank");
      return;
    }
    this.props.history.push("elementDetails/" + p.uid);
  }

  getElementProducts(el) {
    const { products } = this.props.admin.products;
    if (!!products) return [];
    console.log("AA pr", products);

    let elementProducts = [];
    products.forEach(p => {
      const composition = p.composition;
      composition.forEach(c => {
        if (c.uid == el.uid) elementProducts.push(p);
      });
    });

    return elementProducts;
  }

  onDelete(p) {
    this.props.modals.addModal(
      "Confermi eliminazione " + p.name,
      this.renderUsedOn(this.getElementProducts(p)),
      [
        {
          label: "Esci",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "Elimina",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDelete(p);
        } else {
          this.props.history.goBack();
        }
      }
    );
  }

  async confirmDelete(p) {
    const productSnap = await firebase
      .firestore()
      .collection("elements")
      .doc(p.uid)
      .delete();
  }

  addElement() {
    this.props.history.push("elementAdd");
  }

  renderUsedOn(products) {
    return (
      <div className="mtb-80" style={{ background: "#fff" }}>
        <h3 className="title has-text-black ">Usato in</h3>

        {!!products &&
          products.map(s => {
            return (
              <div>
                <Label>{s.name}</Label>
              </div>
            );
          })}
      </div>
    );
  }

  render() {
    const { elements } = this.props.admin;
    if (!elements) return <Loader />;

    const columns = [
      {
        Header: "Nome",
        accessor: "name",
        style: { maxWidth: "300px" }
      },
      {
        Header: "Giacenza",
        accessor: "quantity",
        style: { textAlign: "center", maxWidth: "70px" },
        styleWarning: { color: "red", textAlign: "center", maxWidth: "50px" },
        rounded: true
      },
      {
        Header: "Unità Misura",
        accessor: "unitmeasure",
        style: { textAlign: "center", maxWidth: "70px" }
      },
      {
        Header: "Cod",
        accessor: "code"
      },
      {
        Header: "Ordine Minimo",
        accessor: "ordineMinimo"
      },
      {
        Header: "Fornitore",
        accessor: "supplier"
      }
    ];

    return (
      <section>
        <AdminNavbar />
        <Container>
          <Columns className="admin-header mt-5">
            <Column>
              <h2 className="title has-text-black ">Materie Prime e PKG</h2>
            </Column>
            <Column>
              <div className="float-right">
                {!!this.props.ware.isCreator && (
                  <Button
                    className="is-primary mt-15"
                    onClick={() => this.addElement()}
                  >
                    AGGIUNGI
                  </Button>
                )}
                <Button
                  className="is-primary ml-15 mt-15"
                  onClick={() => this.export()}
                >
                  ESPORTA
                </Button>
              </div>
            </Column>
          </Columns>
          <div className="admin-panels">
            <MyTable
              data={elements}
              columns={columns}
              onModify={(u, id, e) => this.onModify(u, id, e)}
              onGiacenzaDetails={u => this.onGiacenzaDetails(u)}
              onDelete={u => this.onDelete(u)}
              cerca={true}
              searchFields={["barcode", "name", "code"]}
              onFilter={data => this.onFilter(data)}
              {...this.props}
            />
          </div>
        </Container>
      </section>
    );
  }
}

export default withModalsCtx(withRouter(withAdminCtx(withWareCtx(DElements))));
