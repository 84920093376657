import React, { Component } from "react";
import firebase from "firebase/compat/app";

import { withRouter } from "react-router-dom";
import Utils from "../Utils";
import OrderStatus from "../enums/OrderStatus";
import DocumentType from "../enums/DocumentType";
import DocumentStatus from "../enums/DocumentStatus";
// import { threadId } from "worker_threads";

export const AdminContext = React.createContext(null);

export const AdminConsumer = AdminContext.Consumer;

export class AdminProvider extends Component {
  state = {
    revenues: {
      loading: true,
      revenues: null,
      setRevenuesState: null,
      revenuesObserver: null
    },
    documents: [],
    products: {
      loadingElements: true,
      loadingProducts: true,
      products: [],
      childProducts: [],
      childProd: [],
      elements: null,
      productsObserver: null,
      elementsObserver: null
    },
    utils: {
      renderTableLabel: null,
      renderTimeSpan: null,
      calcLength: null,
      setOrderStatus: null,
      setOrderTracking: null,
      setAffiliationState: null,
      setProductState: null,
      setRevenuesState: null
    }
  };

  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);

    this.createProductsObserver = this.createProductsObserver.bind(this);
    this.createElementsObserver = this.createElementsObserver.bind(this);
    this.createDocumentsObserver = this.createDocumentsObserver.bind(this);
    this.changeProductQuantity = this.changeProductQuantity.bind(this);
    this.changeProduzioneQuantity = this.changeProduzioneQuantity.bind(this);
  }

  async getProductPrices(p) {
    const data = await firebase
      .firestore()
      .collection("products_prices")
      .doc(p.sku)
      .get();

    if (data.exists) {
      p.prezzo_acquisto = data.data().prezzo_acquisto;
      p.prezzo_vendita_b2b = data.data().prezzo_vendita_b2b;
      p.iva = data.data()?.iva || 10;
    } else {
      p.prezzo_acquisto = 0;
      p.prezzo_vendita_b2b = 0;
    }
    return p;
  }

  async createProductsObserver() {
    console.log("ZZ create productobs");
    try {
      let productsRef = firebase.firestore().collection("products");

      const productsObserver = await productsRef.onSnapshot(async docs => {
        const products = [];
        const childProducts = [];
        const productsTable = [];
        const childProd = [];

        const promises = [];
        docs.forEach(u => {
          promises.push(
            new Promise(async (r, rj) => {
              let product = u.data();

              if (!!product.sku) product = await this.getProductPrices(product);
              products.push({
                ...product,
                combo: product.tipologia === "Combo prodotti"
              });

              productsTable[product.sku] = {
                ...product
              };

              if (!!u.data().codiciFigli) {
                u.data().codiciFigli.forEach(c => {
                  childProd.push({
                    sku: c.sku,
                    parentSku: u.data().sku,
                    name: c.name,
                    quantity: c.quantity,
                    brand: u.data().brand
                  });
                  childProducts[c.sku] = {
                    sku: c.sku,
                    parentSku: u.data().sku,
                    name: c.name,
                    quantity: c.quantity,
                    brand: u.data().brand
                  };
                });
              }
              r(product);
            })
          );
        });

        await Promise.all(promises);
        products.forEach(p => {
          if (!!p.codiciFigli) {
            const prodottiFigli = [];
            p.codiciFigli.forEach(f => {
              prodottiFigli.push({
                ...productsTable[f.sku],

                quantityInBundle: f.quantity
              });
            });

            p.prodottiPadri = products.filter(
              pp => pp.codiciFigli?.filter(f => f.sku === p.sku).length > 0
            );
            p.prodottiFigli = prodottiFigli;
          }
        });
        products.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });

        console.log("AA PRODUCTS", products);
        this.setState({
          products: {
            ...this.state.products,
            products,
            childProducts,
            productsTable,
            childProd,
            loadingProducts: false
          }
        });
      });

      this.setState({
        products: {
          ...this.state.products,
          productsObserver
        }
      });
    } catch (e) {
      console.error("error fetching products", e);
      Utils.handleError(e);
    }
  }

  async createElementsObserver() {
    try {
      let elementsRef = firebase.firestore().collection("elements");

      const elementsObserver = await elementsRef.onSnapshot(async docs => {
        const elements = [];

        docs.forEach(u => {
          elements.push(u.data());
        });

        elements.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });

        this.setState({
          elements,
          loadingElements: false
        });
      });

      this.setState({
        ...this.state.elements,
        elementsObserver
      });
    } catch (e) {
      console.error("error fetching products", e);
      Utils.handleError(e);
    }
  }

  async createIvaObserver() {
    try {
      let ivaRef = firebase.firestore().collection("iva");

      const ivaObserver = await ivaRef.onSnapshot(async values => {
        const iva = [];

        values.forEach(v => {
          iva.push(v.data());
        });

        // elements.sort((a, b) => {
        //   if (a.name > b.name) return 1;
        //   if (a.name < b.name) return -1;
        //   return 0;
        // });

        this.setState({
          iva
        });
      });

      this.setState({
        ivaObserver
      });
    } catch (e) {
      console.error("error fetching products", e);
      Utils.handleError(e);
    }
  }

  async createPackagingObserver() {
    try {
      let packRef = firebase.firestore().collection("packaging");

      const packagingObserver = await packRef.onSnapshot(async values => {
        const packaging = [];

        values.forEach(v => {
          packaging.push(v.data());
        });

        packaging.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });

        this.setState({
          packaging
        });
      });

      this.setState({
        packagingObserver
      });
    } catch (e) {
      console.error("error fetching products", e);
      Utils.handleError(e);
    }
  }

  async createDocumentsObserver() {
    try {
      let docsRef = firebase
        .firestore()
        .collection("documents")
        .where("type", "in", [
          DocumentType.ENTRATA,
          DocumentType.PREVENTIVO,
          DocumentType.DISTINTA
        ]);

      const docsObserver = await docsRef.onSnapshot(async docs => {
        const documents = [];
        const productionDocs = [];

        docs.forEach(u => {
          const data = Utils.formatDate(u.data().date);
          let doc = u.data();
          doc.date = data;
          doc.dateTimestamp = u.data().date;
          doc.codeNumber = Utils.getCodeNumber(u.data());
          doc.typeFormatted =
            u.data().type === DocumentType.DISTINTA ? "uscita" : u.data().type;

          documents.push(doc);
        });

        documents.sort((a, b) => {
          if (a.dateTimestamp > b.dateTimestamp) return -1;
          if (a.dateTimestamp < b.dateTimestamp) return 1;
          return 0;
        });
        console.log("AA Documents", documents);
        this.setState({
          documents
        });
      });

      this.setState({
        ...this.state.documents,
        docsObserver
      });
    } catch (e) {
      console.error("error fetching documents", e);
      Utils.handleError(e);
    }
  }

  async deleteDuplicateContact(id) {
    console.log("aa delete", id);

    await firebase
      .firestore()
      .collection("contacts")
      .doc(id)
      .delete();
  }

  async createContactsObserver() {
    try {
      let contactssRef = firebase.firestore().collection("contacts");

      const contactsObserver = await contactssRef.onSnapshot(async docs => {
        const contacts = [];

        docs.forEach(u => {
          const c = u.data();
          // console.log("aa contact", c, u.id);
          c.typeC = "";
          if (c.typeMultiple) {
            for (let i = 0; i < c.typeMultiple.length; i++) {
              const t = c.typeMultiple[i];
              c.typeC += i == 0 ? t : "/" + t;
            }
          }

          const a = c.billingAddress;
          if (!!a) {
            let address = "";
            address =
              (!!a.via && a.via + ", ") +
              (a.numero && a.numero + ", ") +
              (a.citta && a.citta + ", ") +
              (a.cap && a.cap);
            c.fulladdress = address;
          }
          const b = c.shippingAddress;
          if (!!b) {
            let address = "";
            address =
              (!!b.via && b.via + ", ") +
              (b.numero && b.numero + ", ") +
              (b.citta && b.citta + ", ") +
              (b.cap && b.cap);
            c.fulladdressShipping = address;
          }
          if (!!c.uid && c.uid != u.id) {
            console.log("aa add", c.uid, u.id);
            console.log("aa delete", c.uid, u.id);

            this.deleteDuplicateContact(u.id);
          }

          contacts.push(c);
        });
        // console.log("aa contacts", contacts);

        contacts.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });

        const clienti = contacts.filter(c =>
          Utils.getTipoCliente(c, "Cliente")
        );
        const fornitori = contacts.filter(c =>
          Utils.getTipoCliente(c, "Fornitore")
        );
        const affiliati = contacts.filter(c =>
          Utils.getTipoCliente(c, "Affiliato")
        );

        console.log("aa", contacts);
        this.setState({
          ...this.state,
          contacts,
          clienti,
          fornitori,
          affiliati
        });
      });

      this.setState({
        ...this.state.documents,
        contactsObserver
      });
    } catch (e) {
      console.error("error fetching documents", e);
      Utils.handleError(e);
    }
  }

  async changeProductQuantity(document, removing) {
    const products = document.products;
    let productToChange = [];
    products.forEach(p => {
      if (p.combo && p.prodottiFigli) {
        p.prodottiFigli.forEach(f => {
          productToChange.push({
            ...f,
            quantity: parseFloat(f.quantityInBundle) * parseFloat(p.quantity)
          });
        });
      } else {
        productToChange.push(p);
      }
    });
    const type = document.type;
    const causale = document.causale || "";

    console.log("BBB productToChange", productToChange);

    if (!productToChange) return;
    const promises = [];

    productToChange.forEach(async prod => {
      promises.push(
        new Promise(async (r, rj) => {
          const quantity = removing ? -prod.quantity : prod.quantity;
          const doc = {
            uid: document.uid,
            quantity,
            creatorName: document.creatorName,
            date: new Date(),
            type,
            causale
          };
          console.log("BBB change product quantity prod", prod);
          console.log("BBB change product quantity doc", doc);

          const prodRef = firebase
            .firestore()
            .collection("products")
            .doc(prod.uid);

          if (removing) {
            await prodRef
              .collection("documents")
              .doc(doc.uid)
              .delete();
          } else {
            const prodDocRef = prodRef.collection("documents").doc(doc.uid);
            const prodSnap = await prodDocRef.get();

            const prodList = productToChange.filter(p => p.uid == prod.uid);
            console.log("BBB prodList", prodList);

            if (prodList.length > 1) {
              console.log("BBB exist", prodList);
              let qt = 0;
              prodList.forEach(p => {
                qt += p.quantity;
              });
              console.log("BBB exist qt", qt);
              doc.quantity = qt;
            }
            await prodDocRef.set(doc);
          }

          await firebase
            .firestore()
            .runTransaction(function(transaction) {
              return transaction.get(prodRef).then(function(sfDoc) {
                let qt = 0;
                if (type === DocumentType.ENTRATA) qt = quantity;
                else if (type === DocumentType.DISTINTA) qt = -1 * quantity;
                else if (type === DocumentType.MIGRAZIONE) qt = quantity;

                var newQuantity = sfDoc.data().quantity + qt;
                console.log(
                  "BBB newQuantity",
                  newQuantity,
                  sfDoc.data().quantity,
                  qt
                );

                const changeDanneggiati = causale === "Danneggiato";
                const danneggiati = sfDoc.data().danneggiati || 0;
                var newDanneggiati = changeDanneggiati
                  ? danneggiati + quantity
                  : danneggiati;

                console.log("BBB lotti", sfDoc.data());

                let lotti = sfDoc.data()?.lotti || [];
                let found = false;
                if (!!lotti) {
                  lotti.forEach(l => {
                    console.log("AA lotto l", l);

                    if (l.name == prod.lotto) {
                      found = true;
                      l.quantity += qt;
                    }
                  });
                }
                console.log("BBB prodotto update ", lotti);
                console.log("BBB prodotto newQt ", newQuantity);

                transaction.update(prodRef, {
                  lotti: lotti,
                  quantity: newQuantity,
                  danneggiati: newDanneggiati
                });

                r(doc);
              });
            })
            .catch(function(err) {
              window.alert(JSON.stringify(err.message));
              // This will be an "population is too big" error.
              console.error(err);
            });
        })
      );
    });

    await Promise.all(promises);

    if (causale === "Reintegro") {
      this.changeDanneggiatiQty(
        document.oldProduct,
        document.danneggiati,
        removing
      );
    }
  }

  async changeDanneggiatiQty(prod, quantity, removing) {
    var qt = 0;
    if (!removing) qt = quantity;
    else qt = -1 * quantity;

    const prodRef = firebase
      .firestore()
      .collection("products")
      .doc(prod.uid);

    firebase
      .firestore()
      .runTransaction(function(transaction) {
        return transaction.get(prodRef).then(function(sfDoc) {
          const danneggiati = sfDoc.data().danneggiati || 0;
          var newDanneggiati = danneggiati - qt;

          transaction.update(prodRef, { danneggiati: newDanneggiati });
        });
      })
      .catch(function(err) {
        // This will be an "population is too big" error.
        console.error(err);
      });
  }

  async changeProduzioneQuantity(document, removing) {
    const { elements, type, status } = document;

    if (!elements) return;

    const promises = [];
    elements.forEach(c => {
      promises.push(
        new Promise(async (r, rj) => {
          console.log("AA change nostrafornitura", type, c.nostraFornitura, c);

          if (
            type === DocumentType.ENTRATA_PROD ||
            type === DocumentType.USCITA_MANUALE ||
            c.nostraFornitura == "Si"
          ) {
            const quantityInOrder = !!c.quantityInOrder
              ? c.quantityInOrder
              : c.quantity;
            const quantity = removing ? -quantityInOrder : quantityInOrder;

            const doc = {
              uid: document.uid,
              quantity,
              creatorName: document.creatorName,
              date: new Date(),
              type
            };

            const elementRef = firebase
              .firestore()
              .collection("elements")
              .doc(c.uid);

            if (removing) {
              await elementRef
                .collection("documents")
                .doc(doc.uid)
                .delete();
            } else {
              await elementRef
                .collection("documents")
                .doc(doc.uid)
                .set(doc);
            }

            await firebase
              .firestore()
              .runTransaction(function(transaction) {
                return transaction.get(elementRef).then(function(sfDoc) {
                  let qt = 0;
                  if (type === DocumentType.ENTRATA_PROD) qt = quantity;
                  else if (
                    type === DocumentType.USCITA_PROD ||
                    type === DocumentType.USCITA_MANUALE
                  )
                    qt = -1 * quantity;
                  var newQuantity = sfDoc.data().quantity + qt;

                  let lotti = sfDoc.data().lotti;
                  if (!!lotti) {
                    lotti.forEach(l => {
                      console.log("AA lotto l", l);

                      if (l.name == c.lotto) {
                        l.quantity += qt;
                      }
                    });
                  } else lotti = [];

                  console.log("AA change quantity", lotti, quantity);

                  transaction.update(elementRef, {
                    lotti: lotti,
                    quantity: newQuantity
                  });
                });
              })
              .catch(function(err) {
                // This will be an "population is too big" error.
                console.error(err);
              });
          }
          r(c);
        })
      );
    });
    console.log("AA ended routine");

    await Promise.all(promises);
    console.log("AA ended routine2");
  }

  async componentDidMount() {
    this.createObservers();
  }

  createObservers() {
    this.createProductsObserver();
    // this.createElementsObserver();
    this.createIvaObserver();
    this.createContactsObserver();

    // this.setState({
    //   timer: setTimeout(async () => {
    //     this.createDocumentsObserver();
    //   }, 1500)
    // });

    // this.createPackagingObserver();

    this.setState({
      changeProductQuantity: this.changeProductQuantity,
      changeProduzioneQuantity: this.changeProduzioneQuantity,
      createOrdersObserver: this.createOrdersObserver,
      createProductsObserver: this.createProductsObserver,
      createElementsObserver: this.createElementsObserver,
      createContactsObserver: this.createContactsObserver,
      createDocumentsObserver: this.createDocumentsObserver
    });
  }

  componentWillUnmount() {
    // if (this.state.ordersObserver) this.state.ordersObserver();
    if (this.state.products.productsObserver)
      this.state.products.productsObserver();
    if (this.state.products.elementsObserver)
      this.state.products.elementsObserver();
    if (this.state.docsObserver) this.state.docsObserver();
    if (this.state.contactsObserver) this.state.contactsObserver();
    if (this.state.ivaObserver) this.state.ivaObserver();
    if (this.state.packagingObserver) this.state.packagingObserver();
  }

  render() {
    return (
      <AdminContext.Provider value={this.state}>
        <div>{this.props.children}</div>
      </AdminContext.Provider>
    );
  }
}

export default withRouter(AdminProvider);

export function withAdminCtx(Component) {
  return props => (
    <AdminConsumer>{ctx => <Component admin={ctx} {...props} />}</AdminConsumer>
  );
}
