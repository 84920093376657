import React, { Component } from "react";
import { Container } from "bloomer";
import { withAdminCtx } from "../contexts/AdminContext";
import MyTable from "../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { withWareCtx } from "../contexts/WarehouseContext";
import firebase from "firebase/compat/app";

import { withModalsCtx } from "../contexts/ModalsContext";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/it";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentType from "../enums/DocumentType";
import StorageService from "../services/StorageService";
import Utils from "../Utils";
import { ExportToCsv } from "export-to-csv-file";
import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading,
  SimpleGrid,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody
} from "@chakra-ui/react";
import { PrintModal } from "../components/DPrintedDoc";
import DDocumentDetail from "./desktop/DDocumentDetail";

var moment = require("moment");

class DUscite extends Component {
  state = {
    printModal: false,
    date: [],
    filteredDocs: [],
    filterValue: "Tutti",
    canaleValue: "Tutti i canali",
    causaleValue: "Tutte le causali",
    paeseValue: "Tutti i paesi",
    startDate: moment(new Date()).add(-30, "days"),
    endDate: moment(new Date()),
    deleteDoc: null,
    deleting: false
  };

  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.setDocs = this.setDocs.bind(this);
  }

  async loadDocs() {
    this.setState({ loading: true });

    try {
      let docsRef = firebase
        .firestore()
        .collection("documents")
        .where("type", "in", [
          DocumentType.ENTRATA,
          DocumentType.PREVENTIVO,
          DocumentType.DISTINTA
        ])
        .where("date", "<=", this.state.endDate.toDate())
        .where("date", ">=", this.state.startDate.toDate());

      if (this.state.docsObserver) this.state.docsObserver();

      const docsObserver = await docsRef.onSnapshot(async docs => {
        const documents = [];
        const productionDocs = [];

        docs.forEach(u => {
          const data = Utils.formatDate(u.data().date);
          let doc = u.data();
          doc.date = data;
          doc.dateTimestamp = u.data().date;
          doc.codeNumber = Utils.getCodeNumber(u.data());
          doc.typeFormatted =
            u.data().type === DocumentType.DISTINTA ? "uscita" : u.data().type;

          documents.push(doc);
        });

        documents.sort((a, b) => {
          if (a.dateTimestamp > b.dateTimestamp) return -1;
          if (a.dateTimestamp < b.dateTimestamp) return 1;
          return 0;
        });
        console.log("AA Documents", documents);
        this.setState(
          {
            docs: documents,
            filteredDocs: documents,
            loading: false,
            dateChanged: false
          },
          () => this.applyFilters()
        );
      });

      this.setState({
        docsObserver
      });
    } catch (e) {
      console.error("error fetching documents", e);
      Utils.handleError(e);
    }
  }

  componentWillUnmount() {
    if (this.state.docsObserver) this.state.docsObserver();
  }

  componentDidMount() {
    this.setState({
      timer: setTimeout(async () => {
        this.loadDocs();
      })
    });
  }

  // componentWillReceiveProps(p) {
  //   this.setDocs(p);
  // }
  // componentWillUnmount() {
  //   if (this.state.docsObserver) this.state.docsObserver();
  // }

  // async createDocumentsObserver() {
  //   try {
  //     let docsRef = firebase
  //       .firestore()
  //       .collection("documents")
  //       .where("type", "in", [
  //         DocumentType.ENTRATA,
  //         DocumentType.PREVENTIVO,
  //         DocumentType.DISTINTA
  //       ]);

  //     const docsObserver = await docsRef.onSnapshot(async docs => {
  //       const documents = [];
  //       const productionDocs = [];

  //       docs.forEach(u => {
  //         const data = Utils.formatDate(u.data().date);
  //         let doc = u.data();
  //         doc.date = data;
  //         doc.dateTimestamp = u.data().date;
  //         doc.codeNumber = Utils.getCodeNumber(u.data());
  //         doc.typeFormatted =
  //           u.data().type === DocumentType.DISTINTA ? "uscita" : u.data().type;

  //         documents.push(doc);
  //       });

  //       documents.sort((a, b) => {
  //         if (a.dateTimestamp > b.dateTimestamp) return -1;
  //         if (a.dateTimestamp < b.dateTimestamp) return 1;
  //         return 0;
  //       });

  //       this.setDocs(documents);
  //       // this.setState({
  //       //   documents
  //       // });
  //     });

  //     this.setState({
  //       // ...this.state.documents,
  //       docsObserver
  //     });
  //   } catch (e) {
  //     console.error("error fetching documents", e);
  //     Utils.handleError(e);
  //   }
  // }

  async setDocs(props) {
    const docs = props?.admin?.documents?.filter(
      d =>
        d.type === DocumentType.DISTINTA ||
        d.type === DocumentType.PREVENTIVO ||
        d.type === DocumentType.ENTRATA
    );
    this.setState({ docs, filteredDocs: docs }, () => this.applyFilters());
  }

  onModify(p, id, e, searchText) {
    if (e.metaKey) {
      window.open("documentDetails/" + p.uid + "/" + p.type, "_blank");
      return;
    }

    this.props.history.push({
      pathname: "/documentDetails/" + p.uid + "/" + p.type,
      goBack: "/uscita/?search=" + searchText,
      state: searchText
    });
  }

  onPrint(p) {
    // this.setState({ printModal: p.uid });
    const w = window.open("/printDoc/" + p.uid);
  }

  onPreview(p) {
    this.setState({ printModal: p.uid });
    // const w = window.open("/printDoc/" + p.uid);
  }

  async onPDFClicked(p) {
    const url = await StorageService.getPDF(p.uid, p.pdfFilename, "pdf");
    console.log("AA url", url);
    window.open(url, "_blank");
  }

  onDelete(p) {
    this.setState({ deleteDoc: p });
  }

  renderDeleteModal() {
    return (
      <AlertDialog
        isOpen={!!this.state.deleteDoc}
        onClose={() => this.setState({ deleteDoc: null })}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confermi eliminazione {this.state.deleteDoc?.codeNumber}
            </AlertDialogHeader>

            <AlertDialogFooter>
              <Button onClick={() => this.setState({ deleteDoc: null })}>
                Esci
              </Button>
              <Button
                colorScheme="red"
                onClick={() => this.confirmDelete(this.state.deleteDoc)}
                isLoading={this.state.deleting}
                ml={3}
              >
                Elimina
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }
  async confirmDelete(p) {
    this.setState({ deleting: true });
    const pdfFilename = p.pdfFilename;
    const uid = p.uid;

    const productSnap = await firebase
      .firestore()
      .collection("documents")
      .doc(p.uid)
      .delete();

    await this.props.admin.changeProductQuantity(p, true);

    if (pdfFilename) StorageService.deletePDF(pdfFilename, uid, "pdf");

    this.setState({ deleting: false, deleteDoc: null });
  }

  addDistinta() {
    this.props.history.push("uscitaAdd");
  }

  addPreventivo() {
    this.props.history.push("preventivo");
  }

  addEntrata() {
    this.props.history.push("entrataAdd");
  }

  export() {
    const { filteredDocs } = this.state;

    let data = [];
    filteredDocs.forEach(o => {
      // Numero – Data – Tipo – Causale – Cliente - Totale - Creato da
      const d = {
        Numero: o.codeNumber,
        Data: o.date,
        Tipo: o.type,
        Causale: o.causale,
        Cliente: o.contact.name || "",
        Totale: o.finalPrice,
        Creatore: o.creatorName
      };

      data.push(d);
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      filename: "MyLab Movimenti prodotti",
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Movimenti prodotti",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  applyFilters() {
    const {
      filterValue,
      canaleValue,
      causaleValue,
      paeseValue,
      startDate,
      endDate
    } = this.state;
    console.log("AA filtervalue", filterValue);
    let docs;
    if (filterValue === "Tutti") docs = this.state.docs;
    else {
      docs = this.state.docs.filter(d =>
        filterValue === "Preventivi"
          ? d.type === DocumentType.PREVENTIVO
          : filterValue === "Distinte Uscita"
          ? d.type === DocumentType.DISTINTA
          : d.type === DocumentType.ENTRATA
      );
    }
    // console.log("AA docs", docs);

    if (canaleValue != "Tutti i canali") {
      docs = docs.filter(
        d =>
          d.canaleVendita === canaleValue ||
          Utils.getCanaleVendita(d, canaleValue)
      );
    }
    // console.log("AA docs", docs);

    if (causaleValue != "Tutte le causali") {
      docs = docs.filter(d => d.causale === causaleValue);
    }

    if (paeseValue != "Tutti i paesi") {
      if (paeseValue === "Italia")
        docs = docs.filter(
          d => !d.paeseVendita || d.paeseVendita === paeseValue
        );
      else docs = docs.filter(d => d.paeseVendita === paeseValue);
    }
    // console.log("AA docs", docs);

    docs = docs?.filter(
      d =>
        d.dateTimestamp.seconds >= startDate.valueOf() / 1000 &&
        d.dateTimestamp.seconds <= endDate.valueOf() / 1000
    );
    // console.log("AA docs", docs);

    this.setState({ filteredDocs: docs });
  }

  onDatesChange(startDate, endDate) {
    this.setState({ startDate, endDate, dateChanged: true });

    if (!startDate || !endDate) return;

    startDate = startDate.utcOffset(0);
    endDate = endDate.utcOffset(0);
    endDate.set({ hour: 24, minute: 0, second: 0, millisecond: 0 });
    startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // this.loadDocs();
    // this.setState({ startDate, endDate }, () => this.applyFilters());
  }

  setFilter(val) {
    this.setState({ filterValue: val, filterActive: false }, () =>
      this.applyFilters()
    );
  }

  renderFilter() {
    const filters = [
      "Tutti",
      "Preventivi",
      "Distinte Uscita",
      "Distinte Entrate"
    ];
    return (
      <div>
        <Dropdown isActive={this.state.filterActive}>
          <DropdownTrigger>
            <Button
              className="filter-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                this.setState({
                  filterActive: !this.state.filterActive
                });
              }}
            >
              {this.state.filterValue}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {filters.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() => this.setFilter(e)}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  setFilterCanle(val) {
    this.setState({ canaleValue: val, filterCanelaActive: false }, () =>
      this.applyFilters()
    );
  }

  renderCanaleFilter() {
    const filters = [
      "Tutti i canali",
      "Sito",
      "Altri marketplace",
      "Affiliati",
      "Partner",
      "Rivenditori",
      "Istituzionali"
    ];
    return (
      <div>
        <Dropdown isActive={this.state.filterCanelaActive}>
          <DropdownTrigger>
            <Button
              className="filter-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                this.setState({
                  filterCanelaActive: !this.state.filterCanelaActive
                });
              }}
            >
              {this.state.canaleValue}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {filters.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() => this.setFilterCanle(e)}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  setFilterCausale(val) {
    this.setState({ causaleValue: val, filterCausaleActive: false }, () =>
      this.applyFilters()
    );
  }

  renderCausaleFilter() {
    const filters = [
      "Tutte le causali",
      "Acquisto",
      "Vendita",
      "Reso",
      "Omaggio",
      "Danneggiato",
      "Autoconsumo"
    ];

    return (
      <div>
        <Dropdown isActive={this.state.filterCausaleActive}>
          <DropdownTrigger>
            <Button
              className="filter-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                this.setState({
                  filterCausaleActive: !this.state.filterCausaleActive
                });
              }}
            >
              {this.state.causaleValue}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {filters.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() => this.setFilterCausale(e)}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  setFilterPaese(val) {
    this.setState({ paeseValue: val, filterPaeseActive: false }, () =>
      this.applyFilters()
    );
  }

  renderPaeseFilter() {
    const filters = [
      "Tutti i paesi",
      "Italia",
      "Francia",
      "Germania",
      "Spagna",
      "Olanda",
      "Svezia",
      "Polonia",
      "Belgio",
      "Regno Unito"
    ];

    return (
      <div>
        <Dropdown isActive={this.state.filterPaeseActive}>
          <DropdownTrigger>
            <Button
              className="filter-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                this.setState({
                  filterPaeseActive: !this.state.filterPaeseActive
                });
              }}
            >
              {this.state.paeseValue}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {filters.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() => this.setFilterPaese(e)}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  render() {
    const { filteredDocs } = this.state;
    console.log("AA filteredDocs", filteredDocs);
    // if (!filteredDocs) return <Loader />;

    const columns = [
      {
        Header: "Numero",
        accessor: "codeNumber",
        style: { textAlign: "center" }
      },
      {
        Header: "Data",
        accessor: "date",
        style: { textAlign: "center" }
      },
      {
        Header: "Tipo",
        accessor: "typeFormatted",
        maxWidth: 100,
        style: { textAlign: "center" },
        styleWarning: { color: "red", textAlign: "center" }
      },
      {
        Header: "Causale",
        accessor: "causale",
        maxWidth: 100,
        style: { textAlign: "center" }
      },
      {
        Header: "Cliente",
        accessor: "contact",
        subaccessor: "name",
        style: { textAlign: "center" }
      },
      // {
      //   Header: "Totale",
      //   accessor: "finalPrice",
      //   type: "currency",
      //   style: { textAlign: "center" }
      // },
      {
        Header: "Creato da",
        accessor: "creatorName",
        style: { textAlign: "center" }
      }
    ];

    return (
      <section>
        <Container>
          <div
            style={{
              backgroundColor: "#75759047",
              height: "60px",
              padding: "5px"
            }}
          >
            {/* <Flex align="center" gap={1}> */}
            <DateRangePicker
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              isOutsideRange={() => false}
              displayFormat="DD MMM YYYY"
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) =>
                this.onDatesChange(startDate, endDate)
              } // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              startDatePlaceholderText={"Data inizio"}
              endDatePlaceholderText={"Data fine"}
            />
            {this.state.dateChanged && (
              <Button
                isLoading={this.state.loading}
                ml={2}
                colorScheme="green"
                onClick={() => this.loadDocs()}
                size="sm"
              >
                <FontAwesomeIcon className="icn" icon={["fa", "check"]} />
              </Button>
            )}
            {/* </Flex> */}
            <div style={{ display: "flex", float: "right", padding: "5px" }}>
              {this.renderFilter()}
              {this.renderCanaleFilter()}
              {this.renderCausaleFilter()}
              {this.renderPaeseFilter()}
            </div>
          </div>

          <Flex align={"center"} justifyContent="space-between" my={2}>
            <Heading size="lg"> Movimenti Prodotti</Heading>

            <SimpleGrid columns={2} spacing={1}>
              <Button colorScheme="green" onClick={() => this.addPreventivo()}>
                NUOVO PREVENTIVO
              </Button>
              <Button colorScheme="green" onClick={() => this.addDistinta()}>
                DISTINTA USCITA
              </Button>{" "}
              <Button colorScheme="green" onClick={() => this.addEntrata()}>
                DISTINTA ENTRATA
              </Button>
              <Button colorScheme="green" onClick={() => this.export()}>
                ESPORTA
              </Button>
            </SimpleGrid>
          </Flex>
          <div className="admin-panels">
            <MyTable
              data={filteredDocs}
              columns={columns}
              onPDFClicked={u => this.onPDFClicked(u)}
              onModify={(u, id, e, searchText) =>
                this.onModify(u, id, e, searchText)
              }
              onDelete={u => this.onDelete(u)}
              onPrint={u => this.onPrint(u)}
              onPreview={u => this.onPreview(u)}
              cerca={true}
              searchGroup={["products", "contact"]}
              searchFields={["nomeDisplay", "name", "codeNumber"]}
              {...this.props}
            />
          </div>
        </Container>

        {this.renderDeleteModal()}
        <PrintModal
          isOpen={this.state.printModal}
          uid={this.state.printModal}
          onClose={() => this.setState({ printModal: null })}
          onPrint={id => {
            this.onPrint({ uid: id });
            this.setState({ printModal: null });
          }}
        />
      </section>
    );
  }
}

export default withModalsCtx(withRouter(withAdminCtx(withWareCtx(DUscite))));
