import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataIE from "@highcharts/map-collection/countries/it/it-all.geo.json";
import province from "../static-data/province";
import italia from "../static-data/italia";

highchartsMap(Highcharts); // Initialize the map module

if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

export default class DReportMap extends React.Component {
  state = {
    mapData: []
  };
  componentDidMount() {
    console.log("AA componentWillReceiveProps");

    const mapDataObj = [];
    this.props.data.forEach(d => {
      italia.regioni.forEach(r => {
        r.province.forEach(p => {
          p.comuni.forEach(c => {
            // console.log("AA c.cap", c.cap, d.cap);

            if (c.cap === d.cap) {
              // console.log("AA cap", c.cap);
              if (!mapDataObj[p.code]) {
                mapDataObj[p.code] = {
                  code: p.code,
                  cap: c.cap,
                  ordini: 0,
                  provincia: "it-" + p.code.toLowerCase()
                };
              }
              mapDataObj[p.code] = {
                code: p.code,
                cap: c.cap,
                ordini: mapDataObj[p.code].ordini + 1,
                provincia: "it-" + p.code.toLowerCase()
              };
            }
          });
        });
      });
    });

    console.log("AA dati", mapDataObj);
    const mapData = [];
    Object.keys(mapDataObj).forEach(function(code) {
      const mappaD = mapDataObj[code];
      // console.log("AA d", mappaD);
      const provincia = mappaD.provincia;
      const ordini = mappaD.ordini;

      mapData.push([provincia, ordini]);
    });

    console.log("AA mapdati", mapData);
    this.setState({ mapData });
  }

  render() {
    const mapOptions = {
      chart: {
        map: "countries/it/it-all"
      },
      title: {
        text: " "
      },
      credits: {
        enabled: false
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom"
        }
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle"
      },
      colorAxis: {
        min: 0,
        minColor: "#E6E7E8",
        maxColor: "#005645"
      },
      // tooltip: {
      //   headerFormat: "",
      //   pointFormat: "city: {point.keyword} lat: {point.lat}, lon: {point.lon}"
      // },
      series: [
        // {
        //   // Use the gb-all map with no data as a basemap
        //   name: "Basemap",
        //   mapData: mapDataIE,
        //   borderColor: "#A0A0A0",
        //   nullColor: "rgba(200, 200, 200, 0.3)",
        //   showInLegend: false
        // },
        {
          mapData: mapDataIE,

          // Specify points using lat/lon
          // type: "mapbubble",
          name: "Ordini",
          // color: "#4169E1",
          data: this.state.mapData,

          // cursor: "pointer",
          states: {
            hover: {
              color: "#BADA55"
            }
          },
          dataLabels: {
            enabled: true,
            format: "{point.name}"
          }
        }
      ]
    };

    // const p = province;
    // console.log("AA prov", p);
    // console.log("AA italia", italia);
    // mapOptions.series[0].data = this.state.mapData;
    // console.log("AA mapOptions", mapOptions.series[0].data);
    // console.log("AA datat", data);

    return (
      <div>
        <h1>Ordini per Provincia</h1>
        <HighchartsReact constructorType={"mapChart"} highcharts={Highcharts} options={mapOptions} />
      </div>
    );
  }
}
