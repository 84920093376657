import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withModalsCtx } from "../contexts/ModalsContext";
import { withWareCtx } from "../contexts/WarehouseContext";
import { withAdminCtx } from "../contexts/AdminContext";
import firebase from "firebase/compat/app";

import Loader from "../components/Loader";
import { Columns, Column } from "bloomer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "bloomer/lib/layout/Container";
import { Button } from "bloomer/lib/elements/Button";
import { Field } from "bloomer/lib/elements/Form/Field/Field";
import { Input } from "bloomer/lib/elements/Form/Input";
import { Label } from "bloomer/lib/elements/Form/Label";
import Utils from "../Utils";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import Decimal from "decimal.js";
import { Help } from "bloomer/lib/elements/Form/Help";
import DocumentType from "../enums/DocumentType";
import DocumentStatus from "../enums/DocumentStatus";
import { TextArea } from "bloomer/lib/elements/Form/TextArea";

class DProduzioneDoc extends Component {
  state = {
    doc: null,
    modify: false,
    needsUpadteQty: false,
    ivaDropdownActive: [],
    nomiDropdownActive: [],
    multipleValues: []
  };

  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
  }

  async componentDidMount() {
    const uid = this.props.match.params.pUid;

    const filteredProducts = this.props.admin.products.products;

    if (!uid) {
      const creatorName = firebase.auth().currentUser.displayName;

      const doc = {
        name: "Nuovo documento",
        type: DocumentType.USCITA_PROD,
        creatorName
      };
      this.setState({
        doc,
        modify: true,
        type: this.props.type,
        filteredProducts
      });
    } else {
      const docSnap = await firebase
        .firestore()
        .collection("documents")
        .doc(uid)
        .get();

      if (docSnap.exists) {
        let doc = docSnap.data();
        const data = Utils.formatDate(doc.date);
        doc.dateFormatted = data;
        doc.codeNumber = Utils.getCodeNumber(doc);
        const needsUpadteQty = doc.status === DocumentStatus.SOSPESO;
        const modify = doc.status === DocumentStatus.SOSPESO ? true : false;
        this.setState(
          {
            doc,
            modify,
            type: docSnap.data().type,
            productsBeforeChange: docSnap.data().products,
            filteredProducts,
            needsUpadteQty
          },
          () => this.checkArrivatoStatus()
        );
      }
    }
  }

  doModify() {
    this.setState({ modify: !this.state.modify });
  }

  submit() {
    this.props.modals.addModal(
      "Confermi salvataggio?",
      <div />,
      [
        {
          label: "Cancella",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "Salva",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmSave();
        } else {
          this.props.history.goBack();
        }
      }
    );
  }

  checkStatus() {
    let { doc } = this.state;
    let needOrder = false;
    if (!doc.elements) return false;

    doc.elements.forEach(c => {
      if (!needOrder && c.quantityToOrder > 0) needOrder = true;
    });

    return needOrder;
  }

  changeQuantity(doc) {
    var changeProdQt = firebase
      .functions()
      .httpsCallable("changeProduzioneQuantity");
    changeProdQt({ document: doc, removing: false }).then(function(result) {
      // Read result of the Cloud Function.
      // var sanitizedMessage = result.data.text;
      // ...
      console.log("AA quantity changed!!!!!");
    });

    // await this.props.admin.changeProduzioneQuantity(doc, false);
  }

  async confirmSave() {
    let { doc } = this.state;

    if (this.props.adding) {
      console.log("AA adding");
      const needsOrder = this.checkStatus();

      doc.creator = firebase.auth().currentUser.uid;
      doc.creatorName = firebase.auth().currentUser.displayName;
      doc.date = new Date();
      console.log("AA prod", doc);
      let docUid = null;
      const docRef = await firebase
        .firestore()
        .collection("documents")
        .add(doc)
        .then(docRef => (doc.uid = docRef.id));

      if (doc.status != DocumentStatus.SOSPESO) this.changeQuantity(doc);

      if (needsOrder) this.showAlert();
    } else {
      const docRef = await firebase
        .firestore()
        .collection("documents")
        .doc(doc.uid)
        .update(doc);
    }

    if (this.state.needsUpadteQty && doc.status != DocumentStatus.SOSPESO) {
      this.changeQuantity(doc);
    }
    this.onSave();
  }

  async doUpdateStatus() {
    let { doc, documentoChiuso } = this.state;
    console.log("AA doc products", doc.products);

    const docRef = await firebase
      .firestore()
      .collection("documents")
      .doc(doc.uid)
      .update({ status: doc.status, products: doc.products, documentoChiuso });

    if (this.state.needsUpadteQty && doc.status != DocumentStatus.SOSPESO) {
      this.changeQuantity(doc);
    }

    this.onSave();
  }

  showAlert() {
    this.props.modals.addModal(
      "Giacenze di magazzino insufficienti, è necessario ordinare i seguenti prodotti",
      <div className="admin-panel"> {this.renderResults()}</div>,
      [
        {
          label: "Esci",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        }
      ],
      false,
      result => {}
    );
  }

  onSave() {
    this.props.history.push("/produzione");
  }

  renderProducts() {
    const { modify } = this.state;
    const { doc } = this.state;
    return (
      <div className="mtb-80">
        <h3 className="title has-text-black ">Prodotti </h3>
        <Columns>
          <Column>
            <Label>Prodotto</Label>
          </Column>
          <Column isSize="1/4">
            <Label>Quantità</Label>
          </Column>
          {modify && <Column isSize="1/4" />}
          {!modify && doc.type === DocumentType.USCITA_PROD && (
            <Column isSize="1/4">Arrivato</Column>
          )}
        </Columns>
        {!!doc.products &&
          doc.products.map((s, idx) => {
            return (
              <div key={s.uid}>
                <Columns>
                  <Column>
                    <Field>
                      {!!s.nomiAlternativi && s.nomiAlternativi.length > 0 ? (
                        this.renderNomi(s)
                      ) : (
                        <Input
                          className={"blocked"}
                          type="text"
                          value={s.name}
                          readOnly
                        />
                      )}
                    </Field>
                  </Column>
                  <Column isSize="1/4">
                    <Field>
                      <Input
                        className={modify ? "" : "blocked"}
                        name="quantity"
                        type="number"
                        placeholder=""
                        value={s.quantity}
                        onChange={e => {
                          this.handleQuantityChange(
                            s,
                            parseFloat(e.target.value)
                          );
                        }}
                      />
                    </Field>
                  </Column>

                  {modify && (
                    <Column isSize="1/4">
                      <Field>
                        <Button onClick={() => this.onDelete(s)}>
                          <FontAwesomeIcon
                            className="icn"
                            icon={["fa", "trash"]}
                          />
                        </Button>
                      </Field>
                    </Column>
                  )}

                  {!modify && doc.type === DocumentType.USCITA_PROD && (
                    <Column isSize="1/4">
                      <div className="has-checkradio">
                        <input
                          className="is-checkradio"
                          type="checkbox"
                          required
                          name="arrivato"
                          checked={!!doc.products[idx].arrivato}
                          onChange={() => {}}
                        />
                        <label
                          htmlFor="arrivato"
                          onClick={() => this.setProdottoArrivato(idx)}
                        />
                      </div>
                    </Column>
                  )}
                </Columns>
              </div>
            );
          })}

        {modify && (
          <div>
            <Columns>
              <Column>
                <Dropdown
                  isActive={this.state.productDropdownActive && modify}
                  className="dropdown-menu-bis"
                >
                  <Field className="mb-10">
                    <Input
                      name="product"
                      type="text"
                      placeholder="cerca prodotto"
                      value={this.state.searchCode}
                      onChange={e => {
                        this.handleSearchCode(e.target.value);
                      }}
                    />
                  </Field>

                  <DropdownMenu>
                    <DropdownContent className="elements-dropdown">
                      {this.state.filteredProducts.map(e => {
                        return (
                          <DropdownItem
                            className="ta-l"
                            onClick={() => this.onSelect(e)}
                            key={e.uid}
                          >
                            {e.name}
                            <FontAwesomeIcon
                              className="ml-15"
                              icon={["fa", "plus-circle"]}
                            />
                          </DropdownItem>
                        );
                      })}
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown>
              </Column>
            </Columns>
          </div>
        )}
      </div>
    );
  }

  setProdottoArrivato(idx) {
    const { doc } = this.state;
    doc.products[idx].arrivato = !doc.products[idx].arrivato;
    this.setState({ doc }, () => this.checkArrivatoStatus());
  }

  checkArrivatoStatus() {
    const { doc } = this.state;
    let documentoChiuso = true;
    doc.products.forEach(p => {
      if (!p.arrivato && documentoChiuso) documentoChiuso = false;
    });

    console.log("AA documentoChiuso", documentoChiuso);
    this.setState({ documentoChiuso });
  }
  renderNomi(p) {
    const { modify } = this.state;

    return (
      <div>
        <Button
          className="dropdown-warehouse"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => {
            if (modify) {
              const nomiDropdownActive = [];
              nomiDropdownActive[p.uid] = !this.state.nomiDropdownActive[p.uid];

              this.setState({
                nomiDropdownActive
              });
            }
          }}
        >
          {p.nomeDisplay}
          <span className="dropdown-icn">
            <FontAwesomeIcon className="icn" icon={["fa", "chevron-down"]} />
          </span>
        </Button>
        <Dropdown isActive={this.state.nomiDropdownActive[p.uid] && modify}>
          <DropdownTrigger />
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {p.nomiAlternativi.map(e => {
                return (
                  <DropdownItem
                    className="ta-l"
                    onClick={() => this.onSelectNome(e, p)}
                    key={e}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  handleQuantityChange(product, val) {
    let { doc } = this.state;
    doc.products.forEach(p => {
      if (p.uid === product.uid) p.quantity = val;
    });

    this.setState({ doc }, () => this.calcResults());
  }

  async calcResults() {
    let { doc } = this.state;

    let elements = [];

    const promises = [];

    doc.products.forEach(p => {
      p.composition.forEach(async e => {
        e.quantityInOrder = p.quantity * e.quantity;
        const applySfrido = e.unitmeasure != "pezzi";
        e.quantityInOrderSplitted = !!applySfrido
          ? p.quantity * e.quantity * 1.04
          : p.quantity * e.quantity;

        let found = false;

        elements.forEach(r => {
          if (r.uid === e.uid) {
            found = true;
            r.quantityInOrder += e.quantityInOrder;
          }
        });

        this.props.admin.elements.forEach(el => {
          if (el.uid === e.uid) {
            e.quantityAvailable = el.quantity;
            e.nostraFornitura = el.nostraFornitura ? el.nostraFornitura : "No";
            e.unitmeasure = el.unitmeasure || "";
            e.fornitore = el.supplier;
            if (!!el.ordineMinimo) e.ordineMinimo = el.ordineMinimo;
          }
        });

        if (!found) elements.push(e);

        // // if (!found) {
        // promises.push(
        //   new Promise(async (r, rj) => {
        //     const elSnap = await firebase
        //       .firestore()
        //       .collection("elements")
        //       .doc(e.uid)
        //       .get();
        //     if (elSnap.exists) {
        //       const el = elSnap.data();
        //       e.quantityAvailable = el.quantity;
        //       e.nostraFornitura = el.nostraFornitura ? el.nostraFornitura : "No";
        //       e.unitmeasure = el.unitmeasure;
        //       e.fornitore = el.supplier;
        //       if (!!el.ordineMinimo) e.ordineMinimo = el.ordineMinimo;
        //     }

        //     r(e);
        //   })
        // );
        // if (!found) {
        //   elements.push(e);
        // }
      });
    });

    // await Promise.all(promises);

    elements.forEach(s => {
      const applySfrido = s.unitmeasure != "pezzi";
      s.quantityWithoutSfrido = s.quantityInOrder;
      s.quantityInOrder = !!applySfrido
        ? s.quantityInOrder * 1.04
        : s.quantityInOrder;
      let quantityToOrder = s.quantityInOrder - s.quantityAvailable;
      if (!!s.ordineMinimo) {
        let resto = quantityToOrder % s.ordineMinimo;
        let div = parseInt(quantityToOrder / s.ordineMinimo);
        quantityToOrder =
          resto > 0 ? (div + 1) * s.ordineMinimo : div * s.ordineMinimo;
      }
      quantityToOrder =
        s.quantityInOrder - s.quantityAvailable > 0
          ? Utils.mlround(quantityToOrder)
          : 0;
      s.quantityToOrder = quantityToOrder;
    });

    doc.elements = elements;
    this.setState({ doc });
  }

  handleSearchCode(text) {
    const filteredProducts = this.props.admin.products.products.filter(
      p =>
        String(p.name.toLowerCase()).includes(text.toLowerCase()) ||
        (!!p.barcode &&
          String(p.barcode.toLowerCase()).includes(text.toLowerCase())) ||
        (!!p.code && String(p.code.toLowerCase()).includes(text.toLowerCase()))
    );

    this.setState({
      searchCode: text,
      productDropdownActive: true,
      filteredProducts
    });
  }

  async onSelect(e) {
    console.log("AA ele", e);
    let { doc } = this.state;
    const nomiAlternativi = !!e.nomiAlternativi ? e.nomiAlternativi : "";
    const el = {
      name: e.name,
      nomeDisplay: e.name,
      nomiAlternativi: nomiAlternativi,
      uid: e.uid,
      price_sell: !!e.price_sell ? e.price_sell : 0,
      iva: 22,
      ivaDisplay: "22%",
      quantity: 0,
      quantityAvailable: e.quantity,
      composition: e.composition
    };

    const products = !!doc.products ? doc.products : [];

    products.push(el);
    doc.products = products;
    this.setState({ doc, productDropdownActive: false, searchCode: "" });

    this.calcResults();
    this.checkUndefined(el);
  }

  checkUndefined(el) {
    var test = Utils.checkValueUndefined(el);
    console.log("AA test", test);
    if (test) {
      var string = "valore nullo nel campo " + test;
      alert(string);
    }

    el.composition.forEach(e => {
      var test = Utils.checkValueUndefined(e);
      if (test) {
        var string =
          "valore nullo nel campo composition " + e.name + " " + test;
        alert(string);
      }
    });
  }

  onSelectNome(e, product) {
    let { doc } = this.state;
    const products = doc.products;
    products.forEach(p => {
      if (p.uid === product.uid) {
        p.nomeDisplay = e;
      }
    });

    doc.products = products;

    const nomiDropdownActive = [];
    nomiDropdownActive[product.uid] = false;

    this.setState({
      doc,
      nomiDropdownActive
    });
  }

  async onDelete(s) {
    let { doc } = this.state;
    doc.products = doc.products.filter(sub => sub.uid != s.uid);
    this.setState({ doc }, () => this.calcResults());
  }

  renderResults() {
    const { doc } = this.state;

    return (
      <div>
        <h3 className="title has-text-black">Nota d'ordine </h3>
        <div className="produzione-doc">
          <div className=" prodotti">
            {!!doc.products &&
              doc.products.map(p => {
                return (
                  <div>
                    <h3
                      className="title has-text-black"
                      style={{ marginTop: "10px", marginBottom: "0px" }}
                    >
                      {p.name}
                    </h3>
                    <Columns>
                      <Column isSize="1/4">
                        <Label>Prodotto</Label>
                      </Column>
                      <Column>
                        <Label>Quantità Ordine</Label>
                      </Column>
                      <Column></Column>
                      <Column></Column>
                      <Column></Column>
                      <Column></Column>
                    </Columns>
                    {p.composition.map(c => {
                      return (
                        <div
                          key={c.uid}
                          className={
                            c.nostraFornitura == "Si"
                              ? "nostra-fornitura-row"
                              : "column-rows"
                          }
                          style={{
                            borderBottom: "solid 3px #cac2c2",
                            marginTop: "10px"
                          }}
                        >
                          <Columns>
                            <Column isSize="1/4">
                              <Label>{c.name}</Label>
                              <Help>
                                {!!c.ordineMinimo &&
                                  " ordine minimo " + c.ordineMinimo}{" "}
                              </Help>
                            </Column>
                            <Column>
                              <Label>
                                {Utils.mlround(c.quantityInOrderSplitted)}
                              </Label>
                              {/* {c.quantityInOrder != c.quantityWithoutSfrido && <Label style={{ textDecoration: "line-through" }}>{Utils.mlround(c.quantityInOrder - c.quantityWithoutSfrido)}</Label>} */}
                            </Column>
                            <Column></Column>
                            <Column></Column>
                            <Column></Column>
                            <Column></Column>
                            {/* <Column>
                            <Label>{Utils.mlround(c.quantityAvailable)}</Label>
                          </Column>
                          <Column>
                            <Label className={c.quantityToOrder > 0 && "has-text-red"}>{c.quantityToOrder}</Label>
                          </Column> */}
                            {/* <Column>
                              <Label>{c.nostraFornitura}</Label>
                            </Column> */}
                          </Columns>
                        </div>
                      );
                    })}
                  </div>
                );
              })}

            <h3
              className="title has-text-black"
              style={{ marginTop: "10px", marginBottom: "0px" }}
            >
              TOTALE
            </h3>
            <Columns>
              <Column isSize="1/4">
                <Label>Prodotto</Label>
              </Column>
              <Column>
                <Label>Quantità Ordine</Label>
              </Column>
              <Column>
                <Label>Quantità In Magazzino</Label>
              </Column>
              <Column>
                <Label>Quantità da Ordinare</Label>
              </Column>
              <Column>
                <Label>Nostra Fornitura</Label>
              </Column>
              <Column>
                <Label>Fornitore</Label>
              </Column>
            </Columns>
            {!!doc.elements &&
              doc.elements.map(s => {
                return (
                  <div
                    key={s.uid}
                    className={
                      s.nostraFornitura == "Si"
                        ? "nostra-fornitura-row"
                        : "column-rows"
                    }
                    style={{
                      borderBottom: "solid 3px #cac2c2",
                      marginTop: "10px"
                    }}
                  >
                    <Columns>
                      <Column isSize="1/4">
                        <Label>{s.name}</Label>
                        <Help>
                          {!!s.ordineMinimo &&
                            " ordine minimo " + s.ordineMinimo}{" "}
                        </Help>
                      </Column>
                      <Column>
                        <Label>{Utils.mlround(s.quantityInOrder)}</Label>
                        {s.quantityInOrder != s.quantityWithoutSfrido && (
                          <Label style={{ textDecoration: "line-through" }}>
                            {Utils.mlround(
                              s.quantityInOrder - s.quantityWithoutSfrido
                            )}
                          </Label>
                        )}
                      </Column>
                      <Column>
                        <Label>
                          {!!s.quantityAvailable &&
                            s.quantityAvailable.toFixed(0)}
                        </Label>
                      </Column>
                      <Column>
                        <Label
                          className={s.quantityToOrder > 0 && "has-text-red"}
                        >
                          {s.quantityToOrder.toFixed(0)}
                        </Label>
                      </Column>
                      <Column>
                        <Label>{s.nostraFornitura}</Label>
                      </Column>
                      <Column>
                        <Label>{s.fornitore}</Label>
                      </Column>
                    </Columns>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }

  renderMultipleValues(f) {
    const { Header, accessor, subaccessor, values, modifiable } = f;

    const { doc, modify } = this.state;

    console.log("aa values", accessor, values);
    return (
      <div>
        <Label>{Header}</Label>

        <Dropdown isActive={this.state.multipleValues[accessor]}>
          <DropdownTrigger>
            <Button
              className={
                modify || modifiable
                  ? "dropdown-warehouse modify"
                  : "dropdown-warehouse"
              }
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                if (modify || modifiable) {
                  const multipleValues = [];
                  multipleValues[accessor] = !this.state.multipleValues[
                    accessor
                  ];
                  this.setState({
                    multipleValues
                  });
                }
              }}
            >
              {!!doc && !!doc[accessor] && subaccessor
                ? doc[accessor][subaccessor]
                : doc[accessor]}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {!!values &&
                values.map((e, idx) => {
                  const value = !!subaccessor ? e[subaccessor] : e;

                  return (
                    <DropdownItem
                      key={idx}
                      className="ta-l"
                      onClick={() => this.setMultipleValue(e, accessor)}
                    >
                      {value}
                    </DropdownItem>
                  );
                })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  setMultipleValue(address, accessor) {
    console.log(address);
    const { doc } = this.state;
    doc[accessor] = address;

    const multipleValues = [];
    multipleValues[accessor] = false;
    this.setState({
      doc,
      multipleValues
    });
  }

  renderInputField(f) {
    const { Header, accessor, type, blocked, modifiable } = f;

    const p = this.state.doc;
    const warning = type === "number" && p[accessor] < 0;
    const modify = this.state.modify && !blocked;
    return (
      <div key={Header}>
        <Label>{Header}</Label>
        <Field>
          <Input
            className={
              modify || modifiable
                ? warning
                  ? "has-text-red"
                  : ""
                : warning
                ? "has-text-red blocked"
                : "blocked"
            }
            name={accessor}
            type={type}
            placeholder=""
            value={p[accessor]}
            onChange={e => {
              this.handleChange(
                type === "number" ? parseFloat(e.target.value) : e.target.value,
                accessor
              );
            }}
          />
        </Field>
      </div>
    );
  }

  renderTextField(f) {
    const { Header, accessor, type, blocked } = f;

    const p = this.state.doc;
    const warning = type === "number" && p[accessor] < 0;
    const modify = this.state.modify && !blocked;
    return (
      <div key={Header}>
        <Label>{Header}</Label>
        <Field>
          <TextArea
            className={
              modify
                ? warning
                  ? "has-text-red"
                  : ""
                : warning
                ? "has-text-red blocked"
                : "blocked"
            }
            name={accessor}
            type={type}
            placeholder=""
            value={p[accessor]}
            onChange={e => {
              this.handleChange(
                type === "number" ? parseFloat(e.target.value) : e.target.value,
                accessor
              );
            }}
          />
        </Field>
      </div>
    );
  }

  renderField(field) {
    switch (field.type) {
      case "multiValues":
        return this.renderMultipleValues(field);
      case "textField":
        return this.renderTextField(field);
      default:
        return this.renderInputField(field);
    }
  }

  renderFields(fields) {
    return (
      <form name="doc" onChange={() => this.resetForm}>
        {fields.map((f, idx) => {
          return <div key={idx}>{this.renderField(f)}</div>;
        })}
      </form>
    );
  }

  handleChange(val, field) {
    var data = {};
    data[field] = val;
    this.setState({
      ...this.state,
      doc: {
        ...this.state.doc,
        ...data
      }
    });
  }

  render() {
    const p = this.state.doc;
    const { type, documentoChiuso } = this.state;
    let title = "Documento di produzione";

    if (!p) return <Loader />;

    if (!!p.codeNumber) title = title + " - " + p.codeNumber;

    const values = [
      DocumentStatus.IN_LAVORAZIONE,
      DocumentStatus.IN_PRODUZIONE,
      DocumentStatus.SPEDITO,
      DocumentStatus.CONSEGNATO
    ];
    if (this.props.adding) values.push(DocumentStatus.SOSPESO);

    const fields = [
      {
        Header: "Stato",
        accessor: "status",
        type: "multiValues",
        values: values,
        modifiable: true
      },
      {
        Header: "Data Alert",
        accessor: "dataAlert",
        type: "date"
      },
      {
        Header: "Note",
        accessor: "note",
        type: "textField"
      }
    ];
    return (
      <div>
        <Columns isMobile>
          <Column>
            <Button
              onClick={() => this.props.history.goBack()}
              className="title user-area-title vw-tablet"
            >
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
              Torna indietro
            </Button>
          </Column>
        </Columns>

        <Container className="generic-doc">
          <h1 className="title has-text-black">{title}</h1>
          {!!p.dateFormatted && (
            <h2 className="subtitle has-text-black">{p.dateFormatted}</h2>
          )}
          {!!p.status && (
            <h2 className="subtitle has-text-black">Stato: {p.status}</h2>
          )}
          {!!documentoChiuso && (
            <Button className="documento-chiuso">DOCUMENTO CHIUSO</Button>
          )}
          {this.renderFields(fields)}

          {this.renderProducts()}

          {this.renderResults()}

          {!this.state.modify && type === "preventivo" && (
            <Button
              className=" is-primary is-fullwidth mt-10 mb-40"
              onClick={() => this.doModify()}
            >
              MODIFICA
            </Button>
          )}
          {this.props.update && (
            <Button
              className=" is-primary is-fullwidth mt-10 mb-40"
              onClick={() => this.doUpdateStatus()}
            >
              AGGIORNA STATO
            </Button>
          )}
          {this.state.modify && (
            <div>
              <Button
                className=" is-primary is-fullwidth mt-10 mb-40"
                onClick={() => this.submit()}
              >
                Salva
              </Button>
              <Button
                className="ml-25 is-primary is-fullwidth mt-10 mb-40"
                onClick={() => this.doModify()}
              >
                Cancella
              </Button>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default withAdminCtx(
  withModalsCtx(withRouter(withWareCtx(DProduzioneDoc)))
);
