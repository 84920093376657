import React, { Component } from "react";
import { Container, Columns, Column } from "bloomer";
import { withAdminCtx } from "../contexts/AdminContext";
import Loader from "../components/Loader";
import MyTable from "../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { Button } from "bloomer/lib/elements/Button";
import { withWareCtx } from "../contexts/WarehouseContext";
import firebase from "firebase/compat/app";

import { withModalsCtx } from "../contexts/ModalsContext";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController
} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/it";
import AdminNavbar from "../components/admin/AdminNavbar";
import DocumentType from "../enums/DocumentType";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentStatus from "../enums/DocumentStatus";
import StorageService from "../services/StorageService";

var moment = require("moment");

class DProduzione extends Component {
  state = {
    date: [],
    calendarShowing: false,
    filteredDocs: [],
    filterValue: "Tutti",
    startDate: moment(new Date()).add(-30, "days"),
    endDate: moment(new Date())
  };

  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.setDocs = this.setDocs.bind(this);
  }

  setDocs(props) {
    const docs = props.admin.documents.filter(
      d =>
        d.type === DocumentType.USCITA_PROD ||
        d.type === DocumentType.USCITA_MANUALE ||
        d.type === DocumentType.ENTRATA_PROD
    );
    docs.forEach(d => {
      const today = moment(new Date());
      const dataAlert = moment(d.dataAlert);
      if (dataAlert < today) d.alert = true;
    });
    this.setState({ docs, filteredDocs: docs });
  }
  componentDidMount() {
    this.setDocs(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setDocs(nextProps);
  }

  async onPDFClicked(p) {
    const url = await StorageService.getPDF(p.uid, p.pdfFilename, "pdf");
    console.log("AA url", url);
    window.open(url, "_blank");
  }

  onModify(p, id, e) {
    if (e.metaKey) {
      if (p.type === DocumentType.ENTRATA_PROD)
        window.open("produzioneEntrataDetails/" + p.uid, "_blank");
      else if (p.type === DocumentType.USCITA_MANUALE)
        window.open("produzioneUscitaDetails/" + p.uid, "_blank");
      else window.open("produzioneDetails/" + p.uid, "_blank");

      return;
    }

    if (p.type === DocumentType.ENTRATA_PROD)
      this.props.history.push("produzioneEntrataDetails/" + p.uid);
    else if (p.type === DocumentType.USCITA_MANUALE)
      this.props.history.push("produzioneUscitaDetails/" + p.uid);
    else this.props.history.push("produzioneDetails/" + p.uid);
  }

  //   onPrint(p) {
  //     const w = window.open("/printDoc/" + p.uid);
  //   }

  onDelete(p) {
    this.props.modals.addModal(
      "Confermi eliminazione " + p.codeNumber,
      <div />,
      [
        {
          label: "Esci",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "Elimina",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDelete(p);
        } else {
          this.props.history.goBack();
        }
      }
    );
  }

  async confirmDelete(p) {
    const productSnap = await firebase
      .firestore()
      .collection("documents")
      .doc(p.uid)
      .delete();

    if (p.status != DocumentStatus.SOSPESO) {
      var changeProdQt = firebase
        .functions()
        .httpsCallable("changeProduzioneQuantity");
      changeProdQt({ document: p, removing: true }).then(function(result) {});
    }
    // this.props.admin.changeProduzioneQuantity(p, true);
  }

  addDocument() {
    this.props.history.push("produzioneAdd");
  }

  addEntrataDocument() {
    this.props.history.push("produzioneEntrataAdd");
  }

  addUscitaDocument() {
    this.props.history.push("produzioneUscitaAdd");
  }

  applyFilters() {
    const { filterValue, startDate, endDate } = this.state;

    let docs;
    if (filterValue === "Tutti") docs = this.state.docs;
    else {
      docs = this.state.docs.filter(d =>
        filterValue === "Uscite"
          ? d.type === DocumentType.USCITA_PROD
          : filterValue === "Uscite Manuali"
          ? d.type === DocumentType.USCITA_MANUALE
          : filterValue === "Entrate"
          ? d.type === DocumentType.ENTRATA_PROD
          : filterValue === "In sospeso"
          ? d.status === DocumentStatus.SOSPESO
          : filterValue === "In lavorazione"
          ? d.status === DocumentStatus.IN_LAVORAZIONE
          : filterValue === "In produzione"
          ? d.status === DocumentStatus.IN_PRODUZIONE
          : filterValue === "Spedito"
          ? d.status === DocumentStatus.SPEDITO
          : d.status === DocumentStatus.CONSEGNATO
      );
    }

    docs = docs.filter(
      d =>
        d.dateTimestamp.seconds >= startDate.valueOf() / 1000 &&
        d.dateTimestamp.seconds <= endDate.valueOf() / 1000
    );

    this.setState({ filteredDocs: docs });
  }

  onDatesChange(startDate, endDate) {
    this.setState({ startDate, endDate });

    if (!startDate || !endDate) return;

    startDate = startDate.utcOffset(0);
    endDate = endDate.utcOffset(0);
    endDate.set({ hour: 24, minute: 0, second: 0, millisecond: 0 });
    startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    this.setState({ startDate, endDate }, () => this.applyFilters());
  }

  setFilter(val) {
    this.setState({ filterValue: val, filterActive: false }, () =>
      this.applyFilters()
    );
  }

  renderFilter() {
    const filters = [
      "Tutti",
      "Uscite",
      "Uscite Manuali",
      "Entrate",
      "In lavorazione",
      "In produzione",
      "Spedito",
      "Consegnato",
      "In sospeso"
    ];
    return (
      <div>
        <Dropdown isActive={this.state.filterActive}>
          <DropdownTrigger>
            <Button
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                this.setState({
                  filterActive: !this.state.filterActive
                });
              }}
            >
              {this.state.filterValue}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {filters.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() => this.setFilter(e)}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  render() {
    const { filteredDocs } = this.state;

    if (this.props.admin.documents.loading || !filteredDocs) return <Loader />;

    const columns = [
      {
        Header: "Numero",
        accessor: "codeNumber",
        type: "status"
      },
      {
        Header: "Data",
        accessor: "date"
      },
      {
        Header: "Stato",
        accessor: "status"
      },
      {
        Header: "Codice",
        accessor: "code"
        // maxWidth: 100,
        // style: { textAlign: "center" },
        // styleWarning: { color: "red", textAlign: "center" }
      },
      {
        Header: "Tipo",
        accessor: "type",
        maxWidth: 100,
        style: { textAlign: "center" },
        styleWarning: { color: "red", textAlign: "center" }
      },
      {
        Header: "Creato da",
        accessor: "creatorName"
      }
    ];

    return (
      <section>
        <AdminNavbar />
        <Container>
          <Columns>
            <Column>
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                isOutsideRange={() => false}
                displayFormat="DD MMM YYYY"
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) =>
                  this.onDatesChange(startDate, endDate)
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                startDatePlaceholderText={"Data inizio"}
                endDatePlaceholderText={"Data fine"}
              />
            </Column>
          </Columns>
          <Columns className="admin-header mt-5">
            <Column>
              <h2 className="title has-text-black ">Documenti di produzione</h2>
            </Column>
            <Column>{this.renderFilter()}</Column>
            <Column>
              <Button
                className="is-primary ml-15 mt-15"
                onClick={() => this.addDocument()}
              >
                Ordina prodotti
              </Button>
              <Button
                className="is-primary ml-15 mt-15"
                onClick={() => this.addEntrataDocument()}
              >
                Registra entrata
              </Button>
              <Button
                className="is-primary ml-15 mt-15"
                onClick={() => this.addUscitaDocument()}
              >
                Registra uscita
              </Button>
            </Column>
          </Columns>
          <div className="admin-panels">
            <MyTable
              data={filteredDocs}
              columns={columns}
              onModify={(u, id, e) => this.onModify(u, id, e)}
              onDelete={u => this.onDelete(u)}
              onPDFClicked={u => this.onPDFClicked(u)}
              {...this.props}
            />
          </div>
        </Container>
      </section>
    );
  }
}

export default withModalsCtx(
  withRouter(withAdminCtx(withWareCtx(DProduzione)))
);
