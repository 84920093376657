import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import AdminNavbar from "./components/admin/AdminNavbar";
import DDashboard from "./views/desktop/DDashboard";
import DProducts from "./views/desktop/DProducts";
import DProductDetails from "./views/desktop/DProductDetails";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import AppPrivateRoute from "./AppPrivateRoute";
import { withWareCtx } from "./contexts/WarehouseContext";
import DAccessNotGranted from "./views/DAccessNotGranted";
import firebase from "firebase/compat/app";

import DElements from "./views/desktop/DElements";
import DUsers from "./views/DUsers";
import DElementDetails from "./views/desktop/DElementDetails";
import ScrollToTop from "./ScrollToTop";
import DEntrate from "./views/DEntrate";
import DUscite from "./views/DUscite";
import DDocumentDetail from "./views/desktop/DDocumentDetail";
import DProductHistory from "./views/desktop/DProductHistory";
import DContacts from "./views/DContacts";
import DContactDetails from "./views/desktop/DContactDetails";
import DPrintedDoc from "./components/DPrintedDoc";
import DProduzioneDoc from "./components/DProduzioneDoc";
import DProduzione from "./views/DProduzione";
import DElementHistory from "./views/desktop/DElementHistory";
import DocumentType from "./enums/DocumentType";
import DProduzioneEntrataDoc from "./components/DProduzioneEntrataDoc";
import DProductReintegra from "./views/desktop/DProductReintegra";
import DProduzioneUscitaDoc from "./components/DProduzioneUscitaDoc";
import DReportsKPI from "./views/DReportsKPI";
import DReportGiacenze from "./views/DReportGiacenze";
import DReportCaricamento from "./views/DReportCaricamento";

function AppNavRoute() {
  return (
    <Route
      exact
      path={`/products`}
      render={props => <DProducts {...props} />}
    />
  );
}

class AppRouter extends Component {
  state = {
    accessGranted: true,
    user: null
  };

  componentDidMount() {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async user => {
        if (!user) {
          console.log("CC go back user", user);
          this.setState({ user });
        } else {
          console.log("aa ware context user", user.uid);

          let userSnap = firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .get()
            .then(user => {
              const accessGranted = user.exists && user.data().enabled;
              this.setState({ accessGranted, user });
            });
        }
      });
    this.setState({ unregisterAuthObserver });
  }

  render() {
    if (!this.state.accessGranted) return <DAccessNotGranted />;

    if (!this.state.user) return <DDashboard />;
    if (
      firebase.auth().currentUser &&
      (!this.props.ware || !this.props.ware.accessGranted)
    )
      return <DAccessNotGranted />;

    const NoAdminContainer = () => {
      return (
        <>
          <Route
            exact
            path={`/printDoc/:pUid`}
            component={DPrintedDoc}

            // render={props => <DPrintedDoc {...props} />}
          />
        </>
      );
    };
    const AdminContainer = () => {
      return (
        <>
          <AdminNavbar />

          <Route exact path={`/`} render={props => <DDashboard {...props} />} />
          <Route
            exact
            path={`/products`}
            render={props => <DProducts {...props} />}
          />
          <Route
            exact
            path={`/productDetails/:pUid`}
            render={props => <DProductDetails {...props} />}
          />
          <Route
            exact
            path={`/productAdd`}
            render={props => <DProductDetails adding={true} {...props} />}
          />
          <Route
            exact
            path={`/productHistory/:pUid`}
            render={props => <DProductHistory {...props} />}
          />
          <Route
            exact
            path={`/productReintegra/:pUid`}
            render={props => <DProductReintegra {...props} />}
          />
          <Route
            exact
            path={`/productMigra/:pUid`}
            render={props => <DProductReintegra migra {...props} />}
          />
          <Route
            exact
            path={`/elements`}
            render={props => <DElements {...props} />}
          />
          <Route
            exact
            path={`/elementDetails/:pUid`}
            render={props => <DElementDetails {...props} />}
          />
          <Route
            exact
            path={`/elementAdd`}
            render={props => <DElementDetails adding={true} {...props} />}
          />
          <Route
            exact
            path={`/elementHistory/:pUid`}
            render={props => <DElementHistory {...props} />}
          />
          <Route
            exact
            path={`/users`}
            render={props => <DUsers {...props} />}
          />
          <Route
            exact
            path={`/entrata`}
            render={props => <DEntrate {...props} />}
          />
          <Route
            exact
            path={`/uscita`}
            render={props => <DUscite {...props} />}
          />
          <Route
            exact
            path={`/documentDetails/:pUid/:pType`}
            render={props => <DDocumentDetail {...props} />}
          />
          <Route
            exact
            path={`/preventivo`}
            render={props => (
              <DDocumentDetail
                adding={true}
                type={DocumentType.PREVENTIVO}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`/entrataAdd`}
            render={props => (
              <DDocumentDetail
                adding={true}
                type={DocumentType.ENTRATA}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`/uscitaAdd`}
            render={props => (
              <DDocumentDetail
                adding={true}
                type={DocumentType.DISTINTA}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`/produzione`}
            render={props => <DProduzione {...props} />}
          />
          <Route
            exact
            path={`/produzioneAdd`}
            render={props => <DProduzioneDoc adding={true} {...props} />}
          />
          <Route
            exact
            path={`/produzioneDetails/:pUid`}
            render={props => <DProduzioneDoc update {...props} />}
          />
          <Route
            exact
            path={`/produzioneEntrataAdd`}
            render={props => (
              <DProduzioneEntrataDoc
                adding={true}
                type={DocumentType.ENTRATA_PROD}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`/produzioneUscitaAdd`}
            render={props => (
              <DProduzioneUscitaDoc
                adding={true}
                type={DocumentType.USCITA_MANUALE}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`/produzioneEntrataDetails/:pUid`}
            render={props => <DProduzioneEntrataDoc {...props} />}
          />
          <Route
            exact
            path={`/produzioneUscitaDetails/:pUid`}
            render={props => <DProduzioneUscitaDoc {...props} />}
          />
          <Route
            exact
            path={`/anagrafica`}
            render={props => <DContacts {...props} />}
          />
          <Route
            exact
            path={`/contactAdd`}
            render={props => <DContactDetails adding={true} {...props} />}
          />
          <Route
            exact
            path={`/contactDetails/:pUid`}
            render={props => <DContactDetails {...props} />}
          />
          <Route
            exact
            path={`/reports`}
            render={props => <DReportsKPI {...props} />}
          />
          <Route
            exact
            path={`/giacenze`}
            render={props => <DReportGiacenze {...props} />}
          />
          <Route
            exact
            path={`/caricamento`}
            render={props => <DReportCaricamento {...props} />}
          />
        </>
      );
    };

    return (
      <div>
        <Router>
          <>
            <div className="admin-panel">
              <TransitionGroup>
                <CSSTransition
                  key={this.props.location.key}
                  classNames="fade"
                  timeout={80}
                >
                  <Switch location={this.props.location}>
                    {/* <ScrollToTop location={this.props.location}> */}
                    {/* <Route
                      exact
                      path="/printDoc/:pUid"
                      component={NoAdminContainer}
                    />
                    <Route component={AdminContainer} /> */}
                    <Route
                      exact
                      path={`/printDoc/:pUid`}
                      render={props => <DPrintedDoc {...props} />}
                    />
                    <>
                      <AdminNavbar />

                      <Route
                        exact
                        path={`/`}
                        render={props => <DDashboard {...props} />}
                      />
                      <Route
                        exact
                        path={`/products`}
                        render={props => <DProducts {...props} />}
                      />
                      <Route
                        exact
                        path={`/productDetails/:pUid`}
                        render={props => <DProductDetails {...props} />}
                      />
                      <Route
                        exact
                        path={`/productAdd`}
                        render={props => (
                          <DProductDetails adding={true} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path={`/productHistory/:pUid`}
                        render={props => <DProductHistory {...props} />}
                      />
                      <Route
                        exact
                        path={`/productReintegra/:pUid`}
                        render={props => <DProductReintegra {...props} />}
                      />
                      <Route
                        exact
                        path={`/productMigra/:pUid`}
                        render={props => <DProductReintegra migra {...props} />}
                      />
                      <Route
                        exact
                        path={`/elements`}
                        render={props => <DElements {...props} />}
                      />
                      <Route
                        exact
                        path={`/elementDetails/:pUid`}
                        render={props => <DElementDetails {...props} />}
                      />
                      <Route
                        exact
                        path={`/elementAdd`}
                        render={props => (
                          <DElementDetails adding={true} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path={`/elementHistory/:pUid`}
                        render={props => <DElementHistory {...props} />}
                      />
                      <Route
                        exact
                        path={`/users`}
                        render={props => <DUsers {...props} />}
                      />
                      <Route
                        exact
                        path={`/entrata`}
                        render={props => <DEntrate {...props} />}
                      />
                      <Route
                        exact
                        path={`/uscita`}
                        render={props => <DUscite {...props} />}
                      />
                      <Route
                        exact
                        path={`/documentDetails/:pUid/:pType`}
                        render={props => <DDocumentDetail {...props} />}
                      />
                      <Route
                        exact
                        path={`/preventivo`}
                        render={props => (
                          <DDocumentDetail
                            adding={true}
                            type={DocumentType.PREVENTIVO}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/entrataAdd`}
                        render={props => (
                          <DDocumentDetail
                            adding={true}
                            type={DocumentType.ENTRATA}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/uscitaAdd`}
                        render={props => (
                          <DDocumentDetail
                            adding={true}
                            type={DocumentType.DISTINTA}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/produzione`}
                        render={props => <DProduzione {...props} />}
                      />
                      <Route
                        exact
                        path={`/produzioneAdd`}
                        render={props => (
                          <DProduzioneDoc adding={true} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path={`/produzioneDetails/:pUid`}
                        render={props => <DProduzioneDoc update {...props} />}
                      />
                      <Route
                        exact
                        path={`/produzioneEntrataAdd`}
                        render={props => (
                          <DProduzioneEntrataDoc
                            adding={true}
                            type={DocumentType.ENTRATA_PROD}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/produzioneUscitaAdd`}
                        render={props => (
                          <DProduzioneUscitaDoc
                            adding={true}
                            type={DocumentType.USCITA_MANUALE}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/produzioneEntrataDetails/:pUid`}
                        render={props => <DProduzioneEntrataDoc {...props} />}
                      />
                      <Route
                        exact
                        path={`/produzioneUscitaDetails/:pUid`}
                        render={props => <DProduzioneUscitaDoc {...props} />}
                      />
                      <Route
                        exact
                        path={`/anagrafica`}
                        render={props => <DContacts {...props} />}
                      />
                      <Route
                        exact
                        path={`/contactAdd`}
                        render={props => (
                          <DContactDetails adding={true} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path={`/contactDetails/:pUid`}
                        render={props => <DContactDetails {...props} />}
                      />
                      <Route
                        exact
                        path={`/reports`}
                        render={props => <DReportsKPI {...props} />}
                      />
                      <Route
                        exact
                        path={`/giacenze`}
                        render={props => <DReportGiacenze {...props} />}
                      />
                      <Route
                        exact
                        path={`/caricamento`}
                        render={props => <DReportCaricamento {...props} />}
                      />
                    </>
                    {/* </ScrollToTop> */}
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </>
        </Router>
      </div>
    );
  }
}

export default withRouter(withWareCtx(AppRouter));
