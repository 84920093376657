import React, { Component } from "react";
import { withGiacenzeCtx } from "../../contexts/GiacenzeContext";
import { withAdminCtx } from "../../contexts/AdminContext";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Flex,
  Divider
} from "@chakra-ui/react";
import { withRouter } from "react-router";
var moment = require("moment");

class GiacenzeModal extends Component {
  state = { openModal: false, loaded: false };
  componentDidMount() {
    console.log("AA GIACENE DID MOUNT");
    this.setState({
      timer: setTimeout(async () => {
        this.loadDataGiacenze(this.props);
      })
    });
  }

  componentWillReceiveProps(p) {
    console.log(
      "AA Received props",
      this.state.loading,
      p.admin.products?.products.length
    );
    if (this.state.loaded) return;

    if (!this.state.loading && p.admin.products?.products.length > 0) {
      console.log(
        "aa giacenze loading from props",
        p.admin.products?.products.length
      );

      this.setState({ loading: true });
      this.loadDataGiacenze(p);
    } else if (this.state.loading && !p.giacenze.loading) {
      const esaurimento = [];
      p.giacenze.productsArray.forEach(p => {
        if (p.esaurimentoDays <= 150) esaurimento.push(p);
      });

      esaurimento.sort((a, b) => {
        if (parseInt(a.esaurimentoDays) > parseInt(b.esaurimentoDays)) return 1;
        if (parseInt(a.esaurimentoDays) < parseInt(b.esaurimentoDays))
          return -1;
        return 0;
      });
      console.log("AA giacense calcolate", esaurimento, p.giacenze);
      this.props.onLoadGiacenze(esaurimento.length);
      this.setState({
        esaurimento: esaurimento,
        loading: false,
        loaded: true,
        dateChanged: false,
        openModal: esaurimento.length > 0
      });
    }
  }

  async loadDataGiacenze(p) {
    if (!p.ware.notificaGiacenze) return;

    await p.giacenze.loadGiacenze(
      p.admin.products,
      moment(new Date()).add(-30, "days"),
      moment(new Date())
    );
  }

  renderModalGiacenze() {
    return (
      <Modal
        size="3xl"
        isOpen={this.state.openModal}
        onClose={() => {
          this.setState({ openModal: false });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Giacenze in scandenza (150gg)</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex key={"title"} gap={2} color="#61737b">
              <Heading fontSize="sm" w={"150px"}>
                SKU
              </Heading>
              <Heading fontSize="sm" minW={"400px"} textAlign="left">
                NOME
              </Heading>
              <Heading fontSize="sm" minW={"20"} textAlign="center">
                ESAURIMENTO
              </Heading>
            </Flex>
            <Divider mb={3} opacity="1" color="#61737b" />
            {this.state.esaurimento?.map(e => {
              return (
                <Flex key={e.sku} gap={2}>
                  <Heading fontSize="xs" w={"150px"}>
                    {e.sku}
                  </Heading>
                  <Heading fontSize="xs" minW={"400px"} textAlign="left">
                    {e.name}
                  </Heading>
                  <Heading fontSize="xs" minW={"20"} textAlign="center">
                    {e.esaurimentoDays}
                  </Heading>
                </Flex>
              );
            })}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              onClick={() => {
                this.props.history.push("/giacenze");
                this.setState({ openModal: false });
              }}
            >
              Vedi Report
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  render() {
    return this.renderModalGiacenze();
  }
}
export default withAdminCtx(withGiacenzeCtx(withRouter(GiacenzeModal)));
