import React, { Component } from "react";
import { withRouter } from "react-router";
import { withModalsCtx } from "../../contexts/ModalsContext";
import DGenericProduct from "../../components/DGenericProduct";
import { withAdminCtx } from "../../contexts/AdminContext";
import { withWareCtx } from "../../contexts/WarehouseContext";
import AdminNavbar from "../../components/admin/AdminNavbar";

class DProductDetails extends Component {
  // onSave() {
  //   console.log("AA onSave");
  //   this.props.history.push("/products");
  // }

  render() {
    const fields = [
      {
        Header: "Nome",
        accessor: "name",
        type: "text"
      },
      {
        Header: "Brand",
        accessor: "brand",
        type: "multiValues",
        values: ["-", " T-REX INTEGRATORI", "NUTRACLE", "SUPRAPET", "MYLAB"]
      },
      {
        Header: "Tipologia",
        accessor: "tipologia",
        type: "multiValues",
        values: [
          "-",
          "Materie Prime",
          "Packaging",
          "Prodotto finito",
          "Combo prodotti",
          "Imballaggio",
          "Ufficio"
        ]
      },
      {
        Header: "SKU",
        accessor: "sku",
        type: "text",
        required: true
      },
      {
        Header: "Barcode",
        accessor: "barcode",
        type: "text"
      },
      {
        Header: "Giacenza",
        accessor: "quantity",
        type: "number",
        blocked: !this.props.ware.isAdmin
      },
      {
        Header: "Danneggiati",
        accessor: "danneggiati",
        type: "number",
        blocked: !this.props.ware.isAdmin
      },
      // {
      //   Header: "Prezzo Vendita",
      //   accessor: "price_sell",
      //   type: "number"
      // },
      // {
      //   Header: "Prezzo Acquisto",
      //   accessor: "price_buy",
      //   type: "number"
      // },
      {
        Header: "Produttore",
        accessor: "fornitore",
        type: "search",
        list: [...new Set(this.props.admin.fornitori.map(f => f.name))]
      },
      {
        Header: "Unità di misura",
        accessor: "unitaMisura",
        type: "text"
      },
      {
        Header: "Formato",
        accessor: "formato",
        type: "number"
      },
      // {
      //   Header: "Dose giornaliera",
      //   accessor: "doseGiornaliera",
      //   type: "number"
      // },
      // {
      //   Header: "Venduti 30gg (media annua)",
      //   accessor: "venduti_30",
      //   type: "number"
      // },
      // {
      //   Header: "Numero alert magazzino",
      //   accessor: "alert",
      //   type: "number"
      // },
      {
        Header: "Creato da",
        accessor: "creatorName",
        blocked: true
      }
    ];

    console.log("BB location ", this.props.location);
    return (
      <div>
        <DGenericProduct
          table="products"
          uid={this.props.uid || this.props.match.params.pUid}
          fields={fields}
          subcollection="elements"
          onSave={() => this.props.onSave()}
          adding={this.props.adding}
          lotti
          codiciFigli
          canBeModified={this.props.ware.isCreator}
          {...this.props}
        />
      </div>
    );
  }
}

export default withWareCtx(
  withAdminCtx(withModalsCtx(withRouter(DProductDetails)))
);
