import React, { Component } from "react";
import { Container } from "bloomer";
import { withAdminCtx } from "../../contexts/AdminContext";
import Loader from "../../components/Loader";
import MyTable from "../../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { withWareCtx } from "../../contexts/WarehouseContext";
import firebase, { firestore } from "firebase/compat/app";
import { withModalsCtx } from "../../contexts/ModalsContext";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportToCsv } from "export-to-csv-file";
import Utils from "../../Utils";
import Decimal from "decimal.js";
import DButtonCSV from "../../components/DButtonCSV";
import {
  Heading,
  Button,
  SimpleGrid,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton
} from "@chakra-ui/react";
import { ExporterButton } from "../../components/DExporterButton";
import DProductDetails from "./DProductDetails";
import DProductHistory from "./DProductHistory";
import ModalUI from "../../modals/ModalUI";

class DProducts extends Component {
  state = {
    filteredProducts: [],
    filterValue: "Tutti",
    filterTipologiaValue: "Tutti",
    filterInProduzione: "In produzione",
    loading: false
  };

  componentDidMount() {
    console.log("AA didmount");
    this.setState({
      timer: setTimeout(async () => {
        this.setDocs(this.props);
      })
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log("AA componentWillReceiveProps");
    this.setDocs(nextProps);
  }

  setDocs(props) {
    const products = props.admin.products.products;
    this.setState(
      {
        products,
        filteredProducts: products,
        filteredData: products
      },
      () => this.applyFilters()
    );
  }

  onModify(p, id, e, searchText) {
    if (e.metaKey) {
      window.open("productDetails/" + p.uid, "_blank");
      return;
    }

    this.setState({ openProduct: p.uid });
    // console.log("aa modify", p);
    // this.props.history.push({
    //   pathname: "/productDetails/" + p.uid,
    //   goBack: "/products/?search=" + searchText,
    //   state: searchText
    // });
  }

  onGiacenzaDetails(p) {
    this.setState({ openProductStorico: p.uid });

    // this.props.history.push("productHistory/" + p.uid);
  }

  onReintegraProdotto(p) {
    this.props.history.push("productReintegra/" + p.uid);
  }

  onMigraProdotto(p) {
    this.props.history.push("productMigra/" + p.uid);
  }

  addProduct() {
    // this.props.history.push("productAdd");
    this.setState({ openProductAdd: true });
  }

  onModifyField(p, target, index, type) {
    const { products } = this.state;

    products.forEach(prod => {
      if (prod.uid === p.uid) {
        if (!prod["old" + target.name]) {
          if (type === "currency")
            prod["old" + target.name] = prod[target.name] || new Decimal(0);
          else prod["old" + target.name] = prod[target.name] || "";
        }

        prod[target.name] =
          type === "currency" ? parseFloat(target.value) : target.value;
      }
    });

    this.setState({
      products,
      filteredProducts: products,
      filteredData: products
    });
  }

  revertProducts() {
    const { products } = this.state;
    products.forEach(p => {
      if (!!p.oldprice_buy) {
        p.price_buy = p.oldprice_buy;
        delete p.oldprice_buy;
      }
      if (!!p.oldprice_sell) {
        p.price_sell = p.oldprice_sell;
        delete p.oldprice_sell;
      }
    });

    this.setState({
      products,
      filteredProducts: products,
      filteredData: products
    });
  }

  renderRiepilogo() {
    const { products } = this.state;
    const columns = [
      {
        Header: "Nome",
        accessor: "name",
        style: { maxWidth: "300px" }
      },
      {
        Header: "PREZZO VENDITA (IVA escl.)",
        accessor: "price_sell",
        style: { maxWidth: "150px", textAlign: "center" },
        type: "currency"
      },
      {
        Header: "PREZZO ACQUISTO (IVA escl.)",
        accessor: "price_buy",
        style: { maxWidth: "150px", textAlign: "center" },
        type: "currency"
      },
      {
        Header: "SKU",
        accessor: "sku"
      }
    ];
    const data = [];

    products.forEach(p => {
      if (!!p.oldprice_buy || !!p.oldprice_sell || !!p.oldsku) {
        console.log("AA p", p.name);
        data.push(p);
      }
    });

    console.log("AA data", data);
    return (
      <div className="admin-panels riepilogo-prezzi">
        <MyTable
          data={data}
          columns={columns}
          hideButtons
          hideDelete
          {...this.props}
        />
      </div>
    );
  }

  savePrezzi() {
    const { products } = this.state;
    this.props.modals.addModal(
      "Confermi cambio prodotti ?",
      <div>{this.renderRiepilogo()}</div>,
      [
        {
          label: "Esci",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "SALVA",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmSavePrezzi();
        }
      }
    );
  }

  async confirmSavePrezzi() {
    const { products } = this.state;
    this.setState({ loading: true });
    const promises = [];
    products.forEach(p => {
      promises.push(
        new Promise(async (r, rj) => {
          if (!!p.oldprice_buy || !!p.oldprice_sell || !!p.oldsku) {
            console.log("AA p", p.name);
            await firebase
              .firestore()
              .collection("products")
              .doc(p.uid)
              .update({
                price_buy: p.price_buy || 0,
                price_sell: p.price_sell || 0,
                sku: p.sku || ""
              });
          }
          r(p.uid);
        })
      );
    });
    await Promise.all(promises);
    this.setState({ modifyFields: false, loading: false });
    this.showConfirmMessage();
  }

  showConfirmMessage() {
    this.props.modals.addModal(
      <h1 className="ta-c">Salvataggio effettuato</h1>,
      <div />,
      [
        {
          label: "Esci",
          className: "button is-primary is-fullwidth",
          result: "ok"
        }
      ],
      false,
      result => {}
    );
  }

  onDelete(p) {
    this.props.modals.addModal(
      "Confermi eliminazione " + p.name,
      <div />,
      [
        {
          label: "Esci",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "Elimina",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDelete(p);
        } else {
          this.props.history.goBack();
        }
      }
    );
  }
  onDuplicate(p) {
    this.props.modals.addModal(
      "Confermi duplicazione " + p.name,
      <div />,
      [
        {
          label: "Esci",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "Duplica",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDuplicate(p);
        } else {
          this.props.history.goBack();
        }
      }
    );
  }

  async confirmDuplicate(p) {
    const duplicateProduct = { ...p };
    duplicateProduct.name = p.name + " Copia";
    delete duplicateProduct.uid;
    delete duplicateProduct.lotti;
    // if (!!duplicateProduct.lotti) {
    //   duplicateProduct.lotti.forEach(l => {
    //     l.quantity = 0;
    //   });
    // }

    const productSnap = await firebase
      .firestore()
      .collection("products")
      .add(duplicateProduct);
  }

  async confirmDelete(p) {
    const productSnap = await firebase
      .firestore()
      .collection("products")
      .doc(p.uid)
      .delete();
  }

  applyFilters() {
    const {
      filterValue,
      filterTipologiaValue,
      filterInProduzione
    } = this.state;

    let products;
    if (filterValue === "Tutti" && filterTipologiaValue === "Tutti")
      products = this.state.products;
    else {
      products = this.state.products
        .filter(d =>
          filterValue === "Danneggiati"
            ? d.danneggiati > 0
            : filterValue === "In esaurimento"
            ? d.quantity <= d.alert
            : d
        )
        .filter(
          d =>
            filterTipologiaValue === "Tutti" ||
            d.tipologia === filterTipologiaValue
        );
    }
    console.log("VV filterInProduzione", filterInProduzione);
    products = products.filter(p =>
      filterInProduzione === "In produzione"
        ? p.active === true
        : filterInProduzione !== "Tutti"
        ? p.active === false
        : p
    );

    this.setState({ filteredProducts: products, filteredData: products });
  }

  setFilter(val) {
    this.setState({ filterValue: val, filterActive: false }, () =>
      this.applyFilters()
    );
  }

  setFilterTipologia(val) {
    this.setState(
      { filterTipologiaValue: val, filterTipologiaActive: false },
      () => this.applyFilters()
    );
  }

  setFilterInProduzione(val) {
    this.setState(
      { filterInProduzione: val, filterInProduzioneActive: false },
      () => this.applyFilters()
    );
  }

  renderFilter() {
    const filters = ["Tutti", "Danneggiati", "In esaurimento"];
    return (
      <div>
        <Dropdown isActive={this.state.filterActive}>
          <DropdownTrigger>
            <Button
              className="filter-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                this.setState({
                  filterActive: !this.state.filterActive
                });
              }}
            >
              {this.state.filterValue}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {filters.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() => this.setFilter(e)}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  renderTipologiaFilter() {
    const filters = [
      "Tutti",
      "Materie Prime",
      "Packaging",
      "Prodotto finito",
      "Combo prodotti",
      "Imballaggio",
      "Ufficio"
    ];
    return (
      <div>
        <Dropdown isActive={this.state.filterTipologiaActive}>
          <DropdownTrigger>
            <Button
              className="filter-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                this.setState({
                  filterTipologiaActive: !this.state.filterTipologiaActive
                });
              }}
            >
              {this.state.filterTipologiaValue}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {filters.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() => this.setFilterTipologia(e)}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
  renderInProduzioneFilter() {
    const filters = ["In produzione", "Fuori produzione", "Tutti"];
    return (
      <div>
        <Dropdown isActive={this.state.filterInProduzioneActive}>
          <DropdownTrigger>
            <Button
              className="filter-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                this.setState({
                  filterInProduzioneActive: !this.state.filterInProduzioneActive
                });
              }}
            >
              {this.state.filterInProduzione}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {filters.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() => this.setFilterInProduzione(e)}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  getPrezziColumns() {
    return [
      {
        Header: "Prodotto",
        accessor: "nome_prodotto"
      },
      {
        Header: "SKU",
        accessor: "sku"
      },
      {
        Header: "Prezzo acquisto",
        accessor: "prezzo_acquisto"
      },
      {
        Header: "Prezzo vendita B2b",
        accessor: "prezzo_vendita_b2b"
      }
    ];
  }

  async changeActive(p) {
    console.log("AA Change active", p);
    const products = this.state.filteredProducts;
    const index = products.findIndex(obj => obj.uid == p.uid);

    const state = !products[index].active;
    products[index] = { ...products[index], active: state };
    this.setState({ filteredProducts: products });

    await firebase
      .firestore()
      .collection("products")
      .doc(p.uid)
      .update({
        active: state
      });
  }

  render() {
    const products = this.state.filteredProducts;

    console.log("AA pro ", products);
    console.log("AA location ", this.props.location);
    // if (!products || products.length === 0) return <Loader />;

    const columns = [
      {
        Header: "Nome",
        accessor: "name",
        style: { maxWidth: "300px" }
      },
      {
        Header: "Tipologia",
        accessor: "tipologia",
        style: { textAlign: "center" }
      },
      {
        Header: "Sku",
        accessor: "sku",
        style: { textAlign: "center" }
      },
      {
        Header: "Combo",
        accessor: "combo",
        type: "checkbox",
        style: { textAlign: "center" }
      },
      {
        Header: "Giacenza",
        accessor: "quantity",
        maxWidth: 100,
        style: { textAlign: "center" },
        styleWarning: { color: "red", textAlign: "center" }
      },

      {
        Header: "Prezzo acquisto",
        accessor: "prezzo_acquisto",
        visibility: this.props.ware.canModifyPrices,
        style: { textAlign: "right" }
      },
      {
        Header: "Prezzo vendita B2b",
        accessor: "prezzo_vendita_b2b",
        visibility: this.props.ware.canModifyPrices,
        style: { textAlign: "right" }
      },
      {
        Header: "Attivo",
        accessor: "active",
        type: "switch",
        onChange: id => this.changeActive(id)
      }

      // {
      //   Header: "Danneggiati",
      //   accessor: "danneggiati",
      //   maxWidth: 10,
      //   style: { textAlign: "center" },
      //   styleWarning: { color: "red", textAlign: "center" }
      // },
      // {
      //   Header: "PREZZO VENDITA (IVA escl.)",
      //   accessor: "price_sell",
      //   style: { maxWidth: "150px", textAlign: "center" },
      //   type: "currency"
      // },
      // {
      //   Header: "PREZZO ACQUISTO (IVA escl.)",
      //   accessor: "price_buy",
      //   style: { maxWidth: "150px", textAlign: "center" },
      //   type: "currency"
      // }
      // {
      //   Header: "Venduti 30gg",
      //   accessor: "venduti_30",
      //   style: { maxWidth: "150px", textAlign: "center" },
      //   type: "number"
      // }
    ];

    return (
      <section>
        <Container>
          <Box p={5} bg="#75759047" borderRadius="md">
            <Flex align={"center"} justifyContent="space-between">
              <Heading size="lg">Prodotti</Heading>
              <Flex justify={"end"} gap={5}>
                {this.renderTipologiaFilter()}
                {this.renderInProduzioneFilter()}
              </Flex>
            </Flex>
          </Box>

          <Flex justifyContent="space-between" my={2}>
            {!!this.props.ware.canModifyPrices && (
              <DButtonCSV
                title="CARICA PREZZI"
                columns={this.getPrezziColumns()}
                uid="sku"
                table="products_prices"
              />
            )}

            <SimpleGrid columns={2} spacing={1} w="20%">
              {!!this.props.ware.isCreator && (
                <Button colorScheme={"green"} onClick={() => this.addProduct()}>
                  AGGIUNGI
                </Button>
              )}

              <ExporterButton
                data={products}
                filename="MyLab Products"
                title="Prodotti"
                prepare={o => {
                  let lotti = "";
                  if (!!o.lotti) {
                    o.lotti.forEach(l => {
                      lotti += l.name + " qty " + l.quantity + " - ";
                    });
                  }
                  return {
                    nome: o.name,
                    tipologia: o.tipologia,
                    sku: o.sku,
                    barcode: o.barcode,
                    prezzo_acquisto: o.price_buy,
                    prezzo_vendita: o.price_sell,
                    lotto: lotti,
                    giacenza: o.quantity,
                    danneggiati: o.danneggiati,
                    venduti: o.venduti_30 || ""
                  };
                }}
              />
            </SimpleGrid>
          </Flex>

          {/* <Columns>
                <div className="float-right">
                  {
                    <Button
                      className="is-primary mt-15"
                      onClick={() => {
                        if (this.state.modifyFields) this.revertProducts();
                        this.setState({ modifyFields: !this.state.modifyFields });
                      }}
                    >
                      {this.state.modifyFields ? "CANCELLA" : "MODIFICA"}
                    </Button>
                  }
                  {!!this.props.ware.isCreator && this.state.modifyFields && (
                    <Button className="is-primary ml-15 mt-15" onClick={() => this.savePrezzi()}>
                      SALVA
                    </Button>
                  )}
                </div>
              </Columns> */}

          <div className="admin-panels">
            {this.state.loading ? (
              <Loader />
            ) : (
              <MyTable
                data={products}
                columns={columns}
                onModify={(u, id, e, searchText) =>
                  this.onModify(u, id, e, searchText)
                }
                // onDelete={u => this.onDelete(u)}
                // onDuplicate={u => this.onDuplicate(u)}
                onGiacenzaDetails={u => this.onGiacenzaDetails(u)}
                // onReintegraProdotto={u => this.onReintegraProdotto(u)}
                // onMigraProdotto={u => this.onMigraProdotto(u)}
                cerca={true}
                fieldsModifiable={["price_sell", "price_buy", "sku"]}
                modifyFields={this.state.modifyFields}
                searchFields={["sku", "barcode", "name", "code"]}
                searchGroup={["codiciFigli"]}
                onModifyField={(product, target, index, type) =>
                  this.onModifyField(product, target, index, type)
                }
                hideDelete
                {...this.props}
              />
            )}
          </div>
        </Container>
        <ModalUI
          open={this.state.openProduct}
          children=<DProductDetails
            uid={this.state.openProduct}
            onSave={() => this.setState({ openProduct: null })}
          />
        />
        <ModalUI
          open={this.state.openProductAdd}
          children=<DProductDetails
            adding={true}
            {...this.props}
            onSave={() => this.setState({ openProductAdd: null })}
          />
        />
        <ModalUI
          open={this.state.openProductStorico}
          children=<DProductHistory uid={this.state.openProductStorico} />
        />
      </section>
    );
  }
}

export default withModalsCtx(withRouter(withAdminCtx(withWareCtx(DProducts))));
