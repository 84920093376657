import React, { PureComponent } from "react";
import { Table } from "bloomer";
import Utils from "../../Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import DateUtils from "../../services/DateUtils";
import MultiRender from "../MultiRender";
import {
  faCheckSquare,
  faCheck,
  faSquare,
  faSquareFull,
  faTruck,
  faExclamationTriangle,
  faToggleOn,
  faToggleOff
} from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Switch,
  Text,
  Tooltip,
  VStack,
  Wrap
} from "@chakra-ui/react";
import Loader from "../Loader";
import { ExporterButton } from "../DExporterButton";

export default class MyTable extends PureComponent {
  state = {
    filteredData: [],
    originalData: [],
    sortOnFiled: "",
    invertedSort: false,
    searchText: ""
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log(
  //     "zzz nextState.searchText === this.state.searchText",
  //     nextState.filteredData,
  //     this.state.filteredData
  //   );
  //   if (
  //     nextProps.data.length === this.props.data.length &&
  //     nextState.filteredData === this.state.filteredData &&
  //     nextState.searchText === this.state.searchText &&
  //     nextState.invertedSort === this.state.invertedSort &&
  //     nextState.sortOnFiled === this.state.sortOnFiled
  //   )
  //     return false;
  //   else return true;
  // }

  constructor(props) {
    super(props);
    this.filterProducts = this.filterProducts.bind(this);
  }

  checkSearchFied(value, p) {
    const { searchFields } = this.props;
    const { searchGroup } = this.props;
    let found = false;

    if (!!searchGroup) {
      searchGroup.forEach(g => {
        searchFields.forEach(s => {
          const group = p[g];
          // console.log("aa group", group, p, g);
          if (Array.isArray(group)) {
            group.forEach(gr => {
              if (
                !found &&
                String(!!gr[s] && gr[s].toLowerCase()).includes(
                  value.toLowerCase()
                )
              )
                found = true;
            });
          } else if (!!group) {
            if (
              !found &&
              String(!!group[s] && group[s].toLowerCase()).includes(
                value.toLowerCase()
              )
            )
              found = true;
          }
        });
      });
    }

    if (!!searchFields) {
      searchFields.forEach(s => {
        if (
          !found &&
          String(!!p[s] && p[s].toString().toLowerCase()).includes(
            value.toLowerCase()
          )
        )
          found = true;
      });
    }

    return found;
  }

  filterProducts(value) {
    const { data } = this.props;
    let prod = data;
    prod = prod.filter(p => this.checkSearchFied(value, p));

    this.setState({ filteredData: prod, searchText: value });

    if (this.props.onFilter) this.props.onFilter(prod);
  }

  // getSearchParam() {
  //   var qs = require("qs");

  //   const search = qs.parse(this.props.location.search, {
  //     ignoreQueryPrefix: true
  //   }).search;
  //   return search || "";
  // }

  componentDidMount() {
    const { data } = this.props;
    // const searchText = this.getSearchParam();
    this.setState({ filteredData: data || [] });
  }
  componentWillReceiveProps(p) {
    const { data } = p;

    if (
      this.state.originalData?.length !== data?.length ||
      this.state.originalData?.[0] != data?.[0]
    )
      this.setState({ filteredData: data || [], originalData: data });
  }

  onSort(field) {
    const { filteredData, sortOnFiled, invertedSort } = this.state;

    const val = field.accessor;
    let inverted = !invertedSort && sortOnFiled === val;
    const multiplier = inverted ? -1 : 1;
    filteredData.sort((a, b) => {
      const valA = !!a[val] ? a[val] : "";
      const valB = !!b[val] ? b[val] : "";
      if (valA > valB) return multiplier;
      if (valA < valB) return -1 * multiplier;
      return 0;
    });

    this.setState({ filteredData, sortOnFiled: val, invertedSort: inverted });
  }

  renderAlertLegend() {
    const { columns } = this.props;
    let alert = null;
    columns.forEach(c => {
      if (!!c.alert && !alert) alert = c.alert;
    });
    if (!alert) return;

    return (
      <div className="alert-legend">
        <h1 className="ta-c">{this.props.alertLegend}</h1>
        <Columns>
          <Column>
            <span className="square-alert alert_red" /> &lt; {alert[0].val}
          </Column>
          <Column>
            <span className="square-alert alert_orange" /> &lt; {alert[1].val}
          </Column>
          <Column>
            <span className="square-alert alert_yellow" /> &lt; {alert[2].val}
          </Column>
        </Columns>
      </div>
    );
  }

  renderExportToCSV() {
    const columns = this.props.columns.map(c =>
      c.visibility !== false ? c.accessor : null
    );

    const obj = columns.reduce((acc, curr) => ((acc[curr] = ""), acc), {});
    return (
      <ExporterButton
        data={this.state.filteredData}
        icon={true}
        filename={this.props?.export?.filename || "MyLab Export"}
        title={this.props?.export?.title || "Export"}
        prepare={o => {
          const _obj = {};
          Object.keys(obj).map(k => (_obj[k] = o[k]));
          return _obj;
        }}
      />
    );
  }

  renderSearch() {
    const { searchFields } = this.props;

    return (
      <>
        <Tooltip
          hasArrow
          p={3}
          label={
            <>
              <Heading size="sm">Campi di ricerca</Heading>
              <Divider my={3} />
              <Heading size="xs">
                {searchFields.map((s, id) => {
                  return <Box key={id}>{s}</Box>;
                })}
              </Heading>
            </>
          }
        >
          <Stack spacing={1} width={"300px"}>
            <Flex gap={3}>
              <InputGroup size="xs" borderColor={"#61737b"}>
                <InputLeftAddon children="Cerca" bg="#61737b" color="white" />
                <Input
                  bg="white"
                  placeholder={"Cerca"}
                  value={this.state.searchText}
                  onChange={e => this.filterProducts(e.target.value)}
                />
              </InputGroup>
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.setState({ searchText: "" }, () =>
                    this.filterProducts("")
                  )
                }
                color="#61737b"
                className="icn"
                icon={["fa", "times-circle"]}
              />
            </Flex>
          </Stack>
        </Tooltip>
      </>
    );
  }

  render() {
    const { filteredData, sortOnFiled, invertedSort } = this.state;
    const { columns, hideButtons, searchFields } = this.props;

    const columnsToRender = columns.filter(
      c => c.visibility == undefined || (!!c.visibility && c.visibility)
    );
    console.log("AA filteredData", filteredData);
    return !this.props.data || this.props.data.length === 0 ? (
      <Loader />
    ) : (
      <VStack
        // h={"calc(100vh - 260px)"}
        overflow={"hidden"}
        display="block"
        // style={{ borderBottom: "1px solid #61737b30" }}
      >
        <div className="affiliates-table">
          <Flex align={"center"} justify="space-between" mb={2}>
            <Flex w={"70%"} align="center" justify="space-between">
              {this.props.cerca && this.renderSearch()}
              {this.props.alertLegend && this.renderAlertLegend()}
            </Flex>
            <Flex color="#61737b" align="center" justify="end" gap={2} pr={5}>
              <Text fontSize="sm" colorScheme="green">
                Righe: {!!filteredData ? filteredData.length : 0}
              </Text>

              {this.renderExportToCSV()}
            </Flex>
          </Flex>
          <div
            className="sticky-header-table"
            style={this.props.style || { maxHeight: "calc(100vh - 300px)" }}
          >
            <Table isBordered isStriped>
              <thead>
                <tr className="title">
                  {/* {!hideButtons && <th> </th>} */}
                  {columnsToRender.map(c => (
                    <th
                      style={c.style || c.header_style}
                      key={c.Header}
                      onClick={() => this.onSort(c)}
                    >
                      <Flex gap={2} align="center" justify={"space-evenly"}>
                        {c.Header}
                        {sortOnFiled === c.accessor && invertedSort && (
                          <FontAwesomeIcon
                            className="caret"
                            icon={["fa", "angle-up"]}
                          />
                        )}
                        {sortOnFiled === c.accessor && !invertedSort && (
                          <FontAwesomeIcon
                            className="caret"
                            icon={["fa", "angle-down"]}
                          />
                        )}
                        {c.tooltip && (
                          <Tooltip hasArrow p={3} label={<>{c.tooltip}</>}>
                            <Button
                              ml={2}
                              variant="solid"
                              colorScheme="green"
                              label="i"
                              rounded={"50%"}
                              size="xs"
                              fontSize={"11px"}
                              minW={"10px"}
                              w={"10px"}
                              height={"15px"}
                            >
                              i
                            </Button>
                          </Tooltip>
                        )}
                      </Flex>
                    </th>
                  ))}
                  {!!this.props.onGiacenzaDetails && !hideButtons && (
                    <th className="has-text-centered">STORICO GIACENZA</th>
                  )}
                  {!!this.props.onReintegraProdotto && !hideButtons && (
                    <th className="  has-text-centered">REINTEGRA</th>
                  )}
                  {!!this.props.onMigraProdotto && !hideButtons && (
                    <th className=" has-text-centered">MIGRA</th>
                  )}
                  {!hideButtons && this.props.onPreview && <th> </th>}
                  {!hideButtons && this.props.onPrint && <th> </th>}
                  {!hideButtons && this.props.onDuplicate && <th> </th>}
                  {((!!this.props.ware.isAdmin &&
                    !hideButtons &&
                    !this.props.hideDelete) ||
                    this.props.showDelete) && <th> </th>}
                  {!hideButtons && this.props.onPDFClicked && <th> </th>}
                </tr>
              </thead>
              <tbody>
                {filteredData.slice(0, 1000).map((p, idx) => (
                  <MyRow
                    key={idx}
                    index={idx}
                    row={p}
                    onModify={this.props.onModify}
                    onDelete={this.props.onDelete}
                    onGiacenzaDetails={this.props.onGiacenzaDetails}
                    onPrint={this.props.onPrint}
                    onPreview={this.props.onPreview}
                    onPDFClicked={this.props.onPDFClicked}
                    onReintegraProdotto={this.props.onReintegraProdotto}
                    onMigraProdotto={this.props.onMigraProdotto}
                    onModifyField={this.props.onModifyField}
                    columnsToRender={columnsToRender}
                    searchText={this.state.searchText}
                    {...this.props}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </VStack>
    );
  }
}

class MyRow extends React.Component {
  getFieldModifiable(field) {
    const { fieldsModifiable, modifyFields } = this.props;

    return modifyFields && fieldsModifiable.filter(f => f == field).length > 0;
  }

  render() {
    const {
      row,
      hideButtons,
      index,
      fieldsModifiable,
      modifyFields,
      searchText
    } = this.props;
    const { columnsToRender } = this.props;
    // console.log("AA row", row);

    const rowStyle = this.props.rowStyle
      ? { cursor: "pointer", ...this.props.rowStyle(row) }
      : { cursor: "pointer" };
    return (
      <tr
        onClick={e => this.props.onModify(row, index, e, searchText)}
        className={
          row.alert >= row.quantity && !this.props.hideAlert
            ? "has-text-red myrow"
            : "has-text-green myrow"
        }
        style={rowStyle}
      >
        {/* {!hideButtons && (
          <td>
            <Button
              className="icn"
              onClick={e => this.props.onModify(row, index, e)}
            >
              <FontAwesomeIcon icon={["fa", "pencil-alt"]} />
            </Button>
          </td>
        )} */}
        {columnsToRender.map((c, idx) => {
          const modifiable = this.getFieldModifiable(c.accessor);
          let val =
            !!c.subaccessor && !!row[c.accessor]
              ? row[c.accessor][c.subaccessor]
              : row[c.accessor];
          if (c.type === "dateTimestamp") {
            val = Utils.formatDate(val);
          } else if (c.type === "dateString") {
            val = val.toDateString();
          }

          let className = "";
          if (!!c.alert) {
            if (!!c.alert[0] && val <= c.alert[0].val)
              className = c.alert[0].style;
            else if (!!c.alert[1] && val <= c.alert[1].val)
              className = c.alert[1].style;
            else if (!!c.alert[2] && val <= c.alert[2].val)
              className = c.alert[2].style;
          }

          if (c.type === "switch") {
            const active = row[c.accessor];
            // console.log("AA switch", active, c.accessor);
            return (
              <td className={active ? "green" : "red"}>
                <Button
                  bg="transparent"
                  size="sm"
                  onClick={e => {
                    e.stopPropagation();
                    c.onChange(row);
                  }}
                >
                  <FontAwesomeIcon
                    color={active ? "#61737b" : "red"}
                    size="lg"
                    icon={!!active ? faToggleOn : faToggleOff}
                  />
                </Button>
                {/* 
                <Switch
                  size="sm"
                  onChange={() => {
                    e.stopPropagation();
                    c.onChange(row);
                  }}
                  isChecked={active}
                /> */}
              </td>
            );
          } else if (c.type === "checkbox") {
            return (
              <td key={idx} style={c.style || undefined}>
                {/* <FontAwesomeIcon icon={!!val ? faCheck : ""} /> */}
                {val ? "SI" : " "}
              </td>
            );
          } else if (c.type === "alert") {
            return (
              <td className={className + " ta-c"}>
                {className.length > 0 ? (
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                ) : (
                  ""
                )}
              </td>
            );
          }

          return (
            <td
              style={row[c.accessor] < 0 ? c.styleWarning : c.style}
              key={idx}
              className={className}
            >
              {modifiable && (
                <input
                  className="search-field"
                  style={{ maxWidth: "80px" }}
                  type={c.type === "currency" ? "number" : "text"}
                  key={row.uid}
                  step="0.01"
                  name={c.accessor}
                  value={val}
                  onChange={e => {
                    console.log("AA change ", e.target.value, e.target.name);
                    this.props.onModifyField(row, e.target, index, c.type);
                  }}
                />
              )}

              {!modifiable && (
                <MultiRender>
                  {" "}
                  {c.type === "currency"
                    ? Utils.stringToCurrency(val)
                    : !!c.rounded
                    ? Math.round(val)
                    : val}
                </MultiRender>
              )}
              {!modifiable && c.type === "status" && row.documentoChiuso && (
                <span className="dot-green" />
              )}
            </td>
          );
        })}
        {!!this.props.onGiacenzaDetails && !hideButtons && (
          <td style={{ maxWidth: "80px", width: "80px" }}>
            <Button
              colorScheme="green"
              size="sm"
              onClick={e => {
                e.stopPropagation();
                this.props.onGiacenzaDetails(row);
              }}
            >
              VEDI
            </Button>
          </td>
        )}
        {!!this.props.onReintegraProdotto && !hideButtons && (
          <td style={{ maxWidth: "80px", width: "80px" }}>
            <Button
              className="centered is-primary"
              onClick={e => {
                e.stopPropagation();
                this.props.onReintegraProdotto(row);
              }}
            >
              VAI
            </Button>
          </td>
        )}
        {!!this.props.onMigraProdotto && !hideButtons && (
          <td style={{ maxWidth: "80px", width: "80px" }}>
            <Button
              className="centered is-primary"
              onClick={e => {
                e.stopPropagation();
                this.props.onMigraProdotto(row);
              }}
            >
              VAI
            </Button>
          </td>
        )}
        {!hideButtons && this.props.onPreview && (
          <td>
            <Button
              className="icn"
              onClick={e => {
                e.stopPropagation();
                this.props.onPreview(row);
              }}
            >
              <FontAwesomeIcon className="icn" icon={["fa", "eye"]} />
            </Button>
          </td>
        )}
        {!hideButtons && this.props.onPrint && (
          <td>
            <Button
              className="icn"
              onClick={e => {
                e.stopPropagation();
                this.props.onPrint(row);
              }}
            >
              <FontAwesomeIcon className="icn" icon={["fa", "print"]} />
            </Button>
          </td>
        )}
        {!hideButtons && this.props.onDuplicate && (
          <td>
            <Button
              className="icn"
              onClick={e => {
                e.stopPropagation();
                this.props.onDuplicate(row);
              }}
            >
              <FontAwesomeIcon className="icn" icon={["fa", "copy"]} />
            </Button>
          </td>
        )}
        {((!!this.props.ware.isAdmin &&
          !hideButtons &&
          !this.props.hideDelete) ||
          this.props.showDelete) && (
          <td>
            <Button
              className="icn"
              onClick={e => {
                e.stopPropagation();
                this.props.onDelete(row, index);
              }}
            >
              <FontAwesomeIcon className="icn" icon={["fa", "trash"]} />
            </Button>
          </td>
        )}
        {!hideButtons &&
          this.props.onPDFClicked &&
          (row.pdfFilename || row.filename) && (
            <td style={{ maxWidth: "40px" }}>
              <Button
                className="icn"
                onClick={e => {
                  e.stopPropagation();
                  this.props.onPDFClicked(row);
                }}
              >
                <FontAwesomeIcon className="icn" icon={["fa", "file-pdf"]} />
              </Button>
            </td>
          )}
      </tr>
    );
  }
}
