import React, { Component } from "react";
import firebase from "firebase/compat/app";

import { withRouter } from "react-router-dom";
import Utils from "../Utils";
import DocumentType from "../enums/DocumentType";
import { withAdminCtx } from "./AdminContext";
import { getAmazonCountry } from "../views/KPIUtils";
var moment = require("moment");

export const GiacenzeContext = React.createContext(null);

export const GiacenzeConsumer = GiacenzeContext.Consumer;

export class GiacenzeProvider extends Component {
  state = {
    loading: false,
    productsArray: [],
    startDate: moment(new Date()).add(-30, "days"),
    endDate: moment(new Date()),
    codiciChildPivot: [],
    productArray: []
  };

  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);

    this.loadGiacenze = this.loadGiacenze.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadGiacenze: this.loadGiacenze
    });
  }

  async loadGiacenze(products, startDate = null, endDate = null) {
    console.log("AA GIACENZE CONTEXT LOAD", products);
    if (products.products.length === 0) return;
    console.log("AA loadGiacenze");
    this.setState({ loading: true });
    let docs = await firebase
      .firestore()
      .collection("documents")
      // .where("type", "in", [
      //   DocumentType.ENTRATA,
      //   DocumentType.PREVENTIVO,
      //   DocumentType.DISTINTA
      // ])
      .where("date", "<=", this.state.endDate.toDate())
      .where("date", ">=", this.state.startDate.toDate())
      .get();

    let documents = [];

    docs.forEach((u) => {
      const data = Utils.formatDate(u.data().date);
      let doc = u.data();
      doc.date = data;
      doc.dateTimestamp = u.data().date;
      doc.codeNumber = Utils.getCodeNumber(u.data());
      doc.typeFormatted =
        u.data().type === DocumentType.DISTINTA ? "uscita" : u.data().type;

      documents.push(doc);
    });
    const prodottiPivotTable = [];
    const productData = await firebase
      .firestore()
      .collection("reports_products")
      .get();

    productData.forEach((p) => {
      prodottiPivotTable.push(p.data());
    });

    const productsArray = [];
    const allProductsArray = [];
    const productsMyRebArray = [];

    products.products
      .filter((p) => p.active && p.tipologia === "Prodotto finito")
      .forEach((p) => {
        if (p.brand === "MyRebranding") productsMyRebArray.push(p);
        else productsArray.push(p);
      });
    products.products
      .filter((p) => p.active)
      .forEach((p) => {
        if (p.brand != "MyRebranding") allProductsArray.push(p);
      });

    this.setState(
      {
        prodottiPivotTable,
        productsArray,
        allProductsArray,
        productsMyRebArray,
        products,
        documents,
        startDate: startDate || this.state.startDate,
        endDate: endDate || this.state.endDate
      },
      () => {
        this.loadData();
      }
    );
  }

  async loadData() {
    let { startDate, endDate, prodottiPivotTable, products, documents } =
      this.state;
    console.log(
      "aa filter",
      documents.filter((d) => !d.contact)
    );
    const filteredDocs = documents
      .filter(
        (d) =>
          !!d.dateTimestamp &&
          d.dateTimestamp.seconds >= startDate.valueOf() / 1000 &&
          d.dateTimestamp.seconds <= endDate.valueOf() / 1000
      )
      .filter((d) => d.type === DocumentType.DISTINTA)
      .filter((d) => !d.contact || d.contact.uid != "gVCCgP0IpZK3gF2BgBv4") //AMAZON EU SRL
      .filter((d) => !d.contact || d.contact.uid != "THVlrjEyExydYijvTBmB") //AMAZON VENDOR
      .filter((d) => !d.contact || d.contact.uid != "QeHDalXJYC7DxRMyWYMt"); // E COMMERCE

    console.log("AA doc", filteredDocs);
    const venditeOffline = filteredDocs.filter((d) =>
      Utils.getCanaleVendita(d, "Offline")
    );
    const omaggi = filteredDocs.filter(
      (d) => d.causale === "Omaggio" || d.causale === "Vendita"
    );
    const venditeMyReb = filteredDocs.filter((d) =>
      Utils.getCanaleVendita(d, "Myrebranding")
    );

    console.log("AA omaggi", omaggi);
    console.log("AA offline", venditeOffline);
    console.log("AA myrebranding", venditeMyReb);

    console.log("CC date ", startDate, endDate);
    const rowData = await firebase
      .firestore()
      .collection("reports_kpi")
      .where(
        "purchase_date",
        ">=",
        firebase.firestore.Timestamp.fromDate(new Date(startDate))
      )
      .where(
        "purchase_date",
        "<=",
        firebase.firestore.Timestamp.fromDate(new Date(endDate))
      )
      .get();

    const reportsData = [];
    rowData.forEach((r) => {
      const row = r.data();

      this.getProductInfo(row, prodottiPivotTable);

      reportsData.push(row);
    });

    const rowDataVendor = await firebase
      .firestore()
      .collection("reports_vendor")
      .where(
        "purchase_date",
        ">=",
        firebase.firestore.Timestamp.fromDate(new Date(startDate))
      )
      .where(
        "purchase_date",
        "<=",
        firebase.firestore.Timestamp.fromDate(new Date(endDate))
      )
      .get();

    const reportsDataVendor = [];
    rowDataVendor.forEach((r) => {
      const row = r.data();
      reportsDataVendor.push(row);
    });
    console.log("AA rowData", reportsData);
    console.log("AA rowDataVendor", reportsDataVendor, rowDataVendor);
    const daysDifference = endDate.diff(startDate, "days", true);
    console.log("AA days", daysDifference);

    this.calculateTotals(
      venditeOffline,
      venditeMyReb,
      omaggi,
      reportsData,
      reportsDataVendor,
      daysDifference,
      false
    );

    this.setState({ reportsData, reportsDataVendor });
  }

  async getProductInfo(row, prodotti) {
    let prodottiFiltered = [];
    if (row.piattaforma == "AMAZON") {
      prodottiFiltered = prodotti.filter(
        (p) =>
          p.amz_prime == row.sku ||
          p.amz_noi == row.sku ||
          p.amz_ean === row.sku
      );
    } else if (
      row.piattaforma == "WOO" ||
      row.piattaforma == "ALIEXPRESS" ||
      row.piattaforma == "EBAY"
    ) {
      prodottiFiltered = prodotti.filter((p) => p.uid == row.sku);
    }

    if (prodottiFiltered.length > 0) {
      row.sku = prodottiFiltered[0].uid;
      row.product = prodottiFiltered[0].name;
      row.brand = prodottiFiltered[0].brand;
    }
  }

  getAmazonCountriesQty(p) {
    let val = p.amazon_quantity;
    if (p.amazon_quantity_it) val += p.amazon_quantity_it;
    if (p.amazon_quantity_fr) val += p.amazon_quantity_fr;
    if (p.amazon_quantity_es) val += p.amazon_quantity_es;
    if (p.amazon_quantity_de) val += p.amazon_quantity_de;
    if (p.amazon_quantity_nl) val += p.amazon_quantity_nl;
    if (p.amazon_quantity_be) val += p.amazon_quantity_be;
    if (p.amazon_quantity_pl) val += p.amazon_quantity_pl;
    if (p.amazon_quantity_se) val += p.amazon_quantity_se;
    if (p.amazon_quantity_uk) val += p.amazon_quantity_uk;
    return val;
  }

  calculateTotals(
    venditeOffline,
    venditeMyReb,
    omaggi,
    reportsData,
    reportsDataVendor,
    daysDifference,
    isTrend
  ) {
    const {
      productsArray,
      allProductsArray,
      productsMyRebArray,
      startDate,
      endDate,
      products,
      prodottiPivotTable
    } = this.state;

    const days = endDate.diff(startDate, "days") + 1;
    console.log("WW Days", days);
    console.log("WW productsArray", productsArray, allProductsArray);
    console.log(
      "WW reportsData",
      reportsData.filter((r) => typeof r.quantity_shipped === "string")
    );

    productsArray.forEach((p) => {
      const productMyReb =
        productsMyRebArray.filter((r) => r.sku == p.sku).length > 0
          ? productsMyRebArray.filter((r) => r.sku == p.sku)[0]
          : null;
      let offlineTot = 0;
      let omaggiTot = 0;
      let ebay = 0;
      let woo = 0;
      let amazon = 0;
      let myrebranding = 0;
      let vendor = 0;

      venditeMyReb.forEach((o) => {
        o.products.forEach((prod) => {
          if (!!productMyReb && prod.uid === productMyReb.uid)
            myrebranding += prod.quantity;
        });
      });

      const _omaggi = [];
      omaggi.forEach((o) => {
        o.products.forEach((prod) => {
          if (prod.uid === p.uid) {
            _omaggi.push(o);
            omaggiTot += prod.quantity;
          }
          if (!!productMyReb && prod.uid === productMyReb.uid)
            omaggiTot += prod.quantity;
        });
      });

      venditeOffline.forEach((o) => {
        o.products.forEach((prod) => {
          if (prod.uid === p.uid) offlineTot += prod.quantity;
          if (!!productMyReb && prod.uid === productMyReb.uid)
            offlineTot += prod.quantity;
        });
      });

      // console.log("AA reportsData", reportsData, p.sku, prodottiPivotTable);

      if (!!p.sku) {
        reportsData.forEach((o) => {
          let _sku = o.sku;
          if (o.piattaforma === "AMAZON") {
            const country = getAmazonCountry(o);
            _sku =
              prodottiPivotTable.find(
                (p) => p?.["amazon_" + o.amazonCountry] === o.sku
              )?.uid || o.sku;

            // if (o.sku === "Ananas-ama-de")
            //   console.log(
            //     "found country",
            //     country,
            //     _sku,
            //     o.sku,
            //     p.sku,
            //     o,
            //     o.amazonCountry
            //   );
          }

          if (_sku === p.sku) {
            // if (o.sku === "N014")
            //   console.log(
            //     "AA found ",
            //     p.sku,
            //     _sku,
            //     o.sku,
            //     o.amazonCountry,
            //     o.quantity_shipped,
            //     amazon
            //   );

            if (o.piattaforma === "EBAY") ebay += o.quantity_shipped;
            if (o.piattaforma === "AMAZON") amazon += o.quantity_shipped;
            if (o.piattaforma === "WOO") woo += o.quantity_shipped;
          } else if (
            !!products.childProducts[_sku] &&
            products.childProducts[_sku].parentSku == p.sku
          ) {
            //cerca nei figli
            if (o.piattaforma === "EBAY")
              ebay +=
                o.quantity_shipped * products.childProducts[_sku].quantity;
            if (o.piattaforma === "AMAZON")
              amazon +=
                o.quantity_shipped * products.childProducts[_sku].quantity;
            if (o.piattaforma === "WOO")
              woo += o.quantity_shipped * products.childProducts[_sku].quantity;
          }
        });

        reportsDataVendor.forEach((o) => {
          if (o.sku === p.sku) {
            vendor += o.unitaOrdinate || 0;
          } else if (
            !!products.childProducts[o.sku] &&
            products.childProducts[o.sku].parentSku == p.sku
          ) {
            //cerca nei figli
            vendor +=
              (o.unitaOrdinate || 0) * products.childProducts[o.sku].quantity;
          }
        });

        p._omaggi = _omaggi;
        // p.uscite = offlineTot + omaggiTot + ebay + woo + amazon + myrebranding;
        p.uscite = ebay + woo + amazon + vendor + myrebranding + omaggiTot;

        // console.log(
        //   "vv uscite",
        //   p.sku,
        //   p.uscite,
        //   ebay,
        //   woo,
        //   amazon,
        //   vendor,
        //   myrebranding,
        //   omaggiTot
        // );
        p.usciteMese = Math.floor((p.uscite / days) * 30);
      }

      p.amazon_quantity_tot = this.getAmazonCountriesQty(p) || 0;
      p.vendor_ricevuti = p.vendor_vendibili || 0;
      p.vendor_prenotati = p.vendor_ordini_aperti || 0;

      // console.log("vv p", p);

      // console.log("zz padre", p.sku);
      p.prodottiPadri?.forEach((c) => {
        p.amazon_quantity_tot += !!this.getAmazonCountriesQty(c)
          ? this.getAmazonCountriesQty(c) *
            c.codiciFigli.filter((ff) => ff.sku === p.sku)[0].quantity
          : 0;
      });

      p.quantityTot = p.quantity;
      if (!!productMyReb) {
        p.quantityTot += productMyReb.quantity;
      }

      p.magazzinoTot =
        p.quantityTot + p.amazon_quantity_tot + p.vendor_ricevuti;

      p.esaurimentoDays =
        p.usciteMese === 0
          ? 365
          : ((p.magazzinoTot / p.usciteMese) * 30).toFixed(0) || 0;
      p.esaurimento = moment(new Date()).add(p.esaurimentoDays, "days");

      // let consumi = ebay + woo + amazon + offlineTot + omaggiTot + myrebranding;
      let consumi = ebay + woo + amazon + omaggiTot + myrebranding;
      if (isTrend) {
        p["autonomia" + parseInt(daysDifference)] =
          consumi == 0
            ? 99999
            : parseInt((p.magazzinoTot / consumi) * parseInt(daysDifference));
      } else {
        p.ebay = ebay;
        p.woo = woo;
        p.amazon = amazon;
        p.offline = offlineTot;
        p.omaggi = omaggiTot;
        p.myrebranding = myrebranding;
        p.consumi = consumi;
        p.magazzino = p.magazzinoTot;
        p.days = daysDifference;
        p.autonomia =
          consumi == 0
            ? 99999
            : parseInt((p.magazzinoTot / consumi) * parseInt(daysDifference));
        // p.esaurimento = moment(new Date()).add(p.autonomia, "days");
        p.attenzione = p.autonomia - p.autonomia15 > 10 ? 0 : 1;
      }

      console.log(
        "vv uscite",
        p.sku,

        amazon
      );
    });

    console.log("AA productsArray", productsArray);

    this.setState({ productsArray, loading: false });
  }

  // async loadDataGG(days) {
  //   const { documents } = this.props.admin;
  //   const { prodottiPivotTable } = this.state;

  //   let startDate = moment(new Date()).add(-1 * days, "days");
  //   let endDate = moment(new Date());
  //   startDate = startDate.utcOffset(0);
  //   endDate = endDate.utcOffset(0);
  //   endDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
  //   startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  //   const filteredDocs = documents
  //     .filter(
  //       d =>
  //         !!d.dateTimestamp &&
  //         d.dateTimestamp.seconds >= startDate.valueOf() / 1000 &&
  //         d.dateTimestamp.seconds <= endDate.valueOf() / 1000
  //     )
  //     .filter(d => d.type === DocumentType.DISTINTA);

  //   const venditeOffline = filteredDocs.filter(d =>
  //     Utils.getCanaleVendita(d, "Offline")
  //   );
  //   const omaggi = filteredDocs.filter(
  //     d => d.causale === "Omaggio" || d.causale === "Vendita"
  //   );
  //   const venditeMyReb = filteredDocs.filter(d =>
  //     Utils.getCanaleVendita(d, "Myrebranding")
  //   );

  //   const rowData = await firebase
  //     .firestore()
  //     .collection("reports_kpi")
  //     .where(
  //       "purchase_date",
  //       ">=",
  //       firebase.firestore.Timestamp.fromDate(new Date(startDate))
  //     )
  //     .where(
  //       "purchase_date",
  //       "<=",
  //       firebase.firestore.Timestamp.fromDate(new Date(endDate))
  //     )
  //     .get();

  //   const reportsData = [];
  //   rowData.forEach(r => {
  //     const row = r.data();

  //     this.getProductInfo(row, prodottiPivotTable);

  //     reportsData.push(row);
  //   });
  //   // let startMonth = moment(DateUtils.timestampToDate(this.props.startDate));
  //   // const endMonth = moment(DateUtils.timestampToDate(this.props.endDate));
  //   const daysDifference = endDate.diff(startDate, "days", true);

  //   this.calculateTotals(
  //     venditeOffline,
  //     venditeMyReb,
  //     omaggi,
  //     reportsData,
  //     daysDifference,
  //     true
  //   );
  // }

  render() {
    return (
      <GiacenzeContext.Provider value={this.state}>
        <div>{this.props.children}</div>
      </GiacenzeContext.Provider>
    );
  }
}

export default withAdminCtx(withRouter(GiacenzeProvider));

export function withGiacenzeCtx(Component) {
  return (props) => (
    <GiacenzeConsumer>
      {(ctx) => <Component giacenze={ctx} {...props} />}
    </GiacenzeConsumer>
  );
}
