import React from "react";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import { Label } from "bloomer/lib/elements/Form/Label";
import { Field } from "bloomer/lib/elements/Form/Field/Field";
import { Input } from "bloomer/lib/elements/Form/Input";
import { Help } from "bloomer/lib/elements/Form/Help";
import { Button } from "bloomer/lib/elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import firebase from "firebase/compat/app";

import Loader from "../../components/Loader";
import { withAdminCtx } from "../../contexts/AdminContext";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { Container } from "bloomer/lib/layout/Container";
import AdminNavbar from "../../components/admin/AdminNavbar";
import { withModalsCtx } from "../../contexts/ModalsContext";
import DocumentType from "../../enums/DocumentType";
import {
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle
} from "bloomer";

class DProductReintegra extends React.Component {
  state = {
    filteredData: [],
    filteredProducts: [],
    lottiDropdownActive: [],
    nomiDropdownActive: []
  };

  async componentDidMount() {
    const uid = this.props.match.params.pUid;
    const pRef = firebase
      .firestore()
      .collection("products")
      .doc(uid);
    const pSnap = await pRef.get();

    if (pSnap.exists) {
      this.setState({
        product: pSnap.data(),
        filteredProducts: this.props.admin.products.products
      });
    }
  }

  submit() {
    this.props.modals.addModal(
      "Confermi salvataggio?",
      <div />,
      [
        {
          label: "Cancella",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "Salva",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmSave();
        } else {
          this.props.history.goBack();
        }
      }
    );
  }

  async confirmSave() {
    var doc;
    if (this.props.migra) {
      //MIGRAZIONE
      //scalo prodotto attuale
      let products = [];
      const p = this.state.product;
      p.quantity = -1 * this.state.newProduct.quantity;
      products.push(p);

      doc = {
        name: "Nuovo documento",
        creatorName: firebase.auth().currentUser.displayName,
        creator: firebase.auth().currentUser.uid,
        date: new Date(),
        type: DocumentType.MIGRAZIONE,
        causale: "Migrazione",
        products,
        oldProduct: this.state.newProduct,
        quantity: -1 * this.state.newProduct.quantity
      };

      console.log("AA doc attuale", doc);
      let docRef = await firebase
        .firestore()
        .collection("documents")
        .add(doc)
        .then(docRef => (doc.uid = docRef.id));

      this.props.admin.changeProductQuantity(doc, false);

      //entra nuovo prodotto
      products = [];
      products.push(this.state.newProduct);

      doc = {
        name: "Nuovo documento",
        creatorName: firebase.auth().currentUser.displayName,
        creator: firebase.auth().currentUser.uid,
        date: new Date(),
        type: DocumentType.MIGRAZIONE,
        causale: "Migrazione",
        products,
        oldProduct: this.state.product,
        quantity: this.state.newProduct.quantity
      };

      console.log("AA doc migrazione", doc);

      docRef = await firebase
        .firestore()
        .collection("documents")
        .add(doc)
        .then(docRef => (doc.uid = docRef.id));

      this.props.admin.changeProductQuantity(doc, false);
    } else {
      //REINTEGRA
      const products = [];
      products.push(this.state.newProduct);

      doc = {
        name: "Nuovo documento",
        creatorName: firebase.auth().currentUser.displayName,
        creator: firebase.auth().currentUser.uid,
        date: new Date(),
        type: DocumentType.ENTRATA,
        causale: "Reintegro",
        products,
        oldProduct: this.state.product,
        danneggiati: this.state.newProduct.quantity
      };

      console.log("AA doc", doc);
      let docUid = null;
      const docRef = await firebase
        .firestore()
        .collection("documents")
        .add(doc)
        .then(docRef => (doc.uid = docRef.id));

      this.props.admin.changeProductQuantity(doc, false);
    }

    this.props.history.push("/products");
  }

  handleSearchCode(text) {
    const filteredProducts = this.props.admin.products.products.filter(
      p =>
        String(p.name.toLowerCase()).includes(text.toLowerCase()) ||
        (!!p.barcode &&
          String(p.barcode.toLowerCase()).includes(text.toLowerCase())) ||
        (!!p.code && String(p.code.toLowerCase()).includes(text.toLowerCase()))
    );

    this.setState({
      searchCode: text,
      productDropdownActive: true,
      filteredProducts
    });
  }

  onSelect(e) {
    const nomiAlternativi = !!e.nomiAlternativi ? e.nomiAlternativi : "";
    const lotti = !!e.lotti ? e.lotti : "";
    const el = {
      name: e.name,
      nomeDisplay: e.name,
      nomiAlternativi: nomiAlternativi,
      lotti: lotti,
      uid: e.uid,
      price_sell: !!e.price_sell ? e.price_sell : 0,
      iva: 22,
      ivaDisplay: "22%",
      quantity: 0,
      sconto: 0,
      quantityAvailable: e.quantity
    };

    this.setState({
      newProduct: el,
      productDropdownActive: false,
      searchCode: ""
    });
  }

  handleQuantityChange(s, val) {
    let { newProduct } = this.state;
    newProduct.quantity = val;
    this.setState({ newProduct });
  }

  renderNomi(p) {
    return (
      <div>
        <Button
          className="dropdown-warehouse"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => {
            const nomiDropdownActive = [];
            nomiDropdownActive[p.uid] = !this.state.nomiDropdownActive[p.uid];

            this.setState({
              nomiDropdownActive
            });
          }}
        >
          {p.nomeDisplay}
          <span className="dropdown-icn">
            <FontAwesomeIcon className="icn" icon={["fa", "chevron-down"]} />
          </span>
        </Button>
        <Dropdown isActive={this.state.nomiDropdownActive[p.uid]}>
          <DropdownTrigger />
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {p.nomiAlternativi.map(e => {
                return (
                  <DropdownItem
                    className="ta-l"
                    onClick={() => this.onSelectNome(e, p)}
                    key={e}
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  renderLotti(p) {
    const lotti = !!p.lotti ? p.lotti.slice() : [];
    // const nuovoLotto = {
    //   name: "Nuovo"
    // };
    // lotti.push(nuovoLotto);

    return (
      <div>
        <Button
          className="dropdown-warehouse"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => {
            const lottiDropdownActive = [];
            lottiDropdownActive[p.uid] = !this.state.lottiDropdownActive[p.uid];

            this.setState({
              lottiDropdownActive
            });
          }}
        >
          {p.lotto}
          <span className="dropdown-icn">
            <FontAwesomeIcon className="icn" icon={["fa", "chevron-down"]} />
          </span>
        </Button>
        <Dropdown isActive={this.state.lottiDropdownActive[p.uid]}>
          <DropdownTrigger />
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {lotti.map((e, idx) => {
                return (
                  <DropdownItem
                    className="ta-l"
                    onClick={() => this.onSelectLotto(e, p)}
                    key={idx}
                  >
                    {e.name}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>

        {lotti && (
          <Modal
            className=" has-text-centered is-small"
            isActive={this.state.lottoModalActive}
          >
            <ModalBackground
              onClick={() => this.setState({ modalActive: true })}
            />
            <ModalCard>
              <ModalCardHeader className="no-pb">
                <ModalCardTitle className="has-text-centered no-pl mb-10">
                  Lotti
                </ModalCardTitle>
              </ModalCardHeader>
              <ModalCardBody>
                {!!p.lotti &&
                  p.lotti.map((n, index) => (
                    <Columns>
                      <Column>
                        <Field key={index} className="mb-10">
                          <Input
                            name={n.name}
                            placeholder=""
                            value={n.name}
                            onChange={e => {
                              this.changeLottoElement(e.target.value, index, p);
                            }}
                          />
                        </Field>
                      </Column>
                    </Columns>
                  ))}
                <Button onClick={() => this.addLottoToList(p)}>Nuovo</Button>
              </ModalCardBody>
              <Button
                className="is-primary is-pulled-right"
                onClick={() => {
                  this.setState({ lottoModalActive: false });
                }}
              >
                Ok
              </Button>
            </ModalCard>
          </Modal>
        )}
      </div>
    );
  }

  changeLottoElement(text, index, p) {
    p.lotti[index].name = text;

    this.setState({
      [p]: p
    });
  }

  addLottoToList(p) {
    let list = [];
    if (!!p.lotti) {
      list = p.lotti;
    }
    list.push({ name: "" });

    p.lotti = list;

    this.setState({
      [p]: p
    });
  }

  onSelectLotto(e, p) {
    if (e.name == "Nuovo") {
      this.setState({ lottoModalActive: true });
      return;
    }

    p.lotto = e.name;
    p.lottoAvailability = e.quantity || 0;

    const lottiDropdownActive = [];
    lottiDropdownActive[p.uid] = false;

    console.log("CC ", p);
    this.setState(
      {
        [p]: p,
        lottiDropdownActive
      },
      () => console.log("CCC ", p)
    );
  }

  render() {
    const { product, newProduct } = this.state;
    if (!product) return <Loader />;

    const doc = [];
    return (
      <div>
        <AdminNavbar />
        <Columns isMobile>
          <Column>
            <Button
              onClick={() => this.props.history.goBack()}
              className="title user-area-title vw-tablet"
            >
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
              Torna indietro
            </Button>
          </Column>
        </Columns>
        <div className="mtb-80">
          <Container>
            <h1 className="title has-text-black ">
              {this.props.migra ? "Migra Prodotto" : "Reintegra prodotto"}{" "}
              {product.name}
            </h1>
            {!!this.props.migra && (
              <h3 className="subtitle has-text-black ">
                Prodotti disponibili: {product.quantity}{" "}
              </h3>
            )}
            {!this.props.migra && (
              <h3 className="subtitle has-text-black ">
                Danneggiati disponibili: {product.danneggiati}{" "}
              </h3>
            )}
            <Columns>
              <Column>
                <Label>Prodotto Partenza</Label>
              </Column>
              <Column>
                <Label>Lotto</Label>
              </Column>
              <Column style={{ width: "150px", maxWidth: "150px" }}></Column>
            </Columns>
            {!!product && (
              <div>
                <Columns>
                  <Column>
                    <Field>
                      <Input
                        className={"blocked"}
                        type="text"
                        value={product.name}
                        readOnly
                      />
                    </Field>
                  </Column>
                  <Column>
                    <Field>{this.renderLotti(product)}</Field>
                    <Help>Disponibili {product.lottoAvailability}</Help>
                  </Column>
                  <Column
                    style={{ width: "150px", maxWidth: "150px" }}
                  ></Column>
                </Columns>

                <Columns>
                  <Column>
                    <Label>Prodotto Destinazione</Label>
                  </Column>
                  <Column>
                    <Label>Lotto</Label>
                  </Column>
                  <Column style={{ width: "150px", maxWidth: "150px" }}>
                    <Label>Quantità</Label>
                  </Column>
                </Columns>
              </div>
            )}

            {!!newProduct && (
              <div>
                <Columns>
                  <Column>
                    <Field>
                      {/* {!!newProduct.nomiAlternativi && newProduct.nomiAlternativi.length > 0 ? (
                        this.renderNomi(newProduct)
                      ) : ( */}
                      <Input
                        className={"blocked"}
                        type="text"
                        value={newProduct.name}
                        readOnly
                      />
                      {/* )} */}
                    </Field>
                  </Column>
                  <Column>
                    <Field>{this.renderLotti(newProduct)}</Field>
                    <Help>Disponibili {newProduct.lottoAvailability}</Help>
                  </Column>
                  <Column style={{ width: "150px", maxWidth: "150px" }}>
                    <Field>
                      <Input
                        name="quantity"
                        type="number"
                        placeholder=""
                        value={newProduct.quantity}
                        onChange={e => {
                          this.handleQuantityChange(
                            newProduct,
                            parseFloat(e.target.value)
                          );
                        }}
                      />
                      {!this.props.migra && (
                        <Help>Disponibili {product.danneggiati}</Help>
                      )}
                    </Field>
                  </Column>
                </Columns>
              </div>
            )}
            {
              <div>
                <Columns>
                  <Column>
                    <Dropdown
                      isActive={this.state.productDropdownActive}
                      className="dropdown-menu-bis"
                    >
                      <Field className="mb-10">
                        <Input
                          name="product"
                          autoComplete="off"
                          type="text"
                          placeholder="cerca prodotto"
                          value={this.state.searchCode}
                          onChange={e => {
                            this.handleSearchCode(e.target.value);
                          }}
                        />
                      </Field>

                      <DropdownMenu>
                        <DropdownContent className="elements-dropdown">
                          {this.state.filteredProducts.map(e => {
                            return (
                              <DropdownItem
                                className="ta-l"
                                onClick={() => this.onSelect(e)}
                                key={e.uid}
                              >
                                {e.name}
                                <FontAwesomeIcon
                                  className="ml-15"
                                  icon={["fa", "plus-circle"]}
                                />
                              </DropdownItem>
                            );
                          })}
                        </DropdownContent>
                      </DropdownMenu>
                    </Dropdown>
                  </Column>
                </Columns>
              </div>
            }
            <Button
              className=" is-primary is-fullwidth mt-10 mb-40"
              onClick={() => this.submit()}
            >
              Salva
            </Button>
          </Container>
        </div>
      </div>
    );
  }
}

export default withModalsCtx(withAdminCtx(DProductReintegra));
