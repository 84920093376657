import React, { Component } from "react";
import firebase from "firebase/compat/app";

import { Table } from "bloomer/lib/elements/Table";
import { Container } from "bloomer/lib/layout/Container";
import { Column } from "bloomer/lib/grid/Column";
import { Columns } from "bloomer/lib/grid/Columns";

export default class DUsers extends Component {
  state = {
    users: []
  };

  async componentDidMount() {
    this.setState({
      timer: setTimeout(async () => {
        this.loadData();
      })
    });
  }
  async loadData() {
    let users = [];
    const usersSanp = await firebase
      .firestore()
      .collection("users")
      .get();
    usersSanp.forEach(u => {
      users.push(u.data());
    });

    users.sort((a, b) => {
      if (a.displayName > b.displayName) return 1;
      if (a.displayName < b.displayName) return -1;
      return 0;
    });
    this.setState({ users });
  }

  render() {
    const { users } = this.state;

    return (
      <div>
        <Container>
          <Columns className="admin-header mt-5">
            <Column>
              <h2 className="title has-text-black ">Utenti</h2>
            </Column>
          </Columns>
          <div className="affiliates-table">
            <Table isBordered isStriped>
              <thead>
                <tr className="title">
                  <th>Nome</th>
                  <th>Abilitato</th>
                  <th>Admin</th>
                  <th>Creazione e Modifica Prodotti e MP</th>
                  <th>Report KPI</th>
                  <th>Modifica Prezzi</th>
                  <th>Notifica Giacenze</th>
                </tr>
              </thead>
              <tbody>
                {users.map(p => (
                  <UserRow key={p.uid} row={p} {...this.props} />
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    );
  }
}

class UserRow extends React.Component {
  state = {
    enabled: false,
    isAdmin: false,
    isCreator: false,
    enableReportAmazon: false,
    canModifyPrices: false,
    notificaGiacenze: false
  };

  componentDidMount() {
    console.log("AA", this.props);
    this.setState({
      enabled: this.props.row.enabled,
      isAdmin: this.props.row.isAdmin,
      canModifyPrices: this.props.row.canModifyPrices,
      notificaGiacenze: this.props.row.notificaGiacenze,
      isCreator: this.props.row.isCreator,
      enableReportAmazon: this.props.row.enableReportAmazon
    });
  }

  async enableUser(uid, val) {
    const usersSanp = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({ enabled: val });

    this.setState({ enabled: val });
  }

  async enableUserAdmin(uid, val) {
    const usersSanp = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({ isAdmin: val });

    this.setState({ isAdmin: val });
  }

  async enablecanModifyPrices(uid, val) {
    const usersSanp = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({ canModifyPrices: val });

    this.setState({ canModifyPrices: val });
  }

  async enableUserCreator(uid, val) {
    const usersSanp = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({ isCreator: val });

    this.setState({ isCreator: val });
  }

  async enableUserReportAmazon(uid, val) {
    const usersSanp = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({ enableReportAmazon: val });

    this.setState({ enableReportAmazon: val });
  }

  async enableNotificaGiacenze(uid, val) {
    const usersSanp = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({ notificaGiacenze: val });

    this.setState({ notificaGiacenze: val });
  }

  render() {
    const { row } = this.props;
    return (
      <tr className="has-text-green">
        <td>{row.displayName}</td>
        <td>
          <div className="has-checkradio">
            <input
              className="is-checkradio"
              type="checkbox"
              required
              name="fattura"
              checked={this.state.enabled}
              onChange={() => {}}
            />
            <label
              htmlFor="fattura"
              onClick={() => this.enableUser(row.uid, !this.state.enabled)}
            />
          </div>
        </td>
        <td>
          <div className="has-checkradio">
            <input
              className="is-checkradio"
              type="checkbox"
              required
              name="fattura"
              checked={this.state.isAdmin}
              onChange={() => {}}
            />
            <label
              htmlFor="fattura"
              onClick={() => this.enableUserAdmin(row.uid, !this.state.isAdmin)}
            />
          </div>
        </td>
        <td>
          <div className="has-checkradio">
            <input
              className="is-checkradio"
              type="checkbox"
              required
              name="fattura"
              checked={this.state.isCreator}
              onChange={() => {}}
            />
            <label
              htmlFor="fattura"
              onClick={() =>
                this.enableUserCreator(row.uid, !this.state.isCreator)
              }
            />
          </div>
        </td>
        <td>
          <div className="has-checkradio">
            <input
              className="is-checkradio"
              type="checkbox"
              required
              name="fattura"
              checked={this.state.enableReportAmazon}
              onChange={() => {}}
            />
            <label
              htmlFor="fattura"
              onClick={() =>
                this.enableUserReportAmazon(
                  row.uid,
                  !this.state.enableReportAmazon
                )
              }
            />
          </div>
        </td>
        <td>
          <div className="has-checkradio">
            <input
              className="is-checkradio"
              type="checkbox"
              required
              name="canModifyPrices"
              checked={this.state.canModifyPrices}
              onChange={() => {}}
            />
            <label
              htmlFor="fattura"
              onClick={() =>
                this.enablecanModifyPrices(row.uid, !this.state.canModifyPrices)
              }
            />
          </div>
        </td>
        <td>
          <div className="has-checkradio">
            <input
              className="is-checkradio"
              type="checkbox"
              required
              name="canModifyPrices"
              checked={this.state.notificaGiacenze}
              onChange={() => {}}
            />
            <label
              htmlFor="fattura"
              onClick={() =>
                this.enableNotificaGiacenze(
                  row.uid,
                  !this.state.notificaGiacenze
                )
              }
            />
          </div>
        </td>
      </tr>
    );
  }
}
