import React from "react";
import {
  Button,
  Navbar,
  NavbarStart,
  NavbarBrand,
  NavbarEnd,
  NavbarItem
} from "bloomer";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import firebase from "firebase/compat/app";

import { Media, MediaLeft, Image, MediaContent } from "bloomer";
import Utils from "../../Utils";
import { withWareCtx } from "../../contexts/WarehouseContext";
import {
  Avatar,
  AvatarBadge,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import GiacenzeModal from "./GiacenzeModal";

function ReportMenuOption() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        variant="ghost"
        mx={1}
        py={[1, 2, 2]}
        px={4}
        borderRadius={5}
        _hover={{ color: "#5b7682" }}
        aria-label="Courses"
        fontWeight="normal"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <Heading size="sm" _hover={{ color: "#5b7682" }}>
          REPORT
        </Heading>
      </MenuButton>
      <MenuList onMouseEnter={onOpen} onMouseLeave={onClose}>
        <MenuItem>
          <Heading size="sm" color={"#5b7682"}>
            <Link to="/reports">Reports KPI</Link>
          </Heading>
        </MenuItem>
        <MenuItem>
          <Heading size="sm" color={"#5b7682"}>
            <Link to="/giacenze">Reports Giacenze</Link>
          </Heading>
        </MenuItem>
        <MenuItem>
          <Heading size="sm" color={"#5b7682"}>
            <Link to="/caricamento">Caricamento Dati</Link>
          </Heading>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

class AdminNavbar extends React.Component {
  state = {
    userDropdown: false,
    transparent: true,
    user: null
  };

  constructor(props) {
    super(props);
    this.doLogout = this.doLogout.bind(this);
  }

  goToUserArea() {
    this.props.history.push("/");
  }

  goToPTArea() {
    this.props.history.push("/business");
  }

  goToAdminArea() {
    this.props.history.push("/admin");
  }

  goToUserDetails() {
    this.props.history.push("/me");
  }

  goToUserAccountDetails() {
    this.props.history.push("/me/account");
  }

  async doLogout() {
    Utils.log("AA logout");
    try {
      await firebase
        .auth()
        .signOut()
        .then(() => this.props.history.push("/"));
    } catch (error) {
      Utils.handleError(error);
    }
  }

  render() {
    const user = firebase.auth().currentUser;

    const path = this.props.location.pathname;
    return (
      <Navbar
        className={
          "mobile-navbar " +
            (!this.props.isDark && this.state.transparent
              ? "full-transparent "
              : "") +
            this.props.className || ""
        }
      >
        <div className="nav-container">
          <NavbarBrand>
            <NavbarItem>
              <Link to="/">
                <img src="/images/mylabLogo.png" alt="" />
              </Link>
            </NavbarItem>
          </NavbarBrand>

          <NavbarStart>
            <NavbarItem className={path === "/anagrafica" && "active"}>
              <Link to="/anagrafica" className="button is-transparent">
                <Heading size="sm" _hover={{ color: "#5b7682" }}>
                  Anagrafica
                </Heading>
              </Link>
            </NavbarItem>
            <NavbarItem className={path === "/uscita" && "active"}>
              <Link to="/uscita" className="button is-transparent">
                <Heading size="sm" _hover={{ color: "#5b7682" }}>
                  Movimenti Prodotti
                </Heading>
              </Link>
            </NavbarItem>
            {/* <NavbarItem className={path === "/produzione" && "active"}>
              <Link to="/produzione" className="button is-transparent">
                Produzione
              </Link>
            </NavbarItem>
            <NavbarItem className={path === "/elements" && "active"}>
              <Link to="/elements" className="button is-transparent">
                Materie Prime e PKG
              </Link>
            </NavbarItem> */}
            <NavbarItem className={path === "/products" && "active"}>
              <Link to="/products" className="button is-transparent">
                <Heading size="sm" _hover={{ color: "#5b7682" }}>
                  Prodotti
                </Heading>
              </Link>
            </NavbarItem>
            {this.props.ware.enableReportAmazon && (
              <NavbarItem
                className={
                  (path === "/reports" || path === "/giacenze") && "active"
                }
              >
                <ReportMenuOption />
                {/* <Dropdown isActive={this.state.reportDropdownActive}>
                  <DropdownTrigger>
                    <Button
                      onClick={() =>
                        this.setState({
                          reportDropdownActive: !this.state.reportDropdownActive
                        })
                      }
                    >
                      <Heading size="sm" _hover={{ color: "#5b7682" }}>
                        REPORTS
                      </Heading>
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownContent className="elements-dropdown">
                      <DropdownItem className="ta-l">
                        <Link to="/reports">Reports KPI</Link>
                      </DropdownItem>
                      <DropdownItem className="ta-l">
                        <Link to="/giacenze">Reports Giacenze</Link>
                      </DropdownItem>
                      <DropdownItem className="ta-l">
                        <Link to="/caricamento">Caricamento Dati</Link>
                      </DropdownItem>
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown> */}
              </NavbarItem>
            )}
            {this.props.ware.isAdmin && (
              <NavbarItem className={path === "/users" && "active"}>
                <Link to="/users" className="button is-transparent">
                  <Heading size="sm" _hover={{ color: "#5b7682" }}>
                    Utenti
                  </Heading>
                </Link>
              </NavbarItem>
            )}
          </NavbarStart>
          <NavbarEnd>
            {!!user && (
              <div className="login">
                <Media>
                  <MediaLeft>
                    <Avatar src={user.photoURL}>
                      {!!this.state.esaurimento &&
                        this.state.esaurimento > 0 && (
                          <Tooltip
                            hasArrow
                            p={3}
                            label=<Heading fontSize="xs">
                              {this.state.esaurimento +
                                " prodotti in esaurimento"}
                            </Heading>
                          >
                            <AvatarBadge
                              boxSize="1.25em"
                              bg="red.500"
                              border={"2px"}
                            >
                              <Heading fontSize={"xs"} color="white">
                                {this.state.esaurimento}
                              </Heading>
                            </AvatarBadge>
                          </Tooltip>
                        )}
                    </Avatar>
                  </MediaLeft>
                  <MediaContent className="has-text-white">
                    <h2>{user.displayName}</h2>
                    <Button
                      onClick={this.doLogout}
                      className="is-transparent is-text"
                    >
                      <h4 className="subtitle">LOGOUT</h4>
                    </Button>
                  </MediaContent>
                </Media>
              </div>
            )}
          </NavbarEnd>
        </div>
        {/* <GiacenzeModal
          onLoadGiacenze={d => this.setState({ esaurimento: d })}
          {...this.props}
        /> */}
      </Navbar>
    );
  }
}

export default withRouter(withWareCtx(AdminNavbar));
