import React, { Component } from "react";
import { Container, Columns, Column } from "bloomer";
import { withAdminCtx } from "../../contexts/AdminContext";
import Loader from "../../components/Loader";
import MyTable from "../../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { Button } from "bloomer/lib/elements/Button";
import { withWareCtx } from "../../contexts/WarehouseContext";
import firebase from "firebase/compat/app";

import { withModalsCtx } from "../../contexts/ModalsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../../Utils";
import DocumentType from "../../enums/DocumentType";
import AdminNavbar from "../../components/admin/AdminNavbar";

class DProductHistory extends Component {
  state = {
    product: null
  };

  async componentDidMount() {
    const uid = this.props.uid || this.props.match.params.pUid;
    const pRef = firebase
      .firestore()
      .collection("products")
      .doc(uid);
    const pSnap = await pRef.get();

    let giacenzaCalcolata = 0;
    if (pSnap.exists) {
      const documents = [];
      const promises = [];
      const docSnap = await pRef.collection("documents").get();
      docSnap.forEach(d => {
        const doc = d.data();
        doc.dateFormatted = Utils.formatDate(d.data().date);
        if (doc.type === DocumentType.DISTINTA)
          doc.quantity = -1 * doc.quantity;

        promises.push(
          new Promise(async (r, rj) => {
            const realDocSnap = await firebase
              .firestore()
              .collection("documents")
              .doc(doc.uid)
              .get();

            if (realDocSnap.exists) {
              const realDoc = realDocSnap.data();
              let product = realDoc.products?.filter(
                p => p.sku === pSnap.data().sku
              )[0];

              if (!product) {
                realDoc.products?.forEach(p =>
                  p.prodottiFigli?.forEach(f => {
                    if (f.sku === pSnap.data().sku) product = f;
                  })
                );
              }

              doc.codeNumber = Utils.getCodeNumber(realDoc);
              doc.lotto = product?.lotto || "";
            }

            r(doc);
          })
        );
        if (
          doc.type === DocumentType.DISTINTA ||
          doc.type === DocumentType.ENTRATA ||
          doc.type === DocumentType.MIGRAZIONE ||
          doc.type == DocumentType.CORREZIONE
        ) {
          documents.push(doc);
        }
      });

      await Promise.all(promises);
      documents.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
      });

      let found = false;
      documents.forEach(d => {
        if (!found) giacenzaCalcolata += d.quantity;
        if (d.type === DocumentType.CORREZIONE) found = true;
      });

      const giacenzaLotti = pSnap
        .data()
        .lotti?.reduce((a, b) => a + b.quantity, 0);
      this.setState({
        product: pSnap.data(),
        documents,
        giacenzaCalcolata,
        giacenzaLotti
      });
    }
  }

  onModify(p) {
    window.open("documentDetails/" + p.uid + "/" + p.type, "_blank");

    // this.props.history.push("/documentDetails/" + p.uid + "/" + p.type);
  }

  render() {
    const { product, documents, giacenzaLotti } = this.state;

    if (!product) return <Loader />;

    const columns = [
      {
        Header: "Numero",
        accessor: "codeNumber"
      },
      {
        Header: "Data",
        accessor: "dateFormatted"
      },
      {
        Header: "Lotto",
        accessor: "lotto"
      },
      {
        Header: "Quantità",
        accessor: "quantity",
        maxWidth: 100,
        style: { textAlign: "center" },
        styleWarning: { color: "red", textAlign: "center" }
      },
      {
        Header: "Tipo",
        accessor: "type"
      },
      {
        Header: "Causale",
        accessor: "causale"
      },
      {
        Header: "Creato da",
        accessor: "creatorName"
      }
    ];

    return (
      <section>
        {/* <Columns isMobile>
          <Column>
            <Button
              onClick={() => this.props.history.goBack()}
              className="title user-area-title vw-tablet"
            >
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
              Torna indietro
            </Button>
          </Column>
        </Columns> */}
        <Container>
          <Columns className="admin-header mt-5">
            <Column>
              <h2 className="title has-text-black ">
                Storico Giacenza prodotto {product.name}
              </h2>
              <h3 className="subtitle has-text-black">
                Magazzino:{product.quantity} - Lotti:{giacenzaLotti || 0} -
                Calcolata:
                {this.state.giacenzaCalcolata}{" "}
              </h3>
            </Column>
          </Columns>
          <div className="admin-panels">
            {
              <MyTable
                data={documents}
                columns={columns}
                onModify={u => this.onModify(u)}
                hideDelete
                {...this.props}
              />
            }
          </div>
        </Container>
      </section>
    );
  }
}

export default withModalsCtx(
  withRouter(withAdminCtx(withWareCtx(DProductHistory)))
);
