export default {
  DISTINTA: "distinta",
  PREVENTIVO: "preventivo",
  ENTRATA: "entrata",
  MIGRAZIONE: "migrazione",
  ENTRATA_PROD: "entrataProduzione",
  USCITA_PROD: "uscitaProduzione",
  USCITA_MANUALE: "uscitaProduzioneManuale",
  CORREZIONE: "Correzione"
};
