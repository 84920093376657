import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

export default class Loader extends React.Component {
  render() {
    return (
      <Center>
        <Spinner mt={10} />
      </Center>
    );
  }
}
