import React, { Component } from "react";
import MyTable from "../../components/admin/MyTable";
import firebase from "firebase/compat/app";

import { Button } from "bloomer/lib/elements/Button";
import { Modal } from "bloomer/lib/components/Modal/Modal";
import { ModalBackground } from "bloomer/lib/components/Modal/ModalBackground";
import { ModalCard } from "bloomer/lib/components/Modal/Card/ModalCard";
import { ModalCardHeader } from "bloomer/lib/components/Modal/Card/ModalCardHeader";
import { ModalCardTitle } from "bloomer/lib/components/Modal/Card/ModalCardTitle";
import { ModalCardBody } from "bloomer/lib/components/Modal/Card/ModalCardBody";
import { Field, Input, Columns } from "bloomer";
import { TextArea } from "bloomer/lib/elements/Form/TextArea";
import { Column } from "bloomer/lib/grid/Column";
import DateUtils from "../../services/DateUtils";
import Utils from "../../Utils";

export default class DContactLog extends Component {
  state = {
    log: [],
    modalActive: false,
    modifyRecord: false,
    modifyRow: 0
  };

  componentDidMount() {
    this.setState({ log: this.props.contact.contactLog || [] });
  }

  onModify(u, idx) {
    console.log("AA onModify", idx);
    const d = DateUtils.timestampToDate(u.date);
    const dd = Utils.dateToYYYMMDD(d);

    this.setState({
      modifyRecord: true,
      date: dd,
      text: u.text,
      modalActive: true,
      modifyRow: idx
    });
  }

  onDelete(u, idx) {
    console.log("AA onDelete");

    this.props.modals.addModal(
      "Confermi eliminazione?",
      <div />,
      [
        {
          label: "ESCI",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "ELIMINA",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDelete(idx);
        }
      }
    );
  }

  confirmDelete(idx) {
    const { log } = this.state;

    log.splice(idx, 1);
    this.setState({ log }, () => this.updateTable(log));

    console.log("AA onDelete confkirm", log);
  }

  updateTable(log) {
    const { contact } = this.props;

    firebase
      .firestore()
      .collection("contacts")
      .doc(contact.uid)
      .update({ contactLog: log });
  }

  addContact() {
    const { log, modifyRecord, modifyRow } = this.state;
    var datum = firebase.firestore.Timestamp.fromDate(
      new Date(this.state.date)
    );

    if (modifyRecord) {
      const l = log[modifyRow];
      l.date = datum;
      l.text = this.state.text;
    } else {
      log.push({ date: datum, text: this.state.text });
    }
    console.log("AA onDelete confkirm", log);

    this.setState({ log, modalActive: false }, () => this.updateTable(log));
  }

  handleChange(e) {
    const s = {};
    s[e.target.name] = e.target.value;
    this.setState(s);
  }

  render() {
    const { log } = this.state;

    const columns = [
      {
        Header: "Data",
        accessor: "date",
        type: "dateTimestamp"
      },
      {
        Header: "Testo",
        accessor: "text"
      }
    ];
    return (
      <div className="admin-panels">
        <h4 className="title has-text-black mb-0">CONTATTI</h4>

        {
          <MyTable
            data={log}
            columns={columns}
            showDelete
            onDelete={(u, idx) => this.onDelete(u, idx)}
            onModify={(u, idx) => this.onModify(u, idx)}
            {...this.props}
          />
        }
        <Button
          className="is-primary mt-20"
          onClick={() =>
            this.setState({ modalActive: true, modifyRecord: false })
          }
        >
          AGGIUNGI CONTATTO
        </Button>

        <Modal
          className="warehouse-modal has-text-centered"
          isActive={this.state.modalActive}
        >
          <ModalBackground
            onClick={() => this.setState({ modalActive: false })}
          />
          <ModalCard>
            <ModalCardHeader className="no-pb">
              <ModalCardTitle className="has-text-centered no-pl mb-10">
                {this.state.modifyRecord
                  ? "Modifica Contatto"
                  : "Nuovo Contatto"}
              </ModalCardTitle>
            </ModalCardHeader>
            <ModalCardBody>
              <Field className="mb-10">
                <Input
                  name="date"
                  placeholder=""
                  value={this.state.date}
                  type="date"
                  onChange={e => {
                    this.handleChange(e);
                  }}
                />
              </Field>

              <Field className="mb-10">
                <TextArea
                  name="text"
                  placeholder=""
                  value={this.state.text}
                  type="textarea"
                  onChange={e => {
                    this.handleChange(e);
                  }}
                />
              </Field>
            </ModalCardBody>
            <Columns>
              <Column>
                <Button
                  className="is-primary  "
                  onClick={() => {
                    this.setState({ modalActive: false });
                  }}
                >
                  CANCELLA
                </Button>
              </Column>
              <Column>
                <Button
                  className="is-primary  "
                  onClick={() => {
                    this.addContact();
                  }}
                >
                  Ok
                </Button>
              </Column>
            </Columns>
          </ModalCard>
        </Modal>
      </div>
    );
  }
}
