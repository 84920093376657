import { Column } from "bloomer";
import { Columns } from "bloomer/lib/grid/Columns";
import { Container } from "bloomer/lib/layout/Container";
import React, { Component } from "react";
import { withAdminCtx } from "../contexts/AdminContext";
import Loader from "../components/Loader";
import MyTable from "../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { withWareCtx } from "../contexts/WarehouseContext";
import { withModalsCtx } from "../contexts/ModalsContext";
import { DateRangePicker } from "react-dates";
import { withGiacenzeCtx } from "../contexts/GiacenzeContext";
import { Button, Divider, Heading } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var moment = require("moment");

class DReportGiacenze extends Component {
  state = {
    loading: false,
    productsArray: [],
    startDate: moment(new Date()).add(-30, "days"),
    endDate: moment(new Date()),
    codiciChildPivot: []
  };

  componentDidMount() {
    this.setState({
      timer: setTimeout(async () => {
        this.loadData();
      })
    });
  }

  componentWillReceiveProps(p) {
    if (!p.giacenze.loading) {
      this.setState({
        productsArray: p.giacenze.productsArray,
        loading: false,
        dateChanged: false
      });
    }
  }

  async loadData() {
    const { productsArray, startDate, endDate } = this.state;
    console.log("AA loadGiacenze componentWillReceiveProps");

    if (this.state.loading) return;
    this.setState({ loading: true });
    console.log("AA loadGiacenze");
    await this.props.giacenze.loadGiacenze(
      this.props.admin.products,
      startDate,
      endDate
    );
  }

  async onDatesChange(startDate, endDate) {
    console.log("onDateChange");
    this.setState({
      startDate,
      endDate,
      dateChanged: true
    });

    if (!startDate || !endDate) return;

    startDate = startDate.utcOffset(0);
    endDate = endDate.utcOffset(0);
    endDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
    startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // this.setState({ loading: true });

    // this.props.giacenze.loadGiacenze(
    //   this.props.admin.products,
    //   startDate,
    //   endDate
    // );
  }

  renderProducts() {
    const { productsArray } = this.state;

    const checkVisibility =
      this.props.ware.uid == "sf6hm9fPYafjMkLoaHlpTDy7jqe2"; //Alessandro Verde
    console.log("ZZZ productsArray", productsArray);
    const columns = [
      {
        Header: "Nome",
        accessor: "name",
        header_style: { backgroundColor: "#d0e7ce", color: "black" }
      },
      {
        Header: "SKU",
        accessor: "sku",
        header_style: { backgroundColor: "#d0e7ce", color: "black" }
      },
      {
        Header: "Magazzino Totale",
        accessor: "magazzinoTot",
        header_style: { backgroundColor: "#c7dcdc", color: "black" },
        tooltip: (
          <>
            <Heading fontSize="12px">
              Numero totale dei pezzi giacenti in tutti i magazzini (nostro,
              vendor, seller)
            </Heading>
            <Divider my={2} />
            <Heading fontSize="12px">
              TOTALE tra: Mag Vendor Ric + Mag Seller + Mag Nostro
            </Heading>
          </>
        )
      },
      {
        Header: "Mag Vendor Ric",
        accessor: "vendor_ricevuti",
        header_style: { backgroundColor: "#c7dcdc", color: "black" },
        tooltip: (
          <>
            <Heading fontSize="12px">
              Numero Totale dei pezzi ricevuti e giacenti nel magazzino Vendor
            </Heading>
            <Divider my={2} />
            <Heading fontSize="12px">
              Prende i dati dal file "REPORT GIACENZE VENDOR" (da creare ex
              novo) che viene caricato in "Report giacenze Amazon" in
              "Caricamento Dati"
            </Heading>
          </>
        )
      },
      {
        Header: "Mag Vendor Pren",
        accessor: "vendor_prenotati",
        header_style: { backgroundColor: "#c7dcdc", color: "black" },
        tooltip: (
          <>
            <Heading fontSize="12px">
              Numero Totale dei pezzi prenotati da spedire a magazzino Vendor
            </Heading>
            <Divider my={2} />
            <Heading fontSize="12px">
              Prende i dati dal file "REPORT GIACENZE VENDOR" (da creare ex
              novo) che viene caricato in "Report giacenze Amazon" in
              "Caricamento Dati"
            </Heading>
          </>
        )
      },
      {
        Header: "Mag Seller",
        accessor: "amazon_quantity_tot",
        header_style: { backgroundColor: "#c7dcdc", color: "black" },
        tooltip: (
          <>
            <Heading fontSize="12px">
              Numero Totale dei pezzi ricevuti e giacenti nel magazzino Seller
            </Heading>
            <Divider my={2} />
            <Heading fontSize="12px">
              Prende i dati dal file "REPORT GIACENZE AMZ"
            </Heading>
          </>
        )
      },
      {
        Header: "Mag Nostro",
        accessor: "quantityTot",
        header_style: { backgroundColor: "#c7dcdc", color: "black" },
        tooltip: (
          <>
            <Heading fontSize="12px">
              Numero Totale dei pezzi giacenti nel nostro magazzino{" "}
            </Heading>
            <Divider my={2} />
            <Heading fontSize="12px">
              Prende i dati da tutte le distinte in entrata{" "}
            </Heading>
          </>
        )
      },

      {
        Header: "Ebay",
        accessor: "ebay",
        visibility: checkVisibility
      },
      {
        Header: "Amazon",
        accessor: "amazon",
        visibility: checkVisibility
      },
      {
        Header: "eCommerce",
        accessor: "woo",
        visibility: checkVisibility
      },
      {
        Header: "MyRebranding",
        accessor: "myrebranding",
        visibility: checkVisibility
      },
      {
        Header: "Offline",
        accessor: "offline",
        visibility: checkVisibility
      },
      {
        Header: "Omaggi",
        accessor: "omaggi",
        visibility: checkVisibility
      },
      {
        Header: "Vendite Tot",
        accessor: "uscite",
        header_style: { backgroundColor: "#d5cbe4", color: "black" },
        tooltip: (
          <>
            <Heading fontSize="12px">
              Tutte le vendite nel periodo selezionato
            </Heading>
            <Divider my={2} />
            <Heading fontSize="12px">
              SOMMA di tutti i pezzi trovati nelle distinte TRANNE le distinte
              con cliente "AMAZON VENDOR" + "AMAZON EU srl" + tutti i pezzi
              inseriti nel file "Amazon Vendor" (da creare ex novo) che viene
              caricato in "Reports Vendite"" in "Caricamento Dati"" + quelli
              inseriti nel file ""Amazon"" in ""Reports Vendite"" in
              "Caricamento Dati"
            </Heading>
          </>
        )
      },
      {
        Header: "Vendite medie mensili",
        accessor: "usciteMese",
        header_style: { backgroundColor: "#d5cbe4", color: "black" },
        tooltip: (
          <>
            <Heading fontSize="12px">
              Tutte le vendite che mediamente facciamo in un mese
            </Heading>
            <Divider my={2} />
            <Heading fontSize="12px">
              Vendite totali / mesi nel periodo selezionato (dovrebbe già fare
              così la formula, è giusta)
            </Heading>
          </>
        )
      },
      {
        Header: "Autonomia (GG)",
        accessor: "autonomia",
        alert: [
          { val: 120, style: "alert_red" },
          { val: 150, style: "alert_orange" },
          { val: 180, style: "alert_yellow" },
          { val: 99999999999999, style: "alert_green" }
        ],
        visibility: checkVisibility
      },
      {
        Header: "Esaurimento giorni",
        accessor: "esaurimentoDays",
        alert: [
          { val: 120, style: "alert_red" },
          { val: 150, style: "alert_orange" },
          { val: 180, style: "alert_yellow" },
          { val: 99999999999999, style: "alert_green" }
        ],
        header_style: { backgroundColor: "#ebcad7", color: "black" }
      },
      {
        Header: "Esaurimento",
        accessor: "esaurimento",
        type: "dateTimestamp",
        header_style: { backgroundColor: "#ebcad7", color: "black" }
      },
      {
        Header: "Autonomia (Trend 15GG)",
        accessor: "autonomia15",
        alert: [
          { val: 120, style: "alert_red" },
          { val: 150, style: "alert_orange" },
          { val: 180, style: "alert_yellow" },
          { val: 99999999999999, style: "alert_green" }
        ],
        visibility: checkVisibility
      },
      {
        Header: "Autonomia (Trend 30G)",
        accessor: "autonomia30",
        visibility: checkVisibility
      },
      {
        Header: "Autonomia (Trend 60GG)",
        accessor: "autonomia60",
        visibility: checkVisibility
      },
      {
        Header: "Attenzione",
        accessor: "attenzione",
        type: "alert",
        // alert: [{ val: 0, style: "alert_red" }],
        visibility: checkVisibility
      }
    ];

    return (
      <div style={{ marginTop: "30px" }}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <MyTable
            data={productsArray}
            columns={columns}
            cerca={true}
            searchFields={["sku", "name", "brand"]}
            alertLegend={"Alert autonomia"}
            hideAlert
            hideButtons
            {...this.props}
          />
        )}
      </div>
    );
  }

  renderAlert() {
    const { reportsData } = this.state;
    if (!reportsData) return;
    const errors = [];
    reportsData.forEach((d) => {
      if (!d.sku) errors.push(d);
    });
    console.log("AA errors", errors);
    if (errors.length === 0) return;

    return (
      <div style={{ backgroundColor: "#ea363669", padding: "10px" }}>
        {errors.map((e) => {
          return (
            <div>
              {e.piattaforma === "EBAY" ? (
                <h5 className="subtitle has-text-black">
                  errore manca codice interno per oggetto EBAY:{e.titolo} -{" "}
                  {e.numero_oggetto}
                </h5>
              ) : e.piattaforma === "WOO" ? (
                <h5 className="subtitle has-text-black">
                  errore manca codice interno per oggetto eCommerce:
                  {e.item_name}
                </h5>
              ) : (
                <h5 className="subtitle has-text-black">
                  errore manca codice interno per oggetto
                </h5>
              )}
            </div>
          );
        })}
      </div>
    );
  }
  render() {
    return (
      <section>
        <Container>
          <div
            style={{
              backgroundColor: "#75759047",
              height: "60px",
              padding: "5px"
            }}
          >
            <div className={this.state.loading ? "no-click" : undefined}>
              <DateRangePicker
                className={this.state.loading ? "no-click" : undefined}
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                isOutsideRange={() => false}
                displayFormat="DD MMM YYYY"
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) =>
                  this.onDatesChange(startDate, endDate)
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                } // PropTypes.func.isRequired,
                startDatePlaceholderText={"Data inizio"}
                endDatePlaceholderText={"Data fine"}
                class={this.state.loading ? "no-click" : undefined}
              />
              {this.state.dateChanged && (
                <Button
                  isLoading={this.state.loading}
                  ml={2}
                  colorScheme="green"
                  onClick={() => this.loadData()}
                  size="sm"
                >
                  <FontAwesomeIcon className="icn" icon={["fa", "check"]} />
                </Button>
              )}
            </div>
          </div>{" "}
          <Heading size="lg">Report giacenze</Heading>
          {this.renderAlert()}
          {this.renderProducts()}
        </Container>
      </section>
    );
  }
}

export default withGiacenzeCtx(
  withModalsCtx(withRouter(withAdminCtx(withWareCtx(DReportGiacenze))))
);
