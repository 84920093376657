import firebase from "firebase/compat/app";

import "./scss/custom.css";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/messaging";
import "firebase/compat/functions";
import Utils from "./Utils";

let config = {
  apiKey: "AIzaSyDDx2fcud6JKysoawobZRCASOsOdsRwACc",
  authDomain: "mylab-warehouse.firebaseapp.com",
  databaseURL: "https://mylab-warehouse.firebaseio.com",
  projectId: "mylab-warehouse",
  storageBucket: "mylab-warehouse.appspot.com",
  messagingSenderId: "731518269030"
};
const firebaseApp = firebase.initializeApp(config);
// firebaseApp.firebase_.firestore().settings({});

// firebase
//   .firestore()
//   .enablePersistence({ experimentalTabSynchronization: true })
//   .then(() => {
//     Utils.log("Enabled local persistance");
//   })
//   .catch(function(err) {
//     Utils.handleError(err);
//     console.error("cannot do local persistance", err);
//     if (err.code === "failed-precondition") {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === "unimplemented") {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });

window.firebase = firebase;

try {
  let app = window.firebase.app();
  let features = ["auth", "database", "messaging", "storage"].filter(
    feature => typeof app[feature] === "function"
  );
  Utils.log(`Firebase SDK loaded with ${features.join(", ")}`);
} catch (e) {
  console.error("Error loading the Firebase SDK, check the console.", e);
}
