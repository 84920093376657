import React, { useEffect, useState } from "react";
import {
  Heading,
  Button,
  SimpleGrid,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton
} from "@chakra-ui/react";

export default function ModalUI({ children, open }) {
  const [openModal, setopenModal] = useState(open);
  console.log("AA opne", open, openModal);

  useEffect(() => {
    setopenModal(open);
  }, [open]);

  return (
    <Modal isOpen={openModal} onClose={() => setopenModal(null)} size={"6xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody className="admin-panel">{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
