import React, { Component } from "react";
import { Container, Columns, Column } from "bloomer";
import { withAdminCtx } from "../contexts/AdminContext";
import Loader from "../components/Loader";
import MyTable from "../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { Button } from "bloomer/lib/elements/Button";
import { withWareCtx } from "../contexts/WarehouseContext";
import firebase from "firebase/compat/app";

import { withModalsCtx } from "../contexts/ModalsContext";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController
} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/it";
import AdminNavbar from "../components/admin/AdminNavbar";

var moment = require("moment");

class DEntrate extends Component {
  state = {
    date: [],
    calendarShowing: false,
    filteredDocs: [],
    startDate: moment(new Date()).add(-30, "days"),
    endDate: moment(new Date())
  };

  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  componentDidMount() {
    this.setState({
      docs: this.props.admin.documents.docsEntrata,
      filteredDocs: this.props.admin.documents.docsEntrata
    });
  }

  onModify(p, id, e) {
    if (e.metaKey) {
      window.open("documentDetails/" + p.uid + "/" + p.type, "_blank");
      return;
    }
    this.props.history.push("documentDetails/" + p.uid + "/" + p.type);
  }

  onPrint(p) {
    const w = window.open("/printDoc/" + p.uid);
  }

  onDelete(p) {
    this.props.modals.addModal(
      "Confermi eliminazione " + p.codeNumber,
      <div />,
      [
        {
          label: "Esci",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "Elimina",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDelete(p);
        } else {
          this.props.history.goBack();
        }
      }
    );
  }

  async confirmDelete(p) {
    const productSnap = await firebase
      .firestore()
      .collection("documents")
      .doc(p.uid)
      .delete();

    this.props.admin.changeProductQuantity(p, true);
  }

  addProduct() {
    this.props.history.push("entrataAdd");
  }

  onDatesChange(startDate, endDate) {
    this.setState({ startDate, endDate });

    if (!startDate || !endDate) return;

    const docs = this.state.docs.filter(
      d =>
        d.dateTimestamp.seconds >= startDate.valueOf() / 1000 &&
        d.dateTimestamp.seconds <= endDate.valueOf() / 1000
    );

    this.setState({ filteredDocs: docs });
  }

  render() {
    const { filteredDocs } = this.state;

    if (this.props.admin.documents.loading || !filteredDocs) return <Loader />;

    const columns = [
      {
        Header: "Numero",
        accessor: "codeNumber"
      },
      {
        Header: "Data",
        accessor: "date"
      },
      {
        Header: "Codice",
        accessor: "code"
        // maxWidth: 100,
        // style: { textAlign: "center" },
        // styleWarning: { color: "red", textAlign: "center" }
      },
      {
        Header: "Fornitore",
        accessor: "contact",
        subaccessor: "name"
      },
      {
        Header: "Creato da",
        accessor: "creatorName"
      }
    ];

    return (
      <section>
        <AdminNavbar />
        <Container>
          <Columns>
            <Column>
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                isOutsideRange={() => false}
                displayFormat="DD MMM YYYY"
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) =>
                  this.onDatesChange(startDate, endDate)
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                startDatePlaceholderText={"Data inizio"}
                endDatePlaceholderText={"Data fine"}
              />
            </Column>
          </Columns>
          <Columns className="admin-header mt-5">
            <Column>
              <h2 className="title has-text-black ">Documenti d'entrata</h2>
            </Column>

            <Column>
              <Button
                className="is-primary float-right mt-15"
                onClick={() => this.addProduct()}
              >
                AGGIUNGI
              </Button>
            </Column>
          </Columns>
          <div className="admin-panels">
            <MyTable
              data={filteredDocs}
              columns={columns}
              onModify={(u, id, e) => this.onModify(u, id, e)}
              onDelete={u => this.onDelete(u)}
              onPrint={u => this.onPrint(u)}
              {...this.props}
            />
          </div>
        </Container>
      </section>
    );
  }
}

export default withModalsCtx(withRouter(withAdminCtx(withWareCtx(DEntrate))));
