import { Decimal } from "decimal.js";
import firebase from "firebase/compat/app";

import DateUtils from "./services/DateUtils";
import DocumentType from "./enums/DocumentType";
export default class Utils {
  static getBrands(onlyBrands) {
    if (!!onlyBrands)
      return [
        "T-rexintegratori",
        "Mylabnutrition",
        "Nutracle",
        "Supp4Pets",
        "Altro"
      ];
    else
      return [
        "T-rexintegratori",
        "Mylabnutrition",
        "eBay",
        "Amazon",
        "Myrebranding",
        "Nutracle",
        "Supp4Pets",
        "Offline",
        "Altro"
      ];
  }

  static getPaymentsMethod() {
    return ["Bonifico", "PayPal", "Buono Amazon", "Coupon"];
  }

  static hasResto(a, b) {
    return Math.floor(a / b) !== Utils.mlround(a / b);
  }

  static checkValueUndefined(obj) {
    console.log("AA obj", obj);

    for (var propt in obj) {
      if (typeof obj[propt] === "object") {
        return this.checkValueUndefined(obj[propt]);
      }

      if (obj[propt] == null) {
        console.log("AA propt, obj[propt]", propt, obj[propt]);
        return propt;
      }
    }
  }

  static getCodeNumber(doc) {
    let codeNumber = doc.number
      ? doc.type.charAt(0).toUpperCase() + doc.number
      : "";
    if (doc.type == DocumentType.USCITA_PROD) codeNumber = "O" + doc.number;
    return codeNumber;
  }

  static mlround(i) {
    return Math.round(i * 100) / 100;
  }

  static handleError(e) {
    // return setTimeout(() => {
    console.log("handling error");
    console.error(e);
    if (!e.url || e.url.indexOf("localhost") !== -1)
      if (e instanceof Event) window.dispatchEvent(e);
      else if (e instanceof Error)
        window.dispatchEvent(
          new ErrorEvent("webapp-error", {
            colno: 12,
            error: e,
            filename: "Utils.js",
            lineno: 4,
            message: e.message
          })
        );
      else console.error("handleError: e is not an event", e);
    else console.warn("Running on localhost, error not saved", e);
    // });
  }

  static getParameterByName(name, url) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  static getRecipeDailyGrams(recipe) {
    return recipe.elements
      .map(e => new Decimal(e.quantity))
      .reduce((add, curr) => add.add(curr), new Decimal(0))
      .toDecimalPlaces(2)
      .toString();
  }

  static getRecipeTotalSachets(recipe) {
    var totalSachets = new Decimal(0);
    recipe.elements.forEach(e => {
      const sachets = new Decimal(e.quantity).dividedBy(e.sachetGrams);
      totalSachets = totalSachets.add(sachets);
    });

    return totalSachets.toDecimalPlaces(2).toString();
  }

  async getRecipeDailyPrice(recipe) {
    const elements = [];
    const promises = [];
    recipe.elements.forEach(e => {
      promises.push(
        new Promise(async (r, rj) => {
          try {
            const snap = await firebase
              .firestore()
              .collection("elements")
              .doc(e.uid)
              .get();
            const data = snap.data();
            const pps = new Decimal(data.sachetPrice);
            const gps = new Decimal(data.sachetGrams);
            const qt = new Decimal(e.quantity);

            const price = qt
              .dividedBy(gps)
              .floor()
              .times(pps)
              .add(qt.modulo(gps).isZero() ? 0 : 1);

            elements.push(price);

            r(price);
          } catch (e) {
            rj(e);
          }
        })
      );
    });
    await Promise.all(promises);

    return elements
      .reduce((acc, curr) => acc.add(curr), new Decimal(0))
      .toDecimalPlaces(2)
      .toFixed(2);
  }

  static toEpoch(date) {
    if (!date) return null;
    const time = date.getTime();
    return new Date(time - (time % 86400000));
  }

  static getCurrentEpoch() {
    return Utils.toEpoch(new Date());
  }

  static dateFilter(data, initDate, currDate) {
    let rData = [];
    // per ogni data, tra la data inizio e la data fine
    for (
      let d = new Date(initDate.getTime());
      d < currDate;
      d.setDate(d.getDate() + 1)
    ) {
      // inserisci un elemento che abbia come (x) la data
      // e come (y) la somma delle revenue in quella data
      rData.push({
        x: new Date(d.getTime()),
        y: data
          .filter(e => e.x.getTime() === d.getTime())
          .reduce((a, b) => Number(a.y || 0) + Number(b.y || 0), 0)
      });
    }
    return rData;
  }

  static getLastMonthEpoch() {
    return Utils.toEpoch(
      new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
    );
  }

  static getLastWeekEpoch() {
    return Utils.toEpoch(
      new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
    );
  }

  static calcPerc(value, max) {
    return Math.floor((value / max) * 100 * 100) / 100;
  }

  static decodeRevenueStatus(status) {
    switch (status) {
      case "PAID":
        return "Commissione affiliazione pagata";
      case "CANCELED":
        return "Ordine cliente annullato";
      case "CONFIRMED":
        return "Ordine cliente pagato";
      case "TO_BE_CONFIRMED":
        return "Ordine in processamento";
      default:
        return status;
    }
  }

  static toYYYMMDD(date) {
    if (!date) return null;
    let d = Utils.parseDate(date);
    if (d) return d.toISOString().substr(0, 10);
    else return date;
  }

  static dateToYYYMMDD(date) {
    if (!date) return null;
    try {
      return date.toISOString().substr(0, 10);
    } catch (e) {
      console.log("Wrong date");
    }
  }

  static parseDate(date) {
    DateUtils.timestampToDate(date);
    return null;
  }

  static formatDate(ts) {
    const date = DateUtils.timestampToDate(ts);
    if (date) {
      date.toLocaleDateString();
      return new Intl.DateTimeFormat("it-IT", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      }).format(date);
    } else return "";
  }

  static formatDateMonthYear(ts) {
    const date = DateUtils.timestampToDate(ts);
    if (date) {
      date.toLocaleDateString();
      return new Intl.DateTimeFormat("it-IT", {
        year: "numeric",
        month: "long"
      }).format(date);
    } else return "";
  }

  static formatDateTime(ms) {
    let d = new Date(ms);
    if (d) {
      return d.toLocaleString("it-IT", {
        // navigator.languages[0] || navigator.language || "it-IT"
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      });
    }
    return null;
  }

  static msToDate(ms) {
    if (ms && ms > 0) return new Date(ms);
    return null;
  }

  static mapRevenueToXY(e) {
    return {
      x: new Date(e.creation),
      y: e.amount
    };
  }

  static stringToCurrency(s) {
    if (!s) return "0 €";
    return (
      s.toLocaleString(undefined, {
        maximumFractionDigits: 2
      }) + " €"
    );
  }

  static fixObj(obj) {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop] !== "" || obj[prop] !== "") {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  }
  static emptyObj(obj) {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
      newObj[prop] = "";
    });
    return newObj;
  }

  static emailIsVaild(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!!email) {
      const isValid = re.test(String(email).toLowerCase());
      return isValid;
    }
    return false;
  }

  static log(text, ...optionalParams) {
    console.log(text, ...optionalParams);
  }

  static percent(val) {
    return (val * 100).toFixed(2);
  }

  static getTipoCliente(c, value) {
    if (!c.typeMultiple) return false;
    let found = false;
    c.typeMultiple.forEach(v => {
      if (v === value) found = true;
    });

    return found;
  }

  static getCanaleVendita(doc, canaleValue) {
    if (!doc.canaleVenditaMultiplo) return false;
    let found = false;
    //console.log("canaleVenditaMultiplo", doc.canaleVenditaMultiplo);
    if (Array.isArray(doc.canaleVenditaMultiplo)) {
      doc.canaleVenditaMultiplo.forEach(v => {
        if (v === canaleValue) found = true;
      });
    } else {
      found = doc.canaleVenditaMultiplo === canaleValue;
    }

    return found;
  }

  static prune(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  }
}
