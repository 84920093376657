import { Button, Tooltip } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportToCsv } from "export-to-csv-file";

export function ExporterButton({ data, prepare, title, filename, icon }) {
  const exportCSV = () => {
    let _data = [];
    data.forEach(o => {
      _data.push(prepare(o));
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      filename,
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(_data);
  };
  return icon ? (
    <FontAwesomeIcon
      icon={["fa", "file-csv"]}
      style={{ cursor: "pointer" }}
      onClick={() => exportCSV()}
    />
  ) : (
    <Button colorScheme="green" onClick={() => exportCSV()}>
      ESPORTA
    </Button>
  );
}
