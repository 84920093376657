import React, { Component } from "react";
import { withRouter } from "react-router";
import { withModalsCtx } from "../../contexts/ModalsContext";
import DGenericProduct from "../../components/DGenericProduct";
import { withWareCtx } from "../../contexts/WarehouseContext";
import { withAdminCtx } from "../../contexts/AdminContext";
import AdminNavbar from "../../components/admin/AdminNavbar";

class DElementDetails extends Component {
  state = {
    elementProducts: [],
  };
  onSave() {
    this.props.history.push("/elements");
  }

  async componentDidMount() {
    console.log("AA add", this.props.adding);
    const { products } = this.props.admin.products;
    console.log("products", products);

    let elementProducts = [];
    products.forEach((p) => {
      const composition = p.composition;
      p.link = "/productDetails/" + p.uid;
      if (!!composition) {
        composition.forEach((c) => {
          if (c.uid == this.props.match.params.pUid) elementProducts.push(p);
        });
      }
    });

    this.setState({ elementProducts });
    console.log("AA products", elementProducts);
  }

  render() {
    const elementProducts = this.state.elementProducts;
    const fields = [
      {
        Header: "Nome",
        accessor: "name",
        type: "text",
      },
      {
        Header: "Giacenza",
        accessor: "quantity",
        maxWidth: 100,
        style: { textAlign: "center" },
        type: "number",
        blocked: !this.props.ware.isAdmin,
      },
      {
        Header: "Unità Misura",
        accessor: "unitmeasure",
        type: "multiValues",
        values: ["grammi", "litri", "pezzi"],
      },
      {
        Header: "Ordine minimo",
        accessor: "ordineMinimo",
        type: "number",
      },
      {
        Header: "Cod",
        accessor: "code",
      },
      {
        Header: "Fornitore",
        accessor: "supplier",
      },
      {
        Header: "Nostra Fornitura",
        accessor: "nostraFornitura",
        type: "multiValues",
        values: ["Si", "No"],
      },
      {
        Header: "Creato da",
        accessor: "creatorName",
        blocked: true,
      },
    ];

    return (
      <div>
        <AdminNavbar />
        <DGenericProduct
          table="elements"
          uid={this.props.match.params.pUid}
          fields={fields}
          usedOn={elementProducts}
          onSave={() => this.onSave()}
          adding={this.props.adding}
          lotti
          canBeModified={this.props.ware.isCreator}
          {...this.props}
        />
      </div>
    );
  }
}

export default withModalsCtx(withAdminCtx(withRouter(withWareCtx(DElementDetails))));
