import React, { Component, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import firebase from "firebase/compat/app";

import Loader from "../components/Loader";
import { Columns, Column } from "bloomer";
import { Container } from "bloomer/lib/layout/Container";
import { Label } from "bloomer/lib/elements/Form/Label";
import Decimal from "decimal.js";
import DocumentType from "../enums/DocumentType";
import Utils from "../Utils";
import ReactToPrint, { useReactToPrint } from "react-to-print";

import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Flex,
  Button,
  Box,
  Heading,
  Text
} from "@chakra-ui/react";

class DPrintedDoc extends React.PureComponent {
  state = {
    doc: null
  };

  constructor(props) {
    super(props);
    this.getTotale = this.getTotale.bind(this);
    this.getFullAddress = this.getFullAddress.bind(this);
  }

  async componentDidMount() {
    // const uid = this.props.match.params.pUid;
    const uid = this.props.uid || this.props.match.params.pUid;

    const docSnap = await firebase
      .firestore()
      .collection("documents")
      .doc(uid)
      .get();

    if (docSnap.exists) {
      let doc = docSnap.data();
      doc.dateTimestamp = doc.date;

      const data = Utils.formatDate(doc.date);
      doc.date = data;
      doc.codeNumber = Utils.getCodeNumber(doc);
      this.setState({ doc });
    }
  }

  getPrezzoIvato(p) {
    return ((p.price_sell * p.quantity * (100 + p.iva)) / 100).toFixed(2);
  }

  getPrezzoScontato(p) {
    return ((this.getPrezzoIvato(p) * (100 - p.sconto)) / 100).toFixed(2);
  }

  renderProducts() {
    const { doc } = this.state;
    const preventivo = doc.type === "preventivo";
    return (
      <div className="prodotti">
        <div className="header-prodotti">
          <Columns>
            <Column style={{ minWidth: "200px" }}>
              <Label>Prodotto</Label>
            </Column>
            <Column style={{ minWidth: "100px", textAlign: "right" }}>
              <Label>Lotto</Label>
            </Column>

            <Column style={{ width: "50px" }}>
              <Label className="ta-c">Quantità</Label>
            </Column>
            {preventivo && (
              <Column>
                <Label className="ta-r">Prezzo Unit.</Label>
              </Column>
            )}
            {preventivo && (
              <Column>
                <Label className="ta-r">Prezzo Totale</Label>
              </Column>
            )}
            {preventivo && (
              <Column>
                <Label className="ta-c">IVA</Label>
              </Column>
            )}
            {preventivo && (
              <Column>
                <Label className="ta-c">Sconto</Label>
              </Column>
            )}
            {preventivo && (
              <Column>
                <Label className="ta-r">Prezzo Finale</Label>
              </Column>
            )}
          </Columns>
        </div>
        {!!doc.products &&
          doc.products.map(s => {
            return (
              <div key={s.uid}>
                <Columns>
                  <Column style={{ minWidth: "200px" }}>
                    <Label>{s.nomeDisplay}</Label>
                  </Column>
                  <Column style={{ minWidth: "100px", textAlign: "right" }}>
                    <Label>{s.lotto}</Label>
                  </Column>

                  <Column style={{ width: "50px" }}>
                    <Label className="ta-c">{s.quantity}</Label>
                  </Column>
                  {preventivo && (
                    <Column>
                      <Label className="ta-r">
                        {Utils.stringToCurrency(
                          parseFloat(s.price_sell).toFixed(2)
                        )}
                      </Label>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Label className="ta-r">
                        {Utils.stringToCurrency(
                          (s.price_sell * s.quantity).toFixed(2)
                        )}
                      </Label>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Label className="ta-c">{s.ivaDisplay}</Label>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Label className="ta-c">{s.sconto}%</Label>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Label className="ta-r">
                        {Utils.stringToCurrency(this.getPrezzoScontato(s))}
                      </Label>
                    </Column>
                  )}
                </Columns>

                {s.combo &&
                  s.prodottiFigli.map((c, kdx) => {
                    return (
                      <Box
                        key={kdx}
                        bg="gray.200"
                        paddingLeft="30px"
                        mt="-20px"
                        mb={4}
                      >
                        <Columns>
                          <Column style={{ minWidth: "170px" }}>
                            <Text fontSize={"xs"}>{c.name}</Text>
                          </Column>
                          <Column
                            style={{
                              minWidth: "70px",
                              textAlign: "right"
                            }}
                          >
                            <Text fontSize={"xs"}> {c.lotto}</Text>
                          </Column>
                          <Column style={{ width: "80px" }}></Column>
                        </Columns>
                      </Box>
                    );
                  })}
              </div>
            );
          })}
      </div>
    );
  }

  getTotale() {
    const { doc } = this.state;
    let totale = new Decimal(0);
    {
      doc.products &&
        doc.products.forEach(p => {
          const priceTot = p.price_sell * p.quantity;
          console.log("AA pricetot", priceTot);
          totale = totale.add(p.price_sell * p.quantity);
        });
    }
    return totale;
  }

  getTotaleIva() {
    const { doc } = this.state;
    let totaleIva = new Decimal(0);
    {
      doc.products &&
        doc.products.forEach(p => {
          totaleIva = totaleIva.add(this.getPrezzoSoloIva(p));
          console.log("AA totaleIva", totaleIva);
        });
    }
    return totaleIva;
  }

  getPrezzoSoloIva(p) {
    return (
      (((p.price_sell * (100 - p.sconto)) / 100) * p.quantity * p.iva) /
      100
    ).toFixed(2);
  }

  renderTotale() {
    const { doc } = this.state;

    return (
      <div className="avoid-page-break">
        {/* <hr style={{ border: "1px solid black" }} /> */}
        <Columns className="mt-20">
          <Column>
            <h4 className="subtitle has-text-black ">Totale </h4>
          </Column>

          <Column>
            <h4 className="subtitle has-text-black float-right">
              {Utils.stringToCurrency(doc.totalPrice)}
            </h4>
          </Column>
        </Columns>
        {doc.type === DocumentType.PREVENTIVO && (
          <Columns style={{ marginTop: "-30px" }}>
            <Column>
              <h4 className="subtitle has-text-black ">Di cui IVA </h4>
            </Column>
            <Column>
              <h4 className="subtitle has-text-black float-right">
                {Utils.stringToCurrency(this.getTotaleIva())}
              </h4>
            </Column>
          </Columns>
        )}
        {!!doc.scontoTotale && doc.scontoTotale > 0 && (
          <Columns style={{ marginTop: "-30px" }}>
            <Column>
              <h4 className="subtitle has-text-black ">
                Sconto o arrotondamento
              </h4>
            </Column>
            <Column>
              <h4 className="subtitle has-text-black float-right">
                {Utils.stringToCurrency(doc.scontoTotale)}
              </h4>
            </Column>
          </Columns>
        )}

        {!!doc.speseSpedizione && doc.speseSpedizione > 0 && (
          <Columns style={{ marginTop: "-30px" }}>
            <Column>
              <h4 className="subtitle has-text-black ">
                Spese Spedizione (iva inclusa)
              </h4>
            </Column>
            <Column>
              <h4 className="subtitle has-text-black float-right">
                {Utils.stringToCurrency(doc.speseSpedizione.toFixed(2))}
              </h4>
            </Column>
          </Columns>
        )}

        <Columns style={{ marginTop: "-30px" }}>
          <Column>
            <h4 className="title has-text-black ">Totale Scontato</h4>
          </Column>
          <Column>
            <h4 className="title has-text-black float-right">
              {Utils.stringToCurrency(doc.finalPrice.toFixed(2))}
            </h4>
          </Column>
        </Columns>
      </div>
    );
  }

  modalitaPagamento() {
    const { doc } = this.state;

    return (
      <Columns className="avoid-page-break">
        <Column>
          <div className="box mt-20">
            <h3>Modalità di pagamento:</h3>
            {doc.modalitaPagamento === "Paypal" && (
              <div>
                <h3>Paypal</h3>
                <h3>Account: info@mylabnutrition.net</h3>
              </div>
            )}
            {doc.modalitaPagamento === "Bonifico" && (
              <div>
                <h3>Bonifico bancario</h3>
                <h3>My Lab Nutrition s.r.l IBAN IT18V0306939300100000006652</h3>
              </div>
            )}
            {doc.modalitaPagamento === "Contrassegno" && (
              <div>
                <h3>Contrassegno</h3>
              </div>
            )}
          </div>
        </Column>
        <Column>
          <div className="box mt-20">
            <h3>{doc.note}</h3>
          </div>
        </Column>
      </Columns>
    );
  }

  renderDistintaNote() {
    const { doc } = this.state;

    return (
      <div>
        <h3 style={{ marginTop: "10px", marginBottom: "-12px" }}>
          Colli: {doc.colli || "-"}{" "}
          <span>Aspetto esteriore: {doc.aspetto || "-"}</span>
        </h3>

        <Columns style={{ marginTop: "-25px" }}>
          <Column>
            <div className="box mt-20">
              <h3>{doc.note}</h3>
            </div>
          </Column>
          <Column>
            <h3 style={{ marginTop: "20px" }}>Data</h3>
            <h3 style={{ marginTop: "30px" }}>Firma</h3>
          </Column>
        </Columns>
      </div>
    );
  }

  getFullAddress(a) {
    return (
      (!!a.via && a.via + ", ") +
      (a.numero && a.numero + ", ") +
      (a.citta && a.citta + ", ") +
      (a.cap && a.cap)
    );
  }

  renderContact() {
    const { contact, shippingAddress: docShipping } = this.state.doc;
    if (!contact) return;

    const {
      billingAddress,
      shippingAddress: contactShipping,
      codFiscale,
      partitaIva
    } = contact;

    const shippingAddress = docShipping || contactShipping;

    return (
      <div className="mt-10">
        <Columns>
          <Column>
            <h3>Destinazione Merce</h3>

            <div className="box">
              {!!shippingAddress && (
                <div>
                  <h3>{!!shippingAddress.nome && shippingAddress.nome}</h3>
                  <h3>{this.getFullAddress(shippingAddress)}</h3>
                  <h3>{shippingAddress.nazione && shippingAddress.nazione}</h3>
                </div>
              )}
              <h3>{!!contact.phone && contact.phone}</h3>
            </div>
          </Column>

          <Column>
            <h3>Cliente</h3>
            <div className="box">
              {!!billingAddress && (
                <div>
                  <h3>{!!billingAddress.nome && billingAddress.nome}</h3>
                  <h3>{this.getFullAddress(billingAddress)}</h3>
                  <h3>{!!billingAddress.nazione && billingAddress.nazione}</h3>
                  <h3>
                    {!!codFiscale && "Cod.Fiscale: " + codFiscale}{" "}
                    {!!partitaIva && "Partita IVA: " + partitaIva}
                  </h3>
                </div>
              )}
              <h3>{!!contact.codSDI && "SDI: " + contact.codSDI}</h3>
            </div>
          </Column>
        </Columns>
      </div>
    );
  }

  renderHeader() {
    return (
      <div>
        <Columns>
          <Column>
            <img style={{ height: "70px" }} src="/images/mylabLogo.png" />
          </Column>
          <Column className="has-text-centered">
            <h2>MyLab Nutrition S.r.l.</h2>
            <h4>Via Marsala 29 H - 00185 Roma</h4>
            <h4>Tel. 329 2980 405 - Email info@mylabnutrition.net</h4>
            <h4>Iva: 14602891005 REA: RM - 1533225</h4>
          </Column>
        </Columns>
      </div>
    );
  }
  render() {
    const { doc } = this.state;
    if (!doc) return <Loader />;

    let title = "";
    if (doc.type === DocumentType.ENTRATA) title = "Distinta d'entrata";
    else if (doc.type == DocumentType.DISTINTA) title = "Distinta d'uscita";
    else if (doc.type == DocumentType.PREVENTIVO) title = "Preventivo";

    if (!!doc.codeNumber) title = title + " - " + doc.codeNumber;

    console.log("AA doc type", doc.type, title);

    return (
      <div className="printed-doc">
        <Container>
          {this.renderHeader()}

          <div className="separator" />
          <h2>{title}</h2>
          {doc.type === DocumentType.DISTINTA ? (
            <h3>
              Data documento {doc.date} - Data spedizione {doc.dataSpedizione}
            </h3>
          ) : (
            <h3>{doc.date}</h3>
          )}

          {this.renderContact()}

          {this.renderProducts()}

          {doc.type === "preventivo" && this.renderTotale()}

          {doc.type === "preventivo" && this.modalitaPagamento()}

          {doc.type === "distinta" && this.renderDistintaNote()}
        </Container>
      </div>
    );
  }
}

export default withRouter(DPrintedDoc);

export function PrintModal({ isOpen, onClose, uid, onPrint }) {
  const componentRef = useRef();
  const [dataLoaded, setDataLoaded] = useState(false);
  const onBeforeGetContentResolve = useRef();

  const handleOnBeforeGetContent = () => {
    return new Promise(resolve => {
      // `react-to-print` will wait for this Promise to resolve before continuing
      // Load data
      onBeforeGetContentResolve.current = resolve;
      setDataLoaded(true); // When data is done loading
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: handleOnBeforeGetContent
  });

  useEffect(() => {
    if (dataLoaded) {
      // Resolves the Promise, telling `react-to-print` it is time to gather the content of the page for printing
      onBeforeGetContentResolve.current();
    }
  }, [dataLoaded, onBeforeGetContentResolve]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justify={"end"}>
            <Button colorScheme="green" mr={3} onClick={onClose}>
              Chiudi
            </Button>
            <Button variant="ghost" onClick={() => onPrint(uid)}>
              Stampa
              {/* <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return <a href="#">Stampa</a>;
                }}
                content={() => componentRef.current}
              /> */}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <DPrintedDoc uid={uid} ref={componentRef} />
          {/* <ComponentToPrint ref={componentRef} /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
