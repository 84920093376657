import React, { Component } from "react";
import { withRouter } from "react-router";
import { withModalsCtx } from "../../contexts/ModalsContext";
import DGenericProduct from "../../components/DGenericProduct";
import { withAdminCtx } from "../../contexts/AdminContext";
import { withWareCtx } from "../../contexts/WarehouseContext";
import firebase from "firebase/compat/app";

class DContactDetails extends Component {
  onSave() {
    console.log("AA onSave");
    this.props.history.push("/anagrafica");
  }

  async onCheckData(c) {
    console.log("aa check data", c);

    if (!!c.partitaIva) {
      const contactSnap = await firebase
        .firestore()
        .collection("contacts")
        .where("partitaIva", "==", c.partitaIva)
        .get();

      if (
        contactSnap.docs.length > 0 &&
        contactSnap.docs[0].data().uid != c.uid
      ) {
        this.props.modals.addModal(
          "Partita Iva già assegnata al contatto " +
            contactSnap.docs[0].data().name,
          <div />,
          [
            {
              label: "Esci",
              className: "button is-primary is-fullwidth",
              result: "chiudi"
            }
          ],
          false,
          result => {}
        );
        return false;
      }
    }

    if (!!c.codFiscale) {
      const contactSnap = await firebase
        .firestore()
        .collection("contacts")
        .where("codFiscale", "==", c.codFiscale)
        .get();

      if (
        contactSnap.docs.length > 0 &&
        contactSnap.docs[0].data().uid != c.uid
      ) {
        this.props.modals.addModal(
          "Codice fiscale già assegnata al contatto " +
            contactSnap.docs[0].data().name,
          <div />,
          [
            {
              label: "Esci",
              className: "button is-primary is-fullwidth",
              result: "chiudi"
            }
          ],
          false,
          result => {}
        );
        return false;
      }
    }

    return true;
  }

  render() {
    const fields = [
      {
        Header: "Nome",
        accessor: "name",
        type: "text"
      },
      {
        Header: "Ragione Sociale",
        accessor: "ragioneSociale",
        type: "text"
      },
      {
        Header: "Partita Iva",
        accessor: "partitaIva",
        type: "text"
      },
      {
        Header: "Codice Fiscale",
        accessor: "codFiscale",
        type: "text"
      },
      {
        Header: "Codice SDI",
        accessor: "codSDI",
        type: "text"
      },
      {
        Header: "eMail",
        accessor: "email",
        type: "email"
      },
      {
        Header: "PEC",
        accessor: "pec",
        type: "email"
      },
      {
        Header: "Telefono",
        accessor: "phone",
        type: "phone"
      },
      // {
      //   Header: "Professione",
      //   accessor: "professione",
      //   type: "multiValues",
      //   values: ["Proprietario Palestra", "Personal Trainer", "Imprenditore Generico", "Proprietario Farmacia/Parafarmacia", "Nutrizionista", "Centro Estetico", "Altro"]
      // },
      {
        Header: "Tipo",
        accessor: "typeMultiple",
        type: "multiCheckbox",
        values: ["Cliente", "Fornitore", "Affiliato"]
      },
      // {
      //   Header: "Brand",
      //   accessor: "brands",
      //   type: "multiCheckbox",
      //   values: ["T-Rex Integratori", "Nutracle", "Supp4Pets", "MyLabNutrition", "MyRebranding"]
      // },
      // {
      //   Header: "Canale di provenienza",
      //   accessor: "provenienza",
      //   type: "multiValues",
      //   values: ["Whatsapp", "Facebook", "Instagram", "LinkedIn", "Email", "Telefono", "Altro"]
      // },
      {
        type: "line"
      },
      {
        Header: "Indirizzo Fatturazione",
        accessor: "billingAddress",
        type: "address",
        style: { width: "50%", display: "inline-block", paddingRight: "10px" },
        values: [
          {
            Header: "Nome",
            accessor: "nome",
            type: "text"
          },
          {
            Header: "Via",
            accessor: "via",
            type: "Geosuggest"
          },
          {
            Header: "Numero",
            accessor: "numero",
            type: "text"
          },
          {
            Header: "Città",
            accessor: "citta",
            type: "text"
          },
          {
            Header: "Regione",
            accessor: "regione",
            type: "text"
          },
          {
            Header: "Nazione",
            accessor: "nazione",
            type: "text"
          },
          {
            Header: "Cap",
            accessor: "cap",
            type: "text"
          }
        ]
      },
      {
        Header: "Indirizzo Spedizione",
        // accessor: "shippingAddress",
        // type: "address",
        accessor: "shippingAddressMulti",
        type: "addressMulti",
        style: { width: "50%", display: "inline-block", paddingLeft: "10px" },
        values: [
          {
            Header: "Nome",
            accessor: "nome",
            type: "text"
          },
          {
            Header: "Via",
            accessor: "via",
            type: "Geosuggest"
          },
          {
            Header: "Numero",
            accessor: "numero",
            type: "text"
          },
          {
            Header: "Città",
            accessor: "citta",
            type: "text"
          },
          {
            Header: "Regione",
            accessor: "regione",
            type: "text"
          },
          {
            Header: "Nazione",
            accessor: "nazione",
            type: "text"
          },
          {
            Header: "Cap",
            accessor: "cap",
            type: "text"
          }
        ]
      },
      {
        type: "line"
      },
      {
        Header: "Creato da",
        accessor: "creatorName",
        blocked: true
      }
    ];

    return (
      <DGenericProduct
        table="contacts"
        contacts={true}
        uid={this.props.match.params.pUid}
        fields={fields}
        onSave={() => this.onSave()}
        onCheckData={c => this.onCheckData(c)}
        adding={this.props.adding}
        canBeModified
        {...this.props}
      />
    );
  }
}

export default withWareCtx(
  withAdminCtx(withModalsCtx(withRouter(DContactDetails)))
);
