import React, { Component } from "react";
import MyTable from "../../components/admin/MyTable";
import firebase from "firebase/compat/app";

import { Button } from "bloomer/lib/elements/Button";
import { Modal } from "bloomer/lib/components/Modal/Modal";
import { ModalBackground } from "bloomer/lib/components/Modal/ModalBackground";
import { ModalCard } from "bloomer/lib/components/Modal/Card/ModalCard";
import { ModalCardHeader } from "bloomer/lib/components/Modal/Card/ModalCardHeader";
import { ModalCardTitle } from "bloomer/lib/components/Modal/Card/ModalCardTitle";
import { ModalCardBody } from "bloomer/lib/components/Modal/Card/ModalCardBody";
import { Field, Input, Columns } from "bloomer";
import { TextArea } from "bloomer/lib/elements/Form/TextArea";
import { Column } from "bloomer/lib/grid/Column";
import DateUtils from "../../services/DateUtils";
import Utils from "../../Utils";
import { Label } from "bloomer/lib/elements/Form/Label";
import { Checkbox } from "bloomer/lib/elements/Form/Checkbox";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import Loader from "../../components/Loader";

export default class DContactCommissioni extends Component {
  state = {
    commissioni: [],
    modalActive: false,
    modifyRecord: false,
    modifyRow: 0,
    date: "",
    brand: "-",
    quietanza: false,
    modulorichiesta: false,
    estinzione: "",
    commissioniRichieste: 0,
    commissioniEstinte: 0,
    commissioniRimanenti: 0,
    note: ""
  };

  componentDidMount() {
    this.setState({ commissioni: this.props.contact.commissioni || [] });
  }

  onModify(c, idx) {
    console.log("AA onModify", idx);
    const d = DateUtils.timestampToDate(c.date);
    const dd = Utils.dateToYYYMMDD(d);

    this.setState({
      modifyRecord: true,
      date: dd,
      brand: c.brand,
      estinzione: c.estinzione,
      quietanza: c.quietanza,
      modulorichiesta: c.modulorichiesta,
      commissioniRichieste: c.commissioniRichieste,
      commissioniEstinte: c.commissioniEstinte,
      commissioniRimanenti: c.commissioniRimanenti,
      note: c.note,
      modalActive: true,
      modifyRow: idx
    });
  }

  onDelete(u, idx) {
    console.log("AA onDelete");

    this.props.modals.addModal(
      "Confermi eliminazione?",
      <div />,
      [
        {
          label: "ESCI",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "ELIMINA",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDelete(idx);
        }
      }
    );
  }

  confirmDelete(idx) {
    const { commissioni } = this.state;

    commissioni.splice(idx, 1);
    this.setState({ commissioni }, () => this.updateTable(commissioni));

    console.log("AA onDelete confkirm", commissioni);
  }

  async updateTable(commissioni) {
    const { contact } = this.props;
    this.setState({ loading: true });
    await firebase
      .firestore()
      .collection("contacts")
      .doc(contact.uid)
      .update({ commissioni: commissioni });

    this.setState({ loading: false });
  }

  addCommissione() {
    const {
      commissioni,
      modifyRecord,
      modifyRow,
      date,
      brand,
      estinzione,
      quietanza,
      modulorichiesta,
      commissioniRichieste,
      commissioniEstinte,
      commissioniRimanenti,
      note
    } = this.state;
    var datum = firebase.firestore.Timestamp.fromDate(
      new Date(this.state.date)
    );

    if (modifyRecord) {
      const c = commissioni[modifyRow];
      c.date = datum;
      c.brand = brand;
      c.estinzione = estinzione;
      c.quietanza = quietanza;
      c.modulorichiesta = modulorichiesta;
      c.commissioniRichieste = commissioniRichieste;
      c.commissioniEstinte = commissioniEstinte;
      c.commissioniRimanenti = commissioniRimanenti;
      c.note = note;
    } else {
      commissioni.push({
        date: datum,
        brand,
        estinzione,
        quietanza,
        modulorichiesta,
        commissioniRichieste,
        commissioniEstinte,
        commissioniRimanenti,
        note
      });
    }
    console.log("AA onDelete confkirm", commissioni);

    this.setState({ commissioni, modalActive: false }, () =>
      this.updateTable(commissioni)
    );
  }

  handleChange(e) {
    const s = {};
    s[e.target.name] = e.target.value;
    this.setState(s);
  }

  renderBrandDropdown() {
    const brands = Utils.getBrands(true);

    return (
      <div>
        <Label>Brand</Label>
        <Dropdown isActive={this.state.brandActive}>
          <DropdownTrigger>
            <Button
              className="modal-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() =>
                this.setState({ brandActive: !this.state.brandActive })
              }
            >
              {this.state.brand}
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {brands.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() =>
                      this.setState({ brand: e, brandActive: false })
                    }
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  renderEstinzioneDropdown() {
    const payments = Utils.getPaymentsMethod();

    return (
      <div>
        <Label>Estinzione</Label>
        <Dropdown isActive={this.state.estinzioneActive}>
          <DropdownTrigger>
            <Button
              className="modal-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() =>
                this.setState({
                  estinzioneActive: !this.state.estinzioneActive
                })
              }
            >
              {this.state.estinzione}
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {payments.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() =>
                      this.setState({ estinzione: e, estinzioneActive: false })
                    }
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
  render() {
    const { commissioni } = this.state;

    const columns = [
      {
        Header: "Data",
        accessor: "date",
        type: "dateTimestamp"
      },
      {
        Header: "Brand",
        accessor: "brand"
      },
      {
        Header: "Commissioni Richieste",
        accessor: "commissioniRichieste",
        type: "currency"
      },
      {
        Header: "Commissioni Estinte",
        accessor: "commissioniEstinte",
        type: "currency"
      },
      {
        Header: "Commissioni Rimanenti",
        accessor: "commissioniRimanenti",
        type: "currency"
      },
      {
        Header: "Modalità di estinzione",
        accessor: "estinzione"
      },
      {
        Header: "Modulo richiesta",
        accessor: "modulorichiesta",
        type: "checkbox"
      },
      {
        Header: "Invio quietanza",
        accessor: "quietanza",
        type: "checkbox"
      },
      {
        Header: "Note",
        accessor: "note"
      }
    ];
    const { loading } = this.state;
    return (
      <div className="admin-panels">
        {!!loading ? (
          <Loader />
        ) : (
          <MyTable
            data={commissioni}
            columns={columns}
            showDelete
            onDelete={(u, idx) => this.onDelete(u, idx)}
            onModify={(u, idx) => this.onModify(u, idx)}
            {...this.props}
          />
        )}
        <Button
          className="is-primary mt-20"
          onClick={() =>
            this.setState({ modalActive: true, modifyRecord: false })
          }
        >
          AGGIUNGI COMMISSIONE
        </Button>

        <Modal
          className="warehouse-modal has-text-centered"
          isActive={this.state.modalActive}
        >
          <ModalBackground
            onClick={() => this.setState({ modalActive: false })}
          />
          <ModalCard style={{ height: "570px" }}>
            <ModalCardHeader className="no-pb">
              <ModalCardTitle className="has-text-centered no-pl mb-10">
                {this.state.modifyRecord
                  ? "Modifica Commissione"
                  : "Nuova Commissione"}
              </ModalCardTitle>
            </ModalCardHeader>
            <ModalCardBody>
              <Columns>
                <Column>
                  <Label>Data</Label>
                  <Field className="mb-10">
                    <Input
                      name="date"
                      placeholder=""
                      value={this.state.date}
                      type="date"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
                <Column>{this.renderBrandDropdown()}</Column>
                <Column>{this.renderEstinzioneDropdown()}</Column>
              </Columns>
              <Columns>
                <Column>
                  <Label>Commissioni Richieste</Label>
                  <Field className="mb-10">
                    <Input
                      name="commissioniRichieste"
                      placeholder=""
                      value={this.state.commissioniRichieste}
                      type="number"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
                <Column>
                  <Label>Commissioni Estinte</Label>
                  <Field className="mb-10">
                    <Input
                      name="commissioniEstinte"
                      placeholder=""
                      value={this.state.commissioniEstinte}
                      type="number"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
                <Column>
                  <Label>Commissioni Rimanenti</Label>
                  <Field className="mb-10">
                    <Input
                      name="commissioniRimanenti"
                      placeholder=""
                      value={this.state.commissioniRimanenti}
                      type="number"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <Checkbox
                    style={{ marginRight: "5px" }}
                    onClick={() =>
                      this.setState({
                        modulorichiesta: !this.state.modulorichiesta
                      })
                    }
                    checked={this.state.modulorichiesta}
                  >
                    <Label
                      style={{ display: "inline-flex", marginRight: "45px" }}
                      className="ta-l"
                    >
                      Modulo Richiesta
                    </Label>
                  </Checkbox>
                </Column>
                <Column>
                  <Checkbox
                    style={{ marginRight: "5px" }}
                    onClick={() =>
                      this.setState({ quietanza: !this.state.quietanza })
                    }
                    checked={this.state.quietanza}
                  >
                    <Label
                      style={{ display: "inline-flex", marginRight: "45px" }}
                      className="ta-l"
                    >
                      Invio Quietanza
                    </Label>
                  </Checkbox>
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <Label>Note</Label>
                  <Field className="mb-10">
                    <TextArea
                      name="note"
                      placeholder=""
                      value={this.state.note}
                      type="input"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
              </Columns>
            </ModalCardBody>
            <Columns>
              <Column>
                <Button
                  className="is-primary  "
                  onClick={() => {
                    this.setState({ modalActive: false });
                  }}
                >
                  CANCELLA
                </Button>
              </Column>
              <Column>
                <Button
                  className="is-primary  "
                  onClick={() => {
                    this.addCommissione();
                  }}
                >
                  Ok
                </Button>
              </Column>
            </Columns>
          </ModalCard>
        </Modal>
      </div>
    );
  }
}
