import { Button, Column, Label } from "bloomer";
import { Table } from "bloomer/lib/elements/Table";
import React, { Component } from "react";
import { Modal } from "bloomer/lib/components/Modal/Modal";
import { ModalBackground } from "bloomer/lib/components/Modal/ModalBackground";
import { ModalCard } from "bloomer/lib/components/Modal/Card/ModalCard";
import { ModalCardHeader } from "bloomer/lib/components/Modal/Card/ModalCardHeader";
import { ModalCardTitle } from "bloomer/lib/components/Modal/Card/ModalCardTitle";
import { ModalCardBody } from "bloomer/lib/components/Modal/Card/ModalCardBody";
import { Field, Input, Columns } from "bloomer";
import Utils from "../../Utils";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import firebase from "firebase/compat/app";

import { isTablet } from "bloomer/lib/bulma";

export default class DContactPipeline extends Component {
  state = { pipeline: [], brand: "-", update: false };

  componentDidMount() {
    const pipeline = this.props.contact.pipeline || [];

    pipeline.sort((a, b) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    });

    this.setState({ pipeline });
  }

  handleChange(e) {
    const s = {};
    s[e.target.name] = e.target.value;
    this.setState(s);
  }

  addPipeline() {
    const { pipeline } = this.state;
    var datum = firebase.firestore.Timestamp.fromDate(
      new Date(this.state.date)
    );

    pipeline.push({
      date: datum,
      brand: this.state.brand,
      post: 0,
      stories: 0
    });

    this.setState({ pipeline, modalActive: false }, () =>
      this.updateTable(pipeline)
    );
  }

  saveUpdate() {
    if (!this.state.update) {
      this.updateTable(this.state.pipeline);
    }
  }
  updateTable(pipeline) {
    const { contact } = this.props;

    firebase
      .firestore()
      .collection("contacts")
      .doc(contact.uid)
      .update({ pipeline: pipeline });
  }

  renderBrandDropdown() {
    const brands = Utils.getBrands();

    return (
      <div>
        <Label>Brand</Label>
        <Dropdown isActive={this.state.brandActive}>
          <DropdownTrigger>
            <Button
              className="modal-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() =>
                this.setState({ brandActive: !this.state.brandActive })
              }
            >
              {this.state.brand}
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {brands.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    className="ta-l"
                    onClick={() =>
                      this.setState({ brand: e, brandActive: false })
                    }
                  >
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  onModify(target, idx) {
    const { pipeline } = this.state;
    pipeline[idx][target.name] = target.value;
    this.setState({ pipeline });
  }

  render() {
    const { pipeline } = this.state;
    console.log("AA pipeline", pipeline);

    return (
      <div>
        <h4 className="title has-text-black mt-80 mb-20">PIPELINE</h4>
        {pipeline.map((p, idx) => {
          return (
            <DPipelineSingle
              data={p}
              update={this.state.update}
              index={idx}
              onModify={(target, idx) => this.onModify(target, idx)}
              {...this.props}
            />
          );
        })}
        <div className="admin-panels">
          <Button
            className="is-primary mt-20"
            onClick={() =>
              this.setState({ modalActive: true, modifyRecord: false })
            }
          >
            AGGIUNGI
          </Button>
          <Button
            className="is-primary mt-20"
            onClick={() =>
              this.setState({ update: !this.state.update }, () =>
                this.saveUpdate()
              )
            }
          >
            {!!this.state.update ? "SALVA" : "MODIFICA"}
          </Button>
        </div>

        <Modal
          className="warehouse-modal has-text-centered"
          isActive={this.state.modalActive}
        >
          <ModalBackground
            onClick={() => this.setState({ modalActive: false })}
          />
          <ModalCard>
            <ModalCardHeader className="no-pb">
              <ModalCardTitle className="has-text-centered no-pl mb-10">
                {this.state.modifyRecord
                  ? "Modifica Contatto"
                  : "Nuovo Contatto"}
              </ModalCardTitle>
            </ModalCardHeader>
            <ModalCardBody>
              <Columns>
                <Column>
                  <Label>Data</Label>
                  <Field>
                    <Input
                      name="date"
                      placeholder=""
                      value={this.state.date}
                      type="date"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </Field>
                </Column>
                <Column>{this.renderBrandDropdown()}</Column>
              </Columns>
            </ModalCardBody>
            <Columns>
              <Column>
                <Button
                  className="is-primary  "
                  onClick={() => {
                    this.setState({ modalActive: false });
                  }}
                >
                  CANCELLA
                </Button>
              </Column>
              <Column>
                <Button
                  className="is-primary  "
                  onClick={() => {
                    this.addPipeline();
                  }}
                >
                  Ok
                </Button>
              </Column>
            </Columns>
          </ModalCard>
        </Modal>
      </div>
    );
  }
}

class DPipelineSingle extends React.Component {
  render() {
    const { data } = this.props;
    const postClass = data.post < 4 ? "ta-c red-cell" : "ta-c green-cell";
    const storiesClass = data.stories < 8 ? "ta-c red-cell" : "ta-c green-cell";
    return (
      <div className="affiliates-table d-ib">
        <Table isBordered isStriped>
          <thead>
            <tr className="title">
              <th colspan="2" className="has-text-centered">
                {Utils.formatDateMonthYear(data.date)}
              </th>
            </tr>

            <tr className="title">
              <th colspan="2" className="has-text-centered">
                {data.brand}
              </th>
            </tr>

            <tr className="title">
              <th className="has-text-centered">post</th>

              <th className="has-text-centered">stories</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {this.props.update ? (
                <td style={{ width: "50%", maxHeight: "18px" }}>
                  <input
                    className="search-field"
                    style={{ maxWidth: "40px", maxHeight: "18px" }}
                    type="number"
                    step="1"
                    name="post"
                    value={data.post}
                    onChange={e => {
                      this.props.onModify(e.target, this.props.index);
                    }}
                  />
                </td>
              ) : (
                <td
                  className={postClass}
                  style={{ width: "50%", maxHeight: "18px" }}
                >
                  {data.post}
                </td>
              )}
              {this.props.update ? (
                <td style={{ width: "50%", maxHeight: "18px" }}>
                  <input
                    className="search-field"
                    style={{ maxWidth: "40px", maxHeight: "18px" }}
                    type="number"
                    step="1"
                    name="stories"
                    value={data.stories}
                    onChange={e => {
                      this.props.onModify(e.target, this.props.index);
                    }}
                  />
                </td>
              ) : (
                <td
                  className={storiesClass}
                  style={{ width: "50%", maxHeight: "18px" }}
                >
                  {data.stories}
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
