import React, { Component } from "react";
import { Container, Columns, Column } from "bloomer";
import { withAdminCtx } from "../../contexts/AdminContext";
import Loader from "../../components/Loader";
import MyTable from "../../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { Button } from "bloomer/lib/elements/Button";
import { withWareCtx } from "../../contexts/WarehouseContext";
import firebase from "firebase/compat/app";

import { withModalsCtx } from "../../contexts/ModalsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../../Utils";
import DocumentType from "../../enums/DocumentType";
import AdminNavbar from "../../components/admin/AdminNavbar";

class DElementHistory extends Component {
  state = {
    product: null
  };

  async componentDidMount() {
    const uid = this.props.match.params.pUid;
    const pRef = firebase
      .firestore()
      .collection("elements")
      .doc(uid);
    const pSnap = await pRef.get();

    if (pSnap.exists) {
      const documents = [];
      const promises = [];
      const docSnap = await pRef.collection("documents").get();
      docSnap.forEach(d => {
        const doc = d.data();
        doc.dateFormatted = Utils.formatDate(d.data().date);
        if (
          doc.type === DocumentType.USCITA_PROD ||
          doc.type === DocumentType.USCITA_MANUALE
        )
          doc.quantity = -1 * doc.quantity;

        promises.push(
          new Promise(async (r, rj) => {
            const realDocSnap = await firebase
              .firestore()
              .collection("documents")
              .doc(doc.uid)
              .get();

            if (realDocSnap.exists)
              doc.codeNumber = Utils.getCodeNumber(realDocSnap.data());
            r(doc);
          })
        );

        if (
          doc.type === DocumentType.USCITA_PROD ||
          doc.type === DocumentType.ENTRATA_PROD ||
          doc.type === DocumentType.USCITA_MANUALE ||
          doc.type == DocumentType.CORREZIONE
        )
          documents.push(doc);
      });

      await Promise.all(promises);

      documents.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
      });
      this.setState({ product: pSnap.data(), documents });
    }
  }

  onModify(p) {
    if (p.type === DocumentType.ENTRATA_PROD)
      this.props.history.push("/produzioneEntrataDetails/" + p.uid);
    else if (p.type === DocumentType.USCITA_MANUALE)
      this.props.history.push("/produzioneUscitaDetails/" + p.uid);
    else this.props.history.push("/produzioneDetails/" + p.uid);
  }

  render() {
    const { product, documents } = this.state;
    if (!product) return <Loader />;

    const columns = [
      {
        Header: "Numero",
        accessor: "codeNumber"
      },
      {
        Header: "Data",
        accessor: "dateFormatted"
      },
      {
        Header: "Quantità",
        accessor: "quantity",
        maxWidth: 100,
        style: { textAlign: "center" },
        styleWarning: { color: "red", textAlign: "center" }
      },
      {
        Header: "Tipo",
        accessor: "type"
      },
      {
        Header: "Creato da",
        accessor: "creatorName"
      }
    ];

    return (
      <section>
        <AdminNavbar />
        <Columns isMobile>
          <Column>
            <Button
              onClick={() => this.props.history.goBack()}
              className="title user-area-title vw-tablet"
            >
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
              Torna indietro
            </Button>
          </Column>
        </Columns>
        <Container>
          <Columns className="admin-header mt-5">
            <Column>
              <h2 className="title has-text-black ">
                Storico Giacenza elemento {product.name}
              </h2>
            </Column>
          </Columns>
          <div className="admin-panels">
            {
              <MyTable
                data={documents}
                columns={columns}
                onModify={u => this.onModify(u)}
                hideDelete
                {...this.props}
              />
            }
          </div>
        </Container>
      </section>
    );
  }
}

export default withModalsCtx(
  withRouter(withAdminCtx(withWareCtx(DElementHistory)))
);
