import React, { Component } from "react";
import firebase from "firebase/compat/app";

import { withRouter } from "react-router-dom";
import Utils from "../Utils";

export const WareContext = React.createContext(null);

export const WareConsumer = WareContext.Consumer;

export class WareProvider extends Component {
  state = {
    debug: false,
    accessGranted: false,
    isAdmin: false,
    email: "",
    notificaGiacenze: false,
    canNotificaGiacenze: false,
    isCreator: false,
    enableReportAmazon: false
  };

  constructor(props) {
    super(props);
    this.verifyAccessGranted = this.verifyAccessGranted.bind(this);
  }

  verifyAccessGranted(user) {
    if (!!user) {
      console.log("aa ware context user", user.uid);

      let userSnap = firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then(user => {
          if (user.exists && !!user.data().enabled)
            this.setState({ accessGranted: true });
          if (user.exists && !!user.data().isAdmin)
            this.setState({ isAdmin: true });
          if (user.exists && !!user.data().canModifyPrices)
            this.setState({ canModifyPrices: true });
          if (user.exists && !!user.data().notificaGiacenze)
            this.setState({ notificaGiacenze: true });
          if (user.exists && !!user.data().isCreator)
            this.setState({ isCreator: true });
          if (user.exists && !!user.data().enableReportAmazon)
            this.setState({ enableReportAmazon: true });
          if (user.exists && !!user.data().debug)
            this.setState({ debug: true });

          this.setState({ user: user.data() });
        });
    }
  }

  componentDidMount() {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async user => {
        console.log("AA user", user);
        this.setState(user);
        this.verifyAccessGranted(user);
      });

    this.setState({ unregisterAuthObserver });
  }

  componentWillUnmount() {
    if (this.state.unregisterAuthObserver) this.state.unregisterAuthObserver();
  }

  render() {
    return (
      <WareContext.Provider value={this.state}>
        <div>{this.props.children}</div>
      </WareContext.Provider>
    );
  }
}

export default withRouter(WareProvider);

export function withWareCtx(Component) {
  return props => (
    <WareConsumer>{ctx => <Component ware={ctx} {...props} />}</WareConsumer>
  );
}
