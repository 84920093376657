import React, { Component } from "react";
import { Container } from "bloomer";
import { withAdminCtx } from "../contexts/AdminContext";
import Loader from "../components/Loader";
import MyTable from "../components/admin/MyTable";
import "react-table/react-table.css";
import { withRouter } from "react-router-dom";
import { withWareCtx } from "../contexts/WarehouseContext";
import firebase from "firebase/compat/app";

import { withModalsCtx } from "../contexts/ModalsContext";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../Utils";
import {
  Flex,
  Heading,
  Box,
  SimpleGrid,
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay
} from "@chakra-ui/react";
import { ExporterButton } from "../components/DExporterButton";
import DContactDetails from "./desktop/DContactDetails";
import ModalUI from "../modals/ModalUI";

class DContacts extends Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.setContacts = this.setContacts.bind(this);
    this.exportDocuments = this.exportDocuments.bind(this);
  }

  state = {
    filterValue: "Tutti",
    deleteDoc: null,
    deleting: false
  };

  componentDidMount() {
    this.setState({
      timer: setTimeout(async () => {
        this.setContacts(this.props);
      })
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setContacts(nextProps);
  }

  setContacts(props) {
    this.setState({ filteredContacts: props.admin.contacts });
  }

  onModify(p, id, e, searchText) {
    if (e.metaKey) {
      window.open("contactDetails/" + p.uid, "_blank");
      return;
    }
    // this.props.history.push({
    //   pathname: "/contactDetails/" + p.uid,
    //   goBack: "/anagrafica/?search=" + searchText,
    //   state: searchText
    // });

    this.setState({ openContact: p.uid });
  }

  addProduct() {
    // this.props.history.push("contactAdd");
    this.setState({ openAddContact: true });
  }

  onDelete(p) {
    console.log("AA DELETE", p);
    this.setState({ deleteDoc: p });
  }
  renderDeleteModal() {
    return (
      <AlertDialog
        isOpen={!!this.state.deleteDoc}
        onClose={() => this.setState({ deleteDoc: null })}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confermi eliminazione {this.state.deleteDoc?.name}
            </AlertDialogHeader>

            <AlertDialogFooter>
              <Button onClick={() => this.setState({ deleteDoc: null })}>
                Esci
              </Button>
              <Button
                colorScheme="red"
                onClick={() => this.confirmDelete(this.state.deleteDoc)}
                isLoading={this.state.deleting}
                ml={3}
              >
                Elimina
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }
  async confirmDelete(p) {
    console.log("AA adding delete p", p);
    const contactsSnap = await firebase
      .firestore()
      .collection("contacts")
      .doc(p.uid)
      .delete();

    this.setState({ deleting: false, deleteDoc: null });
  }

  setFilter(val) {
    let { contacts } = this.props.admin;
    if (val != "Tutti") {
      contacts = contacts.filter(c =>
        val === "Clienti"
          ? Utils.getTipoCliente(c, "Cliente")
          : val === "Fornitori"
          ? Utils.getTipoCliente(c, "Fornitore")
          : Utils.getTipoCliente(c, "Affiliato")
      );
    }

    this.setState({
      filteredContacts: contacts,
      filterValue: val,
      filterActive: false
    });
  }
  async exportDocuments() {
    const { filteredContacts } = this.state;
    console.log("AA filteredContacts", filteredContacts);

    let contacts = filteredContacts.filter(c => c.name.indexOf("AMAZON") == -1);
    console.log("AA filteredContacts", contacts);

    for (let c of contacts) {
      if (c) {
        console.log("AA C", c);

        const docsSnap = await firebase
          .firestore()
          .collection("documents")
          .where("contact.uid", "==", c.uid)
          .where("type", "==", "distinta")
          .where("causale", "==", "Vendita")
          .limit(10)
          .get();

        const contactDocs = [];
        let fatturato = 0;
        docsSnap.forEach(async d => {
          const doc = d.data();
          fatturato += doc.finalPrice;
          console.log("AA Docs", doc);
          // doc.codeNumber = Utils.getCodeNumber(doc);
          // doc.dateFormatted = Utils.formatDate(doc.date);
          // if (!!doc.products) {
          //   let numeroPezzi = 0;
          //   let prodottiConcatenati = "";
          //   let index = 0;
          //   doc.products.forEach(async p => {
          //     numeroPezzi += p.quantity;
          //     if (index != 0) prodottiConcatenati += "; ";
          //     prodottiConcatenati += p.nomeDisplay || p.nome;
          //     index++;

          //     // if (!p.price_buy) {
          //     //   const productSnap = await firebase
          //     //     .firestore()
          //     //     .collection("products")
          //     //     .doc(p.uid)
          //     //     .get();

          //     //   p.price_buy = productSnap.data().price_buy || 0;

          //     //   console.log("BB price buy", p.price_buy, productSnap.data());
          //     // }
          //   });

          //   doc.numeroPezzi = numeroPezzi;
          //   doc.prodottiConcatenati = prodottiConcatenati;
          // }
          contactDocs.push(doc);
        });
        c.fatturato = fatturato;
        contactDocs.sort((a, b) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        });
      }
    }

    console.log("AA contacts", contacts);

    this.setState({ contacts });
  }

  renderFilter() {
    const filters = ["Tutti", "Fornitori", "Clienti", "Affiliati"];
    return (
      <Dropdown isActive={this.state.filterActive}>
        <DropdownTrigger>
          <Button
            className="filter-button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => {
              this.setState({
                filterActive: !this.state.filterActive
              });
            }}
          >
            {this.state.filterValue}
            <span className="dropdown-icn">
              <FontAwesomeIcon className="icn" icon={["fa", "chevron-down"]} />
            </span>
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownContent className="elements-dropdown">
            {filters.map((e, idx) => {
              return (
                <DropdownItem
                  key={idx}
                  className="ta-l"
                  onClick={() => this.setFilter(e)}
                >
                  {e}
                </DropdownItem>
              );
            })}
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    const { filteredContacts, contacts } = this.state;
    if (!filteredContacts) return <Loader />;

    console.log("AA contacts loaded", contacts);
    const columns = [
      {
        Header: "Nome",
        accessor: "name"
      },
      {
        Header: "Tipo",
        accessor: "typeC",
        style: { textAlign: "center" }
      },
      {
        Header: "Indirizzo",
        accessor: "fulladdress"
      },
      {
        Header: "Creato da",
        accessor: "creatorName",
        style: { textAlign: "center" }
      }
    ];

    return (
      <section>
        <Container>
          <Box p={5} bg="#75759047" borderRadius="md">
            <Flex align={"center"} justifyContent="space-between">
              <Heading size="lg">Anagrafica Clienti/Fornitori</Heading>
              <Button onClick={() => this.exportDocuments()}>
                ESPORTA DOCUMENTI
              </Button>
              <Flex justify={"end"}>{this.renderFilter()}</Flex>
            </Flex>
          </Box>
          <Flex justifyContent="end" my={2}>
            <SimpleGrid columns={2} spacing={1} w="20%">
              {!!this.props.ware.isCreator && (
                <Button colorScheme="green" onClick={() => this.addProduct()}>
                  AGGIUNGI
                </Button>
              )}
              <ExporterButton
                data={contacts}
                filename="MyLab Anagrafica"
                title="Clienti"
                prepare={o => {
                  o.tipoCliente = "";
                  if (!!o.typeMultiple) {
                    o.typeMultiple.forEach(l => {
                      o.tipoCliente += l + " - ";
                    });
                  }

                  return {
                    nome: o.name,
                    email: o.email || "",
                    fattirato: o.fatturato || 0,
                    pec: o.pec || "",
                    indirizzo_fatturazione: o.fulladdress || "",
                    indirizzo_spedizione: o.fulladdressShipping || "",
                    telefono: o.phone || "",
                    cod_fiscale: o.codFiscale || "",
                    cod_sdi: o.codSDI || "",
                    partita_iva: o.partitaIva || "",
                    ragione_sociale: o.ragioneSociale || "",
                    tipo_cliente: o.tipoCliente || ""
                  };
                }}
              />
            </SimpleGrid>
          </Flex>

          <div className="admin-panels">
            <MyTable
              data={filteredContacts}
              columns={columns}
              cerca={true}
              searchFields={["codFiscale", "name", "partitaIva"]}
              onModify={(u, id, e, searchText) =>
                this.onModify(u, id, e, searchText)
              }
              onDelete={u => this.onDelete(u)}
              {...this.props}
            />
          </div>
        </Container>
        {this.renderDeleteModal()}
        <ModalUI
          open={this.state.openContact}
          children=<DContactDetails
            uid={this.state.openContact}
            onSave={() => this.setState({ openContact: null })}
          />
        />
        <ModalUI
          open={this.state.openAddContact}
          children=<DContactDetails
            adding
            onSave={() => this.setState({ openAddContact: null })}
          />
        />
      </section>
    );
  }
}

export default withModalsCtx(withRouter(withAdminCtx(withWareCtx(DContacts))));
