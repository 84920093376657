import firebase from "firebase/compat/app";

import Utils from "../Utils";

export default class StorageService {
  // static async getDefaultContract() {
  //   return new Promise(async (r, rj) => {
  //     try {
  //       const ref = firebase.storage().ref("contracts/default.pdf");
  //       r(await ref.getDownloadURL());
  //     } catch (err) {
  //       console.error("error getting default contract", err);
  //       rj(err);
  //     }
  //   });
  // }

  static async getPDF(uid, filename, folder) {
    return new Promise(async (r, rj) => {
      try {
        const ref = firebase.storage().ref(`${folder}/${uid}/${filename}`);
        r(await ref.getDownloadURL());
      } catch (err) {
        console.error("error getting pdf", filename, err);
        rj(err);
      }
    });
  }

  static async postPDF(file, filename, uid, folder) {
    return new Promise(async (r, rj) => {
      try {
        Utils.log("putting contract in bucket");
        const ref = firebase.storage().ref(`${folder}/${uid}/${filename}`);

        r(
          await ref.put(file, {
            contentType: "application/pdf"
          })
        );
      } catch (e) {
        console.error("error uploading pdf", e);
        rj(e);
      }
    });
  }

  static deletePDF(filename, uid, folder) {
    return new Promise(async (r, rj) => {
      try {
        const ref = firebase.storage().ref(`${folder}/${uid}/${filename}`);
        r(await ref.delete());
      } catch (err) {
        rj(err);
      }
    });
  }
}
