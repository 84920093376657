export default {
    "regioni": [
        {
            "province": [
                {
                    "code": "CH",
                    "comuni": [
                        {
                            "code": "069001",
                            "cap": "66040",
                            "nome": "Altino"
                        },
                        {
                            "code": "069002",
                            "cap": "66044",
                            "nome": "Archi"
                        },
                        {
                            "code": "069003",
                            "cap": "66010",
                            "nome": "Ari"
                        },
                        {
                            "code": "069004",
                            "cap": "66030",
                            "nome": "Arielli"
                        },
                        {
                            "code": "069005",
                            "cap": "66041",
                            "nome": "Atessa"
                        },
                        {
                            "code": "069006",
                            "cap": "66042",
                            "nome": "Bomba"
                        },
                        {
                            "code": "069007",
                            "cap": "66040",
                            "nome": "Borrello"
                        },
                        {
                            "code": "069008",
                            "cap": "66011",
                            "nome": "Bucchianico"
                        },
                        {
                            "code": "069010",
                            "cap": "66010",
                            "nome": "Canosa Sannita"
                        },
                        {
                            "code": "069011",
                            "cap": "66030",
                            "nome": "Carpineto Sinello"
                        },
                        {
                            "code": "069012",
                            "cap": "66050",
                            "nome": "Carunchio"
                        },
                        {
                            "code": "069013",
                            "cap": "66010",
                            "nome": "Casacanditella"
                        },
                        {
                            "code": "069014",
                            "cap": "66031",
                            "nome": "Casalanguida"
                        },
                        {
                            "code": "069015",
                            "cap": "66021",
                            "nome": "Casalbordino"
                        },
                        {
                            "code": "069016",
                            "cap": "66012",
                            "nome": "Casalincontrada"
                        },
                        {
                            "code": "069017",
                            "cap": "66043",
                            "nome": "Casoli"
                        },
                        {
                            "code": "069018",
                            "cap": "66032",
                            "nome": "Castel Frentano"
                        },
                        {
                            "code": "069019",
                            "cap": "66040",
                            "nome": "Castelguidone"
                        },
                        {
                            "code": "069020",
                            "cap": "66033",
                            "nome": "Castiglione Messer Marino"
                        },
                        {
                            "code": "069021",
                            "cap": "66050",
                            "nome": "Celenza sul Trigno"
                        },
                        {
                            "code": "069022",
                            "cap": "66100",
                            "nome": "Chieti"
                        },
                        {
                            "code": "069023",
                            "cap": "66040",
                            "nome": "Civitaluparella"
                        },
                        {
                            "code": "069024",
                            "cap": "66010",
                            "nome": "Civitella Messer Raimondo"
                        },
                        {
                            "code": "069025",
                            "cap": "66010",
                            "nome": "Colledimacine"
                        },
                        {
                            "code": "069026",
                            "cap": "66040",
                            "nome": "Colledimezzo"
                        },
                        {
                            "code": "069027",
                            "cap": "66014",
                            "nome": "Crecchio"
                        },
                        {
                            "code": "069028",
                            "cap": "66051",
                            "nome": "Cupello"
                        },
                        {
                            "code": "069029",
                            "cap": "66050",
                            "nome": "Dogliola"
                        },
                        {
                            "code": "069104",
                            "cap": "66040",
                            "nome": "Fallo"
                        },
                        {
                            "code": "069030",
                            "cap": "66010",
                            "nome": "Fara Filiorum Petri"
                        },
                        {
                            "code": "069031",
                            "cap": "66015",
                            "nome": "Fara San Martino"
                        },
                        {
                            "code": "069032",
                            "cap": "66030",
                            "nome": "Filetto"
                        },
                        {
                            "code": "069033",
                            "cap": "66022",
                            "nome": "Fossacesia"
                        },
                        {
                            "code": "069034",
                            "cap": "66050",
                            "nome": "Fraine"
                        },
                        {
                            "code": "069035",
                            "cap": "66023",
                            "nome": "Francavilla al Mare"
                        },
                        {
                            "code": "069036",
                            "cap": "66050",
                            "nome": "Fresagrandinaria"
                        },
                        {
                            "code": "069037",
                            "cap": "66030",
                            "nome": "Frisa"
                        },
                        {
                            "code": "069038",
                            "cap": "66050",
                            "nome": "Furci"
                        },
                        {
                            "code": "069039",
                            "cap": "66040",
                            "nome": "Gamberale"
                        },
                        {
                            "code": "069040",
                            "cap": "66010",
                            "nome": "Gessopalena"
                        },
                        {
                            "code": "069041",
                            "cap": "66052",
                            "nome": "Gissi"
                        },
                        {
                            "code": "069042",
                            "cap": "66010",
                            "nome": "Giuliano Teatino"
                        },
                        {
                            "code": "069043",
                            "cap": "66016",
                            "nome": "Guardiagrele"
                        },
                        {
                            "code": "069044",
                            "cap": "66050",
                            "nome": "Guilmi"
                        },
                        {
                            "code": "069045",
                            "cap": "66010",
                            "nome": "Lama dei Peligni"
                        },
                        {
                            "code": "069046",
                            "cap": "66034",
                            "nome": "Lanciano"
                        },
                        {
                            "code": "069047",
                            "cap": "66050",
                            "nome": "Lentella"
                        },
                        {
                            "code": "069048",
                            "cap": "66010",
                            "nome": "Lettopalena"
                        },
                        {
                            "code": "069049",
                            "cap": "66050",
                            "nome": "Liscia"
                        },
                        {
                            "code": "069050",
                            "cap": "66010",
                            "nome": "Miglianico"
                        },
                        {
                            "code": "069051",
                            "cap": "66030",
                            "nome": "Montazzoli"
                        },
                        {
                            "code": "069009",
                            "cap": "66040",
                            "nome": "Montebello sul Sangro"
                        },
                        {
                            "code": "069052",
                            "cap": "66040",
                            "nome": "Monteferrante"
                        },
                        {
                            "code": "069053",
                            "cap": "66040",
                            "nome": "Montelapiano"
                        },
                        {
                            "code": "069054",
                            "cap": "66010",
                            "nome": "Montenerodomo"
                        },
                        {
                            "code": "069055",
                            "cap": "66050",
                            "nome": "Monteodorisio"
                        },
                        {
                            "code": "069056",
                            "cap": "66030",
                            "nome": "Mozzagrogna"
                        },
                        {
                            "code": "069057",
                            "cap": "66036",
                            "nome": "Orsogna"
                        },
                        {
                            "code": "069058",
                            "cap": "66026",
                            "nome": "Ortona"
                        },
                        {
                            "code": "069059",
                            "cap": "66020",
                            "nome": "Paglieta"
                        },
                        {
                            "code": "069060",
                            "cap": "66017",
                            "nome": "Palena"
                        },
                        {
                            "code": "069061",
                            "cap": "66050",
                            "nome": "Palmoli"
                        },
                        {
                            "code": "069062",
                            "cap": "66010",
                            "nome": "Palombaro"
                        },
                        {
                            "code": "069063",
                            "cap": "66040",
                            "nome": "Pennadomo"
                        },
                        {
                            "code": "069064",
                            "cap": "66010",
                            "nome": "Pennapiedimonte"
                        },
                        {
                            "code": "069065",
                            "cap": "66040",
                            "nome": "Perano"
                        },
                        {
                            "code": "069103",
                            "cap": "66040",
                            "nome": "Pietraferrazzana"
                        },
                        {
                            "code": "069066",
                            "cap": "66040",
                            "nome": "Pizzoferrato"
                        },
                        {
                            "code": "069067",
                            "cap": "66030",
                            "nome": "Poggiofiorito"
                        },
                        {
                            "code": "069068",
                            "cap": "66020",
                            "nome": "Pollutri"
                        },
                        {
                            "code": "069069",
                            "cap": "66010",
                            "nome": "Pretoro"
                        },
                        {
                            "code": "069070",
                            "cap": "66040",
                            "nome": "Quadri"
                        },
                        {
                            "code": "069071",
                            "cap": "66010",
                            "nome": "Rapino"
                        },
                        {
                            "code": "069072",
                            "cap": "66010",
                            "nome": "Ripa Teatina"
                        },
                        {
                            "code": "069074",
                            "cap": "66020",
                            "nome": "Rocca San Giovanni"
                        },
                        {
                            "code": "069073",
                            "cap": "66010",
                            "nome": "Roccamontepiano"
                        },
                        {
                            "code": "069075",
                            "cap": "66040",
                            "nome": "Roccascalegna"
                        },
                        {
                            "code": "069076",
                            "cap": "66050",
                            "nome": "Roccaspinalveti"
                        },
                        {
                            "code": "069077",
                            "cap": "66040",
                            "nome": "Roio del Sangro"
                        },
                        {
                            "code": "069078",
                            "cap": "66040",
                            "nome": "Rosello"
                        },
                        {
                            "code": "069079",
                            "cap": "66050",
                            "nome": "San Buono"
                        },
                        {
                            "code": "069080",
                            "cap": "66050",
                            "nome": "San Giovanni Lipioni"
                        },
                        {
                            "code": "069081",
                            "cap": "66020",
                            "nome": "San Giovanni Teatino"
                        },
                        {
                            "code": "069082",
                            "cap": "66010",
                            "nome": "San Martino sulla Marrucina"
                        },
                        {
                            "code": "069083",
                            "cap": "66050",
                            "nome": "San Salvo"
                        },
                        {
                            "code": "069086",
                            "cap": "66038",
                            "nome": "San Vito Chietino"
                        },
                        {
                            "code": "069084",
                            "cap": "66030",
                            "nome": "Santa Maria Imbaro"
                        },
                        {
                            "code": "069085",
                            "cap": "66037",
                            "nome": "Sant'Eusanio del Sangro"
                        },
                        {
                            "code": "069087",
                            "cap": "66020",
                            "nome": "Scerni"
                        },
                        {
                            "code": "069088",
                            "cap": "66045",
                            "nome": "Schiavi di Abruzzo"
                        },
                        {
                            "code": "069089",
                            "cap": "66018",
                            "nome": "Taranta Peligna"
                        },
                        {
                            "code": "069090",
                            "cap": "66010",
                            "nome": "Tollo"
                        },
                        {
                            "code": "069091",
                            "cap": "66020",
                            "nome": "Torino di Sangro"
                        },
                        {
                            "code": "069092",
                            "cap": "66046",
                            "nome": "Tornareccio"
                        },
                        {
                            "code": "069093",
                            "cap": "66050",
                            "nome": "Torrebruna"
                        },
                        {
                            "code": "069094",
                            "cap": "66010",
                            "nome": "Torrevecchia Teatina"
                        },
                        {
                            "code": "069095",
                            "cap": "66019",
                            "nome": "Torricella Peligna"
                        },
                        {
                            "code": "069096",
                            "cap": "66030",
                            "nome": "Treglio"
                        },
                        {
                            "code": "069097",
                            "cap": "66050",
                            "nome": "Tufillo"
                        },
                        {
                            "code": "069098",
                            "cap": "66010",
                            "nome": "Vacri"
                        },
                        {
                            "code": "069099",
                            "cap": "66054",
                            "nome": "Vasto"
                        },
                        {
                            "code": "069102",
                            "cap": "66047",
                            "nome": "Villa Santa Maria"
                        },
                        {
                            "code": "069100",
                            "cap": "66020",
                            "nome": "Villalfonsina"
                        },
                        {
                            "code": "069101",
                            "cap": "66010",
                            "nome": "Villamagna"
                        }
                    ],
                    "nome": "Chieti"
                },
                {
                    "code": "AQ",
                    "comuni": [
                        {
                            "code": "066001",
                            "cap": "67020",
                            "nome": "Acciano"
                        },
                        {
                            "code": "066002",
                            "cap": "67041",
                            "nome": "Aielli"
                        },
                        {
                            "code": "066003",
                            "cap": "67030",
                            "nome": "Alfedena"
                        },
                        {
                            "code": "066004",
                            "cap": "67030",
                            "nome": "Anversa degli Abruzzi"
                        },
                        {
                            "code": "066005",
                            "cap": "67030",
                            "nome": "Ateleta"
                        },
                        {
                            "code": "066006",
                            "cap": "67051",
                            "nome": "Avezzano"
                        },
                        {
                            "code": "066007",
                            "cap": "67052",
                            "nome": "Balsorano"
                        },
                        {
                            "code": "066008",
                            "cap": "67010",
                            "nome": "Barete"
                        },
                        {
                            "code": "066009",
                            "cap": "67021",
                            "nome": "Barisciano"
                        },
                        {
                            "code": "066010",
                            "cap": "67030",
                            "nome": "Barrea"
                        },
                        {
                            "code": "066011",
                            "cap": "67050",
                            "nome": "Bisegna"
                        },
                        {
                            "code": "066012",
                            "cap": "67030",
                            "nome": "Bugnara"
                        },
                        {
                            "code": "066013",
                            "cap": "67012",
                            "nome": "Cagnano Amiterno"
                        },
                        {
                            "code": "066014",
                            "cap": "67020",
                            "nome": "Calascio"
                        },
                        {
                            "code": "066015",
                            "cap": "67030",
                            "nome": "Campo di Giove"
                        },
                        {
                            "code": "066016",
                            "cap": "67013",
                            "nome": "Campotosto"
                        },
                        {
                            "code": "066017",
                            "cap": "67050",
                            "nome": "Canistro"
                        },
                        {
                            "code": "066018",
                            "cap": "67030",
                            "nome": "Cansano"
                        },
                        {
                            "code": "066019",
                            "cap": "67022",
                            "nome": "Capestrano"
                        },
                        {
                            "code": "066020",
                            "cap": "67053",
                            "nome": "Capistrello"
                        },
                        {
                            "code": "066021",
                            "cap": "67014",
                            "nome": "Capitignano"
                        },
                        {
                            "code": "066022",
                            "cap": "67020",
                            "nome": "Caporciano"
                        },
                        {
                            "code": "066023",
                            "cap": "67060",
                            "nome": "Cappadocia"
                        },
                        {
                            "code": "066024",
                            "cap": "67020",
                            "nome": "Carapelle Calvisio"
                        },
                        {
                            "code": "066025",
                            "cap": "67061",
                            "nome": "Carsoli"
                        },
                        {
                            "code": "066026",
                            "cap": "67023",
                            "nome": "Castel del Monte"
                        },
                        {
                            "code": "066027",
                            "cap": "67020",
                            "nome": "Castel di Ieri"
                        },
                        {
                            "code": "066028",
                            "cap": "67031",
                            "nome": "Castel di Sangro"
                        },
                        {
                            "code": "066029",
                            "cap": "67050",
                            "nome": "Castellafiume"
                        },
                        {
                            "code": "066030",
                            "cap": "67020",
                            "nome": "Castelvecchio Calvisio"
                        },
                        {
                            "code": "066031",
                            "cap": "67024",
                            "nome": "Castelvecchio Subequo"
                        },
                        {
                            "code": "066032",
                            "cap": "67043",
                            "nome": "Celano"
                        },
                        {
                            "code": "066033",
                            "cap": "67044",
                            "nome": "Cerchio"
                        },
                        {
                            "code": "066034",
                            "cap": "67050",
                            "nome": "Civita d'Antino"
                        },
                        {
                            "code": "066035",
                            "cap": "67030",
                            "nome": "Civitella Alfedena"
                        },
                        {
                            "code": "066036",
                            "cap": "67054",
                            "nome": "Civitella Roveto"
                        },
                        {
                            "code": "066037",
                            "cap": "67030",
                            "nome": "Cocullo"
                        },
                        {
                            "code": "066038",
                            "cap": "67040",
                            "nome": "Collarmele"
                        },
                        {
                            "code": "066039",
                            "cap": "67050",
                            "nome": "Collelongo"
                        },
                        {
                            "code": "066040",
                            "cap": "67020",
                            "nome": "Collepietro"
                        },
                        {
                            "code": "066041",
                            "cap": "67030",
                            "nome": "Corfinio"
                        },
                        {
                            "code": "066042",
                            "cap": "67020",
                            "nome": "Fagnano Alto"
                        },
                        {
                            "code": "066043",
                            "cap": "67020",
                            "nome": "Fontecchio"
                        },
                        {
                            "code": "066044",
                            "cap": "67020",
                            "nome": "Fossa"
                        },
                        {
                            "code": "066045",
                            "cap": "67020",
                            "nome": "Gagliano Aterno"
                        },
                        {
                            "code": "066046",
                            "cap": "67055",
                            "nome": "Gioia dei Marsi"
                        },
                        {
                            "code": "066047",
                            "cap": "67030",
                            "nome": "Goriano Sicoli"
                        },
                        {
                            "code": "066048",
                            "cap": "67030",
                            "nome": "Introdacqua"
                        },
                        {
                            "code": "066049",
                            "cap": "67100",
                            "nome": "L'Aquila"
                        },
                        {
                            "code": "066050",
                            "cap": "67050",
                            "nome": "Lecce nei Marsi"
                        },
                        {
                            "code": "066051",
                            "cap": "67056",
                            "nome": "Luco dei Marsi"
                        },
                        {
                            "code": "066052",
                            "cap": "67045",
                            "nome": "Lucoli"
                        },
                        {
                            "code": "066053",
                            "cap": "67062",
                            "nome": "Magliano de' Marsi"
                        },
                        {
                            "code": "066054",
                            "cap": "67050",
                            "nome": "Massa d'Albe"
                        },
                        {
                            "code": "066055",
                            "cap": "67020",
                            "nome": "Molina Aterno"
                        },
                        {
                            "code": "066056",
                            "cap": "67015",
                            "nome": "Montereale"
                        },
                        {
                            "code": "066057",
                            "cap": "67050",
                            "nome": "Morino"
                        },
                        {
                            "code": "066058",
                            "cap": "67020",
                            "nome": "Navelli"
                        },
                        {
                            "code": "066059",
                            "cap": "67040",
                            "nome": "Ocre"
                        },
                        {
                            "code": "066060",
                            "cap": "67025",
                            "nome": "Ofena"
                        },
                        {
                            "code": "066061",
                            "cap": "67030",
                            "nome": "Opi"
                        },
                        {
                            "code": "066062",
                            "cap": "67063",
                            "nome": "Oricola"
                        },
                        {
                            "code": "066063",
                            "cap": "67050",
                            "nome": "Ortona dei Marsi"
                        },
                        {
                            "code": "066064",
                            "cap": "67050",
                            "nome": "Ortucchio"
                        },
                        {
                            "code": "066065",
                            "cap": "67046",
                            "nome": "Ovindoli"
                        },
                        {
                            "code": "066066",
                            "cap": "67030",
                            "nome": "Pacentro"
                        },
                        {
                            "code": "066067",
                            "cap": "67064",
                            "nome": "Pereto"
                        },
                        {
                            "code": "066068",
                            "cap": "67032",
                            "nome": "Pescasseroli"
                        },
                        {
                            "code": "066069",
                            "cap": "67057",
                            "nome": "Pescina"
                        },
                        {
                            "code": "066070",
                            "cap": "67033",
                            "nome": "Pescocostanzo"
                        },
                        {
                            "code": "066071",
                            "cap": "67034",
                            "nome": "Pettorano sul Gizio"
                        },
                        {
                            "code": "066072",
                            "cap": "67017",
                            "nome": "Pizzoli"
                        },
                        {
                            "code": "066073",
                            "cap": "67026",
                            "nome": "Poggio Picenze"
                        },
                        {
                            "code": "066074",
                            "cap": "67020",
                            "nome": "Prata d'Ansidonia"
                        },
                        {
                            "code": "066075",
                            "cap": "67035",
                            "nome": "Pratola Peligna"
                        },
                        {
                            "code": "066076",
                            "cap": "67030",
                            "nome": "Prezza"
                        },
                        {
                            "code": "066077",
                            "cap": "67027",
                            "nome": "Raiano"
                        },
                        {
                            "code": "066078",
                            "cap": "67036",
                            "nome": "Rivisondoli"
                        },
                        {
                            "code": "066080",
                            "cap": "67066",
                            "nome": "Rocca di Botte"
                        },
                        {
                            "code": "066081",
                            "cap": "67047",
                            "nome": "Rocca di Cambio"
                        },
                        {
                            "code": "066082",
                            "cap": "67048",
                            "nome": "Rocca di Mezzo"
                        },
                        {
                            "code": "066083",
                            "cap": "67030",
                            "nome": "Rocca Pia"
                        },
                        {
                            "code": "066079",
                            "cap": "67030",
                            "nome": "Roccacasale"
                        },
                        {
                            "code": "066084",
                            "cap": "67037",
                            "nome": "Roccaraso"
                        },
                        {
                            "code": "066085",
                            "cap": "67058",
                            "nome": "San Benedetto dei Marsi"
                        },
                        {
                            "code": "066086",
                            "cap": "67020",
                            "nome": "San Benedetto in Perillis"
                        },
                        {
                            "code": "066087",
                            "cap": "67028",
                            "nome": "San Demetrio ne' Vestini"
                        },
                        {
                            "code": "066088",
                            "cap": "67020",
                            "nome": "San Pio delle Camere"
                        },
                        {
                            "code": "066092",
                            "cap": "67050",
                            "nome": "San Vincenzo Valle Roveto"
                        },
                        {
                            "code": "066089",
                            "cap": "67067",
                            "nome": "Sante Marie"
                        },
                        {
                            "code": "066090",
                            "cap": "67020",
                            "nome": "Sant'Eusanio Forconese"
                        },
                        {
                            "code": "066091",
                            "cap": "67020",
                            "nome": "Santo Stefano di Sessanio"
                        },
                        {
                            "code": "066093",
                            "cap": "67038",
                            "nome": "Scanno"
                        },
                        {
                            "code": "066094",
                            "cap": "67030",
                            "nome": "Scontrone"
                        },
                        {
                            "code": "066095",
                            "cap": "67019",
                            "nome": "Scoppito"
                        },
                        {
                            "code": "066096",
                            "cap": "67068",
                            "nome": "Scurcola Marsicana"
                        },
                        {
                            "code": "066097",
                            "cap": "67029",
                            "nome": "Secinaro"
                        },
                        {
                            "code": "066098",
                            "cap": "67039",
                            "nome": "Sulmona"
                        },
                        {
                            "code": "066099",
                            "cap": "67069",
                            "nome": "Tagliacozzo"
                        },
                        {
                            "code": "066100",
                            "cap": "67020",
                            "nome": "Tione degli Abruzzi"
                        },
                        {
                            "code": "066101",
                            "cap": "67049",
                            "nome": "Tornimparte"
                        },
                        {
                            "code": "066102",
                            "cap": "67059",
                            "nome": "Trasacco"
                        },
                        {
                            "code": "066104",
                            "cap": "67020",
                            "nome": "Villa Santa Lucia degli Abruzzi"
                        },
                        {
                            "code": "066105",
                            "cap": "67020",
                            "nome": "Villa Sant'Angelo"
                        },
                        {
                            "code": "066103",
                            "cap": "67030",
                            "nome": "Villalago"
                        },
                        {
                            "code": "066106",
                            "cap": "67050",
                            "nome": "Villavallelonga"
                        },
                        {
                            "code": "066107",
                            "cap": "67030",
                            "nome": "Villetta Barrea"
                        },
                        {
                            "code": "066108",
                            "cap": "67030",
                            "nome": "Vittorito"
                        }
                    ],
                    "nome": "L'Aquila"
                },
                {
                    "code": "PE",
                    "comuni": [
                        {
                            "code": "068001",
                            "cap": "65020",
                            "nome": "Abbateggio"
                        },
                        {
                            "code": "068002",
                            "cap": "65020",
                            "nome": "Alanno"
                        },
                        {
                            "code": "068003",
                            "cap": "65020",
                            "nome": "Bolognano"
                        },
                        {
                            "code": "068004",
                            "cap": "65010",
                            "nome": "Brittoli"
                        },
                        {
                            "code": "068005",
                            "cap": "65022",
                            "nome": "Bussi sul Tirino"
                        },
                        {
                            "code": "068006",
                            "cap": "65010",
                            "nome": "Cappelle sul Tavo"
                        },
                        {
                            "code": "068007",
                            "cap": "65023",
                            "nome": "Caramanico Terme"
                        },
                        {
                            "code": "068008",
                            "cap": "65010",
                            "nome": "Carpineto della Nora"
                        },
                        {
                            "code": "068009",
                            "cap": "65020",
                            "nome": "Castiglione a Casauria"
                        },
                        {
                            "code": "068010",
                            "cap": "65011",
                            "nome": "Catignano"
                        },
                        {
                            "code": "068011",
                            "cap": "65012",
                            "nome": "Cepagatti"
                        },
                        {
                            "code": "068012",
                            "cap": "65013",
                            "nome": "Citt\u00e0 Sant'Angelo"
                        },
                        {
                            "code": "068013",
                            "cap": "65010",
                            "nome": "Civitaquana"
                        },
                        {
                            "code": "068014",
                            "cap": "65010",
                            "nome": "Civitella Casanova"
                        },
                        {
                            "code": "068015",
                            "cap": "65010",
                            "nome": "Collecorvino"
                        },
                        {
                            "code": "068016",
                            "cap": "65020",
                            "nome": "Corvara"
                        },
                        {
                            "code": "068017",
                            "cap": "65020",
                            "nome": "Cugnoli"
                        },
                        {
                            "code": "068018",
                            "cap": "65010",
                            "nome": "Elice"
                        },
                        {
                            "code": "068019",
                            "cap": "65010",
                            "nome": "Farindola"
                        },
                        {
                            "code": "068020",
                            "cap": "65020",
                            "nome": "Lettomanoppello"
                        },
                        {
                            "code": "068021",
                            "cap": "65014",
                            "nome": "Loreto Aprutino"
                        },
                        {
                            "code": "068022",
                            "cap": "65024",
                            "nome": "Manoppello"
                        },
                        {
                            "code": "068023",
                            "cap": "65010",
                            "nome": "Montebello di Bertona"
                        },
                        {
                            "code": "068024",
                            "cap": "65015",
                            "nome": "Montesilvano"
                        },
                        {
                            "code": "068025",
                            "cap": "65010",
                            "nome": "Moscufo"
                        },
                        {
                            "code": "068026",
                            "cap": "65010",
                            "nome": "Nocciano"
                        },
                        {
                            "code": "068027",
                            "cap": "65017",
                            "nome": "Penne"
                        },
                        {
                            "code": "068028",
                            "cap": "6512x",
                            "nome": "Pescara"
                        },
                        {
                            "code": "068029",
                            "cap": "65020",
                            "nome": "Pescosansonesco"
                        },
                        {
                            "code": "068030",
                            "cap": "65019",
                            "nome": "Pianella"
                        },
                        {
                            "code": "068031",
                            "cap": "65010",
                            "nome": "Picciano"
                        },
                        {
                            "code": "068032",
                            "cap": "65020",
                            "nome": "Pietranico"
                        },
                        {
                            "code": "068033",
                            "cap": "65026",
                            "nome": "Popoli"
                        },
                        {
                            "code": "068034",
                            "cap": "65020",
                            "nome": "Roccamorice"
                        },
                        {
                            "code": "068035",
                            "cap": "65020",
                            "nome": "Rosciano"
                        },
                        {
                            "code": "068036",
                            "cap": "65020",
                            "nome": "Salle"
                        },
                        {
                            "code": "068038",
                            "cap": "65020",
                            "nome": "San Valentino in Abruzzo Citeriore"
                        },
                        {
                            "code": "068037",
                            "cap": "65020",
                            "nome": "Sant'Eufemia a Maiella"
                        },
                        {
                            "code": "068039",
                            "cap": "65027",
                            "nome": "Scafa"
                        },
                        {
                            "code": "068040",
                            "cap": "65025",
                            "nome": "Serramonacesca"
                        },
                        {
                            "code": "068041",
                            "cap": "65010",
                            "nome": "Spoltore"
                        },
                        {
                            "code": "068042",
                            "cap": "65028",
                            "nome": "Tocco da Casauria"
                        },
                        {
                            "code": "068043",
                            "cap": "65029",
                            "nome": "Torre De' Passeri"
                        },
                        {
                            "code": "068044",
                            "cap": "65020",
                            "nome": "Turrivalignani"
                        },
                        {
                            "code": "068045",
                            "cap": "65010",
                            "nome": "Vicoli"
                        },
                        {
                            "code": "068046",
                            "cap": "65010",
                            "nome": "Villa Celiera"
                        }
                    ],
                    "nome": "Pescara"
                },
                {
                    "code": "TE",
                    "comuni": [
                        {
                            "code": "067001",
                            "cap": "64011",
                            "nome": "Alba Adriatica"
                        },
                        {
                            "code": "067002",
                            "cap": "64010",
                            "nome": "Ancarano"
                        },
                        {
                            "code": "067003",
                            "cap": "64031",
                            "nome": "Arsita"
                        },
                        {
                            "code": "067004",
                            "cap": "64032",
                            "nome": "Atri"
                        },
                        {
                            "code": "067005",
                            "cap": "64030",
                            "nome": "Basciano"
                        },
                        {
                            "code": "067006",
                            "cap": "64020",
                            "nome": "Bellante"
                        },
                        {
                            "code": "067007",
                            "cap": "64033",
                            "nome": "Bisenti"
                        },
                        {
                            "code": "067008",
                            "cap": "64012",
                            "nome": "Campli"
                        },
                        {
                            "code": "067009",
                            "cap": "64020",
                            "nome": "Canzano"
                        },
                        {
                            "code": "067010",
                            "cap": "64030",
                            "nome": "Castel Castagna"
                        },
                        {
                            "code": "067011",
                            "cap": "64020",
                            "nome": "Castellalto"
                        },
                        {
                            "code": "067012",
                            "cap": "64041",
                            "nome": "Castelli"
                        },
                        {
                            "code": "067013",
                            "cap": "64034",
                            "nome": "Castiglione Messer Raimondo"
                        },
                        {
                            "code": "067014",
                            "cap": "64035",
                            "nome": "Castilenti"
                        },
                        {
                            "code": "067015",
                            "cap": "64036",
                            "nome": "Cellino Attanasio"
                        },
                        {
                            "code": "067016",
                            "cap": "64037",
                            "nome": "Cermignano"
                        },
                        {
                            "code": "067017",
                            "cap": "64010",
                            "nome": "Civitella del Tronto"
                        },
                        {
                            "code": "067018",
                            "cap": "64042",
                            "nome": "Colledara"
                        },
                        {
                            "code": "067019",
                            "cap": "64010",
                            "nome": "Colonnella"
                        },
                        {
                            "code": "067020",
                            "cap": "64010",
                            "nome": "Controguerra"
                        },
                        {
                            "code": "067021",
                            "cap": "64013",
                            "nome": "Corropoli"
                        },
                        {
                            "code": "067022",
                            "cap": "64040",
                            "nome": "Cortino"
                        },
                        {
                            "code": "067023",
                            "cap": "64043",
                            "nome": "Crognaleto"
                        },
                        {
                            "code": "067024",
                            "cap": "64044",
                            "nome": "Fano Adriano"
                        },
                        {
                            "code": "067025",
                            "cap": "64021",
                            "nome": "Giulianova"
                        },
                        {
                            "code": "067026",
                            "cap": "64045",
                            "nome": "Isola del Gran Sasso d'Italia"
                        },
                        {
                            "code": "067047",
                            "cap": "64014",
                            "nome": "Martinsicuro"
                        },
                        {
                            "code": "067027",
                            "cap": "64030",
                            "nome": "Montefino"
                        },
                        {
                            "code": "067028",
                            "cap": "64046",
                            "nome": "Montorio al Vomano"
                        },
                        {
                            "code": "067029",
                            "cap": "64020",
                            "nome": "Morro d'Oro"
                        },
                        {
                            "code": "067030",
                            "cap": "64023",
                            "nome": "Mosciano Sant'Angelo"
                        },
                        {
                            "code": "067031",
                            "cap": "64015",
                            "nome": "Nereto"
                        },
                        {
                            "code": "067032",
                            "cap": "64024",
                            "nome": "Notaresco"
                        },
                        {
                            "code": "067033",
                            "cap": "64039",
                            "nome": "Penna Sant'Andrea"
                        },
                        {
                            "code": "067034",
                            "cap": "64047",
                            "nome": "Pietracamela"
                        },
                        {
                            "code": "067035",
                            "cap": "64025",
                            "nome": "Pineto"
                        },
                        {
                            "code": "067036",
                            "cap": "64010",
                            "nome": "Rocca Santa Maria"
                        },
                        {
                            "code": "067037",
                            "cap": "64026",
                            "nome": "Roseto degli Abruzzi"
                        },
                        {
                            "code": "067038",
                            "cap": "64016",
                            "nome": "Sant'Egidio alla Vibrata"
                        },
                        {
                            "code": "067039",
                            "cap": "64027",
                            "nome": "Sant'Omero"
                        },
                        {
                            "code": "067040",
                            "cap": "64028",
                            "nome": "Silvi"
                        },
                        {
                            "code": "067041",
                            "cap": "64100",
                            "nome": "Teramo"
                        },
                        {
                            "code": "067042",
                            "cap": "64010",
                            "nome": "Torano Nuovo"
                        },
                        {
                            "code": "067043",
                            "cap": "64010",
                            "nome": "Torricella Sicura"
                        },
                        {
                            "code": "067044",
                            "cap": "64018",
                            "nome": "Tortoreto"
                        },
                        {
                            "code": "067045",
                            "cap": "64049",
                            "nome": "Tossicia"
                        },
                        {
                            "code": "067046",
                            "cap": "64010",
                            "nome": "Valle Castellana"
                        }
                    ],
                    "nome": "Teramo"
                }
            ],
            "nome": "Abruzzo"
        },
        {
            "province": [
                {
                    "code": "MT",
                    "comuni": [
                        {
                            "code": "077001",
                            "cap": "75011",
                            "nome": "Accettura"
                        },
                        {
                            "code": "077002",
                            "cap": "75010",
                            "nome": "Aliano"
                        },
                        {
                            "code": "077003",
                            "cap": "75012",
                            "nome": "Bernalda"
                        },
                        {
                            "code": "077004",
                            "cap": "75010",
                            "nome": "Calciano"
                        },
                        {
                            "code": "077005",
                            "cap": "75010",
                            "nome": "Cirigliano"
                        },
                        {
                            "code": "077006",
                            "cap": "75021",
                            "nome": "Colobraro"
                        },
                        {
                            "code": "077007",
                            "cap": "75010",
                            "nome": "Craco"
                        },
                        {
                            "code": "077008",
                            "cap": "75013",
                            "nome": "Ferrandina"
                        },
                        {
                            "code": "077009",
                            "cap": "75010",
                            "nome": "Garaguso"
                        },
                        {
                            "code": "077010",
                            "cap": "75010",
                            "nome": "Gorgoglione"
                        },
                        {
                            "code": "077011",
                            "cap": "75014",
                            "nome": "Grassano"
                        },
                        {
                            "code": "077012",
                            "cap": "75010",
                            "nome": "Grottole"
                        },
                        {
                            "code": "077013",
                            "cap": "75022",
                            "nome": "Irsina"
                        },
                        {
                            "code": "077014",
                            "cap": "75100",
                            "nome": "Matera"
                        },
                        {
                            "code": "077015",
                            "cap": "75010",
                            "nome": "Miglionico"
                        },
                        {
                            "code": "077016",
                            "cap": "75023",
                            "nome": "Montalbano Jonico"
                        },
                        {
                            "code": "077017",
                            "cap": "75024",
                            "nome": "Montescaglioso"
                        },
                        {
                            "code": "077018",
                            "cap": "75020",
                            "nome": "Nova Siri"
                        },
                        {
                            "code": "077019",
                            "cap": "75010",
                            "nome": "Oliveto Lucano"
                        },
                        {
                            "code": "077020",
                            "cap": "75015",
                            "nome": "Pisticci"
                        },
                        {
                            "code": "077021",
                            "cap": "75025",
                            "nome": "Policoro"
                        },
                        {
                            "code": "077022",
                            "cap": "75016",
                            "nome": "Pomarico"
                        },
                        {
                            "code": "077023",
                            "cap": "75026",
                            "nome": "Rotondella"
                        },
                        {
                            "code": "077024",
                            "cap": "75017",
                            "nome": "Salandra"
                        },
                        {
                            "code": "077025",
                            "cap": "75027",
                            "nome": "San Giorgio Lucano"
                        },
                        {
                            "code": "077026",
                            "cap": "75010",
                            "nome": "San Mauro Forte"
                        },
                        {
                            "code": "077031",
                            "cap": "75020",
                            "nome": "Scanzano Jonico"
                        },
                        {
                            "code": "077027",
                            "cap": "75018",
                            "nome": "Stigliano"
                        },
                        {
                            "code": "077028",
                            "cap": "75019",
                            "nome": "Tricarico"
                        },
                        {
                            "code": "077029",
                            "cap": "75028",
                            "nome": "Tursi"
                        },
                        {
                            "code": "077030",
                            "cap": "75029",
                            "nome": "Valsinni"
                        }
                    ],
                    "nome": "Matera"
                },
                {
                    "code": "PZ",
                    "comuni": [
                        {
                            "code": "076001",
                            "cap": "85010",
                            "nome": "Abriola"
                        },
                        {
                            "code": "076002",
                            "cap": "85011",
                            "nome": "Acerenza"
                        },
                        {
                            "code": "076003",
                            "cap": "85010",
                            "nome": "Albano di Lucania"
                        },
                        {
                            "code": "076004",
                            "cap": "85010",
                            "nome": "Anzi"
                        },
                        {
                            "code": "076005",
                            "cap": "85010",
                            "nome": "Armento"
                        },
                        {
                            "code": "076006",
                            "cap": "85020",
                            "nome": "Atella"
                        },
                        {
                            "code": "076007",
                            "cap": "85021",
                            "nome": "Avigliano"
                        },
                        {
                            "code": "076008",
                            "cap": "85050",
                            "nome": "Balvano"
                        },
                        {
                            "code": "076009",
                            "cap": "85010",
                            "nome": "Banzi"
                        },
                        {
                            "code": "076010",
                            "cap": "85050",
                            "nome": "Baragiano"
                        },
                        {
                            "code": "076011",
                            "cap": "85022",
                            "nome": "Barile"
                        },
                        {
                            "code": "076012",
                            "cap": "85051",
                            "nome": "Bella"
                        },
                        {
                            "code": "076013",
                            "cap": "85050",
                            "nome": "Brienza"
                        },
                        {
                            "code": "076014",
                            "cap": "85010",
                            "nome": "Brindisi Montagna"
                        },
                        {
                            "code": "076015",
                            "cap": "85010",
                            "nome": "Calvello"
                        },
                        {
                            "code": "076016",
                            "cap": "85030",
                            "nome": "Calvera"
                        },
                        {
                            "code": "076017",
                            "cap": "85010",
                            "nome": "Campomaggiore"
                        },
                        {
                            "code": "076018",
                            "cap": "85010",
                            "nome": "Cancellara"
                        },
                        {
                            "code": "076019",
                            "cap": "85030",
                            "nome": "Carbone"
                        },
                        {
                            "code": "076021",
                            "cap": "85050",
                            "nome": "Castelgrande"
                        },
                        {
                            "code": "076022",
                            "cap": "85040",
                            "nome": "Castelluccio Inferiore"
                        },
                        {
                            "code": "076023",
                            "cap": "85040",
                            "nome": "Castelluccio Superiore"
                        },
                        {
                            "code": "076024",
                            "cap": "85010",
                            "nome": "Castelmezzano"
                        },
                        {
                            "code": "076025",
                            "cap": "85031",
                            "nome": "Castelsaraceno"
                        },
                        {
                            "code": "076026",
                            "cap": "85030",
                            "nome": "Castronuovo di Sant'Andrea"
                        },
                        {
                            "code": "076027",
                            "cap": "85030",
                            "nome": "Cersosimo"
                        },
                        {
                            "code": "076028",
                            "cap": "85032",
                            "nome": "Chiaromonte"
                        },
                        {
                            "code": "076029",
                            "cap": "85012",
                            "nome": "Corleto Perticara"
                        },
                        {
                            "code": "076030",
                            "cap": "85033",
                            "nome": "Episcopia"
                        },
                        {
                            "code": "076031",
                            "cap": "85034",
                            "nome": "Fardella"
                        },
                        {
                            "code": "076032",
                            "cap": "85020",
                            "nome": "Filiano"
                        },
                        {
                            "code": "076033",
                            "cap": "85023",
                            "nome": "Forenza"
                        },
                        {
                            "code": "076034",
                            "cap": "85034",
                            "nome": "Francavilla in Sinni"
                        },
                        {
                            "code": "076035",
                            "cap": "85010",
                            "nome": "Gallicchio"
                        },
                        {
                            "code": "076036",
                            "cap": "85013",
                            "nome": "Genzano di Lucania"
                        },
                        {
                            "code": "076099",
                            "cap": "85020",
                            "nome": "Ginestra"
                        },
                        {
                            "code": "076037",
                            "cap": "85050",
                            "nome": "Grumento Nova"
                        },
                        {
                            "code": "076038",
                            "cap": "85010",
                            "nome": "Guardia Perticara"
                        },
                        {
                            "code": "076039",
                            "cap": "85042",
                            "nome": "Lagonegro"
                        },
                        {
                            "code": "076040",
                            "cap": "85043",
                            "nome": "Latronico"
                        },
                        {
                            "code": "076041",
                            "cap": "85014",
                            "nome": "Laurenzana"
                        },
                        {
                            "code": "076042",
                            "cap": "85044",
                            "nome": "Lauria"
                        },
                        {
                            "code": "076043",
                            "cap": "85024",
                            "nome": "Lavello"
                        },
                        {
                            "code": "076044",
                            "cap": "85046",
                            "nome": "Maratea"
                        },
                        {
                            "code": "076045",
                            "cap": "85052",
                            "nome": "Marsico Nuovo"
                        },
                        {
                            "code": "076046",
                            "cap": "85050",
                            "nome": "Marsicovetere"
                        },
                        {
                            "code": "076047",
                            "cap": "85020",
                            "nome": "Maschito"
                        },
                        {
                            "code": "076048",
                            "cap": "85025",
                            "nome": "Melfi"
                        },
                        {
                            "code": "076049",
                            "cap": "85010",
                            "nome": "Missanello"
                        },
                        {
                            "code": "076050",
                            "cap": "85047",
                            "nome": "Moliterno"
                        },
                        {
                            "code": "076051",
                            "cap": "85020",
                            "nome": "Montemilone"
                        },
                        {
                            "code": "076052",
                            "cap": "85053",
                            "nome": "Montemurro"
                        },
                        {
                            "code": "076053",
                            "cap": "85054",
                            "nome": "Muro Lucano"
                        },
                        {
                            "code": "076054",
                            "cap": "85040",
                            "nome": "Nemoli"
                        },
                        {
                            "code": "076055",
                            "cap": "85035",
                            "nome": "Noepoli"
                        },
                        {
                            "code": "076056",
                            "cap": "85015",
                            "nome": "Oppido Lucano"
                        },
                        {
                            "code": "076057",
                            "cap": "85026",
                            "nome": "Palazzo San Gervasio"
                        },
                        {
                            "code": "076100",
                            "cap": "85050",
                            "nome": "Paterno"
                        },
                        {
                            "code": "076058",
                            "cap": "85020",
                            "nome": "Pescopagano"
                        },
                        {
                            "code": "076059",
                            "cap": "85055",
                            "nome": "Picerno"
                        },
                        {
                            "code": "076060",
                            "cap": "85016",
                            "nome": "Pietragalla"
                        },
                        {
                            "code": "076061",
                            "cap": "85010",
                            "nome": "Pietrapertosa"
                        },
                        {
                            "code": "076062",
                            "cap": "85010",
                            "nome": "Pignola"
                        },
                        {
                            "code": "076063",
                            "cap": "85100",
                            "nome": "Potenza"
                        },
                        {
                            "code": "076064",
                            "cap": "85027",
                            "nome": "Rapolla"
                        },
                        {
                            "code": "076065",
                            "cap": "85020",
                            "nome": "Rapone"
                        },
                        {
                            "code": "076066",
                            "cap": "85028",
                            "nome": "Rionero in Vulture"
                        },
                        {
                            "code": "076067",
                            "cap": "85020",
                            "nome": "Ripacandida"
                        },
                        {
                            "code": "076068",
                            "cap": "85040",
                            "nome": "Rivello"
                        },
                        {
                            "code": "076069",
                            "cap": "85036",
                            "nome": "Roccanova"
                        },
                        {
                            "code": "076070",
                            "cap": "85048",
                            "nome": "Rotonda"
                        },
                        {
                            "code": "076071",
                            "cap": "85056",
                            "nome": "Ruoti"
                        },
                        {
                            "code": "076072",
                            "cap": "85020",
                            "nome": "Ruvo del Monte"
                        },
                        {
                            "code": "076073",
                            "cap": "85010",
                            "nome": "San Chirico Nuovo"
                        },
                        {
                            "code": "076074",
                            "cap": "85030",
                            "nome": "San Chirico Raparo"
                        },
                        {
                            "code": "076075",
                            "cap": "85030",
                            "nome": "San Costantino Albanese"
                        },
                        {
                            "code": "076076",
                            "cap": "85020",
                            "nome": "San Fele"
                        },
                        {
                            "code": "076077",
                            "cap": "85030",
                            "nome": "San Martino d'Agri"
                        },
                        {
                            "code": "076020",
                            "cap": "85030",
                            "nome": "San Paolo Albanese"
                        },
                        {
                            "code": "076078",
                            "cap": "85030",
                            "nome": "San Severino Lucano"
                        },
                        {
                            "code": "076079",
                            "cap": "85050",
                            "nome": "Sant'Angelo Le Fratte"
                        },
                        {
                            "code": "076080",
                            "cap": "85037",
                            "nome": "Sant'Arcangelo"
                        },
                        {
                            "code": "076081",
                            "cap": "85050",
                            "nome": "Sarconi"
                        },
                        {
                            "code": "076082",
                            "cap": "85050",
                            "nome": "Sasso di Castalda"
                        },
                        {
                            "code": "076083",
                            "cap": "85050",
                            "nome": "Satriano di Lucania"
                        },
                        {
                            "code": "076084",
                            "cap": "85050",
                            "nome": "Savoia di Lucania"
                        },
                        {
                            "code": "076085",
                            "cap": "85038",
                            "nome": "Senise"
                        },
                        {
                            "code": "076086",
                            "cap": "85039",
                            "nome": "Spinoso"
                        },
                        {
                            "code": "076087",
                            "cap": "85032",
                            "nome": "Teana"
                        },
                        {
                            "code": "076088",
                            "cap": "85030",
                            "nome": "Terranova di Pollino"
                        },
                        {
                            "code": "076089",
                            "cap": "85050",
                            "nome": "Tito"
                        },
                        {
                            "code": "076090",
                            "cap": "85017",
                            "nome": "Tolve"
                        },
                        {
                            "code": "076091",
                            "cap": "85057",
                            "nome": "Tramutola"
                        },
                        {
                            "code": "076092",
                            "cap": "85049",
                            "nome": "Trecchina"
                        },
                        {
                            "code": "076093",
                            "cap": "85018",
                            "nome": "Trivigno"
                        },
                        {
                            "code": "076094",
                            "cap": "85010",
                            "nome": "Vaglio Basilicata"
                        },
                        {
                            "code": "076095",
                            "cap": "85029",
                            "nome": "Venosa"
                        },
                        {
                            "code": "076096",
                            "cap": "85058",
                            "nome": "Vietri di Potenza"
                        },
                        {
                            "code": "076097",
                            "cap": "85040",
                            "nome": "Viggianello"
                        },
                        {
                            "code": "076098",
                            "cap": "85059",
                            "nome": "Viggiano"
                        }
                    ],
                    "nome": "Potenza"
                }
            ],
            "nome": "Basilicata"
        },
        {
            "province": [
                {
                    "code": "CZ",
                    "comuni": [
                        {
                            "code": "079002",
                            "cap": "88055",
                            "nome": "Albi"
                        },
                        {
                            "code": "079003",
                            "cap": "88050",
                            "nome": "Amaroni"
                        },
                        {
                            "code": "079004",
                            "cap": "88040",
                            "nome": "Amato"
                        },
                        {
                            "code": "079005",
                            "cap": "88050",
                            "nome": "Andali"
                        },
                        {
                            "code": "079007",
                            "cap": "88060",
                            "nome": "Argusto"
                        },
                        {
                            "code": "079008",
                            "cap": "88060",
                            "nome": "Badolato"
                        },
                        {
                            "code": "079009",
                            "cap": "88050",
                            "nome": "Belcastro"
                        },
                        {
                            "code": "079011",
                            "cap": "88021",
                            "nome": "Borgia"
                        },
                        {
                            "code": "079012",
                            "cap": "88070",
                            "nome": "Botricello"
                        },
                        {
                            "code": "079017",
                            "cap": "88050",
                            "nome": "Caraffa di Catanzaro"
                        },
                        {
                            "code": "079018",
                            "cap": "88062",
                            "nome": "Cardinale"
                        },
                        {
                            "code": "079020",
                            "cap": "88040",
                            "nome": "Carlopoli"
                        },
                        {
                            "code": "079023",
                            "cap": "88100",
                            "nome": "Catanzaro"
                        },
                        {
                            "code": "079024",
                            "cap": "88067",
                            "nome": "Cenadi"
                        },
                        {
                            "code": "079025",
                            "cap": "88067",
                            "nome": "Centrache"
                        },
                        {
                            "code": "079027",
                            "cap": "88050",
                            "nome": "Cerva"
                        },
                        {
                            "code": "079029",
                            "cap": "88064",
                            "nome": "Chiaravalle Centrale"
                        },
                        {
                            "code": "079030",
                            "cap": "88040",
                            "nome": "Cicala"
                        },
                        {
                            "code": "079033",
                            "cap": "88040",
                            "nome": "Conflenti"
                        },
                        {
                            "code": "079034",
                            "cap": "88020",
                            "nome": "Cortale"
                        },
                        {
                            "code": "079036",
                            "cap": "88051",
                            "nome": "Cropani"
                        },
                        {
                            "code": "079039",
                            "cap": "88022",
                            "nome": "Curinga"
                        },
                        {
                            "code": "079042",
                            "cap": "88060",
                            "nome": "Davoli"
                        },
                        {
                            "code": "079043",
                            "cap": "88041",
                            "nome": "Decollatura"
                        },
                        {
                            "code": "079047",
                            "cap": "88042",
                            "nome": "Falerna"
                        },
                        {
                            "code": "079048",
                            "cap": "88040",
                            "nome": "Feroleto Antico"
                        },
                        {
                            "code": "079052",
                            "cap": "88050",
                            "nome": "Fossato Serralta"
                        },
                        {
                            "code": "079055",
                            "cap": "88060",
                            "nome": "Gagliato"
                        },
                        {
                            "code": "079056",
                            "cap": "88060",
                            "nome": "Gasperina"
                        },
                        {
                            "code": "079058",
                            "cap": "88045",
                            "nome": "Gimigliano"
                        },
                        {
                            "code": "079059",
                            "cap": "88024",
                            "nome": "Girifalco"
                        },
                        {
                            "code": "079060",
                            "cap": "88040",
                            "nome": "Gizzeria"
                        },
                        {
                            "code": "079061",
                            "cap": "88065",
                            "nome": "Guardavalle"
                        },
                        {
                            "code": "079063",
                            "cap": "88060",
                            "nome": "Isca sullo Ionio"
                        },
                        {
                            "code": "079065",
                            "cap": "88020",
                            "nome": "Jacurso"
                        },
                        {
                            "code": "079160",
                            "cap": "88046",
                            "nome": "Lamezia Terme"
                        },
                        {
                            "code": "079068",
                            "cap": "88050",
                            "nome": "Magisano"
                        },
                        {
                            "code": "079069",
                            "cap": "88025",
                            "nome": "Maida"
                        },
                        {
                            "code": "079071",
                            "cap": "88050",
                            "nome": "Marcedusa"
                        },
                        {
                            "code": "079072",
                            "cap": "88044",
                            "nome": "Marcellinara"
                        },
                        {
                            "code": "079073",
                            "cap": "88040",
                            "nome": "Martirano"
                        },
                        {
                            "code": "079074",
                            "cap": "88040",
                            "nome": "Martirano Lombardo"
                        },
                        {
                            "code": "079077",
                            "cap": "88040",
                            "nome": "Miglierina"
                        },
                        {
                            "code": "079080",
                            "cap": "88060",
                            "nome": "Montauro"
                        },
                        {
                            "code": "079081",
                            "cap": "88060",
                            "nome": "Montepaone"
                        },
                        {
                            "code": "079083",
                            "cap": "88040",
                            "nome": "Motta Santa Lucia"
                        },
                        {
                            "code": "079087",
                            "cap": "88047",
                            "nome": "Nocera Terinese"
                        },
                        {
                            "code": "079088",
                            "cap": "88067",
                            "nome": "Olivadi"
                        },
                        {
                            "code": "079089",
                            "cap": "88050",
                            "nome": "Palermiti"
                        },
                        {
                            "code": "079092",
                            "cap": "88050",
                            "nome": "Pentone"
                        },
                        {
                            "code": "079094",
                            "cap": "88060",
                            "nome": "Petrizzi"
                        },
                        {
                            "code": "079095",
                            "cap": "88050",
                            "nome": "Petron\u00e0"
                        },
                        {
                            "code": "079096",
                            "cap": "88040",
                            "nome": "Pianopoli"
                        },
                        {
                            "code": "079099",
                            "cap": "88040",
                            "nome": "Platania"
                        },
                        {
                            "code": "079108",
                            "cap": "88021",
                            "nome": "San Floro"
                        },
                        {
                            "code": "079110",
                            "cap": "88040",
                            "nome": "San Mango d'Aquino"
                        },
                        {
                            "code": "079114",
                            "cap": "88025",
                            "nome": "San Pietro a Maida"
                        },
                        {
                            "code": "079115",
                            "cap": "88040",
                            "nome": "San Pietro Apostolo"
                        },
                        {
                            "code": "079116",
                            "cap": "88060",
                            "nome": "San Sostene"
                        },
                        {
                            "code": "079122",
                            "cap": "88067",
                            "nome": "San Vito sullo Ionio"
                        },
                        {
                            "code": "079117",
                            "cap": "88060",
                            "nome": "Santa Caterina dello Ionio"
                        },
                        {
                            "code": "079118",
                            "cap": "88060",
                            "nome": "Sant'Andrea Apostolo dello Ionio"
                        },
                        {
                            "code": "079123",
                            "cap": "88060",
                            "nome": "Satriano"
                        },
                        {
                            "code": "079126",
                            "cap": "88050",
                            "nome": "Sellia"
                        },
                        {
                            "code": "079127",
                            "cap": "88050",
                            "nome": "Sellia Marina"
                        },
                        {
                            "code": "079129",
                            "cap": "88040",
                            "nome": "Serrastretta"
                        },
                        {
                            "code": "079130",
                            "cap": "88054",
                            "nome": "Sersale"
                        },
                        {
                            "code": "079131",
                            "cap": "88040",
                            "nome": "Settingiano"
                        },
                        {
                            "code": "079133",
                            "cap": "88050",
                            "nome": "Simeri Crichi"
                        },
                        {
                            "code": "079134",
                            "cap": "88050",
                            "nome": "Sorbo San Basile"
                        },
                        {
                            "code": "079137",
                            "cap": "88068",
                            "nome": "Soverato"
                        },
                        {
                            "code": "079138",
                            "cap": "88049",
                            "nome": "Soveria Mannelli"
                        },
                        {
                            "code": "079139",
                            "cap": "88050",
                            "nome": "Soveria Simeri"
                        },
                        {
                            "code": "079142",
                            "cap": "88069",
                            "nome": "Squillace"
                        },
                        {
                            "code": "079143",
                            "cap": "88069",
                            "nome": "Stalett\u00ec"
                        },
                        {
                            "code": "079146",
                            "cap": "88055",
                            "nome": "Taverna"
                        },
                        {
                            "code": "079147",
                            "cap": "88056",
                            "nome": "Tiriolo"
                        },
                        {
                            "code": "079148",
                            "cap": "88060",
                            "nome": "Torre di Ruggiero"
                        },
                        {
                            "code": "079151",
                            "cap": "88050",
                            "nome": "Vallefiorita"
                        },
                        {
                            "code": "079157",
                            "cap": "88050",
                            "nome": "Zagarise"
                        }
                    ],
                    "nome": "Catanzaro"
                },
                {
                    "code": "CS",
                    "comuni": [
                        {
                            "code": "078001",
                            "cap": "87010",
                            "nome": "Acquaformosa"
                        },
                        {
                            "code": "078002",
                            "cap": "87020",
                            "nome": "Acquappesa"
                        },
                        {
                            "code": "078003",
                            "cap": "87041",
                            "nome": "Acri"
                        },
                        {
                            "code": "078004",
                            "cap": "87031",
                            "nome": "Aiello Calabro"
                        },
                        {
                            "code": "078005",
                            "cap": "87020",
                            "nome": "Aieta"
                        },
                        {
                            "code": "078006",
                            "cap": "87070",
                            "nome": "Albidona"
                        },
                        {
                            "code": "078007",
                            "cap": "87070",
                            "nome": "Alessandria del Carretto"
                        },
                        {
                            "code": "078008",
                            "cap": "87040",
                            "nome": "Altilia"
                        },
                        {
                            "code": "078009",
                            "cap": "87042",
                            "nome": "Altomonte"
                        },
                        {
                            "code": "078010",
                            "cap": "87032",
                            "nome": "Amantea"
                        },
                        {
                            "code": "078011",
                            "cap": "87071",
                            "nome": "Amendolara"
                        },
                        {
                            "code": "078012",
                            "cap": "87051",
                            "nome": "Aprigliano"
                        },
                        {
                            "code": "078013",
                            "cap": "87033",
                            "nome": "Belmonte Calabro"
                        },
                        {
                            "code": "078014",
                            "cap": "87030",
                            "nome": "Belsito"
                        },
                        {
                            "code": "078015",
                            "cap": "87021",
                            "nome": "Belvedere Marittimo"
                        },
                        {
                            "code": "078016",
                            "cap": "87050",
                            "nome": "Bianchi"
                        },
                        {
                            "code": "078017",
                            "cap": "87043",
                            "nome": "Bisignano"
                        },
                        {
                            "code": "078018",
                            "cap": "87060",
                            "nome": "Bocchigliero"
                        },
                        {
                            "code": "078019",
                            "cap": "87020",
                            "nome": "Bonifati"
                        },
                        {
                            "code": "078020",
                            "cap": "87020",
                            "nome": "Buonvicino"
                        },
                        {
                            "code": "078021",
                            "cap": "87060",
                            "nome": "Calopezzati"
                        },
                        {
                            "code": "078022",
                            "cap": "87060",
                            "nome": "Caloveto"
                        },
                        {
                            "code": "078023",
                            "cap": "87061",
                            "nome": "Campana"
                        },
                        {
                            "code": "078024",
                            "cap": "87070",
                            "nome": "Canna"
                        },
                        {
                            "code": "078025",
                            "cap": "87062",
                            "nome": "Cariati"
                        },
                        {
                            "code": "078026",
                            "cap": "87030",
                            "nome": "Carolei"
                        },
                        {
                            "code": "078027",
                            "cap": "87050",
                            "nome": "Carpanzano"
                        },
                        {
                            "code": "078028",
                            "cap": "87050",
                            "nome": "Casole Bruzio"
                        },
                        {
                            "code": "078029",
                            "cap": "87011",
                            "nome": "Cassano all'Ionio"
                        },
                        {
                            "code": "078030",
                            "cap": "87040",
                            "nome": "Castiglione Cosentino"
                        },
                        {
                            "code": "078031",
                            "cap": "87040",
                            "nome": "Castrolibero"
                        },
                        {
                            "code": "078032",
                            "cap": "87070",
                            "nome": "Castroregio"
                        },
                        {
                            "code": "078033",
                            "cap": "87012",
                            "nome": "Castrovillari"
                        },
                        {
                            "code": "078034",
                            "cap": "87053",
                            "nome": "Celico"
                        },
                        {
                            "code": "078035",
                            "cap": "87050",
                            "nome": "Cellara"
                        },
                        {
                            "code": "078036",
                            "cap": "87070",
                            "nome": "Cerchiara di Calabria"
                        },
                        {
                            "code": "078037",
                            "cap": "87044",
                            "nome": "Cerisano"
                        },
                        {
                            "code": "078038",
                            "cap": "87010",
                            "nome": "Cervicati"
                        },
                        {
                            "code": "078039",
                            "cap": "87040",
                            "nome": "Cerzeto"
                        },
                        {
                            "code": "078040",
                            "cap": "87022",
                            "nome": "Cetraro"
                        },
                        {
                            "code": "078041",
                            "cap": "87010",
                            "nome": "Civita"
                        },
                        {
                            "code": "078042",
                            "cap": "87030",
                            "nome": "Cleto"
                        },
                        {
                            "code": "078043",
                            "cap": "87050",
                            "nome": "Colosimi"
                        },
                        {
                            "code": "078044",
                            "cap": "87064",
                            "nome": "Corigliano Calabro"
                        },
                        {
                            "code": "078045",
                            "cap": "87100",
                            "nome": "Cosenza"
                        },
                        {
                            "code": "078046",
                            "cap": "87060",
                            "nome": "Cropalati"
                        },
                        {
                            "code": "078047",
                            "cap": "87060",
                            "nome": "Crosia"
                        },
                        {
                            "code": "078048",
                            "cap": "87023",
                            "nome": "Diamante"
                        },
                        {
                            "code": "078049",
                            "cap": "87045",
                            "nome": "Dipignano"
                        },
                        {
                            "code": "078050",
                            "cap": "87030",
                            "nome": "Domanico"
                        },
                        {
                            "code": "078051",
                            "cap": "87013",
                            "nome": "Fagnano Castello"
                        },
                        {
                            "code": "078052",
                            "cap": "87030",
                            "nome": "Falconara Albanese"
                        },
                        {
                            "code": "078053",
                            "cap": "87050",
                            "nome": "Figline Vegliaturo"
                        },
                        {
                            "code": "078054",
                            "cap": "87010",
                            "nome": "Firmo"
                        },
                        {
                            "code": "078055",
                            "cap": "87030",
                            "nome": "Fiumefreddo Bruzio"
                        },
                        {
                            "code": "078056",
                            "cap": "87072",
                            "nome": "Francavilla Marittima"
                        },
                        {
                            "code": "078057",
                            "cap": "87010",
                            "nome": "Frascineto"
                        },
                        {
                            "code": "078058",
                            "cap": "87024",
                            "nome": "Fuscaldo"
                        },
                        {
                            "code": "078059",
                            "cap": "87034",
                            "nome": "Grimaldi"
                        },
                        {
                            "code": "078060",
                            "cap": "87020",
                            "nome": "Grisolia"
                        },
                        {
                            "code": "078061",
                            "cap": "87020",
                            "nome": "Guardia Piemontese"
                        },
                        {
                            "code": "078062",
                            "cap": "87035",
                            "nome": "Lago"
                        },
                        {
                            "code": "078063",
                            "cap": "87014",
                            "nome": "Laino Borgo"
                        },
                        {
                            "code": "078064",
                            "cap": "87015",
                            "nome": "Laino Castello"
                        },
                        {
                            "code": "078065",
                            "cap": "87050",
                            "nome": "Lappano"
                        },
                        {
                            "code": "078066",
                            "cap": "87010",
                            "nome": "Lattarico"
                        },
                        {
                            "code": "078067",
                            "cap": "87030",
                            "nome": "Longobardi"
                        },
                        {
                            "code": "078068",
                            "cap": "87066",
                            "nome": "Longobucco"
                        },
                        {
                            "code": "078069",
                            "cap": "87010",
                            "nome": "Lungro"
                        },
                        {
                            "code": "078070",
                            "cap": "87040",
                            "nome": "Luzzi"
                        },
                        {
                            "code": "078071",
                            "cap": "87020",
                            "nome": "Maier\u00e0"
                        },
                        {
                            "code": "078072",
                            "cap": "87030",
                            "nome": "Malito"
                        },
                        {
                            "code": "078073",
                            "cap": "87010",
                            "nome": "Malvito"
                        },
                        {
                            "code": "078074",
                            "cap": "87060",
                            "nome": "Mandatoriccio"
                        },
                        {
                            "code": "078075",
                            "cap": "87050",
                            "nome": "Mangone"
                        },
                        {
                            "code": "078076",
                            "cap": "87040",
                            "nome": "Marano Marchesato"
                        },
                        {
                            "code": "078077",
                            "cap": "87040",
                            "nome": "Marano Principato"
                        },
                        {
                            "code": "078078",
                            "cap": "87050",
                            "nome": "Marzi"
                        },
                        {
                            "code": "078079",
                            "cap": "87040",
                            "nome": "Mendicino"
                        },
                        {
                            "code": "078080",
                            "cap": "87040",
                            "nome": "Mongrassano"
                        },
                        {
                            "code": "078081",
                            "cap": "87046",
                            "nome": "Montalto Uffugo"
                        },
                        {
                            "code": "078082",
                            "cap": "87070",
                            "nome": "Montegiordano"
                        },
                        {
                            "code": "078083",
                            "cap": "87016",
                            "nome": "Morano Calabro"
                        },
                        {
                            "code": "078084",
                            "cap": "87026",
                            "nome": "Mormanno"
                        },
                        {
                            "code": "078085",
                            "cap": "87010",
                            "nome": "Mottafollone"
                        },
                        {
                            "code": "078086",
                            "cap": "87070",
                            "nome": "Nocara"
                        },
                        {
                            "code": "078087",
                            "cap": "87073",
                            "nome": "Oriolo"
                        },
                        {
                            "code": "078088",
                            "cap": "87020",
                            "nome": "Orsomarso"
                        },
                        {
                            "code": "078089",
                            "cap": "87060",
                            "nome": "Paludi"
                        },
                        {
                            "code": "078090",
                            "cap": "87050",
                            "nome": "Panettieri"
                        },
                        {
                            "code": "078091",
                            "cap": "87027",
                            "nome": "Paola"
                        },
                        {
                            "code": "078092",
                            "cap": "87020",
                            "nome": "Papasidero"
                        },
                        {
                            "code": "078093",
                            "cap": "87040",
                            "nome": "Parenti"
                        },
                        {
                            "code": "078094",
                            "cap": "87040",
                            "nome": "Paterno Calabro"
                        },
                        {
                            "code": "078095",
                            "cap": "87050",
                            "nome": "Pedace"
                        },
                        {
                            "code": "078096",
                            "cap": "87050",
                            "nome": "Pedivigliano"
                        },
                        {
                            "code": "078097",
                            "cap": "87050",
                            "nome": "Piane Crati"
                        },
                        {
                            "code": "078098",
                            "cap": "87050",
                            "nome": "Pietrafitta"
                        },
                        {
                            "code": "078099",
                            "cap": "87060",
                            "nome": "Pietrapaola"
                        },
                        {
                            "code": "078100",
                            "cap": "87070",
                            "nome": "Plataci"
                        },
                        {
                            "code": "078101",
                            "cap": "87028",
                            "nome": "Praia a Mare"
                        },
                        {
                            "code": "078102",
                            "cap": "87036",
                            "nome": "Rende"
                        },
                        {
                            "code": "078103",
                            "cap": "87074",
                            "nome": "Rocca Imperiale"
                        },
                        {
                            "code": "078104",
                            "cap": "87017",
                            "nome": "Roggiano Gravina"
                        },
                        {
                            "code": "078105",
                            "cap": "87054",
                            "nome": "Rogliano"
                        },
                        {
                            "code": "078106",
                            "cap": "87040",
                            "nome": "Rose"
                        },
                        {
                            "code": "078107",
                            "cap": "87070",
                            "nome": "Roseto Capo Spulico"
                        },
                        {
                            "code": "078108",
                            "cap": "87067",
                            "nome": "Rossano"
                        },
                        {
                            "code": "078109",
                            "cap": "87010",
                            "nome": "Rota Greca"
                        },
                        {
                            "code": "078110",
                            "cap": "87050",
                            "nome": "Rovito"
                        },
                        {
                            "code": "078111",
                            "cap": "87010",
                            "nome": "San Basile"
                        },
                        {
                            "code": "078112",
                            "cap": "87040",
                            "nome": "San Benedetto Ullano"
                        },
                        {
                            "code": "078113",
                            "cap": "87060",
                            "nome": "San Cosmo Albanese"
                        },
                        {
                            "code": "078114",
                            "cap": "87069",
                            "nome": "San Demetrio Corone"
                        },
                        {
                            "code": "078115",
                            "cap": "87010",
                            "nome": "San Donato di Ninea"
                        },
                        {
                            "code": "078116",
                            "cap": "87037",
                            "nome": "San Fili"
                        },
                        {
                            "code": "078118",
                            "cap": "87060",
                            "nome": "San Giorgio Albanese"
                        },
                        {
                            "code": "078119",
                            "cap": "87055",
                            "nome": "San Giovanni in Fiore"
                        },
                        {
                            "code": "078120",
                            "cap": "87070",
                            "nome": "San Lorenzo Bellizzi"
                        },
                        {
                            "code": "078121",
                            "cap": "87040",
                            "nome": "San Lorenzo del Vallo"
                        },
                        {
                            "code": "078122",
                            "cap": "87038",
                            "nome": "San Lucido"
                        },
                        {
                            "code": "078123",
                            "cap": "87018",
                            "nome": "San Marco Argentano"
                        },
                        {
                            "code": "078124",
                            "cap": "87010",
                            "nome": "San Martino di Finita"
                        },
                        {
                            "code": "078125",
                            "cap": "87020",
                            "nome": "San Nicola Arcella"
                        },
                        {
                            "code": "078126",
                            "cap": "87030",
                            "nome": "San Pietro in Amantea"
                        },
                        {
                            "code": "078127",
                            "cap": "87047",
                            "nome": "San Pietro in Guarano"
                        },
                        {
                            "code": "078128",
                            "cap": "87010",
                            "nome": "San Sosti"
                        },
                        {
                            "code": "078135",
                            "cap": "87030",
                            "nome": "San Vincenzo la Costa"
                        },
                        {
                            "code": "078117",
                            "cap": "87020",
                            "nome": "Sangineto"
                        },
                        {
                            "code": "078129",
                            "cap": "87010",
                            "nome": "Santa Caterina Albanese"
                        },
                        {
                            "code": "078130",
                            "cap": "87020",
                            "nome": "Santa Domenica Talao"
                        },
                        {
                            "code": "078132",
                            "cap": "87020",
                            "nome": "Santa Maria del Cedro"
                        },
                        {
                            "code": "078133",
                            "cap": "87048",
                            "nome": "Santa Sofia D'Epiro"
                        },
                        {
                            "code": "078131",
                            "cap": "87010",
                            "nome": "Sant'Agata di Esaro"
                        },
                        {
                            "code": "078134",
                            "cap": "87056",
                            "nome": "Santo Stefano di Rogliano"
                        },
                        {
                            "code": "078136",
                            "cap": "87010",
                            "nome": "Saracena"
                        },
                        {
                            "code": "078137",
                            "cap": "87060",
                            "nome": "Scala Coeli"
                        },
                        {
                            "code": "078138",
                            "cap": "87029",
                            "nome": "Scalea"
                        },
                        {
                            "code": "078139",
                            "cap": "87057",
                            "nome": "Scigliano"
                        },
                        {
                            "code": "078140",
                            "cap": "87030",
                            "nome": "Serra d'Aiello"
                        },
                        {
                            "code": "078141",
                            "cap": "87050",
                            "nome": "Serra Pedace"
                        },
                        {
                            "code": "078142",
                            "cap": "87019",
                            "nome": "Spezzano Albanese"
                        },
                        {
                            "code": "078143",
                            "cap": "87058",
                            "nome": "Spezzano della Sila"
                        },
                        {
                            "code": "078144",
                            "cap": "87050",
                            "nome": "Spezzano Piccolo"
                        },
                        {
                            "code": "078145",
                            "cap": "87040",
                            "nome": "Tarsia"
                        },
                        {
                            "code": "078146",
                            "cap": "87010",
                            "nome": "Terranova da Sibari"
                        },
                        {
                            "code": "078147",
                            "cap": "87060",
                            "nome": "Terravecchia"
                        },
                        {
                            "code": "078148",
                            "cap": "87010",
                            "nome": "Torano Castello"
                        },
                        {
                            "code": "078149",
                            "cap": "87020",
                            "nome": "Tortora"
                        },
                        {
                            "code": "078150",
                            "cap": "87075",
                            "nome": "Trebisacce"
                        },
                        {
                            "code": "078151",
                            "cap": "87050",
                            "nome": "Trenta"
                        },
                        {
                            "code": "078152",
                            "cap": "87060",
                            "nome": "Vaccarizzo Albanese"
                        },
                        {
                            "code": "078153",
                            "cap": "87020",
                            "nome": "Verbicaro"
                        },
                        {
                            "code": "078154",
                            "cap": "87076",
                            "nome": "Villapiana"
                        },
                        {
                            "code": "078155",
                            "cap": "87040",
                            "nome": "Zumpano"
                        }
                    ],
                    "nome": "Cosenza"
                },
                {
                    "code": "KR",
                    "comuni": [
                        {
                            "code": "101001",
                            "cap": "88824",
                            "nome": "Belvedere di Spinello"
                        },
                        {
                            "code": "101002",
                            "cap": "88833",
                            "nome": "Caccuri"
                        },
                        {
                            "code": "101003",
                            "cap": "88817",
                            "nome": "Carfizzi"
                        },
                        {
                            "code": "101004",
                            "cap": "88822",
                            "nome": "Casabona"
                        },
                        {
                            "code": "101005",
                            "cap": "88834",
                            "nome": "Castelsilano"
                        },
                        {
                            "code": "101006",
                            "cap": "88833",
                            "nome": "Cerenzia"
                        },
                        {
                            "code": "101007",
                            "cap": "88813",
                            "nome": "Cir\u00f2"
                        },
                        {
                            "code": "101008",
                            "cap": "88811",
                            "nome": "Cir\u00f2 Marina"
                        },
                        {
                            "code": "101009",
                            "cap": "88836",
                            "nome": "Cotronei"
                        },
                        {
                            "code": "101010",
                            "cap": "88900",
                            "nome": "Crotone"
                        },
                        {
                            "code": "101011",
                            "cap": "88812",
                            "nome": "Crucoli"
                        },
                        {
                            "code": "101012",
                            "cap": "88842",
                            "nome": "Cutro"
                        },
                        {
                            "code": "101013",
                            "cap": "88841",
                            "nome": "Isola di Capo Rizzuto"
                        },
                        {
                            "code": "101014",
                            "cap": "88814",
                            "nome": "Melissa"
                        },
                        {
                            "code": "101015",
                            "cap": "88838",
                            "nome": "Mesoraca"
                        },
                        {
                            "code": "101016",
                            "cap": "88818",
                            "nome": "Pallagorio"
                        },
                        {
                            "code": "101017",
                            "cap": "88837",
                            "nome": "Petilia Policastro"
                        },
                        {
                            "code": "101019",
                            "cap": "88821",
                            "nome": "Rocca di Neto"
                        },
                        {
                            "code": "101018",
                            "cap": "88835",
                            "nome": "Roccabernarda"
                        },
                        {
                            "code": "101020",
                            "cap": "88831",
                            "nome": "San Mauro Marchesato"
                        },
                        {
                            "code": "101021",
                            "cap": "88817",
                            "nome": "San Nicola dell'Alto"
                        },
                        {
                            "code": "101022",
                            "cap": "88832",
                            "nome": "Santa Severina"
                        },
                        {
                            "code": "101023",
                            "cap": "88825",
                            "nome": "Savelli"
                        },
                        {
                            "code": "101024",
                            "cap": "88831",
                            "nome": "Scandale"
                        },
                        {
                            "code": "101025",
                            "cap": "88816",
                            "nome": "Strongoli"
                        },
                        {
                            "code": "101026",
                            "cap": "88823",
                            "nome": "Umbriatico"
                        },
                        {
                            "code": "101027",
                            "cap": "88819",
                            "nome": "Verzino"
                        }
                    ],
                    "nome": "Crotone"
                },
                {
                    "code": "RC",
                    "comuni": [
                        {
                            "code": "080001",
                            "cap": "89030",
                            "nome": "Africo"
                        },
                        {
                            "code": "080002",
                            "cap": "89040",
                            "nome": "Agnana Calabra"
                        },
                        {
                            "code": "080003",
                            "cap": "89020",
                            "nome": "Anoia"
                        },
                        {
                            "code": "080004",
                            "cap": "89040",
                            "nome": "Antonimina"
                        },
                        {
                            "code": "080005",
                            "cap": "89031",
                            "nome": "Ardore"
                        },
                        {
                            "code": "080006",
                            "cap": "89060",
                            "nome": "Bagaladi"
                        },
                        {
                            "code": "080007",
                            "cap": "89011",
                            "nome": "Bagnara Calabra"
                        },
                        {
                            "code": "080008",
                            "cap": "89030",
                            "nome": "Benestare"
                        },
                        {
                            "code": "080009",
                            "cap": "89032",
                            "nome": "Bianco"
                        },
                        {
                            "code": "080010",
                            "cap": "89040",
                            "nome": "Bivongi"
                        },
                        {
                            "code": "080011",
                            "cap": "89033",
                            "nome": "Bova"
                        },
                        {
                            "code": "080013",
                            "cap": "89035",
                            "nome": "Bova Marina"
                        },
                        {
                            "code": "080012",
                            "cap": "89034",
                            "nome": "Bovalino"
                        },
                        {
                            "code": "080014",
                            "cap": "89036",
                            "nome": "Brancaleone"
                        },
                        {
                            "code": "080015",
                            "cap": "89030",
                            "nome": "Bruzzano Zeffirio"
                        },
                        {
                            "code": "080016",
                            "cap": "89050",
                            "nome": "Calanna"
                        },
                        {
                            "code": "080017",
                            "cap": "89040",
                            "nome": "Camini"
                        },
                        {
                            "code": "080018",
                            "cap": "89052",
                            "nome": "Campo Calabro"
                        },
                        {
                            "code": "080019",
                            "cap": "89020",
                            "nome": "Candidoni"
                        },
                        {
                            "code": "080020",
                            "cap": "89040",
                            "nome": "Canolo"
                        },
                        {
                            "code": "080021",
                            "cap": "89030",
                            "nome": "Caraffa del Bianco"
                        },
                        {
                            "code": "080022",
                            "cap": "89060",
                            "nome": "Cardeto"
                        },
                        {
                            "code": "080023",
                            "cap": "89030",
                            "nome": "Careri"
                        },
                        {
                            "code": "080024",
                            "cap": "89030",
                            "nome": "Casignana"
                        },
                        {
                            "code": "080025",
                            "cap": "89041",
                            "nome": "Caulonia"
                        },
                        {
                            "code": "080026",
                            "cap": "89040",
                            "nome": "Cimin\u00e0"
                        },
                        {
                            "code": "080027",
                            "cap": "89021",
                            "nome": "Cinquefrondi"
                        },
                        {
                            "code": "080028",
                            "cap": "89022",
                            "nome": "Cittanova"
                        },
                        {
                            "code": "080029",
                            "cap": "89030",
                            "nome": "Condofuri"
                        },
                        {
                            "code": "080030",
                            "cap": "89050",
                            "nome": "Cosoleto"
                        },
                        {
                            "code": "080031",
                            "cap": "89012",
                            "nome": "Delianuova"
                        },
                        {
                            "code": "080032",
                            "cap": "89050",
                            "nome": "Feroleto della Chiesa"
                        },
                        {
                            "code": "080033",
                            "cap": "89030",
                            "nome": "Ferruzzano"
                        },
                        {
                            "code": "080034",
                            "cap": "89050",
                            "nome": "Fiumara"
                        },
                        {
                            "code": "080035",
                            "cap": "89054",
                            "nome": "Galatro"
                        },
                        {
                            "code": "080036",
                            "cap": "89040",
                            "nome": "Gerace"
                        },
                        {
                            "code": "080037",
                            "cap": "89020",
                            "nome": "Giffone"
                        },
                        {
                            "code": "080038",
                            "cap": "89013",
                            "nome": "Gioia Tauro"
                        },
                        {
                            "code": "080039",
                            "cap": "89042",
                            "nome": "Gioiosa Ionica"
                        },
                        {
                            "code": "080040",
                            "cap": "89043",
                            "nome": "Grotteria"
                        },
                        {
                            "code": "080041",
                            "cap": "89050",
                            "nome": "Laganadi"
                        },
                        {
                            "code": "080042",
                            "cap": "89023",
                            "nome": "Laureana di Borrello"
                        },
                        {
                            "code": "080043",
                            "cap": "89044",
                            "nome": "Locri"
                        },
                        {
                            "code": "080044",
                            "cap": "89045",
                            "nome": "Mammola"
                        },
                        {
                            "code": "080045",
                            "cap": "89046",
                            "nome": "Marina di Gioiosa Ionica"
                        },
                        {
                            "code": "080046",
                            "cap": "89020",
                            "nome": "Maropati"
                        },
                        {
                            "code": "080047",
                            "cap": "89040",
                            "nome": "Martone"
                        },
                        {
                            "code": "080048",
                            "cap": "89020",
                            "nome": "Melicucc\u00e0"
                        },
                        {
                            "code": "080049",
                            "cap": "89020",
                            "nome": "Melicucco"
                        },
                        {
                            "code": "080050",
                            "cap": "89063",
                            "nome": "Melito di Porto Salvo"
                        },
                        {
                            "code": "080051",
                            "cap": "89010",
                            "nome": "Molochio"
                        },
                        {
                            "code": "080052",
                            "cap": "89040",
                            "nome": "Monasterace"
                        },
                        {
                            "code": "080053",
                            "cap": "89064",
                            "nome": "Montebello Ionico"
                        },
                        {
                            "code": "080054",
                            "cap": "89065",
                            "nome": "Motta San Giovanni"
                        },
                        {
                            "code": "080055",
                            "cap": "89014",
                            "nome": "Oppido Mamertina"
                        },
                        {
                            "code": "080056",
                            "cap": "89038",
                            "nome": "Palizzi"
                        },
                        {
                            "code": "080057",
                            "cap": "89015",
                            "nome": "Palmi"
                        },
                        {
                            "code": "080058",
                            "cap": "89040",
                            "nome": "Pazzano"
                        },
                        {
                            "code": "080059",
                            "cap": "89040",
                            "nome": "Placanica"
                        },
                        {
                            "code": "080060",
                            "cap": "89039",
                            "nome": "Plat\u00ec"
                        },
                        {
                            "code": "080061",
                            "cap": "89024",
                            "nome": "Polistena"
                        },
                        {
                            "code": "080062",
                            "cap": "89040",
                            "nome": "Portigliola"
                        },
                        {
                            "code": "080063",
                            "cap": "891xx",
                            "nome": "Reggio Calabria"
                        },
                        {
                            "code": "080064",
                            "cap": "89040",
                            "nome": "Riace"
                        },
                        {
                            "code": "080065",
                            "cap": "89016",
                            "nome": "Rizziconi"
                        },
                        {
                            "code": "080066",
                            "cap": "89060",
                            "nome": "Roccaforte del Greco"
                        },
                        {
                            "code": "080067",
                            "cap": "89047",
                            "nome": "Roccella Ionica"
                        },
                        {
                            "code": "080068",
                            "cap": "89060",
                            "nome": "Roghudi"
                        },
                        {
                            "code": "080069",
                            "cap": "89025",
                            "nome": "Rosarno"
                        },
                        {
                            "code": "080070",
                            "cap": "89030",
                            "nome": "Samo"
                        },
                        {
                            "code": "080097",
                            "cap": "89026",
                            "nome": "San Ferdinando"
                        },
                        {
                            "code": "080071",
                            "cap": "89017",
                            "nome": "San Giorgio Morgeto"
                        },
                        {
                            "code": "080072",
                            "cap": "89040",
                            "nome": "San Giovanni di Gerace"
                        },
                        {
                            "code": "080073",
                            "cap": "89069",
                            "nome": "San Lorenzo"
                        },
                        {
                            "code": "080074",
                            "cap": "89030",
                            "nome": "San Luca"
                        },
                        {
                            "code": "080075",
                            "cap": "89020",
                            "nome": "San Pietro di Carid\u00e0"
                        },
                        {
                            "code": "080076",
                            "cap": "89020",
                            "nome": "San Procopio"
                        },
                        {
                            "code": "080077",
                            "cap": "89050",
                            "nome": "San Roberto"
                        },
                        {
                            "code": "080078",
                            "cap": "89056",
                            "nome": "Santa Cristina d'Aspromonte"
                        },
                        {
                            "code": "080079",
                            "cap": "89030",
                            "nome": "Sant'Agata del Bianco"
                        },
                        {
                            "code": "080080",
                            "cap": "89050",
                            "nome": "Sant'Alessio in Aspromonte"
                        },
                        {
                            "code": "080081",
                            "cap": "89027",
                            "nome": "Sant'Eufemia d'Aspromonte"
                        },
                        {
                            "code": "080082",
                            "cap": "89040",
                            "nome": "Sant'Ilario dello Ionio"
                        },
                        {
                            "code": "080083",
                            "cap": "89057",
                            "nome": "Santo Stefano in Aspromonte"
                        },
                        {
                            "code": "080084",
                            "cap": "89010",
                            "nome": "Scido"
                        },
                        {
                            "code": "080085",
                            "cap": "89058",
                            "nome": "Scilla"
                        },
                        {
                            "code": "080086",
                            "cap": "89028",
                            "nome": "Seminara"
                        },
                        {
                            "code": "080087",
                            "cap": "89020",
                            "nome": "Serrata"
                        },
                        {
                            "code": "080088",
                            "cap": "89048",
                            "nome": "Siderno"
                        },
                        {
                            "code": "080089",
                            "cap": "89020",
                            "nome": "Sinopoli"
                        },
                        {
                            "code": "080090",
                            "cap": "89030",
                            "nome": "Staiti"
                        },
                        {
                            "code": "080091",
                            "cap": "89040",
                            "nome": "Stignano"
                        },
                        {
                            "code": "080092",
                            "cap": "89049",
                            "nome": "Stilo"
                        },
                        {
                            "code": "080093",
                            "cap": "89029",
                            "nome": "Taurianova"
                        },
                        {
                            "code": "080094",
                            "cap": "89010",
                            "nome": "Terranova Sappo Minulio"
                        },
                        {
                            "code": "080095",
                            "cap": "89010",
                            "nome": "Varapodio"
                        },
                        {
                            "code": "080096",
                            "cap": "89018",
                            "nome": "Villa San Giovanni"
                        }
                    ],
                    "nome": "Reggio Calabria"
                },
                {
                    "code": "VV",
                    "comuni": [
                        {
                            "code": "102001",
                            "cap": "89832",
                            "nome": "Acquaro"
                        },
                        {
                            "code": "102002",
                            "cap": "89832",
                            "nome": "Arena"
                        },
                        {
                            "code": "102003",
                            "cap": "89817",
                            "nome": "Briatico"
                        },
                        {
                            "code": "102004",
                            "cap": "89822",
                            "nome": "Brognaturo"
                        },
                        {
                            "code": "102005",
                            "cap": "89818",
                            "nome": "Capistrano"
                        },
                        {
                            "code": "102006",
                            "cap": "89816",
                            "nome": "Cessaniti"
                        },
                        {
                            "code": "102007",
                            "cap": "89832",
                            "nome": "Das\u00e0"
                        },
                        {
                            "code": "102008",
                            "cap": "89833",
                            "nome": "Dinami"
                        },
                        {
                            "code": "102009",
                            "cap": "89862",
                            "nome": "Drapia"
                        },
                        {
                            "code": "102010",
                            "cap": "89823",
                            "nome": "Fabrizia"
                        },
                        {
                            "code": "102011",
                            "cap": "89814",
                            "nome": "Filadelfia"
                        },
                        {
                            "code": "102012",
                            "cap": "89841",
                            "nome": "Filandari"
                        },
                        {
                            "code": "102013",
                            "cap": "89843",
                            "nome": "Filogaso"
                        },
                        {
                            "code": "102014",
                            "cap": "89815",
                            "nome": "Francavilla Angitola"
                        },
                        {
                            "code": "102015",
                            "cap": "89851",
                            "nome": "Francica"
                        },
                        {
                            "code": "102016",
                            "cap": "89831",
                            "nome": "Gerocarne"
                        },
                        {
                            "code": "102017",
                            "cap": "89851",
                            "nome": "Ionadi"
                        },
                        {
                            "code": "102018",
                            "cap": "89863",
                            "nome": "Joppolo"
                        },
                        {
                            "code": "102019",
                            "cap": "89844",
                            "nome": "Limbadi"
                        },
                        {
                            "code": "102020",
                            "cap": "89843",
                            "nome": "Maierato"
                        },
                        {
                            "code": "102021",
                            "cap": "89852",
                            "nome": "Mileto"
                        },
                        {
                            "code": "102022",
                            "cap": "89823",
                            "nome": "Mongiana"
                        },
                        {
                            "code": "102023",
                            "cap": "89819",
                            "nome": "Monterosso Calabro"
                        },
                        {
                            "code": "102024",
                            "cap": "89824",
                            "nome": "Nardodipace"
                        },
                        {
                            "code": "102025",
                            "cap": "89844",
                            "nome": "Nicotera"
                        },
                        {
                            "code": "102026",
                            "cap": "89861",
                            "nome": "Parghelia"
                        },
                        {
                            "code": "102027",
                            "cap": "89812",
                            "nome": "Pizzo"
                        },
                        {
                            "code": "102028",
                            "cap": "89834",
                            "nome": "Pizzoni"
                        },
                        {
                            "code": "102029",
                            "cap": "89813",
                            "nome": "Polia"
                        },
                        {
                            "code": "102030",
                            "cap": "89866",
                            "nome": "Ricadi"
                        },
                        {
                            "code": "102031",
                            "cap": "89841",
                            "nome": "Rombiolo"
                        },
                        {
                            "code": "102032",
                            "cap": "89842",
                            "nome": "San Calogero"
                        },
                        {
                            "code": "102033",
                            "cap": "89851",
                            "nome": "San Costantino Calabro"
                        },
                        {
                            "code": "102034",
                            "cap": "89853",
                            "nome": "San Gregorio d'Ippona"
                        },
                        {
                            "code": "102035",
                            "cap": "89821",
                            "nome": "San Nicola da Crissa"
                        },
                        {
                            "code": "102036",
                            "cap": "89843",
                            "nome": "Sant'Onofrio"
                        },
                        {
                            "code": "102037",
                            "cap": "89822",
                            "nome": "Serra San Bruno"
                        },
                        {
                            "code": "102038",
                            "cap": "89822",
                            "nome": "Simbario"
                        },
                        {
                            "code": "102039",
                            "cap": "89831",
                            "nome": "Sorianello"
                        },
                        {
                            "code": "102040",
                            "cap": "89831",
                            "nome": "Soriano Calabro"
                        },
                        {
                            "code": "102041",
                            "cap": "89822",
                            "nome": "Spadola"
                        },
                        {
                            "code": "102042",
                            "cap": "89864",
                            "nome": "Spilinga"
                        },
                        {
                            "code": "102043",
                            "cap": "89843",
                            "nome": "Stefanaconi"
                        },
                        {
                            "code": "102044",
                            "cap": "89861",
                            "nome": "Tropea"
                        },
                        {
                            "code": "102045",
                            "cap": "89821",
                            "nome": "Vallelonga"
                        },
                        {
                            "code": "102046",
                            "cap": "89834",
                            "nome": "Vazzano"
                        },
                        {
                            "code": "102047",
                            "cap": "89900",
                            "nome": "Vibo Valentia"
                        },
                        {
                            "code": "102048",
                            "cap": "89867",
                            "nome": "Zaccanopoli"
                        },
                        {
                            "code": "102049",
                            "cap": "89868",
                            "nome": "Zambrone"
                        },
                        {
                            "code": "102050",
                            "cap": "89867",
                            "nome": "Zungri"
                        }
                    ],
                    "nome": "Vibo Valentia"
                }
            ],
            "nome": "Calabria"
        },
        {
            "province": [
                {
                    "code": "AV",
                    "comuni": [
                        {
                            "code": "064001",
                            "cap": "83020",
                            "nome": "Aiello del Sabato"
                        },
                        {
                            "code": "064002",
                            "cap": "83011",
                            "nome": "Altavilla Irpina"
                        },
                        {
                            "code": "064003",
                            "cap": "83040",
                            "nome": "Andretta"
                        },
                        {
                            "code": "064004",
                            "cap": "83041",
                            "nome": "Aquilonia"
                        },
                        {
                            "code": "064005",
                            "cap": "83031",
                            "nome": "Ariano Irpino"
                        },
                        {
                            "code": "064006",
                            "cap": "83042",
                            "nome": "Atripalda"
                        },
                        {
                            "code": "064007",
                            "cap": "83021",
                            "nome": "Avella"
                        },
                        {
                            "code": "064008",
                            "cap": "83100",
                            "nome": "Avellino"
                        },
                        {
                            "code": "064009",
                            "cap": "83043",
                            "nome": "Bagnoli Irpino"
                        },
                        {
                            "code": "064010",
                            "cap": "83022",
                            "nome": "Baiano"
                        },
                        {
                            "code": "064011",
                            "cap": "83044",
                            "nome": "Bisaccia"
                        },
                        {
                            "code": "064012",
                            "cap": "83032",
                            "nome": "Bonito"
                        },
                        {
                            "code": "064013",
                            "cap": "83040",
                            "nome": "Cairano"
                        },
                        {
                            "code": "064014",
                            "cap": "83040",
                            "nome": "Calabritto"
                        },
                        {
                            "code": "064015",
                            "cap": "83045",
                            "nome": "Calitri"
                        },
                        {
                            "code": "064016",
                            "cap": "83040",
                            "nome": "Candida"
                        },
                        {
                            "code": "064017",
                            "cap": "83040",
                            "nome": "Caposele"
                        },
                        {
                            "code": "064018",
                            "cap": "83010",
                            "nome": "Capriglia Irpina"
                        },
                        {
                            "code": "064019",
                            "cap": "83040",
                            "nome": "Carife"
                        },
                        {
                            "code": "064020",
                            "cap": "83034",
                            "nome": "Casalbore"
                        },
                        {
                            "code": "064021",
                            "cap": "83040",
                            "nome": "Cassano Irpino"
                        },
                        {
                            "code": "064022",
                            "cap": "83040",
                            "nome": "Castel Baronia"
                        },
                        {
                            "code": "064023",
                            "cap": "83040",
                            "nome": "Castelfranci"
                        },
                        {
                            "code": "064024",
                            "cap": "83040",
                            "nome": "Castelvetere sul Calore"
                        },
                        {
                            "code": "064025",
                            "cap": "83012",
                            "nome": "Cervinara"
                        },
                        {
                            "code": "064026",
                            "cap": "83020",
                            "nome": "Cesinali"
                        },
                        {
                            "code": "064027",
                            "cap": "83010",
                            "nome": "Chianche"
                        },
                        {
                            "code": "064028",
                            "cap": "83040",
                            "nome": "Chiusano di San Domenico"
                        },
                        {
                            "code": "064029",
                            "cap": "83020",
                            "nome": "Contrada"
                        },
                        {
                            "code": "064030",
                            "cap": "83040",
                            "nome": "Conza della Campania"
                        },
                        {
                            "code": "064031",
                            "cap": "83020",
                            "nome": "Domicella"
                        },
                        {
                            "code": "064032",
                            "cap": "83040",
                            "nome": "Flumeri"
                        },
                        {
                            "code": "064033",
                            "cap": "83040",
                            "nome": "Fontanarosa"
                        },
                        {
                            "code": "064034",
                            "cap": "83020",
                            "nome": "Forino"
                        },
                        {
                            "code": "064035",
                            "cap": "83040",
                            "nome": "Frigento"
                        },
                        {
                            "code": "064036",
                            "cap": "83040",
                            "nome": "Gesualdo"
                        },
                        {
                            "code": "064037",
                            "cap": "83030",
                            "nome": "Greci"
                        },
                        {
                            "code": "064038",
                            "cap": "83035",
                            "nome": "Grottaminarda"
                        },
                        {
                            "code": "064039",
                            "cap": "83010",
                            "nome": "Grottolella"
                        },
                        {
                            "code": "064040",
                            "cap": "83040",
                            "nome": "Guardia Lombardi"
                        },
                        {
                            "code": "064041",
                            "cap": "83046",
                            "nome": "Lacedonia"
                        },
                        {
                            "code": "064042",
                            "cap": "83030",
                            "nome": "Lapio"
                        },
                        {
                            "code": "064043",
                            "cap": "83023",
                            "nome": "Lauro"
                        },
                        {
                            "code": "064044",
                            "cap": "83047",
                            "nome": "Lioni"
                        },
                        {
                            "code": "064045",
                            "cap": "83040",
                            "nome": "Luogosano"
                        },
                        {
                            "code": "064046",
                            "cap": "83030",
                            "nome": "Manocalzati"
                        },
                        {
                            "code": "064047",
                            "cap": "83020",
                            "nome": "Marzano di Nola"
                        },
                        {
                            "code": "064048",
                            "cap": "83030",
                            "nome": "Melito Irpino"
                        },
                        {
                            "code": "064049",
                            "cap": "83013",
                            "nome": "Mercogliano"
                        },
                        {
                            "code": "064050",
                            "cap": "83036",
                            "nome": "Mirabella Eclano"
                        },
                        {
                            "code": "064051",
                            "cap": "83030",
                            "nome": "Montaguto"
                        },
                        {
                            "code": "064052",
                            "cap": "83037",
                            "nome": "Montecalvo Irpino"
                        },
                        {
                            "code": "064053",
                            "cap": "83030",
                            "nome": "Montefalcione"
                        },
                        {
                            "code": "064054",
                            "cap": "83024",
                            "nome": "Monteforte Irpino"
                        },
                        {
                            "code": "064055",
                            "cap": "83030",
                            "nome": "Montefredane"
                        },
                        {
                            "code": "064056",
                            "cap": "83030",
                            "nome": "Montefusco"
                        },
                        {
                            "code": "064057",
                            "cap": "83048",
                            "nome": "Montella"
                        },
                        {
                            "code": "064058",
                            "cap": "83040",
                            "nome": "Montemarano"
                        },
                        {
                            "code": "064059",
                            "cap": "83038",
                            "nome": "Montemiletto"
                        },
                        {
                            "code": "064060",
                            "cap": "83049",
                            "nome": "Monteverde"
                        },
                        {
                            "code": "064061",
                            "cap": "83025",
                            "nome": "Montoro Inferiore"
                        },
                        {
                            "code": "064062",
                            "cap": "83026",
                            "nome": "Montoro Superiore"
                        },
                        {
                            "code": "064063",
                            "cap": "83040",
                            "nome": "Morra De Sanctis"
                        },
                        {
                            "code": "064064",
                            "cap": "83020",
                            "nome": "Moschiano"
                        },
                        {
                            "code": "064065",
                            "cap": "83027",
                            "nome": "Mugnano del Cardinale"
                        },
                        {
                            "code": "064066",
                            "cap": "83051",
                            "nome": "Nusco"
                        },
                        {
                            "code": "064067",
                            "cap": "83014",
                            "nome": "Ospedaletto d'Alpinolo"
                        },
                        {
                            "code": "064068",
                            "cap": "83020",
                            "nome": "Pago del Vallo di Lauro"
                        },
                        {
                            "code": "064069",
                            "cap": "83050",
                            "nome": "Parolise"
                        },
                        {
                            "code": "064070",
                            "cap": "83052",
                            "nome": "Paternopoli"
                        },
                        {
                            "code": "064071",
                            "cap": "83010",
                            "nome": "Petruro Irpino"
                        },
                        {
                            "code": "064072",
                            "cap": "83030",
                            "nome": "Pietradefusi"
                        },
                        {
                            "code": "064073",
                            "cap": "83015",
                            "nome": "Pietrastornina"
                        },
                        {
                            "code": "064074",
                            "cap": "83030",
                            "nome": "Prata di Principato Ultra"
                        },
                        {
                            "code": "064075",
                            "cap": "83039",
                            "nome": "Pratola Serra"
                        },
                        {
                            "code": "064076",
                            "cap": "83020",
                            "nome": "Quadrelle"
                        },
                        {
                            "code": "064077",
                            "cap": "83020",
                            "nome": "Quindici"
                        },
                        {
                            "code": "064079",
                            "cap": "83050",
                            "nome": "Rocca San Felice"
                        },
                        {
                            "code": "064078",
                            "cap": "83016",
                            "nome": "Roccabascerana"
                        },
                        {
                            "code": "064080",
                            "cap": "83017",
                            "nome": "Rotondi"
                        },
                        {
                            "code": "064081",
                            "cap": "83050",
                            "nome": "Salza Irpina"
                        },
                        {
                            "code": "064082",
                            "cap": "83050",
                            "nome": "San Mango sul Calore"
                        },
                        {
                            "code": "064083",
                            "cap": "83018",
                            "nome": "San Martino Valle Caudina"
                        },
                        {
                            "code": "064084",
                            "cap": "83020",
                            "nome": "San Michele di Serino"
                        },
                        {
                            "code": "064085",
                            "cap": "83050",
                            "nome": "San Nicola Baronia"
                        },
                        {
                            "code": "064086",
                            "cap": "83050",
                            "nome": "San Potito Ultra"
                        },
                        {
                            "code": "064087",
                            "cap": "83050",
                            "nome": "San Sossio Baronia"
                        },
                        {
                            "code": "064088",
                            "cap": "83020",
                            "nome": "Santa Lucia di Serino"
                        },
                        {
                            "code": "064093",
                            "cap": "83030",
                            "nome": "Santa Paolina"
                        },
                        {
                            "code": "064089",
                            "cap": "83053",
                            "nome": "Sant'Andrea di Conza"
                        },
                        {
                            "code": "064091",
                            "cap": "83010",
                            "nome": "Sant'Angelo a Scala"
                        },
                        {
                            "code": "064090",
                            "cap": "83050",
                            "nome": "Sant'Angelo all'Esca"
                        },
                        {
                            "code": "064092",
                            "cap": "83054",
                            "nome": "Sant'Angelo dei Lombardi"
                        },
                        {
                            "code": "064095",
                            "cap": "83050",
                            "nome": "Santo Stefano del Sole"
                        },
                        {
                            "code": "064096",
                            "cap": "83030",
                            "nome": "Savignano Irpino"
                        },
                        {
                            "code": "064097",
                            "cap": "83050",
                            "nome": "Scampitella"
                        },
                        {
                            "code": "064098",
                            "cap": "83050",
                            "nome": "Senerchia"
                        },
                        {
                            "code": "064099",
                            "cap": "83028",
                            "nome": "Serino"
                        },
                        {
                            "code": "064100",
                            "cap": "83020",
                            "nome": "Sirignano"
                        },
                        {
                            "code": "064101",
                            "cap": "83029",
                            "nome": "Solofra"
                        },
                        {
                            "code": "064102",
                            "cap": "83050",
                            "nome": "Sorbo Serpico"
                        },
                        {
                            "code": "064103",
                            "cap": "83020",
                            "nome": "Sperone"
                        },
                        {
                            "code": "064104",
                            "cap": "83055",
                            "nome": "Sturno"
                        },
                        {
                            "code": "064105",
                            "cap": "83010",
                            "nome": "Summonte"
                        },
                        {
                            "code": "064106",
                            "cap": "83020",
                            "nome": "Taurano"
                        },
                        {
                            "code": "064107",
                            "cap": "83030",
                            "nome": "Taurasi"
                        },
                        {
                            "code": "064108",
                            "cap": "83056",
                            "nome": "Teora"
                        },
                        {
                            "code": "064109",
                            "cap": "83057",
                            "nome": "Torella dei Lombardi"
                        },
                        {
                            "code": "064110",
                            "cap": "83030",
                            "nome": "Torre Le Nocelle"
                        },
                        {
                            "code": "064111",
                            "cap": "83010",
                            "nome": "Torrioni"
                        },
                        {
                            "code": "064112",
                            "cap": "83058",
                            "nome": "Trevico"
                        },
                        {
                            "code": "064113",
                            "cap": "83010",
                            "nome": "Tufo"
                        },
                        {
                            "code": "064114",
                            "cap": "83059",
                            "nome": "Vallata"
                        },
                        {
                            "code": "064115",
                            "cap": "83050",
                            "nome": "Vallesaccarda"
                        },
                        {
                            "code": "064116",
                            "cap": "83030",
                            "nome": "Venticano"
                        },
                        {
                            "code": "064117",
                            "cap": "83050",
                            "nome": "Villamaina"
                        },
                        {
                            "code": "064118",
                            "cap": "83030",
                            "nome": "Villanova del Battista"
                        },
                        {
                            "code": "064119",
                            "cap": "83050",
                            "nome": "Volturara Irpina"
                        },
                        {
                            "code": "064120",
                            "cap": "83030",
                            "nome": "Zungoli"
                        }
                    ],
                    "nome": "Avellino"
                },
                {
                    "code": "BN",
                    "comuni": [
                        {
                            "code": "062001",
                            "cap": "82011",
                            "nome": "Airola"
                        },
                        {
                            "code": "062002",
                            "cap": "82031",
                            "nome": "Amorosi"
                        },
                        {
                            "code": "062003",
                            "cap": "82021",
                            "nome": "Apice"
                        },
                        {
                            "code": "062004",
                            "cap": "82030",
                            "nome": "Apollosa"
                        },
                        {
                            "code": "062005",
                            "cap": "82011",
                            "nome": "Arpaia"
                        },
                        {
                            "code": "062006",
                            "cap": "82010",
                            "nome": "Arpaise"
                        },
                        {
                            "code": "062007",
                            "cap": "82020",
                            "nome": "Baselice"
                        },
                        {
                            "code": "062008",
                            "cap": "82100",
                            "nome": "Benevento"
                        },
                        {
                            "code": "062009",
                            "cap": "82013",
                            "nome": "Bonea"
                        },
                        {
                            "code": "062010",
                            "cap": "82010",
                            "nome": "Bucciano"
                        },
                        {
                            "code": "062011",
                            "cap": "82020",
                            "nome": "Buonalbergo"
                        },
                        {
                            "code": "062012",
                            "cap": "82018",
                            "nome": "Calvi"
                        },
                        {
                            "code": "062013",
                            "cap": "82020",
                            "nome": "Campolattaro"
                        },
                        {
                            "code": "062014",
                            "cap": "82030",
                            "nome": "Campoli del Monte Taburno"
                        },
                        {
                            "code": "062015",
                            "cap": "82027",
                            "nome": "Casalduni"
                        },
                        {
                            "code": "062016",
                            "cap": "82022",
                            "nome": "Castelfranco in Miscano"
                        },
                        {
                            "code": "062017",
                            "cap": "82024",
                            "nome": "Castelpagano"
                        },
                        {
                            "code": "062018",
                            "cap": "82030",
                            "nome": "Castelpoto"
                        },
                        {
                            "code": "062019",
                            "cap": "82037",
                            "nome": "Castelvenere"
                        },
                        {
                            "code": "062020",
                            "cap": "82023",
                            "nome": "Castelvetere in Val Fortore"
                        },
                        {
                            "code": "062021",
                            "cap": "82030",
                            "nome": "Cautano"
                        },
                        {
                            "code": "062022",
                            "cap": "82010",
                            "nome": "Ceppaloni"
                        },
                        {
                            "code": "062023",
                            "cap": "82032",
                            "nome": "Cerreto Sannita"
                        },
                        {
                            "code": "062024",
                            "cap": "82020",
                            "nome": "Circello"
                        },
                        {
                            "code": "062025",
                            "cap": "82024",
                            "nome": "Colle Sannita"
                        },
                        {
                            "code": "062026",
                            "cap": "82033",
                            "nome": "Cusano Mutri"
                        },
                        {
                            "code": "062027",
                            "cap": "82030",
                            "nome": "Dugenta"
                        },
                        {
                            "code": "062028",
                            "cap": "82015",
                            "nome": "Durazzano"
                        },
                        {
                            "code": "062029",
                            "cap": "82030",
                            "nome": "Faicchio"
                        },
                        {
                            "code": "062030",
                            "cap": "82030",
                            "nome": "Foglianise"
                        },
                        {
                            "code": "062031",
                            "cap": "82020",
                            "nome": "Foiano di Val Fortore"
                        },
                        {
                            "code": "062032",
                            "cap": "82011",
                            "nome": "Forchia"
                        },
                        {
                            "code": "062033",
                            "cap": "82020",
                            "nome": "Fragneto L'Abate"
                        },
                        {
                            "code": "062034",
                            "cap": "82020",
                            "nome": "Fragneto Monforte"
                        },
                        {
                            "code": "062035",
                            "cap": "82030",
                            "nome": "Frasso Telesino"
                        },
                        {
                            "code": "062036",
                            "cap": "82020",
                            "nome": "Ginestra degli Schiavoni"
                        },
                        {
                            "code": "062037",
                            "cap": "82034",
                            "nome": "Guardia Sanframondi"
                        },
                        {
                            "code": "062038",
                            "cap": "82030",
                            "nome": "Limatola"
                        },
                        {
                            "code": "062039",
                            "cap": "82030",
                            "nome": "Melizzano"
                        },
                        {
                            "code": "062040",
                            "cap": "82010",
                            "nome": "Moiano"
                        },
                        {
                            "code": "062041",
                            "cap": "82020",
                            "nome": "Molinara"
                        },
                        {
                            "code": "062042",
                            "cap": "82025",
                            "nome": "Montefalcone di Val Fortore"
                        },
                        {
                            "code": "062043",
                            "cap": "82016",
                            "nome": "Montesarchio"
                        },
                        {
                            "code": "062044",
                            "cap": "82026",
                            "nome": "Morcone"
                        },
                        {
                            "code": "062045",
                            "cap": "82020",
                            "nome": "Paduli"
                        },
                        {
                            "code": "062046",
                            "cap": "82020",
                            "nome": "Pago Veiano"
                        },
                        {
                            "code": "062047",
                            "cap": "82017",
                            "nome": "Pannarano"
                        },
                        {
                            "code": "062048",
                            "cap": "82011",
                            "nome": "Paolisi"
                        },
                        {
                            "code": "062049",
                            "cap": "82030",
                            "nome": "Paupisi"
                        },
                        {
                            "code": "062050",
                            "cap": "82020",
                            "nome": "Pesco Sannita"
                        },
                        {
                            "code": "062051",
                            "cap": "82030",
                            "nome": "Pietraroja"
                        },
                        {
                            "code": "062052",
                            "cap": "82020",
                            "nome": "Pietrelcina"
                        },
                        {
                            "code": "062053",
                            "cap": "82030",
                            "nome": "Ponte"
                        },
                        {
                            "code": "062054",
                            "cap": "82027",
                            "nome": "Pontelandolfo"
                        },
                        {
                            "code": "062055",
                            "cap": "82030",
                            "nome": "Puglianello"
                        },
                        {
                            "code": "062056",
                            "cap": "82020",
                            "nome": "Reino"
                        },
                        {
                            "code": "062057",
                            "cap": "82028",
                            "nome": "San Bartolomeo in Galdo"
                        },
                        {
                            "code": "062058",
                            "cap": "82018",
                            "nome": "San Giorgio del Sannio"
                        },
                        {
                            "code": "062059",
                            "cap": "82020",
                            "nome": "San Giorgio La Molara"
                        },
                        {
                            "code": "062060",
                            "cap": "82010",
                            "nome": "San Leucio del Sannio"
                        },
                        {
                            "code": "062061",
                            "cap": "82030",
                            "nome": "San Lorenzello"
                        },
                        {
                            "code": "062062",
                            "cap": "82034",
                            "nome": "San Lorenzo Maggiore"
                        },
                        {
                            "code": "062063",
                            "cap": "82034",
                            "nome": "San Lupo"
                        },
                        {
                            "code": "062064",
                            "cap": "82029",
                            "nome": "San Marco dei Cavoti"
                        },
                        {
                            "code": "062065",
                            "cap": "82010",
                            "nome": "San Martino Sannita"
                        },
                        {
                            "code": "062066",
                            "cap": "82018",
                            "nome": "San Nazzaro"
                        },
                        {
                            "code": "062067",
                            "cap": "82010",
                            "nome": "San Nicola Manfredi"
                        },
                        {
                            "code": "062068",
                            "cap": "82030",
                            "nome": "San Salvatore Telesino"
                        },
                        {
                            "code": "062069",
                            "cap": "82020",
                            "nome": "Santa Croce del Sannio"
                        },
                        {
                            "code": "062070",
                            "cap": "82019",
                            "nome": "Sant'Agata De' Goti"
                        },
                        {
                            "code": "062071",
                            "cap": "82010",
                            "nome": "Sant'Angelo a Cupolo"
                        },
                        {
                            "code": "062078",
                            "cap": "82021",
                            "nome": "Sant'Arcangelo Trimonte"
                        },
                        {
                            "code": "062072",
                            "cap": "82026",
                            "nome": "Sassinoro"
                        },
                        {
                            "code": "062073",
                            "cap": "82036",
                            "nome": "Solopaca"
                        },
                        {
                            "code": "062074",
                            "cap": "82037",
                            "nome": "Telese Terme"
                        },
                        {
                            "code": "062075",
                            "cap": "82030",
                            "nome": "Tocco Caudio"
                        },
                        {
                            "code": "062076",
                            "cap": "82030",
                            "nome": "Torrecuso"
                        },
                        {
                            "code": "062077",
                            "cap": "82038",
                            "nome": "Vitulano"
                        }
                    ],
                    "nome": "Benevento"
                },
                {
                    "code": "CE",
                    "comuni": [
                        {
                            "code": "061001",
                            "cap": "81010",
                            "nome": "Ailano"
                        },
                        {
                            "code": "061002",
                            "cap": "81011",
                            "nome": "Alife"
                        },
                        {
                            "code": "061003",
                            "cap": "81012",
                            "nome": "Alvignano"
                        },
                        {
                            "code": "061004",
                            "cap": "81021",
                            "nome": "Arienzo"
                        },
                        {
                            "code": "061005",
                            "cap": "81031",
                            "nome": "Aversa"
                        },
                        {
                            "code": "061006",
                            "cap": "81010",
                            "nome": "Baia e Latina"
                        },
                        {
                            "code": "061007",
                            "cap": "81041",
                            "nome": "Bellona"
                        },
                        {
                            "code": "061008",
                            "cap": "81059",
                            "nome": "Caianello"
                        },
                        {
                            "code": "061009",
                            "cap": "81013",
                            "nome": "Caiazzo"
                        },
                        {
                            "code": "061010",
                            "cap": "81042",
                            "nome": "Calvi Risorta"
                        },
                        {
                            "code": "061011",
                            "cap": "81050",
                            "nome": "Camigliano"
                        },
                        {
                            "code": "061012",
                            "cap": "81030",
                            "nome": "Cancello ed Arnone"
                        },
                        {
                            "code": "061013",
                            "cap": "81020",
                            "nome": "Capodrise"
                        },
                        {
                            "code": "061014",
                            "cap": "81014",
                            "nome": "Capriati a Volturno"
                        },
                        {
                            "code": "061015",
                            "cap": "81043",
                            "nome": "Capua"
                        },
                        {
                            "code": "061016",
                            "cap": "81032",
                            "nome": "Carinaro"
                        },
                        {
                            "code": "061017",
                            "cap": "81030",
                            "nome": "Carinola"
                        },
                        {
                            "code": "061018",
                            "cap": "81022",
                            "nome": "Casagiove"
                        },
                        {
                            "code": "061019",
                            "cap": "81033",
                            "nome": "Casal di Principe"
                        },
                        {
                            "code": "061020",
                            "cap": "81030",
                            "nome": "Casaluce"
                        },
                        {
                            "code": "061103",
                            "cap": "81030",
                            "nome": "Casapesenna"
                        },
                        {
                            "code": "061021",
                            "cap": "81020",
                            "nome": "Casapulla"
                        },
                        {
                            "code": "061022",
                            "cap": "81100",
                            "nome": "Caserta"
                        },
                        {
                            "code": "061023",
                            "cap": "81010",
                            "nome": "Castel Campagnano"
                        },
                        {
                            "code": "061024",
                            "cap": "81040",
                            "nome": "Castel di Sasso"
                        },
                        {
                            "code": "061026",
                            "cap": "81020",
                            "nome": "Castel Morrone"
                        },
                        {
                            "code": "061027",
                            "cap": "81030",
                            "nome": "Castel Volturno"
                        },
                        {
                            "code": "061025",
                            "cap": "81016",
                            "nome": "Castello del Matese"
                        },
                        {
                            "code": "061102",
                            "cap": "81030",
                            "nome": "Cellole"
                        },
                        {
                            "code": "061028",
                            "cap": "81023",
                            "nome": "Cervino"
                        },
                        {
                            "code": "061029",
                            "cap": "81030",
                            "nome": "Cesa"
                        },
                        {
                            "code": "061030",
                            "cap": "81010",
                            "nome": "Ciorlano"
                        },
                        {
                            "code": "061031",
                            "cap": "81044",
                            "nome": "Conca della Campania"
                        },
                        {
                            "code": "061032",
                            "cap": "81040",
                            "nome": "Curti"
                        },
                        {
                            "code": "061033",
                            "cap": "81010",
                            "nome": "Dragoni"
                        },
                        {
                            "code": "061101",
                            "cap": "81030",
                            "nome": "Falciano del Massico"
                        },
                        {
                            "code": "061034",
                            "cap": "81014",
                            "nome": "Fontegreca"
                        },
                        {
                            "code": "061035",
                            "cap": "81040",
                            "nome": "Formicola"
                        },
                        {
                            "code": "061036",
                            "cap": "81050",
                            "nome": "Francolise"
                        },
                        {
                            "code": "061037",
                            "cap": "81030",
                            "nome": "Frignano"
                        },
                        {
                            "code": "061038",
                            "cap": "81010",
                            "nome": "Gallo Matese"
                        },
                        {
                            "code": "061039",
                            "cap": "81044",
                            "nome": "Galluccio"
                        },
                        {
                            "code": "061040",
                            "cap": "81042",
                            "nome": "Giano Vetusto"
                        },
                        {
                            "code": "061041",
                            "cap": "81010",
                            "nome": "Gioia Sannitica"
                        },
                        {
                            "code": "061042",
                            "cap": "81046",
                            "nome": "Grazzanise"
                        },
                        {
                            "code": "061043",
                            "cap": "81030",
                            "nome": "Gricignano di Aversa"
                        },
                        {
                            "code": "061044",
                            "cap": "81010",
                            "nome": "Letino"
                        },
                        {
                            "code": "061045",
                            "cap": "81040",
                            "nome": "Liberi"
                        },
                        {
                            "code": "061046",
                            "cap": "81030",
                            "nome": "Lusciano"
                        },
                        {
                            "code": "061047",
                            "cap": "81047",
                            "nome": "Macerata Campania"
                        },
                        {
                            "code": "061048",
                            "cap": "81024",
                            "nome": "Maddaloni"
                        },
                        {
                            "code": "061049",
                            "cap": "81025",
                            "nome": "Marcianise"
                        },
                        {
                            "code": "061050",
                            "cap": "81035",
                            "nome": "Marzano Appio"
                        },
                        {
                            "code": "061051",
                            "cap": "81049",
                            "nome": "Mignano Monte Lungo"
                        },
                        {
                            "code": "061052",
                            "cap": "81034",
                            "nome": "Mondragone"
                        },
                        {
                            "code": "061053",
                            "cap": "81030",
                            "nome": "Orta di Atella"
                        },
                        {
                            "code": "061054",
                            "cap": "81030",
                            "nome": "Parete"
                        },
                        {
                            "code": "061055",
                            "cap": "81050",
                            "nome": "Pastorano"
                        },
                        {
                            "code": "061056",
                            "cap": "81013",
                            "nome": "Piana di Monte Verna"
                        },
                        {
                            "code": "061057",
                            "cap": "81016",
                            "nome": "Piedimonte Matese"
                        },
                        {
                            "code": "061058",
                            "cap": "81051",
                            "nome": "Pietramelara"
                        },
                        {
                            "code": "061059",
                            "cap": "81040",
                            "nome": "Pietravairano"
                        },
                        {
                            "code": "061060",
                            "cap": "81052",
                            "nome": "Pignataro Maggiore"
                        },
                        {
                            "code": "061061",
                            "cap": "81040",
                            "nome": "Pontelatone"
                        },
                        {
                            "code": "061062",
                            "cap": "81050",
                            "nome": "Portico di Caserta"
                        },
                        {
                            "code": "061063",
                            "cap": "81010",
                            "nome": "Prata Sannita"
                        },
                        {
                            "code": "061064",
                            "cap": "81010",
                            "nome": "Pratella"
                        },
                        {
                            "code": "061065",
                            "cap": "81050",
                            "nome": "Presenzano"
                        },
                        {
                            "code": "061066",
                            "cap": "81017",
                            "nome": "Raviscanina"
                        },
                        {
                            "code": "061067",
                            "cap": "81020",
                            "nome": "Recale"
                        },
                        {
                            "code": "061068",
                            "cap": "81053",
                            "nome": "Riardo"
                        },
                        {
                            "code": "061069",
                            "cap": "81040",
                            "nome": "Rocca D'Evandro"
                        },
                        {
                            "code": "061070",
                            "cap": "81035",
                            "nome": "Roccamonfina"
                        },
                        {
                            "code": "061071",
                            "cap": "81051",
                            "nome": "Roccaromana"
                        },
                        {
                            "code": "061072",
                            "cap": "81042",
                            "nome": "Rocchetta e Croce"
                        },
                        {
                            "code": "061073",
                            "cap": "81010",
                            "nome": "Ruviano"
                        },
                        {
                            "code": "061074",
                            "cap": "81036",
                            "nome": "San Cipriano d'Aversa"
                        },
                        {
                            "code": "061075",
                            "cap": "81027",
                            "nome": "San Felice a Cancello"
                        },
                        {
                            "code": "061076",
                            "cap": "81010",
                            "nome": "San Gregorio Matese"
                        },
                        {
                            "code": "061077",
                            "cap": "81030",
                            "nome": "San Marcellino"
                        },
                        {
                            "code": "061104",
                            "cap": "81020",
                            "nome": "San Marco Evangelista"
                        },
                        {
                            "code": "061078",
                            "cap": "81020",
                            "nome": "San Nicola la Strada"
                        },
                        {
                            "code": "061079",
                            "cap": "81049",
                            "nome": "San Pietro Infine"
                        },
                        {
                            "code": "061080",
                            "cap": "81016",
                            "nome": "San Potito Sannitico"
                        },
                        {
                            "code": "061081",
                            "cap": "81054",
                            "nome": "San Prisco"
                        },
                        {
                            "code": "061085",
                            "cap": "81050",
                            "nome": "San Tammaro"
                        },
                        {
                            "code": "061082",
                            "cap": "81028",
                            "nome": "Santa Maria a Vico"
                        },
                        {
                            "code": "061083",
                            "cap": "81055",
                            "nome": "Santa Maria Capua Vetere"
                        },
                        {
                            "code": "061084",
                            "cap": "81050",
                            "nome": "Santa Maria La Fossa"
                        },
                        {
                            "code": "061086",
                            "cap": "81017",
                            "nome": "Sant'Angelo d'Alife"
                        },
                        {
                            "code": "061087",
                            "cap": "81030",
                            "nome": "Sant'Arpino"
                        },
                        {
                            "code": "061088",
                            "cap": "81037",
                            "nome": "Sessa Aurunca"
                        },
                        {
                            "code": "061089",
                            "cap": "81056",
                            "nome": "Sparanise"
                        },
                        {
                            "code": "061090",
                            "cap": "81030",
                            "nome": "Succivo"
                        },
                        {
                            "code": "061091",
                            "cap": "81057",
                            "nome": "Teano"
                        },
                        {
                            "code": "061092",
                            "cap": "81030",
                            "nome": "Teverola"
                        },
                        {
                            "code": "061093",
                            "cap": "81044",
                            "nome": "Tora e Piccilli"
                        },
                        {
                            "code": "061094",
                            "cap": "81038",
                            "nome": "Trentola-Ducenta"
                        },
                        {
                            "code": "061095",
                            "cap": "81058",
                            "nome": "Vairano Patenora"
                        },
                        {
                            "code": "061096",
                            "cap": "81010",
                            "nome": "Valle Agricola"
                        },
                        {
                            "code": "061097",
                            "cap": "81020",
                            "nome": "Valle di Maddaloni"
                        },
                        {
                            "code": "061098",
                            "cap": "81030",
                            "nome": "Villa di Briano"
                        },
                        {
                            "code": "061099",
                            "cap": "81039",
                            "nome": "Villa Literno"
                        },
                        {
                            "code": "061100",
                            "cap": "81041",
                            "nome": "Vitulazio"
                        }
                    ],
                    "nome": "Caserta"
                },
                {
                    "code": "NA",
                    "comuni": [
                        {
                            "code": "063001",
                            "cap": "80011",
                            "nome": "Acerra"
                        },
                        {
                            "code": "063002",
                            "cap": "80021",
                            "nome": "Afragola"
                        },
                        {
                            "code": "063003",
                            "cap": "80051",
                            "nome": "Agerola"
                        },
                        {
                            "code": "063004",
                            "cap": "80071",
                            "nome": "Anacapri"
                        },
                        {
                            "code": "063005",
                            "cap": "80022",
                            "nome": "Arzano"
                        },
                        {
                            "code": "063006",
                            "cap": "80070",
                            "nome": "Bacoli"
                        },
                        {
                            "code": "063007",
                            "cap": "80070",
                            "nome": "Barano d'Ischia"
                        },
                        {
                            "code": "063008",
                            "cap": "80041",
                            "nome": "Boscoreale"
                        },
                        {
                            "code": "063009",
                            "cap": "80042",
                            "nome": "Boscotrecase"
                        },
                        {
                            "code": "063010",
                            "cap": "80031",
                            "nome": "Brusciano"
                        },
                        {
                            "code": "063011",
                            "cap": "80023",
                            "nome": "Caivano"
                        },
                        {
                            "code": "063012",
                            "cap": "80012",
                            "nome": "Calvizzano"
                        },
                        {
                            "code": "063013",
                            "cap": "80030",
                            "nome": "Camposano"
                        },
                        {
                            "code": "063014",
                            "cap": "80073",
                            "nome": "Capri"
                        },
                        {
                            "code": "063015",
                            "cap": "80030",
                            "nome": "Carbonara di Nola"
                        },
                        {
                            "code": "063016",
                            "cap": "80024",
                            "nome": "Cardito"
                        },
                        {
                            "code": "063017",
                            "cap": "80013",
                            "nome": "Casalnuovo di Napoli"
                        },
                        {
                            "code": "063018",
                            "cap": "80032",
                            "nome": "Casamarciano"
                        },
                        {
                            "code": "063019",
                            "cap": "80074",
                            "nome": "Casamicciola Terme"
                        },
                        {
                            "code": "063020",
                            "cap": "80025",
                            "nome": "Casandrino"
                        },
                        {
                            "code": "063021",
                            "cap": "80020",
                            "nome": "Casavatore"
                        },
                        {
                            "code": "063022",
                            "cap": "80050",
                            "nome": "Casola di Napoli"
                        },
                        {
                            "code": "063023",
                            "cap": "80026",
                            "nome": "Casoria"
                        },
                        {
                            "code": "063024",
                            "cap": "80053",
                            "nome": "Castellammare di Stabia"
                        },
                        {
                            "code": "063025",
                            "cap": "80030",
                            "nome": "Castello di Cisterna"
                        },
                        {
                            "code": "063026",
                            "cap": "80040",
                            "nome": "Cercola"
                        },
                        {
                            "code": "063027",
                            "cap": "80033",
                            "nome": "Cicciano"
                        },
                        {
                            "code": "063028",
                            "cap": "80030",
                            "nome": "Cimitile"
                        },
                        {
                            "code": "063029",
                            "cap": "80030",
                            "nome": "Comiziano"
                        },
                        {
                            "code": "063030",
                            "cap": "80020",
                            "nome": "Crispano"
                        },
                        {
                            "code": "063064",
                            "cap": "80056",
                            "nome": "Ercolano"
                        },
                        {
                            "code": "063031",
                            "cap": "80075",
                            "nome": "Forio"
                        },
                        {
                            "code": "063032",
                            "cap": "80027",
                            "nome": "Frattamaggiore"
                        },
                        {
                            "code": "063033",
                            "cap": "80020",
                            "nome": "Frattaminore"
                        },
                        {
                            "code": "063034",
                            "cap": "80014",
                            "nome": "Giugliano in Campania"
                        },
                        {
                            "code": "063035",
                            "cap": "80054",
                            "nome": "Gragnano"
                        },
                        {
                            "code": "063036",
                            "cap": "80028",
                            "nome": "Grumo Nevano"
                        },
                        {
                            "code": "063037",
                            "cap": "80077",
                            "nome": "Ischia"
                        },
                        {
                            "code": "063038",
                            "cap": "80076",
                            "nome": "Lacco Ameno"
                        },
                        {
                            "code": "063039",
                            "cap": "80050",
                            "nome": "Lettere"
                        },
                        {
                            "code": "063040",
                            "cap": "80030",
                            "nome": "Liveri"
                        },
                        {
                            "code": "063041",
                            "cap": "80016",
                            "nome": "Marano di Napoli"
                        },
                        {
                            "code": "063042",
                            "cap": "80030",
                            "nome": "Mariglianella"
                        },
                        {
                            "code": "063043",
                            "cap": "80034",
                            "nome": "Marigliano"
                        },
                        {
                            "code": "063092",
                            "cap": "80040",
                            "nome": "Massa di Somma"
                        },
                        {
                            "code": "063044",
                            "cap": "80061",
                            "nome": "Massa Lubrense"
                        },
                        {
                            "code": "063045",
                            "cap": "80017",
                            "nome": "Melito di Napoli"
                        },
                        {
                            "code": "063046",
                            "cap": "80062",
                            "nome": "Meta"
                        },
                        {
                            "code": "063047",
                            "cap": "80070",
                            "nome": "Monte di Procida"
                        },
                        {
                            "code": "063048",
                            "cap": "80018",
                            "nome": "Mugnano di Napoli"
                        },
                        {
                            "code": "063049",
                            "cap": "801xx",
                            "nome": "Napoli"
                        },
                        {
                            "code": "063050",
                            "cap": "80035",
                            "nome": "Nola"
                        },
                        {
                            "code": "063051",
                            "cap": "80044",
                            "nome": "Ottaviano"
                        },
                        {
                            "code": "063052",
                            "cap": "80036",
                            "nome": "Palma Campania"
                        },
                        {
                            "code": "063053",
                            "cap": "80063",
                            "nome": "Piano di Sorrento"
                        },
                        {
                            "code": "063054",
                            "cap": "80050",
                            "nome": "Pimonte"
                        },
                        {
                            "code": "063055",
                            "cap": "80040",
                            "nome": "Poggiomarino"
                        },
                        {
                            "code": "063056",
                            "cap": "80040",
                            "nome": "Pollena Trocchia"
                        },
                        {
                            "code": "063057",
                            "cap": "80038",
                            "nome": "Pomigliano d'Arco"
                        },
                        {
                            "code": "063058",
                            "cap": "80045",
                            "nome": "Pompei"
                        },
                        {
                            "code": "063059",
                            "cap": "80055",
                            "nome": "Portici"
                        },
                        {
                            "code": "063060",
                            "cap": "80078",
                            "nome": "Pozzuoli"
                        },
                        {
                            "code": "063061",
                            "cap": "80079",
                            "nome": "Procida"
                        },
                        {
                            "code": "063062",
                            "cap": "80019",
                            "nome": "Qualiano"
                        },
                        {
                            "code": "063063",
                            "cap": "80010",
                            "nome": "Quarto"
                        },
                        {
                            "code": "063065",
                            "cap": "80030",
                            "nome": "Roccarainola"
                        },
                        {
                            "code": "063066",
                            "cap": "80040",
                            "nome": "San Gennaro Vesuviano"
                        },
                        {
                            "code": "063067",
                            "cap": "80046",
                            "nome": "San Giorgio a Cremano"
                        },
                        {
                            "code": "063068",
                            "cap": "80047",
                            "nome": "San Giuseppe Vesuviano"
                        },
                        {
                            "code": "063069",
                            "cap": "80030",
                            "nome": "San Paolo Bel Sito"
                        },
                        {
                            "code": "063070",
                            "cap": "80040",
                            "nome": "San Sebastiano al Vesuvio"
                        },
                        {
                            "code": "063075",
                            "cap": "80030",
                            "nome": "San Vitaliano"
                        },
                        {
                            "code": "063090",
                            "cap": "80050",
                            "nome": "Santa Maria la Carit\u00e0"
                        },
                        {
                            "code": "063071",
                            "cap": "80065",
                            "nome": "Sant'Agnello"
                        },
                        {
                            "code": "063072",
                            "cap": "80048",
                            "nome": "Sant'Anastasia"
                        },
                        {
                            "code": "063073",
                            "cap": "80029",
                            "nome": "Sant'Antimo"
                        },
                        {
                            "code": "063074",
                            "cap": "80057",
                            "nome": "Sant'Antonio Abate"
                        },
                        {
                            "code": "063076",
                            "cap": "80039",
                            "nome": "Saviano"
                        },
                        {
                            "code": "063077",
                            "cap": "80030",
                            "nome": "Scisciano"
                        },
                        {
                            "code": "063078",
                            "cap": "80070",
                            "nome": "Serrara Fontana"
                        },
                        {
                            "code": "063079",
                            "cap": "80049",
                            "nome": "Somma Vesuviana"
                        },
                        {
                            "code": "063080",
                            "cap": "80067",
                            "nome": "Sorrento"
                        },
                        {
                            "code": "063081",
                            "cap": "80040",
                            "nome": "Striano"
                        },
                        {
                            "code": "063082",
                            "cap": "80040",
                            "nome": "Terzigno"
                        },
                        {
                            "code": "063083",
                            "cap": "80058",
                            "nome": "Torre Annunziata"
                        },
                        {
                            "code": "063084",
                            "cap": "80059",
                            "nome": "Torre del Greco"
                        },
                        {
                            "code": "063091",
                            "cap": "80040",
                            "nome": "Trecase"
                        },
                        {
                            "code": "063085",
                            "cap": "80030",
                            "nome": "Tufino"
                        },
                        {
                            "code": "063086",
                            "cap": "80069",
                            "nome": "Vico Equense"
                        },
                        {
                            "code": "063087",
                            "cap": "80010",
                            "nome": "Villaricca"
                        },
                        {
                            "code": "063088",
                            "cap": "80030",
                            "nome": "Visciano"
                        },
                        {
                            "code": "063089",
                            "cap": "80040",
                            "nome": "Volla"
                        }
                    ],
                    "nome": "Napoli"
                },
                {
                    "code": "SA",
                    "comuni": [
                        {
                            "code": "065001",
                            "cap": "84042",
                            "nome": "Acerno"
                        },
                        {
                            "code": "065002",
                            "cap": "84043",
                            "nome": "Agropoli"
                        },
                        {
                            "code": "065003",
                            "cap": "84044",
                            "nome": "Albanella"
                        },
                        {
                            "code": "065004",
                            "cap": "84040",
                            "nome": "Alfano"
                        },
                        {
                            "code": "065005",
                            "cap": "84045",
                            "nome": "Altavilla Silentina"
                        },
                        {
                            "code": "065006",
                            "cap": "84011",
                            "nome": "Amalfi"
                        },
                        {
                            "code": "065007",
                            "cap": "84012",
                            "nome": "Angri"
                        },
                        {
                            "code": "065008",
                            "cap": "84020",
                            "nome": "Aquara"
                        },
                        {
                            "code": "065009",
                            "cap": "84046",
                            "nome": "Ascea"
                        },
                        {
                            "code": "065010",
                            "cap": "84030",
                            "nome": "Atena Lucana"
                        },
                        {
                            "code": "065011",
                            "cap": "84010",
                            "nome": "Atrani"
                        },
                        {
                            "code": "065012",
                            "cap": "84031",
                            "nome": "Auletta"
                        },
                        {
                            "code": "065013",
                            "cap": "84081",
                            "nome": "Baronissi"
                        },
                        {
                            "code": "065014",
                            "cap": "84091",
                            "nome": "Battipaglia"
                        },
                        {
                            "code": "065158",
                            "cap": "84092",
                            "nome": "Bellizzi"
                        },
                        {
                            "code": "065015",
                            "cap": "84020",
                            "nome": "Bellosguardo"
                        },
                        {
                            "code": "065016",
                            "cap": "84082",
                            "nome": "Bracigliano"
                        },
                        {
                            "code": "065017",
                            "cap": "84021",
                            "nome": "Buccino"
                        },
                        {
                            "code": "065018",
                            "cap": "84032",
                            "nome": "Buonabitacolo"
                        },
                        {
                            "code": "065019",
                            "cap": "84030",
                            "nome": "Caggiano"
                        },
                        {
                            "code": "065020",
                            "cap": "84080",
                            "nome": "Calvanico"
                        },
                        {
                            "code": "065021",
                            "cap": "84040",
                            "nome": "Camerota"
                        },
                        {
                            "code": "065022",
                            "cap": "84022",
                            "nome": "Campagna"
                        },
                        {
                            "code": "065023",
                            "cap": "84040",
                            "nome": "Campora"
                        },
                        {
                            "code": "065024",
                            "cap": "84040",
                            "nome": "Cannalonga"
                        },
                        {
                            "code": "065025",
                            "cap": "84047",
                            "nome": "Capaccio"
                        },
                        {
                            "code": "065028",
                            "cap": "84040",
                            "nome": "Casal Velino"
                        },
                        {
                            "code": "065026",
                            "cap": "84030",
                            "nome": "Casalbuono"
                        },
                        {
                            "code": "065027",
                            "cap": "84030",
                            "nome": "Casaletto Spartano"
                        },
                        {
                            "code": "065029",
                            "cap": "84030",
                            "nome": "Caselle in Pittari"
                        },
                        {
                            "code": "065034",
                            "cap": "84083",
                            "nome": "Castel San Giorgio"
                        },
                        {
                            "code": "065035",
                            "cap": "84049",
                            "nome": "Castel San Lorenzo"
                        },
                        {
                            "code": "065030",
                            "cap": "84020",
                            "nome": "Castelcivita"
                        },
                        {
                            "code": "065031",
                            "cap": "84048",
                            "nome": "Castellabate"
                        },
                        {
                            "code": "065032",
                            "cap": "84040",
                            "nome": "Castelnuovo Cilento"
                        },
                        {
                            "code": "065033",
                            "cap": "84020",
                            "nome": "Castelnuovo di Conza"
                        },
                        {
                            "code": "065036",
                            "cap": "84090",
                            "nome": "Castiglione del Genovesi"
                        },
                        {
                            "code": "065037",
                            "cap": "84013",
                            "nome": "Cava de' Tirreni"
                        },
                        {
                            "code": "065038",
                            "cap": "84040",
                            "nome": "Celle di Bulgheria"
                        },
                        {
                            "code": "065039",
                            "cap": "84051",
                            "nome": "Centola"
                        },
                        {
                            "code": "065040",
                            "cap": "84052",
                            "nome": "Ceraso"
                        },
                        {
                            "code": "065041",
                            "cap": "84010",
                            "nome": "Cetara"
                        },
                        {
                            "code": "065042",
                            "cap": "84053",
                            "nome": "Cicerale"
                        },
                        {
                            "code": "065043",
                            "cap": "84020",
                            "nome": "Colliano"
                        },
                        {
                            "code": "065044",
                            "cap": "84010",
                            "nome": "Conca dei Marini"
                        },
                        {
                            "code": "065045",
                            "cap": "84020",
                            "nome": "Controne"
                        },
                        {
                            "code": "065046",
                            "cap": "84024",
                            "nome": "Contursi Terme"
                        },
                        {
                            "code": "065047",
                            "cap": "84010",
                            "nome": "Corbara"
                        },
                        {
                            "code": "065048",
                            "cap": "84020",
                            "nome": "Corleto Monforte"
                        },
                        {
                            "code": "065049",
                            "cap": "84050",
                            "nome": "Cuccaro Vetere"
                        },
                        {
                            "code": "065050",
                            "cap": "84025",
                            "nome": "Eboli"
                        },
                        {
                            "code": "065051",
                            "cap": "84055",
                            "nome": "Felitto"
                        },
                        {
                            "code": "065052",
                            "cap": "84084",
                            "nome": "Fisciano"
                        },
                        {
                            "code": "065053",
                            "cap": "84010",
                            "nome": "Furore"
                        },
                        {
                            "code": "065054",
                            "cap": "84050",
                            "nome": "Futani"
                        },
                        {
                            "code": "065055",
                            "cap": "84090",
                            "nome": "Giffoni Sei Casali"
                        },
                        {
                            "code": "065056",
                            "cap": "84095",
                            "nome": "Giffoni Valle Piana"
                        },
                        {
                            "code": "065057",
                            "cap": "84056",
                            "nome": "Gioi"
                        },
                        {
                            "code": "065058",
                            "cap": "84050",
                            "nome": "Giungano"
                        },
                        {
                            "code": "065059",
                            "cap": "84050",
                            "nome": "Ispani"
                        },
                        {
                            "code": "065060",
                            "cap": "84050",
                            "nome": "Laureana Cilento"
                        },
                        {
                            "code": "065061",
                            "cap": "84057",
                            "nome": "Laurino"
                        },
                        {
                            "code": "065062",
                            "cap": "84050",
                            "nome": "Laurito"
                        },
                        {
                            "code": "065063",
                            "cap": "84020",
                            "nome": "Laviano"
                        },
                        {
                            "code": "065064",
                            "cap": "84050",
                            "nome": "Lustra"
                        },
                        {
                            "code": "065065",
                            "cap": "84050",
                            "nome": "Magliano Vetere"
                        },
                        {
                            "code": "065066",
                            "cap": "84010",
                            "nome": "Maiori"
                        },
                        {
                            "code": "065067",
                            "cap": "84085",
                            "nome": "Mercato San Severino"
                        },
                        {
                            "code": "065068",
                            "cap": "84010",
                            "nome": "Minori"
                        },
                        {
                            "code": "065069",
                            "cap": "84060",
                            "nome": "Moio della Civitella"
                        },
                        {
                            "code": "065070",
                            "cap": "84060",
                            "nome": "Montano Antilia"
                        },
                        {
                            "code": "065075",
                            "cap": "84030",
                            "nome": "Monte San Giacomo"
                        },
                        {
                            "code": "065071",
                            "cap": "84060",
                            "nome": "Montecorice"
                        },
                        {
                            "code": "065072",
                            "cap": "84090",
                            "nome": "Montecorvino Pugliano"
                        },
                        {
                            "code": "065073",
                            "cap": "84096",
                            "nome": "Montecorvino Rovella"
                        },
                        {
                            "code": "065074",
                            "cap": "84060",
                            "nome": "Monteforte Cilento"
                        },
                        {
                            "code": "065076",
                            "cap": "84033",
                            "nome": "Montesano sulla Marcellana"
                        },
                        {
                            "code": "065077",
                            "cap": "84030",
                            "nome": "Morigerati"
                        },
                        {
                            "code": "065078",
                            "cap": "84014",
                            "nome": "Nocera Inferiore"
                        },
                        {
                            "code": "065079",
                            "cap": "84015",
                            "nome": "Nocera Superiore"
                        },
                        {
                            "code": "065080",
                            "cap": "84060",
                            "nome": "Novi Velia"
                        },
                        {
                            "code": "065081",
                            "cap": "84061",
                            "nome": "Ogliastro Cilento"
                        },
                        {
                            "code": "065082",
                            "cap": "84062",
                            "nome": "Olevano sul Tusciano"
                        },
                        {
                            "code": "065083",
                            "cap": "84020",
                            "nome": "Oliveto Citra"
                        },
                        {
                            "code": "065084",
                            "cap": "84060",
                            "nome": "Omignano"
                        },
                        {
                            "code": "065085",
                            "cap": "84060",
                            "nome": "Orria"
                        },
                        {
                            "code": "065086",
                            "cap": "84020",
                            "nome": "Ottati"
                        },
                        {
                            "code": "065087",
                            "cap": "84034",
                            "nome": "Padula"
                        },
                        {
                            "code": "065088",
                            "cap": "84016",
                            "nome": "Pagani"
                        },
                        {
                            "code": "065089",
                            "cap": "84020",
                            "nome": "Palomonte"
                        },
                        {
                            "code": "065090",
                            "cap": "84080",
                            "nome": "Pellezzano"
                        },
                        {
                            "code": "065091",
                            "cap": "84060",
                            "nome": "Perdifumo"
                        },
                        {
                            "code": "065092",
                            "cap": "84060",
                            "nome": "Perito"
                        },
                        {
                            "code": "065093",
                            "cap": "84030",
                            "nome": "Pertosa"
                        },
                        {
                            "code": "065094",
                            "cap": "84020",
                            "nome": "Petina"
                        },
                        {
                            "code": "065095",
                            "cap": "84065",
                            "nome": "Piaggine"
                        },
                        {
                            "code": "065096",
                            "cap": "84066",
                            "nome": "Pisciotta"
                        },
                        {
                            "code": "065097",
                            "cap": "84035",
                            "nome": "Polla"
                        },
                        {
                            "code": "065098",
                            "cap": "84068",
                            "nome": "Pollica"
                        },
                        {
                            "code": "065099",
                            "cap": "84098",
                            "nome": "Pontecagnano Faiano"
                        },
                        {
                            "code": "065100",
                            "cap": "84017",
                            "nome": "Positano"
                        },
                        {
                            "code": "065101",
                            "cap": "84026",
                            "nome": "Postiglione"
                        },
                        {
                            "code": "065102",
                            "cap": "84010",
                            "nome": "Praiano"
                        },
                        {
                            "code": "065103",
                            "cap": "84060",
                            "nome": "Prignano Cilento"
                        },
                        {
                            "code": "065104",
                            "cap": "84010",
                            "nome": "Ravello"
                        },
                        {
                            "code": "065105",
                            "cap": "84020",
                            "nome": "Ricigliano"
                        },
                        {
                            "code": "065106",
                            "cap": "84069",
                            "nome": "Roccadaspide"
                        },
                        {
                            "code": "065107",
                            "cap": "84060",
                            "nome": "Roccagloriosa"
                        },
                        {
                            "code": "065108",
                            "cap": "84086",
                            "nome": "Roccapiemonte"
                        },
                        {
                            "code": "065109",
                            "cap": "84070",
                            "nome": "Rofrano"
                        },
                        {
                            "code": "065110",
                            "cap": "84020",
                            "nome": "Romagnano al Monte"
                        },
                        {
                            "code": "065111",
                            "cap": "84020",
                            "nome": "Roscigno"
                        },
                        {
                            "code": "065112",
                            "cap": "84070",
                            "nome": "Rutino"
                        },
                        {
                            "code": "065113",
                            "cap": "84070",
                            "nome": "Sacco"
                        },
                        {
                            "code": "065114",
                            "cap": "84036",
                            "nome": "Sala Consilina"
                        },
                        {
                            "code": "065115",
                            "cap": "84070",
                            "nome": "Salento"
                        },
                        {
                            "code": "065116",
                            "cap": "841xx",
                            "nome": "Salerno"
                        },
                        {
                            "code": "065117",
                            "cap": "84020",
                            "nome": "Salvitelle"
                        },
                        {
                            "code": "065118",
                            "cap": "84099",
                            "nome": "San Cipriano Picentino"
                        },
                        {
                            "code": "065119",
                            "cap": "84070",
                            "nome": "San Giovanni a Piro"
                        },
                        {
                            "code": "065120",
                            "cap": "84020",
                            "nome": "San Gregorio Magno"
                        },
                        {
                            "code": "065121",
                            "cap": "84090",
                            "nome": "San Mango Piemonte"
                        },
                        {
                            "code": "065122",
                            "cap": "84010",
                            "nome": "San Marzano sul Sarno"
                        },
                        {
                            "code": "065123",
                            "cap": "84070",
                            "nome": "San Mauro Cilento"
                        },
                        {
                            "code": "065124",
                            "cap": "84070",
                            "nome": "San Mauro La Bruca"
                        },
                        {
                            "code": "065125",
                            "cap": "84030",
                            "nome": "San Pietro al Tanagro"
                        },
                        {
                            "code": "065126",
                            "cap": "84030",
                            "nome": "San Rufo"
                        },
                        {
                            "code": "065132",
                            "cap": "84010",
                            "nome": "San Valentino Torio"
                        },
                        {
                            "code": "065127",
                            "cap": "84070",
                            "nome": "Santa Marina"
                        },
                        {
                            "code": "065128",
                            "cap": "84027",
                            "nome": "Sant'Angelo a Fasanella"
                        },
                        {
                            "code": "065129",
                            "cap": "84037",
                            "nome": "Sant'Arsenio"
                        },
                        {
                            "code": "065130",
                            "cap": "84010",
                            "nome": "Sant'Egidio del Monte Albino"
                        },
                        {
                            "code": "065131",
                            "cap": "84020",
                            "nome": "Santomenna"
                        },
                        {
                            "code": "065133",
                            "cap": "84030",
                            "nome": "Sanza"
                        },
                        {
                            "code": "065134",
                            "cap": "84073",
                            "nome": "Sapri"
                        },
                        {
                            "code": "065135",
                            "cap": "84087",
                            "nome": "Sarno"
                        },
                        {
                            "code": "065136",
                            "cap": "84038",
                            "nome": "Sassano"
                        },
                        {
                            "code": "065137",
                            "cap": "84018",
                            "nome": "Scafati"
                        },
                        {
                            "code": "065138",
                            "cap": "84010",
                            "nome": "Scala"
                        },
                        {
                            "code": "065139",
                            "cap": "84070",
                            "nome": "Serramezzana"
                        },
                        {
                            "code": "065140",
                            "cap": "84028",
                            "nome": "Serre"
                        },
                        {
                            "code": "065141",
                            "cap": "84074",
                            "nome": "Sessa Cilento"
                        },
                        {
                            "code": "065142",
                            "cap": "84088",
                            "nome": "Siano"
                        },
                        {
                            "code": "065143",
                            "cap": "84029",
                            "nome": "Sicignano degli Alburni"
                        },
                        {
                            "code": "065144",
                            "cap": "84070",
                            "nome": "Stella Cilento"
                        },
                        {
                            "code": "065145",
                            "cap": "84075",
                            "nome": "Stio"
                        },
                        {
                            "code": "065146",
                            "cap": "84039",
                            "nome": "Teggiano"
                        },
                        {
                            "code": "065147",
                            "cap": "84076",
                            "nome": "Torchiara"
                        },
                        {
                            "code": "065148",
                            "cap": "84030",
                            "nome": "Torraca"
                        },
                        {
                            "code": "065149",
                            "cap": "84077",
                            "nome": "Torre Orsaia"
                        },
                        {
                            "code": "065150",
                            "cap": "84030",
                            "nome": "Tortorella"
                        },
                        {
                            "code": "065151",
                            "cap": "84010",
                            "nome": "Tramonti"
                        },
                        {
                            "code": "065152",
                            "cap": "84070",
                            "nome": "Trentinara"
                        },
                        {
                            "code": "065153",
                            "cap": "84070",
                            "nome": "Valle dell'Angelo"
                        },
                        {
                            "code": "065154",
                            "cap": "84078",
                            "nome": "Vallo della Lucania"
                        },
                        {
                            "code": "065155",
                            "cap": "84020",
                            "nome": "Valva"
                        },
                        {
                            "code": "065156",
                            "cap": "84079",
                            "nome": "Vibonati"
                        },
                        {
                            "code": "065157",
                            "cap": "84019",
                            "nome": "Vietri sul Mare"
                        }
                    ],
                    "nome": "Salerno"
                }
            ],
            "nome": "Campania"
        },
        {
            "province": [
                {
                    "code": "BO",
                    "comuni": [
                        {
                            "code": "037001",
                            "cap": "40011",
                            "nome": "Anzola dell'Emilia"
                        },
                        {
                            "code": "037002",
                            "cap": "40050",
                            "nome": "Argelato"
                        },
                        {
                            "code": "037003",
                            "cap": "40052",
                            "nome": "Baricella"
                        },
                        {
                            "code": "037004",
                            "cap": "40053",
                            "nome": "Bazzano"
                        },
                        {
                            "code": "037005",
                            "cap": "40010",
                            "nome": "Bentivoglio"
                        },
                        {
                            "code": "037006",
                            "cap": "401xx",
                            "nome": "Bologna"
                        },
                        {
                            "code": "037007",
                            "cap": "40021",
                            "nome": "Borgo Tossignano"
                        },
                        {
                            "code": "037008",
                            "cap": "40054",
                            "nome": "Budrio"
                        },
                        {
                            "code": "037009",
                            "cap": "40012",
                            "nome": "Calderara di Reno"
                        },
                        {
                            "code": "037010",
                            "cap": "40032",
                            "nome": "Camugnano"
                        },
                        {
                            "code": "037011",
                            "cap": "40033",
                            "nome": "Casalecchio di Reno"
                        },
                        {
                            "code": "037012",
                            "cap": "40020",
                            "nome": "Casalfiumanese"
                        },
                        {
                            "code": "037013",
                            "cap": "40034",
                            "nome": "Castel d'Aiano"
                        },
                        {
                            "code": "037014",
                            "cap": "40022",
                            "nome": "Castel del Rio"
                        },
                        {
                            "code": "037015",
                            "cap": "40030",
                            "nome": "Castel di Casio"
                        },
                        {
                            "code": "037016",
                            "cap": "40023",
                            "nome": "Castel Guelfo di Bologna"
                        },
                        {
                            "code": "037019",
                            "cap": "40013",
                            "nome": "Castel Maggiore"
                        },
                        {
                            "code": "037020",
                            "cap": "40024",
                            "nome": "Castel San Pietro Terme"
                        },
                        {
                            "code": "037017",
                            "cap": "40050",
                            "nome": "Castello d'Argile"
                        },
                        {
                            "code": "037018",
                            "cap": "40050",
                            "nome": "Castello di Serravalle"
                        },
                        {
                            "code": "037021",
                            "cap": "40055",
                            "nome": "Castenaso"
                        },
                        {
                            "code": "037022",
                            "cap": "40035",
                            "nome": "Castiglione dei Pepoli"
                        },
                        {
                            "code": "037023",
                            "cap": "40056",
                            "nome": "Crespellano"
                        },
                        {
                            "code": "037024",
                            "cap": "40014",
                            "nome": "Crevalcore"
                        },
                        {
                            "code": "037025",
                            "cap": "40060",
                            "nome": "Dozza"
                        },
                        {
                            "code": "037026",
                            "cap": "40025",
                            "nome": "Fontanelice"
                        },
                        {
                            "code": "037027",
                            "cap": "40041",
                            "nome": "Gaggio Montano"
                        },
                        {
                            "code": "037028",
                            "cap": "40015",
                            "nome": "Galliera"
                        },
                        {
                            "code": "037029",
                            "cap": "40045",
                            "nome": "Granaglione"
                        },
                        {
                            "code": "037030",
                            "cap": "40057",
                            "nome": "Granarolo dell'Emilia"
                        },
                        {
                            "code": "037031",
                            "cap": "40030",
                            "nome": "Grizzana Morandi"
                        },
                        {
                            "code": "037032",
                            "cap": "40026",
                            "nome": "Imola"
                        },
                        {
                            "code": "037033",
                            "cap": "40042",
                            "nome": "Lizzano in Belvedere"
                        },
                        {
                            "code": "037034",
                            "cap": "40050",
                            "nome": "Loiano"
                        },
                        {
                            "code": "037035",
                            "cap": "40051",
                            "nome": "Malalbergo"
                        },
                        {
                            "code": "037036",
                            "cap": "40043",
                            "nome": "Marzabotto"
                        },
                        {
                            "code": "037037",
                            "cap": "40059",
                            "nome": "Medicina"
                        },
                        {
                            "code": "037038",
                            "cap": "40061",
                            "nome": "Minerbio"
                        },
                        {
                            "code": "037039",
                            "cap": "40062",
                            "nome": "Molinella"
                        },
                        {
                            "code": "037040",
                            "cap": "40063",
                            "nome": "Monghidoro"
                        },
                        {
                            "code": "037042",
                            "cap": "40050",
                            "nome": "Monte San Pietro"
                        },
                        {
                            "code": "037041",
                            "cap": "40050",
                            "nome": "Monterenzio"
                        },
                        {
                            "code": "037043",
                            "cap": "40050",
                            "nome": "Monteveglio"
                        },
                        {
                            "code": "037044",
                            "cap": "40036",
                            "nome": "Monzuno"
                        },
                        {
                            "code": "037045",
                            "cap": "40027",
                            "nome": "Mordano"
                        },
                        {
                            "code": "037046",
                            "cap": "40064",
                            "nome": "Ozzano dell'Emilia"
                        },
                        {
                            "code": "037047",
                            "cap": "40065",
                            "nome": "Pianoro"
                        },
                        {
                            "code": "037048",
                            "cap": "40066",
                            "nome": "Pieve di Cento"
                        },
                        {
                            "code": "037049",
                            "cap": "40046",
                            "nome": "Porretta Terme"
                        },
                        {
                            "code": "037050",
                            "cap": "40010",
                            "nome": "Sala Bolognese"
                        },
                        {
                            "code": "037051",
                            "cap": "40048",
                            "nome": "San Benedetto Val di Sambro"
                        },
                        {
                            "code": "037052",
                            "cap": "40016",
                            "nome": "San Giorgio di Piano"
                        },
                        {
                            "code": "037053",
                            "cap": "40017",
                            "nome": "San Giovanni in Persiceto"
                        },
                        {
                            "code": "037054",
                            "cap": "40068",
                            "nome": "San Lazzaro di Savena"
                        },
                        {
                            "code": "037055",
                            "cap": "40018",
                            "nome": "San Pietro in Casale"
                        },
                        {
                            "code": "037056",
                            "cap": "40019",
                            "nome": "Sant'Agata Bolognese"
                        },
                        {
                            "code": "037057",
                            "cap": "40037",
                            "nome": "Sasso Marconi"
                        },
                        {
                            "code": "037058",
                            "cap": "40060",
                            "nome": "Savigno"
                        },
                        {
                            "code": "037059",
                            "cap": "40038",
                            "nome": "Vergato"
                        },
                        {
                            "code": "037060",
                            "cap": "40069",
                            "nome": "Zola Predosa"
                        }
                    ],
                    "nome": "Bologna"
                },
                {
                    "code": "FE",
                    "comuni": [
                        {
                            "code": "038001",
                            "cap": "44011",
                            "nome": "Argenta"
                        },
                        {
                            "code": "038002",
                            "cap": "44033",
                            "nome": "Berra"
                        },
                        {
                            "code": "038003",
                            "cap": "44012",
                            "nome": "Bondeno"
                        },
                        {
                            "code": "038004",
                            "cap": "44042",
                            "nome": "Cento"
                        },
                        {
                            "code": "038005",
                            "cap": "44021",
                            "nome": "Codigoro"
                        },
                        {
                            "code": "038006",
                            "cap": "44022",
                            "nome": "Comacchio"
                        },
                        {
                            "code": "038007",
                            "cap": "44034",
                            "nome": "Copparo"
                        },
                        {
                            "code": "038008",
                            "cap": "4412x",
                            "nome": "Ferrara"
                        },
                        {
                            "code": "038009",
                            "cap": "44035",
                            "nome": "Formignana"
                        },
                        {
                            "code": "038025",
                            "cap": "44020",
                            "nome": "Goro"
                        },
                        {
                            "code": "038010",
                            "cap": "44037",
                            "nome": "Jolanda di Savoia"
                        },
                        {
                            "code": "038011",
                            "cap": "44023",
                            "nome": "Lagosanto"
                        },
                        {
                            "code": "038012",
                            "cap": "44020",
                            "nome": "Masi Torello"
                        },
                        {
                            "code": "038013",
                            "cap": "44025",
                            "nome": "Massa Fiscaglia"
                        },
                        {
                            "code": "038014",
                            "cap": "44026",
                            "nome": "Mesola"
                        },
                        {
                            "code": "038015",
                            "cap": "44027",
                            "nome": "Migliarino"
                        },
                        {
                            "code": "038026",
                            "cap": "44020",
                            "nome": "Migliaro"
                        },
                        {
                            "code": "038016",
                            "cap": "44043",
                            "nome": "Mirabello"
                        },
                        {
                            "code": "038017",
                            "cap": "44020",
                            "nome": "Ostellato"
                        },
                        {
                            "code": "038018",
                            "cap": "44028",
                            "nome": "Poggio Renatico"
                        },
                        {
                            "code": "038019",
                            "cap": "44015",
                            "nome": "Portomaggiore"
                        },
                        {
                            "code": "038020",
                            "cap": "44030",
                            "nome": "Ro"
                        },
                        {
                            "code": "038021",
                            "cap": "44047",
                            "nome": "Sant'Agostino"
                        },
                        {
                            "code": "038024",
                            "cap": "44039",
                            "nome": "Tresigallo"
                        },
                        {
                            "code": "038022",
                            "cap": "44049",
                            "nome": "Vigarano Mainarda"
                        },
                        {
                            "code": "038023",
                            "cap": "44019",
                            "nome": "Voghiera"
                        }
                    ],
                    "nome": "Ferrara"
                },
                {
                    "code": "FC",
                    "comuni": [
                        {
                            "code": "040001",
                            "cap": "47021",
                            "nome": "Bagno di Romagna"
                        },
                        {
                            "code": "040003",
                            "cap": "47032",
                            "nome": "Bertinoro"
                        },
                        {
                            "code": "040004",
                            "cap": "47030",
                            "nome": "Borghi"
                        },
                        {
                            "code": "040005",
                            "cap": "47011",
                            "nome": "Castrocaro Terme e Terra del Sole"
                        },
                        {
                            "code": "040007",
                            "cap": "4752x",
                            "nome": "Cesena"
                        },
                        {
                            "code": "040008",
                            "cap": "47042",
                            "nome": "Cesenatico"
                        },
                        {
                            "code": "040009",
                            "cap": "47012",
                            "nome": "Civitella di Romagna"
                        },
                        {
                            "code": "040011",
                            "cap": "47013",
                            "nome": "Dovadola"
                        },
                        {
                            "code": "040012",
                            "cap": "4712x",
                            "nome": "Forl\u00ec"
                        },
                        {
                            "code": "040013",
                            "cap": "47034",
                            "nome": "Forlimpopoli"
                        },
                        {
                            "code": "040014",
                            "cap": "47010",
                            "nome": "Galeata"
                        },
                        {
                            "code": "040015",
                            "cap": "47035",
                            "nome": "Gambettola"
                        },
                        {
                            "code": "040016",
                            "cap": "47043",
                            "nome": "Gatteo"
                        },
                        {
                            "code": "040018",
                            "cap": "47020",
                            "nome": "Longiano"
                        },
                        {
                            "code": "040019",
                            "cap": "47014",
                            "nome": "Meldola"
                        },
                        {
                            "code": "040020",
                            "cap": "47025",
                            "nome": "Mercato Saraceno"
                        },
                        {
                            "code": "040022",
                            "cap": "47015",
                            "nome": "Modigliana"
                        },
                        {
                            "code": "040028",
                            "cap": "47020",
                            "nome": "Montiano"
                        },
                        {
                            "code": "040031",
                            "cap": "47010",
                            "nome": "Portico e San Benedetto"
                        },
                        {
                            "code": "040032",
                            "cap": "47016",
                            "nome": "Predappio"
                        },
                        {
                            "code": "040033",
                            "cap": "47010",
                            "nome": "Premilcuore"
                        },
                        {
                            "code": "040036",
                            "cap": "47017",
                            "nome": "Rocca San Casciano"
                        },
                        {
                            "code": "040037",
                            "cap": "47020",
                            "nome": "Roncofreddo"
                        },
                        {
                            "code": "040041",
                            "cap": "47030",
                            "nome": "San Mauro Pascoli"
                        },
                        {
                            "code": "040043",
                            "cap": "47018",
                            "nome": "Santa Sofia"
                        },
                        {
                            "code": "040044",
                            "cap": "47027",
                            "nome": "Sarsina"
                        },
                        {
                            "code": "040045",
                            "cap": "47039",
                            "nome": "Savignano sul Rubicone"
                        },
                        {
                            "code": "040046",
                            "cap": "47030",
                            "nome": "Sogliano al Rubicone"
                        },
                        {
                            "code": "040049",
                            "cap": "47019",
                            "nome": "Tredozio"
                        },
                        {
                            "code": "040050",
                            "cap": "47028",
                            "nome": "Verghereto"
                        }
                    ],
                    "nome": "Forl\u00ec-Cesena"
                },
                {
                    "code": "MO",
                    "comuni": [
                        {
                            "code": "036001",
                            "cap": "41030",
                            "nome": "Bastiglia"
                        },
                        {
                            "code": "036002",
                            "cap": "41030",
                            "nome": "Bomporto"
                        },
                        {
                            "code": "036003",
                            "cap": "41011",
                            "nome": "Campogalliano"
                        },
                        {
                            "code": "036004",
                            "cap": "41031",
                            "nome": "Camposanto"
                        },
                        {
                            "code": "036005",
                            "cap": "41012",
                            "nome": "Carpi"
                        },
                        {
                            "code": "036006",
                            "cap": "41013",
                            "nome": "Castelfranco Emilia"
                        },
                        {
                            "code": "036007",
                            "cap": "41051",
                            "nome": "Castelnuovo Rangone"
                        },
                        {
                            "code": "036008",
                            "cap": "41014",
                            "nome": "Castelvetro di Modena"
                        },
                        {
                            "code": "036009",
                            "cap": "41032",
                            "nome": "Cavezzo"
                        },
                        {
                            "code": "036010",
                            "cap": "41033",
                            "nome": "Concordia sulla Secchia"
                        },
                        {
                            "code": "036011",
                            "cap": "41021",
                            "nome": "Fanano"
                        },
                        {
                            "code": "036012",
                            "cap": "41034",
                            "nome": "Finale Emilia"
                        },
                        {
                            "code": "036013",
                            "cap": "41042",
                            "nome": "Fiorano Modenese"
                        },
                        {
                            "code": "036014",
                            "cap": "41022",
                            "nome": "Fiumalbo"
                        },
                        {
                            "code": "036015",
                            "cap": "41043",
                            "nome": "Formigine"
                        },
                        {
                            "code": "036016",
                            "cap": "41044",
                            "nome": "Frassinoro"
                        },
                        {
                            "code": "036017",
                            "cap": "41052",
                            "nome": "Guiglia"
                        },
                        {
                            "code": "036018",
                            "cap": "41023",
                            "nome": "Lama Mocogno"
                        },
                        {
                            "code": "036019",
                            "cap": "41053",
                            "nome": "Maranello"
                        },
                        {
                            "code": "036020",
                            "cap": "41054",
                            "nome": "Marano sul Panaro"
                        },
                        {
                            "code": "036021",
                            "cap": "41036",
                            "nome": "Medolla"
                        },
                        {
                            "code": "036022",
                            "cap": "41037",
                            "nome": "Mirandola"
                        },
                        {
                            "code": "036023",
                            "cap": "4112x",
                            "nome": "Modena"
                        },
                        {
                            "code": "036024",
                            "cap": "41025",
                            "nome": "Montecreto"
                        },
                        {
                            "code": "036025",
                            "cap": "41045",
                            "nome": "Montefiorino"
                        },
                        {
                            "code": "036026",
                            "cap": "41055",
                            "nome": "Montese"
                        },
                        {
                            "code": "036027",
                            "cap": "41015",
                            "nome": "Nonantola"
                        },
                        {
                            "code": "036028",
                            "cap": "41016",
                            "nome": "Novi di Modena"
                        },
                        {
                            "code": "036029",
                            "cap": "41046",
                            "nome": "Palagano"
                        },
                        {
                            "code": "036030",
                            "cap": "41026",
                            "nome": "Pavullo nel Frignano"
                        },
                        {
                            "code": "036031",
                            "cap": "41027",
                            "nome": "Pievepelago"
                        },
                        {
                            "code": "036032",
                            "cap": "41040",
                            "nome": "Polinago"
                        },
                        {
                            "code": "036033",
                            "cap": "41048",
                            "nome": "Prignano sulla Secchia"
                        },
                        {
                            "code": "036034",
                            "cap": "41017",
                            "nome": "Ravarino"
                        },
                        {
                            "code": "036035",
                            "cap": "41020",
                            "nome": "Riolunato"
                        },
                        {
                            "code": "036036",
                            "cap": "41018",
                            "nome": "San Cesario sul Panaro"
                        },
                        {
                            "code": "036037",
                            "cap": "41038",
                            "nome": "San Felice sul Panaro"
                        },
                        {
                            "code": "036038",
                            "cap": "41039",
                            "nome": "San Possidonio"
                        },
                        {
                            "code": "036039",
                            "cap": "41030",
                            "nome": "San Prospero"
                        },
                        {
                            "code": "036040",
                            "cap": "41049",
                            "nome": "Sassuolo"
                        },
                        {
                            "code": "036041",
                            "cap": "41056",
                            "nome": "Savignano sul Panaro"
                        },
                        {
                            "code": "036042",
                            "cap": "41028",
                            "nome": "Serramazzoni"
                        },
                        {
                            "code": "036043",
                            "cap": "41029",
                            "nome": "Sestola"
                        },
                        {
                            "code": "036044",
                            "cap": "41019",
                            "nome": "Soliera"
                        },
                        {
                            "code": "036045",
                            "cap": "41057",
                            "nome": "Spilamberto"
                        },
                        {
                            "code": "036046",
                            "cap": "41058",
                            "nome": "Vignola"
                        },
                        {
                            "code": "036047",
                            "cap": "41059",
                            "nome": "Zocca"
                        }
                    ],
                    "nome": "Modena"
                },
                {
                    "code": "PR",
                    "comuni": [
                        {
                            "code": "034001",
                            "cap": "43051",
                            "nome": "Albareto"
                        },
                        {
                            "code": "034002",
                            "cap": "43032",
                            "nome": "Bardi"
                        },
                        {
                            "code": "034003",
                            "cap": "43041",
                            "nome": "Bedonia"
                        },
                        {
                            "code": "034004",
                            "cap": "43042",
                            "nome": "Berceto"
                        },
                        {
                            "code": "034005",
                            "cap": "43030",
                            "nome": "Bore"
                        },
                        {
                            "code": "034006",
                            "cap": "43043",
                            "nome": "Borgo Val di Taro"
                        },
                        {
                            "code": "034007",
                            "cap": "43011",
                            "nome": "Busseto"
                        },
                        {
                            "code": "034008",
                            "cap": "43030",
                            "nome": "Calestano"
                        },
                        {
                            "code": "034009",
                            "cap": "43044",
                            "nome": "Collecchio"
                        },
                        {
                            "code": "034010",
                            "cap": "43052",
                            "nome": "Colorno"
                        },
                        {
                            "code": "034011",
                            "cap": "43053",
                            "nome": "Compiano"
                        },
                        {
                            "code": "034012",
                            "cap": "43021",
                            "nome": "Corniglio"
                        },
                        {
                            "code": "034013",
                            "cap": "43035",
                            "nome": "Felino"
                        },
                        {
                            "code": "034014",
                            "cap": "43036",
                            "nome": "Fidenza"
                        },
                        {
                            "code": "034015",
                            "cap": "43012",
                            "nome": "Fontanellato"
                        },
                        {
                            "code": "034016",
                            "cap": "43010",
                            "nome": "Fontevivo"
                        },
                        {
                            "code": "034017",
                            "cap": "43045",
                            "nome": "Fornovo di Taro"
                        },
                        {
                            "code": "034018",
                            "cap": "43013",
                            "nome": "Langhirano"
                        },
                        {
                            "code": "034019",
                            "cap": "43037",
                            "nome": "Lesignano de' Bagni"
                        },
                        {
                            "code": "034020",
                            "cap": "43014",
                            "nome": "Medesano"
                        },
                        {
                            "code": "034021",
                            "cap": "43055",
                            "nome": "Mezzani"
                        },
                        {
                            "code": "034022",
                            "cap": "43010",
                            "nome": "Monchio delle Corti"
                        },
                        {
                            "code": "034023",
                            "cap": "43022",
                            "nome": "Montechiarugolo"
                        },
                        {
                            "code": "034024",
                            "cap": "43024",
                            "nome": "Neviano degli Arduini"
                        },
                        {
                            "code": "034025",
                            "cap": "43015",
                            "nome": "Noceto"
                        },
                        {
                            "code": "034026",
                            "cap": "43025",
                            "nome": "Palanzano"
                        },
                        {
                            "code": "034027",
                            "cap": "4312x",
                            "nome": "Parma"
                        },
                        {
                            "code": "034028",
                            "cap": "43047",
                            "nome": "Pellegrino Parmense"
                        },
                        {
                            "code": "034029",
                            "cap": "43010",
                            "nome": "Polesine Parmense"
                        },
                        {
                            "code": "034030",
                            "cap": "43010",
                            "nome": "Roccabianca"
                        },
                        {
                            "code": "034031",
                            "cap": "43038",
                            "nome": "Sala Baganza"
                        },
                        {
                            "code": "034032",
                            "cap": "43039",
                            "nome": "Salsomaggiore Terme"
                        },
                        {
                            "code": "034033",
                            "cap": "43017",
                            "nome": "San Secondo Parmense"
                        },
                        {
                            "code": "034034",
                            "cap": "43018",
                            "nome": "Sissa"
                        },
                        {
                            "code": "034035",
                            "cap": "43040",
                            "nome": "Solignano"
                        },
                        {
                            "code": "034036",
                            "cap": "43019",
                            "nome": "Soragna"
                        },
                        {
                            "code": "034037",
                            "cap": "43058",
                            "nome": "Sorbolo"
                        },
                        {
                            "code": "034038",
                            "cap": "43040",
                            "nome": "Terenzo"
                        },
                        {
                            "code": "034039",
                            "cap": "43028",
                            "nome": "Tizzano Val Parma"
                        },
                        {
                            "code": "034040",
                            "cap": "43059",
                            "nome": "Tornolo"
                        },
                        {
                            "code": "034041",
                            "cap": "43056",
                            "nome": "Torrile"
                        },
                        {
                            "code": "034042",
                            "cap": "43029",
                            "nome": "Traversetolo"
                        },
                        {
                            "code": "034043",
                            "cap": "43010",
                            "nome": "Trecasali"
                        },
                        {
                            "code": "034044",
                            "cap": "43050",
                            "nome": "Valmozzola"
                        },
                        {
                            "code": "034045",
                            "cap": "43040",
                            "nome": "Varano de' Melegari"
                        },
                        {
                            "code": "034046",
                            "cap": "43049",
                            "nome": "Varsi"
                        },
                        {
                            "code": "034048",
                            "cap": "43010",
                            "nome": "Zibello"
                        }
                    ],
                    "nome": "Parma"
                },
                {
                    "code": "PC",
                    "comuni": [
                        {
                            "code": "033001",
                            "cap": "29010",
                            "nome": "Agazzano"
                        },
                        {
                            "code": "033002",
                            "cap": "29010",
                            "nome": "Alseno"
                        },
                        {
                            "code": "033003",
                            "cap": "29010",
                            "nome": "Besenzone"
                        },
                        {
                            "code": "033004",
                            "cap": "29021",
                            "nome": "Bettola"
                        },
                        {
                            "code": "033005",
                            "cap": "29022",
                            "nome": "Bobbio"
                        },
                        {
                            "code": "033006",
                            "cap": "29011",
                            "nome": "Borgonovo Val Tidone"
                        },
                        {
                            "code": "033007",
                            "cap": "29010",
                            "nome": "Cadeo"
                        },
                        {
                            "code": "033008",
                            "cap": "29010",
                            "nome": "Calendasco"
                        },
                        {
                            "code": "033009",
                            "cap": "29010",
                            "nome": "Caminata"
                        },
                        {
                            "code": "033010",
                            "cap": "29012",
                            "nome": "Caorso"
                        },
                        {
                            "code": "033011",
                            "cap": "29013",
                            "nome": "Carpaneto Piacentino"
                        },
                        {
                            "code": "033013",
                            "cap": "29015",
                            "nome": "Castel San Giovanni"
                        },
                        {
                            "code": "033012",
                            "cap": "29014",
                            "nome": "Castell'Arquato"
                        },
                        {
                            "code": "033014",
                            "cap": "29010",
                            "nome": "Castelvetro Piacentino"
                        },
                        {
                            "code": "033015",
                            "cap": "29020",
                            "nome": "Cerignale"
                        },
                        {
                            "code": "033016",
                            "cap": "29020",
                            "nome": "Coli"
                        },
                        {
                            "code": "033017",
                            "cap": "29020",
                            "nome": "Corte Brugnatella"
                        },
                        {
                            "code": "033018",
                            "cap": "29016",
                            "nome": "Cortemaggiore"
                        },
                        {
                            "code": "033019",
                            "cap": "29023",
                            "nome": "Farini"
                        },
                        {
                            "code": "033020",
                            "cap": "29024",
                            "nome": "Ferriere"
                        },
                        {
                            "code": "033021",
                            "cap": "29017",
                            "nome": "Fiorenzuola d'Arda"
                        },
                        {
                            "code": "033022",
                            "cap": "29010",
                            "nome": "Gazzola"
                        },
                        {
                            "code": "033023",
                            "cap": "29020",
                            "nome": "Gossolengo"
                        },
                        {
                            "code": "033024",
                            "cap": "29010",
                            "nome": "Gragnano Trebbiense"
                        },
                        {
                            "code": "033025",
                            "cap": "29025",
                            "nome": "Gropparello"
                        },
                        {
                            "code": "033026",
                            "cap": "29018",
                            "nome": "Lugagnano Val D'Arda"
                        },
                        {
                            "code": "033027",
                            "cap": "29010",
                            "nome": "Monticelli d'Ongina"
                        },
                        {
                            "code": "033028",
                            "cap": "29020",
                            "nome": "Morfasso"
                        },
                        {
                            "code": "033029",
                            "cap": "29010",
                            "nome": "Nibbiano"
                        },
                        {
                            "code": "033030",
                            "cap": "29026",
                            "nome": "Ottone"
                        },
                        {
                            "code": "033031",
                            "cap": "29010",
                            "nome": "Pecorara"
                        },
                        {
                            "code": "033032",
                            "cap": "2912x",
                            "nome": "Piacenza"
                        },
                        {
                            "code": "033033",
                            "cap": "29010",
                            "nome": "Pianello Val Tidone"
                        },
                        {
                            "code": "033034",
                            "cap": "29010",
                            "nome": "Piozzano"
                        },
                        {
                            "code": "033035",
                            "cap": "29027",
                            "nome": "Podenzano"
                        },
                        {
                            "code": "033036",
                            "cap": "29028",
                            "nome": "Ponte dell'Olio"
                        },
                        {
                            "code": "033037",
                            "cap": "29010",
                            "nome": "Pontenure"
                        },
                        {
                            "code": "033038",
                            "cap": "29029",
                            "nome": "Rivergaro"
                        },
                        {
                            "code": "033039",
                            "cap": "29010",
                            "nome": "Rottofreno"
                        },
                        {
                            "code": "033040",
                            "cap": "29019",
                            "nome": "San Giorgio Piacentino"
                        },
                        {
                            "code": "033041",
                            "cap": "29010",
                            "nome": "San Pietro in Cerro"
                        },
                        {
                            "code": "033042",
                            "cap": "29010",
                            "nome": "Sarmato"
                        },
                        {
                            "code": "033043",
                            "cap": "29020",
                            "nome": "Travo"
                        },
                        {
                            "code": "033044",
                            "cap": "29010",
                            "nome": "Vernasca"
                        },
                        {
                            "code": "033045",
                            "cap": "29020",
                            "nome": "Vigolzone"
                        },
                        {
                            "code": "033046",
                            "cap": "29010",
                            "nome": "Villanova sull'Arda"
                        },
                        {
                            "code": "033047",
                            "cap": "29020",
                            "nome": "Zerba"
                        },
                        {
                            "code": "033048",
                            "cap": "29010",
                            "nome": "Ziano Piacentino"
                        }
                    ],
                    "nome": "Piacenza"
                },
                {
                    "code": "RA",
                    "comuni": [
                        {
                            "code": "039001",
                            "cap": "48011",
                            "nome": "Alfonsine"
                        },
                        {
                            "code": "039002",
                            "cap": "48012",
                            "nome": "Bagnacavallo"
                        },
                        {
                            "code": "039003",
                            "cap": "48010",
                            "nome": "Bagnara di Romagna"
                        },
                        {
                            "code": "039004",
                            "cap": "48013",
                            "nome": "Brisighella"
                        },
                        {
                            "code": "039005",
                            "cap": "48010",
                            "nome": "Casola Valsenio"
                        },
                        {
                            "code": "039006",
                            "cap": "48014",
                            "nome": "Castel Bolognese"
                        },
                        {
                            "code": "039007",
                            "cap": "48015",
                            "nome": "Cervia"
                        },
                        {
                            "code": "039008",
                            "cap": "48017",
                            "nome": "Conselice"
                        },
                        {
                            "code": "039009",
                            "cap": "48010",
                            "nome": "Cotignola"
                        },
                        {
                            "code": "039010",
                            "cap": "48018",
                            "nome": "Faenza"
                        },
                        {
                            "code": "039011",
                            "cap": "48010",
                            "nome": "Fusignano"
                        },
                        {
                            "code": "039012",
                            "cap": "48022",
                            "nome": "Lugo"
                        },
                        {
                            "code": "039013",
                            "cap": "48024",
                            "nome": "Massa Lombarda"
                        },
                        {
                            "code": "039014",
                            "cap": "4812x",
                            "nome": "Ravenna"
                        },
                        {
                            "code": "039015",
                            "cap": "48025",
                            "nome": "Riolo Terme"
                        },
                        {
                            "code": "039016",
                            "cap": "48026",
                            "nome": "Russi"
                        },
                        {
                            "code": "039017",
                            "cap": "48020",
                            "nome": "Sant'Agata sul Santerno"
                        },
                        {
                            "code": "039018",
                            "cap": "48027",
                            "nome": "Solarolo"
                        }
                    ],
                    "nome": "Ravenna"
                },
                {
                    "code": "RE",
                    "comuni": [
                        {
                            "code": "035001",
                            "cap": "42020",
                            "nome": "Albinea"
                        },
                        {
                            "code": "035002",
                            "cap": "42011",
                            "nome": "Bagnolo in Piano"
                        },
                        {
                            "code": "035003",
                            "cap": "42031",
                            "nome": "Baiso"
                        },
                        {
                            "code": "035004",
                            "cap": "42021",
                            "nome": "Bibbiano"
                        },
                        {
                            "code": "035005",
                            "cap": "42022",
                            "nome": "Boretto"
                        },
                        {
                            "code": "035006",
                            "cap": "42041",
                            "nome": "Brescello"
                        },
                        {
                            "code": "035007",
                            "cap": "42032",
                            "nome": "Busana"
                        },
                        {
                            "code": "035008",
                            "cap": "42023",
                            "nome": "Cadelbosco di Sopra"
                        },
                        {
                            "code": "035009",
                            "cap": "42012",
                            "nome": "Campagnola Emilia"
                        },
                        {
                            "code": "035010",
                            "cap": "42040",
                            "nome": "Campegine"
                        },
                        {
                            "code": "035018",
                            "cap": "42026",
                            "nome": "Canossa"
                        },
                        {
                            "code": "035011",
                            "cap": "42033",
                            "nome": "Carpineti"
                        },
                        {
                            "code": "035012",
                            "cap": "42013",
                            "nome": "Casalgrande"
                        },
                        {
                            "code": "035013",
                            "cap": "42034",
                            "nome": "Casina"
                        },
                        {
                            "code": "035014",
                            "cap": "42014",
                            "nome": "Castellarano"
                        },
                        {
                            "code": "035015",
                            "cap": "42024",
                            "nome": "Castelnovo di Sotto"
                        },
                        {
                            "code": "035016",
                            "cap": "42035",
                            "nome": "Castelnovo ne' Monti"
                        },
                        {
                            "code": "035017",
                            "cap": "42025",
                            "nome": "Cavriago"
                        },
                        {
                            "code": "035019",
                            "cap": "42037",
                            "nome": "Collagna"
                        },
                        {
                            "code": "035020",
                            "cap": "42015",
                            "nome": "Correggio"
                        },
                        {
                            "code": "035021",
                            "cap": "42042",
                            "nome": "Fabbrico"
                        },
                        {
                            "code": "035022",
                            "cap": "42043",
                            "nome": "Gattatico"
                        },
                        {
                            "code": "035023",
                            "cap": "42044",
                            "nome": "Gualtieri"
                        },
                        {
                            "code": "035024",
                            "cap": "42016",
                            "nome": "Guastalla"
                        },
                        {
                            "code": "035025",
                            "cap": "42039",
                            "nome": "Ligonchio"
                        },
                        {
                            "code": "035026",
                            "cap": "42045",
                            "nome": "Luzzara"
                        },
                        {
                            "code": "035027",
                            "cap": "42027",
                            "nome": "Montecchio Emilia"
                        },
                        {
                            "code": "035028",
                            "cap": "42017",
                            "nome": "Novellara"
                        },
                        {
                            "code": "035029",
                            "cap": "42028",
                            "nome": "Poviglio"
                        },
                        {
                            "code": "035030",
                            "cap": "42020",
                            "nome": "Quattro Castella"
                        },
                        {
                            "code": "035031",
                            "cap": "42030",
                            "nome": "Ramiseto"
                        },
                        {
                            "code": "035033",
                            "cap": "4212x",
                            "nome": "Reggio Emilia"
                        },
                        {
                            "code": "035032",
                            "cap": "42046",
                            "nome": "Reggiolo"
                        },
                        {
                            "code": "035034",
                            "cap": "42010",
                            "nome": "Rio Saliceto"
                        },
                        {
                            "code": "035035",
                            "cap": "42047",
                            "nome": "Rolo"
                        },
                        {
                            "code": "035036",
                            "cap": "42048",
                            "nome": "Rubiera"
                        },
                        {
                            "code": "035037",
                            "cap": "42018",
                            "nome": "San Martino in Rio"
                        },
                        {
                            "code": "035038",
                            "cap": "42020",
                            "nome": "San Polo d'Enza"
                        },
                        {
                            "code": "035039",
                            "cap": "42049",
                            "nome": "Sant'Ilario d'Enza"
                        },
                        {
                            "code": "035040",
                            "cap": "42019",
                            "nome": "Scandiano"
                        },
                        {
                            "code": "035041",
                            "cap": "42010",
                            "nome": "Toano"
                        },
                        {
                            "code": "035042",
                            "cap": "42020",
                            "nome": "Vetto"
                        },
                        {
                            "code": "035043",
                            "cap": "42030",
                            "nome": "Vezzano sul Crostolo"
                        },
                        {
                            "code": "035044",
                            "cap": "42030",
                            "nome": "Viano"
                        },
                        {
                            "code": "035045",
                            "cap": "42030",
                            "nome": "Villa Minozzo"
                        }
                    ],
                    "nome": "Reggio Emilia"
                },
                {
                    "code": "RN",
                    "comuni": [
                        {
                            "code": "099001",
                            "cap": "47814",
                            "nome": "Bellaria-Igea Marina"
                        },
                        {
                            "code": "099021",
                            "cap": "47861",
                            "nome": "Casteldelci"
                        },
                        {
                            "code": "099002",
                            "cap": "47841",
                            "nome": "Cattolica"
                        },
                        {
                            "code": "099003",
                            "cap": "47853",
                            "nome": "Coriano"
                        },
                        {
                            "code": "099004",
                            "cap": "47855",
                            "nome": "Gemmano"
                        },
                        {
                            "code": "099022",
                            "cap": "47862",
                            "nome": "Maiolo"
                        },
                        {
                            "code": "099005",
                            "cap": "47843",
                            "nome": "Misano Adriatico"
                        },
                        {
                            "code": "099006",
                            "cap": "47836",
                            "nome": "Mondaino"
                        },
                        {
                            "code": "099007",
                            "cap": "47854",
                            "nome": "Monte Colombo"
                        },
                        {
                            "code": "099008",
                            "cap": "47834",
                            "nome": "Montefiore Conca"
                        },
                        {
                            "code": "099009",
                            "cap": "47837",
                            "nome": "Montegridolfo"
                        },
                        {
                            "code": "099010",
                            "cap": "47854",
                            "nome": "Montescudo"
                        },
                        {
                            "code": "099011",
                            "cap": "47833",
                            "nome": "Morciano di Romagna"
                        },
                        {
                            "code": "099023",
                            "cap": "47863",
                            "nome": "Novafeltria"
                        },
                        {
                            "code": "099024",
                            "cap": "47864",
                            "nome": "Pennabilli"
                        },
                        {
                            "code": "099012",
                            "cap": "47824",
                            "nome": "Poggio Berni"
                        },
                        {
                            "code": "099013",
                            "cap": "47838",
                            "nome": "Riccione"
                        },
                        {
                            "code": "099014",
                            "cap": "479xx",
                            "nome": "Rimini"
                        },
                        {
                            "code": "099015",
                            "cap": "47835",
                            "nome": "Saludecio"
                        },
                        {
                            "code": "099016",
                            "cap": "47832",
                            "nome": "San Clemente"
                        },
                        {
                            "code": "099017",
                            "cap": "47842",
                            "nome": "San Giovanni in Marignano"
                        },
                        {
                            "code": "099025",
                            "cap": "47865",
                            "nome": "San Leo"
                        },
                        {
                            "code": "099026",
                            "cap": "47866",
                            "nome": "Sant'Agata Feltria"
                        },
                        {
                            "code": "099018",
                            "cap": "47822",
                            "nome": "Santarcangelo di Romagna"
                        },
                        {
                            "code": "099027",
                            "cap": "47867",
                            "nome": "Talamello"
                        },
                        {
                            "code": "099019",
                            "cap": "47825",
                            "nome": "Torriana"
                        },
                        {
                            "code": "099020",
                            "cap": "47826",
                            "nome": "Verucchio"
                        }
                    ],
                    "nome": "Rimini"
                }
            ],
            "nome": "Emilia-Romagna"
        },
        {
            "province": [
                {
                    "code": "GO",
                    "comuni": [
                        {
                            "code": "031001",
                            "cap": "34070",
                            "nome": "Capriva del Friuli"
                        },
                        {
                            "code": "031002",
                            "cap": "34071",
                            "nome": "Cormons"
                        },
                        {
                            "code": "031003",
                            "cap": "34070",
                            "nome": "Doberd\u00f2 del Lago"
                        },
                        {
                            "code": "031004",
                            "cap": "34070",
                            "nome": "Dolegna del Collio"
                        },
                        {
                            "code": "031005",
                            "cap": "34072",
                            "nome": "Farra d'Isonzo"
                        },
                        {
                            "code": "031006",
                            "cap": "34070",
                            "nome": "Fogliano Redipuglia"
                        },
                        {
                            "code": "031007",
                            "cap": "34170",
                            "nome": "Gorizia"
                        },
                        {
                            "code": "031008",
                            "cap": "34072",
                            "nome": "Gradisca d'Isonzo"
                        },
                        {
                            "code": "031009",
                            "cap": "34073",
                            "nome": "Grado"
                        },
                        {
                            "code": "031010",
                            "cap": "34070",
                            "nome": "Mariano del Friuli"
                        },
                        {
                            "code": "031011",
                            "cap": "34076",
                            "nome": "Medea"
                        },
                        {
                            "code": "031012",
                            "cap": "34074",
                            "nome": "Monfalcone"
                        },
                        {
                            "code": "031013",
                            "cap": "34070",
                            "nome": "Moraro"
                        },
                        {
                            "code": "031014",
                            "cap": "34070",
                            "nome": "Mossa"
                        },
                        {
                            "code": "031015",
                            "cap": "34076",
                            "nome": "Romans d'Isonzo"
                        },
                        {
                            "code": "031016",
                            "cap": "34077",
                            "nome": "Ronchi dei Legionari"
                        },
                        {
                            "code": "031017",
                            "cap": "34078",
                            "nome": "Sagrado"
                        },
                        {
                            "code": "031018",
                            "cap": "34075",
                            "nome": "San Canzian d'Isonzo"
                        },
                        {
                            "code": "031019",
                            "cap": "34070",
                            "nome": "San Floriano del Collio"
                        },
                        {
                            "code": "031020",
                            "cap": "34070",
                            "nome": "San Lorenzo Isontino"
                        },
                        {
                            "code": "031021",
                            "cap": "34070",
                            "nome": "San Pier d'Isonzo"
                        },
                        {
                            "code": "031022",
                            "cap": "34070",
                            "nome": "Savogna d'Isonzo"
                        },
                        {
                            "code": "031023",
                            "cap": "34079",
                            "nome": "Staranzano"
                        },
                        {
                            "code": "031024",
                            "cap": "34070",
                            "nome": "Turriaco"
                        },
                        {
                            "code": "031025",
                            "cap": "34070",
                            "nome": "Villesse"
                        }
                    ],
                    "nome": "Gorizia"
                },
                {
                    "code": "PN",
                    "comuni": [
                        {
                            "code": "093001",
                            "cap": "33080",
                            "nome": "Andreis"
                        },
                        {
                            "code": "093002",
                            "cap": "33090",
                            "nome": "Arba"
                        },
                        {
                            "code": "093003",
                            "cap": "33098",
                            "nome": "Arzene"
                        },
                        {
                            "code": "093004",
                            "cap": "33081",
                            "nome": "Aviano"
                        },
                        {
                            "code": "093005",
                            "cap": "33082",
                            "nome": "Azzano Decimo"
                        },
                        {
                            "code": "093006",
                            "cap": "33080",
                            "nome": "Barcis"
                        },
                        {
                            "code": "093007",
                            "cap": "33070",
                            "nome": "Brugnera"
                        },
                        {
                            "code": "093008",
                            "cap": "33070",
                            "nome": "Budoia"
                        },
                        {
                            "code": "093009",
                            "cap": "33070",
                            "nome": "Caneva"
                        },
                        {
                            "code": "093010",
                            "cap": "33072",
                            "nome": "Casarsa della Delizia"
                        },
                        {
                            "code": "093011",
                            "cap": "33090",
                            "nome": "Castelnovo del Friuli"
                        },
                        {
                            "code": "093012",
                            "cap": "33092",
                            "nome": "Cavasso Nuovo"
                        },
                        {
                            "code": "093013",
                            "cap": "33083",
                            "nome": "Chions"
                        },
                        {
                            "code": "093014",
                            "cap": "33080",
                            "nome": "Cimolais"
                        },
                        {
                            "code": "093015",
                            "cap": "33080",
                            "nome": "Claut"
                        },
                        {
                            "code": "093016",
                            "cap": "33090",
                            "nome": "Clauzetto"
                        },
                        {
                            "code": "093017",
                            "cap": "33084",
                            "nome": "Cordenons"
                        },
                        {
                            "code": "093018",
                            "cap": "33075",
                            "nome": "Cordovado"
                        },
                        {
                            "code": "093019",
                            "cap": "33080",
                            "nome": "Erto e Casso"
                        },
                        {
                            "code": "093020",
                            "cap": "33092",
                            "nome": "Fanna"
                        },
                        {
                            "code": "093021",
                            "cap": "33080",
                            "nome": "Fiume Veneto"
                        },
                        {
                            "code": "093022",
                            "cap": "33074",
                            "nome": "Fontanafredda"
                        },
                        {
                            "code": "093024",
                            "cap": "33080",
                            "nome": "Frisanco"
                        },
                        {
                            "code": "093025",
                            "cap": "33085",
                            "nome": "Maniago"
                        },
                        {
                            "code": "093026",
                            "cap": "33092",
                            "nome": "Meduno"
                        },
                        {
                            "code": "093027",
                            "cap": "33086",
                            "nome": "Montereale Valcellina"
                        },
                        {
                            "code": "093028",
                            "cap": "33075",
                            "nome": "Morsano al Tagliamento"
                        },
                        {
                            "code": "093029",
                            "cap": "33087",
                            "nome": "Pasiano di Pordenone"
                        },
                        {
                            "code": "093030",
                            "cap": "33094",
                            "nome": "Pinzano al Tagliamento"
                        },
                        {
                            "code": "093031",
                            "cap": "33070",
                            "nome": "Polcenigo"
                        },
                        {
                            "code": "093032",
                            "cap": "33080",
                            "nome": "Porcia"
                        },
                        {
                            "code": "093033",
                            "cap": "33170",
                            "nome": "Pordenone"
                        },
                        {
                            "code": "093034",
                            "cap": "33080",
                            "nome": "Prata di Pordenone"
                        },
                        {
                            "code": "093035",
                            "cap": "33076",
                            "nome": "Pravisdomini"
                        },
                        {
                            "code": "093036",
                            "cap": "33080",
                            "nome": "Roveredo in Piano"
                        },
                        {
                            "code": "093037",
                            "cap": "33077",
                            "nome": "Sacile"
                        },
                        {
                            "code": "093038",
                            "cap": "33095",
                            "nome": "San Giorgio della Richinvelda"
                        },
                        {
                            "code": "093039",
                            "cap": "33098",
                            "nome": "San Martino al Tagliamento"
                        },
                        {
                            "code": "093040",
                            "cap": "33080",
                            "nome": "San Quirino"
                        },
                        {
                            "code": "093041",
                            "cap": "33078",
                            "nome": "San Vito al Tagliamento"
                        },
                        {
                            "code": "093042",
                            "cap": "33090",
                            "nome": "Sequals"
                        },
                        {
                            "code": "093043",
                            "cap": "33079",
                            "nome": "Sesto al Reghena"
                        },
                        {
                            "code": "093044",
                            "cap": "33097",
                            "nome": "Spilimbergo"
                        },
                        {
                            "code": "093045",
                            "cap": "33090",
                            "nome": "Tramonti di Sopra"
                        },
                        {
                            "code": "093046",
                            "cap": "33090",
                            "nome": "Tramonti di Sotto"
                        },
                        {
                            "code": "093047",
                            "cap": "33090",
                            "nome": "Travesio"
                        },
                        {
                            "code": "093052",
                            "cap": "33080",
                            "nome": "Vajont"
                        },
                        {
                            "code": "093048",
                            "cap": "33098",
                            "nome": "Valvasone"
                        },
                        {
                            "code": "093049",
                            "cap": "33090",
                            "nome": "Vito d'Asio"
                        },
                        {
                            "code": "093050",
                            "cap": "33099",
                            "nome": "Vivaro"
                        },
                        {
                            "code": "093051",
                            "cap": "33080",
                            "nome": "Zoppola"
                        }
                    ],
                    "nome": "Pordenone"
                },
                {
                    "code": "TS",
                    "comuni": [
                        {
                            "code": "032001",
                            "cap": "34011",
                            "nome": "Duino-Aurisina"
                        },
                        {
                            "code": "032002",
                            "cap": "34016",
                            "nome": "Monrupino"
                        },
                        {
                            "code": "032003",
                            "cap": "34015",
                            "nome": "Muggia"
                        },
                        {
                            "code": "032004",
                            "cap": "34018",
                            "nome": "San Dorligo della Valle - Dolina"
                        },
                        {
                            "code": "032005",
                            "cap": "34010",
                            "nome": "Sgonico"
                        },
                        {
                            "code": "032006",
                            "cap": "341xx",
                            "nome": "Trieste"
                        }
                    ],
                    "nome": "Trieste"
                },
                {
                    "code": "UD",
                    "comuni": [
                        {
                            "code": "030001",
                            "cap": "33041",
                            "nome": "Aiello del Friuli"
                        },
                        {
                            "code": "030002",
                            "cap": "33020",
                            "nome": "Amaro"
                        },
                        {
                            "code": "030003",
                            "cap": "33021",
                            "nome": "Ampezzo"
                        },
                        {
                            "code": "030004",
                            "cap": "33051",
                            "nome": "Aquileia"
                        },
                        {
                            "code": "030005",
                            "cap": "33022",
                            "nome": "Arta Terme"
                        },
                        {
                            "code": "030006",
                            "cap": "33011",
                            "nome": "Artegna"
                        },
                        {
                            "code": "030007",
                            "cap": "33040",
                            "nome": "Attimis"
                        },
                        {
                            "code": "030008",
                            "cap": "33050",
                            "nome": "Bagnaria Arsa"
                        },
                        {
                            "code": "030009",
                            "cap": "33031",
                            "nome": "Basiliano"
                        },
                        {
                            "code": "030010",
                            "cap": "33032",
                            "nome": "Bertiolo"
                        },
                        {
                            "code": "030011",
                            "cap": "33050",
                            "nome": "Bicinicco"
                        },
                        {
                            "code": "030012",
                            "cap": "33010",
                            "nome": "Bordano"
                        },
                        {
                            "code": "030013",
                            "cap": "33030",
                            "nome": "Buja"
                        },
                        {
                            "code": "030014",
                            "cap": "33042",
                            "nome": "Buttrio"
                        },
                        {
                            "code": "030015",
                            "cap": "33030",
                            "nome": "Camino al Tagliamento"
                        },
                        {
                            "code": "030016",
                            "cap": "33030",
                            "nome": "Campoformido"
                        },
                        {
                            "code": "030138",
                            "cap": "33040",
                            "nome": "Campolongo Tapogliano"
                        },
                        {
                            "code": "030018",
                            "cap": "33050",
                            "nome": "Carlino"
                        },
                        {
                            "code": "030019",
                            "cap": "33010",
                            "nome": "Cassacco"
                        },
                        {
                            "code": "030020",
                            "cap": "33050",
                            "nome": "Castions di Strada"
                        },
                        {
                            "code": "030021",
                            "cap": "33020",
                            "nome": "Cavazzo Carnico"
                        },
                        {
                            "code": "030022",
                            "cap": "33020",
                            "nome": "Cercivento"
                        },
                        {
                            "code": "030023",
                            "cap": "33052",
                            "nome": "Cervignano del Friuli"
                        },
                        {
                            "code": "030024",
                            "cap": "33048",
                            "nome": "Chiopris-Viscone"
                        },
                        {
                            "code": "030025",
                            "cap": "33010",
                            "nome": "Chiusaforte"
                        },
                        {
                            "code": "030026",
                            "cap": "33043",
                            "nome": "Cividale del Friuli"
                        },
                        {
                            "code": "030027",
                            "cap": "33033",
                            "nome": "Codroipo"
                        },
                        {
                            "code": "030028",
                            "cap": "33010",
                            "nome": "Colloredo di Monte Albano"
                        },
                        {
                            "code": "030029",
                            "cap": "33023",
                            "nome": "Comeglians"
                        },
                        {
                            "code": "030030",
                            "cap": "33040",
                            "nome": "Corno di Rosazzo"
                        },
                        {
                            "code": "030031",
                            "cap": "33030",
                            "nome": "Coseano"
                        },
                        {
                            "code": "030032",
                            "cap": "33030",
                            "nome": "Dignano"
                        },
                        {
                            "code": "030033",
                            "cap": "33010",
                            "nome": "Dogna"
                        },
                        {
                            "code": "030034",
                            "cap": "33040",
                            "nome": "Drenchia"
                        },
                        {
                            "code": "030035",
                            "cap": "33020",
                            "nome": "Enemonzo"
                        },
                        {
                            "code": "030036",
                            "cap": "33040",
                            "nome": "Faedis"
                        },
                        {
                            "code": "030037",
                            "cap": "33034",
                            "nome": "Fagagna"
                        },
                        {
                            "code": "030038",
                            "cap": "33050",
                            "nome": "Fiumicello"
                        },
                        {
                            "code": "030039",
                            "cap": "33030",
                            "nome": "Flaibano"
                        },
                        {
                            "code": "030137",
                            "cap": "33030",
                            "nome": "Forgaria nel Friuli"
                        },
                        {
                            "code": "030040",
                            "cap": "33020",
                            "nome": "Forni Avoltri"
                        },
                        {
                            "code": "030041",
                            "cap": "33024",
                            "nome": "Forni di Sopra"
                        },
                        {
                            "code": "030042",
                            "cap": "33020",
                            "nome": "Forni di Sotto"
                        },
                        {
                            "code": "030043",
                            "cap": "33013",
                            "nome": "Gemona del Friuli"
                        },
                        {
                            "code": "030044",
                            "cap": "33050",
                            "nome": "Gonars"
                        },
                        {
                            "code": "030045",
                            "cap": "33040",
                            "nome": "Grimacco"
                        },
                        {
                            "code": "030046",
                            "cap": "33053",
                            "nome": "Latisana"
                        },
                        {
                            "code": "030047",
                            "cap": "33029",
                            "nome": "Lauco"
                        },
                        {
                            "code": "030048",
                            "cap": "33050",
                            "nome": "Lestizza"
                        },
                        {
                            "code": "030049",
                            "cap": "33054",
                            "nome": "Lignano Sabbiadoro"
                        },
                        {
                            "code": "030050",
                            "cap": "33020",
                            "nome": "Ligosullo"
                        },
                        {
                            "code": "030051",
                            "cap": "33010",
                            "nome": "Lusevera"
                        },
                        {
                            "code": "030052",
                            "cap": "33010",
                            "nome": "Magnano in Riviera"
                        },
                        {
                            "code": "030053",
                            "cap": "33030",
                            "nome": "Majano"
                        },
                        {
                            "code": "030054",
                            "cap": "33010",
                            "nome": "Malborghetto Valbruna"
                        },
                        {
                            "code": "030055",
                            "cap": "33044",
                            "nome": "Manzano"
                        },
                        {
                            "code": "030056",
                            "cap": "33050",
                            "nome": "Marano Lagunare"
                        },
                        {
                            "code": "030057",
                            "cap": "33035",
                            "nome": "Martignacco"
                        },
                        {
                            "code": "030058",
                            "cap": "33036",
                            "nome": "Mereto di Tomba"
                        },
                        {
                            "code": "030059",
                            "cap": "33015",
                            "nome": "Moggio Udinese"
                        },
                        {
                            "code": "030060",
                            "cap": "33040",
                            "nome": "Moimacco"
                        },
                        {
                            "code": "030061",
                            "cap": "33010",
                            "nome": "Montenars"
                        },
                        {
                            "code": "030062",
                            "cap": "33050",
                            "nome": "Mortegliano"
                        },
                        {
                            "code": "030063",
                            "cap": "33030",
                            "nome": "Moruzzo"
                        },
                        {
                            "code": "030064",
                            "cap": "33055",
                            "nome": "Muzzana del Turgnano"
                        },
                        {
                            "code": "030065",
                            "cap": "33045",
                            "nome": "Nimis"
                        },
                        {
                            "code": "030066",
                            "cap": "33010",
                            "nome": "Osoppo"
                        },
                        {
                            "code": "030067",
                            "cap": "33025",
                            "nome": "Ovaro"
                        },
                        {
                            "code": "030068",
                            "cap": "33010",
                            "nome": "Pagnacco"
                        },
                        {
                            "code": "030069",
                            "cap": "33056",
                            "nome": "Palazzolo dello Stella"
                        },
                        {
                            "code": "030070",
                            "cap": "33057",
                            "nome": "Palmanova"
                        },
                        {
                            "code": "030071",
                            "cap": "33026",
                            "nome": "Paluzza"
                        },
                        {
                            "code": "030072",
                            "cap": "33037",
                            "nome": "Pasian di Prato"
                        },
                        {
                            "code": "030073",
                            "cap": "33027",
                            "nome": "Paularo"
                        },
                        {
                            "code": "030074",
                            "cap": "33050",
                            "nome": "Pavia di Udine"
                        },
                        {
                            "code": "030075",
                            "cap": "33050",
                            "nome": "Pocenia"
                        },
                        {
                            "code": "030076",
                            "cap": "33016",
                            "nome": "Pontebba"
                        },
                        {
                            "code": "030077",
                            "cap": "33050",
                            "nome": "Porpetto"
                        },
                        {
                            "code": "030078",
                            "cap": "33040",
                            "nome": "Povoletto"
                        },
                        {
                            "code": "030079",
                            "cap": "33050",
                            "nome": "Pozzuolo del Friuli"
                        },
                        {
                            "code": "030080",
                            "cap": "33040",
                            "nome": "Pradamano"
                        },
                        {
                            "code": "030081",
                            "cap": "33020",
                            "nome": "Prato Carnico"
                        },
                        {
                            "code": "030082",
                            "cap": "33050",
                            "nome": "Precenicco"
                        },
                        {
                            "code": "030083",
                            "cap": "33040",
                            "nome": "Premariacco"
                        },
                        {
                            "code": "030084",
                            "cap": "33020",
                            "nome": "Preone"
                        },
                        {
                            "code": "030085",
                            "cap": "33040",
                            "nome": "Prepotto"
                        },
                        {
                            "code": "030086",
                            "cap": "33046",
                            "nome": "Pulfero"
                        },
                        {
                            "code": "030087",
                            "cap": "33030",
                            "nome": "Ragogna"
                        },
                        {
                            "code": "030088",
                            "cap": "33020",
                            "nome": "Ravascletto"
                        },
                        {
                            "code": "030089",
                            "cap": "33029",
                            "nome": "Raveo"
                        },
                        {
                            "code": "030090",
                            "cap": "33010",
                            "nome": "Reana del Rojale"
                        },
                        {
                            "code": "030091",
                            "cap": "33047",
                            "nome": "Remanzacco"
                        },
                        {
                            "code": "030092",
                            "cap": "33010",
                            "nome": "Resia"
                        },
                        {
                            "code": "030093",
                            "cap": "33010",
                            "nome": "Resiutta"
                        },
                        {
                            "code": "030094",
                            "cap": "33020",
                            "nome": "Rigolato"
                        },
                        {
                            "code": "030095",
                            "cap": "33030",
                            "nome": "Rive D'Arcano"
                        },
                        {
                            "code": "030096",
                            "cap": "33050",
                            "nome": "Rivignano"
                        },
                        {
                            "code": "030097",
                            "cap": "33050",
                            "nome": "Ronchis"
                        },
                        {
                            "code": "030098",
                            "cap": "33050",
                            "nome": "Ruda"
                        },
                        {
                            "code": "030099",
                            "cap": "33038",
                            "nome": "San Daniele del Friuli"
                        },
                        {
                            "code": "030100",
                            "cap": "33058",
                            "nome": "San Giorgio di Nogaro"
                        },
                        {
                            "code": "030101",
                            "cap": "33048",
                            "nome": "San Giovanni al Natisone"
                        },
                        {
                            "code": "030102",
                            "cap": "33040",
                            "nome": "San Leonardo"
                        },
                        {
                            "code": "030103",
                            "cap": "33049",
                            "nome": "San Pietro al Natisone"
                        },
                        {
                            "code": "030105",
                            "cap": "33050",
                            "nome": "San Vito al Torre"
                        },
                        {
                            "code": "030106",
                            "cap": "33030",
                            "nome": "San Vito di Fagagna"
                        },
                        {
                            "code": "030104",
                            "cap": "33050",
                            "nome": "Santa Maria La Longa"
                        },
                        {
                            "code": "030107",
                            "cap": "33020",
                            "nome": "Sauris"
                        },
                        {
                            "code": "030108",
                            "cap": "33040",
                            "nome": "Savogna"
                        },
                        {
                            "code": "030109",
                            "cap": "33039",
                            "nome": "Sedegliano"
                        },
                        {
                            "code": "030110",
                            "cap": "33020",
                            "nome": "Socchieve"
                        },
                        {
                            "code": "030111",
                            "cap": "33040",
                            "nome": "Stregna"
                        },
                        {
                            "code": "030112",
                            "cap": "33020",
                            "nome": "Sutrio"
                        },
                        {
                            "code": "030113",
                            "cap": "33040",
                            "nome": "Taipana"
                        },
                        {
                            "code": "030114",
                            "cap": "33030",
                            "nome": "Talmassons"
                        },
                        {
                            "code": "030116",
                            "cap": "33017",
                            "nome": "Tarcento"
                        },
                        {
                            "code": "030117",
                            "cap": "33018",
                            "nome": "Tarvisio"
                        },
                        {
                            "code": "030118",
                            "cap": "33010",
                            "nome": "Tavagnacco"
                        },
                        {
                            "code": "030119",
                            "cap": "33050",
                            "nome": "Teor"
                        },
                        {
                            "code": "030120",
                            "cap": "33050",
                            "nome": "Terzo d'Aquileia"
                        },
                        {
                            "code": "030121",
                            "cap": "33028",
                            "nome": "Tolmezzo"
                        },
                        {
                            "code": "030122",
                            "cap": "33040",
                            "nome": "Torreano"
                        },
                        {
                            "code": "030123",
                            "cap": "33050",
                            "nome": "Torviscosa"
                        },
                        {
                            "code": "030124",
                            "cap": "33010",
                            "nome": "Trasaghis"
                        },
                        {
                            "code": "030125",
                            "cap": "33020",
                            "nome": "Treppo Carnico"
                        },
                        {
                            "code": "030126",
                            "cap": "33010",
                            "nome": "Treppo Grande"
                        },
                        {
                            "code": "030127",
                            "cap": "33019",
                            "nome": "Tricesimo"
                        },
                        {
                            "code": "030128",
                            "cap": "33050",
                            "nome": "Trivignano Udinese"
                        },
                        {
                            "code": "030129",
                            "cap": "33100",
                            "nome": "Udine"
                        },
                        {
                            "code": "030130",
                            "cap": "33030",
                            "nome": "Varmo"
                        },
                        {
                            "code": "030131",
                            "cap": "33010",
                            "nome": "Venzone"
                        },
                        {
                            "code": "030132",
                            "cap": "33020",
                            "nome": "Verzegnis"
                        },
                        {
                            "code": "030133",
                            "cap": "33029",
                            "nome": "Villa Santina"
                        },
                        {
                            "code": "030134",
                            "cap": "33059",
                            "nome": "Villa Vicentina"
                        },
                        {
                            "code": "030135",
                            "cap": "33040",
                            "nome": "Visco"
                        },
                        {
                            "code": "030136",
                            "cap": "33020",
                            "nome": "Zuglio"
                        }
                    ],
                    "nome": "Udine"
                }
            ],
            "nome": "Friuli-Venezia Giulia"
        },
        {
            "province": [
                {
                    "code": "FR",
                    "comuni": [
                        {
                            "code": "060001",
                            "cap": "03040",
                            "nome": "Acquafondata"
                        },
                        {
                            "code": "060002",
                            "cap": "03010",
                            "nome": "Acuto"
                        },
                        {
                            "code": "060003",
                            "cap": "03011",
                            "nome": "Alatri"
                        },
                        {
                            "code": "060004",
                            "cap": "03041",
                            "nome": "Alvito"
                        },
                        {
                            "code": "060005",
                            "cap": "03021",
                            "nome": "Amaseno"
                        },
                        {
                            "code": "060006",
                            "cap": "03012",
                            "nome": "Anagni"
                        },
                        {
                            "code": "060007",
                            "cap": "03031",
                            "nome": "Aquino"
                        },
                        {
                            "code": "060008",
                            "cap": "03032",
                            "nome": "Arce"
                        },
                        {
                            "code": "060009",
                            "cap": "03020",
                            "nome": "Arnara"
                        },
                        {
                            "code": "060010",
                            "cap": "03033",
                            "nome": "Arpino"
                        },
                        {
                            "code": "060011",
                            "cap": "03042",
                            "nome": "Atina"
                        },
                        {
                            "code": "060012",
                            "cap": "03040",
                            "nome": "Ausonia"
                        },
                        {
                            "code": "060013",
                            "cap": "03040",
                            "nome": "Belmonte Castello"
                        },
                        {
                            "code": "060014",
                            "cap": "03022",
                            "nome": "Boville Ernica"
                        },
                        {
                            "code": "060015",
                            "cap": "03030",
                            "nome": "Broccostella"
                        },
                        {
                            "code": "060016",
                            "cap": "03030",
                            "nome": "Campoli Appennino"
                        },
                        {
                            "code": "060017",
                            "cap": "03030",
                            "nome": "Casalattico"
                        },
                        {
                            "code": "060018",
                            "cap": "03034",
                            "nome": "Casalvieri"
                        },
                        {
                            "code": "060019",
                            "cap": "03043",
                            "nome": "Cassino"
                        },
                        {
                            "code": "060020",
                            "cap": "03030",
                            "nome": "Castelliri"
                        },
                        {
                            "code": "060021",
                            "cap": "03040",
                            "nome": "Castelnuovo Parano"
                        },
                        {
                            "code": "060023",
                            "cap": "03020",
                            "nome": "Castro dei Volsci"
                        },
                        {
                            "code": "060022",
                            "cap": "03030",
                            "nome": "Castrocielo"
                        },
                        {
                            "code": "060024",
                            "cap": "03023",
                            "nome": "Ceccano"
                        },
                        {
                            "code": "060025",
                            "cap": "03024",
                            "nome": "Ceprano"
                        },
                        {
                            "code": "060026",
                            "cap": "03044",
                            "nome": "Cervaro"
                        },
                        {
                            "code": "060027",
                            "cap": "03030",
                            "nome": "Colfelice"
                        },
                        {
                            "code": "060029",
                            "cap": "03030",
                            "nome": "Colle San Magno"
                        },
                        {
                            "code": "060028",
                            "cap": "03010",
                            "nome": "Collepardo"
                        },
                        {
                            "code": "060030",
                            "cap": "03040",
                            "nome": "Coreno Ausonio"
                        },
                        {
                            "code": "060031",
                            "cap": "03045",
                            "nome": "Esperia"
                        },
                        {
                            "code": "060032",
                            "cap": "03020",
                            "nome": "Falvaterra"
                        },
                        {
                            "code": "060033",
                            "cap": "03013",
                            "nome": "Ferentino"
                        },
                        {
                            "code": "060034",
                            "cap": "03010",
                            "nome": "Filettino"
                        },
                        {
                            "code": "060035",
                            "cap": "03014",
                            "nome": "Fiuggi"
                        },
                        {
                            "code": "060036",
                            "cap": "03035",
                            "nome": "Fontana Liri"
                        },
                        {
                            "code": "060037",
                            "cap": "03030",
                            "nome": "Fontechiari"
                        },
                        {
                            "code": "060038",
                            "cap": "03100",
                            "nome": "Frosinone"
                        },
                        {
                            "code": "060039",
                            "cap": "03010",
                            "nome": "Fumone"
                        },
                        {
                            "code": "060040",
                            "cap": "03040",
                            "nome": "Gallinaro"
                        },
                        {
                            "code": "060041",
                            "cap": "03020",
                            "nome": "Giuliano di Roma"
                        },
                        {
                            "code": "060042",
                            "cap": "03016",
                            "nome": "Guarcino"
                        },
                        {
                            "code": "060043",
                            "cap": "03036",
                            "nome": "Isola del Liri"
                        },
                        {
                            "code": "060044",
                            "cap": "03025",
                            "nome": "Monte San Giovanni Campano"
                        },
                        {
                            "code": "060045",
                            "cap": "03017",
                            "nome": "Morolo"
                        },
                        {
                            "code": "060046",
                            "cap": "03018",
                            "nome": "Paliano"
                        },
                        {
                            "code": "060047",
                            "cap": "03020",
                            "nome": "Pastena"
                        },
                        {
                            "code": "060048",
                            "cap": "03010",
                            "nome": "Patrica"
                        },
                        {
                            "code": "060049",
                            "cap": "03030",
                            "nome": "Pescosolido"
                        },
                        {
                            "code": "060050",
                            "cap": "03040",
                            "nome": "Picinisco"
                        },
                        {
                            "code": "060051",
                            "cap": "03020",
                            "nome": "Pico"
                        },
                        {
                            "code": "060052",
                            "cap": "03030",
                            "nome": "Piedimonte San Germano"
                        },
                        {
                            "code": "060053",
                            "cap": "03010",
                            "nome": "Piglio"
                        },
                        {
                            "code": "060054",
                            "cap": "03040",
                            "nome": "Pignataro Interamna"
                        },
                        {
                            "code": "060055",
                            "cap": "03026",
                            "nome": "Pofi"
                        },
                        {
                            "code": "060056",
                            "cap": "03037",
                            "nome": "Pontecorvo"
                        },
                        {
                            "code": "060057",
                            "cap": "03030",
                            "nome": "Posta Fibreno"
                        },
                        {
                            "code": "060058",
                            "cap": "03027",
                            "nome": "Ripi"
                        },
                        {
                            "code": "060059",
                            "cap": "03030",
                            "nome": "Rocca d'Arce"
                        },
                        {
                            "code": "060060",
                            "cap": "03038",
                            "nome": "Roccasecca"
                        },
                        {
                            "code": "060061",
                            "cap": "03040",
                            "nome": "San Biagio Saracinisco"
                        },
                        {
                            "code": "060062",
                            "cap": "03046",
                            "nome": "San Donato Val di Comino"
                        },
                        {
                            "code": "060063",
                            "cap": "03047",
                            "nome": "San Giorgio a Liri"
                        },
                        {
                            "code": "060064",
                            "cap": "03028",
                            "nome": "San Giovanni Incarico"
                        },
                        {
                            "code": "060070",
                            "cap": "03040",
                            "nome": "San Vittore del Lazio"
                        },
                        {
                            "code": "060065",
                            "cap": "03040",
                            "nome": "Sant'Ambrogio sul Garigliano"
                        },
                        {
                            "code": "060066",
                            "cap": "03040",
                            "nome": "Sant'Andrea del Garigliano"
                        },
                        {
                            "code": "060067",
                            "cap": "03048",
                            "nome": "Sant'Apollinare"
                        },
                        {
                            "code": "060068",
                            "cap": "03049",
                            "nome": "Sant'Elia Fiumerapido"
                        },
                        {
                            "code": "060069",
                            "cap": "03030",
                            "nome": "Santopadre"
                        },
                        {
                            "code": "060071",
                            "cap": "03010",
                            "nome": "Serrone"
                        },
                        {
                            "code": "060072",
                            "cap": "03040",
                            "nome": "Settefrati"
                        },
                        {
                            "code": "060073",
                            "cap": "03010",
                            "nome": "Sgurgola"
                        },
                        {
                            "code": "060074",
                            "cap": "03039",
                            "nome": "Sora"
                        },
                        {
                            "code": "060075",
                            "cap": "03020",
                            "nome": "Strangolagalli"
                        },
                        {
                            "code": "060076",
                            "cap": "03019",
                            "nome": "Supino"
                        },
                        {
                            "code": "060077",
                            "cap": "03040",
                            "nome": "Terelle"
                        },
                        {
                            "code": "060078",
                            "cap": "03010",
                            "nome": "Torre Cajetani"
                        },
                        {
                            "code": "060079",
                            "cap": "03020",
                            "nome": "Torrice"
                        },
                        {
                            "code": "060080",
                            "cap": "03010",
                            "nome": "Trevi nel Lazio"
                        },
                        {
                            "code": "060081",
                            "cap": "03010",
                            "nome": "Trivigliano"
                        },
                        {
                            "code": "060082",
                            "cap": "03020",
                            "nome": "Vallecorsa"
                        },
                        {
                            "code": "060083",
                            "cap": "03040",
                            "nome": "Vallemaio"
                        },
                        {
                            "code": "060084",
                            "cap": "03040",
                            "nome": "Vallerotonda"
                        },
                        {
                            "code": "060085",
                            "cap": "03029",
                            "nome": "Veroli"
                        },
                        {
                            "code": "060086",
                            "cap": "03030",
                            "nome": "Vicalvi"
                        },
                        {
                            "code": "060087",
                            "cap": "03010",
                            "nome": "Vico nel Lazio"
                        },
                        {
                            "code": "060088",
                            "cap": "03040",
                            "nome": "Villa Latina"
                        },
                        {
                            "code": "060089",
                            "cap": "03030",
                            "nome": "Villa Santa Lucia"
                        },
                        {
                            "code": "060090",
                            "cap": "03020",
                            "nome": "Villa Santo Stefano"
                        },
                        {
                            "code": "060091",
                            "cap": "03040",
                            "nome": "Viticuso"
                        }
                    ],
                    "nome": "Frosinone"
                },
                {
                    "code": "LT",
                    "comuni": [
                        {
                            "code": "059001",
                            "cap": "04011",
                            "nome": "Aprilia"
                        },
                        {
                            "code": "059002",
                            "cap": "04010",
                            "nome": "Bassiano"
                        },
                        {
                            "code": "059003",
                            "cap": "04020",
                            "nome": "Campodimele"
                        },
                        {
                            "code": "059004",
                            "cap": "04021",
                            "nome": "Castelforte"
                        },
                        {
                            "code": "059005",
                            "cap": "04012",
                            "nome": "Cisterna di Latina"
                        },
                        {
                            "code": "059006",
                            "cap": "04010",
                            "nome": "Cori"
                        },
                        {
                            "code": "059007",
                            "cap": "04022",
                            "nome": "Fondi"
                        },
                        {
                            "code": "059008",
                            "cap": "04023",
                            "nome": "Formia"
                        },
                        {
                            "code": "059009",
                            "cap": "04024",
                            "nome": "Gaeta"
                        },
                        {
                            "code": "059010",
                            "cap": "04020",
                            "nome": "Itri"
                        },
                        {
                            "code": "059011",
                            "cap": "04100",
                            "nome": "Latina"
                        },
                        {
                            "code": "059012",
                            "cap": "04025",
                            "nome": "Lenola"
                        },
                        {
                            "code": "059013",
                            "cap": "04010",
                            "nome": "Maenza"
                        },
                        {
                            "code": "059014",
                            "cap": "04026",
                            "nome": "Minturno"
                        },
                        {
                            "code": "059015",
                            "cap": "04020",
                            "nome": "Monte San Biagio"
                        },
                        {
                            "code": "059016",
                            "cap": "04010",
                            "nome": "Norma"
                        },
                        {
                            "code": "059017",
                            "cap": "04014",
                            "nome": "Pontinia"
                        },
                        {
                            "code": "059018",
                            "cap": "04027",
                            "nome": "Ponza"
                        },
                        {
                            "code": "059019",
                            "cap": "04015",
                            "nome": "Priverno"
                        },
                        {
                            "code": "059020",
                            "cap": "04010",
                            "nome": "Prossedi"
                        },
                        {
                            "code": "059022",
                            "cap": "04010",
                            "nome": "Rocca Massima"
                        },
                        {
                            "code": "059021",
                            "cap": "04010",
                            "nome": "Roccagorga"
                        },
                        {
                            "code": "059023",
                            "cap": "04010",
                            "nome": "Roccasecca dei Volsci"
                        },
                        {
                            "code": "059024",
                            "cap": "04016",
                            "nome": "Sabaudia"
                        },
                        {
                            "code": "059025",
                            "cap": "04017",
                            "nome": "San Felice Circeo"
                        },
                        {
                            "code": "059026",
                            "cap": "04020",
                            "nome": "Santi Cosma e Damiano"
                        },
                        {
                            "code": "059027",
                            "cap": "04013",
                            "nome": "Sermoneta"
                        },
                        {
                            "code": "059028",
                            "cap": "04018",
                            "nome": "Sezze"
                        },
                        {
                            "code": "059029",
                            "cap": "04010",
                            "nome": "Sonnino"
                        },
                        {
                            "code": "059030",
                            "cap": "04029",
                            "nome": "Sperlonga"
                        },
                        {
                            "code": "059031",
                            "cap": "04020",
                            "nome": "Spigno Saturnia"
                        },
                        {
                            "code": "059032",
                            "cap": "04019",
                            "nome": "Terracina"
                        },
                        {
                            "code": "059033",
                            "cap": "04020",
                            "nome": "Ventotene"
                        }
                    ],
                    "nome": "Latina"
                },
                {
                    "code": "RI",
                    "comuni": [
                        {
                            "code": "057001",
                            "cap": "02011",
                            "nome": "Accumoli"
                        },
                        {
                            "code": "057002",
                            "cap": "02012",
                            "nome": "Amatrice"
                        },
                        {
                            "code": "057003",
                            "cap": "02013",
                            "nome": "Antrodoco"
                        },
                        {
                            "code": "057004",
                            "cap": "02020",
                            "nome": "Ascrea"
                        },
                        {
                            "code": "057005",
                            "cap": "02020",
                            "nome": "Belmonte in Sabina"
                        },
                        {
                            "code": "057006",
                            "cap": "02010",
                            "nome": "Borbona"
                        },
                        {
                            "code": "057008",
                            "cap": "02010",
                            "nome": "Borgo Velino"
                        },
                        {
                            "code": "057007",
                            "cap": "02021",
                            "nome": "Borgorose"
                        },
                        {
                            "code": "057009",
                            "cap": "02014",
                            "nome": "Cantalice"
                        },
                        {
                            "code": "057010",
                            "cap": "02040",
                            "nome": "Cantalupo in Sabina"
                        },
                        {
                            "code": "057011",
                            "cap": "02030",
                            "nome": "Casaprota"
                        },
                        {
                            "code": "057012",
                            "cap": "02041",
                            "nome": "Casperia"
                        },
                        {
                            "code": "057013",
                            "cap": "02020",
                            "nome": "Castel di Tora"
                        },
                        {
                            "code": "057015",
                            "cap": "02010",
                            "nome": "Castel Sant'Angelo"
                        },
                        {
                            "code": "057014",
                            "cap": "02031",
                            "nome": "Castelnuovo di Farfa"
                        },
                        {
                            "code": "057016",
                            "cap": "02015",
                            "nome": "Cittaducale"
                        },
                        {
                            "code": "057017",
                            "cap": "02010",
                            "nome": "Cittareale"
                        },
                        {
                            "code": "057018",
                            "cap": "02022",
                            "nome": "Collalto Sabino"
                        },
                        {
                            "code": "057019",
                            "cap": "02020",
                            "nome": "Colle di Tora"
                        },
                        {
                            "code": "057020",
                            "cap": "02020",
                            "nome": "Collegiove"
                        },
                        {
                            "code": "057021",
                            "cap": "02042",
                            "nome": "Collevecchio"
                        },
                        {
                            "code": "057022",
                            "cap": "02010",
                            "nome": "Colli sul Velino"
                        },
                        {
                            "code": "057023",
                            "cap": "02020",
                            "nome": "Concerviano"
                        },
                        {
                            "code": "057024",
                            "cap": "02040",
                            "nome": "Configni"
                        },
                        {
                            "code": "057025",
                            "cap": "02043",
                            "nome": "Contigliano"
                        },
                        {
                            "code": "057026",
                            "cap": "02040",
                            "nome": "Cottanello"
                        },
                        {
                            "code": "057027",
                            "cap": "02032",
                            "nome": "Fara in Sabina"
                        },
                        {
                            "code": "057028",
                            "cap": "02023",
                            "nome": "Fiamignano"
                        },
                        {
                            "code": "057029",
                            "cap": "02044",
                            "nome": "Forano"
                        },
                        {
                            "code": "057030",
                            "cap": "02030",
                            "nome": "Frasso Sabino"
                        },
                        {
                            "code": "057031",
                            "cap": "02045",
                            "nome": "Greccio"
                        },
                        {
                            "code": "057032",
                            "cap": "02010",
                            "nome": "Labro"
                        },
                        {
                            "code": "057033",
                            "cap": "02016",
                            "nome": "Leonessa"
                        },
                        {
                            "code": "057034",
                            "cap": "02020",
                            "nome": "Longone Sabino"
                        },
                        {
                            "code": "057035",
                            "cap": "02046",
                            "nome": "Magliano Sabina"
                        },
                        {
                            "code": "057036",
                            "cap": "02020",
                            "nome": "Marcetelli"
                        },
                        {
                            "code": "057037",
                            "cap": "02010",
                            "nome": "Micigliano"
                        },
                        {
                            "code": "057038",
                            "cap": "02040",
                            "nome": "Mompeo"
                        },
                        {
                            "code": "057039",
                            "cap": "02040",
                            "nome": "Montasola"
                        },
                        {
                            "code": "057043",
                            "cap": "02040",
                            "nome": "Monte San Giovanni in Sabina"
                        },
                        {
                            "code": "057040",
                            "cap": "02040",
                            "nome": "Montebuono"
                        },
                        {
                            "code": "057041",
                            "cap": "02033",
                            "nome": "Monteleone Sabino"
                        },
                        {
                            "code": "057042",
                            "cap": "02040",
                            "nome": "Montenero Sabino"
                        },
                        {
                            "code": "057044",
                            "cap": "02034",
                            "nome": "Montopoli di Sabina"
                        },
                        {
                            "code": "057045",
                            "cap": "02010",
                            "nome": "Morro Reatino"
                        },
                        {
                            "code": "057046",
                            "cap": "02020",
                            "nome": "Nespolo"
                        },
                        {
                            "code": "057047",
                            "cap": "02035",
                            "nome": "Orvinio"
                        },
                        {
                            "code": "057048",
                            "cap": "02020",
                            "nome": "Paganico Sabino"
                        },
                        {
                            "code": "057049",
                            "cap": "02024",
                            "nome": "Pescorocchiano"
                        },
                        {
                            "code": "057050",
                            "cap": "02025",
                            "nome": "Petrella Salto"
                        },
                        {
                            "code": "057051",
                            "cap": "02018",
                            "nome": "Poggio Bustone"
                        },
                        {
                            "code": "057052",
                            "cap": "02040",
                            "nome": "Poggio Catino"
                        },
                        {
                            "code": "057053",
                            "cap": "02047",
                            "nome": "Poggio Mirteto"
                        },
                        {
                            "code": "057054",
                            "cap": "02037",
                            "nome": "Poggio Moiano"
                        },
                        {
                            "code": "057055",
                            "cap": "02030",
                            "nome": "Poggio Nativo"
                        },
                        {
                            "code": "057056",
                            "cap": "02030",
                            "nome": "Poggio San Lorenzo"
                        },
                        {
                            "code": "057057",
                            "cap": "02019",
                            "nome": "Posta"
                        },
                        {
                            "code": "057058",
                            "cap": "02030",
                            "nome": "Pozzaglia Sabina"
                        },
                        {
                            "code": "057059",
                            "cap": "02100",
                            "nome": "Rieti"
                        },
                        {
                            "code": "057060",
                            "cap": "02010",
                            "nome": "Rivodutri"
                        },
                        {
                            "code": "057062",
                            "cap": "02026",
                            "nome": "Rocca Sinibalda"
                        },
                        {
                            "code": "057061",
                            "cap": "02040",
                            "nome": "Roccantica"
                        },
                        {
                            "code": "057063",
                            "cap": "02040",
                            "nome": "Salisano"
                        },
                        {
                            "code": "057064",
                            "cap": "02038",
                            "nome": "Scandriglia"
                        },
                        {
                            "code": "057065",
                            "cap": "02040",
                            "nome": "Selci"
                        },
                        {
                            "code": "057066",
                            "cap": "02048",
                            "nome": "Stimigliano"
                        },
                        {
                            "code": "057067",
                            "cap": "02040",
                            "nome": "Tarano"
                        },
                        {
                            "code": "057068",
                            "cap": "02039",
                            "nome": "Toffia"
                        },
                        {
                            "code": "057070",
                            "cap": "02049",
                            "nome": "Torri in Sabina"
                        },
                        {
                            "code": "057069",
                            "cap": "02030",
                            "nome": "Torricella in Sabina"
                        },
                        {
                            "code": "057071",
                            "cap": "02020",
                            "nome": "Turania"
                        },
                        {
                            "code": "057072",
                            "cap": "02040",
                            "nome": "Vacone"
                        },
                        {
                            "code": "057073",
                            "cap": "02020",
                            "nome": "Varco Sabino"
                        }
                    ],
                    "nome": "Rieti"
                },
                {
                    "code": "RM",
                    "comuni": [
                        {
                            "code": "058001",
                            "cap": "00021",
                            "nome": "Affile"
                        },
                        {
                            "code": "058002",
                            "cap": "00020",
                            "nome": "Agosta"
                        },
                        {
                            "code": "058003",
                            "cap": "00041",
                            "nome": "Albano Laziale"
                        },
                        {
                            "code": "058004",
                            "cap": "00051",
                            "nome": "Allumiere"
                        },
                        {
                            "code": "058005",
                            "cap": "00061",
                            "nome": "Anguillara Sabazia"
                        },
                        {
                            "code": "058006",
                            "cap": "00022",
                            "nome": "Anticoli Corrado"
                        },
                        {
                            "code": "058007",
                            "cap": "00042",
                            "nome": "Anzio"
                        },
                        {
                            "code": "058008",
                            "cap": "00020",
                            "nome": "Arcinazzo Romano"
                        },
                        {
                            "code": "058117",
                            "cap": "00040",
                            "nome": "Ardea"
                        },
                        {
                            "code": "058009",
                            "cap": "00040",
                            "nome": "Ariccia"
                        },
                        {
                            "code": "058010",
                            "cap": "00023",
                            "nome": "Arsoli"
                        },
                        {
                            "code": "058011",
                            "cap": "00031",
                            "nome": "Artena"
                        },
                        {
                            "code": "058012",
                            "cap": "00030",
                            "nome": "Bellegra"
                        },
                        {
                            "code": "058013",
                            "cap": "00062",
                            "nome": "Bracciano"
                        },
                        {
                            "code": "058014",
                            "cap": "00020",
                            "nome": "Camerata Nuova"
                        },
                        {
                            "code": "058015",
                            "cap": "00063",
                            "nome": "Campagnano di Roma"
                        },
                        {
                            "code": "058016",
                            "cap": "00060",
                            "nome": "Canale Monterano"
                        },
                        {
                            "code": "058017",
                            "cap": "00020",
                            "nome": "Canterano"
                        },
                        {
                            "code": "058018",
                            "cap": "00060",
                            "nome": "Capena"
                        },
                        {
                            "code": "058019",
                            "cap": "00030",
                            "nome": "Capranica Prenestina"
                        },
                        {
                            "code": "058020",
                            "cap": "00032",
                            "nome": "Carpineto Romano"
                        },
                        {
                            "code": "058021",
                            "cap": "00010",
                            "nome": "Casape"
                        },
                        {
                            "code": "058022",
                            "cap": "00040",
                            "nome": "Castel Gandolfo"
                        },
                        {
                            "code": "058023",
                            "cap": "00024",
                            "nome": "Castel Madama"
                        },
                        {
                            "code": "058025",
                            "cap": "00030",
                            "nome": "Castel San Pietro Romano"
                        },
                        {
                            "code": "058024",
                            "cap": "00060",
                            "nome": "Castelnuovo di Porto"
                        },
                        {
                            "code": "058026",
                            "cap": "00033",
                            "nome": "Cave"
                        },
                        {
                            "code": "058027",
                            "cap": "00020",
                            "nome": "Cerreto Laziale"
                        },
                        {
                            "code": "058028",
                            "cap": "00020",
                            "nome": "Cervara di Roma"
                        },
                        {
                            "code": "058029",
                            "cap": "00052",
                            "nome": "Cerveteri"
                        },
                        {
                            "code": "058118",
                            "cap": "00043",
                            "nome": "Ciampino"
                        },
                        {
                            "code": "058030",
                            "cap": "00020",
                            "nome": "Ciciliano"
                        },
                        {
                            "code": "058031",
                            "cap": "00020",
                            "nome": "Cineto Romano"
                        },
                        {
                            "code": "058032",
                            "cap": "00053",
                            "nome": "Civitavecchia"
                        },
                        {
                            "code": "058033",
                            "cap": "00060",
                            "nome": "Civitella San Paolo"
                        },
                        {
                            "code": "058034",
                            "cap": "00034",
                            "nome": "Colleferro"
                        },
                        {
                            "code": "058035",
                            "cap": "00030",
                            "nome": "Colonna"
                        },
                        {
                            "code": "058036",
                            "cap": "00065",
                            "nome": "Fiano Romano"
                        },
                        {
                            "code": "058037",
                            "cap": "00060",
                            "nome": "Filacciano"
                        },
                        {
                            "code": "058120",
                            "cap": "00054",
                            "nome": "Fiumicino"
                        },
                        {
                            "code": "058122",
                            "cap": "00013",
                            "nome": "Fonte Nuova"
                        },
                        {
                            "code": "058038",
                            "cap": "00060",
                            "nome": "Formello"
                        },
                        {
                            "code": "058039",
                            "cap": "00044",
                            "nome": "Frascati"
                        },
                        {
                            "code": "058040",
                            "cap": "00010",
                            "nome": "Gallicano nel Lazio"
                        },
                        {
                            "code": "058041",
                            "cap": "00030",
                            "nome": "Gavignano"
                        },
                        {
                            "code": "058042",
                            "cap": "00030",
                            "nome": "Genazzano"
                        },
                        {
                            "code": "058043",
                            "cap": "00045",
                            "nome": "Genzano di Roma"
                        },
                        {
                            "code": "058044",
                            "cap": "00025",
                            "nome": "Gerano"
                        },
                        {
                            "code": "058045",
                            "cap": "00030",
                            "nome": "Gorga"
                        },
                        {
                            "code": "058046",
                            "cap": "00046",
                            "nome": "Grottaferrata"
                        },
                        {
                            "code": "058047",
                            "cap": "00012",
                            "nome": "Guidonia Montecelio"
                        },
                        {
                            "code": "058048",
                            "cap": "00020",
                            "nome": "Jenne"
                        },
                        {
                            "code": "058049",
                            "cap": "00030",
                            "nome": "Labico"
                        },
                        {
                            "code": "058116",
                            "cap": "00055",
                            "nome": "Ladispoli"
                        },
                        {
                            "code": "058050",
                            "cap": "00040",
                            "nome": "Lanuvio"
                        },
                        {
                            "code": "058115",
                            "cap": "00040",
                            "nome": "Lariano"
                        },
                        {
                            "code": "058051",
                            "cap": "00026",
                            "nome": "Licenza"
                        },
                        {
                            "code": "058052",
                            "cap": "00060",
                            "nome": "Magliano Romano"
                        },
                        {
                            "code": "058053",
                            "cap": "00020",
                            "nome": "Mandela"
                        },
                        {
                            "code": "058054",
                            "cap": "00066",
                            "nome": "Manziana"
                        },
                        {
                            "code": "058055",
                            "cap": "00020",
                            "nome": "Marano Equo"
                        },
                        {
                            "code": "058056",
                            "cap": "00010",
                            "nome": "Marcellina"
                        },
                        {
                            "code": "058057",
                            "cap": "00047",
                            "nome": "Marino"
                        },
                        {
                            "code": "058058",
                            "cap": "00060",
                            "nome": "Mazzano Romano"
                        },
                        {
                            "code": "058059",
                            "cap": "00013",
                            "nome": "Mentana"
                        },
                        {
                            "code": "058060",
                            "cap": "00040",
                            "nome": "Monte Compatri"
                        },
                        {
                            "code": "058064",
                            "cap": "00040",
                            "nome": "Monte Porzio Catone"
                        },
                        {
                            "code": "058061",
                            "cap": "00010",
                            "nome": "Monteflavio"
                        },
                        {
                            "code": "058062",
                            "cap": "00030",
                            "nome": "Montelanico"
                        },
                        {
                            "code": "058063",
                            "cap": "00010",
                            "nome": "Montelibretti"
                        },
                        {
                            "code": "058065",
                            "cap": "00015",
                            "nome": "Monterotondo"
                        },
                        {
                            "code": "058066",
                            "cap": "00010",
                            "nome": "Montorio Romano"
                        },
                        {
                            "code": "058067",
                            "cap": "00010",
                            "nome": "Moricone"
                        },
                        {
                            "code": "058068",
                            "cap": "00067",
                            "nome": "Morlupo"
                        },
                        {
                            "code": "058069",
                            "cap": "00060",
                            "nome": "Nazzano"
                        },
                        {
                            "code": "058070",
                            "cap": "00040",
                            "nome": "Nemi"
                        },
                        {
                            "code": "058071",
                            "cap": "00017",
                            "nome": "Nerola"
                        },
                        {
                            "code": "058072",
                            "cap": "00048",
                            "nome": "Nettuno"
                        },
                        {
                            "code": "058073",
                            "cap": "00035",
                            "nome": "Olevano Romano"
                        },
                        {
                            "code": "058074",
                            "cap": "00036",
                            "nome": "Palestrina"
                        },
                        {
                            "code": "058075",
                            "cap": "00018",
                            "nome": "Palombara Sabina"
                        },
                        {
                            "code": "058076",
                            "cap": "00020",
                            "nome": "Percile"
                        },
                        {
                            "code": "058077",
                            "cap": "00020",
                            "nome": "Pisoniano"
                        },
                        {
                            "code": "058078",
                            "cap": "00010",
                            "nome": "Poli"
                        },
                        {
                            "code": "058079",
                            "cap": "00040",
                            "nome": "Pomezia"
                        },
                        {
                            "code": "058080",
                            "cap": "00060",
                            "nome": "Ponzano Romano"
                        },
                        {
                            "code": "058081",
                            "cap": "00060",
                            "nome": "Riano"
                        },
                        {
                            "code": "058082",
                            "cap": "00068",
                            "nome": "Rignano Flaminio"
                        },
                        {
                            "code": "058083",
                            "cap": "00020",
                            "nome": "Riofreddo"
                        },
                        {
                            "code": "058084",
                            "cap": "00020",
                            "nome": "Rocca Canterano"
                        },
                        {
                            "code": "058085",
                            "cap": "00030",
                            "nome": "Rocca di Cave"
                        },
                        {
                            "code": "058086",
                            "cap": "00040",
                            "nome": "Rocca di Papa"
                        },
                        {
                            "code": "058088",
                            "cap": "00040",
                            "nome": "Rocca Priora"
                        },
                        {
                            "code": "058089",
                            "cap": "00030",
                            "nome": "Rocca Santo Stefano"
                        },
                        {
                            "code": "058087",
                            "cap": "00020",
                            "nome": "Roccagiovine"
                        },
                        {
                            "code": "058090",
                            "cap": "00030",
                            "nome": "Roiate"
                        },
                        {
                            "code": "058091",
                            "cap": "001xx",
                            "nome": "Roma"
                        },
                        {
                            "code": "058092",
                            "cap": "00027",
                            "nome": "Roviano"
                        },
                        {
                            "code": "058093",
                            "cap": "00060",
                            "nome": "Sacrofano"
                        },
                        {
                            "code": "058094",
                            "cap": "00020",
                            "nome": "Sambuci"
                        },
                        {
                            "code": "058119",
                            "cap": "00030",
                            "nome": "San Cesareo"
                        },
                        {
                            "code": "058095",
                            "cap": "00010",
                            "nome": "San Gregorio da Sassola"
                        },
                        {
                            "code": "058096",
                            "cap": "00010",
                            "nome": "San Polo dei Cavalieri"
                        },
                        {
                            "code": "058100",
                            "cap": "00030",
                            "nome": "San Vito Romano"
                        },
                        {
                            "code": "058097",
                            "cap": "00058",
                            "nome": "Santa Marinella"
                        },
                        {
                            "code": "058098",
                            "cap": "00010",
                            "nome": "Sant'Angelo Romano"
                        },
                        {
                            "code": "058099",
                            "cap": "00060",
                            "nome": "Sant'Oreste"
                        },
                        {
                            "code": "058101",
                            "cap": "00020",
                            "nome": "Saracinesco"
                        },
                        {
                            "code": "058102",
                            "cap": "00037",
                            "nome": "Segni"
                        },
                        {
                            "code": "058103",
                            "cap": "00028",
                            "nome": "Subiaco"
                        },
                        {
                            "code": "058104",
                            "cap": "00019",
                            "nome": "Tivoli"
                        },
                        {
                            "code": "058105",
                            "cap": "00059",
                            "nome": "Tolfa"
                        },
                        {
                            "code": "058106",
                            "cap": "00060",
                            "nome": "Torrita Tiberina"
                        },
                        {
                            "code": "058107",
                            "cap": "00069",
                            "nome": "Trevignano Romano"
                        },
                        {
                            "code": "058108",
                            "cap": "00020",
                            "nome": "Vallepietra"
                        },
                        {
                            "code": "058109",
                            "cap": "00020",
                            "nome": "Vallinfreda"
                        },
                        {
                            "code": "058110",
                            "cap": "00038",
                            "nome": "Valmontone"
                        },
                        {
                            "code": "058111",
                            "cap": "00049",
                            "nome": "Velletri"
                        },
                        {
                            "code": "058112",
                            "cap": "00029",
                            "nome": "Vicovaro"
                        },
                        {
                            "code": "058113",
                            "cap": "00020",
                            "nome": "Vivaro Romano"
                        },
                        {
                            "code": "058114",
                            "cap": "00039",
                            "nome": "Zagarolo"
                        }
                    ],
                    "nome": "Roma"
                },
                {
                    "code": "VT",
                    "comuni": [
                        {
                            "code": "056001",
                            "cap": "01021",
                            "nome": "Acquapendente"
                        },
                        {
                            "code": "056002",
                            "cap": "01010",
                            "nome": "Arlena di Castro"
                        },
                        {
                            "code": "056003",
                            "cap": "01022",
                            "nome": "Bagnoregio"
                        },
                        {
                            "code": "056004",
                            "cap": "01010",
                            "nome": "Barbarano Romano"
                        },
                        {
                            "code": "056006",
                            "cap": "01030",
                            "nome": "Bassano in Teverina"
                        },
                        {
                            "code": "056005",
                            "cap": "01030",
                            "nome": "Bassano Romano"
                        },
                        {
                            "code": "056007",
                            "cap": "01010",
                            "nome": "Blera"
                        },
                        {
                            "code": "056008",
                            "cap": "01023",
                            "nome": "Bolsena"
                        },
                        {
                            "code": "056009",
                            "cap": "01020",
                            "nome": "Bomarzo"
                        },
                        {
                            "code": "056010",
                            "cap": "01030",
                            "nome": "Calcata"
                        },
                        {
                            "code": "056011",
                            "cap": "01030",
                            "nome": "Canepina"
                        },
                        {
                            "code": "056012",
                            "cap": "01011",
                            "nome": "Canino"
                        },
                        {
                            "code": "056013",
                            "cap": "01010",
                            "nome": "Capodimonte"
                        },
                        {
                            "code": "056014",
                            "cap": "01012",
                            "nome": "Capranica"
                        },
                        {
                            "code": "056015",
                            "cap": "01032",
                            "nome": "Caprarola"
                        },
                        {
                            "code": "056016",
                            "cap": "01030",
                            "nome": "Carbognano"
                        },
                        {
                            "code": "056017",
                            "cap": "01030",
                            "nome": "Castel Sant'Elia"
                        },
                        {
                            "code": "056018",
                            "cap": "01024",
                            "nome": "Castiglione in Teverina"
                        },
                        {
                            "code": "056019",
                            "cap": "01020",
                            "nome": "Celleno"
                        },
                        {
                            "code": "056020",
                            "cap": "01010",
                            "nome": "Cellere"
                        },
                        {
                            "code": "056021",
                            "cap": "01033",
                            "nome": "Civita Castellana"
                        },
                        {
                            "code": "056022",
                            "cap": "01020",
                            "nome": "Civitella d'Agliano"
                        },
                        {
                            "code": "056023",
                            "cap": "01030",
                            "nome": "Corchiano"
                        },
                        {
                            "code": "056024",
                            "cap": "01034",
                            "nome": "Fabrica di Roma"
                        },
                        {
                            "code": "056025",
                            "cap": "01030",
                            "nome": "Faleria"
                        },
                        {
                            "code": "056026",
                            "cap": "01010",
                            "nome": "Farnese"
                        },
                        {
                            "code": "056027",
                            "cap": "01035",
                            "nome": "Gallese"
                        },
                        {
                            "code": "056028",
                            "cap": "01010",
                            "nome": "Gradoli"
                        },
                        {
                            "code": "056029",
                            "cap": "01020",
                            "nome": "Graffignano"
                        },
                        {
                            "code": "056030",
                            "cap": "01025",
                            "nome": "Grotte di Castro"
                        },
                        {
                            "code": "056031",
                            "cap": "01010",
                            "nome": "Ischia di Castro"
                        },
                        {
                            "code": "056032",
                            "cap": "01010",
                            "nome": "Latera"
                        },
                        {
                            "code": "056033",
                            "cap": "01020",
                            "nome": "Lubriano"
                        },
                        {
                            "code": "056034",
                            "cap": "01010",
                            "nome": "Marta"
                        },
                        {
                            "code": "056035",
                            "cap": "01014",
                            "nome": "Montalto di Castro"
                        },
                        {
                            "code": "056037",
                            "cap": "01010",
                            "nome": "Monte Romano"
                        },
                        {
                            "code": "056036",
                            "cap": "01027",
                            "nome": "Montefiascone"
                        },
                        {
                            "code": "056038",
                            "cap": "01030",
                            "nome": "Monterosi"
                        },
                        {
                            "code": "056039",
                            "cap": "01036",
                            "nome": "Nepi"
                        },
                        {
                            "code": "056040",
                            "cap": "01010",
                            "nome": "Onano"
                        },
                        {
                            "code": "056041",
                            "cap": "01010",
                            "nome": "Oriolo Romano"
                        },
                        {
                            "code": "056042",
                            "cap": "01028",
                            "nome": "Orte"
                        },
                        {
                            "code": "056043",
                            "cap": "01010",
                            "nome": "Piansano"
                        },
                        {
                            "code": "056044",
                            "cap": "01020",
                            "nome": "Proceno"
                        },
                        {
                            "code": "056045",
                            "cap": "01037",
                            "nome": "Ronciglione"
                        },
                        {
                            "code": "056047",
                            "cap": "01020",
                            "nome": "San Lorenzo Nuovo"
                        },
                        {
                            "code": "056048",
                            "cap": "01038",
                            "nome": "Soriano nel Cimino"
                        },
                        {
                            "code": "056049",
                            "cap": "01015",
                            "nome": "Sutri"
                        },
                        {
                            "code": "056050",
                            "cap": "01016",
                            "nome": "Tarquinia"
                        },
                        {
                            "code": "056051",
                            "cap": "01010",
                            "nome": "Tessennano"
                        },
                        {
                            "code": "056052",
                            "cap": "01017",
                            "nome": "Tuscania"
                        },
                        {
                            "code": "056053",
                            "cap": "01018",
                            "nome": "Valentano"
                        },
                        {
                            "code": "056054",
                            "cap": "01030",
                            "nome": "Vallerano"
                        },
                        {
                            "code": "056055",
                            "cap": "01030",
                            "nome": "Vasanello"
                        },
                        {
                            "code": "056056",
                            "cap": "01010",
                            "nome": "Vejano"
                        },
                        {
                            "code": "056057",
                            "cap": "01019",
                            "nome": "Vetralla"
                        },
                        {
                            "code": "056058",
                            "cap": "01039",
                            "nome": "Vignanello"
                        },
                        {
                            "code": "056046",
                            "cap": "01010",
                            "nome": "Villa San Giovanni in Tuscia"
                        },
                        {
                            "code": "056059",
                            "cap": "01100",
                            "nome": "Viterbo"
                        },
                        {
                            "code": "056060",
                            "cap": "01030",
                            "nome": "Vitorchiano"
                        }
                    ],
                    "nome": "Viterbo"
                }
            ],
            "nome": "Lazio"
        },
        {
            "province": [
                {
                    "code": "GE",
                    "comuni": [
                        {
                            "code": "010001",
                            "cap": "16011",
                            "nome": "Arenzano"
                        },
                        {
                            "code": "010002",
                            "cap": "16030",
                            "nome": "Avegno"
                        },
                        {
                            "code": "010003",
                            "cap": "16021",
                            "nome": "Bargagli"
                        },
                        {
                            "code": "010004",
                            "cap": "16031",
                            "nome": "Bogliasco"
                        },
                        {
                            "code": "010005",
                            "cap": "16041",
                            "nome": "Borzonasca"
                        },
                        {
                            "code": "010006",
                            "cap": "16012",
                            "nome": "Busalla"
                        },
                        {
                            "code": "010007",
                            "cap": "16032",
                            "nome": "Camogli"
                        },
                        {
                            "code": "010008",
                            "cap": "16013",
                            "nome": "Campo Ligure"
                        },
                        {
                            "code": "010009",
                            "cap": "16014",
                            "nome": "Campomorone"
                        },
                        {
                            "code": "010010",
                            "cap": "16042",
                            "nome": "Carasco"
                        },
                        {
                            "code": "010011",
                            "cap": "16030",
                            "nome": "Casarza Ligure"
                        },
                        {
                            "code": "010012",
                            "cap": "16015",
                            "nome": "Casella"
                        },
                        {
                            "code": "010013",
                            "cap": "16030",
                            "nome": "Castiglione Chiavarese"
                        },
                        {
                            "code": "010014",
                            "cap": "16014",
                            "nome": "Ceranesi"
                        },
                        {
                            "code": "010015",
                            "cap": "16043",
                            "nome": "Chiavari"
                        },
                        {
                            "code": "010016",
                            "cap": "16044",
                            "nome": "Cicagna"
                        },
                        {
                            "code": "010017",
                            "cap": "16016",
                            "nome": "Cogoleto"
                        },
                        {
                            "code": "010018",
                            "cap": "16030",
                            "nome": "Cogorno"
                        },
                        {
                            "code": "010019",
                            "cap": "16040",
                            "nome": "Coreglia Ligure"
                        },
                        {
                            "code": "010020",
                            "cap": "16010",
                            "nome": "Crocefieschi"
                        },
                        {
                            "code": "010021",
                            "cap": "16022",
                            "nome": "Davagna"
                        },
                        {
                            "code": "010022",
                            "cap": "16020",
                            "nome": "Fascia"
                        },
                        {
                            "code": "010023",
                            "cap": "16040",
                            "nome": "Favale di Malvaro"
                        },
                        {
                            "code": "010024",
                            "cap": "16023",
                            "nome": "Fontanigorda"
                        },
                        {
                            "code": "010025",
                            "cap": "161xx",
                            "nome": "Genova"
                        },
                        {
                            "code": "010026",
                            "cap": "16020",
                            "nome": "Gorreto"
                        },
                        {
                            "code": "010027",
                            "cap": "16017",
                            "nome": "Isola del Cantone"
                        },
                        {
                            "code": "010028",
                            "cap": "16033",
                            "nome": "Lavagna"
                        },
                        {
                            "code": "010029",
                            "cap": "16040",
                            "nome": "Leivi"
                        },
                        {
                            "code": "010030",
                            "cap": "16045",
                            "nome": "Lorsica"
                        },
                        {
                            "code": "010031",
                            "cap": "16024",
                            "nome": "Lumarzo"
                        },
                        {
                            "code": "010032",
                            "cap": "16010",
                            "nome": "Masone"
                        },
                        {
                            "code": "010033",
                            "cap": "16010",
                            "nome": "Mele"
                        },
                        {
                            "code": "010034",
                            "cap": "16046",
                            "nome": "Mezzanego"
                        },
                        {
                            "code": "010035",
                            "cap": "16018",
                            "nome": "Mignanego"
                        },
                        {
                            "code": "010036",
                            "cap": "16047",
                            "nome": "Moconesi"
                        },
                        {
                            "code": "010037",
                            "cap": "16030",
                            "nome": "Moneglia"
                        },
                        {
                            "code": "010038",
                            "cap": "16025",
                            "nome": "Montebruno"
                        },
                        {
                            "code": "010039",
                            "cap": "16026",
                            "nome": "Montoggio"
                        },
                        {
                            "code": "010040",
                            "cap": "16040",
                            "nome": "Ne"
                        },
                        {
                            "code": "010041",
                            "cap": "16040",
                            "nome": "Neirone"
                        },
                        {
                            "code": "010042",
                            "cap": "16040",
                            "nome": "Orero"
                        },
                        {
                            "code": "010043",
                            "cap": "16030",
                            "nome": "Pieve Ligure"
                        },
                        {
                            "code": "010044",
                            "cap": "16034",
                            "nome": "Portofino"
                        },
                        {
                            "code": "010045",
                            "cap": "16027",
                            "nome": "Propata"
                        },
                        {
                            "code": "010046",
                            "cap": "16035",
                            "nome": "Rapallo"
                        },
                        {
                            "code": "010047",
                            "cap": "16036",
                            "nome": "Recco"
                        },
                        {
                            "code": "010048",
                            "cap": "16048",
                            "nome": "Rezzoaglio"
                        },
                        {
                            "code": "010049",
                            "cap": "16019",
                            "nome": "Ronco Scrivia"
                        },
                        {
                            "code": "010050",
                            "cap": "16025",
                            "nome": "Rondanina"
                        },
                        {
                            "code": "010051",
                            "cap": "16010",
                            "nome": "Rossiglione"
                        },
                        {
                            "code": "010052",
                            "cap": "16028",
                            "nome": "Rovegno"
                        },
                        {
                            "code": "010053",
                            "cap": "16040",
                            "nome": "San Colombano Certenoli"
                        },
                        {
                            "code": "010054",
                            "cap": "16038",
                            "nome": "Santa Margherita Ligure"
                        },
                        {
                            "code": "010056",
                            "cap": "16049",
                            "nome": "Santo Stefano d'Aveto"
                        },
                        {
                            "code": "010055",
                            "cap": "16010",
                            "nome": "Sant'Olcese"
                        },
                        {
                            "code": "010057",
                            "cap": "16010",
                            "nome": "Savignone"
                        },
                        {
                            "code": "010058",
                            "cap": "16010",
                            "nome": "Serra Ricc\u00f2"
                        },
                        {
                            "code": "010059",
                            "cap": "16039",
                            "nome": "Sestri Levante"
                        },
                        {
                            "code": "010060",
                            "cap": "16030",
                            "nome": "Sori"
                        },
                        {
                            "code": "010061",
                            "cap": "16010",
                            "nome": "Tiglieto"
                        },
                        {
                            "code": "010062",
                            "cap": "16029",
                            "nome": "Torriglia"
                        },
                        {
                            "code": "010063",
                            "cap": "16030",
                            "nome": "Tribogna"
                        },
                        {
                            "code": "010064",
                            "cap": "16030",
                            "nome": "Uscio"
                        },
                        {
                            "code": "010065",
                            "cap": "16010",
                            "nome": "Valbrevenna"
                        },
                        {
                            "code": "010066",
                            "cap": "16010",
                            "nome": "Vobbia"
                        },
                        {
                            "code": "010067",
                            "cap": "16030",
                            "nome": "Zoagli"
                        }
                    ],
                    "nome": "Genova"
                },
                {
                    "code": "IM",
                    "comuni": [
                        {
                            "code": "008001",
                            "cap": "18030",
                            "nome": "Airole"
                        },
                        {
                            "code": "008002",
                            "cap": "18035",
                            "nome": "Apricale"
                        },
                        {
                            "code": "008003",
                            "cap": "18020",
                            "nome": "Aquila d'Arroscia"
                        },
                        {
                            "code": "008004",
                            "cap": "18026",
                            "nome": "Armo"
                        },
                        {
                            "code": "008005",
                            "cap": "18020",
                            "nome": "Aurigo"
                        },
                        {
                            "code": "008006",
                            "cap": "18010",
                            "nome": "Badalucco"
                        },
                        {
                            "code": "008007",
                            "cap": "18031",
                            "nome": "Bajardo"
                        },
                        {
                            "code": "008008",
                            "cap": "18012",
                            "nome": "Bordighera"
                        },
                        {
                            "code": "008009",
                            "cap": "18020",
                            "nome": "Borghetto d'Arroscia"
                        },
                        {
                            "code": "008010",
                            "cap": "18021",
                            "nome": "Borgomaro"
                        },
                        {
                            "code": "008011",
                            "cap": "18033",
                            "nome": "Camporosso"
                        },
                        {
                            "code": "008012",
                            "cap": "18020",
                            "nome": "Caravonica"
                        },
                        {
                            "code": "008013",
                            "cap": "18010",
                            "nome": "Carpasio"
                        },
                        {
                            "code": "008015",
                            "cap": "18030",
                            "nome": "Castel Vittorio"
                        },
                        {
                            "code": "008014",
                            "cap": "18011",
                            "nome": "Castellaro"
                        },
                        {
                            "code": "008016",
                            "cap": "18034",
                            "nome": "Ceriana"
                        },
                        {
                            "code": "008017",
                            "cap": "18010",
                            "nome": "Cervo"
                        },
                        {
                            "code": "008018",
                            "cap": "18022",
                            "nome": "Cesio"
                        },
                        {
                            "code": "008019",
                            "cap": "18027",
                            "nome": "Chiusanico"
                        },
                        {
                            "code": "008020",
                            "cap": "18027",
                            "nome": "Chiusavecchia"
                        },
                        {
                            "code": "008021",
                            "cap": "18017",
                            "nome": "Cipressa"
                        },
                        {
                            "code": "008022",
                            "cap": "18017",
                            "nome": "Civezza"
                        },
                        {
                            "code": "008023",
                            "cap": "18023",
                            "nome": "Cosio d'Arroscia"
                        },
                        {
                            "code": "008024",
                            "cap": "18017",
                            "nome": "Costarainera"
                        },
                        {
                            "code": "008025",
                            "cap": "18013",
                            "nome": "Diano Arentino"
                        },
                        {
                            "code": "008026",
                            "cap": "18013",
                            "nome": "Diano Castello"
                        },
                        {
                            "code": "008027",
                            "cap": "18013",
                            "nome": "Diano Marina"
                        },
                        {
                            "code": "008028",
                            "cap": "18013",
                            "nome": "Diano San Pietro"
                        },
                        {
                            "code": "008029",
                            "cap": "18035",
                            "nome": "Dolceacqua"
                        },
                        {
                            "code": "008030",
                            "cap": "18020",
                            "nome": "Dolcedo"
                        },
                        {
                            "code": "008031",
                            "cap": "18100",
                            "nome": "Imperia"
                        },
                        {
                            "code": "008032",
                            "cap": "18035",
                            "nome": "Isolabona"
                        },
                        {
                            "code": "008033",
                            "cap": "18020",
                            "nome": "Lucinasco"
                        },
                        {
                            "code": "008034",
                            "cap": "18025",
                            "nome": "Mendatica"
                        },
                        {
                            "code": "008035",
                            "cap": "18010",
                            "nome": "Molini di Triora"
                        },
                        {
                            "code": "008036",
                            "cap": "18010",
                            "nome": "Montalto Ligure"
                        },
                        {
                            "code": "008037",
                            "cap": "18025",
                            "nome": "Montegrosso Pian Latte"
                        },
                        {
                            "code": "008038",
                            "cap": "18030",
                            "nome": "Olivetta San Michele"
                        },
                        {
                            "code": "008039",
                            "cap": "18014",
                            "nome": "Ospedaletti"
                        },
                        {
                            "code": "008040",
                            "cap": "18032",
                            "nome": "Perinaldo"
                        },
                        {
                            "code": "008041",
                            "cap": "18010",
                            "nome": "Pietrabruna"
                        },
                        {
                            "code": "008042",
                            "cap": "18026",
                            "nome": "Pieve di Teco"
                        },
                        {
                            "code": "008043",
                            "cap": "18037",
                            "nome": "Pigna"
                        },
                        {
                            "code": "008044",
                            "cap": "18015",
                            "nome": "Pompeiana"
                        },
                        {
                            "code": "008045",
                            "cap": "18027",
                            "nome": "Pontedassio"
                        },
                        {
                            "code": "008046",
                            "cap": "18024",
                            "nome": "Pornassio"
                        },
                        {
                            "code": "008047",
                            "cap": "18020",
                            "nome": "Prel\u00e0"
                        },
                        {
                            "code": "008048",
                            "cap": "18020",
                            "nome": "Ranzo"
                        },
                        {
                            "code": "008049",
                            "cap": "18026",
                            "nome": "Rezzo"
                        },
                        {
                            "code": "008050",
                            "cap": "18015",
                            "nome": "Riva Ligure"
                        },
                        {
                            "code": "008051",
                            "cap": "18030",
                            "nome": "Rocchetta Nervina"
                        },
                        {
                            "code": "008052",
                            "cap": "18016",
                            "nome": "San Bartolomeo al Mare"
                        },
                        {
                            "code": "008053",
                            "cap": "18036",
                            "nome": "San Biagio della Cima"
                        },
                        {
                            "code": "008054",
                            "cap": "18017",
                            "nome": "San Lorenzo al Mare"
                        },
                        {
                            "code": "008055",
                            "cap": "18038",
                            "nome": "Sanremo"
                        },
                        {
                            "code": "008056",
                            "cap": "18010",
                            "nome": "Santo Stefano al Mare"
                        },
                        {
                            "code": "008057",
                            "cap": "18012",
                            "nome": "Seborga"
                        },
                        {
                            "code": "008058",
                            "cap": "18036",
                            "nome": "Soldano"
                        },
                        {
                            "code": "008059",
                            "cap": "18018",
                            "nome": "Taggia"
                        },
                        {
                            "code": "008060",
                            "cap": "18010",
                            "nome": "Terzorio"
                        },
                        {
                            "code": "008061",
                            "cap": "18010",
                            "nome": "Triora"
                        },
                        {
                            "code": "008062",
                            "cap": "18012",
                            "nome": "Vallebona"
                        },
                        {
                            "code": "008063",
                            "cap": "18019",
                            "nome": "Vallecrosia"
                        },
                        {
                            "code": "008064",
                            "cap": "18020",
                            "nome": "Vasia"
                        },
                        {
                            "code": "008065",
                            "cap": "18039",
                            "nome": "Ventimiglia"
                        },
                        {
                            "code": "008066",
                            "cap": "18026",
                            "nome": "Vessalico"
                        },
                        {
                            "code": "008067",
                            "cap": "18010",
                            "nome": "Villa Faraldi"
                        }
                    ],
                    "nome": "Imperia"
                },
                {
                    "code": "SP",
                    "comuni": [
                        {
                            "code": "011001",
                            "cap": "19031",
                            "nome": "Ameglia"
                        },
                        {
                            "code": "011002",
                            "cap": "19021",
                            "nome": "Arcola"
                        },
                        {
                            "code": "011003",
                            "cap": "19020",
                            "nome": "Beverino"
                        },
                        {
                            "code": "011004",
                            "cap": "19020",
                            "nome": "Bolano"
                        },
                        {
                            "code": "011005",
                            "cap": "19011",
                            "nome": "Bonassola"
                        },
                        {
                            "code": "011006",
                            "cap": "19020",
                            "nome": "Borghetto di Vara"
                        },
                        {
                            "code": "011007",
                            "cap": "19020",
                            "nome": "Brugnato"
                        },
                        {
                            "code": "011008",
                            "cap": "19020",
                            "nome": "Calice al Cornoviglio"
                        },
                        {
                            "code": "011009",
                            "cap": "19012",
                            "nome": "Carro"
                        },
                        {
                            "code": "011010",
                            "cap": "19020",
                            "nome": "Carrodano"
                        },
                        {
                            "code": "011011",
                            "cap": "19033",
                            "nome": "Castelnuovo Magra"
                        },
                        {
                            "code": "011012",
                            "cap": "19013",
                            "nome": "Deiva Marina"
                        },
                        {
                            "code": "011013",
                            "cap": "19020",
                            "nome": "Follo"
                        },
                        {
                            "code": "011014",
                            "cap": "19014",
                            "nome": "Framura"
                        },
                        {
                            "code": "011015",
                            "cap": "191xx",
                            "nome": "La Spezia"
                        },
                        {
                            "code": "011016",
                            "cap": "19032",
                            "nome": "Lerici"
                        },
                        {
                            "code": "011017",
                            "cap": "19015",
                            "nome": "Levanto"
                        },
                        {
                            "code": "011018",
                            "cap": "19010",
                            "nome": "Maissana"
                        },
                        {
                            "code": "011019",
                            "cap": "19016",
                            "nome": "Monterosso al Mare"
                        },
                        {
                            "code": "011020",
                            "cap": "19034",
                            "nome": "Ortonovo"
                        },
                        {
                            "code": "011021",
                            "cap": "19020",
                            "nome": "Pignone"
                        },
                        {
                            "code": "011022",
                            "cap": "19025",
                            "nome": "Portovenere"
                        },
                        {
                            "code": "011023",
                            "cap": "19020",
                            "nome": "Ricc\u00f2 del Golfo di Spezia"
                        },
                        {
                            "code": "011024",
                            "cap": "19017",
                            "nome": "Riomaggiore"
                        },
                        {
                            "code": "011025",
                            "cap": "19020",
                            "nome": "Rocchetta di Vara"
                        },
                        {
                            "code": "011026",
                            "cap": "19037",
                            "nome": "Santo Stefano di Magra"
                        },
                        {
                            "code": "011027",
                            "cap": "19038",
                            "nome": "Sarzana"
                        },
                        {
                            "code": "011028",
                            "cap": "19020",
                            "nome": "Sesta Godano"
                        },
                        {
                            "code": "011029",
                            "cap": "19028",
                            "nome": "Varese Ligure"
                        },
                        {
                            "code": "011030",
                            "cap": "19018",
                            "nome": "Vernazza"
                        },
                        {
                            "code": "011031",
                            "cap": "19020",
                            "nome": "Vezzano Ligure"
                        },
                        {
                            "code": "011032",
                            "cap": "19020",
                            "nome": "Zignago"
                        }
                    ],
                    "nome": "La Spezia"
                },
                {
                    "code": "SV",
                    "comuni": [
                        {
                            "code": "009001",
                            "cap": "17021",
                            "nome": "Alassio"
                        },
                        {
                            "code": "009002",
                            "cap": "17031",
                            "nome": "Albenga"
                        },
                        {
                            "code": "009004",
                            "cap": "17011",
                            "nome": "Albisola Superiore"
                        },
                        {
                            "code": "009003",
                            "cap": "17012",
                            "nome": "Albissola Marina"
                        },
                        {
                            "code": "009005",
                            "cap": "17041",
                            "nome": "Altare"
                        },
                        {
                            "code": "009006",
                            "cap": "17051",
                            "nome": "Andora"
                        },
                        {
                            "code": "009007",
                            "cap": "17032",
                            "nome": "Arnasco"
                        },
                        {
                            "code": "009008",
                            "cap": "17020",
                            "nome": "Balestrino"
                        },
                        {
                            "code": "009009",
                            "cap": "17057",
                            "nome": "Bardineto"
                        },
                        {
                            "code": "009010",
                            "cap": "17028",
                            "nome": "Bergeggi"
                        },
                        {
                            "code": "009011",
                            "cap": "17054",
                            "nome": "Boissano"
                        },
                        {
                            "code": "009012",
                            "cap": "17052",
                            "nome": "Borghetto Santo Spirito"
                        },
                        {
                            "code": "009013",
                            "cap": "17022",
                            "nome": "Borgio Verezzi"
                        },
                        {
                            "code": "009014",
                            "cap": "17045",
                            "nome": "Bormida"
                        },
                        {
                            "code": "009015",
                            "cap": "17014",
                            "nome": "Cairo Montenotte"
                        },
                        {
                            "code": "009016",
                            "cap": "17020",
                            "nome": "Calice Ligure"
                        },
                        {
                            "code": "009017",
                            "cap": "17057",
                            "nome": "Calizzano"
                        },
                        {
                            "code": "009018",
                            "cap": "17043",
                            "nome": "Carcare"
                        },
                        {
                            "code": "009019",
                            "cap": "17033",
                            "nome": "Casanova Lerrone"
                        },
                        {
                            "code": "009020",
                            "cap": "17030",
                            "nome": "Castelbianco"
                        },
                        {
                            "code": "009021",
                            "cap": "17034",
                            "nome": "Castelvecchio di Rocca Barbena"
                        },
                        {
                            "code": "009022",
                            "cap": "17015",
                            "nome": "Celle Ligure"
                        },
                        {
                            "code": "009023",
                            "cap": "17056",
                            "nome": "Cengio"
                        },
                        {
                            "code": "009024",
                            "cap": "17023",
                            "nome": "Ceriale"
                        },
                        {
                            "code": "009025",
                            "cap": "17035",
                            "nome": "Cisano sul Neva"
                        },
                        {
                            "code": "009026",
                            "cap": "17017",
                            "nome": "Cosseria"
                        },
                        {
                            "code": "009027",
                            "cap": "17058",
                            "nome": "Dego"
                        },
                        {
                            "code": "009028",
                            "cap": "17030",
                            "nome": "Erli"
                        },
                        {
                            "code": "009029",
                            "cap": "17024",
                            "nome": "Finale Ligure"
                        },
                        {
                            "code": "009030",
                            "cap": "17033",
                            "nome": "Garlenda"
                        },
                        {
                            "code": "009031",
                            "cap": "17027",
                            "nome": "Giustenice"
                        },
                        {
                            "code": "009032",
                            "cap": "17010",
                            "nome": "Giusvalla"
                        },
                        {
                            "code": "009033",
                            "cap": "17053",
                            "nome": "Laigueglia"
                        },
                        {
                            "code": "009034",
                            "cap": "17025",
                            "nome": "Loano"
                        },
                        {
                            "code": "009035",
                            "cap": "17020",
                            "nome": "Magliolo"
                        },
                        {
                            "code": "009036",
                            "cap": "17045",
                            "nome": "Mallare"
                        },
                        {
                            "code": "009037",
                            "cap": "12071",
                            "nome": "Massimino"
                        },
                        {
                            "code": "009038",
                            "cap": "17017",
                            "nome": "Millesimo"
                        },
                        {
                            "code": "009039",
                            "cap": "17040",
                            "nome": "Mioglia"
                        },
                        {
                            "code": "009040",
                            "cap": "17013",
                            "nome": "Murialdo"
                        },
                        {
                            "code": "009041",
                            "cap": "17030",
                            "nome": "Nasino"
                        },
                        {
                            "code": "009042",
                            "cap": "17026",
                            "nome": "Noli"
                        },
                        {
                            "code": "009043",
                            "cap": "17037",
                            "nome": "Onzo"
                        },
                        {
                            "code": "009044",
                            "cap": "17024",
                            "nome": "Orco Feglino"
                        },
                        {
                            "code": "009045",
                            "cap": "17037",
                            "nome": "Ortovero"
                        },
                        {
                            "code": "009046",
                            "cap": "17010",
                            "nome": "Osiglia"
                        },
                        {
                            "code": "009047",
                            "cap": "17043",
                            "nome": "Pallare"
                        },
                        {
                            "code": "009048",
                            "cap": "17058",
                            "nome": "Piana Crixia"
                        },
                        {
                            "code": "009049",
                            "cap": "17027",
                            "nome": "Pietra Ligure"
                        },
                        {
                            "code": "009050",
                            "cap": "17043",
                            "nome": "Plodio"
                        },
                        {
                            "code": "009051",
                            "cap": "17042",
                            "nome": "Pontinvrea"
                        },
                        {
                            "code": "009052",
                            "cap": "17047",
                            "nome": "Quiliano"
                        },
                        {
                            "code": "009053",
                            "cap": "17020",
                            "nome": "Rialto"
                        },
                        {
                            "code": "009054",
                            "cap": "17017",
                            "nome": "Roccavignale"
                        },
                        {
                            "code": "009055",
                            "cap": "17046",
                            "nome": "Sassello"
                        },
                        {
                            "code": "009056",
                            "cap": "17100",
                            "nome": "Savona"
                        },
                        {
                            "code": "009057",
                            "cap": "17028",
                            "nome": "Spotorno"
                        },
                        {
                            "code": "009058",
                            "cap": "17044",
                            "nome": "Stella"
                        },
                        {
                            "code": "009059",
                            "cap": "17020",
                            "nome": "Stellanello"
                        },
                        {
                            "code": "009060",
                            "cap": "17020",
                            "nome": "Testico"
                        },
                        {
                            "code": "009061",
                            "cap": "17055",
                            "nome": "Toirano"
                        },
                        {
                            "code": "009062",
                            "cap": "17020",
                            "nome": "Tovo San Giacomo"
                        },
                        {
                            "code": "009063",
                            "cap": "17048",
                            "nome": "Urbe"
                        },
                        {
                            "code": "009064",
                            "cap": "17047",
                            "nome": "Vado Ligure"
                        },
                        {
                            "code": "009065",
                            "cap": "17019",
                            "nome": "Varazze"
                        },
                        {
                            "code": "009066",
                            "cap": "17032",
                            "nome": "Vendone"
                        },
                        {
                            "code": "009067",
                            "cap": "17028",
                            "nome": "Vezzi Portio"
                        },
                        {
                            "code": "009068",
                            "cap": "17038",
                            "nome": "Villanova d'Albenga"
                        },
                        {
                            "code": "009069",
                            "cap": "17039",
                            "nome": "Zuccarello"
                        }
                    ],
                    "nome": "Savona"
                }
            ],
            "nome": "Liguria"
        },
        {
            "province": [
                {
                    "code": "BG",
                    "comuni": [
                        {
                            "code": "016001",
                            "cap": "24060",
                            "nome": "Adrara San Martino"
                        },
                        {
                            "code": "016002",
                            "cap": "24060",
                            "nome": "Adrara San Rocco"
                        },
                        {
                            "code": "016003",
                            "cap": "24061",
                            "nome": "Albano Sant'Alessandro"
                        },
                        {
                            "code": "016004",
                            "cap": "24021",
                            "nome": "Albino"
                        },
                        {
                            "code": "016248",
                            "cap": "24010",
                            "nome": "Algua"
                        },
                        {
                            "code": "016005",
                            "cap": "24011",
                            "nome": "Alm\u00e8"
                        },
                        {
                            "code": "016006",
                            "cap": "24030",
                            "nome": "Almenno San Bartolomeo"
                        },
                        {
                            "code": "016007",
                            "cap": "24031",
                            "nome": "Almenno San Salvatore"
                        },
                        {
                            "code": "016008",
                            "cap": "24022",
                            "nome": "Alzano Lombardo"
                        },
                        {
                            "code": "016009",
                            "cap": "24030",
                            "nome": "Ambivere"
                        },
                        {
                            "code": "016010",
                            "cap": "24051",
                            "nome": "Antegnate"
                        },
                        {
                            "code": "016011",
                            "cap": "24040",
                            "nome": "Arcene"
                        },
                        {
                            "code": "016012",
                            "cap": "24020",
                            "nome": "Ardesio"
                        },
                        {
                            "code": "016013",
                            "cap": "24040",
                            "nome": "Arzago d'Adda"
                        },
                        {
                            "code": "016014",
                            "cap": "24010",
                            "nome": "Averara"
                        },
                        {
                            "code": "016015",
                            "cap": "24020",
                            "nome": "Aviatico"
                        },
                        {
                            "code": "016016",
                            "cap": "24052",
                            "nome": "Azzano San Paolo"
                        },
                        {
                            "code": "016017",
                            "cap": "24020",
                            "nome": "Azzone"
                        },
                        {
                            "code": "016018",
                            "cap": "24060",
                            "nome": "Bagnatica"
                        },
                        {
                            "code": "016019",
                            "cap": "24040",
                            "nome": "Barbata"
                        },
                        {
                            "code": "016020",
                            "cap": "24050",
                            "nome": "Bariano"
                        },
                        {
                            "code": "016021",
                            "cap": "24030",
                            "nome": "Barzana"
                        },
                        {
                            "code": "016022",
                            "cap": "24030",
                            "nome": "Bedulita"
                        },
                        {
                            "code": "016023",
                            "cap": "24030",
                            "nome": "Berbenno"
                        },
                        {
                            "code": "016024",
                            "cap": "241xx",
                            "nome": "Bergamo"
                        },
                        {
                            "code": "016025",
                            "cap": "24060",
                            "nome": "Berzo San Fermo"
                        },
                        {
                            "code": "016026",
                            "cap": "24060",
                            "nome": "Bianzano"
                        },
                        {
                            "code": "016027",
                            "cap": "24010",
                            "nome": "Blello"
                        },
                        {
                            "code": "016028",
                            "cap": "24060",
                            "nome": "Bolgare"
                        },
                        {
                            "code": "016029",
                            "cap": "24040",
                            "nome": "Boltiere"
                        },
                        {
                            "code": "016030",
                            "cap": "24040",
                            "nome": "Bonate Sopra"
                        },
                        {
                            "code": "016031",
                            "cap": "24040",
                            "nome": "Bonate Sotto"
                        },
                        {
                            "code": "016032",
                            "cap": "24060",
                            "nome": "Borgo di Terzo"
                        },
                        {
                            "code": "016033",
                            "cap": "24060",
                            "nome": "Bossico"
                        },
                        {
                            "code": "016034",
                            "cap": "24040",
                            "nome": "Bottanuco"
                        },
                        {
                            "code": "016035",
                            "cap": "24010",
                            "nome": "Bracca"
                        },
                        {
                            "code": "016036",
                            "cap": "24010",
                            "nome": "Branzi"
                        },
                        {
                            "code": "016037",
                            "cap": "24041",
                            "nome": "Brembate"
                        },
                        {
                            "code": "016038",
                            "cap": "24030",
                            "nome": "Brembate di Sopra"
                        },
                        {
                            "code": "016039",
                            "cap": "24012",
                            "nome": "Brembilla"
                        },
                        {
                            "code": "016040",
                            "cap": "24053",
                            "nome": "Brignano Gera d'Adda"
                        },
                        {
                            "code": "016041",
                            "cap": "24037",
                            "nome": "Brumano"
                        },
                        {
                            "code": "016042",
                            "cap": "24060",
                            "nome": "Brusaporto"
                        },
                        {
                            "code": "016043",
                            "cap": "24050",
                            "nome": "Calcinate"
                        },
                        {
                            "code": "016044",
                            "cap": "24054",
                            "nome": "Calcio"
                        },
                        {
                            "code": "016046",
                            "cap": "24033",
                            "nome": "Calusco d'Adda"
                        },
                        {
                            "code": "016047",
                            "cap": "24040",
                            "nome": "Calvenzano"
                        },
                        {
                            "code": "016048",
                            "cap": "24010",
                            "nome": "Camerata Cornello"
                        },
                        {
                            "code": "016049",
                            "cap": "24040",
                            "nome": "Canonica d'Adda"
                        },
                        {
                            "code": "016050",
                            "cap": "24030",
                            "nome": "Capizzone"
                        },
                        {
                            "code": "016051",
                            "cap": "24042",
                            "nome": "Capriate San Gervasio"
                        },
                        {
                            "code": "016052",
                            "cap": "24030",
                            "nome": "Caprino Bergamasco"
                        },
                        {
                            "code": "016053",
                            "cap": "24043",
                            "nome": "Caravaggio"
                        },
                        {
                            "code": "016055",
                            "cap": "24060",
                            "nome": "Carobbio degli Angeli"
                        },
                        {
                            "code": "016056",
                            "cap": "24010",
                            "nome": "Carona"
                        },
                        {
                            "code": "016057",
                            "cap": "24030",
                            "nome": "Carvico"
                        },
                        {
                            "code": "016058",
                            "cap": "24060",
                            "nome": "Casazza"
                        },
                        {
                            "code": "016059",
                            "cap": "24040",
                            "nome": "Casirate d'Adda"
                        },
                        {
                            "code": "016060",
                            "cap": "24020",
                            "nome": "Casnigo"
                        },
                        {
                            "code": "016061",
                            "cap": "24010",
                            "nome": "Cassiglio"
                        },
                        {
                            "code": "016063",
                            "cap": "24040",
                            "nome": "Castel Rozzone"
                        },
                        {
                            "code": "016062",
                            "cap": "24060",
                            "nome": "Castelli Calepio"
                        },
                        {
                            "code": "016064",
                            "cap": "24020",
                            "nome": "Castione della Presolana"
                        },
                        {
                            "code": "016065",
                            "cap": "24063",
                            "nome": "Castro"
                        },
                        {
                            "code": "016066",
                            "cap": "24050",
                            "nome": "Cavernago"
                        },
                        {
                            "code": "016067",
                            "cap": "24026",
                            "nome": "Cazzano Sant'Andrea"
                        },
                        {
                            "code": "016068",
                            "cap": "24060",
                            "nome": "Cenate Sopra"
                        },
                        {
                            "code": "016069",
                            "cap": "24069",
                            "nome": "Cenate Sotto"
                        },
                        {
                            "code": "016070",
                            "cap": "24020",
                            "nome": "Cene"
                        },
                        {
                            "code": "016071",
                            "cap": "24020",
                            "nome": "Cerete"
                        },
                        {
                            "code": "016072",
                            "cap": "24040",
                            "nome": "Chignolo d'Isola"
                        },
                        {
                            "code": "016073",
                            "cap": "24060",
                            "nome": "Chiuduno"
                        },
                        {
                            "code": "016074",
                            "cap": "24034",
                            "nome": "Cisano Bergamasco"
                        },
                        {
                            "code": "016075",
                            "cap": "24040",
                            "nome": "Ciserano"
                        },
                        {
                            "code": "016076",
                            "cap": "24050",
                            "nome": "Cividate al Piano"
                        },
                        {
                            "code": "016077",
                            "cap": "24023",
                            "nome": "Clusone"
                        },
                        {
                            "code": "016078",
                            "cap": "24020",
                            "nome": "Colere"
                        },
                        {
                            "code": "016079",
                            "cap": "24055",
                            "nome": "Cologno al Serio"
                        },
                        {
                            "code": "016080",
                            "cap": "24020",
                            "nome": "Colzate"
                        },
                        {
                            "code": "016081",
                            "cap": "24040",
                            "nome": "Comun Nuovo"
                        },
                        {
                            "code": "016082",
                            "cap": "24030",
                            "nome": "Corna Imagna"
                        },
                        {
                            "code": "016249",
                            "cap": "24017",
                            "nome": "Cornalba"
                        },
                        {
                            "code": "016083",
                            "cap": "24050",
                            "nome": "Cortenuova"
                        },
                        {
                            "code": "016084",
                            "cap": "24060",
                            "nome": "Costa di Mezzate"
                        },
                        {
                            "code": "016247",
                            "cap": "24010",
                            "nome": "Costa Serina"
                        },
                        {
                            "code": "016085",
                            "cap": "24030",
                            "nome": "Costa Valle Imagna"
                        },
                        {
                            "code": "016086",
                            "cap": "24062",
                            "nome": "Costa Volpino"
                        },
                        {
                            "code": "016087",
                            "cap": "24050",
                            "nome": "Covo"
                        },
                        {
                            "code": "016088",
                            "cap": "24060",
                            "nome": "Credaro"
                        },
                        {
                            "code": "016089",
                            "cap": "24035",
                            "nome": "Curno"
                        },
                        {
                            "code": "016090",
                            "cap": "24010",
                            "nome": "Cusio"
                        },
                        {
                            "code": "016091",
                            "cap": "24044",
                            "nome": "Dalmine"
                        },
                        {
                            "code": "016092",
                            "cap": "24010",
                            "nome": "Dossena"
                        },
                        {
                            "code": "016093",
                            "cap": "24060",
                            "nome": "Endine Gaiano"
                        },
                        {
                            "code": "016094",
                            "cap": "24060",
                            "nome": "Entratico"
                        },
                        {
                            "code": "016096",
                            "cap": "24045",
                            "nome": "Fara Gera d'Adda"
                        },
                        {
                            "code": "016097",
                            "cap": "24058",
                            "nome": "Fara Olivana con Sola"
                        },
                        {
                            "code": "016098",
                            "cap": "24040",
                            "nome": "Filago"
                        },
                        {
                            "code": "016099",
                            "cap": "24020",
                            "nome": "Fino del Monte"
                        },
                        {
                            "code": "016100",
                            "cap": "24020",
                            "nome": "Fiorano al Serio"
                        },
                        {
                            "code": "016101",
                            "cap": "24056",
                            "nome": "Fontanella"
                        },
                        {
                            "code": "016102",
                            "cap": "24060",
                            "nome": "Fonteno"
                        },
                        {
                            "code": "016103",
                            "cap": "24010",
                            "nome": "Foppolo"
                        },
                        {
                            "code": "016104",
                            "cap": "24060",
                            "nome": "Foresto Sparso"
                        },
                        {
                            "code": "016105",
                            "cap": "24040",
                            "nome": "Fornovo San Giovanni"
                        },
                        {
                            "code": "016106",
                            "cap": "24030",
                            "nome": "Fuipiano Valle Imagna"
                        },
                        {
                            "code": "016107",
                            "cap": "24020",
                            "nome": "Gandellino"
                        },
                        {
                            "code": "016108",
                            "cap": "24024",
                            "nome": "Gandino"
                        },
                        {
                            "code": "016109",
                            "cap": "24060",
                            "nome": "Gandosso"
                        },
                        {
                            "code": "016110",
                            "cap": "24060",
                            "nome": "Gaverina Terme"
                        },
                        {
                            "code": "016111",
                            "cap": "24025",
                            "nome": "Gazzaniga"
                        },
                        {
                            "code": "016112",
                            "cap": "24010",
                            "nome": "Gerosa"
                        },
                        {
                            "code": "016113",
                            "cap": "24050",
                            "nome": "Ghisalba"
                        },
                        {
                            "code": "016114",
                            "cap": "24060",
                            "nome": "Gorlago"
                        },
                        {
                            "code": "016115",
                            "cap": "24020",
                            "nome": "Gorle"
                        },
                        {
                            "code": "016116",
                            "cap": "24020",
                            "nome": "Gorno"
                        },
                        {
                            "code": "016117",
                            "cap": "24050",
                            "nome": "Grassobbio"
                        },
                        {
                            "code": "016118",
                            "cap": "24020",
                            "nome": "Gromo"
                        },
                        {
                            "code": "016119",
                            "cap": "24060",
                            "nome": "Grone"
                        },
                        {
                            "code": "016120",
                            "cap": "24064",
                            "nome": "Grumello del Monte"
                        },
                        {
                            "code": "016121",
                            "cap": "24010",
                            "nome": "Isola di Fondra"
                        },
                        {
                            "code": "016122",
                            "cap": "24040",
                            "nome": "Isso"
                        },
                        {
                            "code": "016123",
                            "cap": "24040",
                            "nome": "Lallio"
                        },
                        {
                            "code": "016124",
                            "cap": "24026",
                            "nome": "Leffe"
                        },
                        {
                            "code": "016125",
                            "cap": "24010",
                            "nome": "Lenna"
                        },
                        {
                            "code": "016126",
                            "cap": "24040",
                            "nome": "Levate"
                        },
                        {
                            "code": "016127",
                            "cap": "24030",
                            "nome": "Locatello"
                        },
                        {
                            "code": "016128",
                            "cap": "24065",
                            "nome": "Lovere"
                        },
                        {
                            "code": "016129",
                            "cap": "24050",
                            "nome": "Lurano"
                        },
                        {
                            "code": "016130",
                            "cap": "24069",
                            "nome": "Luzzana"
                        },
                        {
                            "code": "016131",
                            "cap": "24040",
                            "nome": "Madone"
                        },
                        {
                            "code": "016132",
                            "cap": "24030",
                            "nome": "Mapello"
                        },
                        {
                            "code": "016133",
                            "cap": "24057",
                            "nome": "Martinengo"
                        },
                        {
                            "code": "016250",
                            "cap": "24030",
                            "nome": "Medolago"
                        },
                        {
                            "code": "016134",
                            "cap": "24010",
                            "nome": "Mezzoldo"
                        },
                        {
                            "code": "016135",
                            "cap": "24040",
                            "nome": "Misano di Gera d'Adda"
                        },
                        {
                            "code": "016136",
                            "cap": "24010",
                            "nome": "Moio de' Calvi"
                        },
                        {
                            "code": "016137",
                            "cap": "24060",
                            "nome": "Monasterolo del Castello"
                        },
                        {
                            "code": "016139",
                            "cap": "24060",
                            "nome": "Montello"
                        },
                        {
                            "code": "016140",
                            "cap": "24050",
                            "nome": "Morengo"
                        },
                        {
                            "code": "016141",
                            "cap": "24050",
                            "nome": "Mornico al Serio"
                        },
                        {
                            "code": "016142",
                            "cap": "24050",
                            "nome": "Mozzanica"
                        },
                        {
                            "code": "016143",
                            "cap": "24030",
                            "nome": "Mozzo"
                        },
                        {
                            "code": "016144",
                            "cap": "24027",
                            "nome": "Nembro"
                        },
                        {
                            "code": "016145",
                            "cap": "24010",
                            "nome": "Olmo al Brembo"
                        },
                        {
                            "code": "016146",
                            "cap": "24013",
                            "nome": "Oltre il Colle"
                        },
                        {
                            "code": "016147",
                            "cap": "24020",
                            "nome": "Oltressenda Alta"
                        },
                        {
                            "code": "016148",
                            "cap": "24020",
                            "nome": "Oneta"
                        },
                        {
                            "code": "016149",
                            "cap": "24020",
                            "nome": "Onore"
                        },
                        {
                            "code": "016150",
                            "cap": "24050",
                            "nome": "Orio al Serio"
                        },
                        {
                            "code": "016151",
                            "cap": "24010",
                            "nome": "Ornica"
                        },
                        {
                            "code": "016152",
                            "cap": "24040",
                            "nome": "Osio Sopra"
                        },
                        {
                            "code": "016153",
                            "cap": "24046",
                            "nome": "Osio Sotto"
                        },
                        {
                            "code": "016154",
                            "cap": "24040",
                            "nome": "Pagazzano"
                        },
                        {
                            "code": "016155",
                            "cap": "24030",
                            "nome": "Paladina"
                        },
                        {
                            "code": "016156",
                            "cap": "24030",
                            "nome": "Palazzago"
                        },
                        {
                            "code": "016157",
                            "cap": "24050",
                            "nome": "Palosco"
                        },
                        {
                            "code": "016158",
                            "cap": "24020",
                            "nome": "Parre"
                        },
                        {
                            "code": "016159",
                            "cap": "24060",
                            "nome": "Parzanica"
                        },
                        {
                            "code": "016160",
                            "cap": "24066",
                            "nome": "Pedrengo"
                        },
                        {
                            "code": "016161",
                            "cap": "24020",
                            "nome": "Peia"
                        },
                        {
                            "code": "016162",
                            "cap": "24060",
                            "nome": "Pianico"
                        },
                        {
                            "code": "016163",
                            "cap": "24020",
                            "nome": "Piario"
                        },
                        {
                            "code": "016164",
                            "cap": "24014",
                            "nome": "Piazza Brembana"
                        },
                        {
                            "code": "016165",
                            "cap": "24010",
                            "nome": "Piazzatorre"
                        },
                        {
                            "code": "016166",
                            "cap": "24010",
                            "nome": "Piazzolo"
                        },
                        {
                            "code": "016167",
                            "cap": "24040",
                            "nome": "Pognano"
                        },
                        {
                            "code": "016168",
                            "cap": "24028",
                            "nome": "Ponte Nossa"
                        },
                        {
                            "code": "016170",
                            "cap": "24036",
                            "nome": "Ponte San Pietro"
                        },
                        {
                            "code": "016169",
                            "cap": "24010",
                            "nome": "Ponteranica"
                        },
                        {
                            "code": "016171",
                            "cap": "24030",
                            "nome": "Pontida"
                        },
                        {
                            "code": "016172",
                            "cap": "24040",
                            "nome": "Pontirolo Nuovo"
                        },
                        {
                            "code": "016173",
                            "cap": "24020",
                            "nome": "Pradalunga"
                        },
                        {
                            "code": "016174",
                            "cap": "24060",
                            "nome": "Predore"
                        },
                        {
                            "code": "016175",
                            "cap": "24020",
                            "nome": "Premolo"
                        },
                        {
                            "code": "016176",
                            "cap": "24030",
                            "nome": "Presezzo"
                        },
                        {
                            "code": "016177",
                            "cap": "24050",
                            "nome": "Pumenengo"
                        },
                        {
                            "code": "016178",
                            "cap": "24020",
                            "nome": "Ranica"
                        },
                        {
                            "code": "016179",
                            "cap": "24060",
                            "nome": "Ranzanico"
                        },
                        {
                            "code": "016180",
                            "cap": "24060",
                            "nome": "Riva di Solto"
                        },
                        {
                            "code": "016182",
                            "cap": "24060",
                            "nome": "Rogno"
                        },
                        {
                            "code": "016183",
                            "cap": "24058",
                            "nome": "Romano di Lombardia"
                        },
                        {
                            "code": "016184",
                            "cap": "24010",
                            "nome": "Roncobello"
                        },
                        {
                            "code": "016185",
                            "cap": "24030",
                            "nome": "Roncola"
                        },
                        {
                            "code": "016186",
                            "cap": "24037",
                            "nome": "Rota d'Imagna"
                        },
                        {
                            "code": "016187",
                            "cap": "24020",
                            "nome": "Rovetta"
                        },
                        {
                            "code": "016188",
                            "cap": "24015",
                            "nome": "San Giovanni Bianco"
                        },
                        {
                            "code": "016189",
                            "cap": "24060",
                            "nome": "San Paolo d'Argon"
                        },
                        {
                            "code": "016190",
                            "cap": "24016",
                            "nome": "San Pellegrino Terme"
                        },
                        {
                            "code": "016191",
                            "cap": "24010",
                            "nome": "Santa Brigida"
                        },
                        {
                            "code": "016192",
                            "cap": "24038",
                            "nome": "Sant'Omobono Terme"
                        },
                        {
                            "code": "016193",
                            "cap": "24067",
                            "nome": "Sarnico"
                        },
                        {
                            "code": "016194",
                            "cap": "24020",
                            "nome": "Scanzorosciate"
                        },
                        {
                            "code": "016195",
                            "cap": "24020",
                            "nome": "Schilpario"
                        },
                        {
                            "code": "016196",
                            "cap": "24010",
                            "nome": "Sedrina"
                        },
                        {
                            "code": "016197",
                            "cap": "24020",
                            "nome": "Selvino"
                        },
                        {
                            "code": "016198",
                            "cap": "24068",
                            "nome": "Seriate"
                        },
                        {
                            "code": "016199",
                            "cap": "24017",
                            "nome": "Serina"
                        },
                        {
                            "code": "016200",
                            "cap": "24060",
                            "nome": "Solto Collina"
                        },
                        {
                            "code": "016251",
                            "cap": "24030",
                            "nome": "Solza"
                        },
                        {
                            "code": "016201",
                            "cap": "24020",
                            "nome": "Songavazzo"
                        },
                        {
                            "code": "016202",
                            "cap": "24010",
                            "nome": "Sorisole"
                        },
                        {
                            "code": "016203",
                            "cap": "24039",
                            "nome": "Sotto il Monte Giovanni XXIII"
                        },
                        {
                            "code": "016204",
                            "cap": "24060",
                            "nome": "Sovere"
                        },
                        {
                            "code": "016205",
                            "cap": "24060",
                            "nome": "Spinone al Lago"
                        },
                        {
                            "code": "016206",
                            "cap": "24050",
                            "nome": "Spirano"
                        },
                        {
                            "code": "016207",
                            "cap": "24040",
                            "nome": "Stezzano"
                        },
                        {
                            "code": "016208",
                            "cap": "24030",
                            "nome": "Strozza"
                        },
                        {
                            "code": "016209",
                            "cap": "24040",
                            "nome": "Suisio"
                        },
                        {
                            "code": "016210",
                            "cap": "24010",
                            "nome": "Taleggio"
                        },
                        {
                            "code": "016211",
                            "cap": "24060",
                            "nome": "Tavernola Bergamasca"
                        },
                        {
                            "code": "016212",
                            "cap": "24060",
                            "nome": "Telgate"
                        },
                        {
                            "code": "016213",
                            "cap": "24030",
                            "nome": "Terno d'Isola"
                        },
                        {
                            "code": "016214",
                            "cap": "24020",
                            "nome": "Torre Boldone"
                        },
                        {
                            "code": "016216",
                            "cap": "24060",
                            "nome": "Torre de' Roveri"
                        },
                        {
                            "code": "016217",
                            "cap": "24050",
                            "nome": "Torre Pallavicina"
                        },
                        {
                            "code": "016218",
                            "cap": "24069",
                            "nome": "Trescore Balneario"
                        },
                        {
                            "code": "016219",
                            "cap": "24047",
                            "nome": "Treviglio"
                        },
                        {
                            "code": "016220",
                            "cap": "24048",
                            "nome": "Treviolo"
                        },
                        {
                            "code": "016221",
                            "cap": "24010",
                            "nome": "Ubiale Clanezzo"
                        },
                        {
                            "code": "016222",
                            "cap": "24059",
                            "nome": "Urgnano"
                        },
                        {
                            "code": "016223",
                            "cap": "24020",
                            "nome": "Valbondione"
                        },
                        {
                            "code": "016224",
                            "cap": "24030",
                            "nome": "Valbrembo"
                        },
                        {
                            "code": "016225",
                            "cap": "24020",
                            "nome": "Valgoglio"
                        },
                        {
                            "code": "016226",
                            "cap": "24010",
                            "nome": "Valleve"
                        },
                        {
                            "code": "016227",
                            "cap": "24010",
                            "nome": "Valnegra"
                        },
                        {
                            "code": "016228",
                            "cap": "24030",
                            "nome": "Valsecca"
                        },
                        {
                            "code": "016229",
                            "cap": "24010",
                            "nome": "Valtorta"
                        },
                        {
                            "code": "016230",
                            "cap": "24010",
                            "nome": "Vedeseta"
                        },
                        {
                            "code": "016232",
                            "cap": "24040",
                            "nome": "Verdellino"
                        },
                        {
                            "code": "016233",
                            "cap": "24049",
                            "nome": "Verdello"
                        },
                        {
                            "code": "016234",
                            "cap": "24029",
                            "nome": "Vertova"
                        },
                        {
                            "code": "016235",
                            "cap": "24060",
                            "nome": "Viadanica"
                        },
                        {
                            "code": "016236",
                            "cap": "24060",
                            "nome": "Vigano San Martino"
                        },
                        {
                            "code": "016237",
                            "cap": "24060",
                            "nome": "Vigolo"
                        },
                        {
                            "code": "016238",
                            "cap": "24030",
                            "nome": "Villa d'Adda"
                        },
                        {
                            "code": "016239",
                            "cap": "24018",
                            "nome": "Villa d'Alm\u00e8"
                        },
                        {
                            "code": "016240",
                            "cap": "24020",
                            "nome": "Villa di Serio"
                        },
                        {
                            "code": "016241",
                            "cap": "24020",
                            "nome": "Villa d'Ogna"
                        },
                        {
                            "code": "016242",
                            "cap": "24060",
                            "nome": "Villongo"
                        },
                        {
                            "code": "016243",
                            "cap": "24020",
                            "nome": "Vilminore di Scalve"
                        },
                        {
                            "code": "016244",
                            "cap": "24060",
                            "nome": "Zandobbio"
                        },
                        {
                            "code": "016245",
                            "cap": "24050",
                            "nome": "Zanica"
                        },
                        {
                            "code": "016246",
                            "cap": "24019",
                            "nome": "Zogno"
                        }
                    ],
                    "nome": "Bergamo"
                },
                {
                    "code": "BS",
                    "comuni": [
                        {
                            "code": "017001",
                            "cap": "25010",
                            "nome": "Acquafredda"
                        },
                        {
                            "code": "017002",
                            "cap": "25030",
                            "nome": "Adro"
                        },
                        {
                            "code": "017003",
                            "cap": "25071",
                            "nome": "Agnosine"
                        },
                        {
                            "code": "017004",
                            "cap": "25020",
                            "nome": "Alfianello"
                        },
                        {
                            "code": "017005",
                            "cap": "25070",
                            "nome": "Anfo"
                        },
                        {
                            "code": "017006",
                            "cap": "25040",
                            "nome": "Angolo Terme"
                        },
                        {
                            "code": "017007",
                            "cap": "25040",
                            "nome": "Artogne"
                        },
                        {
                            "code": "017008",
                            "cap": "25020",
                            "nome": "Azzano Mella"
                        },
                        {
                            "code": "017009",
                            "cap": "25021",
                            "nome": "Bagnolo Mella"
                        },
                        {
                            "code": "017010",
                            "cap": "25072",
                            "nome": "Bagolino"
                        },
                        {
                            "code": "017011",
                            "cap": "25030",
                            "nome": "Barbariga"
                        },
                        {
                            "code": "017012",
                            "cap": "25070",
                            "nome": "Barghe"
                        },
                        {
                            "code": "017013",
                            "cap": "25020",
                            "nome": "Bassano Bresciano"
                        },
                        {
                            "code": "017014",
                            "cap": "25081",
                            "nome": "Bedizzole"
                        },
                        {
                            "code": "017015",
                            "cap": "25030",
                            "nome": "Berlingo"
                        },
                        {
                            "code": "017016",
                            "cap": "25040",
                            "nome": "Berzo Demo"
                        },
                        {
                            "code": "017017",
                            "cap": "25040",
                            "nome": "Berzo Inferiore"
                        },
                        {
                            "code": "017018",
                            "cap": "25040",
                            "nome": "Bienno"
                        },
                        {
                            "code": "017019",
                            "cap": "25070",
                            "nome": "Bione"
                        },
                        {
                            "code": "017020",
                            "cap": "25022",
                            "nome": "Borgo San Giacomo"
                        },
                        {
                            "code": "017021",
                            "cap": "25010",
                            "nome": "Borgosatollo"
                        },
                        {
                            "code": "017022",
                            "cap": "25042",
                            "nome": "Borno"
                        },
                        {
                            "code": "017023",
                            "cap": "25082",
                            "nome": "Botticino"
                        },
                        {
                            "code": "017024",
                            "cap": "25061",
                            "nome": "Bovegno"
                        },
                        {
                            "code": "017025",
                            "cap": "25073",
                            "nome": "Bovezzo"
                        },
                        {
                            "code": "017026",
                            "cap": "25030",
                            "nome": "Brandico"
                        },
                        {
                            "code": "017027",
                            "cap": "25040",
                            "nome": "Braone"
                        },
                        {
                            "code": "017028",
                            "cap": "25043",
                            "nome": "Breno"
                        },
                        {
                            "code": "017029",
                            "cap": "251xx",
                            "nome": "Brescia"
                        },
                        {
                            "code": "017030",
                            "cap": "25060",
                            "nome": "Brione"
                        },
                        {
                            "code": "017031",
                            "cap": "25070",
                            "nome": "Caino"
                        },
                        {
                            "code": "017032",
                            "cap": "25011",
                            "nome": "Calcinato"
                        },
                        {
                            "code": "017033",
                            "cap": "25080",
                            "nome": "Calvagese della Riviera"
                        },
                        {
                            "code": "017034",
                            "cap": "25012",
                            "nome": "Calvisano"
                        },
                        {
                            "code": "017035",
                            "cap": "25044",
                            "nome": "Capo di Ponte"
                        },
                        {
                            "code": "017036",
                            "cap": "25070",
                            "nome": "Capovalle"
                        },
                        {
                            "code": "017037",
                            "cap": "25020",
                            "nome": "Capriano del Colle"
                        },
                        {
                            "code": "017038",
                            "cap": "25031",
                            "nome": "Capriolo"
                        },
                        {
                            "code": "017039",
                            "cap": "25013",
                            "nome": "Carpenedolo"
                        },
                        {
                            "code": "017040",
                            "cap": "25045",
                            "nome": "Castegnato"
                        },
                        {
                            "code": "017042",
                            "cap": "25030",
                            "nome": "Castel Mella"
                        },
                        {
                            "code": "017041",
                            "cap": "25030",
                            "nome": "Castelcovati"
                        },
                        {
                            "code": "017043",
                            "cap": "25014",
                            "nome": "Castenedolo"
                        },
                        {
                            "code": "017044",
                            "cap": "25070",
                            "nome": "Casto"
                        },
                        {
                            "code": "017045",
                            "cap": "25030",
                            "nome": "Castrezzato"
                        },
                        {
                            "code": "017046",
                            "cap": "25046",
                            "nome": "Cazzago San Martino"
                        },
                        {
                            "code": "017047",
                            "cap": "25051",
                            "nome": "Cedegolo"
                        },
                        {
                            "code": "017048",
                            "cap": "25060",
                            "nome": "Cellatica"
                        },
                        {
                            "code": "017049",
                            "cap": "25040",
                            "nome": "Cerveno"
                        },
                        {
                            "code": "017050",
                            "cap": "25040",
                            "nome": "Ceto"
                        },
                        {
                            "code": "017051",
                            "cap": "25040",
                            "nome": "Cevo"
                        },
                        {
                            "code": "017052",
                            "cap": "25032",
                            "nome": "Chiari"
                        },
                        {
                            "code": "017053",
                            "cap": "25020",
                            "nome": "Cigole"
                        },
                        {
                            "code": "017054",
                            "cap": "25050",
                            "nome": "Cimbergo"
                        },
                        {
                            "code": "017055",
                            "cap": "25040",
                            "nome": "Cividate Camuno"
                        },
                        {
                            "code": "017056",
                            "cap": "25030",
                            "nome": "Coccaglio"
                        },
                        {
                            "code": "017057",
                            "cap": "25060",
                            "nome": "Collebeato"
                        },
                        {
                            "code": "017058",
                            "cap": "25060",
                            "nome": "Collio"
                        },
                        {
                            "code": "017059",
                            "cap": "25033",
                            "nome": "Cologne"
                        },
                        {
                            "code": "017060",
                            "cap": "25030",
                            "nome": "Comezzano-Cizzago"
                        },
                        {
                            "code": "017061",
                            "cap": "25062",
                            "nome": "Concesio"
                        },
                        {
                            "code": "017062",
                            "cap": "25040",
                            "nome": "Corte Franca"
                        },
                        {
                            "code": "017063",
                            "cap": "25040",
                            "nome": "Corteno Golgi"
                        },
                        {
                            "code": "017064",
                            "cap": "25030",
                            "nome": "Corzano"
                        },
                        {
                            "code": "017065",
                            "cap": "25047",
                            "nome": "Darfo Boario Terme"
                        },
                        {
                            "code": "017066",
                            "cap": "25020",
                            "nome": "Dello"
                        },
                        {
                            "code": "017067",
                            "cap": "25015",
                            "nome": "Desenzano del Garda"
                        },
                        {
                            "code": "017068",
                            "cap": "25048",
                            "nome": "Edolo"
                        },
                        {
                            "code": "017069",
                            "cap": "25030",
                            "nome": "Erbusco"
                        },
                        {
                            "code": "017070",
                            "cap": "25040",
                            "nome": "Esine"
                        },
                        {
                            "code": "017071",
                            "cap": "25020",
                            "nome": "Fiesse"
                        },
                        {
                            "code": "017072",
                            "cap": "25020",
                            "nome": "Flero"
                        },
                        {
                            "code": "017073",
                            "cap": "25020",
                            "nome": "Gambara"
                        },
                        {
                            "code": "017074",
                            "cap": "25083",
                            "nome": "Gardone Riviera"
                        },
                        {
                            "code": "017075",
                            "cap": "25063",
                            "nome": "Gardone Val Trompia"
                        },
                        {
                            "code": "017076",
                            "cap": "25084",
                            "nome": "Gargnano"
                        },
                        {
                            "code": "017077",
                            "cap": "25085",
                            "nome": "Gavardo"
                        },
                        {
                            "code": "017078",
                            "cap": "25016",
                            "nome": "Ghedi"
                        },
                        {
                            "code": "017079",
                            "cap": "25040",
                            "nome": "Gianico"
                        },
                        {
                            "code": "017080",
                            "cap": "25023",
                            "nome": "Gottolengo"
                        },
                        {
                            "code": "017081",
                            "cap": "25064",
                            "nome": "Gussago"
                        },
                        {
                            "code": "017082",
                            "cap": "25074",
                            "nome": "Idro"
                        },
                        {
                            "code": "017083",
                            "cap": "25040",
                            "nome": "Incudine"
                        },
                        {
                            "code": "017084",
                            "cap": "25061",
                            "nome": "Irma"
                        },
                        {
                            "code": "017085",
                            "cap": "25049",
                            "nome": "Iseo"
                        },
                        {
                            "code": "017086",
                            "cap": "25010",
                            "nome": "Isorella"
                        },
                        {
                            "code": "017087",
                            "cap": "25074",
                            "nome": "Lavenone"
                        },
                        {
                            "code": "017088",
                            "cap": "25024",
                            "nome": "Leno"
                        },
                        {
                            "code": "017089",
                            "cap": "25010",
                            "nome": "Limone sul Garda"
                        },
                        {
                            "code": "017090",
                            "cap": "25060",
                            "nome": "Lodrino"
                        },
                        {
                            "code": "017091",
                            "cap": "25030",
                            "nome": "Lograto"
                        },
                        {
                            "code": "017092",
                            "cap": "25017",
                            "nome": "Lonato del Garda"
                        },
                        {
                            "code": "017093",
                            "cap": "25030",
                            "nome": "Longhena"
                        },
                        {
                            "code": "017094",
                            "cap": "25050",
                            "nome": "Losine"
                        },
                        {
                            "code": "017095",
                            "cap": "25040",
                            "nome": "Lozio"
                        },
                        {
                            "code": "017096",
                            "cap": "25065",
                            "nome": "Lumezzane"
                        },
                        {
                            "code": "017097",
                            "cap": "25030",
                            "nome": "Maclodio"
                        },
                        {
                            "code": "017098",
                            "cap": "25080",
                            "nome": "Magasa"
                        },
                        {
                            "code": "017099",
                            "cap": "25030",
                            "nome": "Mairano"
                        },
                        {
                            "code": "017100",
                            "cap": "25053",
                            "nome": "Malegno"
                        },
                        {
                            "code": "017101",
                            "cap": "25040",
                            "nome": "Malonno"
                        },
                        {
                            "code": "017102",
                            "cap": "25080",
                            "nome": "Manerba del Garda"
                        },
                        {
                            "code": "017103",
                            "cap": "25025",
                            "nome": "Manerbio"
                        },
                        {
                            "code": "017104",
                            "cap": "25060",
                            "nome": "Marcheno"
                        },
                        {
                            "code": "017105",
                            "cap": "25060",
                            "nome": "Marmentino"
                        },
                        {
                            "code": "017106",
                            "cap": "25054",
                            "nome": "Marone"
                        },
                        {
                            "code": "017107",
                            "cap": "25080",
                            "nome": "Mazzano"
                        },
                        {
                            "code": "017108",
                            "cap": "25020",
                            "nome": "Milzano"
                        },
                        {
                            "code": "017109",
                            "cap": "25080",
                            "nome": "Moniga del Garda"
                        },
                        {
                            "code": "017110",
                            "cap": "25040",
                            "nome": "Monno"
                        },
                        {
                            "code": "017111",
                            "cap": "25050",
                            "nome": "Monte Isola"
                        },
                        {
                            "code": "017112",
                            "cap": "25040",
                            "nome": "Monticelli Brusati"
                        },
                        {
                            "code": "017113",
                            "cap": "25018",
                            "nome": "Montichiari"
                        },
                        {
                            "code": "017114",
                            "cap": "25010",
                            "nome": "Montirone"
                        },
                        {
                            "code": "017115",
                            "cap": "25070",
                            "nome": "Mura"
                        },
                        {
                            "code": "017116",
                            "cap": "25080",
                            "nome": "Muscoline"
                        },
                        {
                            "code": "017117",
                            "cap": "25075",
                            "nome": "Nave"
                        },
                        {
                            "code": "017118",
                            "cap": "25050",
                            "nome": "Niardo"
                        },
                        {
                            "code": "017119",
                            "cap": "25080",
                            "nome": "Nuvolento"
                        },
                        {
                            "code": "017120",
                            "cap": "25080",
                            "nome": "Nuvolera"
                        },
                        {
                            "code": "017121",
                            "cap": "25076",
                            "nome": "Odolo"
                        },
                        {
                            "code": "017122",
                            "cap": "25020",
                            "nome": "Offlaga"
                        },
                        {
                            "code": "017123",
                            "cap": "25050",
                            "nome": "Ome"
                        },
                        {
                            "code": "017124",
                            "cap": "25040",
                            "nome": "Ono San Pietro"
                        },
                        {
                            "code": "017125",
                            "cap": "25034",
                            "nome": "Orzinuovi"
                        },
                        {
                            "code": "017126",
                            "cap": "25030",
                            "nome": "Orzivecchi"
                        },
                        {
                            "code": "017127",
                            "cap": "25035",
                            "nome": "Ospitaletto"
                        },
                        {
                            "code": "017128",
                            "cap": "25050",
                            "nome": "Ossimo"
                        },
                        {
                            "code": "017129",
                            "cap": "25080",
                            "nome": "Padenghe sul Garda"
                        },
                        {
                            "code": "017130",
                            "cap": "25050",
                            "nome": "Paderno Franciacorta"
                        },
                        {
                            "code": "017131",
                            "cap": "25050",
                            "nome": "Paisco Loveno"
                        },
                        {
                            "code": "017132",
                            "cap": "25080",
                            "nome": "Paitone"
                        },
                        {
                            "code": "017133",
                            "cap": "25036",
                            "nome": "Palazzolo sull'Oglio"
                        },
                        {
                            "code": "017134",
                            "cap": "25030",
                            "nome": "Paratico"
                        },
                        {
                            "code": "017135",
                            "cap": "25050",
                            "nome": "Paspardo"
                        },
                        {
                            "code": "017136",
                            "cap": "25050",
                            "nome": "Passirano"
                        },
                        {
                            "code": "017137",
                            "cap": "25020",
                            "nome": "Pavone del Mella"
                        },
                        {
                            "code": "017139",
                            "cap": "25070",
                            "nome": "Pertica Alta"
                        },
                        {
                            "code": "017140",
                            "cap": "25078",
                            "nome": "Pertica Bassa"
                        },
                        {
                            "code": "017141",
                            "cap": "25060",
                            "nome": "Pezzaze"
                        },
                        {
                            "code": "017142",
                            "cap": "25050",
                            "nome": "Pian Camuno"
                        },
                        {
                            "code": "017206",
                            "cap": "25052",
                            "nome": "Piancogno"
                        },
                        {
                            "code": "017143",
                            "cap": "25055",
                            "nome": "Pisogne"
                        },
                        {
                            "code": "017144",
                            "cap": "25060",
                            "nome": "Polaveno"
                        },
                        {
                            "code": "017145",
                            "cap": "25080",
                            "nome": "Polpenazze del Garda"
                        },
                        {
                            "code": "017146",
                            "cap": "25030",
                            "nome": "Pompiano"
                        },
                        {
                            "code": "017147",
                            "cap": "25020",
                            "nome": "Poncarale"
                        },
                        {
                            "code": "017148",
                            "cap": "25056",
                            "nome": "Ponte di Legno"
                        },
                        {
                            "code": "017149",
                            "cap": "25026",
                            "nome": "Pontevico"
                        },
                        {
                            "code": "017150",
                            "cap": "25037",
                            "nome": "Pontoglio"
                        },
                        {
                            "code": "017151",
                            "cap": "25010",
                            "nome": "Pozzolengo"
                        },
                        {
                            "code": "017152",
                            "cap": "25020",
                            "nome": "Pralboino"
                        },
                        {
                            "code": "017153",
                            "cap": "25070",
                            "nome": "Preseglie"
                        },
                        {
                            "code": "017154",
                            "cap": "25040",
                            "nome": "Prestine"
                        },
                        {
                            "code": "017155",
                            "cap": "25080",
                            "nome": "Prevalle"
                        },
                        {
                            "code": "017156",
                            "cap": "25050",
                            "nome": "Provaglio d'Iseo"
                        },
                        {
                            "code": "017157",
                            "cap": "25070",
                            "nome": "Provaglio Val Sabbia"
                        },
                        {
                            "code": "017158",
                            "cap": "25080",
                            "nome": "Puegnago sul Garda"
                        },
                        {
                            "code": "017159",
                            "cap": "25027",
                            "nome": "Quinzano d'Oglio"
                        },
                        {
                            "code": "017160",
                            "cap": "25010",
                            "nome": "Remedello"
                        },
                        {
                            "code": "017161",
                            "cap": "25086",
                            "nome": "Rezzato"
                        },
                        {
                            "code": "017162",
                            "cap": "25030",
                            "nome": "Roccafranca"
                        },
                        {
                            "code": "017163",
                            "cap": "25050",
                            "nome": "Rodengo Saiano"
                        },
                        {
                            "code": "017164",
                            "cap": "25077",
                            "nome": "Ro\u00e8 Volciano"
                        },
                        {
                            "code": "017165",
                            "cap": "25030",
                            "nome": "Roncadelle"
                        },
                        {
                            "code": "017166",
                            "cap": "25038",
                            "nome": "Rovato"
                        },
                        {
                            "code": "017167",
                            "cap": "25030",
                            "nome": "Rudiano"
                        },
                        {
                            "code": "017168",
                            "cap": "25070",
                            "nome": "Sabbio Chiese"
                        },
                        {
                            "code": "017169",
                            "cap": "25057",
                            "nome": "Sale Marasino"
                        },
                        {
                            "code": "017170",
                            "cap": "25087",
                            "nome": "Sal\u00f2"
                        },
                        {
                            "code": "017171",
                            "cap": "25010",
                            "nome": "San Felice del Benaco"
                        },
                        {
                            "code": "017172",
                            "cap": "25020",
                            "nome": "San Gervasio Bresciano"
                        },
                        {
                            "code": "017138",
                            "cap": "25020",
                            "nome": "San Paolo"
                        },
                        {
                            "code": "017173",
                            "cap": "25010",
                            "nome": "San Zeno Naviglio"
                        },
                        {
                            "code": "017174",
                            "cap": "25068",
                            "nome": "Sarezzo"
                        },
                        {
                            "code": "017175",
                            "cap": "25040",
                            "nome": "Saviore dell'Adamello"
                        },
                        {
                            "code": "017176",
                            "cap": "25050",
                            "nome": "Sellero"
                        },
                        {
                            "code": "017177",
                            "cap": "25020",
                            "nome": "Seniga"
                        },
                        {
                            "code": "017178",
                            "cap": "25080",
                            "nome": "Serle"
                        },
                        {
                            "code": "017179",
                            "cap": "25019",
                            "nome": "Sirmione"
                        },
                        {
                            "code": "017180",
                            "cap": "25080",
                            "nome": "Soiano del Lago"
                        },
                        {
                            "code": "017181",
                            "cap": "25048",
                            "nome": "Sonico"
                        },
                        {
                            "code": "017182",
                            "cap": "25058",
                            "nome": "Sulzano"
                        },
                        {
                            "code": "017183",
                            "cap": "25060",
                            "nome": "Tavernole sul Mella"
                        },
                        {
                            "code": "017184",
                            "cap": "25050",
                            "nome": "Tem\u00f9"
                        },
                        {
                            "code": "017185",
                            "cap": "25080",
                            "nome": "Tignale"
                        },
                        {
                            "code": "017186",
                            "cap": "25030",
                            "nome": "Torbole Casaglia"
                        },
                        {
                            "code": "017187",
                            "cap": "25088",
                            "nome": "Toscolano-Maderno"
                        },
                        {
                            "code": "017188",
                            "cap": "25039",
                            "nome": "Travagliato"
                        },
                        {
                            "code": "017189",
                            "cap": "25010",
                            "nome": "Tremosine"
                        },
                        {
                            "code": "017190",
                            "cap": "25030",
                            "nome": "Trenzano"
                        },
                        {
                            "code": "017191",
                            "cap": "25070",
                            "nome": "Treviso Bresciano"
                        },
                        {
                            "code": "017192",
                            "cap": "25030",
                            "nome": "Urago d'Oglio"
                        },
                        {
                            "code": "017193",
                            "cap": "25080",
                            "nome": "Vallio Terme"
                        },
                        {
                            "code": "017194",
                            "cap": "25080",
                            "nome": "Valvestino"
                        },
                        {
                            "code": "017195",
                            "cap": "25028",
                            "nome": "Verolanuova"
                        },
                        {
                            "code": "017196",
                            "cap": "25029",
                            "nome": "Verolavecchia"
                        },
                        {
                            "code": "017197",
                            "cap": "25078",
                            "nome": "Vestone"
                        },
                        {
                            "code": "017198",
                            "cap": "25059",
                            "nome": "Vezza d'Oglio"
                        },
                        {
                            "code": "017199",
                            "cap": "25069",
                            "nome": "Villa Carcina"
                        },
                        {
                            "code": "017200",
                            "cap": "25030",
                            "nome": "Villachiara"
                        },
                        {
                            "code": "017201",
                            "cap": "25089",
                            "nome": "Villanuova sul Clisi"
                        },
                        {
                            "code": "017202",
                            "cap": "25050",
                            "nome": "Vione"
                        },
                        {
                            "code": "017203",
                            "cap": "25010",
                            "nome": "Visano"
                        },
                        {
                            "code": "017204",
                            "cap": "25079",
                            "nome": "Vobarno"
                        },
                        {
                            "code": "017205",
                            "cap": "25050",
                            "nome": "Zone"
                        }
                    ],
                    "nome": "Brescia"
                },
                {
                    "code": "CO",
                    "comuni": [
                        {
                            "code": "013003",
                            "cap": "22031",
                            "nome": "Albavilla"
                        },
                        {
                            "code": "013004",
                            "cap": "22032",
                            "nome": "Albese con Cassano"
                        },
                        {
                            "code": "013005",
                            "cap": "22070",
                            "nome": "Albiolo"
                        },
                        {
                            "code": "013006",
                            "cap": "22040",
                            "nome": "Alserio"
                        },
                        {
                            "code": "013007",
                            "cap": "22040",
                            "nome": "Alzate Brianza"
                        },
                        {
                            "code": "013009",
                            "cap": "22040",
                            "nome": "Anzano del Parco"
                        },
                        {
                            "code": "013010",
                            "cap": "22070",
                            "nome": "Appiano Gentile"
                        },
                        {
                            "code": "013011",
                            "cap": "22010",
                            "nome": "Argegno"
                        },
                        {
                            "code": "013012",
                            "cap": "22060",
                            "nome": "Arosio"
                        },
                        {
                            "code": "013013",
                            "cap": "22033",
                            "nome": "Asso"
                        },
                        {
                            "code": "013015",
                            "cap": "22030",
                            "nome": "Barni"
                        },
                        {
                            "code": "013019",
                            "cap": "22021",
                            "nome": "Bellagio"
                        },
                        {
                            "code": "013021",
                            "cap": "22010",
                            "nome": "Bene Lario"
                        },
                        {
                            "code": "013022",
                            "cap": "22070",
                            "nome": "Beregazzo con Figliaro"
                        },
                        {
                            "code": "013023",
                            "cap": "22070",
                            "nome": "Binago"
                        },
                        {
                            "code": "013024",
                            "cap": "22020",
                            "nome": "Bizzarone"
                        },
                        {
                            "code": "013025",
                            "cap": "22028",
                            "nome": "Blessagno"
                        },
                        {
                            "code": "013026",
                            "cap": "22020",
                            "nome": "Blevio"
                        },
                        {
                            "code": "013028",
                            "cap": "22070",
                            "nome": "Bregnano"
                        },
                        {
                            "code": "013029",
                            "cap": "22040",
                            "nome": "Brenna"
                        },
                        {
                            "code": "013030",
                            "cap": "22010",
                            "nome": "Brienno"
                        },
                        {
                            "code": "013032",
                            "cap": "22034",
                            "nome": "Brunate"
                        },
                        {
                            "code": "013034",
                            "cap": "22070",
                            "nome": "Bulgarograsso"
                        },
                        {
                            "code": "013035",
                            "cap": "22060",
                            "nome": "Cabiate"
                        },
                        {
                            "code": "013036",
                            "cap": "22071",
                            "nome": "Cadorago"
                        },
                        {
                            "code": "013037",
                            "cap": "22030",
                            "nome": "Caglio"
                        },
                        {
                            "code": "013038",
                            "cap": "22070",
                            "nome": "Cagno"
                        },
                        {
                            "code": "013040",
                            "cap": "22060",
                            "nome": "Campione d'Italia"
                        },
                        {
                            "code": "013041",
                            "cap": "22063",
                            "nome": "Cant\u00f9"
                        },
                        {
                            "code": "013042",
                            "cap": "22035",
                            "nome": "Canzo"
                        },
                        {
                            "code": "013043",
                            "cap": "22070",
                            "nome": "Capiago Intimiano"
                        },
                        {
                            "code": "013044",
                            "cap": "22010",
                            "nome": "Carate Urio"
                        },
                        {
                            "code": "013045",
                            "cap": "22070",
                            "nome": "Carbonate"
                        },
                        {
                            "code": "013046",
                            "cap": "22060",
                            "nome": "Carimate"
                        },
                        {
                            "code": "013047",
                            "cap": "22010",
                            "nome": "Carlazzo"
                        },
                        {
                            "code": "013048",
                            "cap": "22060",
                            "nome": "Carugo"
                        },
                        {
                            "code": "013050",
                            "cap": "22022",
                            "nome": "Casasco d'Intelvi"
                        },
                        {
                            "code": "013052",
                            "cap": "22030",
                            "nome": "Caslino d'Erba"
                        },
                        {
                            "code": "013053",
                            "cap": "22070",
                            "nome": "Casnate con Bernate"
                        },
                        {
                            "code": "013055",
                            "cap": "22070",
                            "nome": "Cassina Rizzardi"
                        },
                        {
                            "code": "013058",
                            "cap": "22030",
                            "nome": "Castelmarte"
                        },
                        {
                            "code": "013059",
                            "cap": "22070",
                            "nome": "Castelnuovo Bozzente"
                        },
                        {
                            "code": "013060",
                            "cap": "22023",
                            "nome": "Castiglione d'Intelvi"
                        },
                        {
                            "code": "013061",
                            "cap": "22020",
                            "nome": "Cavallasca"
                        },
                        {
                            "code": "013062",
                            "cap": "22010",
                            "nome": "Cavargna"
                        },
                        {
                            "code": "013063",
                            "cap": "22020",
                            "nome": "Cerano d'Intelvi"
                        },
                        {
                            "code": "013064",
                            "cap": "22072",
                            "nome": "Cermenate"
                        },
                        {
                            "code": "013065",
                            "cap": "22012",
                            "nome": "Cernobbio"
                        },
                        {
                            "code": "013068",
                            "cap": "22070",
                            "nome": "Cirimido"
                        },
                        {
                            "code": "013070",
                            "cap": "22030",
                            "nome": "Civenna"
                        },
                        {
                            "code": "013071",
                            "cap": "22010",
                            "nome": "Claino con Osteno"
                        },
                        {
                            "code": "013074",
                            "cap": "22010",
                            "nome": "Colonno"
                        },
                        {
                            "code": "013075",
                            "cap": "22100",
                            "nome": "Como"
                        },
                        {
                            "code": "013077",
                            "cap": "22010",
                            "nome": "Corrido"
                        },
                        {
                            "code": "013083",
                            "cap": "22010",
                            "nome": "Cremia"
                        },
                        {
                            "code": "013084",
                            "cap": "22060",
                            "nome": "Cucciago"
                        },
                        {
                            "code": "013085",
                            "cap": "22010",
                            "nome": "Cusino"
                        },
                        {
                            "code": "013087",
                            "cap": "22020",
                            "nome": "Dizzasco"
                        },
                        {
                            "code": "013089",
                            "cap": "22013",
                            "nome": "Domaso"
                        },
                        {
                            "code": "013090",
                            "cap": "22014",
                            "nome": "Dongo"
                        },
                        {
                            "code": "013092",
                            "cap": "22010",
                            "nome": "Dosso del Liro"
                        },
                        {
                            "code": "013093",
                            "cap": "22020",
                            "nome": "Drezzo"
                        },
                        {
                            "code": "013095",
                            "cap": "22036",
                            "nome": "Erba"
                        },
                        {
                            "code": "013097",
                            "cap": "22030",
                            "nome": "Eupilio"
                        },
                        {
                            "code": "013098",
                            "cap": "22020",
                            "nome": "Faggeto Lario"
                        },
                        {
                            "code": "013099",
                            "cap": "22020",
                            "nome": "Faloppio"
                        },
                        {
                            "code": "013100",
                            "cap": "22070",
                            "nome": "Fenegr\u00f2"
                        },
                        {
                            "code": "013101",
                            "cap": "22060",
                            "nome": "Figino Serenza"
                        },
                        {
                            "code": "013102",
                            "cap": "22073",
                            "nome": "Fino Mornasco"
                        },
                        {
                            "code": "013106",
                            "cap": "22010",
                            "nome": "Garzeno"
                        },
                        {
                            "code": "013107",
                            "cap": "22010",
                            "nome": "Gera Lario"
                        },
                        {
                            "code": "013109",
                            "cap": "22020",
                            "nome": "Gironico"
                        },
                        {
                            "code": "013110",
                            "cap": "22070",
                            "nome": "Grandate"
                        },
                        {
                            "code": "013111",
                            "cap": "22010",
                            "nome": "Grandola ed Uniti"
                        },
                        {
                            "code": "013249",
                            "cap": "22015",
                            "nome": "Gravedona ed Uniti"
                        },
                        {
                            "code": "013113",
                            "cap": "22011",
                            "nome": "Griante"
                        },
                        {
                            "code": "013114",
                            "cap": "22070",
                            "nome": "Guanzate"
                        },
                        {
                            "code": "013118",
                            "cap": "22044",
                            "nome": "Inverigo"
                        },
                        {
                            "code": "013119",
                            "cap": "22010",
                            "nome": "Laglio"
                        },
                        {
                            "code": "013120",
                            "cap": "22020",
                            "nome": "Laino"
                        },
                        {
                            "code": "013121",
                            "cap": "22045",
                            "nome": "Lambrugo"
                        },
                        {
                            "code": "013122",
                            "cap": "22024",
                            "nome": "Lanzo d'Intelvi"
                        },
                        {
                            "code": "013123",
                            "cap": "22030",
                            "nome": "Lasnigo"
                        },
                        {
                            "code": "013125",
                            "cap": "22016",
                            "nome": "Lenno"
                        },
                        {
                            "code": "013126",
                            "cap": "22025",
                            "nome": "Lezzeno"
                        },
                        {
                            "code": "013128",
                            "cap": "22070",
                            "nome": "Limido Comasco"
                        },
                        {
                            "code": "013129",
                            "cap": "22030",
                            "nome": "Lipomo"
                        },
                        {
                            "code": "013130",
                            "cap": "22010",
                            "nome": "Livo"
                        },
                        {
                            "code": "013131",
                            "cap": "22070",
                            "nome": "Locate Varesino"
                        },
                        {
                            "code": "013133",
                            "cap": "22074",
                            "nome": "Lomazzo"
                        },
                        {
                            "code": "013134",
                            "cap": "22030",
                            "nome": "Longone al Segrino"
                        },
                        {
                            "code": "013135",
                            "cap": "22070",
                            "nome": "Luisago"
                        },
                        {
                            "code": "013136",
                            "cap": "22040",
                            "nome": "Lurago d'Erba"
                        },
                        {
                            "code": "013137",
                            "cap": "22070",
                            "nome": "Lurago Marinone"
                        },
                        {
                            "code": "013138",
                            "cap": "22075",
                            "nome": "Lurate Caccivio"
                        },
                        {
                            "code": "013139",
                            "cap": "22030",
                            "nome": "Magreglio"
                        },
                        {
                            "code": "013143",
                            "cap": "22066",
                            "nome": "Mariano Comense"
                        },
                        {
                            "code": "013144",
                            "cap": "22026",
                            "nome": "Maslianico"
                        },
                        {
                            "code": "013145",
                            "cap": "22017",
                            "nome": "Menaggio"
                        },
                        {
                            "code": "013147",
                            "cap": "22046",
                            "nome": "Merone"
                        },
                        {
                            "code": "013148",
                            "cap": "22010",
                            "nome": "Mezzegra"
                        },
                        {
                            "code": "013152",
                            "cap": "22010",
                            "nome": "Moltrasio"
                        },
                        {
                            "code": "013153",
                            "cap": "22040",
                            "nome": "Monguzzo"
                        },
                        {
                            "code": "013154",
                            "cap": "22070",
                            "nome": "Montano Lucino"
                        },
                        {
                            "code": "013155",
                            "cap": "22010",
                            "nome": "Montemezzo"
                        },
                        {
                            "code": "013157",
                            "cap": "22030",
                            "nome": "Montorfano"
                        },
                        {
                            "code": "013159",
                            "cap": "22076",
                            "nome": "Mozzate"
                        },
                        {
                            "code": "013160",
                            "cap": "22010",
                            "nome": "Musso"
                        },
                        {
                            "code": "013161",
                            "cap": "22020",
                            "nome": "Nesso"
                        },
                        {
                            "code": "013163",
                            "cap": "22060",
                            "nome": "Novedrate"
                        },
                        {
                            "code": "013165",
                            "cap": "22077",
                            "nome": "Olgiate Comasco"
                        },
                        {
                            "code": "013169",
                            "cap": "22070",
                            "nome": "Oltrona di San Mamette"
                        },
                        {
                            "code": "013170",
                            "cap": "22030",
                            "nome": "Orsenigo"
                        },
                        {
                            "code": "013172",
                            "cap": "22010",
                            "nome": "Ossuccio"
                        },
                        {
                            "code": "013175",
                            "cap": "22020",
                            "nome": "Par\u00e8"
                        },
                        {
                            "code": "013178",
                            "cap": "22010",
                            "nome": "Peglio"
                        },
                        {
                            "code": "013179",
                            "cap": "22020",
                            "nome": "Pellio Intelvi"
                        },
                        {
                            "code": "013183",
                            "cap": "22010",
                            "nome": "Pianello del Lario"
                        },
                        {
                            "code": "013184",
                            "cap": "22020",
                            "nome": "Pigra"
                        },
                        {
                            "code": "013185",
                            "cap": "22010",
                            "nome": "Plesio"
                        },
                        {
                            "code": "013186",
                            "cap": "22020",
                            "nome": "Pognana Lario"
                        },
                        {
                            "code": "013187",
                            "cap": "22020",
                            "nome": "Ponna"
                        },
                        {
                            "code": "013188",
                            "cap": "22037",
                            "nome": "Ponte Lambro"
                        },
                        {
                            "code": "013189",
                            "cap": "22018",
                            "nome": "Porlezza"
                        },
                        {
                            "code": "013192",
                            "cap": "22030",
                            "nome": "Proserpio"
                        },
                        {
                            "code": "013193",
                            "cap": "22030",
                            "nome": "Pusiano"
                        },
                        {
                            "code": "013194",
                            "cap": "22020",
                            "nome": "Ramponio Verna"
                        },
                        {
                            "code": "013195",
                            "cap": "22030",
                            "nome": "Rezzago"
                        },
                        {
                            "code": "013197",
                            "cap": "22070",
                            "nome": "Rodero"
                        },
                        {
                            "code": "013199",
                            "cap": "22027",
                            "nome": "Ronago"
                        },
                        {
                            "code": "013201",
                            "cap": "22069",
                            "nome": "Rovellasca"
                        },
                        {
                            "code": "013202",
                            "cap": "22070",
                            "nome": "Rovello Porro"
                        },
                        {
                            "code": "013203",
                            "cap": "22010",
                            "nome": "Sala Comacina"
                        },
                        {
                            "code": "013204",
                            "cap": "22010",
                            "nome": "San Bartolomeo Val Cavargna"
                        },
                        {
                            "code": "013205",
                            "cap": "22028",
                            "nome": "San Fedele Intelvi"
                        },
                        {
                            "code": "013206",
                            "cap": "22020",
                            "nome": "San Fermo della Battaglia"
                        },
                        {
                            "code": "013207",
                            "cap": "22010",
                            "nome": "San Nazzaro Val Cavargna"
                        },
                        {
                            "code": "013248",
                            "cap": "22010",
                            "nome": "San Siro"
                        },
                        {
                            "code": "013211",
                            "cap": "22020",
                            "nome": "Schignano"
                        },
                        {
                            "code": "013212",
                            "cap": "22070",
                            "nome": "Senna Comasco"
                        },
                        {
                            "code": "013215",
                            "cap": "22070",
                            "nome": "Solbiate"
                        },
                        {
                            "code": "013216",
                            "cap": "22010",
                            "nome": "Sorico"
                        },
                        {
                            "code": "013217",
                            "cap": "22030",
                            "nome": "Sormano"
                        },
                        {
                            "code": "013218",
                            "cap": "22010",
                            "nome": "Stazzona"
                        },
                        {
                            "code": "013222",
                            "cap": "22038",
                            "nome": "Tavernerio"
                        },
                        {
                            "code": "013223",
                            "cap": "22020",
                            "nome": "Torno"
                        },
                        {
                            "code": "013225",
                            "cap": "22019",
                            "nome": "Tremezzo"
                        },
                        {
                            "code": "013226",
                            "cap": "22010",
                            "nome": "Trezzone"
                        },
                        {
                            "code": "013227",
                            "cap": "22078",
                            "nome": "Turate"
                        },
                        {
                            "code": "013228",
                            "cap": "22029",
                            "nome": "Uggiate-Trevano"
                        },
                        {
                            "code": "013233",
                            "cap": "22010",
                            "nome": "Val Rezzo"
                        },
                        {
                            "code": "013229",
                            "cap": "22039",
                            "nome": "Valbrona"
                        },
                        {
                            "code": "013232",
                            "cap": "22070",
                            "nome": "Valmorea"
                        },
                        {
                            "code": "013234",
                            "cap": "22010",
                            "nome": "Valsolda"
                        },
                        {
                            "code": "013236",
                            "cap": "22020",
                            "nome": "Veleso"
                        },
                        {
                            "code": "013238",
                            "cap": "22070",
                            "nome": "Veniano"
                        },
                        {
                            "code": "013239",
                            "cap": "22013",
                            "nome": "Vercana"
                        },
                        {
                            "code": "013242",
                            "cap": "22070",
                            "nome": "Vertemate con Minoprio"
                        },
                        {
                            "code": "013245",
                            "cap": "22079",
                            "nome": "Villa Guardia"
                        },
                        {
                            "code": "013246",
                            "cap": "22020",
                            "nome": "Zelbio"
                        }
                    ],
                    "nome": "Como"
                },
                {
                    "code": "CR",
                    "comuni": [
                        {
                            "code": "019001",
                            "cap": "26020",
                            "nome": "Acquanegra Cremonese"
                        },
                        {
                            "code": "019002",
                            "cap": "26020",
                            "nome": "Agnadello"
                        },
                        {
                            "code": "019003",
                            "cap": "26021",
                            "nome": "Annicco"
                        },
                        {
                            "code": "019004",
                            "cap": "26010",
                            "nome": "Azzanello"
                        },
                        {
                            "code": "019005",
                            "cap": "26010",
                            "nome": "Bagnolo Cremasco"
                        },
                        {
                            "code": "019006",
                            "cap": "26040",
                            "nome": "Bonemerse"
                        },
                        {
                            "code": "019007",
                            "cap": "26020",
                            "nome": "Bordolano"
                        },
                        {
                            "code": "019008",
                            "cap": "26030",
                            "nome": "Ca' d'Andrea"
                        },
                        {
                            "code": "019009",
                            "cap": "26030",
                            "nome": "Calvatone"
                        },
                        {
                            "code": "019010",
                            "cap": "26010",
                            "nome": "Camisano"
                        },
                        {
                            "code": "019011",
                            "cap": "26010",
                            "nome": "Campagnola Cremasca"
                        },
                        {
                            "code": "019012",
                            "cap": "26010",
                            "nome": "Capergnanica"
                        },
                        {
                            "code": "019013",
                            "cap": "26020",
                            "nome": "Cappella Cantone"
                        },
                        {
                            "code": "019014",
                            "cap": "26030",
                            "nome": "Cappella de' Picenardi"
                        },
                        {
                            "code": "019015",
                            "cap": "26010",
                            "nome": "Capralba"
                        },
                        {
                            "code": "019016",
                            "cap": "26011",
                            "nome": "Casalbuttano ed Uniti"
                        },
                        {
                            "code": "019017",
                            "cap": "26010",
                            "nome": "Casale Cremasco-Vidolasco"
                        },
                        {
                            "code": "019018",
                            "cap": "26010",
                            "nome": "Casaletto Ceredano"
                        },
                        {
                            "code": "019019",
                            "cap": "26014",
                            "nome": "Casaletto di Sopra"
                        },
                        {
                            "code": "019020",
                            "cap": "26010",
                            "nome": "Casaletto Vaprio"
                        },
                        {
                            "code": "019021",
                            "cap": "26041",
                            "nome": "Casalmaggiore"
                        },
                        {
                            "code": "019022",
                            "cap": "26020",
                            "nome": "Casalmorano"
                        },
                        {
                            "code": "019024",
                            "cap": "26010",
                            "nome": "Castel Gabbiano"
                        },
                        {
                            "code": "019023",
                            "cap": "26030",
                            "nome": "Casteldidone"
                        },
                        {
                            "code": "019025",
                            "cap": "26012",
                            "nome": "Castelleone"
                        },
                        {
                            "code": "019026",
                            "cap": "26022",
                            "nome": "Castelverde"
                        },
                        {
                            "code": "019027",
                            "cap": "26010",
                            "nome": "Castelvisconti"
                        },
                        {
                            "code": "019028",
                            "cap": "26040",
                            "nome": "Cella Dati"
                        },
                        {
                            "code": "019029",
                            "cap": "26010",
                            "nome": "Chieve"
                        },
                        {
                            "code": "019030",
                            "cap": "26030",
                            "nome": "Cicognolo"
                        },
                        {
                            "code": "019031",
                            "cap": "26042",
                            "nome": "Cingia de' Botti"
                        },
                        {
                            "code": "019032",
                            "cap": "26020",
                            "nome": "Corte de' Cortesi con Cignone"
                        },
                        {
                            "code": "019033",
                            "cap": "26010",
                            "nome": "Corte de' Frati"
                        },
                        {
                            "code": "019034",
                            "cap": "26010",
                            "nome": "Credera Rubbiano"
                        },
                        {
                            "code": "019035",
                            "cap": "26013",
                            "nome": "Crema"
                        },
                        {
                            "code": "019036",
                            "cap": "26100",
                            "nome": "Cremona"
                        },
                        {
                            "code": "019037",
                            "cap": "26010",
                            "nome": "Cremosano"
                        },
                        {
                            "code": "019038",
                            "cap": "26020",
                            "nome": "Crotta d'Adda"
                        },
                        {
                            "code": "019039",
                            "cap": "26020",
                            "nome": "Cumignano sul Naviglio"
                        },
                        {
                            "code": "019040",
                            "cap": "26040",
                            "nome": "Derovere"
                        },
                        {
                            "code": "019041",
                            "cap": "26010",
                            "nome": "Dovera"
                        },
                        {
                            "code": "019042",
                            "cap": "26034",
                            "nome": "Drizzona"
                        },
                        {
                            "code": "019043",
                            "cap": "26010",
                            "nome": "Fiesco"
                        },
                        {
                            "code": "019044",
                            "cap": "26020",
                            "nome": "Formigara"
                        },
                        {
                            "code": "019045",
                            "cap": "26030",
                            "nome": "Gabbioneta-Binanuova"
                        },
                        {
                            "code": "019046",
                            "cap": "26030",
                            "nome": "Gadesco-Pieve Delmona"
                        },
                        {
                            "code": "019047",
                            "cap": "26020",
                            "nome": "Genivolta"
                        },
                        {
                            "code": "019048",
                            "cap": "26040",
                            "nome": "Gerre de' Caprioli"
                        },
                        {
                            "code": "019049",
                            "cap": "26020",
                            "nome": "Gombito"
                        },
                        {
                            "code": "019050",
                            "cap": "26044",
                            "nome": "Grontardo"
                        },
                        {
                            "code": "019051",
                            "cap": "26023",
                            "nome": "Grumello Cremonese ed Uniti"
                        },
                        {
                            "code": "019052",
                            "cap": "26040",
                            "nome": "Gussola"
                        },
                        {
                            "code": "019053",
                            "cap": "26031",
                            "nome": "Isola Dovarese"
                        },
                        {
                            "code": "019054",
                            "cap": "26010",
                            "nome": "Izano"
                        },
                        {
                            "code": "019055",
                            "cap": "26020",
                            "nome": "Madignano"
                        },
                        {
                            "code": "019056",
                            "cap": "26030",
                            "nome": "Malagnino"
                        },
                        {
                            "code": "019057",
                            "cap": "26040",
                            "nome": "Martignana di Po"
                        },
                        {
                            "code": "019058",
                            "cap": "26010",
                            "nome": "Monte Cremasco"
                        },
                        {
                            "code": "019059",
                            "cap": "26010",
                            "nome": "Montodine"
                        },
                        {
                            "code": "019060",
                            "cap": "26010",
                            "nome": "Moscazzano"
                        },
                        {
                            "code": "019061",
                            "cap": "26045",
                            "nome": "Motta Baluffi"
                        },
                        {
                            "code": "019062",
                            "cap": "26010",
                            "nome": "Offanengo"
                        },
                        {
                            "code": "019063",
                            "cap": "26010",
                            "nome": "Olmeneta"
                        },
                        {
                            "code": "019064",
                            "cap": "26032",
                            "nome": "Ostiano"
                        },
                        {
                            "code": "019065",
                            "cap": "26024",
                            "nome": "Paderno Ponchielli"
                        },
                        {
                            "code": "019066",
                            "cap": "26020",
                            "nome": "Palazzo Pignano"
                        },
                        {
                            "code": "019067",
                            "cap": "26025",
                            "nome": "Pandino"
                        },
                        {
                            "code": "019068",
                            "cap": "26043",
                            "nome": "Persico Dosimo"
                        },
                        {
                            "code": "019069",
                            "cap": "26033",
                            "nome": "Pescarolo ed Uniti"
                        },
                        {
                            "code": "019070",
                            "cap": "26030",
                            "nome": "Pessina Cremonese"
                        },
                        {
                            "code": "019071",
                            "cap": "26034",
                            "nome": "Piadena"
                        },
                        {
                            "code": "019072",
                            "cap": "26010",
                            "nome": "Pianengo"
                        },
                        {
                            "code": "019073",
                            "cap": "26017",
                            "nome": "Pieranica"
                        },
                        {
                            "code": "019074",
                            "cap": "26040",
                            "nome": "Pieve d'Olmi"
                        },
                        {
                            "code": "019075",
                            "cap": "26035",
                            "nome": "Pieve San Giacomo"
                        },
                        {
                            "code": "019076",
                            "cap": "26026",
                            "nome": "Pizzighettone"
                        },
                        {
                            "code": "019077",
                            "cap": "26010",
                            "nome": "Pozzaglio ed Uniti"
                        },
                        {
                            "code": "019078",
                            "cap": "26017",
                            "nome": "Quintano"
                        },
                        {
                            "code": "019079",
                            "cap": "26010",
                            "nome": "Ricengo"
                        },
                        {
                            "code": "019080",
                            "cap": "26010",
                            "nome": "Ripalta Arpina"
                        },
                        {
                            "code": "019081",
                            "cap": "26010",
                            "nome": "Ripalta Cremasca"
                        },
                        {
                            "code": "019082",
                            "cap": "26010",
                            "nome": "Ripalta Guerina"
                        },
                        {
                            "code": "019083",
                            "cap": "26036",
                            "nome": "Rivarolo del Re ed Uniti"
                        },
                        {
                            "code": "019084",
                            "cap": "26027",
                            "nome": "Rivolta d'Adda"
                        },
                        {
                            "code": "019085",
                            "cap": "26010",
                            "nome": "Robecco d'Oglio"
                        },
                        {
                            "code": "019086",
                            "cap": "26014",
                            "nome": "Romanengo"
                        },
                        {
                            "code": "019087",
                            "cap": "26010",
                            "nome": "Salvirola"
                        },
                        {
                            "code": "019088",
                            "cap": "26020",
                            "nome": "San Bassano"
                        },
                        {
                            "code": "019089",
                            "cap": "26046",
                            "nome": "San Daniele Po"
                        },
                        {
                            "code": "019090",
                            "cap": "26037",
                            "nome": "San Giovanni in Croce"
                        },
                        {
                            "code": "019091",
                            "cap": "26040",
                            "nome": "San Martino del Lago"
                        },
                        {
                            "code": "019092",
                            "cap": "26040",
                            "nome": "Scandolara Ravara"
                        },
                        {
                            "code": "019093",
                            "cap": "26047",
                            "nome": "Scandolara Ripa d'Oglio"
                        },
                        {
                            "code": "019094",
                            "cap": "26010",
                            "nome": "Sergnano"
                        },
                        {
                            "code": "019095",
                            "cap": "26028",
                            "nome": "Sesto ed Uniti"
                        },
                        {
                            "code": "019096",
                            "cap": "26030",
                            "nome": "Solarolo Rainerio"
                        },
                        {
                            "code": "019097",
                            "cap": "26029",
                            "nome": "Soncino"
                        },
                        {
                            "code": "019098",
                            "cap": "26015",
                            "nome": "Soresina"
                        },
                        {
                            "code": "019099",
                            "cap": "26048",
                            "nome": "Sospiro"
                        },
                        {
                            "code": "019100",
                            "cap": "26020",
                            "nome": "Spinadesco"
                        },
                        {
                            "code": "019101",
                            "cap": "26030",
                            "nome": "Spineda"
                        },
                        {
                            "code": "019102",
                            "cap": "26016",
                            "nome": "Spino d'Adda"
                        },
                        {
                            "code": "019103",
                            "cap": "26049",
                            "nome": "Stagno Lombardo"
                        },
                        {
                            "code": "019104",
                            "cap": "26020",
                            "nome": "Ticengo"
                        },
                        {
                            "code": "019105",
                            "cap": "26017",
                            "nome": "Torlino Vimercati"
                        },
                        {
                            "code": "019106",
                            "cap": "26030",
                            "nome": "Tornata"
                        },
                        {
                            "code": "019107",
                            "cap": "26038",
                            "nome": "Torre de' Picenardi"
                        },
                        {
                            "code": "019108",
                            "cap": "26040",
                            "nome": "Torricella del Pizzo"
                        },
                        {
                            "code": "019109",
                            "cap": "26017",
                            "nome": "Trescore Cremasco"
                        },
                        {
                            "code": "019110",
                            "cap": "26018",
                            "nome": "Trigolo"
                        },
                        {
                            "code": "019111",
                            "cap": "26010",
                            "nome": "Vaiano Cremasco"
                        },
                        {
                            "code": "019112",
                            "cap": "26019",
                            "nome": "Vailate"
                        },
                        {
                            "code": "019113",
                            "cap": "26039",
                            "nome": "Vescovato"
                        },
                        {
                            "code": "019114",
                            "cap": "26030",
                            "nome": "Volongo"
                        },
                        {
                            "code": "019115",
                            "cap": "26030",
                            "nome": "Voltido"
                        }
                    ],
                    "nome": "Cremona"
                },
                {
                    "code": "LC",
                    "comuni": [
                        {
                            "code": "097001",
                            "cap": "23821",
                            "nome": "Abbadia Lariana"
                        },
                        {
                            "code": "097002",
                            "cap": "23881",
                            "nome": "Airuno"
                        },
                        {
                            "code": "097003",
                            "cap": "23841",
                            "nome": "Annone di Brianza"
                        },
                        {
                            "code": "097004",
                            "cap": "23811",
                            "nome": "Ballabio"
                        },
                        {
                            "code": "097005",
                            "cap": "23890",
                            "nome": "Barzago"
                        },
                        {
                            "code": "097006",
                            "cap": "23891",
                            "nome": "Barzan\u00f2"
                        },
                        {
                            "code": "097007",
                            "cap": "23816",
                            "nome": "Barzio"
                        },
                        {
                            "code": "097008",
                            "cap": "23822",
                            "nome": "Bellano"
                        },
                        {
                            "code": "097009",
                            "cap": "23842",
                            "nome": "Bosisio Parini"
                        },
                        {
                            "code": "097010",
                            "cap": "23883",
                            "nome": "Brivio"
                        },
                        {
                            "code": "097011",
                            "cap": "23892",
                            "nome": "Bulciago"
                        },
                        {
                            "code": "097012",
                            "cap": "23885",
                            "nome": "Calco"
                        },
                        {
                            "code": "097013",
                            "cap": "23801",
                            "nome": "Calolziocorte"
                        },
                        {
                            "code": "097014",
                            "cap": "23802",
                            "nome": "Carenno"
                        },
                        {
                            "code": "097015",
                            "cap": "23831",
                            "nome": "Casargo"
                        },
                        {
                            "code": "097016",
                            "cap": "23880",
                            "nome": "Casatenovo"
                        },
                        {
                            "code": "097017",
                            "cap": "23893",
                            "nome": "Cassago Brianza"
                        },
                        {
                            "code": "097018",
                            "cap": "23817",
                            "nome": "Cassina Valsassina"
                        },
                        {
                            "code": "097019",
                            "cap": "23884",
                            "nome": "Castello di Brianza"
                        },
                        {
                            "code": "097020",
                            "cap": "23870",
                            "nome": "Cernusco Lombardone"
                        },
                        {
                            "code": "097021",
                            "cap": "23861",
                            "nome": "Cesana Brianza"
                        },
                        {
                            "code": "097022",
                            "cap": "23862",
                            "nome": "Civate"
                        },
                        {
                            "code": "097023",
                            "cap": "23823",
                            "nome": "Colico"
                        },
                        {
                            "code": "097024",
                            "cap": "23886",
                            "nome": "Colle Brianza"
                        },
                        {
                            "code": "097025",
                            "cap": "23813",
                            "nome": "Cortenova"
                        },
                        {
                            "code": "097026",
                            "cap": "23845",
                            "nome": "Costa Masnaga"
                        },
                        {
                            "code": "097027",
                            "cap": "23832",
                            "nome": "Crandola Valsassina"
                        },
                        {
                            "code": "097028",
                            "cap": "23894",
                            "nome": "Cremella"
                        },
                        {
                            "code": "097029",
                            "cap": "23814",
                            "nome": "Cremeno"
                        },
                        {
                            "code": "097030",
                            "cap": "23824",
                            "nome": "Dervio"
                        },
                        {
                            "code": "097031",
                            "cap": "23843",
                            "nome": "Dolzago"
                        },
                        {
                            "code": "097032",
                            "cap": "23824",
                            "nome": "Dorio"
                        },
                        {
                            "code": "097033",
                            "cap": "23848",
                            "nome": "Ello"
                        },
                        {
                            "code": "097034",
                            "cap": "23805",
                            "nome": "Erve"
                        },
                        {
                            "code": "097035",
                            "cap": "23825",
                            "nome": "Esino Lario"
                        },
                        {
                            "code": "097036",
                            "cap": "23851",
                            "nome": "Galbiate"
                        },
                        {
                            "code": "097037",
                            "cap": "23846",
                            "nome": "Garbagnate Monastero"
                        },
                        {
                            "code": "097038",
                            "cap": "23852",
                            "nome": "Garlate"
                        },
                        {
                            "code": "097039",
                            "cap": "23898",
                            "nome": "Imbersago"
                        },
                        {
                            "code": "097040",
                            "cap": "23815",
                            "nome": "Introbio"
                        },
                        {
                            "code": "097041",
                            "cap": "23835",
                            "nome": "Introzzo"
                        },
                        {
                            "code": "097042",
                            "cap": "23900",
                            "nome": "Lecco"
                        },
                        {
                            "code": "097043",
                            "cap": "23827",
                            "nome": "Lierna"
                        },
                        {
                            "code": "097044",
                            "cap": "23871",
                            "nome": "Lomagna"
                        },
                        {
                            "code": "097045",
                            "cap": "23864",
                            "nome": "Malgrate"
                        },
                        {
                            "code": "097046",
                            "cap": "23826",
                            "nome": "Mandello del Lario"
                        },
                        {
                            "code": "097047",
                            "cap": "23832",
                            "nome": "Margno"
                        },
                        {
                            "code": "097048",
                            "cap": "23807",
                            "nome": "Merate"
                        },
                        {
                            "code": "097049",
                            "cap": "23873",
                            "nome": "Missaglia"
                        },
                        {
                            "code": "097050",
                            "cap": "23817",
                            "nome": "Moggio"
                        },
                        {
                            "code": "097051",
                            "cap": "23847",
                            "nome": "Molteno"
                        },
                        {
                            "code": "097052",
                            "cap": "23804",
                            "nome": "Monte Marenzo"
                        },
                        {
                            "code": "097053",
                            "cap": "23874",
                            "nome": "Montevecchia"
                        },
                        {
                            "code": "097054",
                            "cap": "23876",
                            "nome": "Monticello Brianza"
                        },
                        {
                            "code": "097055",
                            "cap": "23811",
                            "nome": "Morterone"
                        },
                        {
                            "code": "097056",
                            "cap": "23895",
                            "nome": "Nibionno"
                        },
                        {
                            "code": "097057",
                            "cap": "23848",
                            "nome": "Oggiono"
                        },
                        {
                            "code": "097058",
                            "cap": "23887",
                            "nome": "Olgiate Molgora"
                        },
                        {
                            "code": "097059",
                            "cap": "23854",
                            "nome": "Olginate"
                        },
                        {
                            "code": "097060",
                            "cap": "23865",
                            "nome": "Oliveto Lario"
                        },
                        {
                            "code": "097061",
                            "cap": "23875",
                            "nome": "Osnago"
                        },
                        {
                            "code": "097062",
                            "cap": "23877",
                            "nome": "Paderno d'Adda"
                        },
                        {
                            "code": "097063",
                            "cap": "23833",
                            "nome": "Pagnona"
                        },
                        {
                            "code": "097064",
                            "cap": "23837",
                            "nome": "Parlasco"
                        },
                        {
                            "code": "097065",
                            "cap": "23818",
                            "nome": "Pasturo"
                        },
                        {
                            "code": "097066",
                            "cap": "23888",
                            "nome": "La Valletta Brianza"
                        },
                        {
                            "code": "097067",
                            "cap": "23828",
                            "nome": "Perledo"
                        },
                        {
                            "code": "097068",
                            "cap": "23855",
                            "nome": "Pescate"
                        },
                        {
                            "code": "097069",
                            "cap": "23834",
                            "nome": "Premana"
                        },
                        {
                            "code": "097070",
                            "cap": "23819",
                            "nome": "Primaluna"
                        },
                        {
                            "code": "097071",
                            "cap": "23899",
                            "nome": "Robbiate"
                        },
                        {
                            "code": "097072",
                            "cap": "23849",
                            "nome": "Rogeno"
                        },
                        {
                            "code": "097074",
                            "cap": "23889",
                            "nome": "Santa Maria Ho\u00e8"
                        },
                        {
                            "code": "097075",
                            "cap": "23844",
                            "nome": "Sirone"
                        },
                        {
                            "code": "097076",
                            "cap": "23896",
                            "nome": "Sirtori"
                        },
                        {
                            "code": "097077",
                            "cap": "23835",
                            "nome": "Sueglio"
                        },
                        {
                            "code": "097078",
                            "cap": "23867",
                            "nome": "Suello"
                        },
                        {
                            "code": "097079",
                            "cap": "23837",
                            "nome": "Taceno"
                        },
                        {
                            "code": "097080",
                            "cap": "23806",
                            "nome": "Torre de' Busi"
                        },
                        {
                            "code": "097081",
                            "cap": "23836",
                            "nome": "Tremenico"
                        },
                        {
                            "code": "097082",
                            "cap": "23857",
                            "nome": "Valgreghentino"
                        },
                        {
                            "code": "097083",
                            "cap": "23868",
                            "nome": "Valmadrera"
                        },
                        {
                            "code": "097084",
                            "cap": "23829",
                            "nome": "Varenna"
                        },
                        {
                            "code": "097085",
                            "cap": "23838",
                            "nome": "Vendrogno"
                        },
                        {
                            "code": "097086",
                            "cap": "23808",
                            "nome": "Vercurago"
                        },
                        {
                            "code": "097087",
                            "cap": "23879",
                            "nome": "Verderio Inferiore"
                        },
                        {
                            "code": "097088",
                            "cap": "23878",
                            "nome": "Verderio Superiore"
                        },
                        {
                            "code": "097089",
                            "cap": "23822",
                            "nome": "Vestreno"
                        },
                        {
                            "code": "097090",
                            "cap": "23897",
                            "nome": "Vigan\u00f2"
                        }
                    ],
                    "nome": "Lecco"
                },
                {
                    "code": "LO",
                    "comuni": [
                        {
                            "code": "098001",
                            "cap": "26834",
                            "nome": "Abbadia Cerreto"
                        },
                        {
                            "code": "098002",
                            "cap": "26821",
                            "nome": "Bertonico"
                        },
                        {
                            "code": "098003",
                            "cap": "26811",
                            "nome": "Boffalora d'Adda"
                        },
                        {
                            "code": "098004",
                            "cap": "26812",
                            "nome": "Borghetto Lodigiano"
                        },
                        {
                            "code": "098005",
                            "cap": "26851",
                            "nome": "Borgo San Giovanni"
                        },
                        {
                            "code": "098006",
                            "cap": "26822",
                            "nome": "Brembio"
                        },
                        {
                            "code": "098007",
                            "cap": "26823",
                            "nome": "Camairago"
                        },
                        {
                            "code": "098008",
                            "cap": "26852",
                            "nome": "Casaletto Lodigiano"
                        },
                        {
                            "code": "098009",
                            "cap": "26831",
                            "nome": "Casalmaiocco"
                        },
                        {
                            "code": "098010",
                            "cap": "26841",
                            "nome": "Casalpusterlengo"
                        },
                        {
                            "code": "098011",
                            "cap": "26842",
                            "nome": "Caselle Landi"
                        },
                        {
                            "code": "098012",
                            "cap": "26853",
                            "nome": "Caselle Lurani"
                        },
                        {
                            "code": "098013",
                            "cap": "26843",
                            "nome": "Castelnuovo Bocca d'Adda"
                        },
                        {
                            "code": "098014",
                            "cap": "26823",
                            "nome": "Castiglione d'Adda"
                        },
                        {
                            "code": "098015",
                            "cap": "26866",
                            "nome": "Castiraga Vidardo"
                        },
                        {
                            "code": "098016",
                            "cap": "26844",
                            "nome": "Cavacurta"
                        },
                        {
                            "code": "098017",
                            "cap": "26824",
                            "nome": "Cavenago d'Adda"
                        },
                        {
                            "code": "098018",
                            "cap": "26832",
                            "nome": "Cervignano d'Adda"
                        },
                        {
                            "code": "098019",
                            "cap": "26845",
                            "nome": "Codogno"
                        },
                        {
                            "code": "098020",
                            "cap": "26833",
                            "nome": "Comazzo"
                        },
                        {
                            "code": "098021",
                            "cap": "26854",
                            "nome": "Cornegliano Laudense"
                        },
                        {
                            "code": "098022",
                            "cap": "26846",
                            "nome": "Corno Giovine"
                        },
                        {
                            "code": "098023",
                            "cap": "26842",
                            "nome": "Cornovecchio"
                        },
                        {
                            "code": "098024",
                            "cap": "26834",
                            "nome": "Corte Palasio"
                        },
                        {
                            "code": "098025",
                            "cap": "26835",
                            "nome": "Crespiatica"
                        },
                        {
                            "code": "098026",
                            "cap": "26861",
                            "nome": "Fombio"
                        },
                        {
                            "code": "098027",
                            "cap": "26832",
                            "nome": "Galgagnano"
                        },
                        {
                            "code": "098028",
                            "cap": "26813",
                            "nome": "Graffignana"
                        },
                        {
                            "code": "098029",
                            "cap": "26862",
                            "nome": "Guardamiglio"
                        },
                        {
                            "code": "098030",
                            "cap": "26814",
                            "nome": "Livraga"
                        },
                        {
                            "code": "098031",
                            "cap": "26900",
                            "nome": "Lodi"
                        },
                        {
                            "code": "098032",
                            "cap": "26855",
                            "nome": "Lodi Vecchio"
                        },
                        {
                            "code": "098033",
                            "cap": "26843",
                            "nome": "Maccastorna"
                        },
                        {
                            "code": "098034",
                            "cap": "26825",
                            "nome": "Mairago"
                        },
                        {
                            "code": "098035",
                            "cap": "26847",
                            "nome": "Maleo"
                        },
                        {
                            "code": "098036",
                            "cap": "26866",
                            "nome": "Marudo"
                        },
                        {
                            "code": "098037",
                            "cap": "26815",
                            "nome": "Massalengo"
                        },
                        {
                            "code": "098038",
                            "cap": "26843",
                            "nome": "Meleti"
                        },
                        {
                            "code": "098039",
                            "cap": "26833",
                            "nome": "Merlino"
                        },
                        {
                            "code": "098040",
                            "cap": "26836",
                            "nome": "Montanaso Lombardo"
                        },
                        {
                            "code": "098041",
                            "cap": "26837",
                            "nome": "Mulazzano"
                        },
                        {
                            "code": "098042",
                            "cap": "26863",
                            "nome": "Orio Litta"
                        },
                        {
                            "code": "098043",
                            "cap": "26864",
                            "nome": "Ospedaletto Lodigiano"
                        },
                        {
                            "code": "098044",
                            "cap": "26816",
                            "nome": "Ossago Lodigiano"
                        },
                        {
                            "code": "098045",
                            "cap": "26854",
                            "nome": "Pieve Fissiraga"
                        },
                        {
                            "code": "098046",
                            "cap": "26857",
                            "nome": "Salerano sul Lambro"
                        },
                        {
                            "code": "098047",
                            "cap": "26848",
                            "nome": "San Fiorano"
                        },
                        {
                            "code": "098048",
                            "cap": "26817",
                            "nome": "San Martino in Strada"
                        },
                        {
                            "code": "098049",
                            "cap": "26865",
                            "nome": "San Rocco al Porto"
                        },
                        {
                            "code": "098050",
                            "cap": "26866",
                            "nome": "Sant'Angelo Lodigiano"
                        },
                        {
                            "code": "098051",
                            "cap": "26849",
                            "nome": "Santo Stefano Lodigiano"
                        },
                        {
                            "code": "098052",
                            "cap": "26826",
                            "nome": "Secugnago"
                        },
                        {
                            "code": "098053",
                            "cap": "26856",
                            "nome": "Senna Lodigiana"
                        },
                        {
                            "code": "098054",
                            "cap": "26867",
                            "nome": "Somaglia"
                        },
                        {
                            "code": "098055",
                            "cap": "26858",
                            "nome": "Sordio"
                        },
                        {
                            "code": "098056",
                            "cap": "26838",
                            "nome": "Tavazzano con Villavesco"
                        },
                        {
                            "code": "098057",
                            "cap": "26827",
                            "nome": "Terranova dei Passerini"
                        },
                        {
                            "code": "098058",
                            "cap": "26828",
                            "nome": "Turano Lodigiano"
                        },
                        {
                            "code": "098059",
                            "cap": "26859",
                            "nome": "Valera Fratta"
                        },
                        {
                            "code": "098060",
                            "cap": "26818",
                            "nome": "Villanova del Sillaro"
                        },
                        {
                            "code": "098061",
                            "cap": "26839",
                            "nome": "Zelo Buon Persico"
                        }
                    ],
                    "nome": "Lodi"
                },
                {
                    "code": "MN",
                    "comuni": [
                        {
                            "code": "020001",
                            "cap": "46011",
                            "nome": "Acquanegra sul Chiese"
                        },
                        {
                            "code": "020002",
                            "cap": "46041",
                            "nome": "Asola"
                        },
                        {
                            "code": "020003",
                            "cap": "46031",
                            "nome": "Bagnolo San Vito"
                        },
                        {
                            "code": "020004",
                            "cap": "46030",
                            "nome": "Bigarello"
                        },
                        {
                            "code": "020005",
                            "cap": "46030",
                            "nome": "Borgoforte"
                        },
                        {
                            "code": "020006",
                            "cap": "46020",
                            "nome": "Borgofranco sul Po"
                        },
                        {
                            "code": "020007",
                            "cap": "46012",
                            "nome": "Bozzolo"
                        },
                        {
                            "code": "020008",
                            "cap": "46013",
                            "nome": "Canneto sull'Oglio"
                        },
                        {
                            "code": "020009",
                            "cap": "46020",
                            "nome": "Carbonara di Po"
                        },
                        {
                            "code": "020010",
                            "cap": "46040",
                            "nome": "Casalmoro"
                        },
                        {
                            "code": "020011",
                            "cap": "46040",
                            "nome": "Casaloldo"
                        },
                        {
                            "code": "020012",
                            "cap": "46040",
                            "nome": "Casalromano"
                        },
                        {
                            "code": "020014",
                            "cap": "46033",
                            "nome": "Castel d'Ario"
                        },
                        {
                            "code": "020015",
                            "cap": "46042",
                            "nome": "Castel Goffredo"
                        },
                        {
                            "code": "020013",
                            "cap": "46032",
                            "nome": "Castelbelforte"
                        },
                        {
                            "code": "020016",
                            "cap": "46014",
                            "nome": "Castellucchio"
                        },
                        {
                            "code": "020017",
                            "cap": "46043",
                            "nome": "Castiglione delle Stiviere"
                        },
                        {
                            "code": "020018",
                            "cap": "46040",
                            "nome": "Cavriana"
                        },
                        {
                            "code": "020019",
                            "cap": "46040",
                            "nome": "Ceresara"
                        },
                        {
                            "code": "020020",
                            "cap": "46010",
                            "nome": "Commessaggio"
                        },
                        {
                            "code": "020021",
                            "cap": "46010",
                            "nome": "Curtatone"
                        },
                        {
                            "code": "020022",
                            "cap": "46030",
                            "nome": "Dosolo"
                        },
                        {
                            "code": "020023",
                            "cap": "46022",
                            "nome": "Felonica"
                        },
                        {
                            "code": "020024",
                            "cap": "46040",
                            "nome": "Gazoldo degli Ippoliti"
                        },
                        {
                            "code": "020025",
                            "cap": "46010",
                            "nome": "Gazzuolo"
                        },
                        {
                            "code": "020026",
                            "cap": "46044",
                            "nome": "Goito"
                        },
                        {
                            "code": "020027",
                            "cap": "46023",
                            "nome": "Gonzaga"
                        },
                        {
                            "code": "020028",
                            "cap": "46040",
                            "nome": "Guidizzolo"
                        },
                        {
                            "code": "020029",
                            "cap": "46020",
                            "nome": "Magnacavallo"
                        },
                        {
                            "code": "020030",
                            "cap": "46100",
                            "nome": "Mantova"
                        },
                        {
                            "code": "020031",
                            "cap": "46010",
                            "nome": "Marcaria"
                        },
                        {
                            "code": "020032",
                            "cap": "46010",
                            "nome": "Mariana Mantovana"
                        },
                        {
                            "code": "020033",
                            "cap": "46045",
                            "nome": "Marmirolo"
                        },
                        {
                            "code": "020034",
                            "cap": "46046",
                            "nome": "Medole"
                        },
                        {
                            "code": "020035",
                            "cap": "46024",
                            "nome": "Moglia"
                        },
                        {
                            "code": "020036",
                            "cap": "46040",
                            "nome": "Monzambano"
                        },
                        {
                            "code": "020037",
                            "cap": "46020",
                            "nome": "Motteggiana"
                        },
                        {
                            "code": "020038",
                            "cap": "46035",
                            "nome": "Ostiglia"
                        },
                        {
                            "code": "020039",
                            "cap": "46020",
                            "nome": "Pegognaga"
                        },
                        {
                            "code": "020040",
                            "cap": "46020",
                            "nome": "Pieve di Coriano"
                        },
                        {
                            "code": "020041",
                            "cap": "46040",
                            "nome": "Piubega"
                        },
                        {
                            "code": "020042",
                            "cap": "46025",
                            "nome": "Poggio Rusco"
                        },
                        {
                            "code": "020043",
                            "cap": "46030",
                            "nome": "Pomponesco"
                        },
                        {
                            "code": "020044",
                            "cap": "46040",
                            "nome": "Ponti sul Mincio"
                        },
                        {
                            "code": "020045",
                            "cap": "46047",
                            "nome": "Porto Mantovano"
                        },
                        {
                            "code": "020046",
                            "cap": "46020",
                            "nome": "Quingentole"
                        },
                        {
                            "code": "020047",
                            "cap": "46026",
                            "nome": "Quistello"
                        },
                        {
                            "code": "020048",
                            "cap": "46010",
                            "nome": "Redondesco"
                        },
                        {
                            "code": "020049",
                            "cap": "46036",
                            "nome": "Revere"
                        },
                        {
                            "code": "020050",
                            "cap": "46017",
                            "nome": "Rivarolo Mantovano"
                        },
                        {
                            "code": "020051",
                            "cap": "46040",
                            "nome": "Rodigo"
                        },
                        {
                            "code": "020052",
                            "cap": "46037",
                            "nome": "Roncoferraro"
                        },
                        {
                            "code": "020053",
                            "cap": "46048",
                            "nome": "Roverbella"
                        },
                        {
                            "code": "020054",
                            "cap": "46018",
                            "nome": "Sabbioneta"
                        },
                        {
                            "code": "020055",
                            "cap": "46027",
                            "nome": "San Benedetto Po"
                        },
                        {
                            "code": "020056",
                            "cap": "46020",
                            "nome": "San Giacomo delle Segnate"
                        },
                        {
                            "code": "020057",
                            "cap": "46030",
                            "nome": "San Giorgio di Mantova"
                        },
                        {
                            "code": "020058",
                            "cap": "46020",
                            "nome": "San Giovanni del Dosso"
                        },
                        {
                            "code": "020059",
                            "cap": "46010",
                            "nome": "San Martino dall'Argine"
                        },
                        {
                            "code": "020060",
                            "cap": "46020",
                            "nome": "Schivenoglia"
                        },
                        {
                            "code": "020061",
                            "cap": "46028",
                            "nome": "Sermide"
                        },
                        {
                            "code": "020062",
                            "cap": "46030",
                            "nome": "Serravalle a Po"
                        },
                        {
                            "code": "020063",
                            "cap": "46040",
                            "nome": "Solferino"
                        },
                        {
                            "code": "020064",
                            "cap": "46030",
                            "nome": "Sustinente"
                        },
                        {
                            "code": "020065",
                            "cap": "46029",
                            "nome": "Suzzara"
                        },
                        {
                            "code": "020066",
                            "cap": "46019",
                            "nome": "Viadana"
                        },
                        {
                            "code": "020067",
                            "cap": "46020",
                            "nome": "Villa Poma"
                        },
                        {
                            "code": "020068",
                            "cap": "46039",
                            "nome": "Villimpenta"
                        },
                        {
                            "code": "020069",
                            "cap": "46030",
                            "nome": "Virgilio"
                        },
                        {
                            "code": "020070",
                            "cap": "46049",
                            "nome": "Volta Mantovana"
                        }
                    ],
                    "nome": "Mantova"
                },
                {
                    "code": "MI",
                    "comuni": [
                        {
                            "code": "015002",
                            "cap": "20081",
                            "nome": "Abbiategrasso"
                        },
                        {
                            "code": "015005",
                            "cap": "20080",
                            "nome": "Albairate"
                        },
                        {
                            "code": "015007",
                            "cap": "20020",
                            "nome": "Arconate"
                        },
                        {
                            "code": "015009",
                            "cap": "20020",
                            "nome": "Arese"
                        },
                        {
                            "code": "015010",
                            "cap": "20010",
                            "nome": "Arluno"
                        },
                        {
                            "code": "015011",
                            "cap": "20090",
                            "nome": "Assago"
                        },
                        {
                            "code": "015250",
                            "cap": "20021",
                            "nome": "Baranzate"
                        },
                        {
                            "code": "015012",
                            "cap": "20010",
                            "nome": "Bareggio"
                        },
                        {
                            "code": "015014",
                            "cap": "20060",
                            "nome": "Basiano"
                        },
                        {
                            "code": "015015",
                            "cap": "20080",
                            "nome": "Basiglio"
                        },
                        {
                            "code": "015016",
                            "cap": "20060",
                            "nome": "Bellinzago Lombardo"
                        },
                        {
                            "code": "015019",
                            "cap": "20010",
                            "nome": "Bernate Ticino"
                        },
                        {
                            "code": "015022",
                            "cap": "20080",
                            "nome": "Besate"
                        },
                        {
                            "code": "015024",
                            "cap": "20082",
                            "nome": "Binasco"
                        },
                        {
                            "code": "015026",
                            "cap": "20010",
                            "nome": "Boffalora Sopra Ticino"
                        },
                        {
                            "code": "015027",
                            "cap": "20021",
                            "nome": "Bollate"
                        },
                        {
                            "code": "015032",
                            "cap": "20091",
                            "nome": "Bresso"
                        },
                        {
                            "code": "015035",
                            "cap": "20080",
                            "nome": "Bubbiano"
                        },
                        {
                            "code": "015036",
                            "cap": "20090",
                            "nome": "Buccinasco"
                        },
                        {
                            "code": "015038",
                            "cap": "20010",
                            "nome": "Buscate"
                        },
                        {
                            "code": "015040",
                            "cap": "20060",
                            "nome": "Bussero"
                        },
                        {
                            "code": "015041",
                            "cap": "20020",
                            "nome": "Busto Garolfo"
                        },
                        {
                            "code": "015042",
                            "cap": "20080",
                            "nome": "Calvignasco"
                        },
                        {
                            "code": "015044",
                            "cap": "20040",
                            "nome": "Cambiago"
                        },
                        {
                            "code": "015046",
                            "cap": "20010",
                            "nome": "Canegrate"
                        },
                        {
                            "code": "015050",
                            "cap": "20080",
                            "nome": "Carpiano"
                        },
                        {
                            "code": "015051",
                            "cap": "20061",
                            "nome": "Carugate"
                        },
                        {
                            "code": "015055",
                            "cap": "20080",
                            "nome": "Casarile"
                        },
                        {
                            "code": "015058",
                            "cap": "20010",
                            "nome": "Casorezzo"
                        },
                        {
                            "code": "015059",
                            "cap": "20062",
                            "nome": "Cassano d'Adda"
                        },
                        {
                            "code": "015060",
                            "cap": "20060",
                            "nome": "Cassina de' Pecchi"
                        },
                        {
                            "code": "015061",
                            "cap": "20081",
                            "nome": "Cassinetta di Lugagnano"
                        },
                        {
                            "code": "015062",
                            "cap": "20022",
                            "nome": "Castano Primo"
                        },
                        {
                            "code": "015070",
                            "cap": "20063",
                            "nome": "Cernusco sul Naviglio"
                        },
                        {
                            "code": "015071",
                            "cap": "20070",
                            "nome": "Cerro al Lambro"
                        },
                        {
                            "code": "015072",
                            "cap": "20023",
                            "nome": "Cerro Maggiore"
                        },
                        {
                            "code": "015074",
                            "cap": "20090",
                            "nome": "Cesano Boscone"
                        },
                        {
                            "code": "015076",
                            "cap": "20020",
                            "nome": "Cesate"
                        },
                        {
                            "code": "015077",
                            "cap": "20092",
                            "nome": "Cinisello Balsamo"
                        },
                        {
                            "code": "015078",
                            "cap": "20080",
                            "nome": "Cisliano"
                        },
                        {
                            "code": "015081",
                            "cap": "20093",
                            "nome": "Cologno Monzese"
                        },
                        {
                            "code": "015082",
                            "cap": "20060",
                            "nome": "Colturano"
                        },
                        {
                            "code": "015085",
                            "cap": "20011",
                            "nome": "Corbetta"
                        },
                        {
                            "code": "015086",
                            "cap": "20032",
                            "nome": "Cormano"
                        },
                        {
                            "code": "015087",
                            "cap": "20010",
                            "nome": "Cornaredo"
                        },
                        {
                            "code": "015093",
                            "cap": "20094",
                            "nome": "Corsico"
                        },
                        {
                            "code": "015096",
                            "cap": "20012",
                            "nome": "Cuggiono"
                        },
                        {
                            "code": "015097",
                            "cap": "20090",
                            "nome": "Cusago"
                        },
                        {
                            "code": "015098",
                            "cap": "20095",
                            "nome": "Cusano Milanino"
                        },
                        {
                            "code": "015099",
                            "cap": "20020",
                            "nome": "Dairago"
                        },
                        {
                            "code": "015101",
                            "cap": "20070",
                            "nome": "Dresano"
                        },
                        {
                            "code": "015103",
                            "cap": "20083",
                            "nome": "Gaggiano"
                        },
                        {
                            "code": "015105",
                            "cap": "20024",
                            "nome": "Garbagnate Milanese"
                        },
                        {
                            "code": "015106",
                            "cap": "20060",
                            "nome": "Gessate"
                        },
                        {
                            "code": "015108",
                            "cap": "20064",
                            "nome": "Gorgonzola"
                        },
                        {
                            "code": "015110",
                            "cap": "20056",
                            "nome": "Grezzago"
                        },
                        {
                            "code": "015112",
                            "cap": "20088",
                            "nome": "Gudo Visconti"
                        },
                        {
                            "code": "015113",
                            "cap": "20010",
                            "nome": "Inveruno"
                        },
                        {
                            "code": "015114",
                            "cap": "20065",
                            "nome": "Inzago"
                        },
                        {
                            "code": "015115",
                            "cap": "20084",
                            "nome": "Lacchiarella"
                        },
                        {
                            "code": "015116",
                            "cap": "20020",
                            "nome": "Lainate"
                        },
                        {
                            "code": "015118",
                            "cap": "20025",
                            "nome": "Legnano"
                        },
                        {
                            "code": "015122",
                            "cap": "20060",
                            "nome": "Liscate"
                        },
                        {
                            "code": "015125",
                            "cap": "20085",
                            "nome": "Locate di Triulzi"
                        },
                        {
                            "code": "015130",
                            "cap": "20013",
                            "nome": "Magenta"
                        },
                        {
                            "code": "015131",
                            "cap": "20020",
                            "nome": "Magnago"
                        },
                        {
                            "code": "015134",
                            "cap": "20010",
                            "nome": "Marcallo con Casone"
                        },
                        {
                            "code": "015136",
                            "cap": "20060",
                            "nome": "Masate"
                        },
                        {
                            "code": "015139",
                            "cap": "20060",
                            "nome": "Mediglia"
                        },
                        {
                            "code": "015140",
                            "cap": "20077",
                            "nome": "Melegnano"
                        },
                        {
                            "code": "015142",
                            "cap": "20066",
                            "nome": "Melzo"
                        },
                        {
                            "code": "015144",
                            "cap": "20010",
                            "nome": "Mesero"
                        },
                        {
                            "code": "015146",
                            "cap": "201xx",
                            "nome": "Milano"
                        },
                        {
                            "code": "015150",
                            "cap": "20081",
                            "nome": "Morimondo"
                        },
                        {
                            "code": "015151",
                            "cap": "20086",
                            "nome": "Motta Visconti"
                        },
                        {
                            "code": "015154",
                            "cap": "20014",
                            "nome": "Nerviano"
                        },
                        {
                            "code": "015155",
                            "cap": "20020",
                            "nome": "Nosate"
                        },
                        {
                            "code": "015157",
                            "cap": "20026",
                            "nome": "Novate Milanese"
                        },
                        {
                            "code": "015158",
                            "cap": "20082",
                            "nome": "Noviglio"
                        },
                        {
                            "code": "015159",
                            "cap": "20090",
                            "nome": "Opera"
                        },
                        {
                            "code": "015164",
                            "cap": "20010",
                            "nome": "Ossona"
                        },
                        {
                            "code": "015165",
                            "cap": "20080",
                            "nome": "Ozzero"
                        },
                        {
                            "code": "015166",
                            "cap": "20037",
                            "nome": "Paderno Dugnano"
                        },
                        {
                            "code": "015167",
                            "cap": "20090",
                            "nome": "Pantigliate"
                        },
                        {
                            "code": "015168",
                            "cap": "20015",
                            "nome": "Parabiago"
                        },
                        {
                            "code": "015169",
                            "cap": "20067",
                            "nome": "Paullo"
                        },
                        {
                            "code": "015170",
                            "cap": "20016",
                            "nome": "Pero"
                        },
                        {
                            "code": "015171",
                            "cap": "20068",
                            "nome": "Peschiera Borromeo"
                        },
                        {
                            "code": "015172",
                            "cap": "20060",
                            "nome": "Pessano con Bornago"
                        },
                        {
                            "code": "015173",
                            "cap": "20090",
                            "nome": "Pieve Emanuele"
                        },
                        {
                            "code": "015175",
                            "cap": "20096",
                            "nome": "Pioltello"
                        },
                        {
                            "code": "015176",
                            "cap": "20010",
                            "nome": "Pogliano Milanese"
                        },
                        {
                            "code": "015177",
                            "cap": "20060",
                            "nome": "Pozzo d'Adda"
                        },
                        {
                            "code": "015178",
                            "cap": "20060",
                            "nome": "Pozzuolo Martesana"
                        },
                        {
                            "code": "015179",
                            "cap": "20010",
                            "nome": "Pregnana Milanese"
                        },
                        {
                            "code": "015181",
                            "cap": "20027",
                            "nome": "Rescaldina"
                        },
                        {
                            "code": "015182",
                            "cap": "20017",
                            "nome": "Rho"
                        },
                        {
                            "code": "015183",
                            "cap": "20020",
                            "nome": "Robecchetto con Induno"
                        },
                        {
                            "code": "015184",
                            "cap": "20087",
                            "nome": "Robecco sul Naviglio"
                        },
                        {
                            "code": "015185",
                            "cap": "20090",
                            "nome": "Rodano"
                        },
                        {
                            "code": "015188",
                            "cap": "20088",
                            "nome": "Rosate"
                        },
                        {
                            "code": "015189",
                            "cap": "20089",
                            "nome": "Rozzano"
                        },
                        {
                            "code": "015191",
                            "cap": "20078",
                            "nome": "San Colombano al Lambro"
                        },
                        {
                            "code": "015192",
                            "cap": "20097",
                            "nome": "San Donato Milanese"
                        },
                        {
                            "code": "015194",
                            "cap": "20010",
                            "nome": "San Giorgio su Legnano"
                        },
                        {
                            "code": "015195",
                            "cap": "20098",
                            "nome": "San Giuliano Milanese"
                        },
                        {
                            "code": "015201",
                            "cap": "20028",
                            "nome": "San Vittore Olona"
                        },
                        {
                            "code": "015202",
                            "cap": "20070",
                            "nome": "San Zenone al Lambro"
                        },
                        {
                            "code": "015200",
                            "cap": "20010",
                            "nome": "Santo Stefano Ticino"
                        },
                        {
                            "code": "015204",
                            "cap": "20018",
                            "nome": "Sedriano"
                        },
                        {
                            "code": "015205",
                            "cap": "20090",
                            "nome": "Segrate"
                        },
                        {
                            "code": "015206",
                            "cap": "20030",
                            "nome": "Senago"
                        },
                        {
                            "code": "015209",
                            "cap": "20099",
                            "nome": "Sesto San Giovanni"
                        },
                        {
                            "code": "015210",
                            "cap": "20090",
                            "nome": "Settala"
                        },
                        {
                            "code": "015211",
                            "cap": "20019",
                            "nome": "Settimo Milanese"
                        },
                        {
                            "code": "015213",
                            "cap": "20020",
                            "nome": "Solaro"
                        },
                        {
                            "code": "015219",
                            "cap": "20060",
                            "nome": "Trezzano Rosa"
                        },
                        {
                            "code": "015220",
                            "cap": "20090",
                            "nome": "Trezzano sul Naviglio"
                        },
                        {
                            "code": "015221",
                            "cap": "20056",
                            "nome": "Trezzo sull'Adda"
                        },
                        {
                            "code": "015222",
                            "cap": "20067",
                            "nome": "Tribiano"
                        },
                        {
                            "code": "015224",
                            "cap": "20060",
                            "nome": "Truccazzano"
                        },
                        {
                            "code": "015226",
                            "cap": "20029",
                            "nome": "Turbigo"
                        },
                        {
                            "code": "015249",
                            "cap": "20020",
                            "nome": "Vanzaghello"
                        },
                        {
                            "code": "015229",
                            "cap": "20010",
                            "nome": "Vanzago"
                        },
                        {
                            "code": "015230",
                            "cap": "20069",
                            "nome": "Vaprio d'Adda"
                        },
                        {
                            "code": "015235",
                            "cap": "20080",
                            "nome": "Vermezzo"
                        },
                        {
                            "code": "015236",
                            "cap": "20080",
                            "nome": "Vernate"
                        },
                        {
                            "code": "015237",
                            "cap": "20060",
                            "nome": "Vignate"
                        },
                        {
                            "code": "015248",
                            "cap": "20020",
                            "nome": "Villa Cortese"
                        },
                        {
                            "code": "015242",
                            "cap": "20090",
                            "nome": "Vimodrone"
                        },
                        {
                            "code": "015243",
                            "cap": "20010",
                            "nome": "Vittuone"
                        },
                        {
                            "code": "015244",
                            "cap": "20070",
                            "nome": "Vizzolo Predabissi"
                        },
                        {
                            "code": "015246",
                            "cap": "20080",
                            "nome": "Zelo Surrigone"
                        },
                        {
                            "code": "015247",
                            "cap": "20080",
                            "nome": "Zibido San Giacomo"
                        }
                    ],
                    "nome": "Milano"
                },
                {
                    "code": "MB",
                    "comuni": [
                        {
                            "code": "108001",
                            "cap": "20864",
                            "nome": "Agrate Brianza"
                        },
                        {
                            "code": "108002",
                            "cap": "20886",
                            "nome": "Aicurzio"
                        },
                        {
                            "code": "108003",
                            "cap": "20847",
                            "nome": "Albiate"
                        },
                        {
                            "code": "108004",
                            "cap": "20862",
                            "nome": "Arcore"
                        },
                        {
                            "code": "108005",
                            "cap": "20825",
                            "nome": "Barlassina"
                        },
                        {
                            "code": "108006",
                            "cap": "20882",
                            "nome": "Bellusco"
                        },
                        {
                            "code": "108007",
                            "cap": "20881",
                            "nome": "Bernareggio"
                        },
                        {
                            "code": "108008",
                            "cap": "20842",
                            "nome": "Besana in Brianza"
                        },
                        {
                            "code": "108009",
                            "cap": "20853",
                            "nome": "Biassono"
                        },
                        {
                            "code": "108010",
                            "cap": "20813",
                            "nome": "Bovisio-Masciago"
                        },
                        {
                            "code": "108011",
                            "cap": "20836",
                            "nome": "Briosco"
                        },
                        {
                            "code": "108012",
                            "cap": "20861",
                            "nome": "Brugherio"
                        },
                        {
                            "code": "108013",
                            "cap": "20875",
                            "nome": "Burago di Molgora"
                        },
                        {
                            "code": "108051",
                            "cap": "20874",
                            "nome": "Busnago"
                        },
                        {
                            "code": "108014",
                            "cap": "20857",
                            "nome": "Camparada"
                        },
                        {
                            "code": "108052",
                            "cap": "20867",
                            "nome": "Caponago"
                        },
                        {
                            "code": "108015",
                            "cap": "20841",
                            "nome": "Carate Brianza"
                        },
                        {
                            "code": "108016",
                            "cap": "20866",
                            "nome": "Carnate"
                        },
                        {
                            "code": "108017",
                            "cap": "20873",
                            "nome": "Cavenago di Brianza"
                        },
                        {
                            "code": "108018",
                            "cap": "20816",
                            "nome": "Ceriano Laghetto"
                        },
                        {
                            "code": "108019",
                            "cap": "20811",
                            "nome": "Cesano Maderno"
                        },
                        {
                            "code": "108020",
                            "cap": "20815",
                            "nome": "Cogliate"
                        },
                        {
                            "code": "108021",
                            "cap": "20863",
                            "nome": "Concorezzo"
                        },
                        {
                            "code": "108053",
                            "cap": "20872",
                            "nome": "Cornate d'Adda"
                        },
                        {
                            "code": "108022",
                            "cap": "20856",
                            "nome": "Correzzana"
                        },
                        {
                            "code": "108023",
                            "cap": "20832",
                            "nome": "Desio"
                        },
                        {
                            "code": "108024",
                            "cap": "20833",
                            "nome": "Giussano"
                        },
                        {
                            "code": "108025",
                            "cap": "20824",
                            "nome": "Lazzate"
                        },
                        {
                            "code": "108054",
                            "cap": "20823",
                            "nome": "Lentate sul Seveso"
                        },
                        {
                            "code": "108026",
                            "cap": "20855",
                            "nome": "Lesmo"
                        },
                        {
                            "code": "108027",
                            "cap": "20812",
                            "nome": "Limbiate"
                        },
                        {
                            "code": "108028",
                            "cap": "20851",
                            "nome": "Lissone"
                        },
                        {
                            "code": "108029",
                            "cap": "20846",
                            "nome": "Macherio"
                        },
                        {
                            "code": "108030",
                            "cap": "20821",
                            "nome": "Meda"
                        },
                        {
                            "code": "108031",
                            "cap": "20883",
                            "nome": "Mezzago"
                        },
                        {
                            "code": "108032",
                            "cap": "20826",
                            "nome": "Misinto"
                        },
                        {
                            "code": "108033",
                            "cap": "20900",
                            "nome": "Monza"
                        },
                        {
                            "code": "108034",
                            "cap": "20835",
                            "nome": "Muggi\u00f2"
                        },
                        {
                            "code": "108035",
                            "cap": "20834",
                            "nome": "Nova Milanese"
                        },
                        {
                            "code": "108036",
                            "cap": "20876",
                            "nome": "Ornago"
                        },
                        {
                            "code": "108037",
                            "cap": "20838",
                            "nome": "Renate"
                        },
                        {
                            "code": "108055",
                            "cap": "20877",
                            "nome": "Roncello"
                        },
                        {
                            "code": "108038",
                            "cap": "20885",
                            "nome": "Ronco Briantino"
                        },
                        {
                            "code": "108039",
                            "cap": "20831",
                            "nome": "Seregno"
                        },
                        {
                            "code": "108040",
                            "cap": "20822",
                            "nome": "Seveso"
                        },
                        {
                            "code": "108041",
                            "cap": "20845",
                            "nome": "Sovico"
                        },
                        {
                            "code": "108042",
                            "cap": "20884",
                            "nome": "Sulbiate"
                        },
                        {
                            "code": "108043",
                            "cap": "20844",
                            "nome": "Triuggio"
                        },
                        {
                            "code": "108044",
                            "cap": "20865",
                            "nome": "Usmate Velate"
                        },
                        {
                            "code": "108045",
                            "cap": "20814",
                            "nome": "Varedo"
                        },
                        {
                            "code": "108046",
                            "cap": "20854",
                            "nome": "Vedano al Lambro"
                        },
                        {
                            "code": "108047",
                            "cap": "20837",
                            "nome": "Veduggio con Colzano"
                        },
                        {
                            "code": "108048",
                            "cap": "20843",
                            "nome": "Verano Brianza"
                        },
                        {
                            "code": "108049",
                            "cap": "20852",
                            "nome": "Villasanta"
                        },
                        {
                            "code": "108050",
                            "cap": "20871",
                            "nome": "Vimercate"
                        }
                    ],
                    "nome": "Monza e Brianza"
                },
                {
                    "code": "PV",
                    "comuni": [
                        {
                            "code": "018001",
                            "cap": "27020",
                            "nome": "Alagna"
                        },
                        {
                            "code": "018002",
                            "cap": "27040",
                            "nome": "Albaredo Arnaboldi"
                        },
                        {
                            "code": "018003",
                            "cap": "27020",
                            "nome": "Albonese"
                        },
                        {
                            "code": "018004",
                            "cap": "27010",
                            "nome": "Albuzzano"
                        },
                        {
                            "code": "018005",
                            "cap": "27040",
                            "nome": "Arena Po"
                        },
                        {
                            "code": "018006",
                            "cap": "27010",
                            "nome": "Badia Pavese"
                        },
                        {
                            "code": "018007",
                            "cap": "27050",
                            "nome": "Bagnaria"
                        },
                        {
                            "code": "018008",
                            "cap": "27041",
                            "nome": "Barbianello"
                        },
                        {
                            "code": "018009",
                            "cap": "27010",
                            "nome": "Bascap\u00e8"
                        },
                        {
                            "code": "018010",
                            "cap": "27050",
                            "nome": "Bastida de' Dossi"
                        },
                        {
                            "code": "018011",
                            "cap": "27050",
                            "nome": "Bastida Pancarana"
                        },
                        {
                            "code": "018012",
                            "cap": "27020",
                            "nome": "Battuda"
                        },
                        {
                            "code": "018013",
                            "cap": "27011",
                            "nome": "Belgioioso"
                        },
                        {
                            "code": "018014",
                            "cap": "27021",
                            "nome": "Bereguardo"
                        },
                        {
                            "code": "018015",
                            "cap": "27010",
                            "nome": "Borgarello"
                        },
                        {
                            "code": "018016",
                            "cap": "27040",
                            "nome": "Borgo Priolo"
                        },
                        {
                            "code": "018018",
                            "cap": "27020",
                            "nome": "Borgo San Siro"
                        },
                        {
                            "code": "018017",
                            "cap": "27040",
                            "nome": "Borgoratto Mormorolo"
                        },
                        {
                            "code": "018019",
                            "cap": "27010",
                            "nome": "Bornasco"
                        },
                        {
                            "code": "018020",
                            "cap": "27040",
                            "nome": "Bosnasco"
                        },
                        {
                            "code": "018021",
                            "cap": "27050",
                            "nome": "Brallo di Pregola"
                        },
                        {
                            "code": "018022",
                            "cap": "27020",
                            "nome": "Breme"
                        },
                        {
                            "code": "018023",
                            "cap": "27042",
                            "nome": "Bressana Bottarone"
                        },
                        {
                            "code": "018024",
                            "cap": "27043",
                            "nome": "Broni"
                        },
                        {
                            "code": "018025",
                            "cap": "27040",
                            "nome": "Calvignano"
                        },
                        {
                            "code": "018026",
                            "cap": "27040",
                            "nome": "Campospinoso"
                        },
                        {
                            "code": "018027",
                            "cap": "27031",
                            "nome": "Candia Lomellina"
                        },
                        {
                            "code": "018028",
                            "cap": "27040",
                            "nome": "Canevino"
                        },
                        {
                            "code": "018029",
                            "cap": "27044",
                            "nome": "Canneto Pavese"
                        },
                        {
                            "code": "018030",
                            "cap": "27020",
                            "nome": "Carbonara al Ticino"
                        },
                        {
                            "code": "018031",
                            "cap": "27041",
                            "nome": "Casanova Lonati"
                        },
                        {
                            "code": "018032",
                            "cap": "27040",
                            "nome": "Casatisma"
                        },
                        {
                            "code": "018033",
                            "cap": "27050",
                            "nome": "Casei Gerola"
                        },
                        {
                            "code": "018034",
                            "cap": "27022",
                            "nome": "Casorate Primo"
                        },
                        {
                            "code": "018035",
                            "cap": "27023",
                            "nome": "Cassolnovo"
                        },
                        {
                            "code": "018036",
                            "cap": "27040",
                            "nome": "Castana"
                        },
                        {
                            "code": "018037",
                            "cap": "27045",
                            "nome": "Casteggio"
                        },
                        {
                            "code": "018038",
                            "cap": "27040",
                            "nome": "Castelletto di Branduzzo"
                        },
                        {
                            "code": "018039",
                            "cap": "27030",
                            "nome": "Castello d'Agogna"
                        },
                        {
                            "code": "018040",
                            "cap": "27030",
                            "nome": "Castelnovetto"
                        },
                        {
                            "code": "018041",
                            "cap": "27051",
                            "nome": "Cava Manara"
                        },
                        {
                            "code": "018042",
                            "cap": "27050",
                            "nome": "Cecima"
                        },
                        {
                            "code": "018043",
                            "cap": "27010",
                            "nome": "Ceranova"
                        },
                        {
                            "code": "018044",
                            "cap": "27030",
                            "nome": "Ceretto Lomellina"
                        },
                        {
                            "code": "018045",
                            "cap": "27020",
                            "nome": "Cergnago"
                        },
                        {
                            "code": "018046",
                            "cap": "27012",
                            "nome": "Certosa di Pavia"
                        },
                        {
                            "code": "018047",
                            "cap": "27050",
                            "nome": "Cervesina"
                        },
                        {
                            "code": "018048",
                            "cap": "27013",
                            "nome": "Chignolo Po"
                        },
                        {
                            "code": "018049",
                            "cap": "27040",
                            "nome": "Cigognola"
                        },
                        {
                            "code": "018050",
                            "cap": "27024",
                            "nome": "Cilavegna"
                        },
                        {
                            "code": "018051",
                            "cap": "27050",
                            "nome": "Codevilla"
                        },
                        {
                            "code": "018052",
                            "cap": "27030",
                            "nome": "Confienza"
                        },
                        {
                            "code": "018053",
                            "cap": "27010",
                            "nome": "Copiano"
                        },
                        {
                            "code": "018054",
                            "cap": "27050",
                            "nome": "Corana"
                        },
                        {
                            "code": "018055",
                            "cap": "27050",
                            "nome": "Cornale"
                        },
                        {
                            "code": "018056",
                            "cap": "27014",
                            "nome": "Corteolona"
                        },
                        {
                            "code": "018057",
                            "cap": "27050",
                            "nome": "Corvino San Quirico"
                        },
                        {
                            "code": "018058",
                            "cap": "27010",
                            "nome": "Costa de' Nobili"
                        },
                        {
                            "code": "018059",
                            "cap": "27030",
                            "nome": "Cozzo"
                        },
                        {
                            "code": "018060",
                            "cap": "27010",
                            "nome": "Cura Carpignano"
                        },
                        {
                            "code": "018061",
                            "cap": "27020",
                            "nome": "Dorno"
                        },
                        {
                            "code": "018062",
                            "cap": "27032",
                            "nome": "Ferrera Erbognone"
                        },
                        {
                            "code": "018063",
                            "cap": "27010",
                            "nome": "Filighera"
                        },
                        {
                            "code": "018064",
                            "cap": "27040",
                            "nome": "Fortunago"
                        },
                        {
                            "code": "018065",
                            "cap": "27030",
                            "nome": "Frascarolo"
                        },
                        {
                            "code": "018066",
                            "cap": "27034",
                            "nome": "Galliavola"
                        },
                        {
                            "code": "018067",
                            "cap": "27030",
                            "nome": "Gambarana"
                        },
                        {
                            "code": "018068",
                            "cap": "27025",
                            "nome": "Gambol\u00f2"
                        },
                        {
                            "code": "018069",
                            "cap": "27026",
                            "nome": "Garlasco"
                        },
                        {
                            "code": "018070",
                            "cap": "27014",
                            "nome": "Genzone"
                        },
                        {
                            "code": "018071",
                            "cap": "27010",
                            "nome": "Gerenzago"
                        },
                        {
                            "code": "018072",
                            "cap": "27010",
                            "nome": "Giussago"
                        },
                        {
                            "code": "018073",
                            "cap": "27052",
                            "nome": "Godiasco"
                        },
                        {
                            "code": "018074",
                            "cap": "27047",
                            "nome": "Golferenzo"
                        },
                        {
                            "code": "018075",
                            "cap": "27020",
                            "nome": "Gravellona Lomellina"
                        },
                        {
                            "code": "018076",
                            "cap": "27027",
                            "nome": "Gropello Cairoli"
                        },
                        {
                            "code": "018077",
                            "cap": "27010",
                            "nome": "Inverno e Monteleone"
                        },
                        {
                            "code": "018078",
                            "cap": "27015",
                            "nome": "Landriano"
                        },
                        {
                            "code": "018079",
                            "cap": "27030",
                            "nome": "Langosco"
                        },
                        {
                            "code": "018080",
                            "cap": "27016",
                            "nome": "Lardirago"
                        },
                        {
                            "code": "018081",
                            "cap": "27010",
                            "nome": "Linarolo"
                        },
                        {
                            "code": "018082",
                            "cap": "27040",
                            "nome": "Lirio"
                        },
                        {
                            "code": "018083",
                            "cap": "27034",
                            "nome": "Lomello"
                        },
                        {
                            "code": "018084",
                            "cap": "27053",
                            "nome": "Lungavilla"
                        },
                        {
                            "code": "018085",
                            "cap": "27010",
                            "nome": "Magherno"
                        },
                        {
                            "code": "018086",
                            "cap": "27020",
                            "nome": "Marcignago"
                        },
                        {
                            "code": "018087",
                            "cap": "27010",
                            "nome": "Marzano"
                        },
                        {
                            "code": "018088",
                            "cap": "27035",
                            "nome": "Mede"
                        },
                        {
                            "code": "018089",
                            "cap": "27050",
                            "nome": "Menconico"
                        },
                        {
                            "code": "018090",
                            "cap": "27030",
                            "nome": "Mezzana Bigli"
                        },
                        {
                            "code": "018091",
                            "cap": "27030",
                            "nome": "Mezzana Rabattone"
                        },
                        {
                            "code": "018092",
                            "cap": "27040",
                            "nome": "Mezzanino"
                        },
                        {
                            "code": "018093",
                            "cap": "27010",
                            "nome": "Miradolo Terme"
                        },
                        {
                            "code": "018094",
                            "cap": "27040",
                            "nome": "Montalto Pavese"
                        },
                        {
                            "code": "018095",
                            "cap": "27054",
                            "nome": "Montebello della Battaglia"
                        },
                        {
                            "code": "018096",
                            "cap": "27047",
                            "nome": "Montecalvo Versiggia"
                        },
                        {
                            "code": "018097",
                            "cap": "27040",
                            "nome": "Montescano"
                        },
                        {
                            "code": "018098",
                            "cap": "27052",
                            "nome": "Montesegale"
                        },
                        {
                            "code": "018099",
                            "cap": "27010",
                            "nome": "Monticelli Pavese"
                        },
                        {
                            "code": "018100",
                            "cap": "27040",
                            "nome": "Mont\u00f9 Beccaria"
                        },
                        {
                            "code": "018101",
                            "cap": "27040",
                            "nome": "Mornico Losana"
                        },
                        {
                            "code": "018102",
                            "cap": "27036",
                            "nome": "Mortara"
                        },
                        {
                            "code": "018103",
                            "cap": "27020",
                            "nome": "Nicorvo"
                        },
                        {
                            "code": "018104",
                            "cap": "27020",
                            "nome": "Olevano di Lomellina"
                        },
                        {
                            "code": "018105",
                            "cap": "27050",
                            "nome": "Oliva Gessi"
                        },
                        {
                            "code": "018106",
                            "cap": "27030",
                            "nome": "Ottobiano"
                        },
                        {
                            "code": "018107",
                            "cap": "27030",
                            "nome": "Palestro"
                        },
                        {
                            "code": "018108",
                            "cap": "27050",
                            "nome": "Pancarana"
                        },
                        {
                            "code": "018109",
                            "cap": "27020",
                            "nome": "Parona"
                        },
                        {
                            "code": "018110",
                            "cap": "27100",
                            "nome": "Pavia"
                        },
                        {
                            "code": "018111",
                            "cap": "27040",
                            "nome": "Pietra de' Giorgi"
                        },
                        {
                            "code": "018112",
                            "cap": "27030",
                            "nome": "Pieve Albignola"
                        },
                        {
                            "code": "018113",
                            "cap": "27037",
                            "nome": "Pieve del Cairo"
                        },
                        {
                            "code": "018114",
                            "cap": "27017",
                            "nome": "Pieve Porto Morone"
                        },
                        {
                            "code": "018115",
                            "cap": "27040",
                            "nome": "Pinarolo Po"
                        },
                        {
                            "code": "018116",
                            "cap": "27050",
                            "nome": "Pizzale"
                        },
                        {
                            "code": "018117",
                            "cap": "27050",
                            "nome": "Ponte Nizza"
                        },
                        {
                            "code": "018118",
                            "cap": "27040",
                            "nome": "Portalbera"
                        },
                        {
                            "code": "018119",
                            "cap": "27040",
                            "nome": "Rea"
                        },
                        {
                            "code": "018120",
                            "cap": "27050",
                            "nome": "Redavalle"
                        },
                        {
                            "code": "018121",
                            "cap": "27050",
                            "nome": "Retorbido"
                        },
                        {
                            "code": "018122",
                            "cap": "27055",
                            "nome": "Rivanazzano Terme"
                        },
                        {
                            "code": "018123",
                            "cap": "27038",
                            "nome": "Robbio"
                        },
                        {
                            "code": "018124",
                            "cap": "27042",
                            "nome": "Robecco Pavese"
                        },
                        {
                            "code": "018125",
                            "cap": "27040",
                            "nome": "Rocca de' Giorgi"
                        },
                        {
                            "code": "018126",
                            "cap": "27052",
                            "nome": "Rocca Susella"
                        },
                        {
                            "code": "018127",
                            "cap": "27010",
                            "nome": "Rognano"
                        },
                        {
                            "code": "018128",
                            "cap": "27050",
                            "nome": "Romagnese"
                        },
                        {
                            "code": "018129",
                            "cap": "27010",
                            "nome": "Roncaro"
                        },
                        {
                            "code": "018130",
                            "cap": "27030",
                            "nome": "Rosasco"
                        },
                        {
                            "code": "018131",
                            "cap": "27040",
                            "nome": "Rovescala"
                        },
                        {
                            "code": "018132",
                            "cap": "27040",
                            "nome": "Ruino"
                        },
                        {
                            "code": "018133",
                            "cap": "27043",
                            "nome": "San Cipriano Po"
                        },
                        {
                            "code": "018134",
                            "cap": "27040",
                            "nome": "San Damiano al Colle"
                        },
                        {
                            "code": "018135",
                            "cap": "27010",
                            "nome": "San Genesio ed Uniti"
                        },
                        {
                            "code": "018136",
                            "cap": "27020",
                            "nome": "San Giorgio di Lomellina"
                        },
                        {
                            "code": "018137",
                            "cap": "27028",
                            "nome": "San Martino Siccomario"
                        },
                        {
                            "code": "018145",
                            "cap": "27010",
                            "nome": "San Zenone al Po"
                        },
                        {
                            "code": "018138",
                            "cap": "27039",
                            "nome": "Sannazzaro de' Burgondi"
                        },
                        {
                            "code": "018139",
                            "cap": "27010",
                            "nome": "Santa Cristina e Bissone"
                        },
                        {
                            "code": "018140",
                            "cap": "27046",
                            "nome": "Santa Giuletta"
                        },
                        {
                            "code": "018142",
                            "cap": "27050",
                            "nome": "Santa Margherita di Staffora"
                        },
                        {
                            "code": "018143",
                            "cap": "27047",
                            "nome": "Santa Maria della Versa"
                        },
                        {
                            "code": "018141",
                            "cap": "27016",
                            "nome": "Sant'Alessio con Vialone"
                        },
                        {
                            "code": "018144",
                            "cap": "27030",
                            "nome": "Sant'Angelo Lomellina"
                        },
                        {
                            "code": "018146",
                            "cap": "27020",
                            "nome": "Sartirana Lomellina"
                        },
                        {
                            "code": "018147",
                            "cap": "27020",
                            "nome": "Scaldasole"
                        },
                        {
                            "code": "018148",
                            "cap": "27020",
                            "nome": "Semiana"
                        },
                        {
                            "code": "018149",
                            "cap": "27050",
                            "nome": "Silvano Pietra"
                        },
                        {
                            "code": "018150",
                            "cap": "27010",
                            "nome": "Siziano"
                        },
                        {
                            "code": "018151",
                            "cap": "27048",
                            "nome": "Sommo"
                        },
                        {
                            "code": "018152",
                            "cap": "27010",
                            "nome": "Spessa"
                        },
                        {
                            "code": "018153",
                            "cap": "27049",
                            "nome": "Stradella"
                        },
                        {
                            "code": "018154",
                            "cap": "27030",
                            "nome": "Suardi"
                        },
                        {
                            "code": "018155",
                            "cap": "27050",
                            "nome": "Torrazza Coste"
                        },
                        {
                            "code": "018156",
                            "cap": "27030",
                            "nome": "Torre Beretti e Castellaro"
                        },
                        {
                            "code": "018157",
                            "cap": "27010",
                            "nome": "Torre d'Arese"
                        },
                        {
                            "code": "018158",
                            "cap": "27011",
                            "nome": "Torre de' Negri"
                        },
                        {
                            "code": "018159",
                            "cap": "27020",
                            "nome": "Torre d'Isola"
                        },
                        {
                            "code": "018160",
                            "cap": "27010",
                            "nome": "Torrevecchia Pia"
                        },
                        {
                            "code": "018161",
                            "cap": "27050",
                            "nome": "Torricella Verzate"
                        },
                        {
                            "code": "018162",
                            "cap": "27020",
                            "nome": "Travac\u00f2 Siccomario"
                        },
                        {
                            "code": "018163",
                            "cap": "27020",
                            "nome": "Trivolzio"
                        },
                        {
                            "code": "018164",
                            "cap": "27020",
                            "nome": "Tromello"
                        },
                        {
                            "code": "018165",
                            "cap": "27020",
                            "nome": "Trovo"
                        },
                        {
                            "code": "018166",
                            "cap": "27050",
                            "nome": "Val di Nizza"
                        },
                        {
                            "code": "018167",
                            "cap": "27020",
                            "nome": "Valeggio"
                        },
                        {
                            "code": "018168",
                            "cap": "27020",
                            "nome": "Valle Lomellina"
                        },
                        {
                            "code": "018169",
                            "cap": "27010",
                            "nome": "Valle Salimbene"
                        },
                        {
                            "code": "018170",
                            "cap": "27050",
                            "nome": "Valverde"
                        },
                        {
                            "code": "018171",
                            "cap": "27057",
                            "nome": "Varzi"
                        },
                        {
                            "code": "018172",
                            "cap": "27020",
                            "nome": "Velezzo Lomellina"
                        },
                        {
                            "code": "018173",
                            "cap": "27010",
                            "nome": "Vellezzo Bellini"
                        },
                        {
                            "code": "018174",
                            "cap": "27053",
                            "nome": "Verretto"
                        },
                        {
                            "code": "018175",
                            "cap": "27040",
                            "nome": "Verrua Po"
                        },
                        {
                            "code": "018176",
                            "cap": "27018",
                            "nome": "Vidigulfo"
                        },
                        {
                            "code": "018177",
                            "cap": "27029",
                            "nome": "Vigevano"
                        },
                        {
                            "code": "018178",
                            "cap": "27035",
                            "nome": "Villa Biscossi"
                        },
                        {
                            "code": "018179",
                            "cap": "27030",
                            "nome": "Villanova d'Ardenghi"
                        },
                        {
                            "code": "018180",
                            "cap": "27019",
                            "nome": "Villanterio"
                        },
                        {
                            "code": "018181",
                            "cap": "27010",
                            "nome": "Vistarino"
                        },
                        {
                            "code": "018182",
                            "cap": "27058",
                            "nome": "Voghera"
                        },
                        {
                            "code": "018183",
                            "cap": "27047",
                            "nome": "Volpara"
                        },
                        {
                            "code": "018184",
                            "cap": "27059",
                            "nome": "Zavattarello"
                        },
                        {
                            "code": "018185",
                            "cap": "27010",
                            "nome": "Zeccone"
                        },
                        {
                            "code": "018186",
                            "cap": "27030",
                            "nome": "Zeme"
                        },
                        {
                            "code": "018187",
                            "cap": "27049",
                            "nome": "Zenevredo"
                        },
                        {
                            "code": "018188",
                            "cap": "27017",
                            "nome": "Zerbo"
                        },
                        {
                            "code": "018189",
                            "cap": "27020",
                            "nome": "Zerbol\u00f2"
                        },
                        {
                            "code": "018190",
                            "cap": "27030",
                            "nome": "Zinasco"
                        }
                    ],
                    "nome": "Pavia"
                },
                {
                    "code": "SO",
                    "comuni": [
                        {
                            "code": "014001",
                            "cap": "23010",
                            "nome": "Albaredo per San Marco"
                        },
                        {
                            "code": "014002",
                            "cap": "23010",
                            "nome": "Albosaggia"
                        },
                        {
                            "code": "014003",
                            "cap": "23014",
                            "nome": "Andalo Valtellino"
                        },
                        {
                            "code": "014004",
                            "cap": "23031",
                            "nome": "Aprica"
                        },
                        {
                            "code": "014005",
                            "cap": "23011",
                            "nome": "Ardenno"
                        },
                        {
                            "code": "014006",
                            "cap": "23010",
                            "nome": "Bema"
                        },
                        {
                            "code": "014007",
                            "cap": "23010",
                            "nome": "Berbenno di Valtellina"
                        },
                        {
                            "code": "014008",
                            "cap": "23030",
                            "nome": "Bianzone"
                        },
                        {
                            "code": "014009",
                            "cap": "23032",
                            "nome": "Bormio"
                        },
                        {
                            "code": "014010",
                            "cap": "23010",
                            "nome": "Buglio in Monte"
                        },
                        {
                            "code": "014011",
                            "cap": "23010",
                            "nome": "Caiolo"
                        },
                        {
                            "code": "014012",
                            "cap": "23021",
                            "nome": "Campodolcino"
                        },
                        {
                            "code": "014013",
                            "cap": "23020",
                            "nome": "Caspoggio"
                        },
                        {
                            "code": "014014",
                            "cap": "23030",
                            "nome": "Castello dell'Acqua"
                        },
                        {
                            "code": "014015",
                            "cap": "23012",
                            "nome": "Castione Andevenno"
                        },
                        {
                            "code": "014016",
                            "cap": "23010",
                            "nome": "Cedrasco"
                        },
                        {
                            "code": "014017",
                            "cap": "23016",
                            "nome": "Cercino"
                        },
                        {
                            "code": "014018",
                            "cap": "23022",
                            "nome": "Chiavenna"
                        },
                        {
                            "code": "014019",
                            "cap": "23023",
                            "nome": "Chiesa in Valmalenco"
                        },
                        {
                            "code": "014020",
                            "cap": "23030",
                            "nome": "Chiuro"
                        },
                        {
                            "code": "014021",
                            "cap": "23010",
                            "nome": "Cino"
                        },
                        {
                            "code": "014022",
                            "cap": "23010",
                            "nome": "Civo"
                        },
                        {
                            "code": "014023",
                            "cap": "23010",
                            "nome": "Colorina"
                        },
                        {
                            "code": "014024",
                            "cap": "23013",
                            "nome": "Cosio Valtellino"
                        },
                        {
                            "code": "014025",
                            "cap": "23010",
                            "nome": "Dazio"
                        },
                        {
                            "code": "014026",
                            "cap": "23014",
                            "nome": "Delebio"
                        },
                        {
                            "code": "014027",
                            "cap": "23015",
                            "nome": "Dubino"
                        },
                        {
                            "code": "014028",
                            "cap": "23020",
                            "nome": "Faedo Valtellino"
                        },
                        {
                            "code": "014029",
                            "cap": "23010",
                            "nome": "Forcola"
                        },
                        {
                            "code": "014030",
                            "cap": "23010",
                            "nome": "Fusine"
                        },
                        {
                            "code": "014031",
                            "cap": "23010",
                            "nome": "Gerola Alta"
                        },
                        {
                            "code": "014032",
                            "cap": "23020",
                            "nome": "Gordona"
                        },
                        {
                            "code": "014033",
                            "cap": "23033",
                            "nome": "Grosio"
                        },
                        {
                            "code": "014034",
                            "cap": "23034",
                            "nome": "Grosotto"
                        },
                        {
                            "code": "014036",
                            "cap": "23020",
                            "nome": "Lanzada"
                        },
                        {
                            "code": "014037",
                            "cap": "23030",
                            "nome": "Livigno"
                        },
                        {
                            "code": "014038",
                            "cap": "23030",
                            "nome": "Lovero"
                        },
                        {
                            "code": "014035",
                            "cap": "23024",
                            "nome": "Madesimo"
                        },
                        {
                            "code": "014039",
                            "cap": "23016",
                            "nome": "Mantello"
                        },
                        {
                            "code": "014040",
                            "cap": "23030",
                            "nome": "Mazzo di Valtellina"
                        },
                        {
                            "code": "014041",
                            "cap": "23010",
                            "nome": "Mello"
                        },
                        {
                            "code": "014042",
                            "cap": "23020",
                            "nome": "Menarola"
                        },
                        {
                            "code": "014043",
                            "cap": "23020",
                            "nome": "Mese"
                        },
                        {
                            "code": "014044",
                            "cap": "23020",
                            "nome": "Montagna in Valtellina"
                        },
                        {
                            "code": "014045",
                            "cap": "23017",
                            "nome": "Morbegno"
                        },
                        {
                            "code": "014046",
                            "cap": "23025",
                            "nome": "Novate Mezzola"
                        },
                        {
                            "code": "014047",
                            "cap": "23010",
                            "nome": "Pedesina"
                        },
                        {
                            "code": "014048",
                            "cap": "23010",
                            "nome": "Piantedo"
                        },
                        {
                            "code": "014049",
                            "cap": "23020",
                            "nome": "Piateda"
                        },
                        {
                            "code": "014050",
                            "cap": "23020",
                            "nome": "Piuro"
                        },
                        {
                            "code": "014051",
                            "cap": "23020",
                            "nome": "Poggiridenti"
                        },
                        {
                            "code": "014052",
                            "cap": "23026",
                            "nome": "Ponte in Valtellina"
                        },
                        {
                            "code": "014053",
                            "cap": "23010",
                            "nome": "Postalesio"
                        },
                        {
                            "code": "014054",
                            "cap": "23020",
                            "nome": "Prata Camportaccio"
                        },
                        {
                            "code": "014055",
                            "cap": "23010",
                            "nome": "Rasura"
                        },
                        {
                            "code": "014056",
                            "cap": "23010",
                            "nome": "Rogolo"
                        },
                        {
                            "code": "014057",
                            "cap": "23027",
                            "nome": "Samolaco"
                        },
                        {
                            "code": "014058",
                            "cap": "23020",
                            "nome": "San Giacomo Filippo"
                        },
                        {
                            "code": "014059",
                            "cap": "23030",
                            "nome": "Sernio"
                        },
                        {
                            "code": "014060",
                            "cap": "23035",
                            "nome": "Sondalo"
                        },
                        {
                            "code": "014061",
                            "cap": "23100",
                            "nome": "Sondrio"
                        },
                        {
                            "code": "014062",
                            "cap": "23020",
                            "nome": "Spriana"
                        },
                        {
                            "code": "014063",
                            "cap": "23018",
                            "nome": "Talamona"
                        },
                        {
                            "code": "014064",
                            "cap": "23010",
                            "nome": "Tartano"
                        },
                        {
                            "code": "014065",
                            "cap": "23036",
                            "nome": "Teglio"
                        },
                        {
                            "code": "014066",
                            "cap": "23037",
                            "nome": "Tirano"
                        },
                        {
                            "code": "014067",
                            "cap": "23020",
                            "nome": "Torre di Santa Maria"
                        },
                        {
                            "code": "014068",
                            "cap": "23030",
                            "nome": "Tovo di Sant'Agata"
                        },
                        {
                            "code": "014069",
                            "cap": "23019",
                            "nome": "Traona"
                        },
                        {
                            "code": "014070",
                            "cap": "23020",
                            "nome": "Tresivio"
                        },
                        {
                            "code": "014074",
                            "cap": "23010",
                            "nome": "Val Masino"
                        },
                        {
                            "code": "014071",
                            "cap": "23038",
                            "nome": "Valdidentro"
                        },
                        {
                            "code": "014072",
                            "cap": "23030",
                            "nome": "Valdisotto"
                        },
                        {
                            "code": "014073",
                            "cap": "23030",
                            "nome": "Valfurva"
                        },
                        {
                            "code": "014075",
                            "cap": "23020",
                            "nome": "Verceia"
                        },
                        {
                            "code": "014076",
                            "cap": "23030",
                            "nome": "Vervio"
                        },
                        {
                            "code": "014077",
                            "cap": "23029",
                            "nome": "Villa di Chiavenna"
                        },
                        {
                            "code": "014078",
                            "cap": "23030",
                            "nome": "Villa di Tirano"
                        }
                    ],
                    "nome": "Sondrio"
                },
                {
                    "code": "VA",
                    "comuni": [
                        {
                            "code": "012001",
                            "cap": "21010",
                            "nome": "Agra"
                        },
                        {
                            "code": "012002",
                            "cap": "21041",
                            "nome": "Albizzate"
                        },
                        {
                            "code": "012003",
                            "cap": "21021",
                            "nome": "Angera"
                        },
                        {
                            "code": "012004",
                            "cap": "21051",
                            "nome": "Arcisate"
                        },
                        {
                            "code": "012005",
                            "cap": "21010",
                            "nome": "Arsago Seprio"
                        },
                        {
                            "code": "012006",
                            "cap": "21022",
                            "nome": "Azzate"
                        },
                        {
                            "code": "012007",
                            "cap": "21030",
                            "nome": "Azzio"
                        },
                        {
                            "code": "012008",
                            "cap": "21020",
                            "nome": "Barasso"
                        },
                        {
                            "code": "012009",
                            "cap": "21020",
                            "nome": "Bardello"
                        },
                        {
                            "code": "012010",
                            "cap": "21039",
                            "nome": "Bedero Valcuvia"
                        },
                        {
                            "code": "012011",
                            "cap": "21050",
                            "nome": "Besano"
                        },
                        {
                            "code": "012012",
                            "cap": "21010",
                            "nome": "Besnate"
                        },
                        {
                            "code": "012013",
                            "cap": "21023",
                            "nome": "Besozzo"
                        },
                        {
                            "code": "012014",
                            "cap": "21024",
                            "nome": "Biandronno"
                        },
                        {
                            "code": "012015",
                            "cap": "21050",
                            "nome": "Bisuschio"
                        },
                        {
                            "code": "012016",
                            "cap": "21020",
                            "nome": "Bodio Lomnago"
                        },
                        {
                            "code": "012017",
                            "cap": "21020",
                            "nome": "Brebbia"
                        },
                        {
                            "code": "012018",
                            "cap": "21020",
                            "nome": "Bregano"
                        },
                        {
                            "code": "012019",
                            "cap": "21030",
                            "nome": "Brenta"
                        },
                        {
                            "code": "012020",
                            "cap": "21010",
                            "nome": "Brezzo di Bedero"
                        },
                        {
                            "code": "012021",
                            "cap": "21030",
                            "nome": "Brinzio"
                        },
                        {
                            "code": "012022",
                            "cap": "21030",
                            "nome": "Brissago-Valtravaglia"
                        },
                        {
                            "code": "012023",
                            "cap": "21020",
                            "nome": "Brunello"
                        },
                        {
                            "code": "012024",
                            "cap": "21050",
                            "nome": "Brusimpiano"
                        },
                        {
                            "code": "012025",
                            "cap": "21020",
                            "nome": "Buguggiate"
                        },
                        {
                            "code": "012026",
                            "cap": "21052",
                            "nome": "Busto Arsizio"
                        },
                        {
                            "code": "012027",
                            "cap": "21031",
                            "nome": "Cadegliano-Viconago"
                        },
                        {
                            "code": "012028",
                            "cap": "21020",
                            "nome": "Cadrezzate"
                        },
                        {
                            "code": "012029",
                            "cap": "21050",
                            "nome": "Cairate"
                        },
                        {
                            "code": "012030",
                            "cap": "21050",
                            "nome": "Cantello"
                        },
                        {
                            "code": "012031",
                            "cap": "21032",
                            "nome": "Caravate"
                        },
                        {
                            "code": "012032",
                            "cap": "21010",
                            "nome": "Cardano al Campo"
                        },
                        {
                            "code": "012033",
                            "cap": "21040",
                            "nome": "Carnago"
                        },
                        {
                            "code": "012034",
                            "cap": "21042",
                            "nome": "Caronno Pertusella"
                        },
                        {
                            "code": "012035",
                            "cap": "21040",
                            "nome": "Caronno Varesino"
                        },
                        {
                            "code": "012036",
                            "cap": "21020",
                            "nome": "Casale Litta"
                        },
                        {
                            "code": "012037",
                            "cap": "21030",
                            "nome": "Casalzuigno"
                        },
                        {
                            "code": "012038",
                            "cap": "21020",
                            "nome": "Casciago"
                        },
                        {
                            "code": "012039",
                            "cap": "21011",
                            "nome": "Casorate Sempione"
                        },
                        {
                            "code": "012040",
                            "cap": "21012",
                            "nome": "Cassano Magnago"
                        },
                        {
                            "code": "012041",
                            "cap": "21030",
                            "nome": "Cassano Valcuvia"
                        },
                        {
                            "code": "012042",
                            "cap": "21053",
                            "nome": "Castellanza"
                        },
                        {
                            "code": "012043",
                            "cap": "21030",
                            "nome": "Castello Cabiaglio"
                        },
                        {
                            "code": "012044",
                            "cap": "21050",
                            "nome": "Castelseprio"
                        },
                        {
                            "code": "012045",
                            "cap": "21010",
                            "nome": "Castelveccana"
                        },
                        {
                            "code": "012046",
                            "cap": "21043",
                            "nome": "Castiglione Olona"
                        },
                        {
                            "code": "012047",
                            "cap": "21040",
                            "nome": "Castronno"
                        },
                        {
                            "code": "012048",
                            "cap": "21044",
                            "nome": "Cavaria con Premezzo"
                        },
                        {
                            "code": "012049",
                            "cap": "21020",
                            "nome": "Cazzago Brabbia"
                        },
                        {
                            "code": "012050",
                            "cap": "21040",
                            "nome": "Cislago"
                        },
                        {
                            "code": "012051",
                            "cap": "21033",
                            "nome": "Cittiglio"
                        },
                        {
                            "code": "012052",
                            "cap": "21050",
                            "nome": "Clivio"
                        },
                        {
                            "code": "012053",
                            "cap": "21034",
                            "nome": "Cocquio-Trevisago"
                        },
                        {
                            "code": "012054",
                            "cap": "21020",
                            "nome": "Comabbio"
                        },
                        {
                            "code": "012055",
                            "cap": "21025",
                            "nome": "Comerio"
                        },
                        {
                            "code": "012056",
                            "cap": "21030",
                            "nome": "Cremenaga"
                        },
                        {
                            "code": "012057",
                            "cap": "21020",
                            "nome": "Crosio della Valle"
                        },
                        {
                            "code": "012058",
                            "cap": "21050",
                            "nome": "Cuasso al Monte"
                        },
                        {
                            "code": "012059",
                            "cap": "21030",
                            "nome": "Cugliate-Fabiasco"
                        },
                        {
                            "code": "012060",
                            "cap": "21035",
                            "nome": "Cunardo"
                        },
                        {
                            "code": "012061",
                            "cap": "21010",
                            "nome": "Curiglia con Monteviasco"
                        },
                        {
                            "code": "012062",
                            "cap": "21030",
                            "nome": "Cuveglio"
                        },
                        {
                            "code": "012063",
                            "cap": "21030",
                            "nome": "Cuvio"
                        },
                        {
                            "code": "012064",
                            "cap": "21020",
                            "nome": "Daverio"
                        },
                        {
                            "code": "012065",
                            "cap": "21010",
                            "nome": "Dumenza"
                        },
                        {
                            "code": "012066",
                            "cap": "21030",
                            "nome": "Duno"
                        },
                        {
                            "code": "012067",
                            "cap": "21054",
                            "nome": "Fagnano Olona"
                        },
                        {
                            "code": "012068",
                            "cap": "21010",
                            "nome": "Ferno"
                        },
                        {
                            "code": "012069",
                            "cap": "21030",
                            "nome": "Ferrera di Varese"
                        },
                        {
                            "code": "012070",
                            "cap": "21013",
                            "nome": "Gallarate"
                        },
                        {
                            "code": "012071",
                            "cap": "21020",
                            "nome": "Galliate Lombardo"
                        },
                        {
                            "code": "012072",
                            "cap": "21026",
                            "nome": "Gavirate"
                        },
                        {
                            "code": "012073",
                            "cap": "21045",
                            "nome": "Gazzada Schianno"
                        },
                        {
                            "code": "012074",
                            "cap": "21036",
                            "nome": "Gemonio"
                        },
                        {
                            "code": "012075",
                            "cap": "21040",
                            "nome": "Gerenzano"
                        },
                        {
                            "code": "012076",
                            "cap": "21010",
                            "nome": "Germignaga"
                        },
                        {
                            "code": "012077",
                            "cap": "21010",
                            "nome": "Golasecca"
                        },
                        {
                            "code": "012078",
                            "cap": "21050",
                            "nome": "Gorla Maggiore"
                        },
                        {
                            "code": "012079",
                            "cap": "21055",
                            "nome": "Gorla Minore"
                        },
                        {
                            "code": "012080",
                            "cap": "21040",
                            "nome": "Gornate-Olona"
                        },
                        {
                            "code": "012081",
                            "cap": "21030",
                            "nome": "Grantola"
                        },
                        {
                            "code": "012082",
                            "cap": "21020",
                            "nome": "Inarzo"
                        },
                        {
                            "code": "012083",
                            "cap": "21056",
                            "nome": "Induno Olona"
                        },
                        {
                            "code": "012084",
                            "cap": "21027",
                            "nome": "Ispra"
                        },
                        {
                            "code": "012085",
                            "cap": "21040",
                            "nome": "Jerago con Orago"
                        },
                        {
                            "code": "012086",
                            "cap": "21037",
                            "nome": "Lavena Ponte Tresa"
                        },
                        {
                            "code": "012087",
                            "cap": "21014",
                            "nome": "Laveno-Mombello"
                        },
                        {
                            "code": "012088",
                            "cap": "21038",
                            "nome": "Leggiuno"
                        },
                        {
                            "code": "012089",
                            "cap": "21050",
                            "nome": "Lonate Ceppino"
                        },
                        {
                            "code": "012090",
                            "cap": "21015",
                            "nome": "Lonate Pozzolo"
                        },
                        {
                            "code": "012091",
                            "cap": "21040",
                            "nome": "Lozza"
                        },
                        {
                            "code": "012092",
                            "cap": "21016",
                            "nome": "Luino"
                        },
                        {
                            "code": "012093",
                            "cap": "21020",
                            "nome": "Luvinate"
                        },
                        {
                            "code": "012094",
                            "cap": "21010",
                            "nome": "Maccagno"
                        },
                        {
                            "code": "012095",
                            "cap": "21020",
                            "nome": "Malgesso"
                        },
                        {
                            "code": "012096",
                            "cap": "21046",
                            "nome": "Malnate"
                        },
                        {
                            "code": "012097",
                            "cap": "21030",
                            "nome": "Marchirolo"
                        },
                        {
                            "code": "012098",
                            "cap": "21050",
                            "nome": "Marnate"
                        },
                        {
                            "code": "012099",
                            "cap": "21030",
                            "nome": "Marzio"
                        },
                        {
                            "code": "012100",
                            "cap": "21030",
                            "nome": "Masciago Primo"
                        },
                        {
                            "code": "012101",
                            "cap": "21020",
                            "nome": "Mercallo"
                        },
                        {
                            "code": "012102",
                            "cap": "21030",
                            "nome": "Mesenzana"
                        },
                        {
                            "code": "012103",
                            "cap": "21010",
                            "nome": "Montegrino Valtravaglia"
                        },
                        {
                            "code": "012104",
                            "cap": "21020",
                            "nome": "Monvalle"
                        },
                        {
                            "code": "012105",
                            "cap": "21040",
                            "nome": "Morazzone"
                        },
                        {
                            "code": "012106",
                            "cap": "21020",
                            "nome": "Mornago"
                        },
                        {
                            "code": "012107",
                            "cap": "21040",
                            "nome": "Oggiona con Santo Stefano"
                        },
                        {
                            "code": "012108",
                            "cap": "21057",
                            "nome": "Olgiate Olona"
                        },
                        {
                            "code": "012109",
                            "cap": "21040",
                            "nome": "Origgio"
                        },
                        {
                            "code": "012110",
                            "cap": "21030",
                            "nome": "Orino"
                        },
                        {
                            "code": "012111",
                            "cap": "21018",
                            "nome": "Osmate"
                        },
                        {
                            "code": "012112",
                            "cap": "21010",
                            "nome": "Pino sulla Sponda del Lago Maggiore"
                        },
                        {
                            "code": "012113",
                            "cap": "21050",
                            "nome": "Porto Ceresio"
                        },
                        {
                            "code": "012114",
                            "cap": "21010",
                            "nome": "Porto Valtravaglia"
                        },
                        {
                            "code": "012115",
                            "cap": "21030",
                            "nome": "Rancio Valcuvia"
                        },
                        {
                            "code": "012116",
                            "cap": "21020",
                            "nome": "Ranco"
                        },
                        {
                            "code": "012117",
                            "cap": "21050",
                            "nome": "Saltrio"
                        },
                        {
                            "code": "012118",
                            "cap": "21017",
                            "nome": "Samarate"
                        },
                        {
                            "code": "012141",
                            "cap": "21038",
                            "nome": "Sangiano"
                        },
                        {
                            "code": "012119",
                            "cap": "21047",
                            "nome": "Saronno"
                        },
                        {
                            "code": "012120",
                            "cap": "21018",
                            "nome": "Sesto Calende"
                        },
                        {
                            "code": "012121",
                            "cap": "21048",
                            "nome": "Solbiate Arno"
                        },
                        {
                            "code": "012122",
                            "cap": "21058",
                            "nome": "Solbiate Olona"
                        },
                        {
                            "code": "012123",
                            "cap": "21019",
                            "nome": "Somma Lombardo"
                        },
                        {
                            "code": "012124",
                            "cap": "21040",
                            "nome": "Sumirago"
                        },
                        {
                            "code": "012125",
                            "cap": "21020",
                            "nome": "Taino"
                        },
                        {
                            "code": "012126",
                            "cap": "21020",
                            "nome": "Ternate"
                        },
                        {
                            "code": "012127",
                            "cap": "21049",
                            "nome": "Tradate"
                        },
                        {
                            "code": "012128",
                            "cap": "21028",
                            "nome": "Travedona-Monate"
                        },
                        {
                            "code": "012129",
                            "cap": "21010",
                            "nome": "Tronzano Lago Maggiore"
                        },
                        {
                            "code": "012130",
                            "cap": "21040",
                            "nome": "Uboldo"
                        },
                        {
                            "code": "012131",
                            "cap": "21039",
                            "nome": "Valganna"
                        },
                        {
                            "code": "012132",
                            "cap": "21020",
                            "nome": "Varano Borghi"
                        },
                        {
                            "code": "012133",
                            "cap": "21100",
                            "nome": "Varese"
                        },
                        {
                            "code": "012134",
                            "cap": "21040",
                            "nome": "Vedano Olona"
                        },
                        {
                            "code": "012135",
                            "cap": "21010",
                            "nome": "Veddasca"
                        },
                        {
                            "code": "012136",
                            "cap": "21040",
                            "nome": "Venegono Inferiore"
                        },
                        {
                            "code": "012137",
                            "cap": "21040",
                            "nome": "Venegono Superiore"
                        },
                        {
                            "code": "012138",
                            "cap": "21029",
                            "nome": "Vergiate"
                        },
                        {
                            "code": "012139",
                            "cap": "21059",
                            "nome": "Viggi\u00f9"
                        },
                        {
                            "code": "012140",
                            "cap": "21010",
                            "nome": "Vizzola Ticino"
                        }
                    ],
                    "nome": "Varese"
                }
            ],
            "nome": "Lombardia"
        },
        {
            "province": [
                {
                    "code": "AN",
                    "comuni": [
                        {
                            "code": "042001",
                            "cap": "60020",
                            "nome": "Agugliano"
                        },
                        {
                            "code": "042002",
                            "cap": "601xx",
                            "nome": "Ancona"
                        },
                        {
                            "code": "042003",
                            "cap": "60011",
                            "nome": "Arcevia"
                        },
                        {
                            "code": "042004",
                            "cap": "60010",
                            "nome": "Barbara"
                        },
                        {
                            "code": "042005",
                            "cap": "60030",
                            "nome": "Belvedere Ostrense"
                        },
                        {
                            "code": "042006",
                            "cap": "60021",
                            "nome": "Camerano"
                        },
                        {
                            "code": "042007",
                            "cap": "60020",
                            "nome": "Camerata Picena"
                        },
                        {
                            "code": "042009",
                            "cap": "60010",
                            "nome": "Castel Colonna"
                        },
                        {
                            "code": "042008",
                            "cap": "60030",
                            "nome": "Castelbellino"
                        },
                        {
                            "code": "042010",
                            "cap": "60022",
                            "nome": "Castelfidardo"
                        },
                        {
                            "code": "042011",
                            "cap": "60010",
                            "nome": "Castelleone di Suasa"
                        },
                        {
                            "code": "042012",
                            "cap": "60031",
                            "nome": "Castelplanio"
                        },
                        {
                            "code": "042013",
                            "cap": "60043",
                            "nome": "Cerreto d'Esi"
                        },
                        {
                            "code": "042014",
                            "cap": "60033",
                            "nome": "Chiaravalle"
                        },
                        {
                            "code": "042015",
                            "cap": "60013",
                            "nome": "Corinaldo"
                        },
                        {
                            "code": "042016",
                            "cap": "60034",
                            "nome": "Cupramontana"
                        },
                        {
                            "code": "042017",
                            "cap": "60044",
                            "nome": "Fabriano"
                        },
                        {
                            "code": "042018",
                            "cap": "60015",
                            "nome": "Falconara Marittima"
                        },
                        {
                            "code": "042019",
                            "cap": "60024",
                            "nome": "Filottrano"
                        },
                        {
                            "code": "042020",
                            "cap": "60040",
                            "nome": "Genga"
                        },
                        {
                            "code": "042021",
                            "cap": "60035",
                            "nome": "Jesi"
                        },
                        {
                            "code": "042022",
                            "cap": "60025",
                            "nome": "Loreto"
                        },
                        {
                            "code": "042023",
                            "cap": "60030",
                            "nome": "Maiolati Spontini"
                        },
                        {
                            "code": "042024",
                            "cap": "60030",
                            "nome": "Mergo"
                        },
                        {
                            "code": "042025",
                            "cap": "60030",
                            "nome": "Monsano"
                        },
                        {
                            "code": "042029",
                            "cap": "60030",
                            "nome": "Monte Roberto"
                        },
                        {
                            "code": "042030",
                            "cap": "60037",
                            "nome": "Monte San Vito"
                        },
                        {
                            "code": "042026",
                            "cap": "60036",
                            "nome": "Montecarotto"
                        },
                        {
                            "code": "042027",
                            "cap": "60018",
                            "nome": "Montemarciano"
                        },
                        {
                            "code": "042028",
                            "cap": "60010",
                            "nome": "Monterado"
                        },
                        {
                            "code": "042031",
                            "cap": "60030",
                            "nome": "Morro d'Alba"
                        },
                        {
                            "code": "042032",
                            "cap": "60026",
                            "nome": "Numana"
                        },
                        {
                            "code": "042033",
                            "cap": "60020",
                            "nome": "Offagna"
                        },
                        {
                            "code": "042034",
                            "cap": "60027",
                            "nome": "Osimo"
                        },
                        {
                            "code": "042035",
                            "cap": "60010",
                            "nome": "Ostra"
                        },
                        {
                            "code": "042036",
                            "cap": "60010",
                            "nome": "Ostra Vetere"
                        },
                        {
                            "code": "042037",
                            "cap": "60030",
                            "nome": "Poggio San Marcello"
                        },
                        {
                            "code": "042038",
                            "cap": "60020",
                            "nome": "Polverigi"
                        },
                        {
                            "code": "042039",
                            "cap": "60010",
                            "nome": "Ripe"
                        },
                        {
                            "code": "042040",
                            "cap": "60030",
                            "nome": "Rosora"
                        },
                        {
                            "code": "042041",
                            "cap": "60030",
                            "nome": "San Marcello"
                        },
                        {
                            "code": "042042",
                            "cap": "60038",
                            "nome": "San Paolo di Jesi"
                        },
                        {
                            "code": "042043",
                            "cap": "60030",
                            "nome": "Santa Maria Nuova"
                        },
                        {
                            "code": "042044",
                            "cap": "60041",
                            "nome": "Sassoferrato"
                        },
                        {
                            "code": "042045",
                            "cap": "60019",
                            "nome": "Senigallia"
                        },
                        {
                            "code": "042046",
                            "cap": "60030",
                            "nome": "Serra de' Conti"
                        },
                        {
                            "code": "042047",
                            "cap": "60048",
                            "nome": "Serra San Quirico"
                        },
                        {
                            "code": "042048",
                            "cap": "60020",
                            "nome": "Sirolo"
                        },
                        {
                            "code": "042049",
                            "cap": "60039",
                            "nome": "Staffolo"
                        }
                    ],
                    "nome": "Ancona"
                },
                {
                    "code": "AP",
                    "comuni": [
                        {
                            "code": "044001",
                            "cap": "63095",
                            "nome": "Acquasanta Terme"
                        },
                        {
                            "code": "044002",
                            "cap": "63075",
                            "nome": "Acquaviva Picena"
                        },
                        {
                            "code": "044005",
                            "cap": "63083",
                            "nome": "Appignano del Tronto"
                        },
                        {
                            "code": "044006",
                            "cap": "63096",
                            "nome": "Arquata del Tronto"
                        },
                        {
                            "code": "044007",
                            "cap": "63100",
                            "nome": "Ascoli Piceno"
                        },
                        {
                            "code": "044010",
                            "cap": "63063",
                            "nome": "Carassai"
                        },
                        {
                            "code": "044011",
                            "cap": "63082",
                            "nome": "Castel di Lama"
                        },
                        {
                            "code": "044012",
                            "cap": "63072",
                            "nome": "Castignano"
                        },
                        {
                            "code": "044013",
                            "cap": "63081",
                            "nome": "Castorano"
                        },
                        {
                            "code": "044014",
                            "cap": "63079",
                            "nome": "Colli del Tronto"
                        },
                        {
                            "code": "044015",
                            "cap": "63087",
                            "nome": "Comunanza"
                        },
                        {
                            "code": "044016",
                            "cap": "63067",
                            "nome": "Cossignano"
                        },
                        {
                            "code": "044017",
                            "cap": "63064",
                            "nome": "Cupra Marittima"
                        },
                        {
                            "code": "044020",
                            "cap": "63084",
                            "nome": "Folignano"
                        },
                        {
                            "code": "044021",
                            "cap": "63086",
                            "nome": "Force"
                        },
                        {
                            "code": "044023",
                            "cap": "63066",
                            "nome": "Grottammare"
                        },
                        {
                            "code": "044027",
                            "cap": "63085",
                            "nome": "Maltignano"
                        },
                        {
                            "code": "044029",
                            "cap": "63061",
                            "nome": "Massignano"
                        },
                        {
                            "code": "044031",
                            "cap": "63077",
                            "nome": "Monsampolo del Tronto"
                        },
                        {
                            "code": "044032",
                            "cap": "63068",
                            "nome": "Montalto delle Marche"
                        },
                        {
                            "code": "044034",
                            "cap": "63069",
                            "nome": "Montedinove"
                        },
                        {
                            "code": "044036",
                            "cap": "63062",
                            "nome": "Montefiore dell'Aso"
                        },
                        {
                            "code": "044038",
                            "cap": "63094",
                            "nome": "Montegallo"
                        },
                        {
                            "code": "044044",
                            "cap": "63088",
                            "nome": "Montemonaco"
                        },
                        {
                            "code": "044045",
                            "cap": "63076",
                            "nome": "Monteprandone"
                        },
                        {
                            "code": "044054",
                            "cap": "63073",
                            "nome": "Offida"
                        },
                        {
                            "code": "044056",
                            "cap": "63092",
                            "nome": "Palmiano"
                        },
                        {
                            "code": "044063",
                            "cap": "63065",
                            "nome": "Ripatransone"
                        },
                        {
                            "code": "044064",
                            "cap": "63093",
                            "nome": "Roccafluvione"
                        },
                        {
                            "code": "044065",
                            "cap": "63071",
                            "nome": "Rotella"
                        },
                        {
                            "code": "044066",
                            "cap": "63074",
                            "nome": "San Benedetto del Tronto"
                        },
                        {
                            "code": "044071",
                            "cap": "63078",
                            "nome": "Spinetoli"
                        },
                        {
                            "code": "044073",
                            "cap": "63091",
                            "nome": "Venarotta"
                        }
                    ],
                    "nome": "Ascoli Piceno"
                },
                {
                    "code": "FM",
                    "comuni": [
                        {
                            "code": "109001",
                            "cap": "63824",
                            "nome": "Altidona"
                        },
                        {
                            "code": "109002",
                            "cap": "63857",
                            "nome": "Amandola"
                        },
                        {
                            "code": "109003",
                            "cap": "63838",
                            "nome": "Belmonte Piceno"
                        },
                        {
                            "code": "109004",
                            "cap": "63828",
                            "nome": "Campofilone"
                        },
                        {
                            "code": "109005",
                            "cap": "63837",
                            "nome": "Falerone"
                        },
                        {
                            "code": "109006",
                            "cap": "63900",
                            "nome": "Fermo"
                        },
                        {
                            "code": "109007",
                            "cap": "63816",
                            "nome": "Francavilla d'Ete"
                        },
                        {
                            "code": "109008",
                            "cap": "63844",
                            "nome": "Grottazzolina"
                        },
                        {
                            "code": "109009",
                            "cap": "63823",
                            "nome": "Lapedona"
                        },
                        {
                            "code": "109010",
                            "cap": "63832",
                            "nome": "Magliano di Tenna"
                        },
                        {
                            "code": "109011",
                            "cap": "63834",
                            "nome": "Massa Fermana"
                        },
                        {
                            "code": "109012",
                            "cap": "63842",
                            "nome": "Monsampietro Morico"
                        },
                        {
                            "code": "109013",
                            "cap": "63835",
                            "nome": "Montappone"
                        },
                        {
                            "code": "109016",
                            "cap": "63846",
                            "nome": "Monte Giberto"
                        },
                        {
                            "code": "109021",
                            "cap": "63852",
                            "nome": "Monte Rinaldo"
                        },
                        {
                            "code": "109023",
                            "cap": "63815",
                            "nome": "Monte San Pietrangeli"
                        },
                        {
                            "code": "109024",
                            "cap": "63813",
                            "nome": "Monte Urano"
                        },
                        {
                            "code": "109025",
                            "cap": "63847",
                            "nome": "Monte Vidon Combatte"
                        },
                        {
                            "code": "109026",
                            "cap": "63836",
                            "nome": "Monte Vidon Corrado"
                        },
                        {
                            "code": "109014",
                            "cap": "63855",
                            "nome": "Montefalcone Appennino"
                        },
                        {
                            "code": "109015",
                            "cap": "63858",
                            "nome": "Montefortino"
                        },
                        {
                            "code": "109017",
                            "cap": "63833",
                            "nome": "Montegiorgio"
                        },
                        {
                            "code": "109018",
                            "cap": "63812",
                            "nome": "Montegranaro"
                        },
                        {
                            "code": "109019",
                            "cap": "63841",
                            "nome": "Monteleone di Fermo"
                        },
                        {
                            "code": "109020",
                            "cap": "63853",
                            "nome": "Montelparo"
                        },
                        {
                            "code": "109022",
                            "cap": "63825",
                            "nome": "Monterubbiano"
                        },
                        {
                            "code": "109027",
                            "cap": "63843",
                            "nome": "Montottone"
                        },
                        {
                            "code": "109028",
                            "cap": "63826",
                            "nome": "Moresco"
                        },
                        {
                            "code": "109029",
                            "cap": "63851",
                            "nome": "Ortezzano"
                        },
                        {
                            "code": "109030",
                            "cap": "63827",
                            "nome": "Pedaso"
                        },
                        {
                            "code": "109031",
                            "cap": "63848",
                            "nome": "Petritoli"
                        },
                        {
                            "code": "109032",
                            "cap": "63845",
                            "nome": "Ponzano di Fermo"
                        },
                        {
                            "code": "109033",
                            "cap": "63822",
                            "nome": "Porto San Giorgio"
                        },
                        {
                            "code": "109034",
                            "cap": "63821",
                            "nome": "Porto Sant'Elpidio"
                        },
                        {
                            "code": "109035",
                            "cap": "63831",
                            "nome": "Rapagnano"
                        },
                        {
                            "code": "109036",
                            "cap": "63854",
                            "nome": "Santa Vittoria in Matenano"
                        },
                        {
                            "code": "109037",
                            "cap": "63811",
                            "nome": "Sant'Elpidio a Mare"
                        },
                        {
                            "code": "109038",
                            "cap": "63839",
                            "nome": "Servigliano"
                        },
                        {
                            "code": "109039",
                            "cap": "63856",
                            "nome": "Smerillo"
                        },
                        {
                            "code": "109040",
                            "cap": "63814",
                            "nome": "Torre San Patrizio"
                        }
                    ],
                    "nome": "Fermo"
                },
                {
                    "code": "MC",
                    "comuni": [
                        {
                            "code": "043001",
                            "cap": "62035",
                            "nome": "Acquacanina"
                        },
                        {
                            "code": "043002",
                            "cap": "62021",
                            "nome": "Apiro"
                        },
                        {
                            "code": "043003",
                            "cap": "62010",
                            "nome": "Appignano"
                        },
                        {
                            "code": "043004",
                            "cap": "62020",
                            "nome": "Belforte del Chienti"
                        },
                        {
                            "code": "043005",
                            "cap": "62035",
                            "nome": "Bolognola"
                        },
                        {
                            "code": "043006",
                            "cap": "62020",
                            "nome": "Caldarola"
                        },
                        {
                            "code": "043007",
                            "cap": "62032",
                            "nome": "Camerino"
                        },
                        {
                            "code": "043008",
                            "cap": "62020",
                            "nome": "Camporotondo di Fiastrone"
                        },
                        {
                            "code": "043009",
                            "cap": "62022",
                            "nome": "Castelraimondo"
                        },
                        {
                            "code": "043010",
                            "cap": "62039",
                            "nome": "Castelsantangelo sul Nera"
                        },
                        {
                            "code": "043011",
                            "cap": "62020",
                            "nome": "Cessapalombo"
                        },
                        {
                            "code": "043012",
                            "cap": "62011",
                            "nome": "Cingoli"
                        },
                        {
                            "code": "043013",
                            "cap": "62012",
                            "nome": "Civitanova Marche"
                        },
                        {
                            "code": "043014",
                            "cap": "62020",
                            "nome": "Colmurano"
                        },
                        {
                            "code": "043015",
                            "cap": "62014",
                            "nome": "Corridonia"
                        },
                        {
                            "code": "043016",
                            "cap": "62024",
                            "nome": "Esanatoglia"
                        },
                        {
                            "code": "043017",
                            "cap": "62035",
                            "nome": "Fiastra"
                        },
                        {
                            "code": "043018",
                            "cap": "62035",
                            "nome": "Fiordimonte"
                        },
                        {
                            "code": "043019",
                            "cap": "62025",
                            "nome": "Fiuminata"
                        },
                        {
                            "code": "043020",
                            "cap": "62022",
                            "nome": "Gagliole"
                        },
                        {
                            "code": "043021",
                            "cap": "62020",
                            "nome": "Gualdo"
                        },
                        {
                            "code": "043022",
                            "cap": "62020",
                            "nome": "Loro Piceno"
                        },
                        {
                            "code": "043023",
                            "cap": "62100",
                            "nome": "Macerata"
                        },
                        {
                            "code": "043024",
                            "cap": "62024",
                            "nome": "Matelica"
                        },
                        {
                            "code": "043025",
                            "cap": "62010",
                            "nome": "Mogliano"
                        },
                        {
                            "code": "043027",
                            "cap": "62036",
                            "nome": "Monte Cavallo"
                        },
                        {
                            "code": "043031",
                            "cap": "62015",
                            "nome": "Monte San Giusto"
                        },
                        {
                            "code": "043032",
                            "cap": "62020",
                            "nome": "Monte San Martino"
                        },
                        {
                            "code": "043026",
                            "cap": "62010",
                            "nome": "Montecassiano"
                        },
                        {
                            "code": "043028",
                            "cap": "62010",
                            "nome": "Montecosaro"
                        },
                        {
                            "code": "043029",
                            "cap": "62010",
                            "nome": "Montefano"
                        },
                        {
                            "code": "043030",
                            "cap": "62010",
                            "nome": "Montelupone"
                        },
                        {
                            "code": "043033",
                            "cap": "62010",
                            "nome": "Morrovalle"
                        },
                        {
                            "code": "043034",
                            "cap": "62034",
                            "nome": "Muccia"
                        },
                        {
                            "code": "043035",
                            "cap": "62020",
                            "nome": "Penna San Giovanni"
                        },
                        {
                            "code": "043036",
                            "cap": "62014",
                            "nome": "Petriolo"
                        },
                        {
                            "code": "043038",
                            "cap": "62036",
                            "nome": "Pieve Torina"
                        },
                        {
                            "code": "043037",
                            "cap": "62035",
                            "nome": "Pievebovigliana"
                        },
                        {
                            "code": "043039",
                            "cap": "62025",
                            "nome": "Pioraco"
                        },
                        {
                            "code": "043040",
                            "cap": "62021",
                            "nome": "Poggio San Vicino"
                        },
                        {
                            "code": "043041",
                            "cap": "62010",
                            "nome": "Pollenza"
                        },
                        {
                            "code": "043042",
                            "cap": "62017",
                            "nome": "Porto Recanati"
                        },
                        {
                            "code": "043043",
                            "cap": "62018",
                            "nome": "Potenza Picena"
                        },
                        {
                            "code": "043044",
                            "cap": "62019",
                            "nome": "Recanati"
                        },
                        {
                            "code": "043045",
                            "cap": "62020",
                            "nome": "Ripe San Ginesio"
                        },
                        {
                            "code": "043046",
                            "cap": "62026",
                            "nome": "San Ginesio"
                        },
                        {
                            "code": "043047",
                            "cap": "62027",
                            "nome": "San Severino Marche"
                        },
                        {
                            "code": "043048",
                            "cap": "62020",
                            "nome": "Sant'Angelo in Pontano"
                        },
                        {
                            "code": "043049",
                            "cap": "62028",
                            "nome": "Sarnano"
                        },
                        {
                            "code": "043050",
                            "cap": "62025",
                            "nome": "Sefro"
                        },
                        {
                            "code": "043051",
                            "cap": "62020",
                            "nome": "Serrapetrona"
                        },
                        {
                            "code": "043052",
                            "cap": "62038",
                            "nome": "Serravalle di Chienti"
                        },
                        {
                            "code": "043053",
                            "cap": "62029",
                            "nome": "Tolentino"
                        },
                        {
                            "code": "043054",
                            "cap": "62010",
                            "nome": "Treia"
                        },
                        {
                            "code": "043055",
                            "cap": "62010",
                            "nome": "Urbisaglia"
                        },
                        {
                            "code": "043056",
                            "cap": "62039",
                            "nome": "Ussita"
                        },
                        {
                            "code": "043057",
                            "cap": "62039",
                            "nome": "Visso"
                        }
                    ],
                    "nome": "Macerata"
                },
                {
                    "code": "PU",
                    "comuni": [
                        {
                            "code": "041001",
                            "cap": "61041",
                            "nome": "Acqualagna"
                        },
                        {
                            "code": "041002",
                            "cap": "61042",
                            "nome": "Apecchio"
                        },
                        {
                            "code": "041003",
                            "cap": "61020",
                            "nome": "Auditore"
                        },
                        {
                            "code": "041004",
                            "cap": "61040",
                            "nome": "Barchi"
                        },
                        {
                            "code": "041005",
                            "cap": "61026",
                            "nome": "Belforte all'Isauro"
                        },
                        {
                            "code": "041006",
                            "cap": "61040",
                            "nome": "Borgo Pace"
                        },
                        {
                            "code": "041007",
                            "cap": "61043",
                            "nome": "Cagli"
                        },
                        {
                            "code": "041008",
                            "cap": "61044",
                            "nome": "Cantiano"
                        },
                        {
                            "code": "041009",
                            "cap": "61021",
                            "nome": "Carpegna"
                        },
                        {
                            "code": "041010",
                            "cap": "61030",
                            "nome": "Cartoceto"
                        },
                        {
                            "code": "041012",
                            "cap": "61022",
                            "nome": "Colbordolo"
                        },
                        {
                            "code": "041013",
                            "cap": "61032",
                            "nome": "Fano"
                        },
                        {
                            "code": "041014",
                            "cap": "61033",
                            "nome": "Fermignano"
                        },
                        {
                            "code": "041015",
                            "cap": "61034",
                            "nome": "Fossombrone"
                        },
                        {
                            "code": "041016",
                            "cap": "61040",
                            "nome": "Fratte Rosa"
                        },
                        {
                            "code": "041017",
                            "cap": "61021",
                            "nome": "Frontino"
                        },
                        {
                            "code": "041018",
                            "cap": "61040",
                            "nome": "Frontone"
                        },
                        {
                            "code": "041019",
                            "cap": "61011",
                            "nome": "Gabicce Mare"
                        },
                        {
                            "code": "041020",
                            "cap": "61012",
                            "nome": "Gradara"
                        },
                        {
                            "code": "041021",
                            "cap": "61030",
                            "nome": "Isola del Piano"
                        },
                        {
                            "code": "041022",
                            "cap": "61026",
                            "nome": "Lunano"
                        },
                        {
                            "code": "041023",
                            "cap": "61023",
                            "nome": "Macerata Feltria"
                        },
                        {
                            "code": "041025",
                            "cap": "61040",
                            "nome": "Mercatello sul Metauro"
                        },
                        {
                            "code": "041026",
                            "cap": "61013",
                            "nome": "Mercatino Conca"
                        },
                        {
                            "code": "041027",
                            "cap": "61024",
                            "nome": "Mombaroccio"
                        },
                        {
                            "code": "041028",
                            "cap": "61040",
                            "nome": "Mondavio"
                        },
                        {
                            "code": "041029",
                            "cap": "61037",
                            "nome": "Mondolfo"
                        },
                        {
                            "code": "041031",
                            "cap": "61010",
                            "nome": "Monte Cerignone"
                        },
                        {
                            "code": "041035",
                            "cap": "61010",
                            "nome": "Monte Grimano Terme"
                        },
                        {
                            "code": "041038",
                            "cap": "61040",
                            "nome": "Monte Porzio"
                        },
                        {
                            "code": "041030",
                            "cap": "61020",
                            "nome": "Montecalvo in Foglia"
                        },
                        {
                            "code": "041032",
                            "cap": "61024",
                            "nome": "Monteciccardo"
                        },
                        {
                            "code": "041033",
                            "cap": "61014",
                            "nome": "Montecopiolo"
                        },
                        {
                            "code": "041034",
                            "cap": "61030",
                            "nome": "Montefelcino"
                        },
                        {
                            "code": "041036",
                            "cap": "61025",
                            "nome": "Montelabbate"
                        },
                        {
                            "code": "041037",
                            "cap": "61030",
                            "nome": "Montemaggiore al Metauro"
                        },
                        {
                            "code": "041040",
                            "cap": "61038",
                            "nome": "Orciano di Pesaro"
                        },
                        {
                            "code": "041041",
                            "cap": "61049",
                            "nome": "Peglio"
                        },
                        {
                            "code": "041043",
                            "cap": "61045",
                            "nome": "Pergola"
                        },
                        {
                            "code": "041044",
                            "cap": "6112x",
                            "nome": "Pesaro"
                        },
                        {
                            "code": "041045",
                            "cap": "61020",
                            "nome": "Petriano"
                        },
                        {
                            "code": "041046",
                            "cap": "61030",
                            "nome": "Piagge"
                        },
                        {
                            "code": "041047",
                            "cap": "61026",
                            "nome": "Piandimeleto"
                        },
                        {
                            "code": "041048",
                            "cap": "61023",
                            "nome": "Pietrarubbia"
                        },
                        {
                            "code": "041049",
                            "cap": "61046",
                            "nome": "Piobbico"
                        },
                        {
                            "code": "041050",
                            "cap": "61030",
                            "nome": "Saltara"
                        },
                        {
                            "code": "041051",
                            "cap": "61039",
                            "nome": "San Costanzo"
                        },
                        {
                            "code": "041052",
                            "cap": "61030",
                            "nome": "San Giorgio di Pesaro"
                        },
                        {
                            "code": "041054",
                            "cap": "61047",
                            "nome": "San Lorenzo in Campo"
                        },
                        {
                            "code": "041056",
                            "cap": "61020",
                            "nome": "Sant'Angelo in Lizzola"
                        },
                        {
                            "code": "041057",
                            "cap": "61048",
                            "nome": "Sant'Angelo in Vado"
                        },
                        {
                            "code": "041058",
                            "cap": "61040",
                            "nome": "Sant'Ippolito"
                        },
                        {
                            "code": "041059",
                            "cap": "61028",
                            "nome": "Sassocorvaro"
                        },
                        {
                            "code": "041060",
                            "cap": "61013",
                            "nome": "Sassofeltrio"
                        },
                        {
                            "code": "041061",
                            "cap": "61040",
                            "nome": "Serra Sant'Abbondio"
                        },
                        {
                            "code": "041062",
                            "cap": "61030",
                            "nome": "Serrungarina"
                        },
                        {
                            "code": "041064",
                            "cap": "61020",
                            "nome": "Tavoleto"
                        },
                        {
                            "code": "041065",
                            "cap": "61010",
                            "nome": "Tavullia"
                        },
                        {
                            "code": "041066",
                            "cap": "61049",
                            "nome": "Urbania"
                        },
                        {
                            "code": "041067",
                            "cap": "61029",
                            "nome": "Urbino"
                        }
                    ],
                    "nome": "Pesaro e Urbino"
                }
            ],
            "nome": "Marche"
        },
        {
            "province": [
                {
                    "code": "CB",
                    "comuni": [
                        {
                            "code": "070001",
                            "cap": "86030",
                            "nome": "Acquaviva Collecroce"
                        },
                        {
                            "code": "070002",
                            "cap": "86011",
                            "nome": "Baranello"
                        },
                        {
                            "code": "070003",
                            "cap": "86021",
                            "nome": "Bojano"
                        },
                        {
                            "code": "070004",
                            "cap": "86041",
                            "nome": "Bonefro"
                        },
                        {
                            "code": "070005",
                            "cap": "86010",
                            "nome": "Busso"
                        },
                        {
                            "code": "070006",
                            "cap": "86100",
                            "nome": "Campobasso"
                        },
                        {
                            "code": "070007",
                            "cap": "86020",
                            "nome": "Campochiaro"
                        },
                        {
                            "code": "070008",
                            "cap": "86010",
                            "nome": "Campodipietra"
                        },
                        {
                            "code": "070009",
                            "cap": "86040",
                            "nome": "Campolieto"
                        },
                        {
                            "code": "070010",
                            "cap": "86042",
                            "nome": "Campomarino"
                        },
                        {
                            "code": "070011",
                            "cap": "86043",
                            "nome": "Casacalenda"
                        },
                        {
                            "code": "070012",
                            "cap": "86010",
                            "nome": "Casalciprano"
                        },
                        {
                            "code": "070013",
                            "cap": "86030",
                            "nome": "Castelbottaccio"
                        },
                        {
                            "code": "070014",
                            "cap": "86020",
                            "nome": "Castellino del Biferno"
                        },
                        {
                            "code": "070015",
                            "cap": "86031",
                            "nome": "Castelmauro"
                        },
                        {
                            "code": "070016",
                            "cap": "86010",
                            "nome": "Castropignano"
                        },
                        {
                            "code": "070017",
                            "cap": "86012",
                            "nome": "Cercemaggiore"
                        },
                        {
                            "code": "070018",
                            "cap": "86010",
                            "nome": "Cercepiccola"
                        },
                        {
                            "code": "070019",
                            "cap": "86030",
                            "nome": "Civitacampomarano"
                        },
                        {
                            "code": "070020",
                            "cap": "86020",
                            "nome": "Colle d'Anchise"
                        },
                        {
                            "code": "070021",
                            "cap": "86044",
                            "nome": "Colletorto"
                        },
                        {
                            "code": "070022",
                            "cap": "86020",
                            "nome": "Duronia"
                        },
                        {
                            "code": "070023",
                            "cap": "86010",
                            "nome": "Ferrazzano"
                        },
                        {
                            "code": "070024",
                            "cap": "86020",
                            "nome": "Fossalto"
                        },
                        {
                            "code": "070025",
                            "cap": "86013",
                            "nome": "Gambatesa"
                        },
                        {
                            "code": "070026",
                            "cap": "86010",
                            "nome": "Gildone"
                        },
                        {
                            "code": "070027",
                            "cap": "86030",
                            "nome": "Guardialfiera"
                        },
                        {
                            "code": "070028",
                            "cap": "86014",
                            "nome": "Guardiaregia"
                        },
                        {
                            "code": "070029",
                            "cap": "86034",
                            "nome": "Guglionesi"
                        },
                        {
                            "code": "070030",
                            "cap": "86015",
                            "nome": "Jelsi"
                        },
                        {
                            "code": "070031",
                            "cap": "86035",
                            "nome": "Larino"
                        },
                        {
                            "code": "070032",
                            "cap": "86022",
                            "nome": "Limosano"
                        },
                        {
                            "code": "070033",
                            "cap": "86030",
                            "nome": "Lucito"
                        },
                        {
                            "code": "070034",
                            "cap": "86030",
                            "nome": "Lupara"
                        },
                        {
                            "code": "070035",
                            "cap": "86040",
                            "nome": "Macchia Valfortore"
                        },
                        {
                            "code": "070036",
                            "cap": "86030",
                            "nome": "Mafalda"
                        },
                        {
                            "code": "070037",
                            "cap": "86030",
                            "nome": "Matrice"
                        },
                        {
                            "code": "070038",
                            "cap": "86010",
                            "nome": "Mirabello Sannitico"
                        },
                        {
                            "code": "070039",
                            "cap": "86020",
                            "nome": "Molise"
                        },
                        {
                            "code": "070040",
                            "cap": "86040",
                            "nome": "Monacilioni"
                        },
                        {
                            "code": "070041",
                            "cap": "86023",
                            "nome": "Montagano"
                        },
                        {
                            "code": "070042",
                            "cap": "86032",
                            "nome": "Montecilfone"
                        },
                        {
                            "code": "070043",
                            "cap": "86033",
                            "nome": "Montefalcone nel Sannio"
                        },
                        {
                            "code": "070044",
                            "cap": "86040",
                            "nome": "Montelongo"
                        },
                        {
                            "code": "070045",
                            "cap": "86030",
                            "nome": "Montemitro"
                        },
                        {
                            "code": "070046",
                            "cap": "86036",
                            "nome": "Montenero di Bisaccia"
                        },
                        {
                            "code": "070047",
                            "cap": "86040",
                            "nome": "Montorio nei Frentani"
                        },
                        {
                            "code": "070048",
                            "cap": "86040",
                            "nome": "Morrone del Sannio"
                        },
                        {
                            "code": "070049",
                            "cap": "86010",
                            "nome": "Oratino"
                        },
                        {
                            "code": "070050",
                            "cap": "86037",
                            "nome": "Palata"
                        },
                        {
                            "code": "070051",
                            "cap": "86038",
                            "nome": "Petacciato"
                        },
                        {
                            "code": "070052",
                            "cap": "86024",
                            "nome": "Petrella Tifernina"
                        },
                        {
                            "code": "070053",
                            "cap": "86040",
                            "nome": "Pietracatella"
                        },
                        {
                            "code": "070054",
                            "cap": "86020",
                            "nome": "Pietracupa"
                        },
                        {
                            "code": "070055",
                            "cap": "86045",
                            "nome": "Portocannone"
                        },
                        {
                            "code": "070056",
                            "cap": "86040",
                            "nome": "Provvidenti"
                        },
                        {
                            "code": "070057",
                            "cap": "86016",
                            "nome": "Riccia"
                        },
                        {
                            "code": "070058",
                            "cap": "86040",
                            "nome": "Ripabottoni"
                        },
                        {
                            "code": "070059",
                            "cap": "86025",
                            "nome": "Ripalimosani"
                        },
                        {
                            "code": "070060",
                            "cap": "86020",
                            "nome": "Roccavivara"
                        },
                        {
                            "code": "070061",
                            "cap": "86040",
                            "nome": "Rotello"
                        },
                        {
                            "code": "070062",
                            "cap": "86026",
                            "nome": "Salcito"
                        },
                        {
                            "code": "070063",
                            "cap": "86020",
                            "nome": "San Biase"
                        },
                        {
                            "code": "070064",
                            "cap": "86030",
                            "nome": "San Felice del Molise"
                        },
                        {
                            "code": "070065",
                            "cap": "86030",
                            "nome": "San Giacomo degli Schiavoni"
                        },
                        {
                            "code": "070066",
                            "cap": "86010",
                            "nome": "San Giovanni in Galdo"
                        },
                        {
                            "code": "070067",
                            "cap": "86010",
                            "nome": "San Giuliano del Sannio"
                        },
                        {
                            "code": "070068",
                            "cap": "86040",
                            "nome": "San Giuliano di Puglia"
                        },
                        {
                            "code": "070069",
                            "cap": "86046",
                            "nome": "San Martino in Pensilis"
                        },
                        {
                            "code": "070070",
                            "cap": "86027",
                            "nome": "San Massimo"
                        },
                        {
                            "code": "070071",
                            "cap": "86020",
                            "nome": "San Polo Matese"
                        },
                        {
                            "code": "070072",
                            "cap": "86047",
                            "nome": "Santa Croce di Magliano"
                        },
                        {
                            "code": "070073",
                            "cap": "86020",
                            "nome": "Sant'Angelo Limosano"
                        },
                        {
                            "code": "070074",
                            "cap": "86048",
                            "nome": "Sant'Elia a Pianisi"
                        },
                        {
                            "code": "070075",
                            "cap": "86017",
                            "nome": "Sepino"
                        },
                        {
                            "code": "070076",
                            "cap": "86020",
                            "nome": "Spinete"
                        },
                        {
                            "code": "070077",
                            "cap": "86030",
                            "nome": "Tavenna"
                        },
                        {
                            "code": "070078",
                            "cap": "86039",
                            "nome": "Termoli"
                        },
                        {
                            "code": "070079",
                            "cap": "86028",
                            "nome": "Torella del Sannio"
                        },
                        {
                            "code": "070080",
                            "cap": "86018",
                            "nome": "Toro"
                        },
                        {
                            "code": "070081",
                            "cap": "86029",
                            "nome": "Trivento"
                        },
                        {
                            "code": "070082",
                            "cap": "86010",
                            "nome": "Tufara"
                        },
                        {
                            "code": "070083",
                            "cap": "86049",
                            "nome": "Ururi"
                        },
                        {
                            "code": "070084",
                            "cap": "86019",
                            "nome": "Vinchiaturo"
                        }
                    ],
                    "nome": "Campobasso"
                },
                {
                    "code": "IS",
                    "comuni": [
                        {
                            "code": "094001",
                            "cap": "86080",
                            "nome": "Acquaviva d'Isernia"
                        },
                        {
                            "code": "094002",
                            "cap": "86081",
                            "nome": "Agnone"
                        },
                        {
                            "code": "094003",
                            "cap": "86091",
                            "nome": "Bagnoli del Trigno"
                        },
                        {
                            "code": "094004",
                            "cap": "86080",
                            "nome": "Belmonte del Sannio"
                        },
                        {
                            "code": "094005",
                            "cap": "86092",
                            "nome": "Cantalupo nel Sannio"
                        },
                        {
                            "code": "094006",
                            "cap": "86082",
                            "nome": "Capracotta"
                        },
                        {
                            "code": "094007",
                            "cap": "86083",
                            "nome": "Carovilli"
                        },
                        {
                            "code": "094008",
                            "cap": "86093",
                            "nome": "Carpinone"
                        },
                        {
                            "code": "094009",
                            "cap": "86080",
                            "nome": "Castel del Giudice"
                        },
                        {
                            "code": "094012",
                            "cap": "86071",
                            "nome": "Castel San Vincenzo"
                        },
                        {
                            "code": "094010",
                            "cap": "86090",
                            "nome": "Castelpetroso"
                        },
                        {
                            "code": "094011",
                            "cap": "86090",
                            "nome": "Castelpizzuto"
                        },
                        {
                            "code": "094013",
                            "cap": "86080",
                            "nome": "Castelverrino"
                        },
                        {
                            "code": "094014",
                            "cap": "86072",
                            "nome": "Cerro al Volturno"
                        },
                        {
                            "code": "094015",
                            "cap": "86097",
                            "nome": "Chiauci"
                        },
                        {
                            "code": "094016",
                            "cap": "86094",
                            "nome": "Civitanova del Sannio"
                        },
                        {
                            "code": "094017",
                            "cap": "86073",
                            "nome": "Colli a Volturno"
                        },
                        {
                            "code": "094018",
                            "cap": "86070",
                            "nome": "Conca Casale"
                        },
                        {
                            "code": "094019",
                            "cap": "86074",
                            "nome": "Filignano"
                        },
                        {
                            "code": "094020",
                            "cap": "86084",
                            "nome": "Forl\u00ec del Sannio"
                        },
                        {
                            "code": "094021",
                            "cap": "86070",
                            "nome": "Fornelli"
                        },
                        {
                            "code": "094022",
                            "cap": "86095",
                            "nome": "Frosolone"
                        },
                        {
                            "code": "094023",
                            "cap": "86170",
                            "nome": "Isernia"
                        },
                        {
                            "code": "094024",
                            "cap": "86090",
                            "nome": "Longano"
                        },
                        {
                            "code": "094025",
                            "cap": "86070",
                            "nome": "Macchia d'Isernia"
                        },
                        {
                            "code": "094026",
                            "cap": "86096",
                            "nome": "Macchiagodena"
                        },
                        {
                            "code": "094027",
                            "cap": "86080",
                            "nome": "Miranda"
                        },
                        {
                            "code": "094028",
                            "cap": "86070",
                            "nome": "Montaquila"
                        },
                        {
                            "code": "094029",
                            "cap": "86080",
                            "nome": "Montenero Val Cocchiara"
                        },
                        {
                            "code": "094030",
                            "cap": "86075",
                            "nome": "Monteroduni"
                        },
                        {
                            "code": "094031",
                            "cap": "86090",
                            "nome": "Pesche"
                        },
                        {
                            "code": "094032",
                            "cap": "86097",
                            "nome": "Pescolanciano"
                        },
                        {
                            "code": "094033",
                            "cap": "86080",
                            "nome": "Pescopennataro"
                        },
                        {
                            "code": "094034",
                            "cap": "86090",
                            "nome": "Pettoranello del Molise"
                        },
                        {
                            "code": "094035",
                            "cap": "86085",
                            "nome": "Pietrabbondante"
                        },
                        {
                            "code": "094036",
                            "cap": "86071",
                            "nome": "Pizzone"
                        },
                        {
                            "code": "094037",
                            "cap": "86086",
                            "nome": "Poggio Sannita"
                        },
                        {
                            "code": "094038",
                            "cap": "86077",
                            "nome": "Pozzilli"
                        },
                        {
                            "code": "094039",
                            "cap": "86087",
                            "nome": "Rionero Sannitico"
                        },
                        {
                            "code": "094040",
                            "cap": "86092",
                            "nome": "Roccamandolfi"
                        },
                        {
                            "code": "094041",
                            "cap": "86080",
                            "nome": "Roccasicura"
                        },
                        {
                            "code": "094042",
                            "cap": "86070",
                            "nome": "Rocchetta a Volturno"
                        },
                        {
                            "code": "094043",
                            "cap": "86088",
                            "nome": "San Pietro Avellana"
                        },
                        {
                            "code": "094045",
                            "cap": "86096",
                            "nome": "Santa Maria del Molise"
                        },
                        {
                            "code": "094044",
                            "cap": "86070",
                            "nome": "Sant'Agapito"
                        },
                        {
                            "code": "094046",
                            "cap": "86080",
                            "nome": "Sant'Angelo del Pesco"
                        },
                        {
                            "code": "094047",
                            "cap": "86095",
                            "nome": "Sant'Elena Sannita"
                        },
                        {
                            "code": "094048",
                            "cap": "86070",
                            "nome": "Scapoli"
                        },
                        {
                            "code": "094049",
                            "cap": "86097",
                            "nome": "Sessano del Molise"
                        },
                        {
                            "code": "094050",
                            "cap": "86078",
                            "nome": "Sesto Campano"
                        },
                        {
                            "code": "094051",
                            "cap": "86089",
                            "nome": "Vastogirardi"
                        },
                        {
                            "code": "094052",
                            "cap": "86079",
                            "nome": "Venafro"
                        }
                    ],
                    "nome": "Isernia"
                }
            ],
            "nome": "Molise"
        },
        {
            "province": [
                {
                    "code": "AL",
                    "comuni": [
                        {
                            "code": "006001",
                            "cap": "15011",
                            "nome": "Acqui Terme"
                        },
                        {
                            "code": "006002",
                            "cap": "15060",
                            "nome": "Albera Ligure"
                        },
                        {
                            "code": "006003",
                            "cap": "1512x",
                            "nome": "Alessandria"
                        },
                        {
                            "code": "006004",
                            "cap": "15021",
                            "nome": "Alfiano Natta"
                        },
                        {
                            "code": "006005",
                            "cap": "15010",
                            "nome": "Alice Bel Colle"
                        },
                        {
                            "code": "006006",
                            "cap": "15040",
                            "nome": "Alluvioni Cambi\u00f2"
                        },
                        {
                            "code": "006007",
                            "cap": "15041",
                            "nome": "Altavilla Monferrato"
                        },
                        {
                            "code": "006008",
                            "cap": "15050",
                            "nome": "Alzano Scrivia"
                        },
                        {
                            "code": "006009",
                            "cap": "15061",
                            "nome": "Arquata Scrivia"
                        },
                        {
                            "code": "006010",
                            "cap": "15050",
                            "nome": "Avolasca"
                        },
                        {
                            "code": "006011",
                            "cap": "15031",
                            "nome": "Balzola"
                        },
                        {
                            "code": "006012",
                            "cap": "15060",
                            "nome": "Basaluzzo"
                        },
                        {
                            "code": "006013",
                            "cap": "15042",
                            "nome": "Bassignana"
                        },
                        {
                            "code": "006014",
                            "cap": "15070",
                            "nome": "Belforte Monferrato"
                        },
                        {
                            "code": "006015",
                            "cap": "15022",
                            "nome": "Bergamasco"
                        },
                        {
                            "code": "006016",
                            "cap": "15050",
                            "nome": "Berzano di Tortona"
                        },
                        {
                            "code": "006017",
                            "cap": "15012",
                            "nome": "Bistagno"
                        },
                        {
                            "code": "006018",
                            "cap": "15060",
                            "nome": "Borghetto di Borbera"
                        },
                        {
                            "code": "006020",
                            "cap": "15032",
                            "nome": "Borgo San Martino"
                        },
                        {
                            "code": "006019",
                            "cap": "15013",
                            "nome": "Borgoratto Alessandrino"
                        },
                        {
                            "code": "006021",
                            "cap": "15062",
                            "nome": "Bosco Marengo"
                        },
                        {
                            "code": "006022",
                            "cap": "15060",
                            "nome": "Bosio"
                        },
                        {
                            "code": "006023",
                            "cap": "15040",
                            "nome": "Bozzole"
                        },
                        {
                            "code": "006024",
                            "cap": "15050",
                            "nome": "Brignano-Frascata"
                        },
                        {
                            "code": "006025",
                            "cap": "15060",
                            "nome": "Cabella Ligure"
                        },
                        {
                            "code": "006026",
                            "cap": "15030",
                            "nome": "Camagna Monferrato"
                        },
                        {
                            "code": "006027",
                            "cap": "15020",
                            "nome": "Camino"
                        },
                        {
                            "code": "006028",
                            "cap": "15060",
                            "nome": "Cantalupo Ligure"
                        },
                        {
                            "code": "006029",
                            "cap": "15060",
                            "nome": "Capriata d'Orba"
                        },
                        {
                            "code": "006030",
                            "cap": "15050",
                            "nome": "Carbonara Scrivia"
                        },
                        {
                            "code": "006031",
                            "cap": "15026",
                            "nome": "Carentino"
                        },
                        {
                            "code": "006032",
                            "cap": "15051",
                            "nome": "Carezzano"
                        },
                        {
                            "code": "006033",
                            "cap": "15071",
                            "nome": "Carpeneto"
                        },
                        {
                            "code": "006034",
                            "cap": "15060",
                            "nome": "Carrega Ligure"
                        },
                        {
                            "code": "006035",
                            "cap": "15060",
                            "nome": "Carrosio"
                        },
                        {
                            "code": "006036",
                            "cap": "15015",
                            "nome": "Cartosio"
                        },
                        {
                            "code": "006037",
                            "cap": "15072",
                            "nome": "Casal Cermelli"
                        },
                        {
                            "code": "006039",
                            "cap": "15033",
                            "nome": "Casale Monferrato"
                        },
                        {
                            "code": "006038",
                            "cap": "15070",
                            "nome": "Casaleggio Boiro"
                        },
                        {
                            "code": "006040",
                            "cap": "15052",
                            "nome": "Casalnoceto"
                        },
                        {
                            "code": "006041",
                            "cap": "15050",
                            "nome": "Casasco"
                        },
                        {
                            "code": "006042",
                            "cap": "15063",
                            "nome": "Cassano Spinola"
                        },
                        {
                            "code": "006043",
                            "cap": "15016",
                            "nome": "Cassine"
                        },
                        {
                            "code": "006044",
                            "cap": "15070",
                            "nome": "Cassinelle"
                        },
                        {
                            "code": "006045",
                            "cap": "15051",
                            "nome": "Castellania"
                        },
                        {
                            "code": "006046",
                            "cap": "15050",
                            "nome": "Castellar Guidobono"
                        },
                        {
                            "code": "006047",
                            "cap": "15073",
                            "nome": "Castellazzo Bormida"
                        },
                        {
                            "code": "006048",
                            "cap": "15010",
                            "nome": "Castelletto d'Erro"
                        },
                        {
                            "code": "006049",
                            "cap": "15060",
                            "nome": "Castelletto d'Orba"
                        },
                        {
                            "code": "006050",
                            "cap": "15020",
                            "nome": "Castelletto Merli"
                        },
                        {
                            "code": "006051",
                            "cap": "15040",
                            "nome": "Castelletto Monferrato"
                        },
                        {
                            "code": "006052",
                            "cap": "15017",
                            "nome": "Castelnuovo Bormida"
                        },
                        {
                            "code": "006053",
                            "cap": "15053",
                            "nome": "Castelnuovo Scrivia"
                        },
                        {
                            "code": "006054",
                            "cap": "15070",
                            "nome": "Castelspina"
                        },
                        {
                            "code": "006055",
                            "cap": "15010",
                            "nome": "Cavatore"
                        },
                        {
                            "code": "006056",
                            "cap": "15034",
                            "nome": "Cella Monte"
                        },
                        {
                            "code": "006057",
                            "cap": "15020",
                            "nome": "Cereseto"
                        },
                        {
                            "code": "006058",
                            "cap": "15050",
                            "nome": "Cerreto Grue"
                        },
                        {
                            "code": "006059",
                            "cap": "15020",
                            "nome": "Cerrina Monferrato"
                        },
                        {
                            "code": "006060",
                            "cap": "15030",
                            "nome": "Coniolo"
                        },
                        {
                            "code": "006061",
                            "cap": "15030",
                            "nome": "Conzano"
                        },
                        {
                            "code": "006062",
                            "cap": "15050",
                            "nome": "Costa Vescovato"
                        },
                        {
                            "code": "006063",
                            "cap": "15010",
                            "nome": "Cremolino"
                        },
                        {
                            "code": "006064",
                            "cap": "15040",
                            "nome": "Cuccaro Monferrato"
                        },
                        {
                            "code": "006065",
                            "cap": "15010",
                            "nome": "Denice"
                        },
                        {
                            "code": "006066",
                            "cap": "15056",
                            "nome": "Dernice"
                        },
                        {
                            "code": "006067",
                            "cap": "15054",
                            "nome": "Fabbrica Curone"
                        },
                        {
                            "code": "006068",
                            "cap": "15023",
                            "nome": "Felizzano"
                        },
                        {
                            "code": "006069",
                            "cap": "15060",
                            "nome": "Fraconalto"
                        },
                        {
                            "code": "006070",
                            "cap": "15060",
                            "nome": "Francavilla Bisio"
                        },
                        {
                            "code": "006071",
                            "cap": "15010",
                            "nome": "Frascaro"
                        },
                        {
                            "code": "006072",
                            "cap": "15035",
                            "nome": "Frassinello Monferrato"
                        },
                        {
                            "code": "006073",
                            "cap": "15040",
                            "nome": "Frassineto Po"
                        },
                        {
                            "code": "006074",
                            "cap": "15064",
                            "nome": "Fresonara"
                        },
                        {
                            "code": "006075",
                            "cap": "15065",
                            "nome": "Frugarolo"
                        },
                        {
                            "code": "006076",
                            "cap": "15043",
                            "nome": "Fubine"
                        },
                        {
                            "code": "006077",
                            "cap": "15020",
                            "nome": "Gabiano"
                        },
                        {
                            "code": "006078",
                            "cap": "15010",
                            "nome": "Gamalero"
                        },
                        {
                            "code": "006079",
                            "cap": "15050",
                            "nome": "Garbagna"
                        },
                        {
                            "code": "006080",
                            "cap": "15063",
                            "nome": "Gavazzana"
                        },
                        {
                            "code": "006081",
                            "cap": "15066",
                            "nome": "Gavi"
                        },
                        {
                            "code": "006082",
                            "cap": "15036",
                            "nome": "Giarole"
                        },
                        {
                            "code": "006083",
                            "cap": "15056",
                            "nome": "Gremiasco"
                        },
                        {
                            "code": "006084",
                            "cap": "15010",
                            "nome": "Grognardo"
                        },
                        {
                            "code": "006085",
                            "cap": "15060",
                            "nome": "Grondona"
                        },
                        {
                            "code": "006086",
                            "cap": "15050",
                            "nome": "Guazzora"
                        },
                        {
                            "code": "006087",
                            "cap": "15050",
                            "nome": "Isola Sant'Antonio"
                        },
                        {
                            "code": "006088",
                            "cap": "15070",
                            "nome": "Lerma"
                        },
                        {
                            "code": "006089",
                            "cap": "15040",
                            "nome": "Lu"
                        },
                        {
                            "code": "006090",
                            "cap": "15015",
                            "nome": "Malvicino"
                        },
                        {
                            "code": "006091",
                            "cap": "15024",
                            "nome": "Masio"
                        },
                        {
                            "code": "006092",
                            "cap": "15010",
                            "nome": "Melazzo"
                        },
                        {
                            "code": "006093",
                            "cap": "15010",
                            "nome": "Merana"
                        },
                        {
                            "code": "006094",
                            "cap": "15040",
                            "nome": "Mirabello Monferrato"
                        },
                        {
                            "code": "006095",
                            "cap": "15074",
                            "nome": "Molare"
                        },
                        {
                            "code": "006096",
                            "cap": "15050",
                            "nome": "Molino dei Torti"
                        },
                        {
                            "code": "006097",
                            "cap": "15020",
                            "nome": "Mombello Monferrato"
                        },
                        {
                            "code": "006098",
                            "cap": "15050",
                            "nome": "Momperone"
                        },
                        {
                            "code": "006099",
                            "cap": "15020",
                            "nome": "Moncestino"
                        },
                        {
                            "code": "006100",
                            "cap": "15060",
                            "nome": "Mongiardino Ligure"
                        },
                        {
                            "code": "006101",
                            "cap": "15059",
                            "nome": "Monleale"
                        },
                        {
                            "code": "006102",
                            "cap": "15050",
                            "nome": "Montacuto"
                        },
                        {
                            "code": "006103",
                            "cap": "15060",
                            "nome": "Montaldeo"
                        },
                        {
                            "code": "006104",
                            "cap": "15010",
                            "nome": "Montaldo Bormida"
                        },
                        {
                            "code": "006105",
                            "cap": "15040",
                            "nome": "Montecastello"
                        },
                        {
                            "code": "006106",
                            "cap": "15010",
                            "nome": "Montechiaro d'Acqui"
                        },
                        {
                            "code": "006107",
                            "cap": "15050",
                            "nome": "Montegioco"
                        },
                        {
                            "code": "006108",
                            "cap": "15050",
                            "nome": "Montemarzino"
                        },
                        {
                            "code": "006109",
                            "cap": "15025",
                            "nome": "Morano sul Po"
                        },
                        {
                            "code": "006110",
                            "cap": "15010",
                            "nome": "Morbello"
                        },
                        {
                            "code": "006111",
                            "cap": "15075",
                            "nome": "Mornese"
                        },
                        {
                            "code": "006112",
                            "cap": "15010",
                            "nome": "Morsasco"
                        },
                        {
                            "code": "006113",
                            "cap": "15020",
                            "nome": "Murisengo"
                        },
                        {
                            "code": "006114",
                            "cap": "15067",
                            "nome": "Novi Ligure"
                        },
                        {
                            "code": "006115",
                            "cap": "15040",
                            "nome": "Occimiano"
                        },
                        {
                            "code": "006116",
                            "cap": "15020",
                            "nome": "Odalengo Grande"
                        },
                        {
                            "code": "006117",
                            "cap": "15020",
                            "nome": "Odalengo Piccolo"
                        },
                        {
                            "code": "006118",
                            "cap": "15030",
                            "nome": "Olivola"
                        },
                        {
                            "code": "006119",
                            "cap": "15010",
                            "nome": "Orsara Bormida"
                        },
                        {
                            "code": "006120",
                            "cap": "15038",
                            "nome": "Ottiglio"
                        },
                        {
                            "code": "006121",
                            "cap": "15076",
                            "nome": "Ovada"
                        },
                        {
                            "code": "006122",
                            "cap": "15026",
                            "nome": "Oviglio"
                        },
                        {
                            "code": "006123",
                            "cap": "15039",
                            "nome": "Ozzano Monferrato"
                        },
                        {
                            "code": "006124",
                            "cap": "15050",
                            "nome": "Paderna"
                        },
                        {
                            "code": "006125",
                            "cap": "15010",
                            "nome": "Pareto"
                        },
                        {
                            "code": "006126",
                            "cap": "15060",
                            "nome": "Parodi Ligure"
                        },
                        {
                            "code": "006127",
                            "cap": "15060",
                            "nome": "Pasturana"
                        },
                        {
                            "code": "006128",
                            "cap": "15040",
                            "nome": "Pecetto di Valenza"
                        },
                        {
                            "code": "006129",
                            "cap": "15040",
                            "nome": "Pietra Marazzi"
                        },
                        {
                            "code": "006130",
                            "cap": "15040",
                            "nome": "Piovera"
                        },
                        {
                            "code": "006131",
                            "cap": "15040",
                            "nome": "Pomaro Monferrato"
                        },
                        {
                            "code": "006132",
                            "cap": "15055",
                            "nome": "Pontecurone"
                        },
                        {
                            "code": "006133",
                            "cap": "15027",
                            "nome": "Pontestura"
                        },
                        {
                            "code": "006134",
                            "cap": "15010",
                            "nome": "Ponti"
                        },
                        {
                            "code": "006135",
                            "cap": "15020",
                            "nome": "Ponzano Monferrato"
                        },
                        {
                            "code": "006136",
                            "cap": "15010",
                            "nome": "Ponzone"
                        },
                        {
                            "code": "006137",
                            "cap": "15050",
                            "nome": "Pozzol Groppo"
                        },
                        {
                            "code": "006138",
                            "cap": "15068",
                            "nome": "Pozzolo Formigaro"
                        },
                        {
                            "code": "006139",
                            "cap": "15010",
                            "nome": "Prasco"
                        },
                        {
                            "code": "006140",
                            "cap": "15077",
                            "nome": "Predosa"
                        },
                        {
                            "code": "006141",
                            "cap": "15044",
                            "nome": "Quargnento"
                        },
                        {
                            "code": "006142",
                            "cap": "15028",
                            "nome": "Quattordio"
                        },
                        {
                            "code": "006143",
                            "cap": "15010",
                            "nome": "Ricaldone"
                        },
                        {
                            "code": "006144",
                            "cap": "15010",
                            "nome": "Rivalta Bormida"
                        },
                        {
                            "code": "006145",
                            "cap": "15040",
                            "nome": "Rivarone"
                        },
                        {
                            "code": "006147",
                            "cap": "15078",
                            "nome": "Rocca Grimalda"
                        },
                        {
                            "code": "006146",
                            "cap": "15060",
                            "nome": "Roccaforte Ligure"
                        },
                        {
                            "code": "006148",
                            "cap": "15060",
                            "nome": "Rocchetta Ligure"
                        },
                        {
                            "code": "006149",
                            "cap": "15030",
                            "nome": "Rosignano Monferrato"
                        },
                        {
                            "code": "006150",
                            "cap": "15030",
                            "nome": "Sala Monferrato"
                        },
                        {
                            "code": "006151",
                            "cap": "15045",
                            "nome": "Sale"
                        },
                        {
                            "code": "006152",
                            "cap": "15060",
                            "nome": "San Cristoforo"
                        },
                        {
                            "code": "006153",
                            "cap": "15020",
                            "nome": "San Giorgio Monferrato"
                        },
                        {
                            "code": "006154",
                            "cap": "15046",
                            "nome": "San Salvatore Monferrato"
                        },
                        {
                            "code": "006155",
                            "cap": "15056",
                            "nome": "San Sebastiano Curone"
                        },
                        {
                            "code": "006156",
                            "cap": "15050",
                            "nome": "Sant'Agata Fossili"
                        },
                        {
                            "code": "006157",
                            "cap": "15060",
                            "nome": "Sardigliano"
                        },
                        {
                            "code": "006158",
                            "cap": "15050",
                            "nome": "Sarezzano"
                        },
                        {
                            "code": "006159",
                            "cap": "15020",
                            "nome": "Serralunga di Crea"
                        },
                        {
                            "code": "006160",
                            "cap": "15069",
                            "nome": "Serravalle Scrivia"
                        },
                        {
                            "code": "006161",
                            "cap": "15079",
                            "nome": "Sezzadio"
                        },
                        {
                            "code": "006162",
                            "cap": "15060",
                            "nome": "Silvano d'Orba"
                        },
                        {
                            "code": "006163",
                            "cap": "15029",
                            "nome": "Solero"
                        },
                        {
                            "code": "006164",
                            "cap": "15020",
                            "nome": "Solonghello"
                        },
                        {
                            "code": "006165",
                            "cap": "15018",
                            "nome": "Spigno Monferrato"
                        },
                        {
                            "code": "006166",
                            "cap": "15050",
                            "nome": "Spineto Scrivia"
                        },
                        {
                            "code": "006167",
                            "cap": "15060",
                            "nome": "Stazzano"
                        },
                        {
                            "code": "006168",
                            "cap": "15019",
                            "nome": "Strevi"
                        },
                        {
                            "code": "006169",
                            "cap": "15070",
                            "nome": "Tagliolo Monferrato"
                        },
                        {
                            "code": "006170",
                            "cap": "15060",
                            "nome": "Tassarolo"
                        },
                        {
                            "code": "006171",
                            "cap": "15030",
                            "nome": "Terruggia"
                        },
                        {
                            "code": "006172",
                            "cap": "15010",
                            "nome": "Terzo"
                        },
                        {
                            "code": "006173",
                            "cap": "15040",
                            "nome": "Ticineto"
                        },
                        {
                            "code": "006174",
                            "cap": "15057",
                            "nome": "Tortona"
                        },
                        {
                            "code": "006175",
                            "cap": "15030",
                            "nome": "Treville"
                        },
                        {
                            "code": "006176",
                            "cap": "15070",
                            "nome": "Trisobbio"
                        },
                        {
                            "code": "006177",
                            "cap": "15048",
                            "nome": "Valenza"
                        },
                        {
                            "code": "006178",
                            "cap": "15040",
                            "nome": "Valmacca"
                        },
                        {
                            "code": "006179",
                            "cap": "15049",
                            "nome": "Vignale Monferrato"
                        },
                        {
                            "code": "006180",
                            "cap": "15060",
                            "nome": "Vignole Borbera"
                        },
                        {
                            "code": "006181",
                            "cap": "15058",
                            "nome": "Viguzzolo"
                        },
                        {
                            "code": "006182",
                            "cap": "15020",
                            "nome": "Villadeati"
                        },
                        {
                            "code": "006183",
                            "cap": "15050",
                            "nome": "Villalvernia"
                        },
                        {
                            "code": "006184",
                            "cap": "15020",
                            "nome": "Villamiroglio"
                        },
                        {
                            "code": "006185",
                            "cap": "15030",
                            "nome": "Villanova Monferrato"
                        },
                        {
                            "code": "006186",
                            "cap": "15050",
                            "nome": "Villaromagnano"
                        },
                        {
                            "code": "006187",
                            "cap": "15010",
                            "nome": "Visone"
                        },
                        {
                            "code": "006188",
                            "cap": "15059",
                            "nome": "Volpedo"
                        },
                        {
                            "code": "006189",
                            "cap": "15050",
                            "nome": "Volpeglino"
                        },
                        {
                            "code": "006190",
                            "cap": "15060",
                            "nome": "Voltaggio"
                        }
                    ],
                    "nome": "Alessandria"
                },
                {
                    "code": "AT",
                    "comuni": [
                        {
                            "code": "005001",
                            "cap": "14041",
                            "nome": "Agliano Terme"
                        },
                        {
                            "code": "005002",
                            "cap": "14022",
                            "nome": "Albugnano"
                        },
                        {
                            "code": "005003",
                            "cap": "14010",
                            "nome": "Antignano"
                        },
                        {
                            "code": "005004",
                            "cap": "14020",
                            "nome": "Aramengo"
                        },
                        {
                            "code": "005005",
                            "cap": "14100",
                            "nome": "Asti"
                        },
                        {
                            "code": "005006",
                            "cap": "14030",
                            "nome": "Azzano d'Asti"
                        },
                        {
                            "code": "005007",
                            "cap": "14011",
                            "nome": "Baldichieri d'Asti"
                        },
                        {
                            "code": "005008",
                            "cap": "14040",
                            "nome": "Belveglio"
                        },
                        {
                            "code": "005009",
                            "cap": "14020",
                            "nome": "Berzano di San Pietro"
                        },
                        {
                            "code": "005010",
                            "cap": "14046",
                            "nome": "Bruno"
                        },
                        {
                            "code": "005011",
                            "cap": "14051",
                            "nome": "Bubbio"
                        },
                        {
                            "code": "005012",
                            "cap": "14021",
                            "nome": "Buttigliera d'Asti"
                        },
                        {
                            "code": "005013",
                            "cap": "14042",
                            "nome": "Calamandrana"
                        },
                        {
                            "code": "005014",
                            "cap": "14031",
                            "nome": "Calliano"
                        },
                        {
                            "code": "005015",
                            "cap": "14052",
                            "nome": "Calosso"
                        },
                        {
                            "code": "005016",
                            "cap": "14020",
                            "nome": "Camerano Casasco"
                        },
                        {
                            "code": "005017",
                            "cap": "14053",
                            "nome": "Canelli"
                        },
                        {
                            "code": "005018",
                            "cap": "14010",
                            "nome": "Cantarana"
                        },
                        {
                            "code": "005019",
                            "cap": "14014",
                            "nome": "Capriglio"
                        },
                        {
                            "code": "005020",
                            "cap": "14032",
                            "nome": "Casorzo"
                        },
                        {
                            "code": "005021",
                            "cap": "14050",
                            "nome": "Cassinasco"
                        },
                        {
                            "code": "005022",
                            "cap": "14054",
                            "nome": "Castagnole delle Lanze"
                        },
                        {
                            "code": "005023",
                            "cap": "14030",
                            "nome": "Castagnole Monferrato"
                        },
                        {
                            "code": "005024",
                            "cap": "14040",
                            "nome": "Castel Boglione"
                        },
                        {
                            "code": "005032",
                            "cap": "14044",
                            "nome": "Castel Rocchero"
                        },
                        {
                            "code": "005025",
                            "cap": "14033",
                            "nome": "Castell'Alfero"
                        },
                        {
                            "code": "005026",
                            "cap": "14013",
                            "nome": "Castellero"
                        },
                        {
                            "code": "005027",
                            "cap": "14040",
                            "nome": "Castelletto Molina"
                        },
                        {
                            "code": "005028",
                            "cap": "14034",
                            "nome": "Castello di Annone"
                        },
                        {
                            "code": "005029",
                            "cap": "14043",
                            "nome": "Castelnuovo Belbo"
                        },
                        {
                            "code": "005030",
                            "cap": "14040",
                            "nome": "Castelnuovo Calcea"
                        },
                        {
                            "code": "005031",
                            "cap": "14022",
                            "nome": "Castelnuovo Don Bosco"
                        },
                        {
                            "code": "005033",
                            "cap": "14010",
                            "nome": "Cellarengo"
                        },
                        {
                            "code": "005034",
                            "cap": "14010",
                            "nome": "Celle Enomondo"
                        },
                        {
                            "code": "005035",
                            "cap": "14020",
                            "nome": "Cerreto d'Asti"
                        },
                        {
                            "code": "005036",
                            "cap": "14030",
                            "nome": "Cerro Tanaro"
                        },
                        {
                            "code": "005037",
                            "cap": "14050",
                            "nome": "Cessole"
                        },
                        {
                            "code": "005038",
                            "cap": "14025",
                            "nome": "Chiusano d'Asti"
                        },
                        {
                            "code": "005039",
                            "cap": "14020",
                            "nome": "Cinaglio"
                        },
                        {
                            "code": "005040",
                            "cap": "14010",
                            "nome": "Cisterna d'Asti"
                        },
                        {
                            "code": "005041",
                            "cap": "14054",
                            "nome": "Coazzolo"
                        },
                        {
                            "code": "005042",
                            "cap": "14023",
                            "nome": "Cocconato"
                        },
                        {
                            "code": "005044",
                            "cap": "14020",
                            "nome": "Corsione"
                        },
                        {
                            "code": "005045",
                            "cap": "14013",
                            "nome": "Cortandone"
                        },
                        {
                            "code": "005046",
                            "cap": "14020",
                            "nome": "Cortanze"
                        },
                        {
                            "code": "005047",
                            "cap": "14010",
                            "nome": "Cortazzone"
                        },
                        {
                            "code": "005048",
                            "cap": "14040",
                            "nome": "Cortiglione"
                        },
                        {
                            "code": "005049",
                            "cap": "14020",
                            "nome": "Cossombrato"
                        },
                        {
                            "code": "005050",
                            "cap": "14055",
                            "nome": "Costigliole d'Asti"
                        },
                        {
                            "code": "005051",
                            "cap": "14026",
                            "nome": "Cunico"
                        },
                        {
                            "code": "005052",
                            "cap": "14010",
                            "nome": "Dusino San Michele"
                        },
                        {
                            "code": "005053",
                            "cap": "14012",
                            "nome": "Ferrere"
                        },
                        {
                            "code": "005054",
                            "cap": "14044",
                            "nome": "Fontanile"
                        },
                        {
                            "code": "005055",
                            "cap": "14030",
                            "nome": "Frinco"
                        },
                        {
                            "code": "005056",
                            "cap": "14031",
                            "nome": "Grana"
                        },
                        {
                            "code": "005057",
                            "cap": "14035",
                            "nome": "Grazzano Badoglio"
                        },
                        {
                            "code": "005058",
                            "cap": "14045",
                            "nome": "Incisa Scapaccino"
                        },
                        {
                            "code": "005059",
                            "cap": "14057",
                            "nome": "Isola d'Asti"
                        },
                        {
                            "code": "005060",
                            "cap": "14051",
                            "nome": "Loazzolo"
                        },
                        {
                            "code": "005061",
                            "cap": "14040",
                            "nome": "Maranzana"
                        },
                        {
                            "code": "005062",
                            "cap": "14018",
                            "nome": "Maretto"
                        },
                        {
                            "code": "005063",
                            "cap": "14050",
                            "nome": "Moasca"
                        },
                        {
                            "code": "005064",
                            "cap": "14050",
                            "nome": "Mombaldone"
                        },
                        {
                            "code": "005065",
                            "cap": "14046",
                            "nome": "Mombaruzzo"
                        },
                        {
                            "code": "005066",
                            "cap": "14047",
                            "nome": "Mombercelli"
                        },
                        {
                            "code": "005067",
                            "cap": "14013",
                            "nome": "Monale"
                        },
                        {
                            "code": "005068",
                            "cap": "14058",
                            "nome": "Monastero Bormida"
                        },
                        {
                            "code": "005069",
                            "cap": "14036",
                            "nome": "Moncalvo"
                        },
                        {
                            "code": "005070",
                            "cap": "14024",
                            "nome": "Moncucco Torinese"
                        },
                        {
                            "code": "005071",
                            "cap": "14040",
                            "nome": "Mongardino"
                        },
                        {
                            "code": "005072",
                            "cap": "14040",
                            "nome": "Montabone"
                        },
                        {
                            "code": "005073",
                            "cap": "14014",
                            "nome": "Montafia"
                        },
                        {
                            "code": "005074",
                            "cap": "14048",
                            "nome": "Montaldo Scarampi"
                        },
                        {
                            "code": "005075",
                            "cap": "14025",
                            "nome": "Montechiaro d'Asti"
                        },
                        {
                            "code": "005076",
                            "cap": "14048",
                            "nome": "Montegrosso d'Asti"
                        },
                        {
                            "code": "005077",
                            "cap": "14030",
                            "nome": "Montemagno"
                        },
                        {
                            "code": "005121",
                            "cap": "14026",
                            "nome": "Montiglio Monferrato"
                        },
                        {
                            "code": "005079",
                            "cap": "14023",
                            "nome": "Moransengo"
                        },
                        {
                            "code": "005080",
                            "cap": "14049",
                            "nome": "Nizza Monferrato"
                        },
                        {
                            "code": "005081",
                            "cap": "14050",
                            "nome": "Olmo Gentile"
                        },
                        {
                            "code": "005082",
                            "cap": "14020",
                            "nome": "Passerano Marmorito"
                        },
                        {
                            "code": "005083",
                            "cap": "14030",
                            "nome": "Penango"
                        },
                        {
                            "code": "005084",
                            "cap": "14020",
                            "nome": "Piea"
                        },
                        {
                            "code": "005085",
                            "cap": "14020",
                            "nome": "Pino d'Asti"
                        },
                        {
                            "code": "005086",
                            "cap": "14026",
                            "nome": "Piov\u00e0 Massaia"
                        },
                        {
                            "code": "005087",
                            "cap": "14037",
                            "nome": "Portacomaro"
                        },
                        {
                            "code": "005088",
                            "cap": "14040",
                            "nome": "Quaranti"
                        },
                        {
                            "code": "005089",
                            "cap": "14030",
                            "nome": "Refrancore"
                        },
                        {
                            "code": "005090",
                            "cap": "14010",
                            "nome": "Revigliasco d'Asti"
                        },
                        {
                            "code": "005091",
                            "cap": "14018",
                            "nome": "Roatto"
                        },
                        {
                            "code": "005092",
                            "cap": "14020",
                            "nome": "Robella"
                        },
                        {
                            "code": "005093",
                            "cap": "14030",
                            "nome": "Rocca d'Arazzo"
                        },
                        {
                            "code": "005094",
                            "cap": "14050",
                            "nome": "Roccaverano"
                        },
                        {
                            "code": "005095",
                            "cap": "14042",
                            "nome": "Rocchetta Palafea"
                        },
                        {
                            "code": "005096",
                            "cap": "14030",
                            "nome": "Rocchetta Tanaro"
                        },
                        {
                            "code": "005097",
                            "cap": "14015",
                            "nome": "San Damiano d'Asti"
                        },
                        {
                            "code": "005098",
                            "cap": "14059",
                            "nome": "San Giorgio Scarampi"
                        },
                        {
                            "code": "005099",
                            "cap": "14010",
                            "nome": "San Martino Alfieri"
                        },
                        {
                            "code": "005100",
                            "cap": "14050",
                            "nome": "San Marzano Oliveto"
                        },
                        {
                            "code": "005101",
                            "cap": "14010",
                            "nome": "San Paolo Solbrito"
                        },
                        {
                            "code": "005103",
                            "cap": "14030",
                            "nome": "Scurzolengo"
                        },
                        {
                            "code": "005104",
                            "cap": "14050",
                            "nome": "Serole"
                        },
                        {
                            "code": "005105",
                            "cap": "14058",
                            "nome": "Sessame"
                        },
                        {
                            "code": "005106",
                            "cap": "14020",
                            "nome": "Settime"
                        },
                        {
                            "code": "005107",
                            "cap": "14020",
                            "nome": "Soglio"
                        },
                        {
                            "code": "005108",
                            "cap": "14016",
                            "nome": "Tigliole"
                        },
                        {
                            "code": "005109",
                            "cap": "14039",
                            "nome": "Tonco"
                        },
                        {
                            "code": "005110",
                            "cap": "14023",
                            "nome": "Tonengo"
                        },
                        {
                            "code": "005111",
                            "cap": "14049",
                            "nome": "Vaglio Serra"
                        },
                        {
                            "code": "005112",
                            "cap": "14017",
                            "nome": "Valfenera"
                        },
                        {
                            "code": "005113",
                            "cap": "14059",
                            "nome": "Vesime"
                        },
                        {
                            "code": "005114",
                            "cap": "14010",
                            "nome": "Viale"
                        },
                        {
                            "code": "005115",
                            "cap": "14030",
                            "nome": "Viarigi"
                        },
                        {
                            "code": "005116",
                            "cap": "14040",
                            "nome": "Vigliano d'Asti"
                        },
                        {
                            "code": "005119",
                            "cap": "14020",
                            "nome": "Villa San Secondo"
                        },
                        {
                            "code": "005117",
                            "cap": "14018",
                            "nome": "Villafranca d'Asti"
                        },
                        {
                            "code": "005118",
                            "cap": "14019",
                            "nome": "Villanova d'Asti"
                        },
                        {
                            "code": "005120",
                            "cap": "14040",
                            "nome": "Vinchio"
                        }
                    ],
                    "nome": "Asti"
                },
                {
                    "code": "BI",
                    "comuni": [
                        {
                            "code": "096001",
                            "cap": "13861",
                            "nome": "Ailoche"
                        },
                        {
                            "code": "096002",
                            "cap": "13811",
                            "nome": "Andorno Micca"
                        },
                        {
                            "code": "096003",
                            "cap": "13871",
                            "nome": "Benna"
                        },
                        {
                            "code": "096004",
                            "cap": "13900",
                            "nome": "Biella"
                        },
                        {
                            "code": "096005",
                            "cap": "13841",
                            "nome": "Bioglio"
                        },
                        {
                            "code": "096006",
                            "cap": "13872",
                            "nome": "Borriana"
                        },
                        {
                            "code": "096007",
                            "cap": "13862",
                            "nome": "Brusnengo"
                        },
                        {
                            "code": "096008",
                            "cap": "13821",
                            "nome": "Callabiana"
                        },
                        {
                            "code": "096009",
                            "cap": "13821",
                            "nome": "Camandona"
                        },
                        {
                            "code": "096010",
                            "cap": "13891",
                            "nome": "Camburzano"
                        },
                        {
                            "code": "096011",
                            "cap": "13812",
                            "nome": "Campiglia Cervo"
                        },
                        {
                            "code": "096012",
                            "cap": "13878",
                            "nome": "Candelo"
                        },
                        {
                            "code": "096013",
                            "cap": "13864",
                            "nome": "Caprile"
                        },
                        {
                            "code": "096014",
                            "cap": "13866",
                            "nome": "Casapinta"
                        },
                        {
                            "code": "096015",
                            "cap": "13851",
                            "nome": "Castelletto Cervo"
                        },
                        {
                            "code": "096016",
                            "cap": "13881",
                            "nome": "Cavagli\u00e0"
                        },
                        {
                            "code": "096017",
                            "cap": "13852",
                            "nome": "Cerreto Castello"
                        },
                        {
                            "code": "096018",
                            "cap": "13882",
                            "nome": "Cerrione"
                        },
                        {
                            "code": "096019",
                            "cap": "13863",
                            "nome": "Coggiola"
                        },
                        {
                            "code": "096020",
                            "cap": "13836",
                            "nome": "Cossato"
                        },
                        {
                            "code": "096021",
                            "cap": "13864",
                            "nome": "Crevacuore"
                        },
                        {
                            "code": "096022",
                            "cap": "13853",
                            "nome": "Crosa"
                        },
                        {
                            "code": "096023",
                            "cap": "13865",
                            "nome": "Curino"
                        },
                        {
                            "code": "096024",
                            "cap": "13893",
                            "nome": "Donato"
                        },
                        {
                            "code": "096025",
                            "cap": "13881",
                            "nome": "Dorzano"
                        },
                        {
                            "code": "096026",
                            "cap": "13894",
                            "nome": "Gaglianico"
                        },
                        {
                            "code": "096027",
                            "cap": "13874",
                            "nome": "Gifflenga"
                        },
                        {
                            "code": "096028",
                            "cap": "13895",
                            "nome": "Graglia"
                        },
                        {
                            "code": "096029",
                            "cap": "13853",
                            "nome": "Lessona"
                        },
                        {
                            "code": "096030",
                            "cap": "13887",
                            "nome": "Magnano"
                        },
                        {
                            "code": "096031",
                            "cap": "13873",
                            "nome": "Massazza"
                        },
                        {
                            "code": "096032",
                            "cap": "13866",
                            "nome": "Masserano"
                        },
                        {
                            "code": "096033",
                            "cap": "13831",
                            "nome": "Mezzana Mortigliengo"
                        },
                        {
                            "code": "096034",
                            "cap": "13816",
                            "nome": "Miagliano"
                        },
                        {
                            "code": "096035",
                            "cap": "13888",
                            "nome": "Mongrando"
                        },
                        {
                            "code": "096084",
                            "cap": "13822",
                            "nome": "Mosso"
                        },
                        {
                            "code": "096037",
                            "cap": "13874",
                            "nome": "Mottalciata"
                        },
                        {
                            "code": "096038",
                            "cap": "13895",
                            "nome": "Muzzano"
                        },
                        {
                            "code": "096039",
                            "cap": "13896",
                            "nome": "Netro"
                        },
                        {
                            "code": "096040",
                            "cap": "13897",
                            "nome": "Occhieppo Inferiore"
                        },
                        {
                            "code": "096041",
                            "cap": "13898",
                            "nome": "Occhieppo Superiore"
                        },
                        {
                            "code": "096042",
                            "cap": "13843",
                            "nome": "Pettinengo"
                        },
                        {
                            "code": "096043",
                            "cap": "13844",
                            "nome": "Piatto"
                        },
                        {
                            "code": "096044",
                            "cap": "13812",
                            "nome": "Piedicavallo"
                        },
                        {
                            "code": "096046",
                            "cap": "13814",
                            "nome": "Pollone"
                        },
                        {
                            "code": "096047",
                            "cap": "13875",
                            "nome": "Ponderano"
                        },
                        {
                            "code": "096048",
                            "cap": "13833",
                            "nome": "Portula"
                        },
                        {
                            "code": "096049",
                            "cap": "13899",
                            "nome": "Pralungo"
                        },
                        {
                            "code": "096050",
                            "cap": "13867",
                            "nome": "Pray"
                        },
                        {
                            "code": "096051",
                            "cap": "13854",
                            "nome": "Quaregna"
                        },
                        {
                            "code": "096052",
                            "cap": "13812",
                            "nome": "Quittengo"
                        },
                        {
                            "code": "096053",
                            "cap": "13845",
                            "nome": "Ronco Biellese"
                        },
                        {
                            "code": "096054",
                            "cap": "13883",
                            "nome": "Roppolo"
                        },
                        {
                            "code": "096055",
                            "cap": "13815",
                            "nome": "Rosazza"
                        },
                        {
                            "code": "096056",
                            "cap": "13816",
                            "nome": "Sagliano Micca"
                        },
                        {
                            "code": "096057",
                            "cap": "13884",
                            "nome": "Sala Biellese"
                        },
                        {
                            "code": "096058",
                            "cap": "13885",
                            "nome": "Salussola"
                        },
                        {
                            "code": "096060",
                            "cap": "13812",
                            "nome": "San Paolo Cervo"
                        },
                        {
                            "code": "096059",
                            "cap": "13876",
                            "nome": "Sandigliano"
                        },
                        {
                            "code": "096061",
                            "cap": "13841",
                            "nome": "Selve Marcone"
                        },
                        {
                            "code": "096062",
                            "cap": "13834",
                            "nome": "Soprana"
                        },
                        {
                            "code": "096063",
                            "cap": "13817",
                            "nome": "Sordevolo"
                        },
                        {
                            "code": "096064",
                            "cap": "13868",
                            "nome": "Sostegno"
                        },
                        {
                            "code": "096065",
                            "cap": "13823",
                            "nome": "Strona"
                        },
                        {
                            "code": "096066",
                            "cap": "13811",
                            "nome": "Tavigliano"
                        },
                        {
                            "code": "096067",
                            "cap": "13844",
                            "nome": "Ternengo"
                        },
                        {
                            "code": "096068",
                            "cap": "13818",
                            "nome": "Tollegno"
                        },
                        {
                            "code": "096069",
                            "cap": "13884",
                            "nome": "Torrazzo"
                        },
                        {
                            "code": "096070",
                            "cap": "13835",
                            "nome": "Trivero"
                        },
                        {
                            "code": "096071",
                            "cap": "13855",
                            "nome": "Valdengo"
                        },
                        {
                            "code": "096072",
                            "cap": "13847",
                            "nome": "Vallanzengo"
                        },
                        {
                            "code": "096073",
                            "cap": "13825",
                            "nome": "Valle Mosso"
                        },
                        {
                            "code": "096074",
                            "cap": "13847",
                            "nome": "Valle San Nicolao"
                        },
                        {
                            "code": "096075",
                            "cap": "13824",
                            "nome": "Veglio"
                        },
                        {
                            "code": "096076",
                            "cap": "13871",
                            "nome": "Verrone"
                        },
                        {
                            "code": "096077",
                            "cap": "13856",
                            "nome": "Vigliano Biellese"
                        },
                        {
                            "code": "096078",
                            "cap": "13868",
                            "nome": "Villa del Bosco"
                        },
                        {
                            "code": "096079",
                            "cap": "13877",
                            "nome": "Villanova Biellese"
                        },
                        {
                            "code": "096080",
                            "cap": "13886",
                            "nome": "Viverone"
                        },
                        {
                            "code": "096081",
                            "cap": "13887",
                            "nome": "Zimone"
                        },
                        {
                            "code": "096082",
                            "cap": "13888",
                            "nome": "Zubiena"
                        },
                        {
                            "code": "096083",
                            "cap": "13848",
                            "nome": "Zumaglia"
                        }
                    ],
                    "nome": "Biella"
                },
                {
                    "code": "CN",
                    "comuni": [
                        {
                            "code": "004001",
                            "cap": "12021",
                            "nome": "Acceglio"
                        },
                        {
                            "code": "004002",
                            "cap": "12010",
                            "nome": "Aisone"
                        },
                        {
                            "code": "004003",
                            "cap": "12051",
                            "nome": "Alba"
                        },
                        {
                            "code": "004004",
                            "cap": "12050",
                            "nome": "Albaretto della Torre"
                        },
                        {
                            "code": "004005",
                            "cap": "12070",
                            "nome": "Alto"
                        },
                        {
                            "code": "004006",
                            "cap": "12010",
                            "nome": "Argentera"
                        },
                        {
                            "code": "004007",
                            "cap": "12050",
                            "nome": "Arguello"
                        },
                        {
                            "code": "004008",
                            "cap": "12071",
                            "nome": "Bagnasco"
                        },
                        {
                            "code": "004009",
                            "cap": "12031",
                            "nome": "Bagnolo Piemonte"
                        },
                        {
                            "code": "004010",
                            "cap": "12040",
                            "nome": "Baldissero d'Alba"
                        },
                        {
                            "code": "004011",
                            "cap": "12050",
                            "nome": "Barbaresco"
                        },
                        {
                            "code": "004012",
                            "cap": "12032",
                            "nome": "Barge"
                        },
                        {
                            "code": "004013",
                            "cap": "12060",
                            "nome": "Barolo"
                        },
                        {
                            "code": "004014",
                            "cap": "12060",
                            "nome": "Bastia Mondov\u00ec"
                        },
                        {
                            "code": "004015",
                            "cap": "12070",
                            "nome": "Battifollo"
                        },
                        {
                            "code": "004016",
                            "cap": "12081",
                            "nome": "Beinette"
                        },
                        {
                            "code": "004017",
                            "cap": "12020",
                            "nome": "Bellino"
                        },
                        {
                            "code": "004018",
                            "cap": "12060",
                            "nome": "Belvedere Langhe"
                        },
                        {
                            "code": "004019",
                            "cap": "12041",
                            "nome": "Bene Vagienna"
                        },
                        {
                            "code": "004020",
                            "cap": "12050",
                            "nome": "Benevello"
                        },
                        {
                            "code": "004021",
                            "cap": "12074",
                            "nome": "Bergolo"
                        },
                        {
                            "code": "004022",
                            "cap": "12010",
                            "nome": "Bernezzo"
                        },
                        {
                            "code": "004023",
                            "cap": "12060",
                            "nome": "Bonvicino"
                        },
                        {
                            "code": "004025",
                            "cap": "12011",
                            "nome": "Borgo San Dalmazzo"
                        },
                        {
                            "code": "004024",
                            "cap": "12050",
                            "nome": "Borgomale"
                        },
                        {
                            "code": "004026",
                            "cap": "12050",
                            "nome": "Bosia"
                        },
                        {
                            "code": "004027",
                            "cap": "12060",
                            "nome": "Bossolasco"
                        },
                        {
                            "code": "004028",
                            "cap": "12012",
                            "nome": "Boves"
                        },
                        {
                            "code": "004029",
                            "cap": "12042",
                            "nome": "Bra"
                        },
                        {
                            "code": "004030",
                            "cap": "12080",
                            "nome": "Briaglia"
                        },
                        {
                            "code": "004031",
                            "cap": "18025",
                            "nome": "Briga Alta"
                        },
                        {
                            "code": "004032",
                            "cap": "12030",
                            "nome": "Brondello"
                        },
                        {
                            "code": "004033",
                            "cap": "12020",
                            "nome": "Brossasco"
                        },
                        {
                            "code": "004034",
                            "cap": "12022",
                            "nome": "Busca"
                        },
                        {
                            "code": "004035",
                            "cap": "12072",
                            "nome": "Camerana"
                        },
                        {
                            "code": "004036",
                            "cap": "12050",
                            "nome": "Camo"
                        },
                        {
                            "code": "004037",
                            "cap": "12043",
                            "nome": "Canale"
                        },
                        {
                            "code": "004038",
                            "cap": "12020",
                            "nome": "Canosio"
                        },
                        {
                            "code": "004039",
                            "cap": "12070",
                            "nome": "Caprauna"
                        },
                        {
                            "code": "004040",
                            "cap": "12023",
                            "nome": "Caraglio"
                        },
                        {
                            "code": "004041",
                            "cap": "12030",
                            "nome": "Caramagna Piemonte"
                        },
                        {
                            "code": "004042",
                            "cap": "12030",
                            "nome": "Card\u00e8"
                        },
                        {
                            "code": "004043",
                            "cap": "12061",
                            "nome": "Carr\u00f9"
                        },
                        {
                            "code": "004044",
                            "cap": "12020",
                            "nome": "Cartignano"
                        },
                        {
                            "code": "004045",
                            "cap": "12030",
                            "nome": "Casalgrasso"
                        },
                        {
                            "code": "004046",
                            "cap": "12050",
                            "nome": "Castagnito"
                        },
                        {
                            "code": "004047",
                            "cap": "12020",
                            "nome": "Casteldelfino"
                        },
                        {
                            "code": "004048",
                            "cap": "12030",
                            "nome": "Castellar"
                        },
                        {
                            "code": "004049",
                            "cap": "12040",
                            "nome": "Castelletto Stura"
                        },
                        {
                            "code": "004050",
                            "cap": "12070",
                            "nome": "Castelletto Uzzone"
                        },
                        {
                            "code": "004051",
                            "cap": "12050",
                            "nome": "Castellinaldo"
                        },
                        {
                            "code": "004052",
                            "cap": "12060",
                            "nome": "Castellino Tanaro"
                        },
                        {
                            "code": "004053",
                            "cap": "12020",
                            "nome": "Castelmagno"
                        },
                        {
                            "code": "004054",
                            "cap": "12070",
                            "nome": "Castelnuovo di Ceva"
                        },
                        {
                            "code": "004055",
                            "cap": "12060",
                            "nome": "Castiglione Falletto"
                        },
                        {
                            "code": "004056",
                            "cap": "12053",
                            "nome": "Castiglione Tinella"
                        },
                        {
                            "code": "004057",
                            "cap": "12050",
                            "nome": "Castino"
                        },
                        {
                            "code": "004058",
                            "cap": "12030",
                            "nome": "Cavallerleone"
                        },
                        {
                            "code": "004059",
                            "cap": "12030",
                            "nome": "Cavallermaggiore"
                        },
                        {
                            "code": "004060",
                            "cap": "12020",
                            "nome": "Celle di Macra"
                        },
                        {
                            "code": "004061",
                            "cap": "12044",
                            "nome": "Centallo"
                        },
                        {
                            "code": "004062",
                            "cap": "12040",
                            "nome": "Ceresole Alba"
                        },
                        {
                            "code": "004063",
                            "cap": "12050",
                            "nome": "Cerretto Langhe"
                        },
                        {
                            "code": "004064",
                            "cap": "12010",
                            "nome": "Cervasca"
                        },
                        {
                            "code": "004065",
                            "cap": "12040",
                            "nome": "Cervere"
                        },
                        {
                            "code": "004066",
                            "cap": "12073",
                            "nome": "Ceva"
                        },
                        {
                            "code": "004067",
                            "cap": "12062",
                            "nome": "Cherasco"
                        },
                        {
                            "code": "004068",
                            "cap": "12013",
                            "nome": "Chiusa di Pesio"
                        },
                        {
                            "code": "004069",
                            "cap": "12060",
                            "nome": "Cigli\u00e8"
                        },
                        {
                            "code": "004070",
                            "cap": "12050",
                            "nome": "Cissone"
                        },
                        {
                            "code": "004071",
                            "cap": "12060",
                            "nome": "Clavesana"
                        },
                        {
                            "code": "004072",
                            "cap": "12040",
                            "nome": "Corneliano d'Alba"
                        },
                        {
                            "code": "004073",
                            "cap": "12074",
                            "nome": "Cortemilia"
                        },
                        {
                            "code": "004074",
                            "cap": "12054",
                            "nome": "Cossano Belbo"
                        },
                        {
                            "code": "004075",
                            "cap": "12024",
                            "nome": "Costigliole Saluzzo"
                        },
                        {
                            "code": "004076",
                            "cap": "12050",
                            "nome": "Cravanzana"
                        },
                        {
                            "code": "004077",
                            "cap": "12030",
                            "nome": "Crissolo"
                        },
                        {
                            "code": "004078",
                            "cap": "12100",
                            "nome": "Cuneo"
                        },
                        {
                            "code": "004079",
                            "cap": "12014",
                            "nome": "Demonte"
                        },
                        {
                            "code": "004080",
                            "cap": "12055",
                            "nome": "Diano d'Alba"
                        },
                        {
                            "code": "004081",
                            "cap": "12063",
                            "nome": "Dogliani"
                        },
                        {
                            "code": "004082",
                            "cap": "12025",
                            "nome": "Dronero"
                        },
                        {
                            "code": "004083",
                            "cap": "12020",
                            "nome": "Elva"
                        },
                        {
                            "code": "004084",
                            "cap": "12010",
                            "nome": "Entracque"
                        },
                        {
                            "code": "004085",
                            "cap": "12030",
                            "nome": "Envie"
                        },
                        {
                            "code": "004086",
                            "cap": "12060",
                            "nome": "Farigliano"
                        },
                        {
                            "code": "004087",
                            "cap": "12030",
                            "nome": "Faule"
                        },
                        {
                            "code": "004088",
                            "cap": "12050",
                            "nome": "Feisoglio"
                        },
                        {
                            "code": "004089",
                            "cap": "12045",
                            "nome": "Fossano"
                        },
                        {
                            "code": "004090",
                            "cap": "12082",
                            "nome": "Frabosa Soprana"
                        },
                        {
                            "code": "004091",
                            "cap": "12083",
                            "nome": "Frabosa Sottana"
                        },
                        {
                            "code": "004092",
                            "cap": "12020",
                            "nome": "Frassino"
                        },
                        {
                            "code": "004093",
                            "cap": "12010",
                            "nome": "Gaiola"
                        },
                        {
                            "code": "004094",
                            "cap": "12030",
                            "nome": "Gambasca"
                        },
                        {
                            "code": "004095",
                            "cap": "12075",
                            "nome": "Garessio"
                        },
                        {
                            "code": "004096",
                            "cap": "12040",
                            "nome": "Genola"
                        },
                        {
                            "code": "004097",
                            "cap": "12070",
                            "nome": "Gorzegno"
                        },
                        {
                            "code": "004098",
                            "cap": "12070",
                            "nome": "Gottasecca"
                        },
                        {
                            "code": "004099",
                            "cap": "12040",
                            "nome": "Govone"
                        },
                        {
                            "code": "004100",
                            "cap": "12060",
                            "nome": "Grinzane Cavour"
                        },
                        {
                            "code": "004101",
                            "cap": "12050",
                            "nome": "Guarene"
                        },
                        {
                            "code": "004102",
                            "cap": "12060",
                            "nome": "Igliano"
                        },
                        {
                            "code": "004103",
                            "cap": "12020",
                            "nome": "Isasca"
                        },
                        {
                            "code": "004105",
                            "cap": "12064",
                            "nome": "La Morra"
                        },
                        {
                            "code": "004104",
                            "cap": "12030",
                            "nome": "Lagnasco"
                        },
                        {
                            "code": "004106",
                            "cap": "12050",
                            "nome": "Lequio Berria"
                        },
                        {
                            "code": "004107",
                            "cap": "12060",
                            "nome": "Lequio Tanaro"
                        },
                        {
                            "code": "004108",
                            "cap": "12076",
                            "nome": "Lesegno"
                        },
                        {
                            "code": "004109",
                            "cap": "12070",
                            "nome": "Levice"
                        },
                        {
                            "code": "004110",
                            "cap": "12015",
                            "nome": "Limone Piemonte"
                        },
                        {
                            "code": "004111",
                            "cap": "12070",
                            "nome": "Lisio"
                        },
                        {
                            "code": "004112",
                            "cap": "12020",
                            "nome": "Macra"
                        },
                        {
                            "code": "004113",
                            "cap": "12050",
                            "nome": "Magliano Alfieri"
                        },
                        {
                            "code": "004114",
                            "cap": "12060",
                            "nome": "Magliano Alpi"
                        },
                        {
                            "code": "004115",
                            "cap": "12056",
                            "nome": "Mango"
                        },
                        {
                            "code": "004116",
                            "cap": "12030",
                            "nome": "Manta"
                        },
                        {
                            "code": "004117",
                            "cap": "12030",
                            "nome": "Marene"
                        },
                        {
                            "code": "004118",
                            "cap": "12040",
                            "nome": "Margarita"
                        },
                        {
                            "code": "004119",
                            "cap": "12020",
                            "nome": "Marmora"
                        },
                        {
                            "code": "004120",
                            "cap": "12060",
                            "nome": "Marsaglia"
                        },
                        {
                            "code": "004121",
                            "cap": "12030",
                            "nome": "Martiniana Po"
                        },
                        {
                            "code": "004122",
                            "cap": "12020",
                            "nome": "Melle"
                        },
                        {
                            "code": "004123",
                            "cap": "12010",
                            "nome": "Moiola"
                        },
                        {
                            "code": "004124",
                            "cap": "12070",
                            "nome": "Mombarcaro"
                        },
                        {
                            "code": "004125",
                            "cap": "12070",
                            "nome": "Mombasiglio"
                        },
                        {
                            "code": "004126",
                            "cap": "12080",
                            "nome": "Monastero di Vasco"
                        },
                        {
                            "code": "004127",
                            "cap": "12080",
                            "nome": "Monasterolo Casotto"
                        },
                        {
                            "code": "004128",
                            "cap": "12030",
                            "nome": "Monasterolo di Savigliano"
                        },
                        {
                            "code": "004129",
                            "cap": "12060",
                            "nome": "Monchiero"
                        },
                        {
                            "code": "004130",
                            "cap": "12084",
                            "nome": "Mondov\u00ec"
                        },
                        {
                            "code": "004131",
                            "cap": "12077",
                            "nome": "Monesiglio"
                        },
                        {
                            "code": "004132",
                            "cap": "12065",
                            "nome": "Monforte d'Alba"
                        },
                        {
                            "code": "004133",
                            "cap": "12046",
                            "nome": "Mont\u00e0"
                        },
                        {
                            "code": "004134",
                            "cap": "12080",
                            "nome": "Montaldo di Mondov\u00ec"
                        },
                        {
                            "code": "004135",
                            "cap": "12040",
                            "nome": "Montaldo Roero"
                        },
                        {
                            "code": "004136",
                            "cap": "12040",
                            "nome": "Montanera"
                        },
                        {
                            "code": "004137",
                            "cap": "12050",
                            "nome": "Montelupo Albese"
                        },
                        {
                            "code": "004138",
                            "cap": "12025",
                            "nome": "Montemale di Cuneo"
                        },
                        {
                            "code": "004139",
                            "cap": "12020",
                            "nome": "Monterosso Grana"
                        },
                        {
                            "code": "004140",
                            "cap": "12040",
                            "nome": "Monteu Roero"
                        },
                        {
                            "code": "004141",
                            "cap": "12070",
                            "nome": "Montezemolo"
                        },
                        {
                            "code": "004142",
                            "cap": "12066",
                            "nome": "Monticello d'Alba"
                        },
                        {
                            "code": "004143",
                            "cap": "12033",
                            "nome": "Moretta"
                        },
                        {
                            "code": "004144",
                            "cap": "12040",
                            "nome": "Morozzo"
                        },
                        {
                            "code": "004145",
                            "cap": "12060",
                            "nome": "Murazzano"
                        },
                        {
                            "code": "004146",
                            "cap": "12030",
                            "nome": "Murello"
                        },
                        {
                            "code": "004147",
                            "cap": "12068",
                            "nome": "Narzole"
                        },
                        {
                            "code": "004148",
                            "cap": "12052",
                            "nome": "Neive"
                        },
                        {
                            "code": "004149",
                            "cap": "12050",
                            "nome": "Neviglie"
                        },
                        {
                            "code": "004150",
                            "cap": "12050",
                            "nome": "Niella Belbo"
                        },
                        {
                            "code": "004151",
                            "cap": "12060",
                            "nome": "Niella Tanaro"
                        },
                        {
                            "code": "004152",
                            "cap": "12060",
                            "nome": "Novello"
                        },
                        {
                            "code": "004153",
                            "cap": "12070",
                            "nome": "Nucetto"
                        },
                        {
                            "code": "004154",
                            "cap": "12030",
                            "nome": "Oncino"
                        },
                        {
                            "code": "004155",
                            "cap": "12078",
                            "nome": "Ormea"
                        },
                        {
                            "code": "004156",
                            "cap": "12030",
                            "nome": "Ostana"
                        },
                        {
                            "code": "004157",
                            "cap": "12034",
                            "nome": "Paesana"
                        },
                        {
                            "code": "004158",
                            "cap": "12030",
                            "nome": "Pagno"
                        },
                        {
                            "code": "004159",
                            "cap": "12087",
                            "nome": "Pamparato"
                        },
                        {
                            "code": "004160",
                            "cap": "12070",
                            "nome": "Paroldo"
                        },
                        {
                            "code": "004161",
                            "cap": "12070",
                            "nome": "Perletto"
                        },
                        {
                            "code": "004162",
                            "cap": "12070",
                            "nome": "Perlo"
                        },
                        {
                            "code": "004163",
                            "cap": "12016",
                            "nome": "Peveragno"
                        },
                        {
                            "code": "004164",
                            "cap": "12070",
                            "nome": "Pezzolo Valle Uzzone"
                        },
                        {
                            "code": "004165",
                            "cap": "12080",
                            "nome": "Pianfei"
                        },
                        {
                            "code": "004166",
                            "cap": "12026",
                            "nome": "Piasco"
                        },
                        {
                            "code": "004167",
                            "cap": "12010",
                            "nome": "Pietraporzio"
                        },
                        {
                            "code": "004168",
                            "cap": "12040",
                            "nome": "Piobesi d'Alba"
                        },
                        {
                            "code": "004169",
                            "cap": "12060",
                            "nome": "Piozzo"
                        },
                        {
                            "code": "004170",
                            "cap": "12060",
                            "nome": "Pocapaglia"
                        },
                        {
                            "code": "004171",
                            "cap": "12030",
                            "nome": "Polonghera"
                        },
                        {
                            "code": "004172",
                            "cap": "12020",
                            "nome": "Pontechianale"
                        },
                        {
                            "code": "004173",
                            "cap": "12027",
                            "nome": "Pradleves"
                        },
                        {
                            "code": "004174",
                            "cap": "12028",
                            "nome": "Prazzo"
                        },
                        {
                            "code": "004175",
                            "cap": "12070",
                            "nome": "Priero"
                        },
                        {
                            "code": "004176",
                            "cap": "12040",
                            "nome": "Priocca"
                        },
                        {
                            "code": "004177",
                            "cap": "12070",
                            "nome": "Priola"
                        },
                        {
                            "code": "004178",
                            "cap": "12077",
                            "nome": "Prunetto"
                        },
                        {
                            "code": "004179",
                            "cap": "12035",
                            "nome": "Racconigi"
                        },
                        {
                            "code": "004180",
                            "cap": "12036",
                            "nome": "Revello"
                        },
                        {
                            "code": "004181",
                            "cap": "12030",
                            "nome": "Rifreddo"
                        },
                        {
                            "code": "004182",
                            "cap": "12010",
                            "nome": "Rittana"
                        },
                        {
                            "code": "004183",
                            "cap": "12010",
                            "nome": "Roaschia"
                        },
                        {
                            "code": "004184",
                            "cap": "12073",
                            "nome": "Roascio"
                        },
                        {
                            "code": "004185",
                            "cap": "12017",
                            "nome": "Robilante"
                        },
                        {
                            "code": "004186",
                            "cap": "12080",
                            "nome": "Roburent"
                        },
                        {
                            "code": "004188",
                            "cap": "12060",
                            "nome": "Rocca Cigli\u00e8"
                        },
                        {
                            "code": "004189",
                            "cap": "12047",
                            "nome": "Rocca de' Baldi"
                        },
                        {
                            "code": "004187",
                            "cap": "12020",
                            "nome": "Roccabruna"
                        },
                        {
                            "code": "004190",
                            "cap": "12088",
                            "nome": "Roccaforte Mondov\u00ec"
                        },
                        {
                            "code": "004191",
                            "cap": "12010",
                            "nome": "Roccasparvera"
                        },
                        {
                            "code": "004192",
                            "cap": "12018",
                            "nome": "Roccavione"
                        },
                        {
                            "code": "004193",
                            "cap": "12050",
                            "nome": "Rocchetta Belbo"
                        },
                        {
                            "code": "004194",
                            "cap": "12060",
                            "nome": "Roddi"
                        },
                        {
                            "code": "004195",
                            "cap": "12050",
                            "nome": "Roddino"
                        },
                        {
                            "code": "004196",
                            "cap": "12050",
                            "nome": "Rodello"
                        },
                        {
                            "code": "004197",
                            "cap": "12020",
                            "nome": "Rossana"
                        },
                        {
                            "code": "004198",
                            "cap": "12030",
                            "nome": "Ruffia"
                        },
                        {
                            "code": "004199",
                            "cap": "12070",
                            "nome": "Sale delle Langhe"
                        },
                        {
                            "code": "004200",
                            "cap": "12070",
                            "nome": "Sale San Giovanni"
                        },
                        {
                            "code": "004201",
                            "cap": "12079",
                            "nome": "Saliceto"
                        },
                        {
                            "code": "004202",
                            "cap": "12040",
                            "nome": "Salmour"
                        },
                        {
                            "code": "004203",
                            "cap": "12037",
                            "nome": "Saluzzo"
                        },
                        {
                            "code": "004204",
                            "cap": "12010",
                            "nome": "Sambuco"
                        },
                        {
                            "code": "004205",
                            "cap": "12020",
                            "nome": "Sampeyre"
                        },
                        {
                            "code": "004206",
                            "cap": "12050",
                            "nome": "San Benedetto Belbo"
                        },
                        {
                            "code": "004207",
                            "cap": "12029",
                            "nome": "San Damiano Macra"
                        },
                        {
                            "code": "004210",
                            "cap": "12080",
                            "nome": "San Michele Mondov\u00ec"
                        },
                        {
                            "code": "004208",
                            "cap": "12040",
                            "nome": "Sanfr\u00e8"
                        },
                        {
                            "code": "004209",
                            "cap": "12030",
                            "nome": "Sanfront"
                        },
                        {
                            "code": "004212",
                            "cap": "12069",
                            "nome": "Santa Vittoria d'Alba"
                        },
                        {
                            "code": "004211",
                            "cap": "12040",
                            "nome": "Sant'Albano Stura"
                        },
                        {
                            "code": "004213",
                            "cap": "12058",
                            "nome": "Santo Stefano Belbo"
                        },
                        {
                            "code": "004214",
                            "cap": "12040",
                            "nome": "Santo Stefano Roero"
                        },
                        {
                            "code": "004215",
                            "cap": "12038",
                            "nome": "Savigliano"
                        },
                        {
                            "code": "004216",
                            "cap": "12070",
                            "nome": "Scagnello"
                        },
                        {
                            "code": "004217",
                            "cap": "12030",
                            "nome": "Scarnafigi"
                        },
                        {
                            "code": "004218",
                            "cap": "12050",
                            "nome": "Serralunga d'Alba"
                        },
                        {
                            "code": "004219",
                            "cap": "12050",
                            "nome": "Serravalle Langhe"
                        },
                        {
                            "code": "004220",
                            "cap": "12050",
                            "nome": "Sinio"
                        },
                        {
                            "code": "004221",
                            "cap": "12060",
                            "nome": "Somano"
                        },
                        {
                            "code": "004222",
                            "cap": "12048",
                            "nome": "Sommariva del Bosco"
                        },
                        {
                            "code": "004223",
                            "cap": "12040",
                            "nome": "Sommariva Perno"
                        },
                        {
                            "code": "004224",
                            "cap": "12020",
                            "nome": "Stroppo"
                        },
                        {
                            "code": "004225",
                            "cap": "12020",
                            "nome": "Tarantasca"
                        },
                        {
                            "code": "004226",
                            "cap": "12050",
                            "nome": "Torre Bormida"
                        },
                        {
                            "code": "004227",
                            "cap": "12080",
                            "nome": "Torre Mondov\u00ec"
                        },
                        {
                            "code": "004228",
                            "cap": "12030",
                            "nome": "Torre San Giorgio"
                        },
                        {
                            "code": "004229",
                            "cap": "12070",
                            "nome": "Torresina"
                        },
                        {
                            "code": "004230",
                            "cap": "12050",
                            "nome": "Treiso"
                        },
                        {
                            "code": "004231",
                            "cap": "12050",
                            "nome": "Trezzo Tinella"
                        },
                        {
                            "code": "004232",
                            "cap": "12049",
                            "nome": "Trinit\u00e0"
                        },
                        {
                            "code": "004233",
                            "cap": "12010",
                            "nome": "Valdieri"
                        },
                        {
                            "code": "004234",
                            "cap": "12020",
                            "nome": "Valgrana"
                        },
                        {
                            "code": "004235",
                            "cap": "12010",
                            "nome": "Valloriate"
                        },
                        {
                            "code": "004236",
                            "cap": "12020",
                            "nome": "Valmala"
                        },
                        {
                            "code": "004237",
                            "cap": "12020",
                            "nome": "Venasca"
                        },
                        {
                            "code": "004238",
                            "cap": "12060",
                            "nome": "Verduno"
                        },
                        {
                            "code": "004239",
                            "cap": "12019",
                            "nome": "Vernante"
                        },
                        {
                            "code": "004240",
                            "cap": "12039",
                            "nome": "Verzuolo"
                        },
                        {
                            "code": "004241",
                            "cap": "12040",
                            "nome": "Vezza d'Alba"
                        },
                        {
                            "code": "004242",
                            "cap": "12080",
                            "nome": "Vicoforte"
                        },
                        {
                            "code": "004243",
                            "cap": "12010",
                            "nome": "Vignolo"
                        },
                        {
                            "code": "004244",
                            "cap": "12020",
                            "nome": "Villafalletto"
                        },
                        {
                            "code": "004245",
                            "cap": "12089",
                            "nome": "Villanova Mondov\u00ec"
                        },
                        {
                            "code": "004246",
                            "cap": "12030",
                            "nome": "Villanova Solaro"
                        },
                        {
                            "code": "004247",
                            "cap": "12020",
                            "nome": "Villar San Costanzo"
                        },
                        {
                            "code": "004248",
                            "cap": "12010",
                            "nome": "Vinadio"
                        },
                        {
                            "code": "004249",
                            "cap": "12070",
                            "nome": "Viola"
                        },
                        {
                            "code": "004250",
                            "cap": "12020",
                            "nome": "Vottignasco"
                        }
                    ],
                    "nome": "Cuneo"
                },
                {
                    "code": "NO",
                    "comuni": [
                        {
                            "code": "003001",
                            "cap": "28010",
                            "nome": "Agrate Conturbia"
                        },
                        {
                            "code": "003002",
                            "cap": "28010",
                            "nome": "Ameno"
                        },
                        {
                            "code": "003006",
                            "cap": "28011",
                            "nome": "Armeno"
                        },
                        {
                            "code": "003008",
                            "cap": "28041",
                            "nome": "Arona"
                        },
                        {
                            "code": "003012",
                            "cap": "28010",
                            "nome": "Barengo"
                        },
                        {
                            "code": "003016",
                            "cap": "28043",
                            "nome": "Bellinzago Novarese"
                        },
                        {
                            "code": "003018",
                            "cap": "28061",
                            "nome": "Biandrate"
                        },
                        {
                            "code": "003019",
                            "cap": "28010",
                            "nome": "Boca"
                        },
                        {
                            "code": "003021",
                            "cap": "28010",
                            "nome": "Bogogno"
                        },
                        {
                            "code": "003022",
                            "cap": "28010",
                            "nome": "Bolzano Novarese"
                        },
                        {
                            "code": "003025",
                            "cap": "28040",
                            "nome": "Borgo Ticino"
                        },
                        {
                            "code": "003023",
                            "cap": "28071",
                            "nome": "Borgolavezzaro"
                        },
                        {
                            "code": "003024",
                            "cap": "28021",
                            "nome": "Borgomanero"
                        },
                        {
                            "code": "003026",
                            "cap": "28010",
                            "nome": "Briga Novarese"
                        },
                        {
                            "code": "003027",
                            "cap": "28072",
                            "nome": "Briona"
                        },
                        {
                            "code": "003030",
                            "cap": "28010",
                            "nome": "Caltignaga"
                        },
                        {
                            "code": "003032",
                            "cap": "28062",
                            "nome": "Cameri"
                        },
                        {
                            "code": "003036",
                            "cap": "28064",
                            "nome": "Carpignano Sesia"
                        },
                        {
                            "code": "003037",
                            "cap": "28060",
                            "nome": "Casalbeltrame"
                        },
                        {
                            "code": "003039",
                            "cap": "28060",
                            "nome": "Casaleggio Novara"
                        },
                        {
                            "code": "003040",
                            "cap": "28060",
                            "nome": "Casalino"
                        },
                        {
                            "code": "003041",
                            "cap": "28060",
                            "nome": "Casalvolone"
                        },
                        {
                            "code": "003042",
                            "cap": "28060",
                            "nome": "Castellazzo Novarese"
                        },
                        {
                            "code": "003043",
                            "cap": "28053",
                            "nome": "Castelletto Sopra Ticino"
                        },
                        {
                            "code": "003044",
                            "cap": "28010",
                            "nome": "Cavaglietto"
                        },
                        {
                            "code": "003045",
                            "cap": "28010",
                            "nome": "Cavaglio d'Agogna"
                        },
                        {
                            "code": "003047",
                            "cap": "28010",
                            "nome": "Cavallirio"
                        },
                        {
                            "code": "003049",
                            "cap": "28065",
                            "nome": "Cerano"
                        },
                        {
                            "code": "003051",
                            "cap": "28010",
                            "nome": "Colazza"
                        },
                        {
                            "code": "003052",
                            "cap": "28060",
                            "nome": "Comignago"
                        },
                        {
                            "code": "003055",
                            "cap": "28012",
                            "nome": "Cressa"
                        },
                        {
                            "code": "003058",
                            "cap": "28060",
                            "nome": "Cureggio"
                        },
                        {
                            "code": "003060",
                            "cap": "28010",
                            "nome": "Divignano"
                        },
                        {
                            "code": "003062",
                            "cap": "28040",
                            "nome": "Dormelletto"
                        },
                        {
                            "code": "003065",
                            "cap": "28073",
                            "nome": "Fara Novarese"
                        },
                        {
                            "code": "003066",
                            "cap": "28010",
                            "nome": "Fontaneto d'Agogna"
                        },
                        {
                            "code": "003068",
                            "cap": "28066",
                            "nome": "Galliate"
                        },
                        {
                            "code": "003069",
                            "cap": "28070",
                            "nome": "Garbagna Novarese"
                        },
                        {
                            "code": "003070",
                            "cap": "28010",
                            "nome": "Gargallo"
                        },
                        {
                            "code": "003071",
                            "cap": "28013",
                            "nome": "Gattico"
                        },
                        {
                            "code": "003073",
                            "cap": "28074",
                            "nome": "Ghemme"
                        },
                        {
                            "code": "003076",
                            "cap": "28024",
                            "nome": "Gozzano"
                        },
                        {
                            "code": "003077",
                            "cap": "28060",
                            "nome": "Granozzo con Monticello"
                        },
                        {
                            "code": "003079",
                            "cap": "28075",
                            "nome": "Grignasco"
                        },
                        {
                            "code": "003082",
                            "cap": "28045",
                            "nome": "Invorio"
                        },
                        {
                            "code": "003083",
                            "cap": "28064",
                            "nome": "Landiona"
                        },
                        {
                            "code": "003084",
                            "cap": "28040",
                            "nome": "Lesa"
                        },
                        {
                            "code": "003088",
                            "cap": "28014",
                            "nome": "Maggiora"
                        },
                        {
                            "code": "003090",
                            "cap": "28060",
                            "nome": "Mandello Vitta"
                        },
                        {
                            "code": "003091",
                            "cap": "28040",
                            "nome": "Marano Ticino"
                        },
                        {
                            "code": "003093",
                            "cap": "28040",
                            "nome": "Massino Visconti"
                        },
                        {
                            "code": "003095",
                            "cap": "28046",
                            "nome": "Meina"
                        },
                        {
                            "code": "003097",
                            "cap": "28040",
                            "nome": "Mezzomerico"
                        },
                        {
                            "code": "003098",
                            "cap": "28010",
                            "nome": "Miasino"
                        },
                        {
                            "code": "003100",
                            "cap": "28015",
                            "nome": "Momo"
                        },
                        {
                            "code": "003103",
                            "cap": "28010",
                            "nome": "Nebbiuno"
                        },
                        {
                            "code": "003104",
                            "cap": "28070",
                            "nome": "Nibbiola"
                        },
                        {
                            "code": "003106",
                            "cap": "28100",
                            "nome": "Novara"
                        },
                        {
                            "code": "003108",
                            "cap": "28047",
                            "nome": "Oleggio"
                        },
                        {
                            "code": "003109",
                            "cap": "28040",
                            "nome": "Oleggio Castello"
                        },
                        {
                            "code": "003112",
                            "cap": "28016",
                            "nome": "Orta San Giulio"
                        },
                        {
                            "code": "003114",
                            "cap": "28040",
                            "nome": "Paruzzaro"
                        },
                        {
                            "code": "003115",
                            "cap": "28010",
                            "nome": "Pella"
                        },
                        {
                            "code": "003116",
                            "cap": "28028",
                            "nome": "Pettenasco"
                        },
                        {
                            "code": "003119",
                            "cap": "28010",
                            "nome": "Pisano"
                        },
                        {
                            "code": "003120",
                            "cap": "28076",
                            "nome": "Pogno"
                        },
                        {
                            "code": "003121",
                            "cap": "28050",
                            "nome": "Pombia"
                        },
                        {
                            "code": "003122",
                            "cap": "28077",
                            "nome": "Prato Sesia"
                        },
                        {
                            "code": "003129",
                            "cap": "28060",
                            "nome": "Recetto"
                        },
                        {
                            "code": "003130",
                            "cap": "28078",
                            "nome": "Romagnano Sesia"
                        },
                        {
                            "code": "003131",
                            "cap": "28068",
                            "nome": "Romentino"
                        },
                        {
                            "code": "003133",
                            "cap": "28017",
                            "nome": "San Maurizio d'Opaglio"
                        },
                        {
                            "code": "003134",
                            "cap": "28060",
                            "nome": "San Nazzaro Sesia"
                        },
                        {
                            "code": "003135",
                            "cap": "28060",
                            "nome": "San Pietro Mosezzo"
                        },
                        {
                            "code": "003138",
                            "cap": "28064",
                            "nome": "Sillavengo"
                        },
                        {
                            "code": "003139",
                            "cap": "28070",
                            "nome": "Sizzano"
                        },
                        {
                            "code": "003140",
                            "cap": "28010",
                            "nome": "Soriso"
                        },
                        {
                            "code": "003141",
                            "cap": "28060",
                            "nome": "Sozzago"
                        },
                        {
                            "code": "003143",
                            "cap": "28019",
                            "nome": "Suno"
                        },
                        {
                            "code": "003144",
                            "cap": "28070",
                            "nome": "Terdobbiate"
                        },
                        {
                            "code": "003146",
                            "cap": "28070",
                            "nome": "Tornaco"
                        },
                        {
                            "code": "003149",
                            "cap": "28069",
                            "nome": "Trecate"
                        },
                        {
                            "code": "003153",
                            "cap": "28010",
                            "nome": "Vaprio d'Agogna"
                        },
                        {
                            "code": "003154",
                            "cap": "28040",
                            "nome": "Varallo Pombia"
                        },
                        {
                            "code": "003157",
                            "cap": "28010",
                            "nome": "Veruno"
                        },
                        {
                            "code": "003158",
                            "cap": "28079",
                            "nome": "Vespolate"
                        },
                        {
                            "code": "003159",
                            "cap": "28060",
                            "nome": "Vicolungo"
                        },
                        {
                            "code": "003164",
                            "cap": "28060",
                            "nome": "Vinzaglio"
                        }
                    ],
                    "nome": "Novara"
                },
                {
                    "code": "TO",
                    "comuni": [
                        {
                            "code": "001001",
                            "cap": "10011",
                            "nome": "Agli\u00e8"
                        },
                        {
                            "code": "001002",
                            "cap": "10060",
                            "nome": "Airasca"
                        },
                        {
                            "code": "001003",
                            "cap": "10070",
                            "nome": "Ala di Stura"
                        },
                        {
                            "code": "001004",
                            "cap": "10010",
                            "nome": "Albiano d'Ivrea"
                        },
                        {
                            "code": "001005",
                            "cap": "10010",
                            "nome": "Alice Superiore"
                        },
                        {
                            "code": "001006",
                            "cap": "10040",
                            "nome": "Almese"
                        },
                        {
                            "code": "001007",
                            "cap": "10080",
                            "nome": "Alpette"
                        },
                        {
                            "code": "001008",
                            "cap": "10091",
                            "nome": "Alpignano"
                        },
                        {
                            "code": "001009",
                            "cap": "10020",
                            "nome": "Andezeno"
                        },
                        {
                            "code": "001010",
                            "cap": "10010",
                            "nome": "Andrate"
                        },
                        {
                            "code": "001011",
                            "cap": "10060",
                            "nome": "Angrogna"
                        },
                        {
                            "code": "001012",
                            "cap": "10020",
                            "nome": "Arignano"
                        },
                        {
                            "code": "001013",
                            "cap": "10051",
                            "nome": "Avigliana"
                        },
                        {
                            "code": "001014",
                            "cap": "10010",
                            "nome": "Azeglio"
                        },
                        {
                            "code": "001015",
                            "cap": "10010",
                            "nome": "Bairo"
                        },
                        {
                            "code": "001016",
                            "cap": "10070",
                            "nome": "Balangero"
                        },
                        {
                            "code": "001017",
                            "cap": "10080",
                            "nome": "Baldissero Canavese"
                        },
                        {
                            "code": "001018",
                            "cap": "10020",
                            "nome": "Baldissero Torinese"
                        },
                        {
                            "code": "001019",
                            "cap": "10070",
                            "nome": "Balme"
                        },
                        {
                            "code": "001020",
                            "cap": "10010",
                            "nome": "Banchette"
                        },
                        {
                            "code": "001021",
                            "cap": "10070",
                            "nome": "Barbania"
                        },
                        {
                            "code": "001022",
                            "cap": "10052",
                            "nome": "Bardonecchia"
                        },
                        {
                            "code": "001023",
                            "cap": "10010",
                            "nome": "Barone Canavese"
                        },
                        {
                            "code": "001024",
                            "cap": "10092",
                            "nome": "Beinasco"
                        },
                        {
                            "code": "001025",
                            "cap": "10060",
                            "nome": "Bibiana"
                        },
                        {
                            "code": "001026",
                            "cap": "10060",
                            "nome": "Bobbio Pellice"
                        },
                        {
                            "code": "001027",
                            "cap": "10012",
                            "nome": "Bollengo"
                        },
                        {
                            "code": "001028",
                            "cap": "10071",
                            "nome": "Borgaro Torinese"
                        },
                        {
                            "code": "001029",
                            "cap": "10080",
                            "nome": "Borgiallo"
                        },
                        {
                            "code": "001030",
                            "cap": "10013",
                            "nome": "Borgofranco d'Ivrea"
                        },
                        {
                            "code": "001031",
                            "cap": "10031",
                            "nome": "Borgomasino"
                        },
                        {
                            "code": "001032",
                            "cap": "10050",
                            "nome": "Borgone Susa"
                        },
                        {
                            "code": "001033",
                            "cap": "10080",
                            "nome": "Bosconero"
                        },
                        {
                            "code": "001034",
                            "cap": "10032",
                            "nome": "Brandizzo"
                        },
                        {
                            "code": "001035",
                            "cap": "10060",
                            "nome": "Bricherasio"
                        },
                        {
                            "code": "001036",
                            "cap": "10080",
                            "nome": "Brosso"
                        },
                        {
                            "code": "001037",
                            "cap": "10020",
                            "nome": "Brozolo"
                        },
                        {
                            "code": "001038",
                            "cap": "10090",
                            "nome": "Bruino"
                        },
                        {
                            "code": "001039",
                            "cap": "10020",
                            "nome": "Brusasco"
                        },
                        {
                            "code": "001040",
                            "cap": "10050",
                            "nome": "Bruzolo"
                        },
                        {
                            "code": "001041",
                            "cap": "10060",
                            "nome": "Buriasco"
                        },
                        {
                            "code": "001042",
                            "cap": "10010",
                            "nome": "Burolo"
                        },
                        {
                            "code": "001043",
                            "cap": "10080",
                            "nome": "Busano"
                        },
                        {
                            "code": "001044",
                            "cap": "10053",
                            "nome": "Bussoleno"
                        },
                        {
                            "code": "001045",
                            "cap": "10090",
                            "nome": "Buttigliera Alta"
                        },
                        {
                            "code": "001046",
                            "cap": "10070",
                            "nome": "Cafasse"
                        },
                        {
                            "code": "001047",
                            "cap": "10014",
                            "nome": "Caluso"
                        },
                        {
                            "code": "001048",
                            "cap": "10020",
                            "nome": "Cambiano"
                        },
                        {
                            "code": "001049",
                            "cap": "10060",
                            "nome": "Campiglione-Fenile"
                        },
                        {
                            "code": "001050",
                            "cap": "10010",
                            "nome": "Candia Canavese"
                        },
                        {
                            "code": "001051",
                            "cap": "10060",
                            "nome": "Candiolo"
                        },
                        {
                            "code": "001052",
                            "cap": "10080",
                            "nome": "Canischio"
                        },
                        {
                            "code": "001053",
                            "cap": "10060",
                            "nome": "Cantalupa"
                        },
                        {
                            "code": "001054",
                            "cap": "10070",
                            "nome": "Cantoira"
                        },
                        {
                            "code": "001055",
                            "cap": "10040",
                            "nome": "Caprie"
                        },
                        {
                            "code": "001056",
                            "cap": "10010",
                            "nome": "Caravino"
                        },
                        {
                            "code": "001057",
                            "cap": "10010",
                            "nome": "Carema"
                        },
                        {
                            "code": "001058",
                            "cap": "10041",
                            "nome": "Carignano"
                        },
                        {
                            "code": "001059",
                            "cap": "10022",
                            "nome": "Carmagnola"
                        },
                        {
                            "code": "001060",
                            "cap": "10020",
                            "nome": "Casalborgone"
                        },
                        {
                            "code": "001061",
                            "cap": "10010",
                            "nome": "Cascinette d'Ivrea"
                        },
                        {
                            "code": "001062",
                            "cap": "10040",
                            "nome": "Caselette"
                        },
                        {
                            "code": "001063",
                            "cap": "10072",
                            "nome": "Caselle Torinese"
                        },
                        {
                            "code": "001064",
                            "cap": "10090",
                            "nome": "Castagneto Po"
                        },
                        {
                            "code": "001065",
                            "cap": "10060",
                            "nome": "Castagnole Piemonte"
                        },
                        {
                            "code": "001066",
                            "cap": "10081",
                            "nome": "Castellamonte"
                        },
                        {
                            "code": "001067",
                            "cap": "10080",
                            "nome": "Castelnuovo Nigra"
                        },
                        {
                            "code": "001068",
                            "cap": "10090",
                            "nome": "Castiglione Torinese"
                        },
                        {
                            "code": "001069",
                            "cap": "10020",
                            "nome": "Cavagnolo"
                        },
                        {
                            "code": "001070",
                            "cap": "10061",
                            "nome": "Cavour"
                        },
                        {
                            "code": "001071",
                            "cap": "10060",
                            "nome": "Cercenasco"
                        },
                        {
                            "code": "001072",
                            "cap": "10070",
                            "nome": "Ceres"
                        },
                        {
                            "code": "001073",
                            "cap": "10080",
                            "nome": "Ceresole Reale"
                        },
                        {
                            "code": "001074",
                            "cap": "10054",
                            "nome": "Cesana Torinese"
                        },
                        {
                            "code": "001075",
                            "cap": "10070",
                            "nome": "Chialamberto"
                        },
                        {
                            "code": "001076",
                            "cap": "10050",
                            "nome": "Chianocco"
                        },
                        {
                            "code": "001077",
                            "cap": "10010",
                            "nome": "Chiaverano"
                        },
                        {
                            "code": "001078",
                            "cap": "10023",
                            "nome": "Chieri"
                        },
                        {
                            "code": "001079",
                            "cap": "10080",
                            "nome": "Chiesanuova"
                        },
                        {
                            "code": "001080",
                            "cap": "10050",
                            "nome": "Chiomonte"
                        },
                        {
                            "code": "001081",
                            "cap": "10050",
                            "nome": "Chiusa di San Michele"
                        },
                        {
                            "code": "001082",
                            "cap": "10034",
                            "nome": "Chivasso"
                        },
                        {
                            "code": "001083",
                            "cap": "10080",
                            "nome": "Ciconio"
                        },
                        {
                            "code": "001084",
                            "cap": "10080",
                            "nome": "Cintano"
                        },
                        {
                            "code": "001085",
                            "cap": "10090",
                            "nome": "Cinzano"
                        },
                        {
                            "code": "001086",
                            "cap": "10073",
                            "nome": "Ciri\u00e8"
                        },
                        {
                            "code": "001087",
                            "cap": "10050",
                            "nome": "Claviere"
                        },
                        {
                            "code": "001088",
                            "cap": "10070",
                            "nome": "Coassolo Torinese"
                        },
                        {
                            "code": "001089",
                            "cap": "10050",
                            "nome": "Coazze"
                        },
                        {
                            "code": "001090",
                            "cap": "10093",
                            "nome": "Collegno"
                        },
                        {
                            "code": "001091",
                            "cap": "10080",
                            "nome": "Colleretto Castelnuovo"
                        },
                        {
                            "code": "001092",
                            "cap": "10010",
                            "nome": "Colleretto Giacosa"
                        },
                        {
                            "code": "001093",
                            "cap": "10055",
                            "nome": "Condove"
                        },
                        {
                            "code": "001094",
                            "cap": "10070",
                            "nome": "Corio"
                        },
                        {
                            "code": "001095",
                            "cap": "10010",
                            "nome": "Cossano Canavese"
                        },
                        {
                            "code": "001096",
                            "cap": "10090",
                            "nome": "Cuceglio"
                        },
                        {
                            "code": "001097",
                            "cap": "10040",
                            "nome": "Cumiana"
                        },
                        {
                            "code": "001098",
                            "cap": "10082",
                            "nome": "Cuorgn\u00e8"
                        },
                        {
                            "code": "001099",
                            "cap": "10040",
                            "nome": "Druento"
                        },
                        {
                            "code": "001100",
                            "cap": "10050",
                            "nome": "Exilles"
                        },
                        {
                            "code": "001101",
                            "cap": "10083",
                            "nome": "Favria"
                        },
                        {
                            "code": "001102",
                            "cap": "10080",
                            "nome": "Feletto"
                        },
                        {
                            "code": "001103",
                            "cap": "10060",
                            "nome": "Fenestrelle"
                        },
                        {
                            "code": "001104",
                            "cap": "10070",
                            "nome": "Fiano"
                        },
                        {
                            "code": "001105",
                            "cap": "10010",
                            "nome": "Fiorano Canavese"
                        },
                        {
                            "code": "001106",
                            "cap": "10090",
                            "nome": "Foglizzo"
                        },
                        {
                            "code": "001107",
                            "cap": "10084",
                            "nome": "Forno Canavese"
                        },
                        {
                            "code": "001108",
                            "cap": "10080",
                            "nome": "Frassinetto"
                        },
                        {
                            "code": "001109",
                            "cap": "10070",
                            "nome": "Front"
                        },
                        {
                            "code": "001110",
                            "cap": "10060",
                            "nome": "Frossasco"
                        },
                        {
                            "code": "001111",
                            "cap": "10060",
                            "nome": "Garzigliana"
                        },
                        {
                            "code": "001112",
                            "cap": "10090",
                            "nome": "Gassino Torinese"
                        },
                        {
                            "code": "001113",
                            "cap": "10070",
                            "nome": "Germagnano"
                        },
                        {
                            "code": "001114",
                            "cap": "10050",
                            "nome": "Giaglione"
                        },
                        {
                            "code": "001115",
                            "cap": "10094",
                            "nome": "Giaveno"
                        },
                        {
                            "code": "001116",
                            "cap": "10040",
                            "nome": "Givoletto"
                        },
                        {
                            "code": "001117",
                            "cap": "10050",
                            "nome": "Gravere"
                        },
                        {
                            "code": "001118",
                            "cap": "10070",
                            "nome": "Groscavallo"
                        },
                        {
                            "code": "001119",
                            "cap": "10070",
                            "nome": "Grosso"
                        },
                        {
                            "code": "001120",
                            "cap": "10095",
                            "nome": "Grugliasco"
                        },
                        {
                            "code": "001121",
                            "cap": "10080",
                            "nome": "Ingria"
                        },
                        {
                            "code": "001122",
                            "cap": "10060",
                            "nome": "Inverso Pinasca"
                        },
                        {
                            "code": "001123",
                            "cap": "10046",
                            "nome": "Isolabella"
                        },
                        {
                            "code": "001124",
                            "cap": "10080",
                            "nome": "Issiglio"
                        },
                        {
                            "code": "001125",
                            "cap": "10015",
                            "nome": "Ivrea"
                        },
                        {
                            "code": "001126",
                            "cap": "10040",
                            "nome": "La Cassa"
                        },
                        {
                            "code": "001127",
                            "cap": "10040",
                            "nome": "La Loggia"
                        },
                        {
                            "code": "001128",
                            "cap": "10074",
                            "nome": "Lanzo Torinese"
                        },
                        {
                            "code": "001129",
                            "cap": "10020",
                            "nome": "Lauriano"
                        },
                        {
                            "code": "001130",
                            "cap": "10040",
                            "nome": "Leini"
                        },
                        {
                            "code": "001131",
                            "cap": "10070",
                            "nome": "Lemie"
                        },
                        {
                            "code": "001132",
                            "cap": "10010",
                            "nome": "Lessolo"
                        },
                        {
                            "code": "001133",
                            "cap": "10070",
                            "nome": "Levone"
                        },
                        {
                            "code": "001134",
                            "cap": "10080",
                            "nome": "Locana"
                        },
                        {
                            "code": "001135",
                            "cap": "10040",
                            "nome": "Lombardore"
                        },
                        {
                            "code": "001136",
                            "cap": "10040",
                            "nome": "Lombriasco"
                        },
                        {
                            "code": "001137",
                            "cap": "10010",
                            "nome": "Loranz\u00e8"
                        },
                        {
                            "code": "001138",
                            "cap": "10080",
                            "nome": "Lugnacco"
                        },
                        {
                            "code": "001139",
                            "cap": "10062",
                            "nome": "Luserna San Giovanni"
                        },
                        {
                            "code": "001140",
                            "cap": "10060",
                            "nome": "Lusernetta"
                        },
                        {
                            "code": "001141",
                            "cap": "10080",
                            "nome": "Lusigli\u00e8"
                        },
                        {
                            "code": "001142",
                            "cap": "10060",
                            "nome": "Macello"
                        },
                        {
                            "code": "001143",
                            "cap": "10030",
                            "nome": "Maglione"
                        },
                        {
                            "code": "001144",
                            "cap": "10020",
                            "nome": "Marentino"
                        },
                        {
                            "code": "001145",
                            "cap": "10060",
                            "nome": "Massello"
                        },
                        {
                            "code": "001146",
                            "cap": "10075",
                            "nome": "Mathi"
                        },
                        {
                            "code": "001147",
                            "cap": "10050",
                            "nome": "Mattie"
                        },
                        {
                            "code": "001148",
                            "cap": "10035",
                            "nome": "Mazz\u00e8"
                        },
                        {
                            "code": "001149",
                            "cap": "10050",
                            "nome": "Meana di Susa"
                        },
                        {
                            "code": "001150",
                            "cap": "10010",
                            "nome": "Mercenasco"
                        },
                        {
                            "code": "001151",
                            "cap": "10080",
                            "nome": "Meugliano"
                        },
                        {
                            "code": "001152",
                            "cap": "10070",
                            "nome": "Mezzenile"
                        },
                        {
                            "code": "001153",
                            "cap": "10020",
                            "nome": "Mombello di Torino"
                        },
                        {
                            "code": "001154",
                            "cap": "10059",
                            "nome": "Mompantero"
                        },
                        {
                            "code": "001155",
                            "cap": "10070",
                            "nome": "Monastero di Lanzo"
                        },
                        {
                            "code": "001156",
                            "cap": "10024",
                            "nome": "Moncalieri"
                        },
                        {
                            "code": "001157",
                            "cap": "10050",
                            "nome": "Moncenisio"
                        },
                        {
                            "code": "001158",
                            "cap": "10020",
                            "nome": "Montaldo Torinese"
                        },
                        {
                            "code": "001159",
                            "cap": "10090",
                            "nome": "Montalenghe"
                        },
                        {
                            "code": "001160",
                            "cap": "10016",
                            "nome": "Montalto Dora"
                        },
                        {
                            "code": "001161",
                            "cap": "10017",
                            "nome": "Montanaro"
                        },
                        {
                            "code": "001162",
                            "cap": "10020",
                            "nome": "Monteu da Po"
                        },
                        {
                            "code": "001163",
                            "cap": "10020",
                            "nome": "Moriondo Torinese"
                        },
                        {
                            "code": "001164",
                            "cap": "10042",
                            "nome": "Nichelino"
                        },
                        {
                            "code": "001165",
                            "cap": "10080",
                            "nome": "Noasca"
                        },
                        {
                            "code": "001166",
                            "cap": "10076",
                            "nome": "Nole"
                        },
                        {
                            "code": "001167",
                            "cap": "10010",
                            "nome": "Nomaglio"
                        },
                        {
                            "code": "001168",
                            "cap": "10060",
                            "nome": "None"
                        },
                        {
                            "code": "001169",
                            "cap": "10050",
                            "nome": "Novalesa"
                        },
                        {
                            "code": "001170",
                            "cap": "10080",
                            "nome": "Oglianico"
                        },
                        {
                            "code": "001171",
                            "cap": "10043",
                            "nome": "Orbassano"
                        },
                        {
                            "code": "001172",
                            "cap": "10010",
                            "nome": "Orio Canavese"
                        },
                        {
                            "code": "001173",
                            "cap": "10060",
                            "nome": "Osasco"
                        },
                        {
                            "code": "001174",
                            "cap": "10040",
                            "nome": "Osasio"
                        },
                        {
                            "code": "001175",
                            "cap": "10056",
                            "nome": "Oulx"
                        },
                        {
                            "code": "001176",
                            "cap": "10080",
                            "nome": "Ozegna"
                        },
                        {
                            "code": "001177",
                            "cap": "10010",
                            "nome": "Palazzo Canavese"
                        },
                        {
                            "code": "001178",
                            "cap": "10060",
                            "nome": "Pancalieri"
                        },
                        {
                            "code": "001179",
                            "cap": "10010",
                            "nome": "Parella"
                        },
                        {
                            "code": "001180",
                            "cap": "10020",
                            "nome": "Pavarolo"
                        },
                        {
                            "code": "001181",
                            "cap": "10018",
                            "nome": "Pavone Canavese"
                        },
                        {
                            "code": "001182",
                            "cap": "10080",
                            "nome": "Pecco"
                        },
                        {
                            "code": "001183",
                            "cap": "10020",
                            "nome": "Pecetto Torinese"
                        },
                        {
                            "code": "001184",
                            "cap": "10063",
                            "nome": "Perosa Argentina"
                        },
                        {
                            "code": "001185",
                            "cap": "10010",
                            "nome": "Perosa Canavese"
                        },
                        {
                            "code": "001186",
                            "cap": "10060",
                            "nome": "Perrero"
                        },
                        {
                            "code": "001187",
                            "cap": "10080",
                            "nome": "Pertusio"
                        },
                        {
                            "code": "001188",
                            "cap": "10070",
                            "nome": "Pessinetto"
                        },
                        {
                            "code": "001189",
                            "cap": "10044",
                            "nome": "Pianezza"
                        },
                        {
                            "code": "001190",
                            "cap": "10060",
                            "nome": "Pinasca"
                        },
                        {
                            "code": "001191",
                            "cap": "10064",
                            "nome": "Pinerolo"
                        },
                        {
                            "code": "001192",
                            "cap": "10025",
                            "nome": "Pino Torinese"
                        },
                        {
                            "code": "001193",
                            "cap": "10040",
                            "nome": "Piobesi Torinese"
                        },
                        {
                            "code": "001194",
                            "cap": "10045",
                            "nome": "Piossasco"
                        },
                        {
                            "code": "001195",
                            "cap": "10060",
                            "nome": "Piscina"
                        },
                        {
                            "code": "001196",
                            "cap": "10010",
                            "nome": "Piverone"
                        },
                        {
                            "code": "001197",
                            "cap": "10046",
                            "nome": "Poirino"
                        },
                        {
                            "code": "001198",
                            "cap": "10063",
                            "nome": "Pomaretto"
                        },
                        {
                            "code": "001199",
                            "cap": "10085",
                            "nome": "Pont-Canavese"
                        },
                        {
                            "code": "001200",
                            "cap": "10060",
                            "nome": "Porte"
                        },
                        {
                            "code": "001201",
                            "cap": "10060",
                            "nome": "Pragelato"
                        },
                        {
                            "code": "001202",
                            "cap": "10060",
                            "nome": "Prali"
                        },
                        {
                            "code": "001203",
                            "cap": "10040",
                            "nome": "Pralormo"
                        },
                        {
                            "code": "001204",
                            "cap": "10065",
                            "nome": "Pramollo"
                        },
                        {
                            "code": "001205",
                            "cap": "10060",
                            "nome": "Prarostino"
                        },
                        {
                            "code": "001206",
                            "cap": "10080",
                            "nome": "Prascorsano"
                        },
                        {
                            "code": "001207",
                            "cap": "10080",
                            "nome": "Pratiglione"
                        },
                        {
                            "code": "001208",
                            "cap": "10010",
                            "nome": "Quagliuzzo"
                        },
                        {
                            "code": "001209",
                            "cap": "10010",
                            "nome": "Quassolo"
                        },
                        {
                            "code": "001210",
                            "cap": "10010",
                            "nome": "Quincinetto"
                        },
                        {
                            "code": "001211",
                            "cap": "10090",
                            "nome": "Reano"
                        },
                        {
                            "code": "001212",
                            "cap": "10080",
                            "nome": "Ribordone"
                        },
                        {
                            "code": "001215",
                            "cap": "10020",
                            "nome": "Riva Presso Chieri"
                        },
                        {
                            "code": "001213",
                            "cap": "10090",
                            "nome": "Rivalba"
                        },
                        {
                            "code": "001214",
                            "cap": "10040",
                            "nome": "Rivalta di Torino"
                        },
                        {
                            "code": "001216",
                            "cap": "10080",
                            "nome": "Rivara"
                        },
                        {
                            "code": "001217",
                            "cap": "10086",
                            "nome": "Rivarolo Canavese"
                        },
                        {
                            "code": "001218",
                            "cap": "10040",
                            "nome": "Rivarossa"
                        },
                        {
                            "code": "001219",
                            "cap": "10098",
                            "nome": "Rivoli"
                        },
                        {
                            "code": "001220",
                            "cap": "10070",
                            "nome": "Robassomero"
                        },
                        {
                            "code": "001221",
                            "cap": "10070",
                            "nome": "Rocca Canavese"
                        },
                        {
                            "code": "001222",
                            "cap": "10060",
                            "nome": "Roletto"
                        },
                        {
                            "code": "001223",
                            "cap": "10090",
                            "nome": "Romano Canavese"
                        },
                        {
                            "code": "001224",
                            "cap": "10080",
                            "nome": "Ronco Canavese"
                        },
                        {
                            "code": "001225",
                            "cap": "10030",
                            "nome": "Rondissone"
                        },
                        {
                            "code": "001226",
                            "cap": "10060",
                            "nome": "Ror\u00e0"
                        },
                        {
                            "code": "001228",
                            "cap": "10090",
                            "nome": "Rosta"
                        },
                        {
                            "code": "001227",
                            "cap": "10060",
                            "nome": "Roure"
                        },
                        {
                            "code": "001229",
                            "cap": "10040",
                            "nome": "Rubiana"
                        },
                        {
                            "code": "001230",
                            "cap": "10010",
                            "nome": "Rueglio"
                        },
                        {
                            "code": "001231",
                            "cap": "10080",
                            "nome": "Salassa"
                        },
                        {
                            "code": "001232",
                            "cap": "10050",
                            "nome": "Salbertrand"
                        },
                        {
                            "code": "001233",
                            "cap": "10010",
                            "nome": "Salerano Canavese"
                        },
                        {
                            "code": "001234",
                            "cap": "10060",
                            "nome": "Salza di Pinerolo"
                        },
                        {
                            "code": "001235",
                            "cap": "10010",
                            "nome": "Samone"
                        },
                        {
                            "code": "001236",
                            "cap": "10080",
                            "nome": "San Benigno Canavese"
                        },
                        {
                            "code": "001237",
                            "cap": "10070",
                            "nome": "San Carlo Canavese"
                        },
                        {
                            "code": "001238",
                            "cap": "10080",
                            "nome": "San Colombano Belmonte"
                        },
                        {
                            "code": "001239",
                            "cap": "10050",
                            "nome": "San Didero"
                        },
                        {
                            "code": "001240",
                            "cap": "10070",
                            "nome": "San Francesco al Campo"
                        },
                        {
                            "code": "001242",
                            "cap": "10065",
                            "nome": "San Germano Chisone"
                        },
                        {
                            "code": "001243",
                            "cap": "10040",
                            "nome": "San Gillio"
                        },
                        {
                            "code": "001244",
                            "cap": "10090",
                            "nome": "San Giorgio Canavese"
                        },
                        {
                            "code": "001245",
                            "cap": "10050",
                            "nome": "San Giorio di Susa"
                        },
                        {
                            "code": "001246",
                            "cap": "10090",
                            "nome": "San Giusto Canavese"
                        },
                        {
                            "code": "001247",
                            "cap": "10010",
                            "nome": "San Martino Canavese"
                        },
                        {
                            "code": "001248",
                            "cap": "10077",
                            "nome": "San Maurizio Canavese"
                        },
                        {
                            "code": "001249",
                            "cap": "10099",
                            "nome": "San Mauro Torinese"
                        },
                        {
                            "code": "001250",
                            "cap": "10060",
                            "nome": "San Pietro Val Lemina"
                        },
                        {
                            "code": "001251",
                            "cap": "10080",
                            "nome": "San Ponso"
                        },
                        {
                            "code": "001252",
                            "cap": "10090",
                            "nome": "San Raffaele Cimena"
                        },
                        {
                            "code": "001253",
                            "cap": "10020",
                            "nome": "San Sebastiano da Po"
                        },
                        {
                            "code": "001254",
                            "cap": "10060",
                            "nome": "San Secondo di Pinerolo"
                        },
                        {
                            "code": "001241",
                            "cap": "10090",
                            "nome": "Sangano"
                        },
                        {
                            "code": "001255",
                            "cap": "10057",
                            "nome": "Sant'Ambrogio di Torino"
                        },
                        {
                            "code": "001256",
                            "cap": "10050",
                            "nome": "Sant'Antonino di Susa"
                        },
                        {
                            "code": "001257",
                            "cap": "10026",
                            "nome": "Santena"
                        },
                        {
                            "code": "001258",
                            "cap": "10054",
                            "nome": "Sauze di Cesana"
                        },
                        {
                            "code": "001259",
                            "cap": "10050",
                            "nome": "Sauze d'Oulx"
                        },
                        {
                            "code": "001260",
                            "cap": "10060",
                            "nome": "Scalenghe"
                        },
                        {
                            "code": "001261",
                            "cap": "10010",
                            "nome": "Scarmagno"
                        },
                        {
                            "code": "001262",
                            "cap": "10090",
                            "nome": "Sciolze"
                        },
                        {
                            "code": "001263",
                            "cap": "10058",
                            "nome": "Sestriere"
                        },
                        {
                            "code": "001264",
                            "cap": "10010",
                            "nome": "Settimo Rottaro"
                        },
                        {
                            "code": "001265",
                            "cap": "10036",
                            "nome": "Settimo Torinese"
                        },
                        {
                            "code": "001266",
                            "cap": "10010",
                            "nome": "Settimo Vittone"
                        },
                        {
                            "code": "001267",
                            "cap": "10080",
                            "nome": "Sparone"
                        },
                        {
                            "code": "001268",
                            "cap": "10010",
                            "nome": "Strambinello"
                        },
                        {
                            "code": "001269",
                            "cap": "10019",
                            "nome": "Strambino"
                        },
                        {
                            "code": "001270",
                            "cap": "10059",
                            "nome": "Susa"
                        },
                        {
                            "code": "001271",
                            "cap": "10010",
                            "nome": "Tavagnasco"
                        },
                        {
                            "code": "001272",
                            "cap": "101xx",
                            "nome": "Torino"
                        },
                        {
                            "code": "001273",
                            "cap": "10037",
                            "nome": "Torrazza Piemonte"
                        },
                        {
                            "code": "001274",
                            "cap": "10010",
                            "nome": "Torre Canavese"
                        },
                        {
                            "code": "001275",
                            "cap": "10066",
                            "nome": "Torre Pellice"
                        },
                        {
                            "code": "001276",
                            "cap": "10090",
                            "nome": "Trana"
                        },
                        {
                            "code": "001277",
                            "cap": "10080",
                            "nome": "Trausella"
                        },
                        {
                            "code": "001278",
                            "cap": "10080",
                            "nome": "Traversella"
                        },
                        {
                            "code": "001279",
                            "cap": "10070",
                            "nome": "Traves"
                        },
                        {
                            "code": "001280",
                            "cap": "10028",
                            "nome": "Trofarello"
                        },
                        {
                            "code": "001281",
                            "cap": "10060",
                            "nome": "Usseaux"
                        },
                        {
                            "code": "001282",
                            "cap": "10070",
                            "nome": "Usseglio"
                        },
                        {
                            "code": "001283",
                            "cap": "10050",
                            "nome": "Vaie"
                        },
                        {
                            "code": "001284",
                            "cap": "10040",
                            "nome": "Val della Torre"
                        },
                        {
                            "code": "001285",
                            "cap": "10094",
                            "nome": "Valgioie"
                        },
                        {
                            "code": "001286",
                            "cap": "10070",
                            "nome": "Vallo Torinese"
                        },
                        {
                            "code": "001287",
                            "cap": "10087",
                            "nome": "Valperga"
                        },
                        {
                            "code": "001288",
                            "cap": "10080",
                            "nome": "Valprato Soana"
                        },
                        {
                            "code": "001289",
                            "cap": "10070",
                            "nome": "Varisella"
                        },
                        {
                            "code": "001290",
                            "cap": "10070",
                            "nome": "Vauda Canavese"
                        },
                        {
                            "code": "001292",
                            "cap": "10078",
                            "nome": "Venaria Reale"
                        },
                        {
                            "code": "001291",
                            "cap": "10050",
                            "nome": "Venaus"
                        },
                        {
                            "code": "001293",
                            "cap": "10038",
                            "nome": "Verolengo"
                        },
                        {
                            "code": "001294",
                            "cap": "10020",
                            "nome": "Verrua Savoia"
                        },
                        {
                            "code": "001295",
                            "cap": "10030",
                            "nome": "Vestign\u00e8"
                        },
                        {
                            "code": "001296",
                            "cap": "10090",
                            "nome": "Vialfr\u00e8"
                        },
                        {
                            "code": "001297",
                            "cap": "10080",
                            "nome": "Vico Canavese"
                        },
                        {
                            "code": "001298",
                            "cap": "10080",
                            "nome": "Vidracco"
                        },
                        {
                            "code": "001299",
                            "cap": "10067",
                            "nome": "Vigone"
                        },
                        {
                            "code": "001300",
                            "cap": "10068",
                            "nome": "Villafranca Piemonte"
                        },
                        {
                            "code": "001301",
                            "cap": "10070",
                            "nome": "Villanova Canavese"
                        },
                        {
                            "code": "001303",
                            "cap": "10040",
                            "nome": "Villar Dora"
                        },
                        {
                            "code": "001305",
                            "cap": "10050",
                            "nome": "Villar Focchiardo"
                        },
                        {
                            "code": "001306",
                            "cap": "10060",
                            "nome": "Villar Pellice"
                        },
                        {
                            "code": "001307",
                            "cap": "10069",
                            "nome": "Villar Perosa"
                        },
                        {
                            "code": "001302",
                            "cap": "10090",
                            "nome": "Villarbasse"
                        },
                        {
                            "code": "001304",
                            "cap": "10030",
                            "nome": "Villareggia"
                        },
                        {
                            "code": "001308",
                            "cap": "10029",
                            "nome": "Villastellone"
                        },
                        {
                            "code": "001309",
                            "cap": "10048",
                            "nome": "Vinovo"
                        },
                        {
                            "code": "001310",
                            "cap": "10060",
                            "nome": "Virle Piemonte"
                        },
                        {
                            "code": "001311",
                            "cap": "10030",
                            "nome": "Vische"
                        },
                        {
                            "code": "001312",
                            "cap": "10080",
                            "nome": "Vistrorio"
                        },
                        {
                            "code": "001313",
                            "cap": "10070",
                            "nome": "Vi\u00f9"
                        },
                        {
                            "code": "001314",
                            "cap": "10088",
                            "nome": "Volpiano"
                        },
                        {
                            "code": "001315",
                            "cap": "10040",
                            "nome": "Volvera"
                        }
                    ],
                    "nome": "Torino"
                },
                {
                    "code": "VB",
                    "comuni": [
                        {
                            "code": "103001",
                            "cap": "28841",
                            "nome": "Antrona Schieranco"
                        },
                        {
                            "code": "103002",
                            "cap": "28877",
                            "nome": "Anzola d'Ossola"
                        },
                        {
                            "code": "103003",
                            "cap": "28811",
                            "nome": "Arizzano"
                        },
                        {
                            "code": "103004",
                            "cap": "28899",
                            "nome": "Arola"
                        },
                        {
                            "code": "103005",
                            "cap": "28812",
                            "nome": "Aurano"
                        },
                        {
                            "code": "103006",
                            "cap": "28861",
                            "nome": "Baceno"
                        },
                        {
                            "code": "103007",
                            "cap": "28871",
                            "nome": "Bannio Anzino"
                        },
                        {
                            "code": "103008",
                            "cap": "28831",
                            "nome": "Baveno"
                        },
                        {
                            "code": "103009",
                            "cap": "28813",
                            "nome": "Bee"
                        },
                        {
                            "code": "103010",
                            "cap": "28832",
                            "nome": "Belgirate"
                        },
                        {
                            "code": "103011",
                            "cap": "28851",
                            "nome": "Beura-Cardezza"
                        },
                        {
                            "code": "103012",
                            "cap": "28842",
                            "nome": "Bognanco"
                        },
                        {
                            "code": "103013",
                            "cap": "28833",
                            "nome": "Brovello-Carpugnino"
                        },
                        {
                            "code": "103014",
                            "cap": "28873",
                            "nome": "Calasca-Castiglione"
                        },
                        {
                            "code": "103015",
                            "cap": "28814",
                            "nome": "Cambiasca"
                        },
                        {
                            "code": "103016",
                            "cap": "28821",
                            "nome": "Cannero Riviera"
                        },
                        {
                            "code": "103017",
                            "cap": "28822",
                            "nome": "Cannobio"
                        },
                        {
                            "code": "103018",
                            "cap": "28815",
                            "nome": "Caprezzo"
                        },
                        {
                            "code": "103019",
                            "cap": "28881",
                            "nome": "Casale Corte Cerro"
                        },
                        {
                            "code": "103020",
                            "cap": "28825",
                            "nome": "Cavaglio-Spoccia"
                        },
                        {
                            "code": "103021",
                            "cap": "28875",
                            "nome": "Ceppo Morelli"
                        },
                        {
                            "code": "103022",
                            "cap": "28891",
                            "nome": "Cesara"
                        },
                        {
                            "code": "103023",
                            "cap": "28801",
                            "nome": "Cossogno"
                        },
                        {
                            "code": "103024",
                            "cap": "28852",
                            "nome": "Craveggia"
                        },
                        {
                            "code": "103025",
                            "cap": "28865",
                            "nome": "Crevoladossola"
                        },
                        {
                            "code": "103026",
                            "cap": "28862",
                            "nome": "Crodo"
                        },
                        {
                            "code": "103027",
                            "cap": "28827",
                            "nome": "Cursolo-Orasso"
                        },
                        {
                            "code": "103028",
                            "cap": "28845",
                            "nome": "Domodossola"
                        },
                        {
                            "code": "103029",
                            "cap": "28853",
                            "nome": "Druogno"
                        },
                        {
                            "code": "103030",
                            "cap": "28827",
                            "nome": "Falmenta"
                        },
                        {
                            "code": "103031",
                            "cap": "28863",
                            "nome": "Formazza"
                        },
                        {
                            "code": "103032",
                            "cap": "28887",
                            "nome": "Germagno"
                        },
                        {
                            "code": "103033",
                            "cap": "28823",
                            "nome": "Ghiffa"
                        },
                        {
                            "code": "103034",
                            "cap": "28836",
                            "nome": "Gignese"
                        },
                        {
                            "code": "103035",
                            "cap": "28883",
                            "nome": "Gravellona Toce"
                        },
                        {
                            "code": "103036",
                            "cap": "28828",
                            "nome": "Gurro"
                        },
                        {
                            "code": "103037",
                            "cap": "28816",
                            "nome": "Intragna"
                        },
                        {
                            "code": "103038",
                            "cap": "28893",
                            "nome": "Loreglia"
                        },
                        {
                            "code": "103039",
                            "cap": "28876",
                            "nome": "Macugnaga"
                        },
                        {
                            "code": "103040",
                            "cap": "28894",
                            "nome": "Madonna del Sasso"
                        },
                        {
                            "code": "103041",
                            "cap": "28854",
                            "nome": "Malesco"
                        },
                        {
                            "code": "103042",
                            "cap": "28855",
                            "nome": "Masera"
                        },
                        {
                            "code": "103043",
                            "cap": "28895",
                            "nome": "Massiola"
                        },
                        {
                            "code": "103044",
                            "cap": "28802",
                            "nome": "Mergozzo"
                        },
                        {
                            "code": "103045",
                            "cap": "28817",
                            "nome": "Miazzina"
                        },
                        {
                            "code": "103046",
                            "cap": "28864",
                            "nome": "Montecrestese"
                        },
                        {
                            "code": "103047",
                            "cap": "28843",
                            "nome": "Montescheno"
                        },
                        {
                            "code": "103048",
                            "cap": "28891",
                            "nome": "Nonio"
                        },
                        {
                            "code": "103049",
                            "cap": "28824",
                            "nome": "Oggebbio"
                        },
                        {
                            "code": "103050",
                            "cap": "28887",
                            "nome": "Omegna"
                        },
                        {
                            "code": "103051",
                            "cap": "28877",
                            "nome": "Ornavasso"
                        },
                        {
                            "code": "103052",
                            "cap": "28884",
                            "nome": "Pallanzeno"
                        },
                        {
                            "code": "103053",
                            "cap": "28885",
                            "nome": "Piedimulera"
                        },
                        {
                            "code": "103054",
                            "cap": "28886",
                            "nome": "Pieve Vergonte"
                        },
                        {
                            "code": "103055",
                            "cap": "28818",
                            "nome": "Premeno"
                        },
                        {
                            "code": "103056",
                            "cap": "28866",
                            "nome": "Premia"
                        },
                        {
                            "code": "103057",
                            "cap": "28803",
                            "nome": "Premosello-Chiovenda"
                        },
                        {
                            "code": "103058",
                            "cap": "28898",
                            "nome": "Quarna Sopra"
                        },
                        {
                            "code": "103059",
                            "cap": "28896",
                            "nome": "Quarna Sotto"
                        },
                        {
                            "code": "103060",
                            "cap": "28856",
                            "nome": "Re"
                        },
                        {
                            "code": "103061",
                            "cap": "28804",
                            "nome": "San Bernardino Verbano"
                        },
                        {
                            "code": "103062",
                            "cap": "28857",
                            "nome": "Santa Maria Maggiore"
                        },
                        {
                            "code": "103063",
                            "cap": "28843",
                            "nome": "Seppiana"
                        },
                        {
                            "code": "103064",
                            "cap": "28838",
                            "nome": "Stresa"
                        },
                        {
                            "code": "103065",
                            "cap": "28858",
                            "nome": "Toceno"
                        },
                        {
                            "code": "103066",
                            "cap": "28826",
                            "nome": "Trarego Viggiona"
                        },
                        {
                            "code": "103067",
                            "cap": "28868",
                            "nome": "Trasquera"
                        },
                        {
                            "code": "103068",
                            "cap": "28859",
                            "nome": "Trontano"
                        },
                        {
                            "code": "103069",
                            "cap": "28897",
                            "nome": "Valstrona"
                        },
                        {
                            "code": "103070",
                            "cap": "28879",
                            "nome": "Vanzone con San Carlo"
                        },
                        {
                            "code": "103071",
                            "cap": "28868",
                            "nome": "Varzo"
                        },
                        {
                            "code": "103072",
                            "cap": "2892x",
                            "nome": "Verbania"
                        },
                        {
                            "code": "103073",
                            "cap": "28841",
                            "nome": "Viganella"
                        },
                        {
                            "code": "103074",
                            "cap": "28819",
                            "nome": "Vignone"
                        },
                        {
                            "code": "103075",
                            "cap": "28844",
                            "nome": "Villadossola"
                        },
                        {
                            "code": "103076",
                            "cap": "28856",
                            "nome": "Villette"
                        },
                        {
                            "code": "103077",
                            "cap": "28805",
                            "nome": "Vogogna"
                        }
                    ],
                    "nome": "Verbano Cusio Ossola"
                },
                {
                    "code": "VC",
                    "comuni": [
                        {
                            "code": "002002",
                            "cap": "13021",
                            "nome": "Alagna Valsesia"
                        },
                        {
                            "code": "002003",
                            "cap": "13030",
                            "nome": "Albano Vercellese"
                        },
                        {
                            "code": "002004",
                            "cap": "13040",
                            "nome": "Alice Castello"
                        },
                        {
                            "code": "002006",
                            "cap": "13031",
                            "nome": "Arborio"
                        },
                        {
                            "code": "002007",
                            "cap": "13032",
                            "nome": "Asigliano Vercellese"
                        },
                        {
                            "code": "002008",
                            "cap": "13020",
                            "nome": "Balmuccia"
                        },
                        {
                            "code": "002009",
                            "cap": "13040",
                            "nome": "Balocco"
                        },
                        {
                            "code": "002011",
                            "cap": "13041",
                            "nome": "Bianz\u00e8"
                        },
                        {
                            "code": "002014",
                            "cap": "13022",
                            "nome": "Boccioleto"
                        },
                        {
                            "code": "002015",
                            "cap": "13040",
                            "nome": "Borgo d'Ale"
                        },
                        {
                            "code": "002017",
                            "cap": "13012",
                            "nome": "Borgo Vercelli"
                        },
                        {
                            "code": "002016",
                            "cap": "13011",
                            "nome": "Borgosesia"
                        },
                        {
                            "code": "002019",
                            "cap": "13020",
                            "nome": "Breia"
                        },
                        {
                            "code": "002021",
                            "cap": "13040",
                            "nome": "Buronzo"
                        },
                        {
                            "code": "002025",
                            "cap": "13023",
                            "nome": "Campertogno"
                        },
                        {
                            "code": "002029",
                            "cap": "13026",
                            "nome": "Carcoforo"
                        },
                        {
                            "code": "002030",
                            "cap": "13010",
                            "nome": "Caresana"
                        },
                        {
                            "code": "002031",
                            "cap": "13030",
                            "nome": "Caresanablot"
                        },
                        {
                            "code": "002032",
                            "cap": "13040",
                            "nome": "Carisio"
                        },
                        {
                            "code": "002033",
                            "cap": "13030",
                            "nome": "Casanova Elvo"
                        },
                        {
                            "code": "002038",
                            "cap": "13024",
                            "nome": "Cellio"
                        },
                        {
                            "code": "002041",
                            "cap": "13025",
                            "nome": "Cervatto"
                        },
                        {
                            "code": "002042",
                            "cap": "13043",
                            "nome": "Cigliano"
                        },
                        {
                            "code": "002043",
                            "cap": "13010",
                            "nome": "Civiasco"
                        },
                        {
                            "code": "002045",
                            "cap": "13030",
                            "nome": "Collobiano"
                        },
                        {
                            "code": "002047",
                            "cap": "13033",
                            "nome": "Costanzana"
                        },
                        {
                            "code": "002048",
                            "cap": "13020",
                            "nome": "Cravagliana"
                        },
                        {
                            "code": "002049",
                            "cap": "13044",
                            "nome": "Crescentino"
                        },
                        {
                            "code": "002052",
                            "cap": "13040",
                            "nome": "Crova"
                        },
                        {
                            "code": "002054",
                            "cap": "13034",
                            "nome": "Desana"
                        },
                        {
                            "code": "002057",
                            "cap": "13025",
                            "nome": "Fobello"
                        },
                        {
                            "code": "002058",
                            "cap": "13040",
                            "nome": "Fontanetto Po"
                        },
                        {
                            "code": "002059",
                            "cap": "13030",
                            "nome": "Formigliana"
                        },
                        {
                            "code": "002061",
                            "cap": "13045",
                            "nome": "Gattinara"
                        },
                        {
                            "code": "002062",
                            "cap": "13030",
                            "nome": "Ghislarengo"
                        },
                        {
                            "code": "002065",
                            "cap": "13030",
                            "nome": "Greggio"
                        },
                        {
                            "code": "002066",
                            "cap": "13010",
                            "nome": "Guardabosone"
                        },
                        {
                            "code": "002067",
                            "cap": "13046",
                            "nome": "Lamporo"
                        },
                        {
                            "code": "002068",
                            "cap": "13035",
                            "nome": "Lenta"
                        },
                        {
                            "code": "002070",
                            "cap": "13034",
                            "nome": "Lignana"
                        },
                        {
                            "code": "002071",
                            "cap": "13046",
                            "nome": "Livorno Ferraris"
                        },
                        {
                            "code": "002072",
                            "cap": "13045",
                            "nome": "Lozzolo"
                        },
                        {
                            "code": "002078",
                            "cap": "13020",
                            "nome": "Mollia"
                        },
                        {
                            "code": "002079",
                            "cap": "13040",
                            "nome": "Moncrivello"
                        },
                        {
                            "code": "002082",
                            "cap": "13010",
                            "nome": "Motta de' Conti"
                        },
                        {
                            "code": "002088",
                            "cap": "13047",
                            "nome": "Olcenengo"
                        },
                        {
                            "code": "002089",
                            "cap": "13030",
                            "nome": "Oldenico"
                        },
                        {
                            "code": "002090",
                            "cap": "13040",
                            "nome": "Palazzolo Vercellese"
                        },
                        {
                            "code": "002091",
                            "cap": "13030",
                            "nome": "Pertengo"
                        },
                        {
                            "code": "002093",
                            "cap": "13010",
                            "nome": "Pezzana"
                        },
                        {
                            "code": "002096",
                            "cap": "13020",
                            "nome": "Pila"
                        },
                        {
                            "code": "002097",
                            "cap": "13020",
                            "nome": "Piode"
                        },
                        {
                            "code": "002102",
                            "cap": "13010",
                            "nome": "Postua"
                        },
                        {
                            "code": "002104",
                            "cap": "13012",
                            "nome": "Prarolo"
                        },
                        {
                            "code": "002107",
                            "cap": "13017",
                            "nome": "Quarona"
                        },
                        {
                            "code": "002108",
                            "cap": "13030",
                            "nome": "Quinto Vercellese"
                        },
                        {
                            "code": "002110",
                            "cap": "13020",
                            "nome": "Rassa"
                        },
                        {
                            "code": "002111",
                            "cap": "13026",
                            "nome": "Rima San Giuseppe"
                        },
                        {
                            "code": "002112",
                            "cap": "13026",
                            "nome": "Rimasco"
                        },
                        {
                            "code": "002113",
                            "cap": "13020",
                            "nome": "Rimella"
                        },
                        {
                            "code": "002114",
                            "cap": "13020",
                            "nome": "Riva Valdobbia"
                        },
                        {
                            "code": "002115",
                            "cap": "13030",
                            "nome": "Rive"
                        },
                        {
                            "code": "002116",
                            "cap": "13060",
                            "nome": "Roasio"
                        },
                        {
                            "code": "002118",
                            "cap": "13036",
                            "nome": "Ronsecco"
                        },
                        {
                            "code": "002121",
                            "cap": "13020",
                            "nome": "Rossa"
                        },
                        {
                            "code": "002122",
                            "cap": "13040",
                            "nome": "Rovasenda"
                        },
                        {
                            "code": "002123",
                            "cap": "13020",
                            "nome": "Sabbia"
                        },
                        {
                            "code": "002126",
                            "cap": "13040",
                            "nome": "Salasco"
                        },
                        {
                            "code": "002127",
                            "cap": "13040",
                            "nome": "Sali Vercellese"
                        },
                        {
                            "code": "002128",
                            "cap": "13040",
                            "nome": "Saluggia"
                        },
                        {
                            "code": "002131",
                            "cap": "13047",
                            "nome": "San Germano Vercellese"
                        },
                        {
                            "code": "002035",
                            "cap": "13030",
                            "nome": "San Giacomo Vercellese"
                        },
                        {
                            "code": "002133",
                            "cap": "13048",
                            "nome": "Santhi\u00e0"
                        },
                        {
                            "code": "002134",
                            "cap": "13027",
                            "nome": "Scopa"
                        },
                        {
                            "code": "002135",
                            "cap": "13028",
                            "nome": "Scopello"
                        },
                        {
                            "code": "002137",
                            "cap": "13037",
                            "nome": "Serravalle Sesia"
                        },
                        {
                            "code": "002142",
                            "cap": "13010",
                            "nome": "Stroppiana"
                        },
                        {
                            "code": "002147",
                            "cap": "13038",
                            "nome": "Tricerro"
                        },
                        {
                            "code": "002148",
                            "cap": "13039",
                            "nome": "Trino"
                        },
                        {
                            "code": "002150",
                            "cap": "13049",
                            "nome": "Tronzano Vercellese"
                        },
                        {
                            "code": "002152",
                            "cap": "13018",
                            "nome": "Valduggia"
                        },
                        {
                            "code": "002156",
                            "cap": "13019",
                            "nome": "Varallo"
                        },
                        {
                            "code": "002158",
                            "cap": "13100",
                            "nome": "Vercelli"
                        },
                        {
                            "code": "002163",
                            "cap": "13030",
                            "nome": "Villarboit"
                        },
                        {
                            "code": "002164",
                            "cap": "13010",
                            "nome": "Villata"
                        },
                        {
                            "code": "002166",
                            "cap": "13020",
                            "nome": "Vocca"
                        }
                    ],
                    "nome": "Vercelli"
                }
            ],
            "nome": "Piemonte"
        },
        {
            "province": [
                {
                    "code": "BA",
                    "comuni": [
                        {
                            "code": "072001",
                            "cap": "70021",
                            "nome": "Acquaviva delle Fonti"
                        },
                        {
                            "code": "072002",
                            "cap": "70010",
                            "nome": "Adelfia"
                        },
                        {
                            "code": "072003",
                            "cap": "70011",
                            "nome": "Alberobello"
                        },
                        {
                            "code": "072004",
                            "cap": "70022",
                            "nome": "Altamura"
                        },
                        {
                            "code": "072006",
                            "cap": "701xx",
                            "nome": "Bari"
                        },
                        {
                            "code": "072008",
                            "cap": "70020",
                            "nome": "Binetto"
                        },
                        {
                            "code": "072010",
                            "cap": "70020",
                            "nome": "Bitetto"
                        },
                        {
                            "code": "072011",
                            "cap": "70032",
                            "nome": "Bitonto"
                        },
                        {
                            "code": "072012",
                            "cap": "70020",
                            "nome": "Bitritto"
                        },
                        {
                            "code": "072014",
                            "cap": "70010",
                            "nome": "Capurso"
                        },
                        {
                            "code": "072015",
                            "cap": "70010",
                            "nome": "Casamassima"
                        },
                        {
                            "code": "072016",
                            "cap": "70020",
                            "nome": "Cassano delle Murge"
                        },
                        {
                            "code": "072017",
                            "cap": "70013",
                            "nome": "Castellana Grotte"
                        },
                        {
                            "code": "072018",
                            "cap": "70010",
                            "nome": "Cellamare"
                        },
                        {
                            "code": "072019",
                            "cap": "70014",
                            "nome": "Conversano"
                        },
                        {
                            "code": "072020",
                            "cap": "70033",
                            "nome": "Corato"
                        },
                        {
                            "code": "072021",
                            "cap": "70023",
                            "nome": "Gioia del Colle"
                        },
                        {
                            "code": "072022",
                            "cap": "70054",
                            "nome": "Giovinazzo"
                        },
                        {
                            "code": "072023",
                            "cap": "70024",
                            "nome": "Gravina in Puglia"
                        },
                        {
                            "code": "072024",
                            "cap": "70025",
                            "nome": "Grumo Appula"
                        },
                        {
                            "code": "072025",
                            "cap": "70010",
                            "nome": "Locorotondo"
                        },
                        {
                            "code": "072027",
                            "cap": "70026",
                            "nome": "Modugno"
                        },
                        {
                            "code": "072028",
                            "cap": "70042",
                            "nome": "Mola di Bari"
                        },
                        {
                            "code": "072029",
                            "cap": "70056",
                            "nome": "Molfetta"
                        },
                        {
                            "code": "072030",
                            "cap": "70043",
                            "nome": "Monopoli"
                        },
                        {
                            "code": "072031",
                            "cap": "70015",
                            "nome": "Noci"
                        },
                        {
                            "code": "072032",
                            "cap": "70016",
                            "nome": "Noic\u00e0ttaro"
                        },
                        {
                            "code": "072033",
                            "cap": "70027",
                            "nome": "Palo del Colle"
                        },
                        {
                            "code": "072034",
                            "cap": "70020",
                            "nome": "Poggiorsini"
                        },
                        {
                            "code": "072035",
                            "cap": "70044",
                            "nome": "Polignano a Mare"
                        },
                        {
                            "code": "072036",
                            "cap": "70017",
                            "nome": "Putignano"
                        },
                        {
                            "code": "072037",
                            "cap": "70018",
                            "nome": "Rutigliano"
                        },
                        {
                            "code": "072038",
                            "cap": "70037",
                            "nome": "Ruvo di Puglia"
                        },
                        {
                            "code": "072039",
                            "cap": "70010",
                            "nome": "Sammichele di Bari"
                        },
                        {
                            "code": "072040",
                            "cap": "70028",
                            "nome": "Sannicandro di Bari"
                        },
                        {
                            "code": "072041",
                            "cap": "70029",
                            "nome": "Santeramo in Colle"
                        },
                        {
                            "code": "072043",
                            "cap": "70038",
                            "nome": "Terlizzi"
                        },
                        {
                            "code": "072044",
                            "cap": "70020",
                            "nome": "Toritto"
                        },
                        {
                            "code": "072046",
                            "cap": "70019",
                            "nome": "Triggiano"
                        },
                        {
                            "code": "072047",
                            "cap": "70010",
                            "nome": "Turi"
                        },
                        {
                            "code": "072048",
                            "cap": "70010",
                            "nome": "Valenzano"
                        }
                    ],
                    "nome": "Bari"
                },
                {
                    "code": "BT",
                    "comuni": [
                        {
                            "code": "110001",
                            "cap": "76123",
                            "nome": "Andria"
                        },
                        {
                            "code": "110002",
                            "cap": "76121",
                            "nome": "Barletta"
                        },
                        {
                            "code": "110003",
                            "cap": "76011",
                            "nome": "Bisceglie"
                        },
                        {
                            "code": "110004",
                            "cap": "76012",
                            "nome": "Canosa di Puglia"
                        },
                        {
                            "code": "110005",
                            "cap": "76016",
                            "nome": "Margherita di Savoia"
                        },
                        {
                            "code": "110006",
                            "cap": "76013",
                            "nome": "Minervino Murge"
                        },
                        {
                            "code": "110007",
                            "cap": "76017",
                            "nome": "San Ferdinando di Puglia"
                        },
                        {
                            "code": "110008",
                            "cap": "76014",
                            "nome": "Spinazzola"
                        },
                        {
                            "code": "110009",
                            "cap": "76125",
                            "nome": "Trani"
                        },
                        {
                            "code": "110010",
                            "cap": "76015",
                            "nome": "Trinitapoli"
                        }
                    ],
                    "nome": "Barletta-Andria-Trani"
                },
                {
                    "code": "BR",
                    "comuni": [
                        {
                            "code": "074001",
                            "cap": "72100",
                            "nome": "Brindisi"
                        },
                        {
                            "code": "074002",
                            "cap": "72012",
                            "nome": "Carovigno"
                        },
                        {
                            "code": "074003",
                            "cap": "72013",
                            "nome": "Ceglie Messapica"
                        },
                        {
                            "code": "074004",
                            "cap": "72020",
                            "nome": "Cellino San Marco"
                        },
                        {
                            "code": "074005",
                            "cap": "72014",
                            "nome": "Cisternino"
                        },
                        {
                            "code": "074006",
                            "cap": "72020",
                            "nome": "Erchie"
                        },
                        {
                            "code": "074007",
                            "cap": "72015",
                            "nome": "Fasano"
                        },
                        {
                            "code": "074008",
                            "cap": "72021",
                            "nome": "Francavilla Fontana"
                        },
                        {
                            "code": "074009",
                            "cap": "72022",
                            "nome": "Latiano"
                        },
                        {
                            "code": "074010",
                            "cap": "72023",
                            "nome": "Mesagne"
                        },
                        {
                            "code": "074011",
                            "cap": "72024",
                            "nome": "Oria"
                        },
                        {
                            "code": "074012",
                            "cap": "72017",
                            "nome": "Ostuni"
                        },
                        {
                            "code": "074013",
                            "cap": "72025",
                            "nome": "San Donaci"
                        },
                        {
                            "code": "074014",
                            "cap": "72018",
                            "nome": "San Michele Salentino"
                        },
                        {
                            "code": "074015",
                            "cap": "72026",
                            "nome": "San Pancrazio Salentino"
                        },
                        {
                            "code": "074016",
                            "cap": "72027",
                            "nome": "San Pietro Vernotico"
                        },
                        {
                            "code": "074017",
                            "cap": "72019",
                            "nome": "San Vito dei Normanni"
                        },
                        {
                            "code": "074018",
                            "cap": "72020",
                            "nome": "Torchiarolo"
                        },
                        {
                            "code": "074019",
                            "cap": "72028",
                            "nome": "Torre Santa Susanna"
                        },
                        {
                            "code": "074020",
                            "cap": "72029",
                            "nome": "Villa Castelli"
                        }
                    ],
                    "nome": "Brindisi"
                },
                {
                    "code": "FG",
                    "comuni": [
                        {
                            "code": "071001",
                            "cap": "71021",
                            "nome": "Accadia"
                        },
                        {
                            "code": "071002",
                            "cap": "71031",
                            "nome": "Alberona"
                        },
                        {
                            "code": "071003",
                            "cap": "71020",
                            "nome": "Anzano di Puglia"
                        },
                        {
                            "code": "071004",
                            "cap": "71011",
                            "nome": "Apricena"
                        },
                        {
                            "code": "071005",
                            "cap": "71022",
                            "nome": "Ascoli Satriano"
                        },
                        {
                            "code": "071006",
                            "cap": "71032",
                            "nome": "Biccari"
                        },
                        {
                            "code": "071007",
                            "cap": "71023",
                            "nome": "Bovino"
                        },
                        {
                            "code": "071008",
                            "cap": "71010",
                            "nome": "Cagnano Varano"
                        },
                        {
                            "code": "071009",
                            "cap": "71024",
                            "nome": "Candela"
                        },
                        {
                            "code": "071010",
                            "cap": "71041",
                            "nome": "Carapelle"
                        },
                        {
                            "code": "071011",
                            "cap": "71030",
                            "nome": "Carlantino"
                        },
                        {
                            "code": "071012",
                            "cap": "71010",
                            "nome": "Carpino"
                        },
                        {
                            "code": "071013",
                            "cap": "71033",
                            "nome": "Casalnuovo Monterotaro"
                        },
                        {
                            "code": "071014",
                            "cap": "71030",
                            "nome": "Casalvecchio di Puglia"
                        },
                        {
                            "code": "071015",
                            "cap": "71025",
                            "nome": "Castelluccio dei Sauri"
                        },
                        {
                            "code": "071016",
                            "cap": "71020",
                            "nome": "Castelluccio Valmaggiore"
                        },
                        {
                            "code": "071017",
                            "cap": "71034",
                            "nome": "Castelnuovo della Daunia"
                        },
                        {
                            "code": "071018",
                            "cap": "71035",
                            "nome": "Celenza Valfortore"
                        },
                        {
                            "code": "071019",
                            "cap": "71020",
                            "nome": "Celle di San Vito"
                        },
                        {
                            "code": "071020",
                            "cap": "71042",
                            "nome": "Cerignola"
                        },
                        {
                            "code": "071021",
                            "cap": "71010",
                            "nome": "Chieuti"
                        },
                        {
                            "code": "071022",
                            "cap": "71026",
                            "nome": "Deliceto"
                        },
                        {
                            "code": "071023",
                            "cap": "71020",
                            "nome": "Faeto"
                        },
                        {
                            "code": "071024",
                            "cap": "7112x",
                            "nome": "Foggia"
                        },
                        {
                            "code": "071025",
                            "cap": "71010",
                            "nome": "Ischitella"
                        },
                        {
                            "code": "071026",
                            "cap": "71040",
                            "nome": "Isole Tremiti"
                        },
                        {
                            "code": "071027",
                            "cap": "71010",
                            "nome": "Lesina"
                        },
                        {
                            "code": "071028",
                            "cap": "71036",
                            "nome": "Lucera"
                        },
                        {
                            "code": "071029",
                            "cap": "71043",
                            "nome": "Manfredonia"
                        },
                        {
                            "code": "071031",
                            "cap": "71030",
                            "nome": "Mattinata"
                        },
                        {
                            "code": "071033",
                            "cap": "71037",
                            "nome": "Monte Sant'Angelo"
                        },
                        {
                            "code": "071032",
                            "cap": "71020",
                            "nome": "Monteleone di Puglia"
                        },
                        {
                            "code": "071034",
                            "cap": "71030",
                            "nome": "Motta Montecorvino"
                        },
                        {
                            "code": "071063",
                            "cap": "71040",
                            "nome": "Ordona"
                        },
                        {
                            "code": "071035",
                            "cap": "71027",
                            "nome": "Orsara di Puglia"
                        },
                        {
                            "code": "071036",
                            "cap": "71045",
                            "nome": "Orta Nova"
                        },
                        {
                            "code": "071037",
                            "cap": "71020",
                            "nome": "Panni"
                        },
                        {
                            "code": "071038",
                            "cap": "71010",
                            "nome": "Peschici"
                        },
                        {
                            "code": "071039",
                            "cap": "71038",
                            "nome": "Pietramontecorvino"
                        },
                        {
                            "code": "071040",
                            "cap": "71010",
                            "nome": "Poggio Imperiale"
                        },
                        {
                            "code": "071041",
                            "cap": "71010",
                            "nome": "Rignano Garganico"
                        },
                        {
                            "code": "071042",
                            "cap": "71020",
                            "nome": "Rocchetta Sant'Antonio"
                        },
                        {
                            "code": "071043",
                            "cap": "71012",
                            "nome": "Rodi Garganico"
                        },
                        {
                            "code": "071044",
                            "cap": "71039",
                            "nome": "Roseto Valfortore"
                        },
                        {
                            "code": "071046",
                            "cap": "71013",
                            "nome": "San Giovanni Rotondo"
                        },
                        {
                            "code": "071047",
                            "cap": "71014",
                            "nome": "San Marco in Lamis"
                        },
                        {
                            "code": "071048",
                            "cap": "71030",
                            "nome": "San Marco la Catola"
                        },
                        {
                            "code": "071049",
                            "cap": "71015",
                            "nome": "San Nicandro Garganico"
                        },
                        {
                            "code": "071050",
                            "cap": "71010",
                            "nome": "San Paolo di Civitate"
                        },
                        {
                            "code": "071051",
                            "cap": "71016",
                            "nome": "San Severo"
                        },
                        {
                            "code": "071052",
                            "cap": "71028",
                            "nome": "Sant'Agata di Puglia"
                        },
                        {
                            "code": "071053",
                            "cap": "71010",
                            "nome": "Serracapriola"
                        },
                        {
                            "code": "071054",
                            "cap": "71047",
                            "nome": "Stornara"
                        },
                        {
                            "code": "071055",
                            "cap": "71048",
                            "nome": "Stornarella"
                        },
                        {
                            "code": "071056",
                            "cap": "71017",
                            "nome": "Torremaggiore"
                        },
                        {
                            "code": "071058",
                            "cap": "71029",
                            "nome": "Troia"
                        },
                        {
                            "code": "071059",
                            "cap": "71018",
                            "nome": "Vico del Gargano"
                        },
                        {
                            "code": "071060",
                            "cap": "71019",
                            "nome": "Vieste"
                        },
                        {
                            "code": "071061",
                            "cap": "71030",
                            "nome": "Volturara Appula"
                        },
                        {
                            "code": "071062",
                            "cap": "71030",
                            "nome": "Volturino"
                        },
                        {
                            "code": "071064",
                            "cap": "71030",
                            "nome": "Zapponeta"
                        }
                    ],
                    "nome": "Foggia"
                },
                {
                    "code": "LE",
                    "comuni": [
                        {
                            "code": "075001",
                            "cap": "73040",
                            "nome": "Acquarica del Capo"
                        },
                        {
                            "code": "075002",
                            "cap": "73031",
                            "nome": "Alessano"
                        },
                        {
                            "code": "075003",
                            "cap": "73011",
                            "nome": "Alezio"
                        },
                        {
                            "code": "075004",
                            "cap": "73040",
                            "nome": "Alliste"
                        },
                        {
                            "code": "075005",
                            "cap": "73032",
                            "nome": "Andrano"
                        },
                        {
                            "code": "075006",
                            "cap": "73040",
                            "nome": "Aradeo"
                        },
                        {
                            "code": "075007",
                            "cap": "73010",
                            "nome": "Arnesano"
                        },
                        {
                            "code": "075008",
                            "cap": "73020",
                            "nome": "Bagnolo del Salento"
                        },
                        {
                            "code": "075009",
                            "cap": "73020",
                            "nome": "Botrugno"
                        },
                        {
                            "code": "075010",
                            "cap": "73021",
                            "nome": "Calimera"
                        },
                        {
                            "code": "075011",
                            "cap": "73012",
                            "nome": "Campi Salentina"
                        },
                        {
                            "code": "075012",
                            "cap": "73020",
                            "nome": "Cannole"
                        },
                        {
                            "code": "075013",
                            "cap": "73010",
                            "nome": "Caprarica di Lecce"
                        },
                        {
                            "code": "075014",
                            "cap": "73041",
                            "nome": "Carmiano"
                        },
                        {
                            "code": "075015",
                            "cap": "73020",
                            "nome": "Carpignano Salentino"
                        },
                        {
                            "code": "075016",
                            "cap": "73042",
                            "nome": "Casarano"
                        },
                        {
                            "code": "075017",
                            "cap": "73020",
                            "nome": "Castri di Lecce"
                        },
                        {
                            "code": "075018",
                            "cap": "73020",
                            "nome": "Castrignano de' Greci"
                        },
                        {
                            "code": "075019",
                            "cap": "73040",
                            "nome": "Castrignano del Capo"
                        },
                        {
                            "code": "075096",
                            "cap": "73030",
                            "nome": "Castro"
                        },
                        {
                            "code": "075020",
                            "cap": "73020",
                            "nome": "Cavallino"
                        },
                        {
                            "code": "075021",
                            "cap": "73040",
                            "nome": "Collepasso"
                        },
                        {
                            "code": "075022",
                            "cap": "73043",
                            "nome": "Copertino"
                        },
                        {
                            "code": "075023",
                            "cap": "73022",
                            "nome": "Corigliano d'Otranto"
                        },
                        {
                            "code": "075024",
                            "cap": "73033",
                            "nome": "Corsano"
                        },
                        {
                            "code": "075025",
                            "cap": "73020",
                            "nome": "Cursi"
                        },
                        {
                            "code": "075026",
                            "cap": "73020",
                            "nome": "Cutrofiano"
                        },
                        {
                            "code": "075027",
                            "cap": "73030",
                            "nome": "Diso"
                        },
                        {
                            "code": "075028",
                            "cap": "73034",
                            "nome": "Gagliano del Capo"
                        },
                        {
                            "code": "075029",
                            "cap": "73013",
                            "nome": "Galatina"
                        },
                        {
                            "code": "075030",
                            "cap": "73044",
                            "nome": "Galatone"
                        },
                        {
                            "code": "075031",
                            "cap": "73014",
                            "nome": "Gallipoli"
                        },
                        {
                            "code": "075032",
                            "cap": "73030",
                            "nome": "Giuggianello"
                        },
                        {
                            "code": "075033",
                            "cap": "73020",
                            "nome": "Giurdignano"
                        },
                        {
                            "code": "075034",
                            "cap": "73010",
                            "nome": "Guagnano"
                        },
                        {
                            "code": "075035",
                            "cap": "73100",
                            "nome": "Lecce"
                        },
                        {
                            "code": "075036",
                            "cap": "73010",
                            "nome": "Lequile"
                        },
                        {
                            "code": "075037",
                            "cap": "73045",
                            "nome": "Leverano"
                        },
                        {
                            "code": "075038",
                            "cap": "73023",
                            "nome": "Lizzanello"
                        },
                        {
                            "code": "075039",
                            "cap": "73024",
                            "nome": "Maglie"
                        },
                        {
                            "code": "075040",
                            "cap": "73025",
                            "nome": "Martano"
                        },
                        {
                            "code": "075041",
                            "cap": "73020",
                            "nome": "Martignano"
                        },
                        {
                            "code": "075042",
                            "cap": "73046",
                            "nome": "Matino"
                        },
                        {
                            "code": "075043",
                            "cap": "73026",
                            "nome": "Melendugno"
                        },
                        {
                            "code": "075044",
                            "cap": "73040",
                            "nome": "Melissano"
                        },
                        {
                            "code": "075045",
                            "cap": "73020",
                            "nome": "Melpignano"
                        },
                        {
                            "code": "075046",
                            "cap": "73035",
                            "nome": "Miggiano"
                        },
                        {
                            "code": "075047",
                            "cap": "73027",
                            "nome": "Minervino di Lecce"
                        },
                        {
                            "code": "075048",
                            "cap": "73047",
                            "nome": "Monteroni di Lecce"
                        },
                        {
                            "code": "075049",
                            "cap": "73030",
                            "nome": "Montesano Salentino"
                        },
                        {
                            "code": "075050",
                            "cap": "73040",
                            "nome": "Morciano di Leuca"
                        },
                        {
                            "code": "075051",
                            "cap": "73036",
                            "nome": "Muro Leccese"
                        },
                        {
                            "code": "075052",
                            "cap": "73048",
                            "nome": "Nard\u00f2"
                        },
                        {
                            "code": "075053",
                            "cap": "73040",
                            "nome": "Neviano"
                        },
                        {
                            "code": "075054",
                            "cap": "73020",
                            "nome": "Nociglia"
                        },
                        {
                            "code": "075055",
                            "cap": "73051",
                            "nome": "Novoli"
                        },
                        {
                            "code": "075056",
                            "cap": "73030",
                            "nome": "Ortelle"
                        },
                        {
                            "code": "075057",
                            "cap": "73028",
                            "nome": "Otranto"
                        },
                        {
                            "code": "075058",
                            "cap": "73020",
                            "nome": "Palmariggi"
                        },
                        {
                            "code": "075059",
                            "cap": "73052",
                            "nome": "Parabita"
                        },
                        {
                            "code": "075060",
                            "cap": "73053",
                            "nome": "Pat\u00f9"
                        },
                        {
                            "code": "075061",
                            "cap": "73037",
                            "nome": "Poggiardo"
                        },
                        {
                            "code": "075097",
                            "cap": "73010",
                            "nome": "Porto Cesareo"
                        },
                        {
                            "code": "075062",
                            "cap": "73054",
                            "nome": "Presicce"
                        },
                        {
                            "code": "075063",
                            "cap": "73055",
                            "nome": "Racale"
                        },
                        {
                            "code": "075064",
                            "cap": "73049",
                            "nome": "Ruffano"
                        },
                        {
                            "code": "075065",
                            "cap": "73015",
                            "nome": "Salice Salentino"
                        },
                        {
                            "code": "075066",
                            "cap": "73050",
                            "nome": "Salve"
                        },
                        {
                            "code": "075095",
                            "cap": "73020",
                            "nome": "San Cassiano"
                        },
                        {
                            "code": "075068",
                            "cap": "73016",
                            "nome": "San Cesario di Lecce"
                        },
                        {
                            "code": "075069",
                            "cap": "73010",
                            "nome": "San Donato di Lecce"
                        },
                        {
                            "code": "075071",
                            "cap": "73010",
                            "nome": "San Pietro in Lama"
                        },
                        {
                            "code": "075067",
                            "cap": "73030",
                            "nome": "Sanarica"
                        },
                        {
                            "code": "075070",
                            "cap": "73017",
                            "nome": "Sannicola"
                        },
                        {
                            "code": "075072",
                            "cap": "73020",
                            "nome": "Santa Cesarea Terme"
                        },
                        {
                            "code": "075073",
                            "cap": "73020",
                            "nome": "Scorrano"
                        },
                        {
                            "code": "075074",
                            "cap": "73050",
                            "nome": "Secl\u00ec"
                        },
                        {
                            "code": "075075",
                            "cap": "73010",
                            "nome": "Sogliano Cavour"
                        },
                        {
                            "code": "075076",
                            "cap": "73010",
                            "nome": "Soleto"
                        },
                        {
                            "code": "075077",
                            "cap": "73040",
                            "nome": "Specchia"
                        },
                        {
                            "code": "075078",
                            "cap": "73038",
                            "nome": "Spongano"
                        },
                        {
                            "code": "075079",
                            "cap": "73018",
                            "nome": "Squinzano"
                        },
                        {
                            "code": "075080",
                            "cap": "73010",
                            "nome": "Sternatia"
                        },
                        {
                            "code": "075081",
                            "cap": "73040",
                            "nome": "Supersano"
                        },
                        {
                            "code": "075082",
                            "cap": "73030",
                            "nome": "Surano"
                        },
                        {
                            "code": "075083",
                            "cap": "73010",
                            "nome": "Surbo"
                        },
                        {
                            "code": "075084",
                            "cap": "73056",
                            "nome": "Taurisano"
                        },
                        {
                            "code": "075085",
                            "cap": "73057",
                            "nome": "Taviano"
                        },
                        {
                            "code": "075086",
                            "cap": "73030",
                            "nome": "Tiggiano"
                        },
                        {
                            "code": "075087",
                            "cap": "73019",
                            "nome": "Trepuzzi"
                        },
                        {
                            "code": "075088",
                            "cap": "73039",
                            "nome": "Tricase"
                        },
                        {
                            "code": "075089",
                            "cap": "73058",
                            "nome": "Tuglie"
                        },
                        {
                            "code": "075090",
                            "cap": "73059",
                            "nome": "Ugento"
                        },
                        {
                            "code": "075091",
                            "cap": "73020",
                            "nome": "Uggiano La Chiesa"
                        },
                        {
                            "code": "075092",
                            "cap": "73010",
                            "nome": "Veglie"
                        },
                        {
                            "code": "075093",
                            "cap": "73029",
                            "nome": "Vernole"
                        },
                        {
                            "code": "075094",
                            "cap": "73010",
                            "nome": "Zollino"
                        }
                    ],
                    "nome": "Lecce"
                },
                {
                    "code": "TA",
                    "comuni": [
                        {
                            "code": "073001",
                            "cap": "74020",
                            "nome": "Avetrana"
                        },
                        {
                            "code": "073002",
                            "cap": "74021",
                            "nome": "Carosino"
                        },
                        {
                            "code": "073003",
                            "cap": "74011",
                            "nome": "Castellaneta"
                        },
                        {
                            "code": "073004",
                            "cap": "74012",
                            "nome": "Crispiano"
                        },
                        {
                            "code": "073005",
                            "cap": "74020",
                            "nome": "Faggiano"
                        },
                        {
                            "code": "073006",
                            "cap": "74022",
                            "nome": "Fragagnano"
                        },
                        {
                            "code": "073007",
                            "cap": "74013",
                            "nome": "Ginosa"
                        },
                        {
                            "code": "073008",
                            "cap": "74023",
                            "nome": "Grottaglie"
                        },
                        {
                            "code": "073009",
                            "cap": "74014",
                            "nome": "Laterza"
                        },
                        {
                            "code": "073010",
                            "cap": "74020",
                            "nome": "Leporano"
                        },
                        {
                            "code": "073011",
                            "cap": "74020",
                            "nome": "Lizzano"
                        },
                        {
                            "code": "073012",
                            "cap": "74024",
                            "nome": "Manduria"
                        },
                        {
                            "code": "073013",
                            "cap": "74015",
                            "nome": "Martina Franca"
                        },
                        {
                            "code": "073014",
                            "cap": "74020",
                            "nome": "Maruggio"
                        },
                        {
                            "code": "073015",
                            "cap": "74016",
                            "nome": "Massafra"
                        },
                        {
                            "code": "073016",
                            "cap": "74020",
                            "nome": "Monteiasi"
                        },
                        {
                            "code": "073017",
                            "cap": "74020",
                            "nome": "Montemesola"
                        },
                        {
                            "code": "073018",
                            "cap": "74020",
                            "nome": "Monteparano"
                        },
                        {
                            "code": "073019",
                            "cap": "74017",
                            "nome": "Mottola"
                        },
                        {
                            "code": "073020",
                            "cap": "74018",
                            "nome": "Palagianello"
                        },
                        {
                            "code": "073021",
                            "cap": "74019",
                            "nome": "Palagiano"
                        },
                        {
                            "code": "073022",
                            "cap": "74026",
                            "nome": "Pulsano"
                        },
                        {
                            "code": "073023",
                            "cap": "74020",
                            "nome": "Roccaforzata"
                        },
                        {
                            "code": "073024",
                            "cap": "74027",
                            "nome": "San Giorgio Ionico"
                        },
                        {
                            "code": "073025",
                            "cap": "74020",
                            "nome": "San Marzano di San Giuseppe"
                        },
                        {
                            "code": "073026",
                            "cap": "74028",
                            "nome": "Sava"
                        },
                        {
                            "code": "073029",
                            "cap": "74010",
                            "nome": "Statte"
                        },
                        {
                            "code": "073027",
                            "cap": "7412x",
                            "nome": "Taranto"
                        },
                        {
                            "code": "073028",
                            "cap": "74020",
                            "nome": "Torricella"
                        }
                    ],
                    "nome": "Taranto"
                }
            ],
            "nome": "Puglia"
        },
        {
            "province": [
                {
                    "code": "CA",
                    "comuni": [
                        {
                            "code": "092002",
                            "cap": "09040",
                            "nome": "Armungia"
                        },
                        {
                            "code": "092003",
                            "cap": "09032",
                            "nome": "Assemini"
                        },
                        {
                            "code": "092004",
                            "cap": "09040",
                            "nome": "Ballao"
                        },
                        {
                            "code": "092005",
                            "cap": "09040",
                            "nome": "Barrali"
                        },
                        {
                            "code": "092008",
                            "cap": "09040",
                            "nome": "Burcei"
                        },
                        {
                            "code": "092009",
                            "cap": "091xx",
                            "nome": "Cagliari"
                        },
                        {
                            "code": "092011",
                            "cap": "09012",
                            "nome": "Capoterra"
                        },
                        {
                            "code": "092106",
                            "cap": "09040",
                            "nome": "Castiadas"
                        },
                        {
                            "code": "092015",
                            "cap": "09033",
                            "nome": "Decimomannu"
                        },
                        {
                            "code": "092016",
                            "cap": "09010",
                            "nome": "Decimoputzu"
                        },
                        {
                            "code": "092017",
                            "cap": "09041",
                            "nome": "Dolianova"
                        },
                        {
                            "code": "092018",
                            "cap": "09010",
                            "nome": "Domus De Maria"
                        },
                        {
                            "code": "092020",
                            "cap": "09040",
                            "nome": "Donori"
                        },
                        {
                            "code": "092108",
                            "cap": "09030",
                            "nome": "Elmas"
                        },
                        {
                            "code": "092110",
                            "cap": "08043",
                            "nome": "Escalaplano"
                        },
                        {
                            "code": "092111",
                            "cap": "08030",
                            "nome": "Escolca"
                        },
                        {
                            "code": "092112",
                            "cap": "08030",
                            "nome": "Esterzili"
                        },
                        {
                            "code": "092113",
                            "cap": "08030",
                            "nome": "Gergei"
                        },
                        {
                            "code": "092024",
                            "cap": "09040",
                            "nome": "Gesico"
                        },
                        {
                            "code": "092027",
                            "cap": "09040",
                            "nome": "Goni"
                        },
                        {
                            "code": "092030",
                            "cap": "09040",
                            "nome": "Guamaggiore"
                        },
                        {
                            "code": "092031",
                            "cap": "09040",
                            "nome": "Guasila"
                        },
                        {
                            "code": "092114",
                            "cap": "08033",
                            "nome": "Isili"
                        },
                        {
                            "code": "092036",
                            "cap": "09040",
                            "nome": "Mandas"
                        },
                        {
                            "code": "092037",
                            "cap": "09040",
                            "nome": "Maracalagonis"
                        },
                        {
                            "code": "092038",
                            "cap": "09023",
                            "nome": "Monastir"
                        },
                        {
                            "code": "092109",
                            "cap": "09042",
                            "nome": "Monserrato"
                        },
                        {
                            "code": "092039",
                            "cap": "09043",
                            "nome": "Muravera"
                        },
                        {
                            "code": "092115",
                            "cap": "08030",
                            "nome": "Nuragus"
                        },
                        {
                            "code": "092116",
                            "cap": "08030",
                            "nome": "Nurallao"
                        },
                        {
                            "code": "092042",
                            "cap": "09024",
                            "nome": "Nuraminis"
                        },
                        {
                            "code": "092117",
                            "cap": "08035",
                            "nome": "Nurri"
                        },
                        {
                            "code": "092118",
                            "cap": "08030",
                            "nome": "Orroli"
                        },
                        {
                            "code": "092044",
                            "cap": "09040",
                            "nome": "Ortacesus"
                        },
                        {
                            "code": "092048",
                            "cap": "09020",
                            "nome": "Pimentel"
                        },
                        {
                            "code": "092050",
                            "cap": "09010",
                            "nome": "Pula"
                        },
                        {
                            "code": "092051",
                            "cap": "09045",
                            "nome": "Quartu Sant'Elena"
                        },
                        {
                            "code": "092105",
                            "cap": "09044",
                            "nome": "Quartucciu"
                        },
                        {
                            "code": "092119",
                            "cap": "08030",
                            "nome": "Sadali"
                        },
                        {
                            "code": "092053",
                            "cap": "09020",
                            "nome": "Samatzai"
                        },
                        {
                            "code": "092054",
                            "cap": "09040",
                            "nome": "San Basilio"
                        },
                        {
                            "code": "092058",
                            "cap": "09040",
                            "nome": "San Nicol\u00f2 Gerrei"
                        },
                        {
                            "code": "092059",
                            "cap": "09026",
                            "nome": "San Sperate"
                        },
                        {
                            "code": "092064",
                            "cap": "09040",
                            "nome": "San Vito"
                        },
                        {
                            "code": "092061",
                            "cap": "09040",
                            "nome": "Sant'Andrea Frius"
                        },
                        {
                            "code": "092066",
                            "cap": "09018",
                            "nome": "Sarroch"
                        },
                        {
                            "code": "092068",
                            "cap": "09047",
                            "nome": "Selargius"
                        },
                        {
                            "code": "092069",
                            "cap": "09040",
                            "nome": "Selegas"
                        },
                        {
                            "code": "092070",
                            "cap": "09040",
                            "nome": "Senorb\u00ec"
                        },
                        {
                            "code": "092071",
                            "cap": "09040",
                            "nome": "Serdiana"
                        },
                        {
                            "code": "092120",
                            "cap": "08030",
                            "nome": "Serri"
                        },
                        {
                            "code": "092074",
                            "cap": "09028",
                            "nome": "Sestu"
                        },
                        {
                            "code": "092075",
                            "cap": "09040",
                            "nome": "Settimo San Pietro"
                        },
                        {
                            "code": "092121",
                            "cap": "08030",
                            "nome": "Seulo"
                        },
                        {
                            "code": "092078",
                            "cap": "09010",
                            "nome": "Siliqua"
                        },
                        {
                            "code": "092079",
                            "cap": "09040",
                            "nome": "Silius"
                        },
                        {
                            "code": "092080",
                            "cap": "09048",
                            "nome": "Sinnai"
                        },
                        {
                            "code": "092081",
                            "cap": "09040",
                            "nome": "Siurgus Donigala"
                        },
                        {
                            "code": "092082",
                            "cap": "09040",
                            "nome": "Soleminis"
                        },
                        {
                            "code": "092083",
                            "cap": "09040",
                            "nome": "Suelli"
                        },
                        {
                            "code": "092084",
                            "cap": "09019",
                            "nome": "Teulada"
                        },
                        {
                            "code": "092088",
                            "cap": "09020",
                            "nome": "Ussana"
                        },
                        {
                            "code": "092090",
                            "cap": "09010",
                            "nome": "Uta"
                        },
                        {
                            "code": "092091",
                            "cap": "09010",
                            "nome": "Vallermosa"
                        },
                        {
                            "code": "092099",
                            "cap": "09010",
                            "nome": "Villa San Pietro"
                        },
                        {
                            "code": "092122",
                            "cap": "08030",
                            "nome": "Villanova Tulo"
                        },
                        {
                            "code": "092097",
                            "cap": "09040",
                            "nome": "Villaputzu"
                        },
                        {
                            "code": "092098",
                            "cap": "09040",
                            "nome": "Villasalto"
                        },
                        {
                            "code": "092100",
                            "cap": "09049",
                            "nome": "Villasimius"
                        },
                        {
                            "code": "092101",
                            "cap": "09034",
                            "nome": "Villasor"
                        },
                        {
                            "code": "092102",
                            "cap": "09010",
                            "nome": "Villaspeciosa"
                        }
                    ],
                    "nome": "Cagliari"
                },
                {
                    "code": "CI",
                    "comuni": [
                        {
                            "code": "107001",
                            "cap": "09010",
                            "nome": "Buggerru"
                        },
                        {
                            "code": "107002",
                            "cap": "09011",
                            "nome": "Calasetta"
                        },
                        {
                            "code": "107003",
                            "cap": "09013",
                            "nome": "Carbonia"
                        },
                        {
                            "code": "107004",
                            "cap": "09014",
                            "nome": "Carloforte"
                        },
                        {
                            "code": "107005",
                            "cap": "09015",
                            "nome": "Domusnovas"
                        },
                        {
                            "code": "107006",
                            "cap": "09010",
                            "nome": "Fluminimaggiore"
                        },
                        {
                            "code": "107007",
                            "cap": "09010",
                            "nome": "Giba"
                        },
                        {
                            "code": "107008",
                            "cap": "09010",
                            "nome": "Gonnesa"
                        },
                        {
                            "code": "107009",
                            "cap": "09016",
                            "nome": "Iglesias"
                        },
                        {
                            "code": "107010",
                            "cap": "09010",
                            "nome": "Masainas"
                        },
                        {
                            "code": "107011",
                            "cap": "09010",
                            "nome": "Musei"
                        },
                        {
                            "code": "107012",
                            "cap": "09010",
                            "nome": "Narcao"
                        },
                        {
                            "code": "107013",
                            "cap": "09010",
                            "nome": "Nuxis"
                        },
                        {
                            "code": "107014",
                            "cap": "09010",
                            "nome": "Perdaxius"
                        },
                        {
                            "code": "107015",
                            "cap": "09010",
                            "nome": "Piscinas"
                        },
                        {
                            "code": "107016",
                            "cap": "09010",
                            "nome": "Portoscuso"
                        },
                        {
                            "code": "107017",
                            "cap": "09010",
                            "nome": "San Giovanni Suergiu"
                        },
                        {
                            "code": "107018",
                            "cap": "09010",
                            "nome": "Santadi"
                        },
                        {
                            "code": "107019",
                            "cap": "09010",
                            "nome": "Sant'Anna Arresi"
                        },
                        {
                            "code": "107020",
                            "cap": "09017",
                            "nome": "Sant'Antioco"
                        },
                        {
                            "code": "107021",
                            "cap": "09010",
                            "nome": "Tratalias"
                        },
                        {
                            "code": "107022",
                            "cap": "09010",
                            "nome": "Villamassargia"
                        },
                        {
                            "code": "107023",
                            "cap": "09010",
                            "nome": "Villaperuccio"
                        }
                    ],
                    "nome": "Carbonia-Iglesias"
                },
                {
                    "code": "VS",
                    "comuni": [
                        {
                            "code": "106001",
                            "cap": "09031",
                            "nome": "Arbus"
                        },
                        {
                            "code": "106002",
                            "cap": "09021",
                            "nome": "Barumini"
                        },
                        {
                            "code": "106003",
                            "cap": "09020",
                            "nome": "Collinas"
                        },
                        {
                            "code": "106004",
                            "cap": "09040",
                            "nome": "Furtei"
                        },
                        {
                            "code": "106005",
                            "cap": "09020",
                            "nome": "Genuri"
                        },
                        {
                            "code": "106006",
                            "cap": "09020",
                            "nome": "Gesturi"
                        },
                        {
                            "code": "106007",
                            "cap": "09035",
                            "nome": "Gonnosfanadiga"
                        },
                        {
                            "code": "106008",
                            "cap": "09036",
                            "nome": "Guspini"
                        },
                        {
                            "code": "106009",
                            "cap": "09020",
                            "nome": "Las Plassas"
                        },
                        {
                            "code": "106010",
                            "cap": "09022",
                            "nome": "Lunamatrona"
                        },
                        {
                            "code": "106011",
                            "cap": "09030",
                            "nome": "Pabillonis"
                        },
                        {
                            "code": "106012",
                            "cap": "09020",
                            "nome": "Pauli Arbarei"
                        },
                        {
                            "code": "106013",
                            "cap": "09030",
                            "nome": "Samassi"
                        },
                        {
                            "code": "106014",
                            "cap": "09037",
                            "nome": "San Gavino Monreale"
                        },
                        {
                            "code": "106015",
                            "cap": "09025",
                            "nome": "Sanluri"
                        },
                        {
                            "code": "106016",
                            "cap": "09030",
                            "nome": "Sardara"
                        },
                        {
                            "code": "106017",
                            "cap": "09040",
                            "nome": "Segariu"
                        },
                        {
                            "code": "106018",
                            "cap": "09038",
                            "nome": "Serramanna"
                        },
                        {
                            "code": "106019",
                            "cap": "09027",
                            "nome": "Serrenti"
                        },
                        {
                            "code": "106020",
                            "cap": "09029",
                            "nome": "Setzu"
                        },
                        {
                            "code": "106021",
                            "cap": "09020",
                            "nome": "Siddi"
                        },
                        {
                            "code": "106022",
                            "cap": "09029",
                            "nome": "Tuili"
                        },
                        {
                            "code": "106023",
                            "cap": "09020",
                            "nome": "Turri"
                        },
                        {
                            "code": "106024",
                            "cap": "09020",
                            "nome": "Ussaramanna"
                        },
                        {
                            "code": "106025",
                            "cap": "09039",
                            "nome": "Villacidro"
                        },
                        {
                            "code": "106026",
                            "cap": "09020",
                            "nome": "Villamar"
                        },
                        {
                            "code": "106027",
                            "cap": "09020",
                            "nome": "Villanovaforru"
                        },
                        {
                            "code": "106028",
                            "cap": "09020",
                            "nome": "Villanovafranca"
                        }
                    ],
                    "nome": "Medio Campidano"
                },
                {
                    "code": "NU",
                    "comuni": [
                        {
                            "code": "091001",
                            "cap": "08031",
                            "nome": "Aritzo"
                        },
                        {
                            "code": "091003",
                            "cap": "08030",
                            "nome": "Atzara"
                        },
                        {
                            "code": "091004",
                            "cap": "08030",
                            "nome": "Austis"
                        },
                        {
                            "code": "091007",
                            "cap": "08030",
                            "nome": "Belv\u00ec"
                        },
                        {
                            "code": "091008",
                            "cap": "08010",
                            "nome": "Birori"
                        },
                        {
                            "code": "091009",
                            "cap": "08021",
                            "nome": "Bitti"
                        },
                        {
                            "code": "091010",
                            "cap": "08011",
                            "nome": "Bolotana"
                        },
                        {
                            "code": "091011",
                            "cap": "08016",
                            "nome": "Borore"
                        },
                        {
                            "code": "091012",
                            "cap": "08012",
                            "nome": "Bortigali"
                        },
                        {
                            "code": "091016",
                            "cap": "08032",
                            "nome": "Desulo"
                        },
                        {
                            "code": "091017",
                            "cap": "08022",
                            "nome": "Dorgali"
                        },
                        {
                            "code": "091018",
                            "cap": "08010",
                            "nome": "Dualchi"
                        },
                        {
                            "code": "091024",
                            "cap": "08023",
                            "nome": "Fonni"
                        },
                        {
                            "code": "091025",
                            "cap": "08030",
                            "nome": "Gadoni"
                        },
                        {
                            "code": "091027",
                            "cap": "08020",
                            "nome": "Galtell\u00ec"
                        },
                        {
                            "code": "091028",
                            "cap": "08020",
                            "nome": "Gavoi"
                        },
                        {
                            "code": "091033",
                            "cap": "08020",
                            "nome": "Irgoli"
                        },
                        {
                            "code": "091038",
                            "cap": "08010",
                            "nome": "Lei"
                        },
                        {
                            "code": "091040",
                            "cap": "08020",
                            "nome": "Loculi"
                        },
                        {
                            "code": "091041",
                            "cap": "08020",
                            "nome": "Lod\u00e8"
                        },
                        {
                            "code": "091104",
                            "cap": "08020",
                            "nome": "Lodine"
                        },
                        {
                            "code": "091043",
                            "cap": "08020",
                            "nome": "Lula"
                        },
                        {
                            "code": "091044",
                            "cap": "08015",
                            "nome": "Macomer"
                        },
                        {
                            "code": "091046",
                            "cap": "08024",
                            "nome": "Mamoiada"
                        },
                        {
                            "code": "091047",
                            "cap": "08030",
                            "nome": "Meana Sardo"
                        },
                        {
                            "code": "091050",
                            "cap": "08010",
                            "nome": "Noragugume"
                        },
                        {
                            "code": "091051",
                            "cap": "08100",
                            "nome": "Nuoro"
                        },
                        {
                            "code": "091055",
                            "cap": "08025",
                            "nome": "Oliena"
                        },
                        {
                            "code": "091056",
                            "cap": "08020",
                            "nome": "Ollolai"
                        },
                        {
                            "code": "091057",
                            "cap": "08020",
                            "nome": "Olzai"
                        },
                        {
                            "code": "091058",
                            "cap": "08020",
                            "nome": "Onan\u00ec"
                        },
                        {
                            "code": "091059",
                            "cap": "08020",
                            "nome": "Onifai"
                        },
                        {
                            "code": "091060",
                            "cap": "08020",
                            "nome": "Oniferi"
                        },
                        {
                            "code": "091061",
                            "cap": "08026",
                            "nome": "Orani"
                        },
                        {
                            "code": "091062",
                            "cap": "08027",
                            "nome": "Orgosolo"
                        },
                        {
                            "code": "091063",
                            "cap": "08028",
                            "nome": "Orosei"
                        },
                        {
                            "code": "091064",
                            "cap": "08020",
                            "nome": "Orotelli"
                        },
                        {
                            "code": "091066",
                            "cap": "08036",
                            "nome": "Ortueri"
                        },
                        {
                            "code": "091067",
                            "cap": "08020",
                            "nome": "Orune"
                        },
                        {
                            "code": "091068",
                            "cap": "08020",
                            "nome": "Osidda"
                        },
                        {
                            "code": "091070",
                            "cap": "08020",
                            "nome": "Ottana"
                        },
                        {
                            "code": "091071",
                            "cap": "08020",
                            "nome": "Ovodda"
                        },
                        {
                            "code": "091073",
                            "cap": "08020",
                            "nome": "Posada"
                        },
                        {
                            "code": "091077",
                            "cap": "08020",
                            "nome": "Sarule"
                        },
                        {
                            "code": "091083",
                            "cap": "08017",
                            "nome": "Silanus"
                        },
                        {
                            "code": "091084",
                            "cap": "08018",
                            "nome": "Sindia"
                        },
                        {
                            "code": "091085",
                            "cap": "08029",
                            "nome": "Siniscola"
                        },
                        {
                            "code": "091086",
                            "cap": "08038",
                            "nome": "Sorgono"
                        },
                        {
                            "code": "091090",
                            "cap": "08030",
                            "nome": "Teti"
                        },
                        {
                            "code": "091091",
                            "cap": "08020",
                            "nome": "Tiana"
                        },
                        {
                            "code": "091093",
                            "cap": "08039",
                            "nome": "Tonara"
                        },
                        {
                            "code": "091094",
                            "cap": "08020",
                            "nome": "Torp\u00e8"
                        }
                    ],
                    "nome": "Nuoro"
                },
                {
                    "code": "OG",
                    "comuni": [
                        {
                            "code": "105001",
                            "cap": "08040",
                            "nome": "Arzana"
                        },
                        {
                            "code": "105002",
                            "cap": "08042",
                            "nome": "Bari Sardo"
                        },
                        {
                            "code": "105003",
                            "cap": "08040",
                            "nome": "Baunei"
                        },
                        {
                            "code": "105004",
                            "cap": "08040",
                            "nome": "Cardedu"
                        },
                        {
                            "code": "105005",
                            "cap": "08040",
                            "nome": "Elini"
                        },
                        {
                            "code": "105006",
                            "cap": "08040",
                            "nome": "Gairo"
                        },
                        {
                            "code": "105007",
                            "cap": "08040",
                            "nome": "Girasole"
                        },
                        {
                            "code": "105008",
                            "cap": "08040",
                            "nome": "Ilbono"
                        },
                        {
                            "code": "105009",
                            "cap": "08044",
                            "nome": "Jerzu"
                        },
                        {
                            "code": "105010",
                            "cap": "08045",
                            "nome": "Lanusei"
                        },
                        {
                            "code": "105011",
                            "cap": "08040",
                            "nome": "Loceri"
                        },
                        {
                            "code": "105012",
                            "cap": "08040",
                            "nome": "Lotzorai"
                        },
                        {
                            "code": "105013",
                            "cap": "08040",
                            "nome": "Osini"
                        },
                        {
                            "code": "105014",
                            "cap": "08046",
                            "nome": "Perdasdefogu"
                        },
                        {
                            "code": "105015",
                            "cap": "08037",
                            "nome": "Seui"
                        },
                        {
                            "code": "105016",
                            "cap": "08040",
                            "nome": "Talana"
                        },
                        {
                            "code": "105017",
                            "cap": "08047",
                            "nome": "Tertenia"
                        },
                        {
                            "code": "105018",
                            "cap": "08048",
                            "nome": "Tortol\u00ec"
                        },
                        {
                            "code": "105019",
                            "cap": "08040",
                            "nome": "Triei"
                        },
                        {
                            "code": "105020",
                            "cap": "08040",
                            "nome": "Ulassai"
                        },
                        {
                            "code": "105021",
                            "cap": "08040",
                            "nome": "Urzulei"
                        },
                        {
                            "code": "105022",
                            "cap": "08040",
                            "nome": "Ussassai"
                        },
                        {
                            "code": "105023",
                            "cap": "08049",
                            "nome": "Villagrande Strisaili"
                        }
                    ],
                    "nome": "Ogliastra"
                },
                {
                    "code": "OT",
                    "comuni": [
                        {
                            "code": "104001",
                            "cap": "07020",
                            "nome": "Aggius"
                        },
                        {
                            "code": "104002",
                            "cap": "07020",
                            "nome": "Aglientu"
                        },
                        {
                            "code": "104003",
                            "cap": "07020",
                            "nome": "Al\u00e0 dei Sardi"
                        },
                        {
                            "code": "104004",
                            "cap": "07021",
                            "nome": "Arzachena"
                        },
                        {
                            "code": "104005",
                            "cap": "07030",
                            "nome": "Badesi"
                        },
                        {
                            "code": "104006",
                            "cap": "07022",
                            "nome": "Berchidda"
                        },
                        {
                            "code": "104007",
                            "cap": "07030",
                            "nome": "Bortigiadas"
                        },
                        {
                            "code": "104008",
                            "cap": "07020",
                            "nome": "Buddus\u00f2"
                        },
                        {
                            "code": "104009",
                            "cap": "08020",
                            "nome": "Budoni"
                        },
                        {
                            "code": "104010",
                            "cap": "07023",
                            "nome": "Calangianus"
                        },
                        {
                            "code": "104011",
                            "cap": "07020",
                            "nome": "Golfo Aranci"
                        },
                        {
                            "code": "104012",
                            "cap": "07024",
                            "nome": "La Maddalena"
                        },
                        {
                            "code": "104013",
                            "cap": "07020",
                            "nome": "Loiri Porto San Paolo"
                        },
                        {
                            "code": "104014",
                            "cap": "07020",
                            "nome": "Luogosanto"
                        },
                        {
                            "code": "104015",
                            "cap": "07025",
                            "nome": "Luras"
                        },
                        {
                            "code": "104016",
                            "cap": "07020",
                            "nome": "Monti"
                        },
                        {
                            "code": "104017",
                            "cap": "07026",
                            "nome": "Olbia"
                        },
                        {
                            "code": "104018",
                            "cap": "07027",
                            "nome": "Oschiri"
                        },
                        {
                            "code": "104019",
                            "cap": "07020",
                            "nome": "Padru"
                        },
                        {
                            "code": "104020",
                            "cap": "07020",
                            "nome": "Palau"
                        },
                        {
                            "code": "104023",
                            "cap": "08020",
                            "nome": "San Teodoro"
                        },
                        {
                            "code": "104022",
                            "cap": "07028",
                            "nome": "Santa Teresa Gallura"
                        },
                        {
                            "code": "104021",
                            "cap": "07030",
                            "nome": "Sant'Antonio di Gallura"
                        },
                        {
                            "code": "104024",
                            "cap": "07020",
                            "nome": "Telti"
                        },
                        {
                            "code": "104025",
                            "cap": "07029",
                            "nome": "Tempio Pausania"
                        },
                        {
                            "code": "104026",
                            "cap": "07038",
                            "nome": "Trinit\u00e0 d'Agultu e Vignola"
                        }
                    ],
                    "nome": "Olbia-Tempio"
                },
                {
                    "code": "OR",
                    "comuni": [
                        {
                            "code": "095001",
                            "cap": "09071",
                            "nome": "Abbasanta"
                        },
                        {
                            "code": "095002",
                            "cap": "09070",
                            "nome": "Aidomaggiore"
                        },
                        {
                            "code": "095003",
                            "cap": "09090",
                            "nome": "Albagiara"
                        },
                        {
                            "code": "095004",
                            "cap": "09091",
                            "nome": "Ales"
                        },
                        {
                            "code": "095005",
                            "cap": "09080",
                            "nome": "Allai"
                        },
                        {
                            "code": "095006",
                            "cap": "09092",
                            "nome": "Arborea"
                        },
                        {
                            "code": "095007",
                            "cap": "09081",
                            "nome": "Ardauli"
                        },
                        {
                            "code": "095008",
                            "cap": "09080",
                            "nome": "Assolo"
                        },
                        {
                            "code": "095009",
                            "cap": "09080",
                            "nome": "Asuni"
                        },
                        {
                            "code": "095010",
                            "cap": "09090",
                            "nome": "Baradili"
                        },
                        {
                            "code": "095011",
                            "cap": "09070",
                            "nome": "Baratili San Pietro"
                        },
                        {
                            "code": "095012",
                            "cap": "09090",
                            "nome": "Baressa"
                        },
                        {
                            "code": "095013",
                            "cap": "09070",
                            "nome": "Bauladu"
                        },
                        {
                            "code": "095014",
                            "cap": "09080",
                            "nome": "Bidon\u00ec"
                        },
                        {
                            "code": "095015",
                            "cap": "09070",
                            "nome": "Bonarcado"
                        },
                        {
                            "code": "095016",
                            "cap": "09080",
                            "nome": "Boroneddu"
                        },
                        {
                            "code": "095079",
                            "cap": "08013",
                            "nome": "Bosa"
                        },
                        {
                            "code": "095017",
                            "cap": "09082",
                            "nome": "Busachi"
                        },
                        {
                            "code": "095018",
                            "cap": "09072",
                            "nome": "Cabras"
                        },
                        {
                            "code": "095019",
                            "cap": "09073",
                            "nome": "Cuglieri"
                        },
                        {
                            "code": "095077",
                            "cap": "09090",
                            "nome": "Curcuris"
                        },
                        {
                            "code": "095080",
                            "cap": "08010",
                            "nome": "Flussio"
                        },
                        {
                            "code": "095020",
                            "cap": "09083",
                            "nome": "Fordongianus"
                        },
                        {
                            "code": "095081",
                            "cap": "08030",
                            "nome": "Genoni"
                        },
                        {
                            "code": "095021",
                            "cap": "09074",
                            "nome": "Ghilarza"
                        },
                        {
                            "code": "095022",
                            "cap": "09090",
                            "nome": "Gonnoscodina"
                        },
                        {
                            "code": "095023",
                            "cap": "09090",
                            "nome": "Gonnosn\u00f2"
                        },
                        {
                            "code": "095024",
                            "cap": "09093",
                            "nome": "Gonnostramatza"
                        },
                        {
                            "code": "095082",
                            "cap": "08034",
                            "nome": "Laconi"
                        },
                        {
                            "code": "095083",
                            "cap": "08010",
                            "nome": "Magomadas"
                        },
                        {
                            "code": "095025",
                            "cap": "09094",
                            "nome": "Marrubiu"
                        },
                        {
                            "code": "095026",
                            "cap": "09090",
                            "nome": "Masullas"
                        },
                        {
                            "code": "095027",
                            "cap": "09070",
                            "nome": "Milis"
                        },
                        {
                            "code": "095084",
                            "cap": "08019",
                            "nome": "Modolo"
                        },
                        {
                            "code": "095028",
                            "cap": "09080",
                            "nome": "Mogorella"
                        },
                        {
                            "code": "095029",
                            "cap": "09095",
                            "nome": "Mogoro"
                        },
                        {
                            "code": "095085",
                            "cap": "08010",
                            "nome": "Montresta"
                        },
                        {
                            "code": "095030",
                            "cap": "09090",
                            "nome": "Morgongiori"
                        },
                        {
                            "code": "095031",
                            "cap": "09070",
                            "nome": "Narbolia"
                        },
                        {
                            "code": "095032",
                            "cap": "09080",
                            "nome": "Neoneli"
                        },
                        {
                            "code": "095033",
                            "cap": "09070",
                            "nome": "Norbello"
                        },
                        {
                            "code": "095034",
                            "cap": "09080",
                            "nome": "Nughedu Santa Vittoria"
                        },
                        {
                            "code": "095035",
                            "cap": "09070",
                            "nome": "Nurachi"
                        },
                        {
                            "code": "095036",
                            "cap": "09080",
                            "nome": "Nureci"
                        },
                        {
                            "code": "095037",
                            "cap": "09088",
                            "nome": "Ollastra"
                        },
                        {
                            "code": "095038",
                            "cap": "09170",
                            "nome": "Oristano"
                        },
                        {
                            "code": "095039",
                            "cap": "09090",
                            "nome": "Palmas Arborea"
                        },
                        {
                            "code": "095040",
                            "cap": "09090",
                            "nome": "Pau"
                        },
                        {
                            "code": "095041",
                            "cap": "09070",
                            "nome": "Paulilatino"
                        },
                        {
                            "code": "095042",
                            "cap": "09093",
                            "nome": "Pompu"
                        },
                        {
                            "code": "095043",
                            "cap": "09070",
                            "nome": "Riola Sardo"
                        },
                        {
                            "code": "095044",
                            "cap": "09085",
                            "nome": "Ruinas"
                        },
                        {
                            "code": "095086",
                            "cap": "08010",
                            "nome": "Sagama"
                        },
                        {
                            "code": "095045",
                            "cap": "09086",
                            "nome": "Samugheo"
                        },
                        {
                            "code": "095046",
                            "cap": "09097",
                            "nome": "San Nicol\u00f2 d'Arcidano"
                        },
                        {
                            "code": "095050",
                            "cap": "09070",
                            "nome": "San Vero Milis"
                        },
                        {
                            "code": "095047",
                            "cap": "09096",
                            "nome": "Santa Giusta"
                        },
                        {
                            "code": "095049",
                            "cap": "09075",
                            "nome": "Santu Lussurgiu"
                        },
                        {
                            "code": "095051",
                            "cap": "09078",
                            "nome": "Scano di Montiferro"
                        },
                        {
                            "code": "095052",
                            "cap": "09076",
                            "nome": "Sedilo"
                        },
                        {
                            "code": "095053",
                            "cap": "09070",
                            "nome": "Seneghe"
                        },
                        {
                            "code": "095054",
                            "cap": "09080",
                            "nome": "Senis"
                        },
                        {
                            "code": "095055",
                            "cap": "09078",
                            "nome": "Sennariolo"
                        },
                        {
                            "code": "095056",
                            "cap": "09070",
                            "nome": "Siamaggiore"
                        },
                        {
                            "code": "095057",
                            "cap": "09080",
                            "nome": "Siamanna"
                        },
                        {
                            "code": "095076",
                            "cap": "09080",
                            "nome": "Siapiccia"
                        },
                        {
                            "code": "095058",
                            "cap": "09090",
                            "nome": "Simala"
                        },
                        {
                            "code": "095059",
                            "cap": "09088",
                            "nome": "Simaxis"
                        },
                        {
                            "code": "095060",
                            "cap": "09090",
                            "nome": "Sini"
                        },
                        {
                            "code": "095061",
                            "cap": "09090",
                            "nome": "Siris"
                        },
                        {
                            "code": "095078",
                            "cap": "09080",
                            "nome": "Sodd\u00ec"
                        },
                        {
                            "code": "095062",
                            "cap": "09077",
                            "nome": "Solarussa"
                        },
                        {
                            "code": "095063",
                            "cap": "09080",
                            "nome": "Sorradile"
                        },
                        {
                            "code": "095087",
                            "cap": "08010",
                            "nome": "Suni"
                        },
                        {
                            "code": "095064",
                            "cap": "09080",
                            "nome": "Tadasuni"
                        },
                        {
                            "code": "095065",
                            "cap": "09098",
                            "nome": "Terralba"
                        },
                        {
                            "code": "095088",
                            "cap": "08010",
                            "nome": "Tinnura"
                        },
                        {
                            "code": "095066",
                            "cap": "09070",
                            "nome": "Tramatza"
                        },
                        {
                            "code": "095067",
                            "cap": "09079",
                            "nome": "Tresnuraghes"
                        },
                        {
                            "code": "095068",
                            "cap": "09080",
                            "nome": "Ul\u00e0 Tirso"
                        },
                        {
                            "code": "095069",
                            "cap": "09099",
                            "nome": "Uras"
                        },
                        {
                            "code": "095070",
                            "cap": "09090",
                            "nome": "Usellus"
                        },
                        {
                            "code": "095048",
                            "cap": "09080",
                            "nome": "Villa Sant'Antonio"
                        },
                        {
                            "code": "095073",
                            "cap": "09090",
                            "nome": "Villa Verde"
                        },
                        {
                            "code": "095071",
                            "cap": "09084",
                            "nome": "Villanova Truschedu"
                        },
                        {
                            "code": "095072",
                            "cap": "09080",
                            "nome": "Villaurbana"
                        },
                        {
                            "code": "095074",
                            "cap": "09070",
                            "nome": "Zeddiani"
                        },
                        {
                            "code": "095075",
                            "cap": "09070",
                            "nome": "Zerfaliu"
                        }
                    ],
                    "nome": "Oristano"
                },
                {
                    "code": "SS",
                    "comuni": [
                        {
                            "code": "090003",
                            "cap": "07041",
                            "nome": "Alghero"
                        },
                        {
                            "code": "090004",
                            "cap": "07010",
                            "nome": "Anela"
                        },
                        {
                            "code": "090005",
                            "cap": "07010",
                            "nome": "Ardara"
                        },
                        {
                            "code": "090007",
                            "cap": "07040",
                            "nome": "Banari"
                        },
                        {
                            "code": "090008",
                            "cap": "07010",
                            "nome": "Benetutti"
                        },
                        {
                            "code": "090010",
                            "cap": "07040",
                            "nome": "Bessude"
                        },
                        {
                            "code": "090011",
                            "cap": "07043",
                            "nome": "Bonnanaro"
                        },
                        {
                            "code": "090012",
                            "cap": "07011",
                            "nome": "Bono"
                        },
                        {
                            "code": "090013",
                            "cap": "07012",
                            "nome": "Bonorva"
                        },
                        {
                            "code": "090015",
                            "cap": "07040",
                            "nome": "Borutta"
                        },
                        {
                            "code": "090016",
                            "cap": "07010",
                            "nome": "Bottidda"
                        },
                        {
                            "code": "090018",
                            "cap": "07010",
                            "nome": "Bultei"
                        },
                        {
                            "code": "090019",
                            "cap": "07030",
                            "nome": "Bulzi"
                        },
                        {
                            "code": "090020",
                            "cap": "07010",
                            "nome": "Burgos"
                        },
                        {
                            "code": "090022",
                            "cap": "07030",
                            "nome": "Cargeghe"
                        },
                        {
                            "code": "090023",
                            "cap": "07031",
                            "nome": "Castelsardo"
                        },
                        {
                            "code": "090024",
                            "cap": "07040",
                            "nome": "Cheremule"
                        },
                        {
                            "code": "090025",
                            "cap": "07030",
                            "nome": "Chiaramonti"
                        },
                        {
                            "code": "090026",
                            "cap": "07040",
                            "nome": "Codrongianos"
                        },
                        {
                            "code": "090027",
                            "cap": "07010",
                            "nome": "Cossoine"
                        },
                        {
                            "code": "090088",
                            "cap": "07030",
                            "nome": "Erula"
                        },
                        {
                            "code": "090028",
                            "cap": "07010",
                            "nome": "Esporlatu"
                        },
                        {
                            "code": "090029",
                            "cap": "07030",
                            "nome": "Florinas"
                        },
                        {
                            "code": "090030",
                            "cap": "07010",
                            "nome": "Giave"
                        },
                        {
                            "code": "090031",
                            "cap": "07010",
                            "nome": "Illorai"
                        },
                        {
                            "code": "090032",
                            "cap": "07010",
                            "nome": "Ittireddu"
                        },
                        {
                            "code": "090033",
                            "cap": "07044",
                            "nome": "Ittiri"
                        },
                        {
                            "code": "090034",
                            "cap": "07030",
                            "nome": "Laerru"
                        },
                        {
                            "code": "090038",
                            "cap": "07010",
                            "nome": "Mara"
                        },
                        {
                            "code": "090039",
                            "cap": "07030",
                            "nome": "Martis"
                        },
                        {
                            "code": "090040",
                            "cap": "07010",
                            "nome": "Monteleone Rocca Doria"
                        },
                        {
                            "code": "090042",
                            "cap": "07013",
                            "nome": "Mores"
                        },
                        {
                            "code": "090043",
                            "cap": "07030",
                            "nome": "Muros"
                        },
                        {
                            "code": "090044",
                            "cap": "07010",
                            "nome": "Nughedu San Nicol\u00f2"
                        },
                        {
                            "code": "090045",
                            "cap": "07010",
                            "nome": "Nule"
                        },
                        {
                            "code": "090046",
                            "cap": "07032",
                            "nome": "Nulvi"
                        },
                        {
                            "code": "090048",
                            "cap": "07040",
                            "nome": "Olmedo"
                        },
                        {
                            "code": "090050",
                            "cap": "07033",
                            "nome": "Osilo"
                        },
                        {
                            "code": "090051",
                            "cap": "07045",
                            "nome": "Ossi"
                        },
                        {
                            "code": "090052",
                            "cap": "07014",
                            "nome": "Ozieri"
                        },
                        {
                            "code": "090053",
                            "cap": "07015",
                            "nome": "Padria"
                        },
                        {
                            "code": "090055",
                            "cap": "07016",
                            "nome": "Pattada"
                        },
                        {
                            "code": "090056",
                            "cap": "07034",
                            "nome": "Perfugas"
                        },
                        {
                            "code": "090057",
                            "cap": "07017",
                            "nome": "Ploaghe"
                        },
                        {
                            "code": "090058",
                            "cap": "07046",
                            "nome": "Porto Torres"
                        },
                        {
                            "code": "090059",
                            "cap": "07018",
                            "nome": "Pozzomaggiore"
                        },
                        {
                            "code": "090060",
                            "cap": "07040",
                            "nome": "Putifigari"
                        },
                        {
                            "code": "090061",
                            "cap": "07010",
                            "nome": "Romana"
                        },
                        {
                            "code": "090087",
                            "cap": "07030",
                            "nome": "Santa Maria Coghinas"
                        },
                        {
                            "code": "090064",
                            "cap": "07100",
                            "nome": "Sassari"
                        },
                        {
                            "code": "090065",
                            "cap": "07035",
                            "nome": "Sedini"
                        },
                        {
                            "code": "090066",
                            "cap": "07010",
                            "nome": "Semestene"
                        },
                        {
                            "code": "090067",
                            "cap": "07036",
                            "nome": "Sennori"
                        },
                        {
                            "code": "090068",
                            "cap": "07040",
                            "nome": "Siligo"
                        },
                        {
                            "code": "090069",
                            "cap": "07037",
                            "nome": "Sorso"
                        },
                        {
                            "code": "090089",
                            "cap": "07040",
                            "nome": "Stintino"
                        },
                        {
                            "code": "090086",
                            "cap": "07030",
                            "nome": "Tergu"
                        },
                        {
                            "code": "090071",
                            "cap": "07047",
                            "nome": "Thiesi"
                        },
                        {
                            "code": "090072",
                            "cap": "07040",
                            "nome": "Tissi"
                        },
                        {
                            "code": "090073",
                            "cap": "07048",
                            "nome": "Torralba"
                        },
                        {
                            "code": "090075",
                            "cap": "07010",
                            "nome": "Tula"
                        },
                        {
                            "code": "090076",
                            "cap": "07040",
                            "nome": "Uri"
                        },
                        {
                            "code": "090077",
                            "cap": "07049",
                            "nome": "Usini"
                        },
                        {
                            "code": "090079",
                            "cap": "07039",
                            "nome": "Valledoria"
                        },
                        {
                            "code": "090082",
                            "cap": "07030",
                            "nome": "Viddalba"
                        },
                        {
                            "code": "090078",
                            "cap": "07019",
                            "nome": "Villanova Monteleone"
                        }
                    ],
                    "nome": "Sassari"
                }
            ],
            "nome": "Sardegna"
        },
        {
            "province": [
                {
                    "code": "AG",
                    "comuni": [
                        {
                            "code": "084001",
                            "cap": "92100",
                            "nome": "Agrigento"
                        },
                        {
                            "code": "084002",
                            "cap": "92010",
                            "nome": "Alessandria della Rocca"
                        },
                        {
                            "code": "084003",
                            "cap": "92021",
                            "nome": "Aragona"
                        },
                        {
                            "code": "084004",
                            "cap": "92010",
                            "nome": "Bivona"
                        },
                        {
                            "code": "084005",
                            "cap": "92010",
                            "nome": "Burgio"
                        },
                        {
                            "code": "084006",
                            "cap": "92010",
                            "nome": "Calamonaci"
                        },
                        {
                            "code": "084007",
                            "cap": "92010",
                            "nome": "Caltabellotta"
                        },
                        {
                            "code": "084008",
                            "cap": "92020",
                            "nome": "Camastra"
                        },
                        {
                            "code": "084009",
                            "cap": "92022",
                            "nome": "Cammarata"
                        },
                        {
                            "code": "084010",
                            "cap": "92023",
                            "nome": "Campobello di Licata"
                        },
                        {
                            "code": "084011",
                            "cap": "92024",
                            "nome": "Canicatt\u00ec"
                        },
                        {
                            "code": "084012",
                            "cap": "92025",
                            "nome": "Casteltermini"
                        },
                        {
                            "code": "084013",
                            "cap": "92020",
                            "nome": "Castrofilippo"
                        },
                        {
                            "code": "084014",
                            "cap": "92011",
                            "nome": "Cattolica Eraclea"
                        },
                        {
                            "code": "084015",
                            "cap": "92012",
                            "nome": "Cianciana"
                        },
                        {
                            "code": "084016",
                            "cap": "92020",
                            "nome": "Comitini"
                        },
                        {
                            "code": "084017",
                            "cap": "92026",
                            "nome": "Favara"
                        },
                        {
                            "code": "084018",
                            "cap": "92020",
                            "nome": "Grotte"
                        },
                        {
                            "code": "084019",
                            "cap": "92010",
                            "nome": "Joppolo Giancaxio"
                        },
                        {
                            "code": "084020",
                            "cap": "92010",
                            "nome": "Lampedusa e Linosa"
                        },
                        {
                            "code": "084021",
                            "cap": "92027",
                            "nome": "Licata"
                        },
                        {
                            "code": "084022",
                            "cap": "92010",
                            "nome": "Lucca Sicula"
                        },
                        {
                            "code": "084023",
                            "cap": "92013",
                            "nome": "Menfi"
                        },
                        {
                            "code": "084024",
                            "cap": "92010",
                            "nome": "Montallegro"
                        },
                        {
                            "code": "084025",
                            "cap": "92010",
                            "nome": "Montevago"
                        },
                        {
                            "code": "084026",
                            "cap": "92028",
                            "nome": "Naro"
                        },
                        {
                            "code": "084027",
                            "cap": "92020",
                            "nome": "Palma di Montechiaro"
                        },
                        {
                            "code": "084028",
                            "cap": "92014",
                            "nome": "Porto Empedocle"
                        },
                        {
                            "code": "084029",
                            "cap": "92020",
                            "nome": "Racalmuto"
                        },
                        {
                            "code": "084030",
                            "cap": "92015",
                            "nome": "Raffadali"
                        },
                        {
                            "code": "084031",
                            "cap": "92029",
                            "nome": "Ravanusa"
                        },
                        {
                            "code": "084032",
                            "cap": "92010",
                            "nome": "Realmonte"
                        },
                        {
                            "code": "084033",
                            "cap": "92016",
                            "nome": "Ribera"
                        },
                        {
                            "code": "084034",
                            "cap": "92017",
                            "nome": "Sambuca di Sicilia"
                        },
                        {
                            "code": "084035",
                            "cap": "92020",
                            "nome": "San Biagio Platani"
                        },
                        {
                            "code": "084036",
                            "cap": "92020",
                            "nome": "San Giovanni Gemini"
                        },
                        {
                            "code": "084037",
                            "cap": "92020",
                            "nome": "Santa Elisabetta"
                        },
                        {
                            "code": "084038",
                            "cap": "92018",
                            "nome": "Santa Margherita di Belice"
                        },
                        {
                            "code": "084039",
                            "cap": "92020",
                            "nome": "Sant'Angelo Muxaro"
                        },
                        {
                            "code": "084040",
                            "cap": "92020",
                            "nome": "Santo Stefano Quisquina"
                        },
                        {
                            "code": "084041",
                            "cap": "92019",
                            "nome": "Sciacca"
                        },
                        {
                            "code": "084042",
                            "cap": "92010",
                            "nome": "Siculiana"
                        },
                        {
                            "code": "084043",
                            "cap": "92020",
                            "nome": "Villafranca Sicula"
                        }
                    ],
                    "nome": "Agrigento"
                },
                {
                    "code": "CL",
                    "comuni": [
                        {
                            "code": "085001",
                            "cap": "93010",
                            "nome": "Acquaviva Platani"
                        },
                        {
                            "code": "085002",
                            "cap": "93010",
                            "nome": "Bompensiere"
                        },
                        {
                            "code": "085003",
                            "cap": "93011",
                            "nome": "Butera"
                        },
                        {
                            "code": "085004",
                            "cap": "93100",
                            "nome": "Caltanissetta"
                        },
                        {
                            "code": "085005",
                            "cap": "93010",
                            "nome": "Campofranco"
                        },
                        {
                            "code": "085006",
                            "cap": "93010",
                            "nome": "Delia"
                        },
                        {
                            "code": "085007",
                            "cap": "93012",
                            "nome": "Gela"
                        },
                        {
                            "code": "085008",
                            "cap": "93010",
                            "nome": "Marianopoli"
                        },
                        {
                            "code": "085009",
                            "cap": "93013",
                            "nome": "Mazzarino"
                        },
                        {
                            "code": "085010",
                            "cap": "93010",
                            "nome": "Milena"
                        },
                        {
                            "code": "085011",
                            "cap": "93010",
                            "nome": "Montedoro"
                        },
                        {
                            "code": "085012",
                            "cap": "93014",
                            "nome": "Mussomeli"
                        },
                        {
                            "code": "085013",
                            "cap": "93015",
                            "nome": "Niscemi"
                        },
                        {
                            "code": "085014",
                            "cap": "93010",
                            "nome": "Resuttano"
                        },
                        {
                            "code": "085015",
                            "cap": "93016",
                            "nome": "Riesi"
                        },
                        {
                            "code": "085016",
                            "cap": "93017",
                            "nome": "San Cataldo"
                        },
                        {
                            "code": "085017",
                            "cap": "93018",
                            "nome": "Santa Caterina Villarmosa"
                        },
                        {
                            "code": "085018",
                            "cap": "93010",
                            "nome": "Serradifalco"
                        },
                        {
                            "code": "085019",
                            "cap": "93019",
                            "nome": "Sommatino"
                        },
                        {
                            "code": "085020",
                            "cap": "93010",
                            "nome": "Sutera"
                        },
                        {
                            "code": "085021",
                            "cap": "93010",
                            "nome": "Vallelunga Pratameno"
                        },
                        {
                            "code": "085022",
                            "cap": "93010",
                            "nome": "Villalba"
                        }
                    ],
                    "nome": "Caltanissetta"
                },
                {
                    "code": "CT",
                    "comuni": [
                        {
                            "code": "087001",
                            "cap": "95020",
                            "nome": "Aci Bonaccorsi"
                        },
                        {
                            "code": "087002",
                            "cap": "95021",
                            "nome": "Aci Castello"
                        },
                        {
                            "code": "087003",
                            "cap": "95022",
                            "nome": "Aci Catena"
                        },
                        {
                            "code": "087005",
                            "cap": "95025",
                            "nome": "Aci Sant'Antonio"
                        },
                        {
                            "code": "087004",
                            "cap": "95024",
                            "nome": "Acireale"
                        },
                        {
                            "code": "087006",
                            "cap": "95031",
                            "nome": "Adrano"
                        },
                        {
                            "code": "087007",
                            "cap": "95032",
                            "nome": "Belpasso"
                        },
                        {
                            "code": "087008",
                            "cap": "95033",
                            "nome": "Biancavilla"
                        },
                        {
                            "code": "087009",
                            "cap": "95034",
                            "nome": "Bronte"
                        },
                        {
                            "code": "087010",
                            "cap": "95011",
                            "nome": "Calatabiano"
                        },
                        {
                            "code": "087011",
                            "cap": "95041",
                            "nome": "Caltagirone"
                        },
                        {
                            "code": "087012",
                            "cap": "95040",
                            "nome": "Camporotondo Etneo"
                        },
                        {
                            "code": "087013",
                            "cap": "95040",
                            "nome": "Castel di Iudica"
                        },
                        {
                            "code": "087014",
                            "cap": "95012",
                            "nome": "Castiglione di Sicilia"
                        },
                        {
                            "code": "087015",
                            "cap": "951xx",
                            "nome": "Catania"
                        },
                        {
                            "code": "087016",
                            "cap": "95013",
                            "nome": "Fiumefreddo di Sicilia"
                        },
                        {
                            "code": "087017",
                            "cap": "95014",
                            "nome": "Giarre"
                        },
                        {
                            "code": "087018",
                            "cap": "95042",
                            "nome": "Grammichele"
                        },
                        {
                            "code": "087019",
                            "cap": "95030",
                            "nome": "Gravina di Catania"
                        },
                        {
                            "code": "087020",
                            "cap": "95040",
                            "nome": "Licodia Eubea"
                        },
                        {
                            "code": "087021",
                            "cap": "95015",
                            "nome": "Linguaglossa"
                        },
                        {
                            "code": "087022",
                            "cap": "95035",
                            "nome": "Maletto"
                        },
                        {
                            "code": "087057",
                            "cap": "95030",
                            "nome": "Maniace"
                        },
                        {
                            "code": "087023",
                            "cap": "95016",
                            "nome": "Mascali"
                        },
                        {
                            "code": "087024",
                            "cap": "95030",
                            "nome": "Mascalucia"
                        },
                        {
                            "code": "087056",
                            "cap": "95040",
                            "nome": "Mazzarrone"
                        },
                        {
                            "code": "087025",
                            "cap": "95043",
                            "nome": "Militello in Val di Catania"
                        },
                        {
                            "code": "087026",
                            "cap": "95010",
                            "nome": "Milo"
                        },
                        {
                            "code": "087027",
                            "cap": "95044",
                            "nome": "Mineo"
                        },
                        {
                            "code": "087028",
                            "cap": "95040",
                            "nome": "Mirabella Imbaccari"
                        },
                        {
                            "code": "087029",
                            "cap": "95045",
                            "nome": "Misterbianco"
                        },
                        {
                            "code": "087030",
                            "cap": "95040",
                            "nome": "Motta Sant'Anastasia"
                        },
                        {
                            "code": "087031",
                            "cap": "95030",
                            "nome": "Nicolosi"
                        },
                        {
                            "code": "087032",
                            "cap": "95046",
                            "nome": "Palagonia"
                        },
                        {
                            "code": "087033",
                            "cap": "95047",
                            "nome": "Patern\u00f2"
                        },
                        {
                            "code": "087034",
                            "cap": "95030",
                            "nome": "Pedara"
                        },
                        {
                            "code": "087035",
                            "cap": "95017",
                            "nome": "Piedimonte Etneo"
                        },
                        {
                            "code": "087036",
                            "cap": "95040",
                            "nome": "Raddusa"
                        },
                        {
                            "code": "087058",
                            "cap": "95030",
                            "nome": "Ragalna"
                        },
                        {
                            "code": "087037",
                            "cap": "95040",
                            "nome": "Ramacca"
                        },
                        {
                            "code": "087038",
                            "cap": "95036",
                            "nome": "Randazzo"
                        },
                        {
                            "code": "087039",
                            "cap": "95018",
                            "nome": "Riposto"
                        },
                        {
                            "code": "087040",
                            "cap": "95040",
                            "nome": "San Cono"
                        },
                        {
                            "code": "087041",
                            "cap": "95037",
                            "nome": "San Giovanni la Punta"
                        },
                        {
                            "code": "087042",
                            "cap": "95027",
                            "nome": "San Gregorio di Catania"
                        },
                        {
                            "code": "087043",
                            "cap": "95040",
                            "nome": "San Michele di Ganzaria"
                        },
                        {
                            "code": "087044",
                            "cap": "95030",
                            "nome": "San Pietro Clarenza"
                        },
                        {
                            "code": "087047",
                            "cap": "95038",
                            "nome": "Santa Maria di Licodia"
                        },
                        {
                            "code": "087048",
                            "cap": "95010",
                            "nome": "Santa Venerina"
                        },
                        {
                            "code": "087045",
                            "cap": "95030",
                            "nome": "Sant'Agata Li Battiati"
                        },
                        {
                            "code": "087046",
                            "cap": "95010",
                            "nome": "Sant'Alfio"
                        },
                        {
                            "code": "087049",
                            "cap": "95048",
                            "nome": "Scordia"
                        },
                        {
                            "code": "087050",
                            "cap": "95039",
                            "nome": "Trecastagni"
                        },
                        {
                            "code": "087051",
                            "cap": "95030",
                            "nome": "Tremestieri Etneo"
                        },
                        {
                            "code": "087052",
                            "cap": "95028",
                            "nome": "Valverde"
                        },
                        {
                            "code": "087053",
                            "cap": "95029",
                            "nome": "Viagrande"
                        },
                        {
                            "code": "087054",
                            "cap": "95049",
                            "nome": "Vizzini"
                        },
                        {
                            "code": "087055",
                            "cap": "95019",
                            "nome": "Zafferana Etnea"
                        }
                    ],
                    "nome": "Catania"
                },
                {
                    "code": "EN",
                    "comuni": [
                        {
                            "code": "086001",
                            "cap": "94011",
                            "nome": "Agira"
                        },
                        {
                            "code": "086002",
                            "cap": "94010",
                            "nome": "Aidone"
                        },
                        {
                            "code": "086003",
                            "cap": "94010",
                            "nome": "Assoro"
                        },
                        {
                            "code": "086004",
                            "cap": "94012",
                            "nome": "Barrafranca"
                        },
                        {
                            "code": "086005",
                            "cap": "94010",
                            "nome": "Calascibetta"
                        },
                        {
                            "code": "086006",
                            "cap": "94010",
                            "nome": "Catenanuova"
                        },
                        {
                            "code": "086007",
                            "cap": "94010",
                            "nome": "Centuripe"
                        },
                        {
                            "code": "086008",
                            "cap": "94010",
                            "nome": "Cerami"
                        },
                        {
                            "code": "086009",
                            "cap": "94100",
                            "nome": "Enna"
                        },
                        {
                            "code": "086010",
                            "cap": "94010",
                            "nome": "Gagliano Castelferrato"
                        },
                        {
                            "code": "086011",
                            "cap": "94013",
                            "nome": "Leonforte"
                        },
                        {
                            "code": "086012",
                            "cap": "94014",
                            "nome": "Nicosia"
                        },
                        {
                            "code": "086013",
                            "cap": "94010",
                            "nome": "Nissoria"
                        },
                        {
                            "code": "086014",
                            "cap": "94015",
                            "nome": "Piazza Armerina"
                        },
                        {
                            "code": "086015",
                            "cap": "94016",
                            "nome": "Pietraperzia"
                        },
                        {
                            "code": "086016",
                            "cap": "94017",
                            "nome": "Regalbuto"
                        },
                        {
                            "code": "086017",
                            "cap": "94010",
                            "nome": "Sperlinga"
                        },
                        {
                            "code": "086018",
                            "cap": "94018",
                            "nome": "Troina"
                        },
                        {
                            "code": "086019",
                            "cap": "94019",
                            "nome": "Valguarnera Caropepe"
                        },
                        {
                            "code": "086020",
                            "cap": "94010",
                            "nome": "Villarosa"
                        }
                    ],
                    "nome": "Enna"
                },
                {
                    "code": "ME",
                    "comuni": [
                        {
                            "code": "083107",
                            "cap": "98070",
                            "nome": "Acquedolci"
                        },
                        {
                            "code": "083001",
                            "cap": "98070",
                            "nome": "Alcara Li Fusi"
                        },
                        {
                            "code": "083002",
                            "cap": "98020",
                            "nome": "Al\u00ec"
                        },
                        {
                            "code": "083003",
                            "cap": "98021",
                            "nome": "Al\u00ec Terme"
                        },
                        {
                            "code": "083004",
                            "cap": "98030",
                            "nome": "Antillo"
                        },
                        {
                            "code": "083005",
                            "cap": "98051",
                            "nome": "Barcellona Pozzo di Gotto"
                        },
                        {
                            "code": "083006",
                            "cap": "98060",
                            "nome": "Basic\u00f2"
                        },
                        {
                            "code": "083007",
                            "cap": "98061",
                            "nome": "Brolo"
                        },
                        {
                            "code": "083008",
                            "cap": "98031",
                            "nome": "Capizzi"
                        },
                        {
                            "code": "083009",
                            "cap": "98071",
                            "nome": "Capo d'Orlando"
                        },
                        {
                            "code": "083010",
                            "cap": "98070",
                            "nome": "Capri Leone"
                        },
                        {
                            "code": "083011",
                            "cap": "98072",
                            "nome": "Caronia"
                        },
                        {
                            "code": "083012",
                            "cap": "98032",
                            "nome": "Casalvecchio Siculo"
                        },
                        {
                            "code": "083013",
                            "cap": "98070",
                            "nome": "Castel di Lucio"
                        },
                        {
                            "code": "083014",
                            "cap": "98070",
                            "nome": "Castell'Umberto"
                        },
                        {
                            "code": "083015",
                            "cap": "98030",
                            "nome": "Castelmola"
                        },
                        {
                            "code": "083016",
                            "cap": "98053",
                            "nome": "Castroreale"
                        },
                        {
                            "code": "083017",
                            "cap": "98033",
                            "nome": "Cesar\u00f2"
                        },
                        {
                            "code": "083018",
                            "cap": "98040",
                            "nome": "Condr\u00f2"
                        },
                        {
                            "code": "083019",
                            "cap": "98060",
                            "nome": "Falcone"
                        },
                        {
                            "code": "083020",
                            "cap": "98062",
                            "nome": "Ficarra"
                        },
                        {
                            "code": "083021",
                            "cap": "98022",
                            "nome": "Fiumedinisi"
                        },
                        {
                            "code": "083022",
                            "cap": "98030",
                            "nome": "Floresta"
                        },
                        {
                            "code": "083023",
                            "cap": "98050",
                            "nome": "Fondachelli-Fantina"
                        },
                        {
                            "code": "083024",
                            "cap": "98030",
                            "nome": "Forza d'Agr\u00f2"
                        },
                        {
                            "code": "083025",
                            "cap": "98034",
                            "nome": "Francavilla di Sicilia"
                        },
                        {
                            "code": "083026",
                            "cap": "98070",
                            "nome": "Frazzan\u00f2"
                        },
                        {
                            "code": "083027",
                            "cap": "98023",
                            "nome": "Furci Siculo"
                        },
                        {
                            "code": "083028",
                            "cap": "98054",
                            "nome": "Furnari"
                        },
                        {
                            "code": "083029",
                            "cap": "98030",
                            "nome": "Gaggi"
                        },
                        {
                            "code": "083030",
                            "cap": "98070",
                            "nome": "Galati Mamertino"
                        },
                        {
                            "code": "083031",
                            "cap": "98030",
                            "nome": "Gallodoro"
                        },
                        {
                            "code": "083032",
                            "cap": "98035",
                            "nome": "Giardini-Naxos"
                        },
                        {
                            "code": "083033",
                            "cap": "98063",
                            "nome": "Gioiosa Marea"
                        },
                        {
                            "code": "083034",
                            "cap": "98036",
                            "nome": "Graniti"
                        },
                        {
                            "code": "083035",
                            "cap": "98040",
                            "nome": "Gualtieri Sicamin\u00f2"
                        },
                        {
                            "code": "083036",
                            "cap": "98025",
                            "nome": "Itala"
                        },
                        {
                            "code": "083037",
                            "cap": "98050",
                            "nome": "Leni"
                        },
                        {
                            "code": "083038",
                            "cap": "98037",
                            "nome": "Letojanni"
                        },
                        {
                            "code": "083039",
                            "cap": "98064",
                            "nome": "Librizzi"
                        },
                        {
                            "code": "083040",
                            "cap": "98030",
                            "nome": "Limina"
                        },
                        {
                            "code": "083041",
                            "cap": "98055",
                            "nome": "Lipari"
                        },
                        {
                            "code": "083042",
                            "cap": "98070",
                            "nome": "Longi"
                        },
                        {
                            "code": "083043",
                            "cap": "98050",
                            "nome": "Malfa"
                        },
                        {
                            "code": "083044",
                            "cap": "98030",
                            "nome": "Malvagna"
                        },
                        {
                            "code": "083045",
                            "cap": "98020",
                            "nome": "Mandanici"
                        },
                        {
                            "code": "083046",
                            "cap": "98056",
                            "nome": "Mazzarr\u00e0 Sant'Andrea"
                        },
                        {
                            "code": "083047",
                            "cap": "98040",
                            "nome": "Mer\u00ec"
                        },
                        {
                            "code": "083048",
                            "cap": "981xx",
                            "nome": "Messina"
                        },
                        {
                            "code": "083049",
                            "cap": "98057",
                            "nome": "Milazzo"
                        },
                        {
                            "code": "083050",
                            "cap": "98070",
                            "nome": "Militello Rosmarino"
                        },
                        {
                            "code": "083051",
                            "cap": "98070",
                            "nome": "Mirto"
                        },
                        {
                            "code": "083052",
                            "cap": "98073",
                            "nome": "Mistretta"
                        },
                        {
                            "code": "083053",
                            "cap": "98030",
                            "nome": "Moio Alcantara"
                        },
                        {
                            "code": "083054",
                            "cap": "98041",
                            "nome": "Monforte San Giorgio"
                        },
                        {
                            "code": "083055",
                            "cap": "98030",
                            "nome": "Mongiuffi Melia"
                        },
                        {
                            "code": "083056",
                            "cap": "98060",
                            "nome": "Montagnareale"
                        },
                        {
                            "code": "083057",
                            "cap": "98065",
                            "nome": "Montalbano Elicona"
                        },
                        {
                            "code": "083058",
                            "cap": "98030",
                            "nome": "Motta Camastra"
                        },
                        {
                            "code": "083059",
                            "cap": "98070",
                            "nome": "Motta d'Affermo"
                        },
                        {
                            "code": "083060",
                            "cap": "98074",
                            "nome": "Naso"
                        },
                        {
                            "code": "083061",
                            "cap": "98026",
                            "nome": "Nizza di Sicilia"
                        },
                        {
                            "code": "083062",
                            "cap": "98058",
                            "nome": "Novara di Sicilia"
                        },
                        {
                            "code": "083063",
                            "cap": "98060",
                            "nome": "Oliveri"
                        },
                        {
                            "code": "083064",
                            "cap": "98042",
                            "nome": "Pace del Mela"
                        },
                        {
                            "code": "083065",
                            "cap": "98020",
                            "nome": "Pagliara"
                        },
                        {
                            "code": "083066",
                            "cap": "98066",
                            "nome": "Patti"
                        },
                        {
                            "code": "083067",
                            "cap": "98070",
                            "nome": "Pettineo"
                        },
                        {
                            "code": "083068",
                            "cap": "98060",
                            "nome": "Piraino"
                        },
                        {
                            "code": "083069",
                            "cap": "98067",
                            "nome": "Raccuja"
                        },
                        {
                            "code": "083070",
                            "cap": "98070",
                            "nome": "Reitano"
                        },
                        {
                            "code": "083071",
                            "cap": "98030",
                            "nome": "Roccafiorita"
                        },
                        {
                            "code": "083072",
                            "cap": "98027",
                            "nome": "Roccalumera"
                        },
                        {
                            "code": "083073",
                            "cap": "98040",
                            "nome": "Roccavaldina"
                        },
                        {
                            "code": "083074",
                            "cap": "98030",
                            "nome": "Roccella Valdemone"
                        },
                        {
                            "code": "083075",
                            "cap": "98059",
                            "nome": "Rod\u00ec Milici"
                        },
                        {
                            "code": "083076",
                            "cap": "98043",
                            "nome": "Rometta"
                        },
                        {
                            "code": "083077",
                            "cap": "98044",
                            "nome": "San Filippo del Mela"
                        },
                        {
                            "code": "083078",
                            "cap": "98075",
                            "nome": "San Fratello"
                        },
                        {
                            "code": "083079",
                            "cap": "98070",
                            "nome": "San Marco D'Alunzio"
                        },
                        {
                            "code": "083080",
                            "cap": "98045",
                            "nome": "San Pier Niceto"
                        },
                        {
                            "code": "083081",
                            "cap": "98068",
                            "nome": "San Piero Patti"
                        },
                        {
                            "code": "083082",
                            "cap": "98070",
                            "nome": "San Salvatore di Fitalia"
                        },
                        {
                            "code": "083090",
                            "cap": "98030",
                            "nome": "San Teodoro"
                        },
                        {
                            "code": "083083",
                            "cap": "98030",
                            "nome": "Santa Domenica Vittoria"
                        },
                        {
                            "code": "083086",
                            "cap": "98046",
                            "nome": "Santa Lucia del Mela"
                        },
                        {
                            "code": "083087",
                            "cap": "98050",
                            "nome": "Santa Marina Salina"
                        },
                        {
                            "code": "083089",
                            "cap": "98028",
                            "nome": "Santa Teresa di Riva"
                        },
                        {
                            "code": "083084",
                            "cap": "98076",
                            "nome": "Sant'Agata di Militello"
                        },
                        {
                            "code": "083085",
                            "cap": "98030",
                            "nome": "Sant'Alessio Siculo"
                        },
                        {
                            "code": "083088",
                            "cap": "98060",
                            "nome": "Sant'Angelo di Brolo"
                        },
                        {
                            "code": "083091",
                            "cap": "98077",
                            "nome": "Santo Stefano di Camastra"
                        },
                        {
                            "code": "083092",
                            "cap": "98047",
                            "nome": "Saponara"
                        },
                        {
                            "code": "083093",
                            "cap": "98038",
                            "nome": "Savoca"
                        },
                        {
                            "code": "083094",
                            "cap": "98029",
                            "nome": "Scaletta Zanclea"
                        },
                        {
                            "code": "083095",
                            "cap": "98069",
                            "nome": "Sinagra"
                        },
                        {
                            "code": "083096",
                            "cap": "98048",
                            "nome": "Spadafora"
                        },
                        {
                            "code": "083097",
                            "cap": "98039",
                            "nome": "Taormina"
                        },
                        {
                            "code": "083106",
                            "cap": "98050",
                            "nome": "Terme Vigliatore"
                        },
                        {
                            "code": "083098",
                            "cap": "98040",
                            "nome": "Torregrotta"
                        },
                        {
                            "code": "083108",
                            "cap": "98070",
                            "nome": "Torrenova"
                        },
                        {
                            "code": "083099",
                            "cap": "98078",
                            "nome": "Tortorici"
                        },
                        {
                            "code": "083100",
                            "cap": "98060",
                            "nome": "Tripi"
                        },
                        {
                            "code": "083101",
                            "cap": "98079",
                            "nome": "Tusa"
                        },
                        {
                            "code": "083102",
                            "cap": "98060",
                            "nome": "Ucria"
                        },
                        {
                            "code": "083103",
                            "cap": "98040",
                            "nome": "Valdina"
                        },
                        {
                            "code": "083104",
                            "cap": "98040",
                            "nome": "Venetico"
                        },
                        {
                            "code": "083105",
                            "cap": "98049",
                            "nome": "Villafranca Tirrena"
                        }
                    ],
                    "nome": "Messina"
                },
                {
                    "code": "PA",
                    "comuni": [
                        {
                            "code": "082001",
                            "cap": "90021",
                            "nome": "Alia"
                        },
                        {
                            "code": "082002",
                            "cap": "90020",
                            "nome": "Alimena"
                        },
                        {
                            "code": "082003",
                            "cap": "90020",
                            "nome": "Aliminusa"
                        },
                        {
                            "code": "082004",
                            "cap": "90010",
                            "nome": "Altavilla Milicia"
                        },
                        {
                            "code": "082005",
                            "cap": "90030",
                            "nome": "Altofonte"
                        },
                        {
                            "code": "082006",
                            "cap": "90011",
                            "nome": "Bagheria"
                        },
                        {
                            "code": "082007",
                            "cap": "90041",
                            "nome": "Balestrate"
                        },
                        {
                            "code": "082008",
                            "cap": "90020",
                            "nome": "Baucina"
                        },
                        {
                            "code": "082009",
                            "cap": "90031",
                            "nome": "Belmonte Mezzagno"
                        },
                        {
                            "code": "082010",
                            "cap": "90032",
                            "nome": "Bisacquino"
                        },
                        {
                            "code": "082082",
                            "cap": "90020",
                            "nome": "Blufi"
                        },
                        {
                            "code": "082011",
                            "cap": "90030",
                            "nome": "Bolognetta"
                        },
                        {
                            "code": "082012",
                            "cap": "90020",
                            "nome": "Bompietro"
                        },
                        {
                            "code": "082013",
                            "cap": "90042",
                            "nome": "Borgetto"
                        },
                        {
                            "code": "082014",
                            "cap": "90012",
                            "nome": "Caccamo"
                        },
                        {
                            "code": "082015",
                            "cap": "90022",
                            "nome": "Caltavuturo"
                        },
                        {
                            "code": "082016",
                            "cap": "90030",
                            "nome": "Campofelice di Fitalia"
                        },
                        {
                            "code": "082017",
                            "cap": "90010",
                            "nome": "Campofelice di Roccella"
                        },
                        {
                            "code": "082018",
                            "cap": "90030",
                            "nome": "Campofiorito"
                        },
                        {
                            "code": "082019",
                            "cap": "90043",
                            "nome": "Camporeale"
                        },
                        {
                            "code": "082020",
                            "cap": "90040",
                            "nome": "Capaci"
                        },
                        {
                            "code": "082021",
                            "cap": "90044",
                            "nome": "Carini"
                        },
                        {
                            "code": "082022",
                            "cap": "90013",
                            "nome": "Castelbuono"
                        },
                        {
                            "code": "082023",
                            "cap": "90014",
                            "nome": "Casteldaccia"
                        },
                        {
                            "code": "082024",
                            "cap": "90020",
                            "nome": "Castellana Sicula"
                        },
                        {
                            "code": "082025",
                            "cap": "90030",
                            "nome": "Castronovo di Sicilia"
                        },
                        {
                            "code": "082026",
                            "cap": "90030",
                            "nome": "Cefal\u00e0 Diana"
                        },
                        {
                            "code": "082027",
                            "cap": "90015",
                            "nome": "Cefal\u00f9"
                        },
                        {
                            "code": "082028",
                            "cap": "90010",
                            "nome": "Cerda"
                        },
                        {
                            "code": "082029",
                            "cap": "90033",
                            "nome": "Chiusa Sclafani"
                        },
                        {
                            "code": "082030",
                            "cap": "90023",
                            "nome": "Ciminna"
                        },
                        {
                            "code": "082031",
                            "cap": "90045",
                            "nome": "Cinisi"
                        },
                        {
                            "code": "082032",
                            "cap": "90016",
                            "nome": "Collesano"
                        },
                        {
                            "code": "082033",
                            "cap": "90030",
                            "nome": "Contessa Entellina"
                        },
                        {
                            "code": "082034",
                            "cap": "90034",
                            "nome": "Corleone"
                        },
                        {
                            "code": "082035",
                            "cap": "90010",
                            "nome": "Ficarazzi"
                        },
                        {
                            "code": "082036",
                            "cap": "90024",
                            "nome": "Gangi"
                        },
                        {
                            "code": "082037",
                            "cap": "90010",
                            "nome": "Geraci Siculo"
                        },
                        {
                            "code": "082038",
                            "cap": "90040",
                            "nome": "Giardinello"
                        },
                        {
                            "code": "082039",
                            "cap": "90030",
                            "nome": "Giuliana"
                        },
                        {
                            "code": "082040",
                            "cap": "90030",
                            "nome": "Godrano"
                        },
                        {
                            "code": "082041",
                            "cap": "90010",
                            "nome": "Gratteri"
                        },
                        {
                            "code": "082042",
                            "cap": "90010",
                            "nome": "Isnello"
                        },
                        {
                            "code": "082043",
                            "cap": "90040",
                            "nome": "Isola delle Femmine"
                        },
                        {
                            "code": "082044",
                            "cap": "90010",
                            "nome": "Lascari"
                        },
                        {
                            "code": "082045",
                            "cap": "90025",
                            "nome": "Lercara Friddi"
                        },
                        {
                            "code": "082046",
                            "cap": "90035",
                            "nome": "Marineo"
                        },
                        {
                            "code": "082047",
                            "cap": "90030",
                            "nome": "Mezzojuso"
                        },
                        {
                            "code": "082048",
                            "cap": "90036",
                            "nome": "Misilmeri"
                        },
                        {
                            "code": "082049",
                            "cap": "90046",
                            "nome": "Monreale"
                        },
                        {
                            "code": "082050",
                            "cap": "90040",
                            "nome": "Montelepre"
                        },
                        {
                            "code": "082051",
                            "cap": "90020",
                            "nome": "Montemaggiore Belsito"
                        },
                        {
                            "code": "082052",
                            "cap": "90030",
                            "nome": "Palazzo Adriano"
                        },
                        {
                            "code": "082053",
                            "cap": "901xx",
                            "nome": "Palermo"
                        },
                        {
                            "code": "082054",
                            "cap": "90047",
                            "nome": "Partinico"
                        },
                        {
                            "code": "082055",
                            "cap": "90026",
                            "nome": "Petralia Soprana"
                        },
                        {
                            "code": "082056",
                            "cap": "90027",
                            "nome": "Petralia Sottana"
                        },
                        {
                            "code": "082057",
                            "cap": "90037",
                            "nome": "Piana degli Albanesi"
                        },
                        {
                            "code": "082058",
                            "cap": "90028",
                            "nome": "Polizzi Generosa"
                        },
                        {
                            "code": "082059",
                            "cap": "90010",
                            "nome": "Pollina"
                        },
                        {
                            "code": "082060",
                            "cap": "90038",
                            "nome": "Prizzi"
                        },
                        {
                            "code": "082061",
                            "cap": "90040",
                            "nome": "Roccamena"
                        },
                        {
                            "code": "082062",
                            "cap": "90020",
                            "nome": "Roccapalumba"
                        },
                        {
                            "code": "082063",
                            "cap": "90040",
                            "nome": "San Cipirello"
                        },
                        {
                            "code": "082064",
                            "cap": "90048",
                            "nome": "San Giuseppe Jato"
                        },
                        {
                            "code": "082065",
                            "cap": "90010",
                            "nome": "San Mauro Castelverde"
                        },
                        {
                            "code": "082066",
                            "cap": "90030",
                            "nome": "Santa Cristina Gela"
                        },
                        {
                            "code": "082067",
                            "cap": "90017",
                            "nome": "Santa Flavia"
                        },
                        {
                            "code": "082068",
                            "cap": "90020",
                            "nome": "Sciara"
                        },
                        {
                            "code": "082081",
                            "cap": "90020",
                            "nome": "Scillato"
                        },
                        {
                            "code": "082069",
                            "cap": "90020",
                            "nome": "Sclafani Bagni"
                        },
                        {
                            "code": "082070",
                            "cap": "90018",
                            "nome": "Termini Imerese"
                        },
                        {
                            "code": "082071",
                            "cap": "90049",
                            "nome": "Terrasini"
                        },
                        {
                            "code": "082072",
                            "cap": "90040",
                            "nome": "Torretta"
                        },
                        {
                            "code": "082073",
                            "cap": "90019",
                            "nome": "Trabia"
                        },
                        {
                            "code": "082074",
                            "cap": "90040",
                            "nome": "Trappeto"
                        },
                        {
                            "code": "082075",
                            "cap": "90010",
                            "nome": "Ustica"
                        },
                        {
                            "code": "082076",
                            "cap": "90029",
                            "nome": "Valledolmo"
                        },
                        {
                            "code": "082077",
                            "cap": "90020",
                            "nome": "Ventimiglia di Sicilia"
                        },
                        {
                            "code": "082078",
                            "cap": "90020",
                            "nome": "Vicari"
                        },
                        {
                            "code": "082079",
                            "cap": "90039",
                            "nome": "Villabate"
                        },
                        {
                            "code": "082080",
                            "cap": "90030",
                            "nome": "Villafrati"
                        }
                    ],
                    "nome": "Palermo"
                },
                {
                    "code": "RG",
                    "comuni": [
                        {
                            "code": "088001",
                            "cap": "97011",
                            "nome": "Acate"
                        },
                        {
                            "code": "088002",
                            "cap": "97012",
                            "nome": "Chiaramonte Gulfi"
                        },
                        {
                            "code": "088003",
                            "cap": "97013",
                            "nome": "Comiso"
                        },
                        {
                            "code": "088004",
                            "cap": "97010",
                            "nome": "Giarratana"
                        },
                        {
                            "code": "088005",
                            "cap": "97014",
                            "nome": "Ispica"
                        },
                        {
                            "code": "088006",
                            "cap": "97015",
                            "nome": "Modica"
                        },
                        {
                            "code": "088007",
                            "cap": "97010",
                            "nome": "Monterosso Almo"
                        },
                        {
                            "code": "088008",
                            "cap": "97016",
                            "nome": "Pozzallo"
                        },
                        {
                            "code": "088009",
                            "cap": "97100",
                            "nome": "Ragusa"
                        },
                        {
                            "code": "088010",
                            "cap": "97017",
                            "nome": "Santa Croce Camerina"
                        },
                        {
                            "code": "088011",
                            "cap": "97018",
                            "nome": "Scicli"
                        },
                        {
                            "code": "088012",
                            "cap": "97019",
                            "nome": "Vittoria"
                        }
                    ],
                    "nome": "Ragusa"
                },
                {
                    "code": "SR",
                    "comuni": [
                        {
                            "code": "089001",
                            "cap": "96011",
                            "nome": "Augusta"
                        },
                        {
                            "code": "089002",
                            "cap": "96012",
                            "nome": "Avola"
                        },
                        {
                            "code": "089003",
                            "cap": "96010",
                            "nome": "Buccheri"
                        },
                        {
                            "code": "089004",
                            "cap": "96010",
                            "nome": "Buscemi"
                        },
                        {
                            "code": "089005",
                            "cap": "96010",
                            "nome": "Canicattini Bagni"
                        },
                        {
                            "code": "089006",
                            "cap": "96013",
                            "nome": "Carlentini"
                        },
                        {
                            "code": "089007",
                            "cap": "96010",
                            "nome": "Cassaro"
                        },
                        {
                            "code": "089008",
                            "cap": "96010",
                            "nome": "Ferla"
                        },
                        {
                            "code": "089009",
                            "cap": "96014",
                            "nome": "Floridia"
                        },
                        {
                            "code": "089010",
                            "cap": "96015",
                            "nome": "Francofonte"
                        },
                        {
                            "code": "089011",
                            "cap": "96016",
                            "nome": "Lentini"
                        },
                        {
                            "code": "089012",
                            "cap": "96010",
                            "nome": "Melilli"
                        },
                        {
                            "code": "089013",
                            "cap": "96017",
                            "nome": "Noto"
                        },
                        {
                            "code": "089014",
                            "cap": "96018",
                            "nome": "Pachino"
                        },
                        {
                            "code": "089015",
                            "cap": "96010",
                            "nome": "Palazzolo Acreide"
                        },
                        {
                            "code": "089020",
                            "cap": "96010",
                            "nome": "Portopalo di Capo Passero"
                        },
                        {
                            "code": "089021",
                            "cap": "96010",
                            "nome": "Priolo Gargallo"
                        },
                        {
                            "code": "089016",
                            "cap": "96019",
                            "nome": "Rosolini"
                        },
                        {
                            "code": "089017",
                            "cap": "96100",
                            "nome": "Siracusa"
                        },
                        {
                            "code": "089018",
                            "cap": "96010",
                            "nome": "Solarino"
                        },
                        {
                            "code": "089019",
                            "cap": "96010",
                            "nome": "Sortino"
                        }
                    ],
                    "nome": "Siracusa"
                },
                {
                    "code": "TP",
                    "comuni": [
                        {
                            "code": "081001",
                            "cap": "91011",
                            "nome": "Alcamo"
                        },
                        {
                            "code": "081002",
                            "cap": "91012",
                            "nome": "Buseto Palizzolo"
                        },
                        {
                            "code": "081003",
                            "cap": "91013",
                            "nome": "Calatafimi Segesta"
                        },
                        {
                            "code": "081004",
                            "cap": "91021",
                            "nome": "Campobello di Mazara"
                        },
                        {
                            "code": "081005",
                            "cap": "91014",
                            "nome": "Castellammare del Golfo"
                        },
                        {
                            "code": "081006",
                            "cap": "91022",
                            "nome": "Castelvetrano"
                        },
                        {
                            "code": "081007",
                            "cap": "91015",
                            "nome": "Custonaci"
                        },
                        {
                            "code": "081008",
                            "cap": "91016",
                            "nome": "Erice"
                        },
                        {
                            "code": "081009",
                            "cap": "91023",
                            "nome": "Favignana"
                        },
                        {
                            "code": "081010",
                            "cap": "91024",
                            "nome": "Gibellina"
                        },
                        {
                            "code": "081011",
                            "cap": "91025",
                            "nome": "Marsala"
                        },
                        {
                            "code": "081012",
                            "cap": "91026",
                            "nome": "Mazara del Vallo"
                        },
                        {
                            "code": "081013",
                            "cap": "91027",
                            "nome": "Paceco"
                        },
                        {
                            "code": "081014",
                            "cap": "91017",
                            "nome": "Pantelleria"
                        },
                        {
                            "code": "081015",
                            "cap": "91028",
                            "nome": "Partanna"
                        },
                        {
                            "code": "081024",
                            "cap": "91020",
                            "nome": "Petrosino"
                        },
                        {
                            "code": "081016",
                            "cap": "91020",
                            "nome": "Poggioreale"
                        },
                        {
                            "code": "081017",
                            "cap": "91020",
                            "nome": "Salaparuta"
                        },
                        {
                            "code": "081018",
                            "cap": "91018",
                            "nome": "Salemi"
                        },
                        {
                            "code": "081020",
                            "cap": "91010",
                            "nome": "San Vito Lo Capo"
                        },
                        {
                            "code": "081019",
                            "cap": "91029",
                            "nome": "Santa Ninfa"
                        },
                        {
                            "code": "081021",
                            "cap": "91100",
                            "nome": "Trapani"
                        },
                        {
                            "code": "081022",
                            "cap": "91019",
                            "nome": "Valderice"
                        },
                        {
                            "code": "081023",
                            "cap": "91010",
                            "nome": "Vita"
                        }
                    ],
                    "nome": "Trapani"
                }
            ],
            "nome": "Sicilia"
        },
        {
            "province": [
                {
                    "code": "AR",
                    "comuni": [
                        {
                            "code": "051001",
                            "cap": "52031",
                            "nome": "Anghiari"
                        },
                        {
                            "code": "051002",
                            "cap": "52100",
                            "nome": "Arezzo"
                        },
                        {
                            "code": "051003",
                            "cap": "52032",
                            "nome": "Badia Tedalda"
                        },
                        {
                            "code": "051004",
                            "cap": "52011",
                            "nome": "Bibbiena"
                        },
                        {
                            "code": "051005",
                            "cap": "52021",
                            "nome": "Bucine"
                        },
                        {
                            "code": "051006",
                            "cap": "52010",
                            "nome": "Capolona"
                        },
                        {
                            "code": "051007",
                            "cap": "52033",
                            "nome": "Caprese Michelangelo"
                        },
                        {
                            "code": "051008",
                            "cap": "52016",
                            "nome": "Castel Focognano"
                        },
                        {
                            "code": "051010",
                            "cap": "52018",
                            "nome": "Castel San Niccol\u00f2"
                        },
                        {
                            "code": "051009",
                            "cap": "52020",
                            "nome": "Castelfranco di Sopra"
                        },
                        {
                            "code": "051011",
                            "cap": "52029",
                            "nome": "Castiglion Fibocchi"
                        },
                        {
                            "code": "051012",
                            "cap": "52043",
                            "nome": "Castiglion Fiorentino"
                        },
                        {
                            "code": "051013",
                            "cap": "52022",
                            "nome": "Cavriglia"
                        },
                        {
                            "code": "051014",
                            "cap": "52010",
                            "nome": "Chitignano"
                        },
                        {
                            "code": "051015",
                            "cap": "52010",
                            "nome": "Chiusi della Verna"
                        },
                        {
                            "code": "051016",
                            "cap": "52041",
                            "nome": "Civitella in Val di Chiana"
                        },
                        {
                            "code": "051017",
                            "cap": "52044",
                            "nome": "Cortona"
                        },
                        {
                            "code": "051018",
                            "cap": "52045",
                            "nome": "Foiano della Chiana"
                        },
                        {
                            "code": "051019",
                            "cap": "52020",
                            "nome": "Laterina"
                        },
                        {
                            "code": "051020",
                            "cap": "52024",
                            "nome": "Loro Ciuffenna"
                        },
                        {
                            "code": "051021",
                            "cap": "52046",
                            "nome": "Lucignano"
                        },
                        {
                            "code": "051022",
                            "cap": "52047",
                            "nome": "Marciano della Chiana"
                        },
                        {
                            "code": "051025",
                            "cap": "52048",
                            "nome": "Monte San Savino"
                        },
                        {
                            "code": "051023",
                            "cap": "52010",
                            "nome": "Montemignaio"
                        },
                        {
                            "code": "051024",
                            "cap": "52035",
                            "nome": "Monterchi"
                        },
                        {
                            "code": "051026",
                            "cap": "52025",
                            "nome": "Montevarchi"
                        },
                        {
                            "code": "051027",
                            "cap": "52010",
                            "nome": "Ortignano Raggiolo"
                        },
                        {
                            "code": "051028",
                            "cap": "52020",
                            "nome": "Pergine Valdarno"
                        },
                        {
                            "code": "051029",
                            "cap": "52026",
                            "nome": "Pian di Sco"
                        },
                        {
                            "code": "051030",
                            "cap": "52036",
                            "nome": "Pieve Santo Stefano"
                        },
                        {
                            "code": "051031",
                            "cap": "52014",
                            "nome": "Poppi"
                        },
                        {
                            "code": "051032",
                            "cap": "52015",
                            "nome": "Pratovecchio"
                        },
                        {
                            "code": "051033",
                            "cap": "52027",
                            "nome": "San Giovanni Valdarno"
                        },
                        {
                            "code": "051034",
                            "cap": "52037",
                            "nome": "Sansepolcro"
                        },
                        {
                            "code": "051035",
                            "cap": "52038",
                            "nome": "Sestino"
                        },
                        {
                            "code": "051036",
                            "cap": "52017",
                            "nome": "Stia"
                        },
                        {
                            "code": "051037",
                            "cap": "52010",
                            "nome": "Subbiano"
                        },
                        {
                            "code": "051038",
                            "cap": "52010",
                            "nome": "Talla"
                        },
                        {
                            "code": "051039",
                            "cap": "52028",
                            "nome": "Terranuova Bracciolini"
                        }
                    ],
                    "nome": "Arezzo"
                },
                {
                    "code": "FI",
                    "comuni": [
                        {
                            "code": "048001",
                            "cap": "50012",
                            "nome": "Bagno a Ripoli"
                        },
                        {
                            "code": "048002",
                            "cap": "50031",
                            "nome": "Barberino di Mugello"
                        },
                        {
                            "code": "048003",
                            "cap": "50021",
                            "nome": "Barberino Val d'Elsa"
                        },
                        {
                            "code": "048004",
                            "cap": "50032",
                            "nome": "Borgo San Lorenzo"
                        },
                        {
                            "code": "048005",
                            "cap": "50041",
                            "nome": "Calenzano"
                        },
                        {
                            "code": "048006",
                            "cap": "50013",
                            "nome": "Campi Bisenzio"
                        },
                        {
                            "code": "048008",
                            "cap": "50050",
                            "nome": "Capraia e Limite"
                        },
                        {
                            "code": "048010",
                            "cap": "50051",
                            "nome": "Castelfiorentino"
                        },
                        {
                            "code": "048011",
                            "cap": "50050",
                            "nome": "Cerreto Guidi"
                        },
                        {
                            "code": "048012",
                            "cap": "50052",
                            "nome": "Certaldo"
                        },
                        {
                            "code": "048013",
                            "cap": "50062",
                            "nome": "Dicomano"
                        },
                        {
                            "code": "048014",
                            "cap": "50053",
                            "nome": "Empoli"
                        },
                        {
                            "code": "048015",
                            "cap": "50014",
                            "nome": "Fiesole"
                        },
                        {
                            "code": "048016",
                            "cap": "50063",
                            "nome": "Figline Valdarno"
                        },
                        {
                            "code": "048017",
                            "cap": "501xx",
                            "nome": "Firenze"
                        },
                        {
                            "code": "048018",
                            "cap": "50033",
                            "nome": "Firenzuola"
                        },
                        {
                            "code": "048019",
                            "cap": "50054",
                            "nome": "Fucecchio"
                        },
                        {
                            "code": "048020",
                            "cap": "50050",
                            "nome": "Gambassi Terme"
                        },
                        {
                            "code": "048021",
                            "cap": "50022",
                            "nome": "Greve in Chianti"
                        },
                        {
                            "code": "048022",
                            "cap": "50023",
                            "nome": "Impruneta"
                        },
                        {
                            "code": "048023",
                            "cap": "50064",
                            "nome": "Incisa in Val d'Arno"
                        },
                        {
                            "code": "048024",
                            "cap": "50055",
                            "nome": "Lastra a Signa"
                        },
                        {
                            "code": "048025",
                            "cap": "50060",
                            "nome": "Londa"
                        },
                        {
                            "code": "048026",
                            "cap": "50034",
                            "nome": "Marradi"
                        },
                        {
                            "code": "048027",
                            "cap": "50050",
                            "nome": "Montaione"
                        },
                        {
                            "code": "048028",
                            "cap": "50056",
                            "nome": "Montelupo Fiorentino"
                        },
                        {
                            "code": "048030",
                            "cap": "50025",
                            "nome": "Montespertoli"
                        },
                        {
                            "code": "048031",
                            "cap": "50035",
                            "nome": "Palazzuolo sul Senio"
                        },
                        {
                            "code": "048032",
                            "cap": "50060",
                            "nome": "Pelago"
                        },
                        {
                            "code": "048033",
                            "cap": "50065",
                            "nome": "Pontassieve"
                        },
                        {
                            "code": "048035",
                            "cap": "50066",
                            "nome": "Reggello"
                        },
                        {
                            "code": "048036",
                            "cap": "50067",
                            "nome": "Rignano sull'Arno"
                        },
                        {
                            "code": "048037",
                            "cap": "50068",
                            "nome": "Rufina"
                        },
                        {
                            "code": "048038",
                            "cap": "50026",
                            "nome": "San Casciano in Val di Pesa"
                        },
                        {
                            "code": "048039",
                            "cap": "50060",
                            "nome": "San Godenzo"
                        },
                        {
                            "code": "048040",
                            "cap": "50037",
                            "nome": "San Piero a Sieve"
                        },
                        {
                            "code": "048041",
                            "cap": "50018",
                            "nome": "Scandicci"
                        },
                        {
                            "code": "048042",
                            "cap": "50038",
                            "nome": "Scarperia"
                        },
                        {
                            "code": "048043",
                            "cap": "50019",
                            "nome": "Sesto Fiorentino"
                        },
                        {
                            "code": "048044",
                            "cap": "50058",
                            "nome": "Signa"
                        },
                        {
                            "code": "048045",
                            "cap": "50028",
                            "nome": "Tavarnelle Val di Pesa"
                        },
                        {
                            "code": "048046",
                            "cap": "50036",
                            "nome": "Vaglia"
                        },
                        {
                            "code": "048049",
                            "cap": "50039",
                            "nome": "Vicchio"
                        },
                        {
                            "code": "048050",
                            "cap": "50059",
                            "nome": "Vinci"
                        }
                    ],
                    "nome": "Firenze"
                },
                {
                    "code": "GR",
                    "comuni": [
                        {
                            "code": "053001",
                            "cap": "58031",
                            "nome": "Arcidosso"
                        },
                        {
                            "code": "053002",
                            "cap": "58042",
                            "nome": "Campagnatico"
                        },
                        {
                            "code": "053003",
                            "cap": "58011",
                            "nome": "Capalbio"
                        },
                        {
                            "code": "053004",
                            "cap": "58033",
                            "nome": "Castel del Piano"
                        },
                        {
                            "code": "053005",
                            "cap": "58034",
                            "nome": "Castell'Azzara"
                        },
                        {
                            "code": "053006",
                            "cap": "58043",
                            "nome": "Castiglione della Pescaia"
                        },
                        {
                            "code": "053007",
                            "cap": "58044",
                            "nome": "Cinigiano"
                        },
                        {
                            "code": "053008",
                            "cap": "58045",
                            "nome": "Civitella Paganico"
                        },
                        {
                            "code": "053009",
                            "cap": "58022",
                            "nome": "Follonica"
                        },
                        {
                            "code": "053010",
                            "cap": "58023",
                            "nome": "Gavorrano"
                        },
                        {
                            "code": "053011",
                            "cap": "58100",
                            "nome": "Grosseto"
                        },
                        {
                            "code": "053012",
                            "cap": "58012",
                            "nome": "Isola del Giglio"
                        },
                        {
                            "code": "053013",
                            "cap": "58051",
                            "nome": "Magliano in Toscana"
                        },
                        {
                            "code": "053014",
                            "cap": "58014",
                            "nome": "Manciano"
                        },
                        {
                            "code": "053015",
                            "cap": "58024",
                            "nome": "Massa Marittima"
                        },
                        {
                            "code": "053016",
                            "cap": "58019",
                            "nome": "Monte Argentario"
                        },
                        {
                            "code": "053027",
                            "cap": "58025",
                            "nome": "Monterotondo Marittimo"
                        },
                        {
                            "code": "053017",
                            "cap": "58026",
                            "nome": "Montieri"
                        },
                        {
                            "code": "053018",
                            "cap": "58015",
                            "nome": "Orbetello"
                        },
                        {
                            "code": "053019",
                            "cap": "58017",
                            "nome": "Pitigliano"
                        },
                        {
                            "code": "053020",
                            "cap": "58053",
                            "nome": "Roccalbegna"
                        },
                        {
                            "code": "053021",
                            "cap": "58036",
                            "nome": "Roccastrada"
                        },
                        {
                            "code": "053022",
                            "cap": "58037",
                            "nome": "Santa Fiora"
                        },
                        {
                            "code": "053023",
                            "cap": "58054",
                            "nome": "Scansano"
                        },
                        {
                            "code": "053024",
                            "cap": "58020",
                            "nome": "Scarlino"
                        },
                        {
                            "code": "053025",
                            "cap": "58038",
                            "nome": "Seggiano"
                        },
                        {
                            "code": "053028",
                            "cap": "58055",
                            "nome": "Semproniano"
                        },
                        {
                            "code": "053026",
                            "cap": "58010",
                            "nome": "Sorano"
                        }
                    ],
                    "nome": "Grosseto"
                },
                {
                    "code": "LI",
                    "comuni": [
                        {
                            "code": "049001",
                            "cap": "57020",
                            "nome": "Bibbona"
                        },
                        {
                            "code": "049002",
                            "cap": "57021",
                            "nome": "Campiglia Marittima"
                        },
                        {
                            "code": "049003",
                            "cap": "57034",
                            "nome": "Campo nell'Elba"
                        },
                        {
                            "code": "049004",
                            "cap": "57031",
                            "nome": "Capoliveri"
                        },
                        {
                            "code": "049005",
                            "cap": "57032",
                            "nome": "Capraia Isola"
                        },
                        {
                            "code": "049006",
                            "cap": "57022",
                            "nome": "Castagneto Carducci"
                        },
                        {
                            "code": "049007",
                            "cap": "57023",
                            "nome": "Cecina"
                        },
                        {
                            "code": "049008",
                            "cap": "57014",
                            "nome": "Collesalvetti"
                        },
                        {
                            "code": "049009",
                            "cap": "571xx",
                            "nome": "Livorno"
                        },
                        {
                            "code": "049010",
                            "cap": "57030",
                            "nome": "Marciana"
                        },
                        {
                            "code": "049011",
                            "cap": "57033",
                            "nome": "Marciana Marina"
                        },
                        {
                            "code": "049012",
                            "cap": "57025",
                            "nome": "Piombino"
                        },
                        {
                            "code": "049013",
                            "cap": "57036",
                            "nome": "Porto Azzurro"
                        },
                        {
                            "code": "049014",
                            "cap": "57037",
                            "nome": "Portoferraio"
                        },
                        {
                            "code": "049015",
                            "cap": "57038",
                            "nome": "Rio Marina"
                        },
                        {
                            "code": "049016",
                            "cap": "57039",
                            "nome": "Rio nell'Elba"
                        },
                        {
                            "code": "049017",
                            "cap": "57016",
                            "nome": "Rosignano Marittimo"
                        },
                        {
                            "code": "049018",
                            "cap": "57027",
                            "nome": "San Vincenzo"
                        },
                        {
                            "code": "049019",
                            "cap": "57020",
                            "nome": "Sassetta"
                        },
                        {
                            "code": "049020",
                            "cap": "57028",
                            "nome": "Suvereto"
                        }
                    ],
                    "nome": "Livorno"
                },
                {
                    "code": "LU",
                    "comuni": [
                        {
                            "code": "046001",
                            "cap": "55011",
                            "nome": "Altopascio"
                        },
                        {
                            "code": "046002",
                            "cap": "55022",
                            "nome": "Bagni di Lucca"
                        },
                        {
                            "code": "046003",
                            "cap": "55051",
                            "nome": "Barga"
                        },
                        {
                            "code": "046004",
                            "cap": "55023",
                            "nome": "Borgo a Mozzano"
                        },
                        {
                            "code": "046005",
                            "cap": "55041",
                            "nome": "Camaiore"
                        },
                        {
                            "code": "046006",
                            "cap": "55031",
                            "nome": "Camporgiano"
                        },
                        {
                            "code": "046007",
                            "cap": "55012",
                            "nome": "Capannori"
                        },
                        {
                            "code": "046008",
                            "cap": "55030",
                            "nome": "Careggine"
                        },
                        {
                            "code": "046009",
                            "cap": "55032",
                            "nome": "Castelnuovo di Garfagnana"
                        },
                        {
                            "code": "046010",
                            "cap": "55033",
                            "nome": "Castiglione di Garfagnana"
                        },
                        {
                            "code": "046011",
                            "cap": "55025",
                            "nome": "Coreglia Antelminelli"
                        },
                        {
                            "code": "046012",
                            "cap": "55020",
                            "nome": "Fabbriche di Vallico"
                        },
                        {
                            "code": "046013",
                            "cap": "55042",
                            "nome": "Forte dei Marmi"
                        },
                        {
                            "code": "046014",
                            "cap": "55020",
                            "nome": "Fosciandora"
                        },
                        {
                            "code": "046015",
                            "cap": "55027",
                            "nome": "Gallicano"
                        },
                        {
                            "code": "046016",
                            "cap": "55030",
                            "nome": "Giuncugnano"
                        },
                        {
                            "code": "046017",
                            "cap": "55100",
                            "nome": "Lucca"
                        },
                        {
                            "code": "046018",
                            "cap": "55054",
                            "nome": "Massarosa"
                        },
                        {
                            "code": "046019",
                            "cap": "55034",
                            "nome": "Minucciano"
                        },
                        {
                            "code": "046020",
                            "cap": "55020",
                            "nome": "Molazzana"
                        },
                        {
                            "code": "046021",
                            "cap": "55015",
                            "nome": "Montecarlo"
                        },
                        {
                            "code": "046022",
                            "cap": "55064",
                            "nome": "Pescaglia"
                        },
                        {
                            "code": "046023",
                            "cap": "55035",
                            "nome": "Piazza al Serchio"
                        },
                        {
                            "code": "046024",
                            "cap": "55045",
                            "nome": "Pietrasanta"
                        },
                        {
                            "code": "046025",
                            "cap": "55036",
                            "nome": "Pieve Fosciana"
                        },
                        {
                            "code": "046026",
                            "cap": "55016",
                            "nome": "Porcari"
                        },
                        {
                            "code": "046027",
                            "cap": "55038",
                            "nome": "San Romano in Garfagnana"
                        },
                        {
                            "code": "046028",
                            "cap": "55047",
                            "nome": "Seravezza"
                        },
                        {
                            "code": "046029",
                            "cap": "55030",
                            "nome": "Sillano"
                        },
                        {
                            "code": "046030",
                            "cap": "55040",
                            "nome": "Stazzema"
                        },
                        {
                            "code": "046031",
                            "cap": "55030",
                            "nome": "Vagli Sotto"
                        },
                        {
                            "code": "046032",
                            "cap": "55020",
                            "nome": "Vergemoli"
                        },
                        {
                            "code": "046033",
                            "cap": "55049",
                            "nome": "Viareggio"
                        },
                        {
                            "code": "046034",
                            "cap": "55019",
                            "nome": "Villa Basilica"
                        },
                        {
                            "code": "046035",
                            "cap": "55030",
                            "nome": "Villa Collemandina"
                        }
                    ],
                    "nome": "Lucca"
                },
                {
                    "code": "MS",
                    "comuni": [
                        {
                            "code": "045001",
                            "cap": "54011",
                            "nome": "Aulla"
                        },
                        {
                            "code": "045002",
                            "cap": "54021",
                            "nome": "Bagnone"
                        },
                        {
                            "code": "045003",
                            "cap": "54033",
                            "nome": "Carrara"
                        },
                        {
                            "code": "045004",
                            "cap": "54014",
                            "nome": "Casola in Lunigiana"
                        },
                        {
                            "code": "045005",
                            "cap": "54015",
                            "nome": "Comano"
                        },
                        {
                            "code": "045006",
                            "cap": "54023",
                            "nome": "Filattiera"
                        },
                        {
                            "code": "045007",
                            "cap": "54013",
                            "nome": "Fivizzano"
                        },
                        {
                            "code": "045008",
                            "cap": "54035",
                            "nome": "Fosdinovo"
                        },
                        {
                            "code": "045009",
                            "cap": "54016",
                            "nome": "Licciana Nardi"
                        },
                        {
                            "code": "045010",
                            "cap": "54100",
                            "nome": "Massa"
                        },
                        {
                            "code": "045011",
                            "cap": "54038",
                            "nome": "Montignoso"
                        },
                        {
                            "code": "045012",
                            "cap": "54026",
                            "nome": "Mulazzo"
                        },
                        {
                            "code": "045013",
                            "cap": "54010",
                            "nome": "Podenzana"
                        },
                        {
                            "code": "045014",
                            "cap": "54027",
                            "nome": "Pontremoli"
                        },
                        {
                            "code": "045015",
                            "cap": "54012",
                            "nome": "Tresana"
                        },
                        {
                            "code": "045016",
                            "cap": "54028",
                            "nome": "Villafranca in Lunigiana"
                        },
                        {
                            "code": "045017",
                            "cap": "54029",
                            "nome": "Zeri"
                        }
                    ],
                    "nome": "Massa e Carrara"
                },
                {
                    "code": "PI",
                    "comuni": [
                        {
                            "code": "050001",
                            "cap": "56031",
                            "nome": "Bientina"
                        },
                        {
                            "code": "050002",
                            "cap": "56032",
                            "nome": "Buti"
                        },
                        {
                            "code": "050003",
                            "cap": "56011",
                            "nome": "Calci"
                        },
                        {
                            "code": "050004",
                            "cap": "56012",
                            "nome": "Calcinaia"
                        },
                        {
                            "code": "050005",
                            "cap": "56033",
                            "nome": "Capannoli"
                        },
                        {
                            "code": "050006",
                            "cap": "56040",
                            "nome": "Casale Marittimo"
                        },
                        {
                            "code": "050007",
                            "cap": "56034",
                            "nome": "Casciana Terme"
                        },
                        {
                            "code": "050008",
                            "cap": "56021",
                            "nome": "Cascina"
                        },
                        {
                            "code": "050009",
                            "cap": "56022",
                            "nome": "Castelfranco di Sotto"
                        },
                        {
                            "code": "050010",
                            "cap": "56040",
                            "nome": "Castellina Marittima"
                        },
                        {
                            "code": "050011",
                            "cap": "56041",
                            "nome": "Castelnuovo di Val di Cecina"
                        },
                        {
                            "code": "050012",
                            "cap": "56034",
                            "nome": "Chianni"
                        },
                        {
                            "code": "050013",
                            "cap": "56040",
                            "nome": "Crespina"
                        },
                        {
                            "code": "050014",
                            "cap": "56043",
                            "nome": "Fauglia"
                        },
                        {
                            "code": "050015",
                            "cap": "56040",
                            "nome": "Guardistallo"
                        },
                        {
                            "code": "050016",
                            "cap": "56030",
                            "nome": "Lajatico"
                        },
                        {
                            "code": "050017",
                            "cap": "56035",
                            "nome": "Lari"
                        },
                        {
                            "code": "050018",
                            "cap": "56043",
                            "nome": "Lorenzana"
                        },
                        {
                            "code": "050019",
                            "cap": "56040",
                            "nome": "Montecatini Val di Cecina"
                        },
                        {
                            "code": "050020",
                            "cap": "56040",
                            "nome": "Montescudaio"
                        },
                        {
                            "code": "050021",
                            "cap": "56040",
                            "nome": "Monteverdi Marittimo"
                        },
                        {
                            "code": "050022",
                            "cap": "56020",
                            "nome": "Montopoli in Val d'Arno"
                        },
                        {
                            "code": "050023",
                            "cap": "56040",
                            "nome": "Orciano Pisano"
                        },
                        {
                            "code": "050024",
                            "cap": "56036",
                            "nome": "Palaia"
                        },
                        {
                            "code": "050025",
                            "cap": "56037",
                            "nome": "Peccioli"
                        },
                        {
                            "code": "050026",
                            "cap": "5612x",
                            "nome": "Pisa"
                        },
                        {
                            "code": "050027",
                            "cap": "56045",
                            "nome": "Pomarance"
                        },
                        {
                            "code": "050028",
                            "cap": "56038",
                            "nome": "Ponsacco"
                        },
                        {
                            "code": "050029",
                            "cap": "56025",
                            "nome": "Pontedera"
                        },
                        {
                            "code": "050030",
                            "cap": "56046",
                            "nome": "Riparbella"
                        },
                        {
                            "code": "050031",
                            "cap": "56017",
                            "nome": "San Giuliano Terme"
                        },
                        {
                            "code": "050032",
                            "cap": "56028",
                            "nome": "San Miniato"
                        },
                        {
                            "code": "050033",
                            "cap": "56029",
                            "nome": "Santa Croce sull'Arno"
                        },
                        {
                            "code": "050034",
                            "cap": "56040",
                            "nome": "Santa Luce"
                        },
                        {
                            "code": "050035",
                            "cap": "56020",
                            "nome": "Santa Maria a Monte"
                        },
                        {
                            "code": "050036",
                            "cap": "56030",
                            "nome": "Terricciola"
                        },
                        {
                            "code": "050037",
                            "cap": "56019",
                            "nome": "Vecchiano"
                        },
                        {
                            "code": "050038",
                            "cap": "56010",
                            "nome": "Vicopisano"
                        },
                        {
                            "code": "050039",
                            "cap": "56048",
                            "nome": "Volterra"
                        }
                    ],
                    "nome": "Pisa"
                },
                {
                    "code": "PT",
                    "comuni": [
                        {
                            "code": "047001",
                            "cap": "51021",
                            "nome": "Abetone"
                        },
                        {
                            "code": "047002",
                            "cap": "51031",
                            "nome": "Agliana"
                        },
                        {
                            "code": "047003",
                            "cap": "51011",
                            "nome": "Buggiano"
                        },
                        {
                            "code": "047022",
                            "cap": "51013",
                            "nome": "Chiesina Uzzanese"
                        },
                        {
                            "code": "047004",
                            "cap": "51024",
                            "nome": "Cutigliano"
                        },
                        {
                            "code": "047005",
                            "cap": "51035",
                            "nome": "Lamporecchio"
                        },
                        {
                            "code": "047006",
                            "cap": "51036",
                            "nome": "Larciano"
                        },
                        {
                            "code": "047007",
                            "cap": "51010",
                            "nome": "Marliana"
                        },
                        {
                            "code": "047008",
                            "cap": "51010",
                            "nome": "Massa e Cozzile"
                        },
                        {
                            "code": "047009",
                            "cap": "51015",
                            "nome": "Monsummano Terme"
                        },
                        {
                            "code": "047010",
                            "cap": "51037",
                            "nome": "Montale"
                        },
                        {
                            "code": "047011",
                            "cap": "51016",
                            "nome": "Montecatini-Terme"
                        },
                        {
                            "code": "047012",
                            "cap": "51017",
                            "nome": "Pescia"
                        },
                        {
                            "code": "047013",
                            "cap": "51018",
                            "nome": "Pieve a Nievole"
                        },
                        {
                            "code": "047014",
                            "cap": "51100",
                            "nome": "Pistoia"
                        },
                        {
                            "code": "047015",
                            "cap": "51020",
                            "nome": "Piteglio"
                        },
                        {
                            "code": "047016",
                            "cap": "51019",
                            "nome": "Ponte Buggianese"
                        },
                        {
                            "code": "047017",
                            "cap": "51039",
                            "nome": "Quarrata"
                        },
                        {
                            "code": "047018",
                            "cap": "51020",
                            "nome": "Sambuca Pistoiese"
                        },
                        {
                            "code": "047019",
                            "cap": "51028",
                            "nome": "San Marcello Pistoiese"
                        },
                        {
                            "code": "047020",
                            "cap": "51030",
                            "nome": "Serravalle Pistoiese"
                        },
                        {
                            "code": "047021",
                            "cap": "51010",
                            "nome": "Uzzano"
                        }
                    ],
                    "nome": "Pistoia"
                },
                {
                    "code": "PO",
                    "comuni": [
                        {
                            "code": "100001",
                            "cap": "59025",
                            "nome": "Cantagallo"
                        },
                        {
                            "code": "100002",
                            "cap": "59015",
                            "nome": "Carmignano"
                        },
                        {
                            "code": "100003",
                            "cap": "59013",
                            "nome": "Montemurlo"
                        },
                        {
                            "code": "100004",
                            "cap": "59016",
                            "nome": "Poggio a Caiano"
                        },
                        {
                            "code": "100005",
                            "cap": "59100",
                            "nome": "Prato"
                        },
                        {
                            "code": "100006",
                            "cap": "59021",
                            "nome": "Vaiano"
                        },
                        {
                            "code": "100007",
                            "cap": "59024",
                            "nome": "Vernio"
                        }
                    ],
                    "nome": "Prato"
                },
                {
                    "code": "SI",
                    "comuni": [
                        {
                            "code": "052001",
                            "cap": "53021",
                            "nome": "Abbadia San Salvatore"
                        },
                        {
                            "code": "052002",
                            "cap": "53041",
                            "nome": "Asciano"
                        },
                        {
                            "code": "052003",
                            "cap": "53022",
                            "nome": "Buonconvento"
                        },
                        {
                            "code": "052004",
                            "cap": "53031",
                            "nome": "Casole d'Elsa"
                        },
                        {
                            "code": "052005",
                            "cap": "53011",
                            "nome": "Castellina in Chianti"
                        },
                        {
                            "code": "052006",
                            "cap": "53019",
                            "nome": "Castelnuovo Berardenga"
                        },
                        {
                            "code": "052007",
                            "cap": "53023",
                            "nome": "Castiglione d'Orcia"
                        },
                        {
                            "code": "052008",
                            "cap": "53040",
                            "nome": "Cetona"
                        },
                        {
                            "code": "052009",
                            "cap": "53042",
                            "nome": "Chianciano Terme"
                        },
                        {
                            "code": "052010",
                            "cap": "53012",
                            "nome": "Chiusdino"
                        },
                        {
                            "code": "052011",
                            "cap": "53043",
                            "nome": "Chiusi"
                        },
                        {
                            "code": "052012",
                            "cap": "53034",
                            "nome": "Colle di Val d'Elsa"
                        },
                        {
                            "code": "052013",
                            "cap": "53013",
                            "nome": "Gaiole in Chianti"
                        },
                        {
                            "code": "052014",
                            "cap": "53024",
                            "nome": "Montalcino"
                        },
                        {
                            "code": "052015",
                            "cap": "53045",
                            "nome": "Montepulciano"
                        },
                        {
                            "code": "052016",
                            "cap": "53035",
                            "nome": "Monteriggioni"
                        },
                        {
                            "code": "052017",
                            "cap": "53014",
                            "nome": "Monteroni d'Arbia"
                        },
                        {
                            "code": "052018",
                            "cap": "53015",
                            "nome": "Monticiano"
                        },
                        {
                            "code": "052019",
                            "cap": "53016",
                            "nome": "Murlo"
                        },
                        {
                            "code": "052020",
                            "cap": "53025",
                            "nome": "Piancastagnaio"
                        },
                        {
                            "code": "052021",
                            "cap": "53026",
                            "nome": "Pienza"
                        },
                        {
                            "code": "052022",
                            "cap": "53036",
                            "nome": "Poggibonsi"
                        },
                        {
                            "code": "052023",
                            "cap": "53017",
                            "nome": "Radda in Chianti"
                        },
                        {
                            "code": "052024",
                            "cap": "53040",
                            "nome": "Radicofani"
                        },
                        {
                            "code": "052025",
                            "cap": "53030",
                            "nome": "Radicondoli"
                        },
                        {
                            "code": "052026",
                            "cap": "53040",
                            "nome": "Rapolano Terme"
                        },
                        {
                            "code": "052027",
                            "cap": "53040",
                            "nome": "San Casciano dei Bagni"
                        },
                        {
                            "code": "052028",
                            "cap": "53037",
                            "nome": "San Gimignano"
                        },
                        {
                            "code": "052029",
                            "cap": "53020",
                            "nome": "San Giovanni d'Asso"
                        },
                        {
                            "code": "052030",
                            "cap": "53027",
                            "nome": "San Quirico d'Orcia"
                        },
                        {
                            "code": "052031",
                            "cap": "53047",
                            "nome": "Sarteano"
                        },
                        {
                            "code": "052032",
                            "cap": "53100",
                            "nome": "Siena"
                        },
                        {
                            "code": "052033",
                            "cap": "53048",
                            "nome": "Sinalunga"
                        },
                        {
                            "code": "052034",
                            "cap": "53018",
                            "nome": "Sovicille"
                        },
                        {
                            "code": "052035",
                            "cap": "53049",
                            "nome": "Torrita di Siena"
                        },
                        {
                            "code": "052036",
                            "cap": "53020",
                            "nome": "Trequanda"
                        }
                    ],
                    "nome": "Siena"
                }
            ],
            "nome": "Toscana"
        },
        {
            "province": [
                {
                    "code": "BZ",
                    "comuni": [
                        {
                            "code": "021001",
                            "cap": "39040",
                            "nome": "Aldino"
                        },
                        {
                            "code": "021002",
                            "cap": "39010",
                            "nome": "Andriano"
                        },
                        {
                            "code": "021003",
                            "cap": "39040",
                            "nome": "Anterivo"
                        },
                        {
                            "code": "021004",
                            "cap": "39057",
                            "nome": "Appiano sulla Strada del Vino"
                        },
                        {
                            "code": "021005",
                            "cap": "39010",
                            "nome": "Avelengo"
                        },
                        {
                            "code": "021006",
                            "cap": "39036",
                            "nome": "Badia"
                        },
                        {
                            "code": "021007",
                            "cap": "39040",
                            "nome": "Barbiano"
                        },
                        {
                            "code": "021008",
                            "cap": "39100",
                            "nome": "Bolzano"
                        },
                        {
                            "code": "021009",
                            "cap": "39030",
                            "nome": "Braies"
                        },
                        {
                            "code": "021010",
                            "cap": "39041",
                            "nome": "Brennero"
                        },
                        {
                            "code": "021011",
                            "cap": "39042",
                            "nome": "Bressanone"
                        },
                        {
                            "code": "021012",
                            "cap": "39051",
                            "nome": "Bronzolo"
                        },
                        {
                            "code": "021013",
                            "cap": "39031",
                            "nome": "Brunico"
                        },
                        {
                            "code": "021014",
                            "cap": "39010",
                            "nome": "Caines"
                        },
                        {
                            "code": "021015",
                            "cap": "39052",
                            "nome": "Caldaro sulla Strada del Vino"
                        },
                        {
                            "code": "021016",
                            "cap": "39040",
                            "nome": "Campo di Trens"
                        },
                        {
                            "code": "021017",
                            "cap": "39032",
                            "nome": "Campo Tures"
                        },
                        {
                            "code": "021018",
                            "cap": "39020",
                            "nome": "Castelbello-Ciardes"
                        },
                        {
                            "code": "021019",
                            "cap": "39040",
                            "nome": "Castelrotto"
                        },
                        {
                            "code": "021020",
                            "cap": "39010",
                            "nome": "Cermes"
                        },
                        {
                            "code": "021021",
                            "cap": "39030",
                            "nome": "Chienes"
                        },
                        {
                            "code": "021022",
                            "cap": "39043",
                            "nome": "Chiusa"
                        },
                        {
                            "code": "021023",
                            "cap": "39053",
                            "nome": "Cornedo all'Isarco"
                        },
                        {
                            "code": "021024",
                            "cap": "39040",
                            "nome": "Cortaccia sulla Strada del Vino"
                        },
                        {
                            "code": "021025",
                            "cap": "39040",
                            "nome": "Cortina sulla Strada del Vino"
                        },
                        {
                            "code": "021026",
                            "cap": "39033",
                            "nome": "Corvara in Badia"
                        },
                        {
                            "code": "021027",
                            "cap": "39027",
                            "nome": "Curon Venosta"
                        },
                        {
                            "code": "021028",
                            "cap": "39034",
                            "nome": "Dobbiaco"
                        },
                        {
                            "code": "021029",
                            "cap": "39044",
                            "nome": "Egna"
                        },
                        {
                            "code": "021030",
                            "cap": "39030",
                            "nome": "Falzes"
                        },
                        {
                            "code": "021031",
                            "cap": "39050",
                            "nome": "Fi\u00e8 allo Sciliar"
                        },
                        {
                            "code": "021032",
                            "cap": "39045",
                            "nome": "Fortezza"
                        },
                        {
                            "code": "021033",
                            "cap": "39040",
                            "nome": "Funes"
                        },
                        {
                            "code": "021034",
                            "cap": "39030",
                            "nome": "Gais"
                        },
                        {
                            "code": "021035",
                            "cap": "39010",
                            "nome": "Gargazzone"
                        },
                        {
                            "code": "021036",
                            "cap": "39020",
                            "nome": "Glorenza"
                        },
                        {
                            "code": "021117",
                            "cap": "39030",
                            "nome": "La Valle"
                        },
                        {
                            "code": "021037",
                            "cap": "39021",
                            "nome": "Laces"
                        },
                        {
                            "code": "021038",
                            "cap": "39022",
                            "nome": "Lagundo"
                        },
                        {
                            "code": "021039",
                            "cap": "39040",
                            "nome": "Laion"
                        },
                        {
                            "code": "021040",
                            "cap": "39055",
                            "nome": "Laives"
                        },
                        {
                            "code": "021041",
                            "cap": "39011",
                            "nome": "Lana"
                        },
                        {
                            "code": "021042",
                            "cap": "39023",
                            "nome": "Lasa"
                        },
                        {
                            "code": "021043",
                            "cap": "39040",
                            "nome": "Lauregno"
                        },
                        {
                            "code": "021044",
                            "cap": "39040",
                            "nome": "Luson"
                        },
                        {
                            "code": "021045",
                            "cap": "39040",
                            "nome": "Magr\u00e8 sulla Strada del Vino"
                        },
                        {
                            "code": "021046",
                            "cap": "39024",
                            "nome": "Malles Venosta"
                        },
                        {
                            "code": "021047",
                            "cap": "39030",
                            "nome": "Marebbe"
                        },
                        {
                            "code": "021048",
                            "cap": "39020",
                            "nome": "Marlengo"
                        },
                        {
                            "code": "021049",
                            "cap": "39020",
                            "nome": "Martello"
                        },
                        {
                            "code": "021050",
                            "cap": "39010",
                            "nome": "Meltina"
                        },
                        {
                            "code": "021051",
                            "cap": "39012",
                            "nome": "Merano"
                        },
                        {
                            "code": "021052",
                            "cap": "39035",
                            "nome": "Monguelfo-Tesido"
                        },
                        {
                            "code": "021053",
                            "cap": "39040",
                            "nome": "Montagna"
                        },
                        {
                            "code": "021054",
                            "cap": "39013",
                            "nome": "Moso in Passiria"
                        },
                        {
                            "code": "021055",
                            "cap": "39010",
                            "nome": "Nalles"
                        },
                        {
                            "code": "021056",
                            "cap": "39025",
                            "nome": "Naturno"
                        },
                        {
                            "code": "021057",
                            "cap": "39040",
                            "nome": "Naz-Sciaves"
                        },
                        {
                            "code": "021058",
                            "cap": "39056",
                            "nome": "Nova Levante"
                        },
                        {
                            "code": "021059",
                            "cap": "39050",
                            "nome": "Nova Ponente"
                        },
                        {
                            "code": "021060",
                            "cap": "39040",
                            "nome": "Ora"
                        },
                        {
                            "code": "021061",
                            "cap": "39046",
                            "nome": "Ortisei"
                        },
                        {
                            "code": "021062",
                            "cap": "39020",
                            "nome": "Parcines"
                        },
                        {
                            "code": "021063",
                            "cap": "39030",
                            "nome": "Perca"
                        },
                        {
                            "code": "021064",
                            "cap": "39025",
                            "nome": "Plaus"
                        },
                        {
                            "code": "021065",
                            "cap": "39040",
                            "nome": "Ponte Gardena"
                        },
                        {
                            "code": "021066",
                            "cap": "39014",
                            "nome": "Postal"
                        },
                        {
                            "code": "021067",
                            "cap": "39026",
                            "nome": "Prato allo Stelvio"
                        },
                        {
                            "code": "021068",
                            "cap": "39030",
                            "nome": "Predoi"
                        },
                        {
                            "code": "021069",
                            "cap": "39040",
                            "nome": "Proves"
                        },
                        {
                            "code": "021070",
                            "cap": "39040",
                            "nome": "Racines"
                        },
                        {
                            "code": "021071",
                            "cap": "39030",
                            "nome": "Rasun Anterselva"
                        },
                        {
                            "code": "021072",
                            "cap": "39054",
                            "nome": "Renon"
                        },
                        {
                            "code": "021073",
                            "cap": "39010",
                            "nome": "Rifiano"
                        },
                        {
                            "code": "021074",
                            "cap": "39037",
                            "nome": "Rio di Pusteria"
                        },
                        {
                            "code": "021075",
                            "cap": "39037",
                            "nome": "Rodengo"
                        },
                        {
                            "code": "021076",
                            "cap": "39040",
                            "nome": "Salorno"
                        },
                        {
                            "code": "021077",
                            "cap": "39038",
                            "nome": "San Candido"
                        },
                        {
                            "code": "021079",
                            "cap": "39050",
                            "nome": "San Genesio Atesino"
                        },
                        {
                            "code": "021080",
                            "cap": "39015",
                            "nome": "San Leonardo in Passiria"
                        },
                        {
                            "code": "021081",
                            "cap": "39030",
                            "nome": "San Lorenzo di Sebato"
                        },
                        {
                            "code": "021082",
                            "cap": "39030",
                            "nome": "San Martino in Badia"
                        },
                        {
                            "code": "021083",
                            "cap": "39010",
                            "nome": "San Martino in Passiria"
                        },
                        {
                            "code": "021084",
                            "cap": "39010",
                            "nome": "San Pancrazio"
                        },
                        {
                            "code": "021085",
                            "cap": "39047",
                            "nome": "Santa Cristina Valgardena"
                        },
                        {
                            "code": "021086",
                            "cap": "39058",
                            "nome": "Sarentino"
                        },
                        {
                            "code": "021087",
                            "cap": "39017",
                            "nome": "Scena"
                        },
                        {
                            "code": "021088",
                            "cap": "39030",
                            "nome": "Selva dei Molini"
                        },
                        {
                            "code": "021089",
                            "cap": "39048",
                            "nome": "Selva di Val Gardena"
                        },
                        {
                            "code": "021091",
                            "cap": "39020",
                            "nome": "Senales"
                        },
                        {
                            "code": "021118",
                            "cap": "39010",
                            "nome": "Senale-San Felice"
                        },
                        {
                            "code": "021092",
                            "cap": "39030",
                            "nome": "Sesto"
                        },
                        {
                            "code": "021093",
                            "cap": "39028",
                            "nome": "Silandro"
                        },
                        {
                            "code": "021094",
                            "cap": "39020",
                            "nome": "Sluderno"
                        },
                        {
                            "code": "021095",
                            "cap": "39029",
                            "nome": "Stelvio"
                        },
                        {
                            "code": "021096",
                            "cap": "39030",
                            "nome": "Terento"
                        },
                        {
                            "code": "021097",
                            "cap": "39018",
                            "nome": "Terlano"
                        },
                        {
                            "code": "021098",
                            "cap": "39040",
                            "nome": "Termeno sulla Strada del Vino"
                        },
                        {
                            "code": "021099",
                            "cap": "39010",
                            "nome": "Tesimo"
                        },
                        {
                            "code": "021100",
                            "cap": "39050",
                            "nome": "Tires"
                        },
                        {
                            "code": "021101",
                            "cap": "39019",
                            "nome": "Tirolo"
                        },
                        {
                            "code": "021102",
                            "cap": "39040",
                            "nome": "Trodena nel parco naturale"
                        },
                        {
                            "code": "021103",
                            "cap": "39020",
                            "nome": "Tubre"
                        },
                        {
                            "code": "021104",
                            "cap": "39016",
                            "nome": "Ultimo"
                        },
                        {
                            "code": "021105",
                            "cap": "39051",
                            "nome": "Vadena"
                        },
                        {
                            "code": "021107",
                            "cap": "39049",
                            "nome": "Val di Vizze"
                        },
                        {
                            "code": "021106",
                            "cap": "39030",
                            "nome": "Valdaora"
                        },
                        {
                            "code": "021108",
                            "cap": "39030",
                            "nome": "Valle Aurina"
                        },
                        {
                            "code": "021109",
                            "cap": "39030",
                            "nome": "Valle di Casies"
                        },
                        {
                            "code": "021110",
                            "cap": "39030",
                            "nome": "Vandoies"
                        },
                        {
                            "code": "021111",
                            "cap": "39040",
                            "nome": "Varna"
                        },
                        {
                            "code": "021116",
                            "cap": "39040",
                            "nome": "Velturno"
                        },
                        {
                            "code": "021112",
                            "cap": "39010",
                            "nome": "Verano"
                        },
                        {
                            "code": "021113",
                            "cap": "39039",
                            "nome": "Villabassa"
                        },
                        {
                            "code": "021114",
                            "cap": "39040",
                            "nome": "Villandro"
                        },
                        {
                            "code": "021115",
                            "cap": "39049",
                            "nome": "Vipiteno"
                        }
                    ],
                    "nome": "Bolzano"
                },
                {
                    "code": "TN",
                    "comuni": [
                        {
                            "code": "022001",
                            "cap": "38061",
                            "nome": "Ala"
                        },
                        {
                            "code": "022002",
                            "cap": "38041",
                            "nome": "Albiano"
                        },
                        {
                            "code": "022003",
                            "cap": "38060",
                            "nome": "Aldeno"
                        },
                        {
                            "code": "022004",
                            "cap": "38011",
                            "nome": "Amblar"
                        },
                        {
                            "code": "022005",
                            "cap": "38010",
                            "nome": "Andalo"
                        },
                        {
                            "code": "022006",
                            "cap": "38062",
                            "nome": "Arco"
                        },
                        {
                            "code": "022007",
                            "cap": "38063",
                            "nome": "Avio"
                        },
                        {
                            "code": "022009",
                            "cap": "38042",
                            "nome": "Baselga di Pin\u00e8"
                        },
                        {
                            "code": "022011",
                            "cap": "38043",
                            "nome": "Bedollo"
                        },
                        {
                            "code": "022012",
                            "cap": "38085",
                            "nome": "Bersone"
                        },
                        {
                            "code": "022013",
                            "cap": "38060",
                            "nome": "Besenello"
                        },
                        {
                            "code": "022015",
                            "cap": "38050",
                            "nome": "Bieno"
                        },
                        {
                            "code": "022017",
                            "cap": "38071",
                            "nome": "Bleggio Superiore"
                        },
                        {
                            "code": "022018",
                            "cap": "38080",
                            "nome": "Bocenago"
                        },
                        {
                            "code": "022019",
                            "cap": "38079",
                            "nome": "Bolbeno"
                        },
                        {
                            "code": "022020",
                            "cap": "38081",
                            "nome": "Bondo"
                        },
                        {
                            "code": "022021",
                            "cap": "38080",
                            "nome": "Bondone"
                        },
                        {
                            "code": "022022",
                            "cap": "38051",
                            "nome": "Borgo Valsugana"
                        },
                        {
                            "code": "022023",
                            "cap": "38049",
                            "nome": "Bosentino"
                        },
                        {
                            "code": "022024",
                            "cap": "38081",
                            "nome": "Breguzzo"
                        },
                        {
                            "code": "022025",
                            "cap": "38060",
                            "nome": "Brentonico"
                        },
                        {
                            "code": "022026",
                            "cap": "38020",
                            "nome": "Bresimo"
                        },
                        {
                            "code": "022027",
                            "cap": "38021",
                            "nome": "Brez"
                        },
                        {
                            "code": "022028",
                            "cap": "38083",
                            "nome": "Brione"
                        },
                        {
                            "code": "022029",
                            "cap": "38080",
                            "nome": "Caderzone Terme"
                        },
                        {
                            "code": "022030",
                            "cap": "38028",
                            "nome": "Cagn\u00f2"
                        },
                        {
                            "code": "022031",
                            "cap": "38072",
                            "nome": "Calavino"
                        },
                        {
                            "code": "022032",
                            "cap": "38050",
                            "nome": "Calceranica al Lago"
                        },
                        {
                            "code": "022033",
                            "cap": "38022",
                            "nome": "Caldes"
                        },
                        {
                            "code": "022034",
                            "cap": "38052",
                            "nome": "Caldonazzo"
                        },
                        {
                            "code": "022035",
                            "cap": "38060",
                            "nome": "Calliano"
                        },
                        {
                            "code": "022036",
                            "cap": "38031",
                            "nome": "Campitello di Fassa"
                        },
                        {
                            "code": "022037",
                            "cap": "38010",
                            "nome": "Campodenno"
                        },
                        {
                            "code": "022038",
                            "cap": "38050",
                            "nome": "Canal San Bovo"
                        },
                        {
                            "code": "022039",
                            "cap": "38032",
                            "nome": "Canazei"
                        },
                        {
                            "code": "022040",
                            "cap": "38030",
                            "nome": "Capriana"
                        },
                        {
                            "code": "022041",
                            "cap": "38033",
                            "nome": "Carano"
                        },
                        {
                            "code": "022042",
                            "cap": "38080",
                            "nome": "Carisolo"
                        },
                        {
                            "code": "022043",
                            "cap": "38050",
                            "nome": "Carzano"
                        },
                        {
                            "code": "022045",
                            "cap": "38082",
                            "nome": "Castel Condino"
                        },
                        {
                            "code": "022046",
                            "cap": "38020",
                            "nome": "Castelfondo"
                        },
                        {
                            "code": "022048",
                            "cap": "38053",
                            "nome": "Castello Tesino"
                        },
                        {
                            "code": "022047",
                            "cap": "38030",
                            "nome": "Castello-Molina di Fiemme"
                        },
                        {
                            "code": "022049",
                            "cap": "38050",
                            "nome": "Castelnuovo"
                        },
                        {
                            "code": "022050",
                            "cap": "38033",
                            "nome": "Cavalese"
                        },
                        {
                            "code": "022051",
                            "cap": "38011",
                            "nome": "Cavareno"
                        },
                        {
                            "code": "022052",
                            "cap": "38010",
                            "nome": "Cavedago"
                        },
                        {
                            "code": "022053",
                            "cap": "38073",
                            "nome": "Cavedine"
                        },
                        {
                            "code": "022054",
                            "cap": "38022",
                            "nome": "Cavizzana"
                        },
                        {
                            "code": "022055",
                            "cap": "38034",
                            "nome": "Cembra"
                        },
                        {
                            "code": "022056",
                            "cap": "38040",
                            "nome": "Centa San Nicol\u00f2"
                        },
                        {
                            "code": "022057",
                            "cap": "38082",
                            "nome": "Cimego"
                        },
                        {
                            "code": "022058",
                            "cap": "38060",
                            "nome": "Cimone"
                        },
                        {
                            "code": "022059",
                            "cap": "38050",
                            "nome": "Cinte Tesino"
                        },
                        {
                            "code": "022060",
                            "cap": "38020",
                            "nome": "Cis"
                        },
                        {
                            "code": "022061",
                            "cap": "38045",
                            "nome": "Civezzano"
                        },
                        {
                            "code": "022062",
                            "cap": "38023",
                            "nome": "Cles"
                        },
                        {
                            "code": "022063",
                            "cap": "38020",
                            "nome": "Cloz"
                        },
                        {
                            "code": "022228",
                            "cap": "38077",
                            "nome": "Comano Terme"
                        },
                        {
                            "code": "022064",
                            "cap": "38020",
                            "nome": "Commezzadura"
                        },
                        {
                            "code": "022066",
                            "cap": "38083",
                            "nome": "Condino"
                        },
                        {
                            "code": "022067",
                            "cap": "38010",
                            "nome": "Coredo"
                        },
                        {
                            "code": "022068",
                            "cap": "38027",
                            "nome": "Croviana"
                        },
                        {
                            "code": "022069",
                            "cap": "38010",
                            "nome": "Cunevo"
                        },
                        {
                            "code": "022070",
                            "cap": "38030",
                            "nome": "Daiano"
                        },
                        {
                            "code": "022071",
                            "cap": "38010",
                            "nome": "Dambel"
                        },
                        {
                            "code": "022072",
                            "cap": "38080",
                            "nome": "Daone"
                        },
                        {
                            "code": "022073",
                            "cap": "38080",
                            "nome": "Dar\u00e8"
                        },
                        {
                            "code": "022074",
                            "cap": "38010",
                            "nome": "Denno"
                        },
                        {
                            "code": "022075",
                            "cap": "38025",
                            "nome": "Dimaro"
                        },
                        {
                            "code": "022076",
                            "cap": "38011",
                            "nome": "Don"
                        },
                        {
                            "code": "022077",
                            "cap": "38070",
                            "nome": "Dorsino"
                        },
                        {
                            "code": "022078",
                            "cap": "38074",
                            "nome": "Drena"
                        },
                        {
                            "code": "022079",
                            "cap": "38074",
                            "nome": "Dro"
                        },
                        {
                            "code": "022080",
                            "cap": "38010",
                            "nome": "Faedo"
                        },
                        {
                            "code": "022081",
                            "cap": "38010",
                            "nome": "Fai della Paganella"
                        },
                        {
                            "code": "022082",
                            "cap": "38030",
                            "nome": "Faver"
                        },
                        {
                            "code": "022083",
                            "cap": "38075",
                            "nome": "Fiav\u00e8"
                        },
                        {
                            "code": "022084",
                            "cap": "38054",
                            "nome": "Fiera di Primiero"
                        },
                        {
                            "code": "022085",
                            "cap": "38050",
                            "nome": "Fierozzo"
                        },
                        {
                            "code": "022086",
                            "cap": "38010",
                            "nome": "Flavon"
                        },
                        {
                            "code": "022087",
                            "cap": "38064",
                            "nome": "Folgaria"
                        },
                        {
                            "code": "022088",
                            "cap": "38013",
                            "nome": "Fondo"
                        },
                        {
                            "code": "022089",
                            "cap": "38040",
                            "nome": "Fornace"
                        },
                        {
                            "code": "022090",
                            "cap": "38050",
                            "nome": "Frassilongo"
                        },
                        {
                            "code": "022091",
                            "cap": "38060",
                            "nome": "Garniga Terme"
                        },
                        {
                            "code": "022092",
                            "cap": "38030",
                            "nome": "Giovo"
                        },
                        {
                            "code": "022093",
                            "cap": "38086",
                            "nome": "Giustino"
                        },
                        {
                            "code": "022094",
                            "cap": "38030",
                            "nome": "Grauno"
                        },
                        {
                            "code": "022095",
                            "cap": "38055",
                            "nome": "Grigno"
                        },
                        {
                            "code": "022096",
                            "cap": "38030",
                            "nome": "Grumes"
                        },
                        {
                            "code": "022097",
                            "cap": "38050",
                            "nome": "Imer"
                        },
                        {
                            "code": "022098",
                            "cap": "38060",
                            "nome": "Isera"
                        },
                        {
                            "code": "022099",
                            "cap": "38059",
                            "nome": "Ivano-Fracena"
                        },
                        {
                            "code": "022100",
                            "cap": "38087",
                            "nome": "Lardaro"
                        },
                        {
                            "code": "022101",
                            "cap": "38076",
                            "nome": "Lasino"
                        },
                        {
                            "code": "022102",
                            "cap": "38046",
                            "nome": "Lavarone"
                        },
                        {
                            "code": "022103",
                            "cap": "38015",
                            "nome": "Lavis"
                        },
                        {
                            "code": "022229",
                            "cap": "38067",
                            "nome": "Ledro"
                        },
                        {
                            "code": "022104",
                            "cap": "38056",
                            "nome": "Levico Terme"
                        },
                        {
                            "code": "022105",
                            "cap": "38030",
                            "nome": "Lisignago"
                        },
                        {
                            "code": "022106",
                            "cap": "38020",
                            "nome": "Livo"
                        },
                        {
                            "code": "022108",
                            "cap": "38040",
                            "nome": "Lona-Lases"
                        },
                        {
                            "code": "022109",
                            "cap": "38040",
                            "nome": "Luserna"
                        },
                        {
                            "code": "022110",
                            "cap": "38027",
                            "nome": "Mal\u00e8"
                        },
                        {
                            "code": "022111",
                            "cap": "38013",
                            "nome": "Malosco"
                        },
                        {
                            "code": "022112",
                            "cap": "38086",
                            "nome": "Massimeno"
                        },
                        {
                            "code": "022113",
                            "cap": "38030",
                            "nome": "Mazzin"
                        },
                        {
                            "code": "022114",
                            "cap": "38020",
                            "nome": "Mezzana"
                        },
                        {
                            "code": "022115",
                            "cap": "38050",
                            "nome": "Mezzano"
                        },
                        {
                            "code": "022116",
                            "cap": "38016",
                            "nome": "Mezzocorona"
                        },
                        {
                            "code": "022117",
                            "cap": "38017",
                            "nome": "Mezzolombardo"
                        },
                        {
                            "code": "022118",
                            "cap": "38035",
                            "nome": "Moena"
                        },
                        {
                            "code": "022120",
                            "cap": "38018",
                            "nome": "Molveno"
                        },
                        {
                            "code": "022121",
                            "cap": "38020",
                            "nome": "Monclassico"
                        },
                        {
                            "code": "022122",
                            "cap": "38070",
                            "nome": "Montagne"
                        },
                        {
                            "code": "022123",
                            "cap": "38065",
                            "nome": "Mori"
                        },
                        {
                            "code": "022124",
                            "cap": "38069",
                            "nome": "Nago-Torbole"
                        },
                        {
                            "code": "022125",
                            "cap": "38010",
                            "nome": "Nanno"
                        },
                        {
                            "code": "022126",
                            "cap": "38010",
                            "nome": "Nave San Rocco"
                        },
                        {
                            "code": "022127",
                            "cap": "38060",
                            "nome": "Nogaredo"
                        },
                        {
                            "code": "022128",
                            "cap": "38060",
                            "nome": "Nomi"
                        },
                        {
                            "code": "022129",
                            "cap": "38050",
                            "nome": "Novaledo"
                        },
                        {
                            "code": "022130",
                            "cap": "38050",
                            "nome": "Ospedaletto"
                        },
                        {
                            "code": "022131",
                            "cap": "38026",
                            "nome": "Ossana"
                        },
                        {
                            "code": "022132",
                            "cap": "38070",
                            "nome": "Padergnone"
                        },
                        {
                            "code": "022133",
                            "cap": "38050",
                            "nome": "Pal\u00f9 del Fersina"
                        },
                        {
                            "code": "022134",
                            "cap": "38030",
                            "nome": "Panchi\u00e0"
                        },
                        {
                            "code": "022136",
                            "cap": "38024",
                            "nome": "Peio"
                        },
                        {
                            "code": "022137",
                            "cap": "38020",
                            "nome": "Pellizzano"
                        },
                        {
                            "code": "022138",
                            "cap": "38079",
                            "nome": "Pelugo"
                        },
                        {
                            "code": "022139",
                            "cap": "38057",
                            "nome": "Pergine Valsugana"
                        },
                        {
                            "code": "022140",
                            "cap": "38085",
                            "nome": "Pieve di Bono"
                        },
                        {
                            "code": "022142",
                            "cap": "38050",
                            "nome": "Pieve Tesino"
                        },
                        {
                            "code": "022143",
                            "cap": "38086",
                            "nome": "Pinzolo"
                        },
                        {
                            "code": "022144",
                            "cap": "38060",
                            "nome": "Pomarolo"
                        },
                        {
                            "code": "022145",
                            "cap": "38036",
                            "nome": "Pozza di Fassa"
                        },
                        {
                            "code": "022146",
                            "cap": "38080",
                            "nome": "Praso"
                        },
                        {
                            "code": "022147",
                            "cap": "38037",
                            "nome": "Predazzo"
                        },
                        {
                            "code": "022148",
                            "cap": "38070",
                            "nome": "Preore"
                        },
                        {
                            "code": "022149",
                            "cap": "38085",
                            "nome": "Prezzo"
                        },
                        {
                            "code": "022150",
                            "cap": "38020",
                            "nome": "Rabbi"
                        },
                        {
                            "code": "022151",
                            "cap": "38070",
                            "nome": "Ragoli"
                        },
                        {
                            "code": "022152",
                            "cap": "38028",
                            "nome": "Rev\u00f2"
                        },
                        {
                            "code": "022153",
                            "cap": "38066",
                            "nome": "Riva del Garda"
                        },
                        {
                            "code": "022154",
                            "cap": "38028",
                            "nome": "Romallo"
                        },
                        {
                            "code": "022155",
                            "cap": "38010",
                            "nome": "Romeno"
                        },
                        {
                            "code": "022156",
                            "cap": "38050",
                            "nome": "Roncegno Terme"
                        },
                        {
                            "code": "022157",
                            "cap": "38050",
                            "nome": "Ronchi Valsugana"
                        },
                        {
                            "code": "022158",
                            "cap": "38087",
                            "nome": "Roncone"
                        },
                        {
                            "code": "022135",
                            "cap": "38060",
                            "nome": "Ronzo-Chienis"
                        },
                        {
                            "code": "022159",
                            "cap": "38010",
                            "nome": "Ronzone"
                        },
                        {
                            "code": "022160",
                            "cap": "38030",
                            "nome": "Rover\u00e8 della Luna"
                        },
                        {
                            "code": "022161",
                            "cap": "38068",
                            "nome": "Rovereto"
                        },
                        {
                            "code": "022162",
                            "cap": "38010",
                            "nome": "Ruffr\u00e8-Mendola"
                        },
                        {
                            "code": "022163",
                            "cap": "38020",
                            "nome": "Rumo"
                        },
                        {
                            "code": "022164",
                            "cap": "38050",
                            "nome": "Sagron Mis"
                        },
                        {
                            "code": "022165",
                            "cap": "38059",
                            "nome": "Samone"
                        },
                        {
                            "code": "022166",
                            "cap": "38078",
                            "nome": "San Lorenzo in Banale"
                        },
                        {
                            "code": "022167",
                            "cap": "38010",
                            "nome": "San Michele all'Adige"
                        },
                        {
                            "code": "022168",
                            "cap": "38050",
                            "nome": "Sant'Orsola Terme"
                        },
                        {
                            "code": "022169",
                            "cap": "38010",
                            "nome": "Sanzeno"
                        },
                        {
                            "code": "022170",
                            "cap": "38011",
                            "nome": "Sarnonico"
                        },
                        {
                            "code": "022171",
                            "cap": "38050",
                            "nome": "Scurelle"
                        },
                        {
                            "code": "022172",
                            "cap": "38047",
                            "nome": "Segonzano"
                        },
                        {
                            "code": "022173",
                            "cap": "38010",
                            "nome": "Sfruz"
                        },
                        {
                            "code": "022174",
                            "cap": "38054",
                            "nome": "Siror"
                        },
                        {
                            "code": "022175",
                            "cap": "38010",
                            "nome": "Smarano"
                        },
                        {
                            "code": "022176",
                            "cap": "38030",
                            "nome": "Soraga"
                        },
                        {
                            "code": "022177",
                            "cap": "38048",
                            "nome": "Sover"
                        },
                        {
                            "code": "022178",
                            "cap": "38059",
                            "nome": "Spera"
                        },
                        {
                            "code": "022179",
                            "cap": "38088",
                            "nome": "Spiazzo"
                        },
                        {
                            "code": "022180",
                            "cap": "38010",
                            "nome": "Spormaggiore"
                        },
                        {
                            "code": "022181",
                            "cap": "38010",
                            "nome": "Sporminore"
                        },
                        {
                            "code": "022182",
                            "cap": "38070",
                            "nome": "Stenico"
                        },
                        {
                            "code": "022183",
                            "cap": "38089",
                            "nome": "Storo"
                        },
                        {
                            "code": "022184",
                            "cap": "38080",
                            "nome": "Strembo"
                        },
                        {
                            "code": "022185",
                            "cap": "38059",
                            "nome": "Strigno"
                        },
                        {
                            "code": "022186",
                            "cap": "38012",
                            "nome": "Taio"
                        },
                        {
                            "code": "022187",
                            "cap": "38010",
                            "nome": "Tassullo"
                        },
                        {
                            "code": "022188",
                            "cap": "38050",
                            "nome": "Telve"
                        },
                        {
                            "code": "022189",
                            "cap": "38050",
                            "nome": "Telve di Sopra"
                        },
                        {
                            "code": "022190",
                            "cap": "38050",
                            "nome": "Tenna"
                        },
                        {
                            "code": "022191",
                            "cap": "38060",
                            "nome": "Tenno"
                        },
                        {
                            "code": "022192",
                            "cap": "38070",
                            "nome": "Terlago"
                        },
                        {
                            "code": "022193",
                            "cap": "38060",
                            "nome": "Terragnolo"
                        },
                        {
                            "code": "022194",
                            "cap": "38010",
                            "nome": "Terres"
                        },
                        {
                            "code": "022195",
                            "cap": "38027",
                            "nome": "Terzolas"
                        },
                        {
                            "code": "022196",
                            "cap": "38038",
                            "nome": "Tesero"
                        },
                        {
                            "code": "022199",
                            "cap": "38079",
                            "nome": "Tione di Trento"
                        },
                        {
                            "code": "022200",
                            "cap": "38010",
                            "nome": "Ton"
                        },
                        {
                            "code": "022201",
                            "cap": "38054",
                            "nome": "Tonadico"
                        },
                        {
                            "code": "022202",
                            "cap": "38050",
                            "nome": "Torcegno"
                        },
                        {
                            "code": "022203",
                            "cap": "38068",
                            "nome": "Trambileno"
                        },
                        {
                            "code": "022204",
                            "cap": "38054",
                            "nome": "Transacqua"
                        },
                        {
                            "code": "022205",
                            "cap": "3812x",
                            "nome": "Trento"
                        },
                        {
                            "code": "022206",
                            "cap": "38010",
                            "nome": "Tres"
                        },
                        {
                            "code": "022207",
                            "cap": "38019",
                            "nome": "Tuenno"
                        },
                        {
                            "code": "022208",
                            "cap": "38030",
                            "nome": "Valda"
                        },
                        {
                            "code": "022209",
                            "cap": "38040",
                            "nome": "Valfloriana"
                        },
                        {
                            "code": "022210",
                            "cap": "38060",
                            "nome": "Vallarsa"
                        },
                        {
                            "code": "022211",
                            "cap": "38030",
                            "nome": "Varena"
                        },
                        {
                            "code": "022212",
                            "cap": "38049",
                            "nome": "Vattaro"
                        },
                        {
                            "code": "022213",
                            "cap": "38029",
                            "nome": "Vermiglio"
                        },
                        {
                            "code": "022214",
                            "cap": "38010",
                            "nome": "Verv\u00f2"
                        },
                        {
                            "code": "022215",
                            "cap": "38070",
                            "nome": "Vezzano"
                        },
                        {
                            "code": "022216",
                            "cap": "38057",
                            "nome": "Vignola-Falesina"
                        },
                        {
                            "code": "022217",
                            "cap": "38039",
                            "nome": "Vigo di Fassa"
                        },
                        {
                            "code": "022220",
                            "cap": "38080",
                            "nome": "Vigo Rendena"
                        },
                        {
                            "code": "022219",
                            "cap": "38049",
                            "nome": "Vigolo Vattaro"
                        },
                        {
                            "code": "022221",
                            "cap": "38059",
                            "nome": "Villa Agnedo"
                        },
                        {
                            "code": "022222",
                            "cap": "38060",
                            "nome": "Villa Lagarina"
                        },
                        {
                            "code": "022223",
                            "cap": "38080",
                            "nome": "Villa Rendena"
                        },
                        {
                            "code": "022224",
                            "cap": "38060",
                            "nome": "Volano"
                        },
                        {
                            "code": "022225",
                            "cap": "38010",
                            "nome": "Zambana"
                        },
                        {
                            "code": "022226",
                            "cap": "38030",
                            "nome": "Ziano di Fiemme"
                        },
                        {
                            "code": "022227",
                            "cap": "38079",
                            "nome": "Zuclo"
                        }
                    ],
                    "nome": "Trento"
                }
            ],
            "nome": "Trentino-Alto Adige"
        },
        {
            "province": [
                {
                    "code": "PG",
                    "comuni": [
                        {
                            "code": "054001",
                            "cap": "06081",
                            "nome": "Assisi"
                        },
                        {
                            "code": "054002",
                            "cap": "06083",
                            "nome": "Bastia Umbra"
                        },
                        {
                            "code": "054003",
                            "cap": "06084",
                            "nome": "Bettona"
                        },
                        {
                            "code": "054004",
                            "cap": "06031",
                            "nome": "Bevagna"
                        },
                        {
                            "code": "054005",
                            "cap": "06042",
                            "nome": "Campello sul Clitunno"
                        },
                        {
                            "code": "054006",
                            "cap": "06033",
                            "nome": "Cannara"
                        },
                        {
                            "code": "054007",
                            "cap": "06043",
                            "nome": "Cascia"
                        },
                        {
                            "code": "054008",
                            "cap": "06044",
                            "nome": "Castel Ritaldi"
                        },
                        {
                            "code": "054009",
                            "cap": "06061",
                            "nome": "Castiglione del Lago"
                        },
                        {
                            "code": "054010",
                            "cap": "06041",
                            "nome": "Cerreto di Spoleto"
                        },
                        {
                            "code": "054011",
                            "cap": "06010",
                            "nome": "Citerna"
                        },
                        {
                            "code": "054012",
                            "cap": "06062",
                            "nome": "Citt\u00e0 della Pieve"
                        },
                        {
                            "code": "054013",
                            "cap": "06012",
                            "nome": "Citt\u00e0 di Castello"
                        },
                        {
                            "code": "054014",
                            "cap": "06050",
                            "nome": "Collazzone"
                        },
                        {
                            "code": "054015",
                            "cap": "06073",
                            "nome": "Corciano"
                        },
                        {
                            "code": "054016",
                            "cap": "06021",
                            "nome": "Costacciaro"
                        },
                        {
                            "code": "054017",
                            "cap": "06053",
                            "nome": "Deruta"
                        },
                        {
                            "code": "054018",
                            "cap": "06034",
                            "nome": "Foligno"
                        },
                        {
                            "code": "054019",
                            "cap": "06022",
                            "nome": "Fossato di Vico"
                        },
                        {
                            "code": "054020",
                            "cap": "06054",
                            "nome": "Fratta Todina"
                        },
                        {
                            "code": "054021",
                            "cap": "06030",
                            "nome": "Giano dell'Umbria"
                        },
                        {
                            "code": "054022",
                            "cap": "06035",
                            "nome": "Gualdo Cattaneo"
                        },
                        {
                            "code": "054023",
                            "cap": "06023",
                            "nome": "Gualdo Tadino"
                        },
                        {
                            "code": "054024",
                            "cap": "06024",
                            "nome": "Gubbio"
                        },
                        {
                            "code": "054025",
                            "cap": "06060",
                            "nome": "Lisciano Niccone"
                        },
                        {
                            "code": "054026",
                            "cap": "06063",
                            "nome": "Magione"
                        },
                        {
                            "code": "054027",
                            "cap": "06055",
                            "nome": "Marsciano"
                        },
                        {
                            "code": "054028",
                            "cap": "06056",
                            "nome": "Massa Martana"
                        },
                        {
                            "code": "054029",
                            "cap": "06057",
                            "nome": "Monte Castello di Vibio"
                        },
                        {
                            "code": "054032",
                            "cap": "06010",
                            "nome": "Monte Santa Maria Tiberina"
                        },
                        {
                            "code": "054030",
                            "cap": "06036",
                            "nome": "Montefalco"
                        },
                        {
                            "code": "054031",
                            "cap": "06045",
                            "nome": "Monteleone di Spoleto"
                        },
                        {
                            "code": "054033",
                            "cap": "06014",
                            "nome": "Montone"
                        },
                        {
                            "code": "054034",
                            "cap": "06025",
                            "nome": "Nocera Umbra"
                        },
                        {
                            "code": "054035",
                            "cap": "06046",
                            "nome": "Norcia"
                        },
                        {
                            "code": "054036",
                            "cap": "06060",
                            "nome": "Paciano"
                        },
                        {
                            "code": "054037",
                            "cap": "06064",
                            "nome": "Panicale"
                        },
                        {
                            "code": "054038",
                            "cap": "06065",
                            "nome": "Passignano sul Trasimeno"
                        },
                        {
                            "code": "054039",
                            "cap": "061xx",
                            "nome": "Perugia"
                        },
                        {
                            "code": "054040",
                            "cap": "06066",
                            "nome": "Piegaro"
                        },
                        {
                            "code": "054041",
                            "cap": "06026",
                            "nome": "Pietralunga"
                        },
                        {
                            "code": "054042",
                            "cap": "06040",
                            "nome": "Poggiodomo"
                        },
                        {
                            "code": "054043",
                            "cap": "06047",
                            "nome": "Preci"
                        },
                        {
                            "code": "054044",
                            "cap": "06016",
                            "nome": "San Giustino"
                        },
                        {
                            "code": "054045",
                            "cap": "06040",
                            "nome": "Sant'Anatolia di Narco"
                        },
                        {
                            "code": "054046",
                            "cap": "06027",
                            "nome": "Scheggia e Pascelupo"
                        },
                        {
                            "code": "054047",
                            "cap": "06040",
                            "nome": "Scheggino"
                        },
                        {
                            "code": "054048",
                            "cap": "06030",
                            "nome": "Sellano"
                        },
                        {
                            "code": "054049",
                            "cap": "06028",
                            "nome": "Sigillo"
                        },
                        {
                            "code": "054050",
                            "cap": "06038",
                            "nome": "Spello"
                        },
                        {
                            "code": "054051",
                            "cap": "06049",
                            "nome": "Spoleto"
                        },
                        {
                            "code": "054052",
                            "cap": "06059",
                            "nome": "Todi"
                        },
                        {
                            "code": "054053",
                            "cap": "06089",
                            "nome": "Torgiano"
                        },
                        {
                            "code": "054054",
                            "cap": "06039",
                            "nome": "Trevi"
                        },
                        {
                            "code": "054055",
                            "cap": "06069",
                            "nome": "Tuoro sul Trasimeno"
                        },
                        {
                            "code": "054056",
                            "cap": "06019",
                            "nome": "Umbertide"
                        },
                        {
                            "code": "054057",
                            "cap": "06029",
                            "nome": "Valfabbrica"
                        },
                        {
                            "code": "054058",
                            "cap": "06040",
                            "nome": "Vallo di Nera"
                        },
                        {
                            "code": "054059",
                            "cap": "06030",
                            "nome": "Valtopina"
                        }
                    ],
                    "nome": "Perugia"
                },
                {
                    "code": "TR",
                    "comuni": [
                        {
                            "code": "055001",
                            "cap": "05021",
                            "nome": "Acquasparta"
                        },
                        {
                            "code": "055002",
                            "cap": "05011",
                            "nome": "Allerona"
                        },
                        {
                            "code": "055003",
                            "cap": "05020",
                            "nome": "Alviano"
                        },
                        {
                            "code": "055004",
                            "cap": "05022",
                            "nome": "Amelia"
                        },
                        {
                            "code": "055005",
                            "cap": "05031",
                            "nome": "Arrone"
                        },
                        {
                            "code": "055006",
                            "cap": "05012",
                            "nome": "Attigliano"
                        },
                        {
                            "code": "055033",
                            "cap": "05020",
                            "nome": "Avigliano Umbro"
                        },
                        {
                            "code": "055007",
                            "cap": "05023",
                            "nome": "Baschi"
                        },
                        {
                            "code": "055008",
                            "cap": "05032",
                            "nome": "Calvi dell'Umbria"
                        },
                        {
                            "code": "055009",
                            "cap": "05013",
                            "nome": "Castel Giorgio"
                        },
                        {
                            "code": "055010",
                            "cap": "05014",
                            "nome": "Castel Viscardo"
                        },
                        {
                            "code": "055011",
                            "cap": "05015",
                            "nome": "Fabro"
                        },
                        {
                            "code": "055012",
                            "cap": "05034",
                            "nome": "Ferentillo"
                        },
                        {
                            "code": "055013",
                            "cap": "05016",
                            "nome": "Ficulle"
                        },
                        {
                            "code": "055014",
                            "cap": "05024",
                            "nome": "Giove"
                        },
                        {
                            "code": "055015",
                            "cap": "05025",
                            "nome": "Guardea"
                        },
                        {
                            "code": "055016",
                            "cap": "05020",
                            "nome": "Lugnano in Teverina"
                        },
                        {
                            "code": "055017",
                            "cap": "05026",
                            "nome": "Montecastrilli"
                        },
                        {
                            "code": "055018",
                            "cap": "05020",
                            "nome": "Montecchio"
                        },
                        {
                            "code": "055019",
                            "cap": "05030",
                            "nome": "Montefranco"
                        },
                        {
                            "code": "055020",
                            "cap": "05010",
                            "nome": "Montegabbione"
                        },
                        {
                            "code": "055021",
                            "cap": "05017",
                            "nome": "Monteleone d'Orvieto"
                        },
                        {
                            "code": "055022",
                            "cap": "05035",
                            "nome": "Narni"
                        },
                        {
                            "code": "055023",
                            "cap": "05018",
                            "nome": "Orvieto"
                        },
                        {
                            "code": "055024",
                            "cap": "05030",
                            "nome": "Otricoli"
                        },
                        {
                            "code": "055025",
                            "cap": "05010",
                            "nome": "Parrano"
                        },
                        {
                            "code": "055026",
                            "cap": "05028",
                            "nome": "Penna in Teverina"
                        },
                        {
                            "code": "055027",
                            "cap": "05030",
                            "nome": "Polino"
                        },
                        {
                            "code": "055028",
                            "cap": "05010",
                            "nome": "Porano"
                        },
                        {
                            "code": "055029",
                            "cap": "05029",
                            "nome": "San Gemini"
                        },
                        {
                            "code": "055030",
                            "cap": "05010",
                            "nome": "San Venanzo"
                        },
                        {
                            "code": "055031",
                            "cap": "05039",
                            "nome": "Stroncone"
                        },
                        {
                            "code": "055032",
                            "cap": "05100",
                            "nome": "Terni"
                        }
                    ],
                    "nome": "Terni"
                }
            ],
            "nome": "Umbria"
        },
        {
            "province": [
                {
                    "code": "AO",
                    "comuni": [
                        {
                            "code": "007001",
                            "cap": "11010",
                            "nome": "Allein"
                        },
                        {
                            "code": "007002",
                            "cap": "11020",
                            "nome": "Antey-Saint-Andr\u00e8"
                        },
                        {
                            "code": "007003",
                            "cap": "11100",
                            "nome": "Aosta"
                        },
                        {
                            "code": "007004",
                            "cap": "11020",
                            "nome": "Arnad"
                        },
                        {
                            "code": "007005",
                            "cap": "11011",
                            "nome": "Arvier"
                        },
                        {
                            "code": "007006",
                            "cap": "11010",
                            "nome": "Avise"
                        },
                        {
                            "code": "007007",
                            "cap": "11020",
                            "nome": "Ayas"
                        },
                        {
                            "code": "007008",
                            "cap": "11010",
                            "nome": "Aymavilles"
                        },
                        {
                            "code": "007009",
                            "cap": "11020",
                            "nome": "Bard"
                        },
                        {
                            "code": "007010",
                            "cap": "11010",
                            "nome": "Bionaz"
                        },
                        {
                            "code": "007011",
                            "cap": "11020",
                            "nome": "Brissogne"
                        },
                        {
                            "code": "007012",
                            "cap": "11022",
                            "nome": "Brusson"
                        },
                        {
                            "code": "007013",
                            "cap": "11020",
                            "nome": "Challand-Saint-Anselme"
                        },
                        {
                            "code": "007014",
                            "cap": "11020",
                            "nome": "Challand-Saint-Victor"
                        },
                        {
                            "code": "007015",
                            "cap": "11023",
                            "nome": "Chambave"
                        },
                        {
                            "code": "007016",
                            "cap": "11020",
                            "nome": "Chamois"
                        },
                        {
                            "code": "007017",
                            "cap": "11020",
                            "nome": "Champdepraz"
                        },
                        {
                            "code": "007018",
                            "cap": "11020",
                            "nome": "Champorcher"
                        },
                        {
                            "code": "007019",
                            "cap": "11020",
                            "nome": "Charvensod"
                        },
                        {
                            "code": "007020",
                            "cap": "11024",
                            "nome": "Chatillon"
                        },
                        {
                            "code": "007021",
                            "cap": "11012",
                            "nome": "Cogne"
                        },
                        {
                            "code": "007022",
                            "cap": "11013",
                            "nome": "Courmayeur"
                        },
                        {
                            "code": "007023",
                            "cap": "11020",
                            "nome": "Donnas"
                        },
                        {
                            "code": "007024",
                            "cap": "11010",
                            "nome": "Doues"
                        },
                        {
                            "code": "007025",
                            "cap": "11020",
                            "nome": "Emar\u00e8se"
                        },
                        {
                            "code": "007026",
                            "cap": "11014",
                            "nome": "Etroubles"
                        },
                        {
                            "code": "007027",
                            "cap": "11020",
                            "nome": "F\u00e9nis"
                        },
                        {
                            "code": "007028",
                            "cap": "11020",
                            "nome": "Fontainemore"
                        },
                        {
                            "code": "007029",
                            "cap": "11020",
                            "nome": "Gaby"
                        },
                        {
                            "code": "007030",
                            "cap": "11010",
                            "nome": "Gignod"
                        },
                        {
                            "code": "007031",
                            "cap": "11020",
                            "nome": "Gressan"
                        },
                        {
                            "code": "007032",
                            "cap": "11020",
                            "nome": "Gressoney-la-Trinit\u00e8"
                        },
                        {
                            "code": "007033",
                            "cap": "11025",
                            "nome": "Gressoney-Saint-Jean"
                        },
                        {
                            "code": "007034",
                            "cap": "11020",
                            "nome": "Hone"
                        },
                        {
                            "code": "007035",
                            "cap": "11010",
                            "nome": "Introd"
                        },
                        {
                            "code": "007036",
                            "cap": "11020",
                            "nome": "Issime"
                        },
                        {
                            "code": "007037",
                            "cap": "11020",
                            "nome": "Issogne"
                        },
                        {
                            "code": "007038",
                            "cap": "11020",
                            "nome": "Jovencan"
                        },
                        {
                            "code": "007039",
                            "cap": "11020",
                            "nome": "La Magdeleine"
                        },
                        {
                            "code": "007040",
                            "cap": "11015",
                            "nome": "La Salle"
                        },
                        {
                            "code": "007041",
                            "cap": "11016",
                            "nome": "La Thuile"
                        },
                        {
                            "code": "007042",
                            "cap": "11020",
                            "nome": "Lillianes"
                        },
                        {
                            "code": "007043",
                            "cap": "11020",
                            "nome": "Montjovet"
                        },
                        {
                            "code": "007044",
                            "cap": "11017",
                            "nome": "Morgex"
                        },
                        {
                            "code": "007045",
                            "cap": "11020",
                            "nome": "Nus"
                        },
                        {
                            "code": "007046",
                            "cap": "11010",
                            "nome": "Ollomont"
                        },
                        {
                            "code": "007047",
                            "cap": "11010",
                            "nome": "Oyace"
                        },
                        {
                            "code": "007048",
                            "cap": "11020",
                            "nome": "Perloz"
                        },
                        {
                            "code": "007049",
                            "cap": "11020",
                            "nome": "Pollein"
                        },
                        {
                            "code": "007050",
                            "cap": "11020",
                            "nome": "Pontboset"
                        },
                        {
                            "code": "007051",
                            "cap": "11024",
                            "nome": "Pontey"
                        },
                        {
                            "code": "007052",
                            "cap": "11026",
                            "nome": "Pont-Saint-Martin"
                        },
                        {
                            "code": "007053",
                            "cap": "11010",
                            "nome": "Pr\u00e8-Saint-Didier"
                        },
                        {
                            "code": "007054",
                            "cap": "11020",
                            "nome": "Quart"
                        },
                        {
                            "code": "007055",
                            "cap": "11010",
                            "nome": "Rhemes-Notre-Dame"
                        },
                        {
                            "code": "007056",
                            "cap": "11010",
                            "nome": "Rhemes-Saint-Georges"
                        },
                        {
                            "code": "007057",
                            "cap": "11010",
                            "nome": "Roisan"
                        },
                        {
                            "code": "007058",
                            "cap": "11020",
                            "nome": "Saint-Christophe"
                        },
                        {
                            "code": "007059",
                            "cap": "11023",
                            "nome": "Saint-Denis"
                        },
                        {
                            "code": "007060",
                            "cap": "11020",
                            "nome": "Saint-Marcel"
                        },
                        {
                            "code": "007061",
                            "cap": "11010",
                            "nome": "Saint-Nicolas"
                        },
                        {
                            "code": "007062",
                            "cap": "11014",
                            "nome": "Saint-Oyen"
                        },
                        {
                            "code": "007063",
                            "cap": "11010",
                            "nome": "Saint-Pierre"
                        },
                        {
                            "code": "007064",
                            "cap": "11010",
                            "nome": "Saint-Rh\u00e9my-en-Bosses"
                        },
                        {
                            "code": "007065",
                            "cap": "11027",
                            "nome": "Saint-Vincent"
                        },
                        {
                            "code": "007066",
                            "cap": "11010",
                            "nome": "Sarre"
                        },
                        {
                            "code": "007067",
                            "cap": "11020",
                            "nome": "Torgnon"
                        },
                        {
                            "code": "007068",
                            "cap": "11010",
                            "nome": "Valgrisenche"
                        },
                        {
                            "code": "007069",
                            "cap": "11010",
                            "nome": "Valpelline"
                        },
                        {
                            "code": "007070",
                            "cap": "11010",
                            "nome": "Valsavarenche"
                        },
                        {
                            "code": "007071",
                            "cap": "11028",
                            "nome": "Valtournenche"
                        },
                        {
                            "code": "007072",
                            "cap": "11020",
                            "nome": "Verrayes"
                        },
                        {
                            "code": "007073",
                            "cap": "11029",
                            "nome": "Verr\u00e8s"
                        },
                        {
                            "code": "007074",
                            "cap": "11018",
                            "nome": "Villeneuve"
                        }
                    ],
                    "nome": "Aosta"
                }
            ],
            "nome": "Valle d'Aosta"
        },
        {
            "province": [
                {
                    "code": "BL",
                    "comuni": [
                        {
                            "code": "025001",
                            "cap": "32021",
                            "nome": "Agordo"
                        },
                        {
                            "code": "025002",
                            "cap": "32031",
                            "nome": "Alano di Piave"
                        },
                        {
                            "code": "025003",
                            "cap": "32022",
                            "nome": "Alleghe"
                        },
                        {
                            "code": "025004",
                            "cap": "32030",
                            "nome": "Arsi\u00e8"
                        },
                        {
                            "code": "025005",
                            "cap": "32041",
                            "nome": "Auronzo di Cadore"
                        },
                        {
                            "code": "025006",
                            "cap": "32100",
                            "nome": "Belluno"
                        },
                        {
                            "code": "025007",
                            "cap": "32040",
                            "nome": "Borca di Cadore"
                        },
                        {
                            "code": "025008",
                            "cap": "32042",
                            "nome": "Calalzo di Cadore"
                        },
                        {
                            "code": "025023",
                            "cap": "32020",
                            "nome": "Canale d'Agordo"
                        },
                        {
                            "code": "025009",
                            "cap": "32010",
                            "nome": "Castellavazzo"
                        },
                        {
                            "code": "025010",
                            "cap": "32020",
                            "nome": "Cencenighe Agordino"
                        },
                        {
                            "code": "025011",
                            "cap": "32030",
                            "nome": "Cesiomaggiore"
                        },
                        {
                            "code": "025012",
                            "cap": "32010",
                            "nome": "Chies d'Alpago"
                        },
                        {
                            "code": "025013",
                            "cap": "32040",
                            "nome": "Cibiana di Cadore"
                        },
                        {
                            "code": "025014",
                            "cap": "32020",
                            "nome": "Colle Santa Lucia"
                        },
                        {
                            "code": "025015",
                            "cap": "32040",
                            "nome": "Comelico Superiore"
                        },
                        {
                            "code": "025016",
                            "cap": "32043",
                            "nome": "Cortina d'Ampezzo"
                        },
                        {
                            "code": "025017",
                            "cap": "32040",
                            "nome": "Danta di Cadore"
                        },
                        {
                            "code": "025018",
                            "cap": "32040",
                            "nome": "Domegge di Cadore"
                        },
                        {
                            "code": "025019",
                            "cap": "32020",
                            "nome": "Falcade"
                        },
                        {
                            "code": "025020",
                            "cap": "32016",
                            "nome": "Farra d'Alpago"
                        },
                        {
                            "code": "025021",
                            "cap": "32032",
                            "nome": "Feltre"
                        },
                        {
                            "code": "025022",
                            "cap": "32030",
                            "nome": "Fonzaso"
                        },
                        {
                            "code": "025024",
                            "cap": "32012",
                            "nome": "Forno di Zoldo"
                        },
                        {
                            "code": "025025",
                            "cap": "32020",
                            "nome": "Gosaldo"
                        },
                        {
                            "code": "025027",
                            "cap": "32020",
                            "nome": "La Valle Agordina"
                        },
                        {
                            "code": "025026",
                            "cap": "32033",
                            "nome": "Lamon"
                        },
                        {
                            "code": "025028",
                            "cap": "32020",
                            "nome": "Lentiai"
                        },
                        {
                            "code": "025029",
                            "cap": "32020",
                            "nome": "Limana"
                        },
                        {
                            "code": "025030",
                            "cap": "32020",
                            "nome": "Livinallongo del Col di Lana"
                        },
                        {
                            "code": "025031",
                            "cap": "32013",
                            "nome": "Longarone"
                        },
                        {
                            "code": "025032",
                            "cap": "32040",
                            "nome": "Lorenzago di Cadore"
                        },
                        {
                            "code": "025033",
                            "cap": "32040",
                            "nome": "Lozzo di Cadore"
                        },
                        {
                            "code": "025034",
                            "cap": "32026",
                            "nome": "Mel"
                        },
                        {
                            "code": "025035",
                            "cap": "32010",
                            "nome": "Ospitale di Cadore"
                        },
                        {
                            "code": "025036",
                            "cap": "32034",
                            "nome": "Pedavena"
                        },
                        {
                            "code": "025037",
                            "cap": "32010",
                            "nome": "Perarolo di Cadore"
                        },
                        {
                            "code": "025038",
                            "cap": "32010",
                            "nome": "Pieve d'Alpago"
                        },
                        {
                            "code": "025039",
                            "cap": "32044",
                            "nome": "Pieve di Cadore"
                        },
                        {
                            "code": "025040",
                            "cap": "32014",
                            "nome": "Ponte nelle Alpi"
                        },
                        {
                            "code": "025041",
                            "cap": "32015",
                            "nome": "Puos d'Alpago"
                        },
                        {
                            "code": "025042",
                            "cap": "32030",
                            "nome": "Quero"
                        },
                        {
                            "code": "025043",
                            "cap": "32020",
                            "nome": "Rivamonte Agordino"
                        },
                        {
                            "code": "025044",
                            "cap": "32020",
                            "nome": "Rocca Pietore"
                        },
                        {
                            "code": "025045",
                            "cap": "32030",
                            "nome": "San Gregorio nelle Alpi"
                        },
                        {
                            "code": "025046",
                            "cap": "32040",
                            "nome": "San Nicol\u00f2 di Comelico"
                        },
                        {
                            "code": "025047",
                            "cap": "32040",
                            "nome": "San Pietro di Cadore"
                        },
                        {
                            "code": "025049",
                            "cap": "32020",
                            "nome": "San Tomaso Agordino"
                        },
                        {
                            "code": "025051",
                            "cap": "32046",
                            "nome": "San Vito di Cadore"
                        },
                        {
                            "code": "025048",
                            "cap": "32035",
                            "nome": "Santa Giustina"
                        },
                        {
                            "code": "025050",
                            "cap": "32045",
                            "nome": "Santo Stefano di Cadore"
                        },
                        {
                            "code": "025052",
                            "cap": "32047",
                            "nome": "Sappada"
                        },
                        {
                            "code": "025053",
                            "cap": "32036",
                            "nome": "Sedico"
                        },
                        {
                            "code": "025054",
                            "cap": "32020",
                            "nome": "Selva di Cadore"
                        },
                        {
                            "code": "025055",
                            "cap": "32030",
                            "nome": "Seren del Grappa"
                        },
                        {
                            "code": "025056",
                            "cap": "32037",
                            "nome": "Sospirolo"
                        },
                        {
                            "code": "025057",
                            "cap": "32010",
                            "nome": "Soverzene"
                        },
                        {
                            "code": "025058",
                            "cap": "32030",
                            "nome": "Sovramonte"
                        },
                        {
                            "code": "025059",
                            "cap": "32027",
                            "nome": "Taibon Agordino"
                        },
                        {
                            "code": "025060",
                            "cap": "32010",
                            "nome": "Tambre"
                        },
                        {
                            "code": "025061",
                            "cap": "32028",
                            "nome": "Trichiana"
                        },
                        {
                            "code": "025062",
                            "cap": "32020",
                            "nome": "Vallada Agordina"
                        },
                        {
                            "code": "025063",
                            "cap": "32040",
                            "nome": "Valle di Cadore"
                        },
                        {
                            "code": "025064",
                            "cap": "32030",
                            "nome": "Vas"
                        },
                        {
                            "code": "025065",
                            "cap": "32040",
                            "nome": "Vigo di Cadore"
                        },
                        {
                            "code": "025066",
                            "cap": "32040",
                            "nome": "Vodo Cadore"
                        },
                        {
                            "code": "025067",
                            "cap": "32020",
                            "nome": "Voltago Agordino"
                        },
                        {
                            "code": "025068",
                            "cap": "32010",
                            "nome": "Zoldo Alto"
                        },
                        {
                            "code": "025069",
                            "cap": "32010",
                            "nome": "Zopp\u00e8 di Cadore"
                        }
                    ],
                    "nome": "Belluno"
                },
                {
                    "code": "PD",
                    "comuni": [
                        {
                            "code": "028001",
                            "cap": "35031",
                            "nome": "Abano Terme"
                        },
                        {
                            "code": "028002",
                            "cap": "35021",
                            "nome": "Agna"
                        },
                        {
                            "code": "028003",
                            "cap": "35020",
                            "nome": "Albignasego"
                        },
                        {
                            "code": "028004",
                            "cap": "35022",
                            "nome": "Anguillara Veneta"
                        },
                        {
                            "code": "028005",
                            "cap": "35032",
                            "nome": "Arqu\u00e0 Petrarca"
                        },
                        {
                            "code": "028006",
                            "cap": "35020",
                            "nome": "Arre"
                        },
                        {
                            "code": "028007",
                            "cap": "35020",
                            "nome": "Arzergrande"
                        },
                        {
                            "code": "028008",
                            "cap": "35023",
                            "nome": "Bagnoli di Sopra"
                        },
                        {
                            "code": "028009",
                            "cap": "35030",
                            "nome": "Baone"
                        },
                        {
                            "code": "028010",
                            "cap": "35040",
                            "nome": "Barbona"
                        },
                        {
                            "code": "028011",
                            "cap": "35041",
                            "nome": "Battaglia Terme"
                        },
                        {
                            "code": "028012",
                            "cap": "35040",
                            "nome": "Boara Pisani"
                        },
                        {
                            "code": "028013",
                            "cap": "35010",
                            "nome": "Borgoricco"
                        },
                        {
                            "code": "028014",
                            "cap": "35024",
                            "nome": "Bovolenta"
                        },
                        {
                            "code": "028015",
                            "cap": "35020",
                            "nome": "Brugine"
                        },
                        {
                            "code": "028016",
                            "cap": "35010",
                            "nome": "Cadoneghe"
                        },
                        {
                            "code": "028020",
                            "cap": "35010",
                            "nome": "Campo San Martino"
                        },
                        {
                            "code": "028017",
                            "cap": "35011",
                            "nome": "Campodarsego"
                        },
                        {
                            "code": "028018",
                            "cap": "35010",
                            "nome": "Campodoro"
                        },
                        {
                            "code": "028019",
                            "cap": "35012",
                            "nome": "Camposampiero"
                        },
                        {
                            "code": "028021",
                            "cap": "35020",
                            "nome": "Candiana"
                        },
                        {
                            "code": "028022",
                            "cap": "35040",
                            "nome": "Carceri"
                        },
                        {
                            "code": "028023",
                            "cap": "35010",
                            "nome": "Carmignano di Brenta"
                        },
                        {
                            "code": "028026",
                            "cap": "35025",
                            "nome": "Cartura"
                        },
                        {
                            "code": "028027",
                            "cap": "35040",
                            "nome": "Casale di Scodosia"
                        },
                        {
                            "code": "028028",
                            "cap": "35020",
                            "nome": "Casalserugo"
                        },
                        {
                            "code": "028029",
                            "cap": "35040",
                            "nome": "Castelbaldo"
                        },
                        {
                            "code": "028030",
                            "cap": "35030",
                            "nome": "Cervarese Santa Croce"
                        },
                        {
                            "code": "028031",
                            "cap": "35030",
                            "nome": "Cinto Euganeo"
                        },
                        {
                            "code": "028032",
                            "cap": "35013",
                            "nome": "Cittadella"
                        },
                        {
                            "code": "028033",
                            "cap": "35020",
                            "nome": "Codevigo"
                        },
                        {
                            "code": "028034",
                            "cap": "35026",
                            "nome": "Conselve"
                        },
                        {
                            "code": "028035",
                            "cap": "35020",
                            "nome": "Correzzola"
                        },
                        {
                            "code": "028036",
                            "cap": "35010",
                            "nome": "Curtarolo"
                        },
                        {
                            "code": "028106",
                            "cap": "35020",
                            "nome": "Due Carrare"
                        },
                        {
                            "code": "028037",
                            "cap": "35042",
                            "nome": "Este"
                        },
                        {
                            "code": "028038",
                            "cap": "35014",
                            "nome": "Fontaniva"
                        },
                        {
                            "code": "028039",
                            "cap": "35015",
                            "nome": "Galliera Veneta"
                        },
                        {
                            "code": "028040",
                            "cap": "35030",
                            "nome": "Galzignano Terme"
                        },
                        {
                            "code": "028041",
                            "cap": "35010",
                            "nome": "Gazzo"
                        },
                        {
                            "code": "028042",
                            "cap": "35010",
                            "nome": "Grantorto"
                        },
                        {
                            "code": "028043",
                            "cap": "35040",
                            "nome": "Granze"
                        },
                        {
                            "code": "028044",
                            "cap": "35020",
                            "nome": "Legnaro"
                        },
                        {
                            "code": "028045",
                            "cap": "35010",
                            "nome": "Limena"
                        },
                        {
                            "code": "028046",
                            "cap": "35010",
                            "nome": "Loreggia"
                        },
                        {
                            "code": "028047",
                            "cap": "35034",
                            "nome": "Lozzo Atestino"
                        },
                        {
                            "code": "028048",
                            "cap": "35020",
                            "nome": "Maser\u00e0 di Padova"
                        },
                        {
                            "code": "028049",
                            "cap": "35040",
                            "nome": "Masi"
                        },
                        {
                            "code": "028050",
                            "cap": "35010",
                            "nome": "Massanzago"
                        },
                        {
                            "code": "028051",
                            "cap": "35040",
                            "nome": "Megliadino San Fidenzio"
                        },
                        {
                            "code": "028052",
                            "cap": "35040",
                            "nome": "Megliadino San Vitale"
                        },
                        {
                            "code": "028053",
                            "cap": "35040",
                            "nome": "Merlara"
                        },
                        {
                            "code": "028054",
                            "cap": "35035",
                            "nome": "Mestrino"
                        },
                        {
                            "code": "028055",
                            "cap": "35043",
                            "nome": "Monselice"
                        },
                        {
                            "code": "028056",
                            "cap": "35044",
                            "nome": "Montagnana"
                        },
                        {
                            "code": "028057",
                            "cap": "35036",
                            "nome": "Montegrotto Terme"
                        },
                        {
                            "code": "028058",
                            "cap": "35027",
                            "nome": "Noventa Padovana"
                        },
                        {
                            "code": "028059",
                            "cap": "35045",
                            "nome": "Ospedaletto Euganeo"
                        },
                        {
                            "code": "028060",
                            "cap": "351xx",
                            "nome": "Padova"
                        },
                        {
                            "code": "028061",
                            "cap": "35020",
                            "nome": "Pernumia"
                        },
                        {
                            "code": "028062",
                            "cap": "35040",
                            "nome": "Piacenza d'Adige"
                        },
                        {
                            "code": "028063",
                            "cap": "35016",
                            "nome": "Piazzola sul Brenta"
                        },
                        {
                            "code": "028064",
                            "cap": "35017",
                            "nome": "Piombino Dese"
                        },
                        {
                            "code": "028065",
                            "cap": "35028",
                            "nome": "Piove di Sacco"
                        },
                        {
                            "code": "028066",
                            "cap": "35020",
                            "nome": "Polverara"
                        },
                        {
                            "code": "028067",
                            "cap": "35040",
                            "nome": "Ponso"
                        },
                        {
                            "code": "028069",
                            "cap": "35020",
                            "nome": "Ponte San Nicol\u00f2"
                        },
                        {
                            "code": "028068",
                            "cap": "35029",
                            "nome": "Pontelongo"
                        },
                        {
                            "code": "028070",
                            "cap": "35020",
                            "nome": "Pozzonovo"
                        },
                        {
                            "code": "028071",
                            "cap": "35030",
                            "nome": "Rovolon"
                        },
                        {
                            "code": "028072",
                            "cap": "35030",
                            "nome": "Rubano"
                        },
                        {
                            "code": "028073",
                            "cap": "35030",
                            "nome": "Saccolongo"
                        },
                        {
                            "code": "028074",
                            "cap": "35046",
                            "nome": "Saletto"
                        },
                        {
                            "code": "028075",
                            "cap": "35010",
                            "nome": "San Giorgio delle Pertiche"
                        },
                        {
                            "code": "028076",
                            "cap": "35010",
                            "nome": "San Giorgio in Bosco"
                        },
                        {
                            "code": "028077",
                            "cap": "35018",
                            "nome": "San Martino di Lupari"
                        },
                        {
                            "code": "028078",
                            "cap": "35010",
                            "nome": "San Pietro in Gu"
                        },
                        {
                            "code": "028079",
                            "cap": "35020",
                            "nome": "San Pietro Viminario"
                        },
                        {
                            "code": "028080",
                            "cap": "35010",
                            "nome": "Santa Giustina in Colle"
                        },
                        {
                            "code": "028081",
                            "cap": "35040",
                            "nome": "Santa Margherita d'Adige"
                        },
                        {
                            "code": "028082",
                            "cap": "35020",
                            "nome": "Sant'Angelo di Piove di Sacco"
                        },
                        {
                            "code": "028083",
                            "cap": "35040",
                            "nome": "Sant'Elena"
                        },
                        {
                            "code": "028084",
                            "cap": "35040",
                            "nome": "Sant'Urbano"
                        },
                        {
                            "code": "028085",
                            "cap": "35020",
                            "nome": "Saonara"
                        },
                        {
                            "code": "028086",
                            "cap": "35030",
                            "nome": "Selvazzano Dentro"
                        },
                        {
                            "code": "028087",
                            "cap": "35047",
                            "nome": "Solesino"
                        },
                        {
                            "code": "028088",
                            "cap": "35048",
                            "nome": "Stanghella"
                        },
                        {
                            "code": "028089",
                            "cap": "35037",
                            "nome": "Teolo"
                        },
                        {
                            "code": "028090",
                            "cap": "35020",
                            "nome": "Terrassa Padovana"
                        },
                        {
                            "code": "028091",
                            "cap": "35019",
                            "nome": "Tombolo"
                        },
                        {
                            "code": "028092",
                            "cap": "35038",
                            "nome": "Torreglia"
                        },
                        {
                            "code": "028093",
                            "cap": "35010",
                            "nome": "Trebaseleghe"
                        },
                        {
                            "code": "028094",
                            "cap": "35020",
                            "nome": "Tribano"
                        },
                        {
                            "code": "028095",
                            "cap": "35040",
                            "nome": "Urbana"
                        },
                        {
                            "code": "028096",
                            "cap": "35030",
                            "nome": "Veggiano"
                        },
                        {
                            "code": "028097",
                            "cap": "35040",
                            "nome": "Vescovana"
                        },
                        {
                            "code": "028098",
                            "cap": "35040",
                            "nome": "Vighizzolo d'Este"
                        },
                        {
                            "code": "028099",
                            "cap": "35010",
                            "nome": "Vigodarzere"
                        },
                        {
                            "code": "028100",
                            "cap": "35010",
                            "nome": "Vigonza"
                        },
                        {
                            "code": "028101",
                            "cap": "35010",
                            "nome": "Villa del Conte"
                        },
                        {
                            "code": "028102",
                            "cap": "35040",
                            "nome": "Villa Estense"
                        },
                        {
                            "code": "028103",
                            "cap": "35010",
                            "nome": "Villafranca Padovana"
                        },
                        {
                            "code": "028104",
                            "cap": "35010",
                            "nome": "Villanova di Camposampiero"
                        },
                        {
                            "code": "028105",
                            "cap": "35030",
                            "nome": "Vo'"
                        }
                    ],
                    "nome": "Padova"
                },
                {
                    "code": "RO",
                    "comuni": [
                        {
                            "code": "029001",
                            "cap": "45011",
                            "nome": "Adria"
                        },
                        {
                            "code": "029002",
                            "cap": "45012",
                            "nome": "Ariano nel Polesine"
                        },
                        {
                            "code": "029003",
                            "cap": "45031",
                            "nome": "Arqu\u00e0 Polesine"
                        },
                        {
                            "code": "029004",
                            "cap": "45021",
                            "nome": "Badia Polesine"
                        },
                        {
                            "code": "029005",
                            "cap": "45022",
                            "nome": "Bagnolo di Po"
                        },
                        {
                            "code": "029006",
                            "cap": "45032",
                            "nome": "Bergantino"
                        },
                        {
                            "code": "029007",
                            "cap": "45033",
                            "nome": "Bosaro"
                        },
                        {
                            "code": "029008",
                            "cap": "45030",
                            "nome": "Calto"
                        },
                        {
                            "code": "029009",
                            "cap": "45034",
                            "nome": "Canaro"
                        },
                        {
                            "code": "029010",
                            "cap": "45020",
                            "nome": "Canda"
                        },
                        {
                            "code": "029011",
                            "cap": "45020",
                            "nome": "Castelguglielmo"
                        },
                        {
                            "code": "029012",
                            "cap": "45035",
                            "nome": "Castelmassa"
                        },
                        {
                            "code": "029013",
                            "cap": "45030",
                            "nome": "Castelnovo Bariano"
                        },
                        {
                            "code": "029014",
                            "cap": "45030",
                            "nome": "Ceneselli"
                        },
                        {
                            "code": "029015",
                            "cap": "45010",
                            "nome": "Ceregnano"
                        },
                        {
                            "code": "029017",
                            "cap": "45015",
                            "nome": "Corbola"
                        },
                        {
                            "code": "029018",
                            "cap": "45023",
                            "nome": "Costa di Rovigo"
                        },
                        {
                            "code": "029019",
                            "cap": "45030",
                            "nome": "Crespino"
                        },
                        {
                            "code": "029021",
                            "cap": "45036",
                            "nome": "Ficarolo"
                        },
                        {
                            "code": "029022",
                            "cap": "45024",
                            "nome": "Fiesso Umbertiano"
                        },
                        {
                            "code": "029023",
                            "cap": "45030",
                            "nome": "Frassinelle Polesine"
                        },
                        {
                            "code": "029024",
                            "cap": "45025",
                            "nome": "Fratta Polesine"
                        },
                        {
                            "code": "029025",
                            "cap": "45030",
                            "nome": "Gaiba"
                        },
                        {
                            "code": "029026",
                            "cap": "45010",
                            "nome": "Gavello"
                        },
                        {
                            "code": "029027",
                            "cap": "45020",
                            "nome": "Giacciano con Baruchella"
                        },
                        {
                            "code": "029028",
                            "cap": "45030",
                            "nome": "Guarda Veneta"
                        },
                        {
                            "code": "029029",
                            "cap": "45026",
                            "nome": "Lendinara"
                        },
                        {
                            "code": "029030",
                            "cap": "45017",
                            "nome": "Loreo"
                        },
                        {
                            "code": "029031",
                            "cap": "45020",
                            "nome": "Lusia"
                        },
                        {
                            "code": "029032",
                            "cap": "45037",
                            "nome": "Melara"
                        },
                        {
                            "code": "029033",
                            "cap": "45030",
                            "nome": "Occhiobello"
                        },
                        {
                            "code": "029034",
                            "cap": "45010",
                            "nome": "Papozze"
                        },
                        {
                            "code": "029035",
                            "cap": "45010",
                            "nome": "Pettorazza Grimani"
                        },
                        {
                            "code": "029036",
                            "cap": "45020",
                            "nome": "Pincara"
                        },
                        {
                            "code": "029037",
                            "cap": "45038",
                            "nome": "Polesella"
                        },
                        {
                            "code": "029038",
                            "cap": "45030",
                            "nome": "Pontecchio Polesine"
                        },
                        {
                            "code": "029039",
                            "cap": "45018",
                            "nome": "Porto Tolle"
                        },
                        {
                            "code": "029052",
                            "cap": "45014",
                            "nome": "Porto Viro"
                        },
                        {
                            "code": "029040",
                            "cap": "45010",
                            "nome": "Rosolina"
                        },
                        {
                            "code": "029041",
                            "cap": "45100",
                            "nome": "Rovigo"
                        },
                        {
                            "code": "029042",
                            "cap": "45030",
                            "nome": "Salara"
                        },
                        {
                            "code": "029043",
                            "cap": "45020",
                            "nome": "San Bellino"
                        },
                        {
                            "code": "029044",
                            "cap": "45030",
                            "nome": "San Martino di Venezze"
                        },
                        {
                            "code": "029045",
                            "cap": "45039",
                            "nome": "Stienta"
                        },
                        {
                            "code": "029046",
                            "cap": "45019",
                            "nome": "Taglio di Po"
                        },
                        {
                            "code": "029047",
                            "cap": "45027",
                            "nome": "Trecenta"
                        },
                        {
                            "code": "029048",
                            "cap": "45010",
                            "nome": "Villadose"
                        },
                        {
                            "code": "029049",
                            "cap": "45030",
                            "nome": "Villamarzana"
                        },
                        {
                            "code": "029050",
                            "cap": "45020",
                            "nome": "Villanova del Ghebbo"
                        },
                        {
                            "code": "029051",
                            "cap": "45030",
                            "nome": "Villanova Marchesana"
                        }
                    ],
                    "nome": "Rovigo"
                },
                {
                    "code": "TV",
                    "comuni": [
                        {
                            "code": "026001",
                            "cap": "31030",
                            "nome": "Altivole"
                        },
                        {
                            "code": "026002",
                            "cap": "31030",
                            "nome": "Arcade"
                        },
                        {
                            "code": "026003",
                            "cap": "31011",
                            "nome": "Asolo"
                        },
                        {
                            "code": "026004",
                            "cap": "31030",
                            "nome": "Borso del Grappa"
                        },
                        {
                            "code": "026005",
                            "cap": "31030",
                            "nome": "Breda di Piave"
                        },
                        {
                            "code": "026006",
                            "cap": "31031",
                            "nome": "Caerano di San Marco"
                        },
                        {
                            "code": "026007",
                            "cap": "31012",
                            "nome": "Cappella Maggiore"
                        },
                        {
                            "code": "026008",
                            "cap": "31030",
                            "nome": "Carbonera"
                        },
                        {
                            "code": "026009",
                            "cap": "31032",
                            "nome": "Casale sul Sile"
                        },
                        {
                            "code": "026010",
                            "cap": "31030",
                            "nome": "Casier"
                        },
                        {
                            "code": "026011",
                            "cap": "31030",
                            "nome": "Castelcucco"
                        },
                        {
                            "code": "026012",
                            "cap": "31033",
                            "nome": "Castelfranco Veneto"
                        },
                        {
                            "code": "026013",
                            "cap": "31030",
                            "nome": "Castello di Godego"
                        },
                        {
                            "code": "026014",
                            "cap": "31034",
                            "nome": "Cavaso del Tomba"
                        },
                        {
                            "code": "026015",
                            "cap": "31040",
                            "nome": "Cessalto"
                        },
                        {
                            "code": "026016",
                            "cap": "31040",
                            "nome": "Chiarano"
                        },
                        {
                            "code": "026017",
                            "cap": "31010",
                            "nome": "Cimadolmo"
                        },
                        {
                            "code": "026018",
                            "cap": "31030",
                            "nome": "Cison di Valmarino"
                        },
                        {
                            "code": "026019",
                            "cap": "31013",
                            "nome": "Codogn\u00e8"
                        },
                        {
                            "code": "026020",
                            "cap": "31014",
                            "nome": "Colle Umberto"
                        },
                        {
                            "code": "026021",
                            "cap": "31015",
                            "nome": "Conegliano"
                        },
                        {
                            "code": "026022",
                            "cap": "31016",
                            "nome": "Cordignano"
                        },
                        {
                            "code": "026023",
                            "cap": "31041",
                            "nome": "Cornuda"
                        },
                        {
                            "code": "026024",
                            "cap": "31017",
                            "nome": "Crespano del Grappa"
                        },
                        {
                            "code": "026025",
                            "cap": "31035",
                            "nome": "Crocetta del Montello"
                        },
                        {
                            "code": "026026",
                            "cap": "31010",
                            "nome": "Farra di Soligo"
                        },
                        {
                            "code": "026027",
                            "cap": "31051",
                            "nome": "Follina"
                        },
                        {
                            "code": "026028",
                            "cap": "31043",
                            "nome": "Fontanelle"
                        },
                        {
                            "code": "026029",
                            "cap": "31010",
                            "nome": "Fonte"
                        },
                        {
                            "code": "026030",
                            "cap": "31010",
                            "nome": "Fregona"
                        },
                        {
                            "code": "026031",
                            "cap": "31018",
                            "nome": "Gaiarine"
                        },
                        {
                            "code": "026032",
                            "cap": "31040",
                            "nome": "Giavera del Montello"
                        },
                        {
                            "code": "026033",
                            "cap": "31010",
                            "nome": "Godega di Sant'Urbano"
                        },
                        {
                            "code": "026034",
                            "cap": "31040",
                            "nome": "Gorgo al Monticano"
                        },
                        {
                            "code": "026035",
                            "cap": "31036",
                            "nome": "Istrana"
                        },
                        {
                            "code": "026036",
                            "cap": "31037",
                            "nome": "Loria"
                        },
                        {
                            "code": "026037",
                            "cap": "31040",
                            "nome": "Mansu\u00e8"
                        },
                        {
                            "code": "026038",
                            "cap": "31010",
                            "nome": "Mareno di Piave"
                        },
                        {
                            "code": "026039",
                            "cap": "31010",
                            "nome": "Maser"
                        },
                        {
                            "code": "026040",
                            "cap": "31052",
                            "nome": "Maserada sul Piave"
                        },
                        {
                            "code": "026041",
                            "cap": "31040",
                            "nome": "Meduna di Livenza"
                        },
                        {
                            "code": "026042",
                            "cap": "31050",
                            "nome": "Miane"
                        },
                        {
                            "code": "026043",
                            "cap": "31021",
                            "nome": "Mogliano Veneto"
                        },
                        {
                            "code": "026044",
                            "cap": "31050",
                            "nome": "Monastier di Treviso"
                        },
                        {
                            "code": "026045",
                            "cap": "31010",
                            "nome": "Monfumo"
                        },
                        {
                            "code": "026046",
                            "cap": "31044",
                            "nome": "Montebelluna"
                        },
                        {
                            "code": "026047",
                            "cap": "31050",
                            "nome": "Morgano"
                        },
                        {
                            "code": "026048",
                            "cap": "31010",
                            "nome": "Moriago della Battaglia"
                        },
                        {
                            "code": "026049",
                            "cap": "31045",
                            "nome": "Motta di Livenza"
                        },
                        {
                            "code": "026050",
                            "cap": "31040",
                            "nome": "Nervesa della Battaglia"
                        },
                        {
                            "code": "026051",
                            "cap": "31046",
                            "nome": "Oderzo"
                        },
                        {
                            "code": "026052",
                            "cap": "31024",
                            "nome": "Ormelle"
                        },
                        {
                            "code": "026053",
                            "cap": "31010",
                            "nome": "Orsago"
                        },
                        {
                            "code": "026054",
                            "cap": "31017",
                            "nome": "Paderno del Grappa"
                        },
                        {
                            "code": "026055",
                            "cap": "31038",
                            "nome": "Paese"
                        },
                        {
                            "code": "026056",
                            "cap": "31040",
                            "nome": "Pederobba"
                        },
                        {
                            "code": "026057",
                            "cap": "31053",
                            "nome": "Pieve di Soligo"
                        },
                        {
                            "code": "026058",
                            "cap": "31047",
                            "nome": "Ponte di Piave"
                        },
                        {
                            "code": "026059",
                            "cap": "31050",
                            "nome": "Ponzano Veneto"
                        },
                        {
                            "code": "026060",
                            "cap": "31040",
                            "nome": "Portobuffol\u00e8"
                        },
                        {
                            "code": "026061",
                            "cap": "31054",
                            "nome": "Possagno"
                        },
                        {
                            "code": "026062",
                            "cap": "31050",
                            "nome": "Povegliano"
                        },
                        {
                            "code": "026063",
                            "cap": "31022",
                            "nome": "Preganziol"
                        },
                        {
                            "code": "026064",
                            "cap": "31055",
                            "nome": "Quinto di Treviso"
                        },
                        {
                            "code": "026065",
                            "cap": "31020",
                            "nome": "Refrontolo"
                        },
                        {
                            "code": "026066",
                            "cap": "31023",
                            "nome": "Resana"
                        },
                        {
                            "code": "026067",
                            "cap": "31020",
                            "nome": "Revine Lago"
                        },
                        {
                            "code": "026068",
                            "cap": "31039",
                            "nome": "Riese Pio X"
                        },
                        {
                            "code": "026069",
                            "cap": "31056",
                            "nome": "Roncade"
                        },
                        {
                            "code": "026070",
                            "cap": "31040",
                            "nome": "Salgareda"
                        },
                        {
                            "code": "026071",
                            "cap": "31048",
                            "nome": "San Biagio di Callalta"
                        },
                        {
                            "code": "026072",
                            "cap": "31020",
                            "nome": "San Fior"
                        },
                        {
                            "code": "026073",
                            "cap": "31020",
                            "nome": "San Pietro di Feletto"
                        },
                        {
                            "code": "026074",
                            "cap": "31020",
                            "nome": "San Polo di Piave"
                        },
                        {
                            "code": "026076",
                            "cap": "31020",
                            "nome": "San Vendemiano"
                        },
                        {
                            "code": "026077",
                            "cap": "31020",
                            "nome": "San Zenone degli Ezzelini"
                        },
                        {
                            "code": "026075",
                            "cap": "31025",
                            "nome": "Santa Lucia di Piave"
                        },
                        {
                            "code": "026078",
                            "cap": "31026",
                            "nome": "Sarmede"
                        },
                        {
                            "code": "026079",
                            "cap": "31040",
                            "nome": "Segusino"
                        },
                        {
                            "code": "026080",
                            "cap": "31020",
                            "nome": "Sernaglia della Battaglia"
                        },
                        {
                            "code": "026081",
                            "cap": "31057",
                            "nome": "Silea"
                        },
                        {
                            "code": "026082",
                            "cap": "31027",
                            "nome": "Spresiano"
                        },
                        {
                            "code": "026083",
                            "cap": "31058",
                            "nome": "Susegana"
                        },
                        {
                            "code": "026084",
                            "cap": "31020",
                            "nome": "Tarzo"
                        },
                        {
                            "code": "026085",
                            "cap": "31040",
                            "nome": "Trevignano"
                        },
                        {
                            "code": "026086",
                            "cap": "31100",
                            "nome": "Treviso"
                        },
                        {
                            "code": "026087",
                            "cap": "31049",
                            "nome": "Valdobbiadene"
                        },
                        {
                            "code": "026088",
                            "cap": "31028",
                            "nome": "Vazzola"
                        },
                        {
                            "code": "026089",
                            "cap": "31050",
                            "nome": "Vedelago"
                        },
                        {
                            "code": "026090",
                            "cap": "31020",
                            "nome": "Vidor"
                        },
                        {
                            "code": "026091",
                            "cap": "31020",
                            "nome": "Villorba"
                        },
                        {
                            "code": "026092",
                            "cap": "31029",
                            "nome": "Vittorio Veneto"
                        },
                        {
                            "code": "026093",
                            "cap": "31040",
                            "nome": "Volpago del Montello"
                        },
                        {
                            "code": "026094",
                            "cap": "31050",
                            "nome": "Zenson di Piave"
                        },
                        {
                            "code": "026095",
                            "cap": "31059",
                            "nome": "Zero Branco"
                        }
                    ],
                    "nome": "Treviso"
                },
                {
                    "code": "VE",
                    "comuni": [
                        {
                            "code": "027001",
                            "cap": "30020",
                            "nome": "Annone Veneto"
                        },
                        {
                            "code": "027002",
                            "cap": "30010",
                            "nome": "Campagna Lupia"
                        },
                        {
                            "code": "027003",
                            "cap": "30010",
                            "nome": "Campolongo Maggiore"
                        },
                        {
                            "code": "027004",
                            "cap": "30010",
                            "nome": "Camponogara"
                        },
                        {
                            "code": "027005",
                            "cap": "30021",
                            "nome": "Caorle"
                        },
                        {
                            "code": "027044",
                            "cap": "30013",
                            "nome": "Cavallino-Treporti"
                        },
                        {
                            "code": "027006",
                            "cap": "30014",
                            "nome": "Cavarzere"
                        },
                        {
                            "code": "027007",
                            "cap": "30022",
                            "nome": "Ceggia"
                        },
                        {
                            "code": "027008",
                            "cap": "30015",
                            "nome": "Chioggia"
                        },
                        {
                            "code": "027009",
                            "cap": "30020",
                            "nome": "Cinto Caomaggiore"
                        },
                        {
                            "code": "027010",
                            "cap": "30010",
                            "nome": "Cona"
                        },
                        {
                            "code": "027011",
                            "cap": "30023",
                            "nome": "Concordia Sagittaria"
                        },
                        {
                            "code": "027012",
                            "cap": "30031",
                            "nome": "Dolo"
                        },
                        {
                            "code": "027013",
                            "cap": "30020",
                            "nome": "Eraclea"
                        },
                        {
                            "code": "027014",
                            "cap": "30032",
                            "nome": "Fiesso d'Artico"
                        },
                        {
                            "code": "027015",
                            "cap": "30020",
                            "nome": "Fossalta di Piave"
                        },
                        {
                            "code": "027016",
                            "cap": "30025",
                            "nome": "Fossalta di Portogruaro"
                        },
                        {
                            "code": "027017",
                            "cap": "30030",
                            "nome": "Foss\u00f2"
                        },
                        {
                            "code": "027018",
                            "cap": "30020",
                            "nome": "Gruaro"
                        },
                        {
                            "code": "027019",
                            "cap": "30016",
                            "nome": "Jesolo"
                        },
                        {
                            "code": "027020",
                            "cap": "30020",
                            "nome": "Marcon"
                        },
                        {
                            "code": "027021",
                            "cap": "30030",
                            "nome": "Martellago"
                        },
                        {
                            "code": "027022",
                            "cap": "30020",
                            "nome": "Meolo"
                        },
                        {
                            "code": "027023",
                            "cap": "30034",
                            "nome": "Mira"
                        },
                        {
                            "code": "027024",
                            "cap": "30035",
                            "nome": "Mirano"
                        },
                        {
                            "code": "027025",
                            "cap": "30024",
                            "nome": "Musile di Piave"
                        },
                        {
                            "code": "027026",
                            "cap": "30033",
                            "nome": "Noale"
                        },
                        {
                            "code": "027027",
                            "cap": "30020",
                            "nome": "Noventa di Piave"
                        },
                        {
                            "code": "027028",
                            "cap": "30030",
                            "nome": "Pianiga"
                        },
                        {
                            "code": "027029",
                            "cap": "30026",
                            "nome": "Portogruaro"
                        },
                        {
                            "code": "027030",
                            "cap": "30020",
                            "nome": "Pramaggiore"
                        },
                        {
                            "code": "027031",
                            "cap": "30020",
                            "nome": "Quarto d'Altino"
                        },
                        {
                            "code": "027032",
                            "cap": "30030",
                            "nome": "Salzano"
                        },
                        {
                            "code": "027033",
                            "cap": "30027",
                            "nome": "San Don\u00e0 di Piave"
                        },
                        {
                            "code": "027034",
                            "cap": "30028",
                            "nome": "San Michele al Tagliamento"
                        },
                        {
                            "code": "027035",
                            "cap": "30036",
                            "nome": "Santa Maria di Sala"
                        },
                        {
                            "code": "027036",
                            "cap": "30029",
                            "nome": "Santo Stino di Livenza"
                        },
                        {
                            "code": "027037",
                            "cap": "30037",
                            "nome": "Scorz\u00e8"
                        },
                        {
                            "code": "027038",
                            "cap": "30038",
                            "nome": "Spinea"
                        },
                        {
                            "code": "027039",
                            "cap": "30039",
                            "nome": "Stra"
                        },
                        {
                            "code": "027040",
                            "cap": "30025",
                            "nome": "Teglio Veneto"
                        },
                        {
                            "code": "027041",
                            "cap": "30020",
                            "nome": "Torre di Mosto"
                        },
                        {
                            "code": "027042",
                            "cap": "301xx",
                            "nome": "Venezia"
                        },
                        {
                            "code": "027043",
                            "cap": "30030",
                            "nome": "Vigonovo"
                        }
                    ],
                    "nome": "Venezia"
                },
                {
                    "code": "VR",
                    "comuni": [
                        {
                            "code": "023001",
                            "cap": "37010",
                            "nome": "Affi"
                        },
                        {
                            "code": "023002",
                            "cap": "37041",
                            "nome": "Albaredo d'Adige"
                        },
                        {
                            "code": "023003",
                            "cap": "37050",
                            "nome": "Angiari"
                        },
                        {
                            "code": "023004",
                            "cap": "37040",
                            "nome": "Arcole"
                        },
                        {
                            "code": "023005",
                            "cap": "37030",
                            "nome": "Badia Calavena"
                        },
                        {
                            "code": "023006",
                            "cap": "37011",
                            "nome": "Bardolino"
                        },
                        {
                            "code": "023007",
                            "cap": "37050",
                            "nome": "Belfiore"
                        },
                        {
                            "code": "023008",
                            "cap": "37040",
                            "nome": "Bevilacqua"
                        },
                        {
                            "code": "023009",
                            "cap": "37040",
                            "nome": "Bonavigo"
                        },
                        {
                            "code": "023010",
                            "cap": "37040",
                            "nome": "Boschi Sant'Anna"
                        },
                        {
                            "code": "023011",
                            "cap": "37021",
                            "nome": "Bosco Chiesanuova"
                        },
                        {
                            "code": "023012",
                            "cap": "37051",
                            "nome": "Bovolone"
                        },
                        {
                            "code": "023013",
                            "cap": "37020",
                            "nome": "Brentino Belluno"
                        },
                        {
                            "code": "023014",
                            "cap": "37010",
                            "nome": "Brenzone"
                        },
                        {
                            "code": "023015",
                            "cap": "37012",
                            "nome": "Bussolengo"
                        },
                        {
                            "code": "023016",
                            "cap": "37060",
                            "nome": "Buttapietra"
                        },
                        {
                            "code": "023017",
                            "cap": "37042",
                            "nome": "Caldiero"
                        },
                        {
                            "code": "023018",
                            "cap": "37013",
                            "nome": "Caprino Veronese"
                        },
                        {
                            "code": "023019",
                            "cap": "37052",
                            "nome": "Casaleone"
                        },
                        {
                            "code": "023020",
                            "cap": "37043",
                            "nome": "Castagnaro"
                        },
                        {
                            "code": "023021",
                            "cap": "37060",
                            "nome": "Castel d'Azzano"
                        },
                        {
                            "code": "023022",
                            "cap": "37014",
                            "nome": "Castelnuovo del Garda"
                        },
                        {
                            "code": "023023",
                            "cap": "37010",
                            "nome": "Cavaion Veronese"
                        },
                        {
                            "code": "023024",
                            "cap": "37030",
                            "nome": "Cazzano di Tramigna"
                        },
                        {
                            "code": "023025",
                            "cap": "37053",
                            "nome": "Cerea"
                        },
                        {
                            "code": "023026",
                            "cap": "37020",
                            "nome": "Cerro Veronese"
                        },
                        {
                            "code": "023027",
                            "cap": "37044",
                            "nome": "Cologna Veneta"
                        },
                        {
                            "code": "023028",
                            "cap": "37030",
                            "nome": "Colognola ai Colli"
                        },
                        {
                            "code": "023029",
                            "cap": "37050",
                            "nome": "Concamarise"
                        },
                        {
                            "code": "023030",
                            "cap": "37010",
                            "nome": "Costermano"
                        },
                        {
                            "code": "023031",
                            "cap": "37020",
                            "nome": "Dolc\u00e8"
                        },
                        {
                            "code": "023032",
                            "cap": "37060",
                            "nome": "Erb\u00e8"
                        },
                        {
                            "code": "023033",
                            "cap": "37020",
                            "nome": "Erbezzo"
                        },
                        {
                            "code": "023034",
                            "cap": "37020",
                            "nome": "Ferrara di Monte Baldo"
                        },
                        {
                            "code": "023035",
                            "cap": "37022",
                            "nome": "Fumane"
                        },
                        {
                            "code": "023036",
                            "cap": "37016",
                            "nome": "Garda"
                        },
                        {
                            "code": "023037",
                            "cap": "37060",
                            "nome": "Gazzo Veronese"
                        },
                        {
                            "code": "023038",
                            "cap": "37023",
                            "nome": "Grezzana"
                        },
                        {
                            "code": "023039",
                            "cap": "37031",
                            "nome": "Illasi"
                        },
                        {
                            "code": "023040",
                            "cap": "37063",
                            "nome": "Isola della Scala"
                        },
                        {
                            "code": "023041",
                            "cap": "37050",
                            "nome": "Isola Rizza"
                        },
                        {
                            "code": "023042",
                            "cap": "37030",
                            "nome": "Lavagno"
                        },
                        {
                            "code": "023043",
                            "cap": "37017",
                            "nome": "Lazise"
                        },
                        {
                            "code": "023044",
                            "cap": "37045",
                            "nome": "Legnago"
                        },
                        {
                            "code": "023045",
                            "cap": "37018",
                            "nome": "Malcesine"
                        },
                        {
                            "code": "023046",
                            "cap": "37020",
                            "nome": "Marano di Valpolicella"
                        },
                        {
                            "code": "023047",
                            "cap": "37030",
                            "nome": "Mezzane di Sotto"
                        },
                        {
                            "code": "023048",
                            "cap": "37046",
                            "nome": "Minerbe"
                        },
                        {
                            "code": "023049",
                            "cap": "37030",
                            "nome": "Montecchia di Crosara"
                        },
                        {
                            "code": "023050",
                            "cap": "37032",
                            "nome": "Monteforte d'Alpone"
                        },
                        {
                            "code": "023051",
                            "cap": "37060",
                            "nome": "Mozzecane"
                        },
                        {
                            "code": "023052",
                            "cap": "37024",
                            "nome": "Negrar"
                        },
                        {
                            "code": "023053",
                            "cap": "37054",
                            "nome": "Nogara"
                        },
                        {
                            "code": "023054",
                            "cap": "37060",
                            "nome": "Nogarole Rocca"
                        },
                        {
                            "code": "023055",
                            "cap": "37050",
                            "nome": "Oppeano"
                        },
                        {
                            "code": "023056",
                            "cap": "37050",
                            "nome": "Pal\u00f9"
                        },
                        {
                            "code": "023057",
                            "cap": "37010",
                            "nome": "Pastrengo"
                        },
                        {
                            "code": "023058",
                            "cap": "37026",
                            "nome": "Pescantina"
                        },
                        {
                            "code": "023059",
                            "cap": "37019",
                            "nome": "Peschiera del Garda"
                        },
                        {
                            "code": "023060",
                            "cap": "37064",
                            "nome": "Povegliano Veronese"
                        },
                        {
                            "code": "023061",
                            "cap": "37040",
                            "nome": "Pressana"
                        },
                        {
                            "code": "023062",
                            "cap": "37010",
                            "nome": "Rivoli Veronese"
                        },
                        {
                            "code": "023063",
                            "cap": "37030",
                            "nome": "Ronc\u00e0"
                        },
                        {
                            "code": "023064",
                            "cap": "37055",
                            "nome": "Ronco all'Adige"
                        },
                        {
                            "code": "023065",
                            "cap": "37050",
                            "nome": "Roverchiara"
                        },
                        {
                            "code": "023067",
                            "cap": "37028",
                            "nome": "Rover\u00e8 Veronese"
                        },
                        {
                            "code": "023066",
                            "cap": "37040",
                            "nome": "Roveredo di Gu\u00e0"
                        },
                        {
                            "code": "023068",
                            "cap": "37056",
                            "nome": "Salizzole"
                        },
                        {
                            "code": "023069",
                            "cap": "37047",
                            "nome": "San Bonifacio"
                        },
                        {
                            "code": "023070",
                            "cap": "37035",
                            "nome": "San Giovanni Ilarione"
                        },
                        {
                            "code": "023071",
                            "cap": "37057",
                            "nome": "San Giovanni Lupatoto"
                        },
                        {
                            "code": "023073",
                            "cap": "37036",
                            "nome": "San Martino Buon Albergo"
                        },
                        {
                            "code": "023074",
                            "cap": "37030",
                            "nome": "San Mauro di Saline"
                        },
                        {
                            "code": "023075",
                            "cap": "37050",
                            "nome": "San Pietro di Morubio"
                        },
                        {
                            "code": "023076",
                            "cap": "37029",
                            "nome": "San Pietro in Cariano"
                        },
                        {
                            "code": "023079",
                            "cap": "37010",
                            "nome": "San Zeno di Montagna"
                        },
                        {
                            "code": "023072",
                            "cap": "37058",
                            "nome": "Sanguinetto"
                        },
                        {
                            "code": "023077",
                            "cap": "37015",
                            "nome": "Sant'Ambrogio di Valpolicella"
                        },
                        {
                            "code": "023078",
                            "cap": "37020",
                            "nome": "Sant'Anna d'Alfaedo"
                        },
                        {
                            "code": "023080",
                            "cap": "37030",
                            "nome": "Selva di Progno"
                        },
                        {
                            "code": "023081",
                            "cap": "37038",
                            "nome": "Soave"
                        },
                        {
                            "code": "023082",
                            "cap": "37066",
                            "nome": "Sommacampagna"
                        },
                        {
                            "code": "023083",
                            "cap": "37060",
                            "nome": "Sona"
                        },
                        {
                            "code": "023084",
                            "cap": "37060",
                            "nome": "Sorg\u00e0"
                        },
                        {
                            "code": "023085",
                            "cap": "37040",
                            "nome": "Terrazzo"
                        },
                        {
                            "code": "023086",
                            "cap": "37010",
                            "nome": "Torri del Benaco"
                        },
                        {
                            "code": "023087",
                            "cap": "37039",
                            "nome": "Tregnago"
                        },
                        {
                            "code": "023088",
                            "cap": "37060",
                            "nome": "Trevenzuolo"
                        },
                        {
                            "code": "023089",
                            "cap": "37067",
                            "nome": "Valeggio sul Mincio"
                        },
                        {
                            "code": "023090",
                            "cap": "37030",
                            "nome": "Velo Veronese"
                        },
                        {
                            "code": "023091",
                            "cap": "371xx",
                            "nome": "Verona"
                        },
                        {
                            "code": "023092",
                            "cap": "37040",
                            "nome": "Veronella"
                        },
                        {
                            "code": "023093",
                            "cap": "37030",
                            "nome": "Vestenanova"
                        },
                        {
                            "code": "023094",
                            "cap": "37068",
                            "nome": "Vigasio"
                        },
                        {
                            "code": "023095",
                            "cap": "37049",
                            "nome": "Villa Bartolomea"
                        },
                        {
                            "code": "023096",
                            "cap": "37069",
                            "nome": "Villafranca di Verona"
                        },
                        {
                            "code": "023097",
                            "cap": "37059",
                            "nome": "Zevio"
                        },
                        {
                            "code": "023098",
                            "cap": "37040",
                            "nome": "Zimella"
                        }
                    ],
                    "nome": "Verona"
                },
                {
                    "code": "VI",
                    "comuni": [
                        {
                            "code": "024001",
                            "cap": "36020",
                            "nome": "Agugliaro"
                        },
                        {
                            "code": "024002",
                            "cap": "36020",
                            "nome": "Albettone"
                        },
                        {
                            "code": "024003",
                            "cap": "36045",
                            "nome": "Alonte"
                        },
                        {
                            "code": "024004",
                            "cap": "36077",
                            "nome": "Altavilla Vicentina"
                        },
                        {
                            "code": "024005",
                            "cap": "36070",
                            "nome": "Altissimo"
                        },
                        {
                            "code": "024006",
                            "cap": "36057",
                            "nome": "Arcugnano"
                        },
                        {
                            "code": "024007",
                            "cap": "36011",
                            "nome": "Arsiero"
                        },
                        {
                            "code": "024008",
                            "cap": "36071",
                            "nome": "Arzignano"
                        },
                        {
                            "code": "024009",
                            "cap": "36012",
                            "nome": "Asiago"
                        },
                        {
                            "code": "024010",
                            "cap": "36020",
                            "nome": "Asigliano Veneto"
                        },
                        {
                            "code": "024011",
                            "cap": "36021",
                            "nome": "Barbarano Vicentino"
                        },
                        {
                            "code": "024012",
                            "cap": "36061",
                            "nome": "Bassano del Grappa"
                        },
                        {
                            "code": "024013",
                            "cap": "36050",
                            "nome": "Bolzano Vicentino"
                        },
                        {
                            "code": "024014",
                            "cap": "36042",
                            "nome": "Breganze"
                        },
                        {
                            "code": "024015",
                            "cap": "36040",
                            "nome": "Brendola"
                        },
                        {
                            "code": "024016",
                            "cap": "36050",
                            "nome": "Bressanvido"
                        },
                        {
                            "code": "024017",
                            "cap": "36070",
                            "nome": "Brogliano"
                        },
                        {
                            "code": "024018",
                            "cap": "36030",
                            "nome": "Caldogno"
                        },
                        {
                            "code": "024019",
                            "cap": "36030",
                            "nome": "Caltrano"
                        },
                        {
                            "code": "024020",
                            "cap": "36030",
                            "nome": "Calvene"
                        },
                        {
                            "code": "024021",
                            "cap": "36043",
                            "nome": "Camisano Vicentino"
                        },
                        {
                            "code": "024022",
                            "cap": "36020",
                            "nome": "Campiglia dei Berici"
                        },
                        {
                            "code": "024023",
                            "cap": "36020",
                            "nome": "Campolongo sul Brenta"
                        },
                        {
                            "code": "024024",
                            "cap": "36010",
                            "nome": "Carr\u00e8"
                        },
                        {
                            "code": "024025",
                            "cap": "36050",
                            "nome": "Cartigliano"
                        },
                        {
                            "code": "024026",
                            "cap": "36022",
                            "nome": "Cassola"
                        },
                        {
                            "code": "024027",
                            "cap": "36020",
                            "nome": "Castegnero"
                        },
                        {
                            "code": "024028",
                            "cap": "36070",
                            "nome": "Castelgomberto"
                        },
                        {
                            "code": "024029",
                            "cap": "36072",
                            "nome": "Chiampo"
                        },
                        {
                            "code": "024030",
                            "cap": "36010",
                            "nome": "Chiuppano"
                        },
                        {
                            "code": "024031",
                            "cap": "36020",
                            "nome": "Cismon del Grappa"
                        },
                        {
                            "code": "024032",
                            "cap": "36010",
                            "nome": "Cogollo del Cengio"
                        },
                        {
                            "code": "024033",
                            "cap": "36062",
                            "nome": "Conco"
                        },
                        {
                            "code": "024034",
                            "cap": "36073",
                            "nome": "Cornedo Vicentino"
                        },
                        {
                            "code": "024035",
                            "cap": "36030",
                            "nome": "Costabissara"
                        },
                        {
                            "code": "024036",
                            "cap": "36051",
                            "nome": "Creazzo"
                        },
                        {
                            "code": "024037",
                            "cap": "36070",
                            "nome": "Crespadoro"
                        },
                        {
                            "code": "024038",
                            "cap": "36031",
                            "nome": "Dueville"
                        },
                        {
                            "code": "024039",
                            "cap": "36052",
                            "nome": "Enego"
                        },
                        {
                            "code": "024040",
                            "cap": "36030",
                            "nome": "Fara Vicentino"
                        },
                        {
                            "code": "024041",
                            "cap": "36010",
                            "nome": "Foza"
                        },
                        {
                            "code": "024042",
                            "cap": "36032",
                            "nome": "Gallio"
                        },
                        {
                            "code": "024043",
                            "cap": "36053",
                            "nome": "Gambellara"
                        },
                        {
                            "code": "024044",
                            "cap": "36050",
                            "nome": "Gambugliano"
                        },
                        {
                            "code": "024045",
                            "cap": "36040",
                            "nome": "Grancona"
                        },
                        {
                            "code": "024046",
                            "cap": "36040",
                            "nome": "Grisignano di Zocco"
                        },
                        {
                            "code": "024047",
                            "cap": "36040",
                            "nome": "Grumolo delle Abbadesse"
                        },
                        {
                            "code": "024048",
                            "cap": "36033",
                            "nome": "Isola Vicentina"
                        },
                        {
                            "code": "024049",
                            "cap": "36010",
                            "nome": "Laghi"
                        },
                        {
                            "code": "024050",
                            "cap": "36040",
                            "nome": "Lastebasse"
                        },
                        {
                            "code": "024051",
                            "cap": "36023",
                            "nome": "Longare"
                        },
                        {
                            "code": "024052",
                            "cap": "36045",
                            "nome": "Lonigo"
                        },
                        {
                            "code": "024053",
                            "cap": "36030",
                            "nome": "Lugo di Vicenza"
                        },
                        {
                            "code": "024054",
                            "cap": "36046",
                            "nome": "Lusiana"
                        },
                        {
                            "code": "024055",
                            "cap": "36034",
                            "nome": "Malo"
                        },
                        {
                            "code": "024056",
                            "cap": "36035",
                            "nome": "Marano Vicentino"
                        },
                        {
                            "code": "024057",
                            "cap": "36063",
                            "nome": "Marostica"
                        },
                        {
                            "code": "024058",
                            "cap": "36064",
                            "nome": "Mason Vicentino"
                        },
                        {
                            "code": "024059",
                            "cap": "36060",
                            "nome": "Molvena"
                        },
                        {
                            "code": "024063",
                            "cap": "36030",
                            "nome": "Monte di Malo"
                        },
                        {
                            "code": "024060",
                            "cap": "36054",
                            "nome": "Montebello Vicentino"
                        },
                        {
                            "code": "024061",
                            "cap": "36075",
                            "nome": "Montecchio Maggiore"
                        },
                        {
                            "code": "024062",
                            "cap": "36030",
                            "nome": "Montecchio Precalcino"
                        },
                        {
                            "code": "024064",
                            "cap": "36047",
                            "nome": "Montegalda"
                        },
                        {
                            "code": "024065",
                            "cap": "36047",
                            "nome": "Montegaldella"
                        },
                        {
                            "code": "024066",
                            "cap": "36050",
                            "nome": "Monteviale"
                        },
                        {
                            "code": "024067",
                            "cap": "36010",
                            "nome": "Monticello Conte Otto"
                        },
                        {
                            "code": "024068",
                            "cap": "36050",
                            "nome": "Montorso Vicentino"
                        },
                        {
                            "code": "024069",
                            "cap": "36024",
                            "nome": "Mossano"
                        },
                        {
                            "code": "024070",
                            "cap": "36065",
                            "nome": "Mussolente"
                        },
                        {
                            "code": "024071",
                            "cap": "36024",
                            "nome": "Nanto"
                        },
                        {
                            "code": "024072",
                            "cap": "36070",
                            "nome": "Nogarole Vicentino"
                        },
                        {
                            "code": "024073",
                            "cap": "36055",
                            "nome": "Nove"
                        },
                        {
                            "code": "024074",
                            "cap": "36025",
                            "nome": "Noventa Vicentina"
                        },
                        {
                            "code": "024075",
                            "cap": "36040",
                            "nome": "Orgiano"
                        },
                        {
                            "code": "024076",
                            "cap": "36040",
                            "nome": "Pedemonte"
                        },
                        {
                            "code": "024077",
                            "cap": "36060",
                            "nome": "Pianezze"
                        },
                        {
                            "code": "024078",
                            "cap": "36013",
                            "nome": "Piovene Rocchette"
                        },
                        {
                            "code": "024079",
                            "cap": "36026",
                            "nome": "Pojana Maggiore"
                        },
                        {
                            "code": "024080",
                            "cap": "36010",
                            "nome": "Posina"
                        },
                        {
                            "code": "024081",
                            "cap": "36020",
                            "nome": "Pove del Grappa"
                        },
                        {
                            "code": "024082",
                            "cap": "36050",
                            "nome": "Pozzoleone"
                        },
                        {
                            "code": "024083",
                            "cap": "36050",
                            "nome": "Quinto Vicentino"
                        },
                        {
                            "code": "024084",
                            "cap": "36076",
                            "nome": "Recoaro Terme"
                        },
                        {
                            "code": "024085",
                            "cap": "36010",
                            "nome": "Roana"
                        },
                        {
                            "code": "024086",
                            "cap": "36060",
                            "nome": "Romano d'Ezzelino"
                        },
                        {
                            "code": "024087",
                            "cap": "36027",
                            "nome": "Ros\u00e0"
                        },
                        {
                            "code": "024088",
                            "cap": "36028",
                            "nome": "Rossano Veneto"
                        },
                        {
                            "code": "024089",
                            "cap": "36010",
                            "nome": "Rotzo"
                        },
                        {
                            "code": "024090",
                            "cap": "36040",
                            "nome": "Salcedo"
                        },
                        {
                            "code": "024092",
                            "cap": "36040",
                            "nome": "San Germano dei Berici"
                        },
                        {
                            "code": "024093",
                            "cap": "36020",
                            "nome": "San Nazario"
                        },
                        {
                            "code": "024094",
                            "cap": "36070",
                            "nome": "San Pietro Mussolino"
                        },
                        {
                            "code": "024096",
                            "cap": "36030",
                            "nome": "San Vito di Leguzzano"
                        },
                        {
                            "code": "024091",
                            "cap": "36066",
                            "nome": "Sandrigo"
                        },
                        {
                            "code": "024095",
                            "cap": "36014",
                            "nome": "Santorso"
                        },
                        {
                            "code": "024097",
                            "cap": "36030",
                            "nome": "Sarcedo"
                        },
                        {
                            "code": "024098",
                            "cap": "36040",
                            "nome": "Sarego"
                        },
                        {
                            "code": "024099",
                            "cap": "36060",
                            "nome": "Schiavon"
                        },
                        {
                            "code": "024100",
                            "cap": "36015",
                            "nome": "Schio"
                        },
                        {
                            "code": "024101",
                            "cap": "36020",
                            "nome": "Solagna"
                        },
                        {
                            "code": "024102",
                            "cap": "36040",
                            "nome": "Sossano"
                        },
                        {
                            "code": "024103",
                            "cap": "36050",
                            "nome": "Sovizzo"
                        },
                        {
                            "code": "024104",
                            "cap": "36056",
                            "nome": "Tezze sul Brenta"
                        },
                        {
                            "code": "024105",
                            "cap": "36016",
                            "nome": "Thiene"
                        },
                        {
                            "code": "024106",
                            "cap": "36040",
                            "nome": "Tonezza del Cimone"
                        },
                        {
                            "code": "024107",
                            "cap": "36036",
                            "nome": "Torrebelvicino"
                        },
                        {
                            "code": "024108",
                            "cap": "36040",
                            "nome": "Torri di Quartesolo"
                        },
                        {
                            "code": "024110",
                            "cap": "36070",
                            "nome": "Trissino"
                        },
                        {
                            "code": "024111",
                            "cap": "36078",
                            "nome": "Valdagno"
                        },
                        {
                            "code": "024112",
                            "cap": "36040",
                            "nome": "Valdastico"
                        },
                        {
                            "code": "024113",
                            "cap": "36030",
                            "nome": "Valli del Pasubio"
                        },
                        {
                            "code": "024114",
                            "cap": "36020",
                            "nome": "Valstagna"
                        },
                        {
                            "code": "024115",
                            "cap": "36010",
                            "nome": "Velo d'Astico"
                        },
                        {
                            "code": "024116",
                            "cap": "36100",
                            "nome": "Vicenza"
                        },
                        {
                            "code": "024117",
                            "cap": "36021",
                            "nome": "Villaga"
                        },
                        {
                            "code": "024118",
                            "cap": "36030",
                            "nome": "Villaverla"
                        },
                        {
                            "code": "024119",
                            "cap": "36010",
                            "nome": "Zan\u00e8"
                        },
                        {
                            "code": "024120",
                            "cap": "36050",
                            "nome": "Zermeghedo"
                        },
                        {
                            "code": "024121",
                            "cap": "36020",
                            "nome": "Zovencedo"
                        },
                        {
                            "code": "024122",
                            "cap": "36030",
                            "nome": "Zugliano"
                        }
                    ],
                    "nome": "Vicenza"
                }
            ],
            "nome": "Veneto"
        }
    ]
}