import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content } from "bloomer/lib/elements/Content";
import React, { Component } from "react";
import MyTable from "../../components/admin/MyTable";
import Loader from "../../components/Loader";
import StorageService from "../../services/StorageService";
import firebase from "firebase/compat/app";

export default class DContactDocuments extends Component {
  state = { pdfFilename: null, loading: false, fileList: [] };

  constructor(props) {
    super(props);
    this.renderPdf = this.renderPdf.bind(this);
    this.uploadPDF = this.uploadPDF.bind(this);
  }

  componentDidMount() {
    const fileList = this.props.contact.fileList || [];

    fileList.sort((a, b) => {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      return 0;
    });
    this.setState({ fileList });
  }

  async uploadPDF(file) {
    const { fileList } = this.state;
    if (!!file) {
      try {
        await StorageService.postPDF(
          file,
          this.state.pdfFilename,
          this.props.uid,
          "utente"
        );
        alert("PDF caricato correttamente");

        const f = {
          filename: this.state.pdfFilename,
          date: firebase.firestore.Timestamp.fromDate(new Date())
        };
        fileList.push(f);
        this.setState({ fileList }, () => this.updateTable(fileList));
      } catch (e) {
        console.error("Error while uploading the pdf: ", e);
        alert(
          "Si è verificato un errore durante il caricamento del pdf: ${e.message}"
        );
      }
    }
    this.setState({ pdfFilename: null, loading: false });
  }

  updateTable(fileList) {
    firebase
      .firestore()
      .collection("contacts")
      .doc(this.props.uid)
      .update({ fileList });
  }

  onDelete(u, idx) {
    console.log("AA onDelete");

    this.props.modals.addModal(
      "Confermi eliminazione?",
      <div />,
      [
        {
          label: "ESCI",
          className: "button is-primary is-fullwidth",
          result: "chiudi"
        },
        {
          label: "ELIMINA",
          className: "button is-transparent is-fullwidth",
          result: "save"
        }
      ],
      false,
      result => {
        if (result === "save") {
          this.confirmDelete(idx);
        }
      }
    );
  }

  async confirmDelete(idx) {
    const { fileList } = this.state;
    try {
      await StorageService.deletePDF(
        fileList[idx].filename,
        this.props.uid,
        "utente"
      );
      //alert("PDF cancellato correttamente");

      fileList.splice(idx, 1);
      this.setState({ fileList }, () => this.updateTable(fileList));
    } catch (e) {
      console.error("Error while deleting the pdf: ", e);
      alert(
        "Si è verificato un errore durante il cancellamento del pdf: ${e.message}"
      );
    }
  }

  async onPDFClicked(p) {
    console.log("AA pdf", p);
    const url = await StorageService.getPDF(
      this.props.uid,
      p.filename,
      "utente"
    );
    console.log("AA url", url);
    window.open(url, "_blank");
  }

  renderPdf() {
    const { fileList } = this.state;
    const columns = [
      {
        Header: "File",
        accessor: "filename"
      },
      {
        Header: "Data",
        accessor: "date",
        type: "dateTimestamp"
      }
    ];

    return (
      <div>
        <MyTable
          data={fileList}
          columns={columns}
          onPDFClicked={u => this.onPDFClicked(u)}
          onDelete={(u, idx) => this.onDelete(u, idx)}
          {...this.props}
        />

        <Content>
          {this.state.pdfFilename ? (
            <div>
              Hai selezionato: "{this.state.pdfFilename}"
              <span className="ml-6p">
                <FontAwesomeIcon
                  onClick={e => this.removePDF()}
                  icon={["fas", ["times-circle"]]}
                />
              </span>
            </div>
          ) : (
            <label
              className="button filewrapper is-fullwidth"
              htmlFor="contractUpload"
            >
              <input
                type="file"
                id="contractUpload"
                name="contractUpload"
                accept=".pdf"
                onChange={e => this.fileChange(e)}
              />
              CARICA PDF
            </label>
          )}
        </Content>
      </div>
    );
  }

  fileChange(event) {
    let c = document.getElementById("contractUpload");
    const file = c.files ? c.files[0] : null;
    const uploadDate = new Date();
    const pdfFilename = `${file.name}-${uploadDate.toISOString()}`;
    console.log("AA fileChange", pdfFilename);
    this.setState({ pdfFilename, loading: true }, () => this.uploadPDF(file));
  }

  onModify(p, id, e, searchText) {
    window.open("documentDetails/" + p.uid + "/" + p.type, "_blank");
  }

  render() {
    const columns = [
      {
        Header: "Numero",
        accessor: "codeNumber"
      },
      {
        Header: "Data",
        accessor: "dateFormatted"
      },
      {
        Header: "Tipo",
        accessor: "type"
      },
      {
        Header: "Causale",
        accessor: "causale"
      },
      {
        Header: "Creato da",
        accessor: "creatorName"
      }
    ];
    return (
      <div className="admin-panels">
        <h4 className="title has-text-black mb-0">MODULISTICA</h4>
        {this.state.loading ? <Loader /> : this.renderPdf()}
        <h4 className="title has-text-black mt-40 mb-0">DOCUMENTI</h4>
        {
          <MyTable
            data={this.props.contactDocs}
            columns={columns}
            hideDelete
            onModify={(u, id, e, searchText) =>
              this.onModify(u, id, e, searchText)
            }
            {...this.props}
          />
        }
      </div>
    );
  }
}
