var moment = require("moment");

export function calcProdottiAggregate(filteredData, productsTable) {
  console.log("AA prodottiaggregate filteredData", filteredData);
  let prodottiAggregate = [];
  prodottiAggregate = Object.values(
    filteredData.reduce(
      (
        result,
        {
          cod,
          product,
          item_price,
          scorporo_iva,
          iva,
          quantity_shipped,
          brand,
          type,
          combo,
          piattaforma,
          comboSKU
        }
      ) => {
        //controlla se è un codice figlio

        if (combo) {
          comboSKU.forEach(c => {
            cod = c.sku || c;
            const productDBData = productsTable[cod];
            // console.log("AA combosku", productsTable, cod, productDBData);

            c.brand = productDBData?.brand || brand;

            // console.log(
            //   "AA combosku",
            //   productsTable,
            //   cod,
            //   comboSKU,
            //   c,
            //   c.sku,
            //   brand,
            //   c.brand
            // );

            const price = item_price / comboSKU.length;
            product = c?.name || product;

            const qty = getProductPezzi({
              quantity_shipped: quantity_shipped * (c?.quantity || 1),

              type
            });
            if (!result[cod])
              result[cod] = {
                cod,
                product,
                brand: c.brand,
                fatturato: 0,
                fatturato_scorporo: 0,
                piattaforma,
                quantity_shipped: 0,
                combo_qty: 0,
                product_qty: 0,
                num_ordini: 0,
                type
              };

            // Append to group
            result[cod] = {
              cod,
              product,
              brand: c.brand,
              num_ordini: result[cod].num_ordini + 1,
              fatturato: result[cod].fatturato + quantity_shipped * price,
              fatturato_scorporo:
                result[cod].fatturato_scorporo +
                quantity_shipped * (price / ((100 + iva) / 100)),
              piattaforma,
              quantity_shipped: result[cod].quantity_shipped + qty,
              combo_qty: result[cod].combo_qty + qty,
              product_qty: result[cod].product_qty,
              type
            };
          });
          // console.log("vv combo", result[cod]);
        } else {
          const qty = getProductPezzi({
            quantity_shipped,
            combo,
            comboSKU,
            type
          });
          // Create new group
          if (!result[cod])
            result[cod] = {
              cod,
              product,
              brand,
              fatturato: 0,
              fatturato_scorporo: 0,
              piattaforma,
              quantity_shipped: 0,
              product_qty: 0,
              combo_qty: 0,
              num_ordini: 0,
              type
            };

          // Append to group
          result[cod] = {
            cod,
            product,
            brand,
            num_ordini: result[cod].num_ordini + 1,
            fatturato: result[cod].fatturato + quantity_shipped * item_price,
            fatturato_scorporo:
              result[cod].fatturato_scorporo +
              quantity_shipped * (item_price / ((100 + iva) / 100)),
            piattaforma,
            quantity_shipped: result[cod].quantity_shipped + qty,
            product_qty: result[cod].product_qty + qty,
            combo_qty: result[cod].combo_qty,
            type
          };
        }
        return result;
      },
      {}
    )
  );

  prodottiAggregate.sort((a, b) => {
    if (a.fatturato > b.fatturato) return -1;
    if (a.fatturato < b.fatturato) return 1;
    return 0;
  });
  return prodottiAggregate;
}

export function calcClientiAggregate(filteredData, clientiTotaliAggregate) {
  let clientiAggregate = [];
  clientiAggregate = Object.values(
    filteredData.reduce(
      (
        result,
        {
          codCliente,
          email,
          buyer_name,
          quantity_shipped,
          item_price,
          cap,
          country,
          country_code,
          piattaforma,
          brand,
          uid,
          order_id,
          purchase_date,
          ship_address_1,
          ship_city,
          ship_address_formatted,
          finalPrice,
          phone
        }
      ) => {
        // Create new group
        buyer_name = buyer_name.toLowerCase();
        if (!result[codCliente])
          result[codCliente] = {
            buyer_name,
            email,
            codCliente,
            quantity_shipped: 0,
            fatturato: 0,
            num_ordini: 0,
            cap,
            country,
            ship_city,
            ship_address_1,
            ship_address_formatted,
            phone,
            country_code
          };

        const piattaforme = result[codCliente].piattaforme || [];
        const brands = result[codCliente].brands || [];
        const ordini = result[codCliente].ordini || [];
        piattaforme.push(piattaforma);
        brands.push(brand);
        ordini.push({
          ordine: uid,
          order_id: order_id,
          date: purchase_date
        });
        const num_ordini = [...new Set(ordini.map(o => o.order_id))].length;

        const val = clientiTotaliAggregate[codCliente];
        const ordini_storico = !!val ? val.num_ordini : 0;

        const price =
          !!item_price && !!quantity_shipped
            ? quantity_shipped * item_price
            : finalPrice;
        // Append to group
        result[codCliente] = {
          buyer_name,
          email,
          codCliente,
          quantity_shipped:
            result[codCliente].quantity_shipped + quantity_shipped,
          fatturato: result[codCliente].fatturato + price,
          num_ordini: num_ordini,
          fatturato_medio: (
            (result[codCliente].fatturato + price) /
            num_ordini
          ).toFixed(2),
          piattaforme: [...new Set(piattaforme)],
          brands: [...new Set(brands)],
          ordini,
          num_piattaforme: [...new Set(piattaforme)].length,
          num_brands: [...new Set(piattaforme)].length,
          cap,
          country,
          ship_city,
          ship_address_1,
          ship_address_formatted,
          phone,
          country_code,
          ordini_storico: ordini_storico,
          nuovo_cliente: ordini_storico > 1 ? "Recurrent" : "New"
        };
        return result;
      },
      {}
    )
  );

  clientiAggregate.sort((a, b) => {
    if (a.buyer_name > b.buyer_name) return 1;
    if (a.buyer_name < b.buyer_name) return -1;
    return 0;
  });
  return clientiAggregate;
}

export const groupBy = (arr, key) => {
  const initialValue = {};
  return arr.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};

export const groupByArray = (arr, key) => {
  const val = groupBy(arr, key);
  const returnVal = [];
  Object.keys(val).map(k => {
    const group = val[k];
    returnVal.push({
      group: group
    });
  });
  return returnVal;
};

export const ordersGroupBy = (arr, key) => {
  const val = groupBy(arr, key);
  const returnVal = [];
  Object.keys(val).map(k => {
    const order = val[k];
    returnVal.push({
      id: order[0].order_id,
      piattaforma: order[0].piattaforma,
      dateFormatted: order[0].dateFormatted,
      fatturato: order.reduce((a, b) => +a + +b.fatturato, 0),
      iva: order.reduce((a, b) => +a + +b.iva, 0),
      prodotti: order.length,
      qtyProdotti: order.reduce((a, b) => +a + +b.qtyProdotti, 0),
      subOrder: order
    });
  });
  return returnVal;
};

export function flattenClienti(clienti) {
  const flatten = [];
  clienti?.map(c => {
    c.ordiniReali.map(o => {
      flatten.push({ ...c, ...o });
    });
  });
  return flatten;
}

export function arraySum(array, key) {
  return array.reduce((a, b) => a + (b[key] || 0), 0);
}

export function flattenClientiGrouped(clienti) {
  const flatten = [];
  clienti?.map(c => {
    flatten.push(c.group[0]);
  });
  return flatten;
}

export function getProductPezzi(d) {
  if (d.type == "REFUND" || d.coupon || d.omaggio) return 0;

  let pezzi = 0;
  if (d.combo) {
    d?.comboSKU?.forEach(c => {
      pezzi += (c?.quantity || 1) * d.quantity_shipped;
    });
  } else {
    pezzi = d.quantity_shipped;
  }

  return pezzi;
}

export function filterClientiNuovi(cc, m, platform) {
  const isWoo = platform === "woo";
  const filters = [];
  // console.log("ccc filterClientiNuovi", cc, m, platform);
  cc.forEach(c => {
    const ordiniMesiPrecedenti = c.ordiniReali.filter(o => {
      // if (c?.codCliente === "albzam39100")
      //   console.log(
      //     "ccc ordiniMesiPrecedenti",
      //     c.codCliente,
      //     o,
      //     m,
      //     m.format("MM-YYYY"),
      //     moment(o.dateFormatted, "DD-MM-YYYY").diff(m, "months"),
      //     moment(o.dateFormatted, "DD-MM-YYYY").isBefore(m.startOf("month"))
      //   );

      return (
        (isWoo ? o.piattaforma === "WOO" : o.piattaforma != "WOO") &&
        moment(o.dateFormatted, "DD-MM-YYYY").isBefore(m.startOf("month"))
      );
    });
    // if (c?.codCliente === "albzam39100")
    //   console.log(
    //     "ccc ordiniMesiPrecedenti",
    //     c.codCliente,
    //     ordiniMesiPrecedenti
    //   );

    if (ordiniMesiPrecedenti.length === 0) filters.push(c);
  });
  // console.log("ccc ordiniMesiPrecedenti filters", filters);

  return filters;
}

export function getAmazonCountry(row) {
  if (!!row.sales_channel) {
    row.amazonCountry = row.sales_channel.substring(
      row.sales_channel.length - 2
    );
  }
}
