import React from 'react';

export default class MultiRender extends React.Component {
    render() {
    	let className = (this.props.className) ? this.props.className : '';
        return (
            <div className={className}>
                {this.props.children}
            </div>
        )
    }
}