import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import firebase from "firebase/compat/app";

import Loader from "../components/Loader";
import { Columns, Column } from "bloomer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "bloomer/lib/layout/Container";
import { Field } from "bloomer/lib/elements/Form/Field/Field";
import { Input } from "bloomer/lib/elements/Form/Input";
import { Label } from "bloomer/lib/elements/Form/Label";
import Utils from "../Utils";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import Geosuggest from "react-geosuggest";
import { Link } from "react-router-dom";
import DocumentType from "../enums/DocumentType";
import { Tabs } from "bloomer/lib/components/Tabs/Tabs";
import { TabList } from "bloomer/lib/components/Tabs/TabList";
import { Tab } from "bloomer/lib/components/Tabs/Tab";
import { TabLink } from "bloomer/lib/components/Tabs/TabLink";
import MyTable from "./admin/MyTable";
import DContactLog from "../views/desktop/DContactLog";
import DContactCoupons from "../views/desktop/DContactCoupons";
import DContactCommissioni from "../views/desktop/DContactCommissioni";
import DContactDocuments from "../views/desktop/DContactDocuments";
import DContactPipeline from "../views/desktop/DContactPipeline";
import DContactReport from "../views/desktop/DContactReport";
import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  ModalFooter,
  Box,
  Select
} from "@chakra-ui/react";
const google = window.google;

class DGenericProduct extends Component {
  state = {
    product: null,
    modify: false,
    nomiDropdownActive: [],
    multipleValues: [],
    tab: "anagrafica",
    contactReports: [],
    contactDocs: [],
    showFilterList: []
  };

  async componentDidMount() {
    const { uid } = this.props;
    const { table } = this.props;
    const { adding } = this.props;

    if (adding) {
      const creatorName = firebase.auth().currentUser.displayName;
      let product = {};

      if (!!this.props.contacts) {
        product = {
          name: "Nuovo cliente o fornitore",
          type: "Cliente",
          creatorName
        };
      } else {
        product = {
          name: "Nuovo prodotto",
          quantity: 0,
          creatorName
        };
      }
      this.setState({
        product,
        modify: true,
        filteredProducts: this.props.admin.elements
      });
    } else {
      const productSnap = await firebase
        .firestore()
        .collection(table)
        .doc(uid)
        .get();

      if (productSnap.exists) {
        this.getComposition(productSnap.data());
        console.log("zzz   found productSnap", productSnap);
        this.setState({
          product: productSnap.data(),
          filteredProducts: this.props.admin.elements
        });
      } else {
        console.log("zzz not found");
      }

      //cerca documenti del contatto
      if (this.props.contacts) {
        const docsSnap = await firebase
          .firestore()
          .collection("documents")
          .where("contact.uid", "==", this.props.uid)
          .get();

        const contactDocs = [];
        docsSnap.forEach(async d => {
          const doc = d.data();
          doc.codeNumber = Utils.getCodeNumber(doc);
          doc.dateFormatted = Utils.formatDate(doc.date);
          if (!!doc.products) {
            let numeroPezzi = 0;
            let prodottiConcatenati = "";
            let index = 0;
            doc.products.forEach(async p => {
              numeroPezzi += p.quantity;
              if (index != 0) prodottiConcatenati += "; ";
              prodottiConcatenati += p.nomeDisplay || p.nome;
              index++;

              if (!p.price_buy) {
                const productSnap = await firebase
                  .firestore()
                  .collection("products")
                  .doc(p.uid)
                  .get();

                p.price_buy = productSnap.data().price_buy || 0;

                console.log("BB price buy", p.price_buy, productSnap.data());
              }
            });

            doc.numeroPezzi = numeroPezzi;
            doc.prodottiConcatenati = prodottiConcatenati;
          }
          contactDocs.push(doc);
        });

        contactDocs.sort((a, b) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        });

        console.log("AA doc", contactDocs);

        //Affiliate get reports KPI
        if (Utils.getTipoCliente(productSnap.data(), "Affiliato")) {
          await this.loadFromReportKPI(productSnap.data());
        }

        this.setState({ contactDocs });
      }
    }
  }

  getBrand(doc) {
    console.log("AA sku", doc);

    if (!doc.sku) return;
    console.log("AA sku", doc.sku[0]);
    switch (doc.sku[0]) {
      case "T":
        doc.brand = "trex";
        break;
      case "M":
        doc.brand = "mylab";
        break;
      case "N":
        doc.brand = "nutracle";
        break;
      case "S":
        doc.brand = "supp4pets";
        break;
      default:
        break;
    }
  }

  async loadFromReportKPI(contact) {
    const { coupons } = contact;
    if (!coupons) return;

    const contactReports = [];
    coupons.forEach(async c => {
      const contactSnap = await firebase
        .firestore()
        .collection("reports_kpi")
        .where("coupon_code", "==", c.code.toLowerCase())
        .get();

      console.log("AA contactSnap", c.code.toLowerCase(), contactSnap);
      contactSnap.forEach(c => {
        const doc = c.data();
        this.getBrand(doc);

        contactReports.push(doc);
      });

      this.setState({ contactReports });
    });
  }

  async getComposition(p) {
    var { composition } = p;
    if (!composition) return;

    const promises = [];

    composition.forEach(async e => {
      promises.push(
        new Promise(async (r, rj) => {
          const elementSnap = await firebase
            .firestore()
            .collection("elements")
            .doc(e.uid)
            .get();

          if (elementSnap.exists) {
            const el = elementSnap.data();
            e.name = el.name;
          }
          r(e);
        })
      );
    });

    await Promise.all(promises);

    p.composition = composition;
    this.setState({ product: p });
  }

  doModify() {
    this.setState({ modify: !this.state.modify });
  }

  submit() {
    this.setState({ submitting: true });
  }

  renderSubmitModal() {
    return (
      <AlertDialog
        isOpen={!!this.state.submitting}
        onClose={() => this.setState({ submitting: false })}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confermi salvataggio?
            </AlertDialogHeader>

            <AlertDialogFooter>
              <Button onClick={() => this.setState({ submitting: false })}>
                Esci
              </Button>
              <Button
                colorScheme="red"
                onClick={() => this.confirmSave()}
                isLoading={this.state.saving}
                ml={3}
              >
                Salva
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }

  async confirmSave() {
    this.setState({ saving: true });

    let p = this.state.product;
    const { table } = this.props;

    if (!!this.props.onCheckData && !(await this.props.onCheckData(p))) return;

    if (this.props.adding) {
      p.creator = firebase.auth().currentUser.uid;
      p.creatorName = firebase.auth().currentUser.displayName;
      p.date = new Date();
      p.active = true;

      var test = Utils.checkValueUndefined(p);
      if (test) {
        var string = "valore nullo nel campo " + test;
        alert(string);
        return;
      }
      console.log("AA adding p", p, table);
      const elemRef = await firebase
        .firestore()
        .collection(table)
        .add(p)
        .then(pRef => (p.uid = pRef.id));
    } else {
      p.quantity = p.quantity || 0;

      var test = Utils.checkValueUndefined(p);
      if (test) {
        var string = "valore nullo nel campo " + test;
        alert(string);
        return;
      }
      const elemRef = await firebase
        .firestore()
        .collection(table)
        .doc(p.uid)
        .update(p);
    }

    if (!this.props.contacts) {
      //add correzione document
      const doc = {
        type: DocumentType.CORREZIONE,
        creatorName: firebase.auth().currentUser.displayName,
        date: new Date(),
        quantity: p.quantity,
        productSnapshot: p
      };
      const docRef = await firebase
        .firestore()
        .collection("documents")
        .add(doc)
        .then(docRef => (doc.uid = docRef.id));

      firebase
        .firestore()
        .collection(table)
        .doc(p.uid)
        .collection("documents")
        .add(doc);
    }

    this.setState({ saving: false });
    this.props.onSave();
  }

  handleChange(val, field, parentAccessor) {
    var data = {};

    if (field === parentAccessor) data[field] = val;
    else {
      var d = this.state.product[parentAccessor];
      if (!d) d = {};

      d[field] = val;
      data[parentAccessor] = d;
    }

    this.setState({
      ...this.state,
      product: {
        ...this.state.product,
        ...data
      }
    });
  }

  handleSearchChange(val, list, field, parentAccessor) {
    var data = {};

    const filterdList = list.filter(e => {
      return e
        .replaceAll(".", "")
        .toLowerCase()
        .includes(val.toLowerCase());
    });

    console.log("AA handleserach", val, filterdList);
    if (field === parentAccessor) data[field] = val;
    else {
      var d = this.state.product[parentAccessor];
      if (!d) d = {};

      d[field] = val;
      data[parentAccessor] = d;
    }

    const showFilterList = [];
    showFilterList[field] = true;

    this.setState({
      ...this.state,
      product: {
        ...this.state.product,
        ...data
      },
      filterdList,
      showFilterList
    });
  }

  addElementToList(listName) {
    let { product } = this.state;
    let list = [];
    if (!!product[listName]) {
      list = product[listName];
    }
    list.push("");
    product[listName] = list;

    this.setState({ product });
  }

  changeListElement(text, index, listName) {
    let { product } = this.state;
    product[listName][index] = text;

    this.setState({ product });
  }

  deleteListElement(val, listName) {
    let { product } = this.state;

    product[listName] = product[listName].filter(l => l != val);

    this.setState({ product });
  }

  renderField(field, parentAccessor) {
    switch (field.type) {
      case "list":
        return this.renderList(field);
      case "multiValues":
        return this.renderMultipleValues(field);
      case "multiCheckbox":
        return this.renderMultipleCheckbox(field);
      case "address":
        return this.renderAddress(field);
      case "addressMulti":
        return this.renderAddressMulti(field);
      case "Geosuggest":
        return this.renderGeosuggest(field, parentAccessor);
      case "line":
        return this.renderLine();
      case "search":
        return this.renderSearch(field, parentAccessor);
      default:
        return this.renderInputFiled(field, parentAccessor);
    }
  }

  renderLine() {
    return <div className="separator-line"></div>;
  }

  renderInputFiled(field, parentAccessor) {
    const { Header, accessor, type, blocked, required } = field;

    const p = this.state.product;
    const warning = type === "number" && p[accessor] < 0;
    const modify = this.state.modify && !blocked;
    const value =
      field.accessor === parentAccessor
        ? !!p[accessor]
          ? p[accessor]
          : ""
        : !!p[parentAccessor]
        ? p[parentAccessor][accessor]
        : "";
    return (
      <div>
        <Label>
          {Header}
          {required && " *"}
        </Label>
        <Field>
          <Input
            className={
              modify
                ? warning
                  ? "has-text-red"
                  : ""
                : warning
                ? "has-text-red blocked"
                : "blocked"
            }
            name={accessor}
            type={type}
            placeholder=""
            value={value}
            step="0.01"
            onChange={e => {
              this.handleChange(
                type === "number" ? parseFloat(e.target.value) : e.target.value,
                accessor,
                parentAccessor
              );
            }}
          />
        </Field>
      </div>
    );
  }

  renderSearch(field, parentAccessor) {
    const { Header, accessor, type, blocked, list, subaccessor } = field;
    const { filterdList } = this.state;
    console.log("AA render", field, parentAccessor);
    const p = this.state.product;
    const warning = type === "number" && p[accessor] < 0;
    const modify = this.state.modify && !blocked;
    const value =
      field.accessor === parentAccessor
        ? !!p[accessor]
          ? p[accessor]
          : ""
        : !!p[parentAccessor]
        ? p[parentAccessor][accessor]
        : "";
    return (
      <div>
        <Label>{Header}</Label>

        <Field>
          <Dropdown isActive={this.state.showFilterList[accessor]} l>
            <DropdownTrigger>
              <Input
                className={
                  modify
                    ? warning
                      ? "has-text-red"
                      : ""
                    : warning
                    ? "has-text-red blocked"
                    : "blocked"
                }
                name={accessor}
                type={type}
                placeholder=""
                value={value}
                autoComplete="off"
                step="0.01"
                onChange={e => {
                  this.handleSearchChange(
                    e.target.value,
                    list,
                    accessor,
                    parentAccessor
                  );
                }}
              />
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownContent className="elements-dropdown">
                {!!filterdList &&
                  filterdList.map((e, idx) => {
                    const value = e;

                    return (
                      <DropdownItem
                        key={idx}
                        className="ta-l"
                        onClick={() => this.setSearchedValue(e, accessor)}
                      >
                        {value}
                      </DropdownItem>
                    );
                  })}
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </Field>
      </div>
    );
  }

  renderList(field) {
    const { Header, accessor } = field;

    const { modify } = this.state;
    const p = this.state.product;
    const canModify = this.props.canBeModified;
    return (
      <div>
        <Label>{Header}</Label>
        <Columns>
          <Column>
            <Dropdown isActive={this.state.nomiDropdownActive[p.uid]}>
              <DropdownTrigger>
                <Button
                  className={
                    modify ? "dropdown-warehouse modify" : "dropdown-warehouse"
                  }
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={() => {
                    const nomiDropdownActive = [];
                    nomiDropdownActive[p.uid] = !this.state.nomiDropdownActive[
                      p.uid
                    ];

                    this.setState({
                      nomiDropdownActive
                    });
                  }}
                >
                  {!!p[accessor] && p[accessor][0]}
                  <span className="dropdown-icn">
                    <FontAwesomeIcon
                      className="icn"
                      icon={["fa", "chevron-down"]}
                    />
                  </span>
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownContent className="elements-dropdown">
                  {!!p[accessor] &&
                    p[accessor].map(e => {
                      return <DropdownItem className="ta-l">{e}</DropdownItem>;
                    })}
                </DropdownContent>
              </DropdownMenu>
            </Dropdown>
          </Column>
          <Column>
            {modify && canModify && (
              <Button
                colorScheme="green"
                onClick={() => this.setState({ modalActive: true })}
              >
                MODIFICA
              </Button>
            )}
          </Column>
        </Columns>
        <Modal
          className=" has-text-centered is-small"
          isOpen={this.state.modalActive}
        >
          <ModalBackground
            onClick={() => this.setState({ modalActive: true })}
          />
          <ModalCard>
            <ModalCardHeader className="no-pb">
              <ModalCardTitle className="has-text-centered no-pl mb-10">
                {Header}
              </ModalCardTitle>
            </ModalCardHeader>
            <ModalCardBody>
              {!!p[accessor] &&
                p[accessor].map((n, index) => (
                  <Columns>
                    <Column>
                      <Field key={index} className="mb-10">
                        <Input
                          name={n}
                          placeholder=""
                          value={n}
                          onChange={e => {
                            this.changeListElement(
                              e.target.value,
                              index,
                              accessor
                            );
                          }}
                        />
                      </Field>
                    </Column>
                    <Column>
                      <Field className="mb-10">
                        <Button
                          onClick={() => this.deleteListElement(n, accessor)}
                        >
                          <FontAwesomeIcon
                            className="icn"
                            icon={["fa", "trash"]}
                          />
                        </Button>
                      </Field>
                    </Column>
                  </Columns>
                ))}
              <Button onClick={() => this.addElementToList(accessor)}>
                Nuovo
              </Button>
            </ModalCardBody>
            <Button
              className="is-primary is-pulled-right"
              onClick={() => {
                this.setState({ modalActive: false });
              }}
            >
              Ok
            </Button>
          </ModalCard>
        </Modal>
      </div>
    );
  }

  setCheckbox(accessor, value, checked) {
    const p = this.state.product;

    let list = p[accessor] || [];
    if (checked) list = list.filter(v => v != value);
    else list.push(value);

    p[accessor] = list;

    this.setState({
      p
    });
  }

  getCheckbox(accessor, value) {
    const p = this.state.product;

    const list = p[accessor] || [];
    let found = false;
    list.forEach(v => {
      if (v === value) found = true;
    });

    return found;
  }

  renderMultipleCheckbox(f) {
    const { Header, accessor, subaccessor, values } = f;

    const { modify } = this.state;

    return (
      <div>
        <Label>{Header}</Label>
        <div className={modify ? "box" : "box blocked"}>
          {!!values &&
            values.map((e, idx) => {
              const value = !!subaccessor ? e[subaccessor] : e;
              const checked = this.getCheckbox(accessor, value);

              return (
                <Checkbox
                  key={idx}
                  style={{ marginRight: "5px" }}
                  onChange={() => this.setCheckbox(accessor, value, checked)}
                  isChecked={checked}
                  colorScheme="green"
                >
                  <Label
                    style={{ display: "inline-flex", marginRight: "45px" }}
                    className="ta-l"
                  >
                    {value}
                  </Label>
                </Checkbox>
              );
            })}
        </div>
      </div>
    );
  }
  renderMultipleValues(field) {
    const { Header, accessor, subaccessor, values } = field;

    const { modify } = this.state;
    const p = this.state.product;

    return (
      <div>
        <Label>{Header}</Label>

        <Dropdown isActive={this.state.multipleValues[accessor]}>
          <DropdownTrigger>
            <Button
              className={
                modify ? "dropdown-warehouse modify" : "dropdown-warehouse"
              }
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                if (modify) {
                  const multipleValues = [];
                  multipleValues[accessor] = !this.state.multipleValues[
                    accessor
                  ];
                  this.setState({
                    multipleValues
                  });
                }
              }}
            >
              {!!p && !!p[accessor] && subaccessor
                ? p[accessor][subaccessor]
                : p[accessor]}
              <span className="dropdown-icn">
                <FontAwesomeIcon
                  className="icn"
                  icon={["fa", "chevron-down"]}
                />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {!!values &&
                values.map((e, idx) => {
                  const value = !!subaccessor ? e[subaccessor] : e;

                  return (
                    <DropdownItem
                      key={idx}
                      className="ta-l"
                      onClick={() => this.setMultipleValue(e, accessor)}
                    >
                      {value}
                    </DropdownItem>
                  );
                })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  findAddressComponent(components, type, version) {
    const address = components.find(function(component) {
      return component.types.indexOf(type) !== -1;
    });
    if (!address) return "";
    return address[version];
  }

  onSuggestSelect(suggest, accessor) {
    if (!suggest) return;
    console.log(suggest.gmaps.address_components);

    const components = suggest.gmaps.address_components;
    const { product } = this.state;

    product[accessor] = {
      ...product[accessor],
      via: this.findAddressComponent(components, "route", "short_name"),
      citta: this.findAddressComponent(components, "locality", "short_name"),
      regione: this.findAddressComponent(
        components,
        "administrative_area_level_1",
        "short_name"
      ),
      nazione: this.findAddressComponent(components, "country", "long_name"),
      cap: this.findAddressComponent(components, "postal_code", "short_name"),
      numero: this.findAddressComponent(
        components,
        "street_number",
        "short_name"
      )
    };

    this.setState({
      product
    });
  }

  renderGeosuggest(field, parentAccessor) {
    const { Header, accessor, type, blocked } = field;

    const p = this.state.product;
    const warning = type === "number" && p[accessor] < 0;
    const modify = this.state.modify && !blocked;
    return (
      <div>
        <Label>{Header}</Label>
        <Geosuggest
          className={modify ? "input" : "input blocked"}
          // name={accessor}
          // value={p[accessor]}
          ref={el => (this._geoSuggest = el)}
          placeholder="Cerca via..."
          initialValue={
            !!this.state.product[parentAccessor] &&
            this.state.product[parentAccessor].via
          }
          // fixtures={fixtures}
          onSuggestSelect={s => this.onSuggestSelect(s, parentAccessor)}
          location={new google.maps.LatLng(53.558572, 9.9278215)}
          radius="20"
          style={{ width: "100%" }}
          onChange={e => {
            console.log("AA onchange", e, accessor);
            p[parentAccessor].via = e;
            this.setState({ product: p });
          }}
        />
      </div>
    );
  }

  renderAddress(field) {
    return (
      <div>
        <h3 className="title has-text-black mt-20">{field.Header} </h3>
        {field.values.map((f, idx) => {
          return <div key={idx}> {this.renderField(f, field.accessor)} </div>;
        })}
      </div>
    );
  }

  saveMultiAddress(field) {
    console.log("AA field", field);
    const p = this.state.product;

    const multi = p[field.accessor] || [];
    multi.push(p["tempAddress"]);

    p[field.accessor] = multi;
    delete p["tempAddress"];
    console.log("AA product", p);
  }

  renderAddressMulti(field) {
    const p = this.state.product;
    const shippingSingle = p["shippingAddress"];

    const currentAddress = !!p[field.accessor]
      ? this.state.selectedMultiAdrress === "single"
        ? shippingSingle
        : this.state.selectedMultiAdrress
        ? p[field.accessor][this.state.selectedMultiAdrress]
        : p[field.accessor][0]
      : shippingSingle;
    return (
      <div>
        <Flex justify={"space-between"} align="center">
          <h3 className="title has-text-black mt-20">{field.Header} </h3>
          <Flex justify="end" gap={2}>
            {!!p[field.accessor] && (
              <Select
                bg="#61737b"
                color={"white"}
                w="250px"
                onChange={v => {
                  console.log("ON CHANGE", v.target.value);
                  this.setState({ selectedMultiAdrress: v.target.value });
                }}
              >
                {p[field.accessor]?.map((f, idx) => {
                  return <option value={idx}>{f.nome + " - " + f.via}</option>;
                })}
                {!!shippingSingle && (
                  <option value={"single"}>
                    {shippingSingle.nome + " - " + shippingSingle.via}
                  </option>
                )}
              </Select>
            )}
            {this.state.modify && (
              <Button onClick={() => this.setState({ openAddressModal: true })}>
                +
              </Button>
            )}
          </Flex>
        </Flex>

        <Box>
          <div>
            <Label>Nome</Label>
            <Field>
              <Input
                className={"blocked"}
                type={"text"}
                value={currentAddress?.nome}
                step="0.01"
              />
            </Field>
          </div>
          <div>
            <Label>Via</Label>
            <Field>
              <Input
                className={"blocked"}
                type={"text"}
                value={currentAddress?.via}
                step="0.01"
              />
            </Field>
          </div>
          <div>
            {" "}
            <Label>Numero</Label>
            <Field>
              <Input
                className={"blocked"}
                type={"text"}
                value={currentAddress?.numero}
                step="0.01"
              />
            </Field>{" "}
          </div>
          <div>
            <Label>Città</Label>
            <Field>
              <Input
                className={"blocked"}
                type={"text"}
                value={currentAddress?.citta}
                step="0.01"
              />
            </Field>{" "}
          </div>
          <div>
            <Label>Regione</Label>
            <Field>
              <Input
                className={"blocked"}
                type={"text"}
                value={currentAddress?.regione}
                step="0.01"
              />
            </Field>{" "}
          </div>
          <div>
            <Label>Nazione</Label>
            <Field>
              <Input
                className={"blocked"}
                type={"text"}
                value={currentAddress?.nazione}
                step="0.01"
              />
            </Field>{" "}
          </div>
          <div>
            <Label>Cap</Label>
            <Field>
              <Input
                className={"blocked"}
                type={"text"}
                value={currentAddress?.cap}
                step="0.01"
              />
            </Field>{" "}
          </div>
        </Box>

        <Modal
          isOpen={this.state.openAddressModal}
          onClose={() => this.setState({ openAddressModal: false })}
          size={"xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalBody>
              {field.values.map((f, idx) => {
                return (
                  <div key={idx}> {this.renderField(f, "tempAddress")} </div>
                );
              })}
            </ModalBody>
            <ModalFooter>
              <Flex gap={2}>
                <Button
                  onClick={() => this.setState({ openAddressModal: false })}
                >
                  CHIUDI
                </Button>
                <Button
                  onClick={() => {
                    this.saveMultiAddress(field);
                    this.setState({ openAddressModal: false });
                  }}
                  colorScheme="green"
                >
                  SALVA
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    );
  }

  setMultipleValue(v, field) {
    const p = this.state.product;

    p[field] = v;
    const multipleValues = [];
    multipleValues[field] = false;

    this.setState({ p, multipleValues });
  }

  setSearchedValue(v, field) {
    const p = this.state.product;

    p[field] = v;
    const showFilterList = [];
    showFilterList[field] = false;

    this.setState({ p, showFilterList });
  }

  renderFields() {
    const p = this.state.product;
    const { fields } = this.props;

    return (
      <form name="product" onChange={() => this.resetForm}>
        {fields.map(f => {
          // const style = !!f.style ? f.style : "";
          // console.log("AA f", style);
          return (
            <div style={f.style} key={f.Header}>
              {this.renderField(f, f.accessor)}
            </div>
          );
        })}
      </form>
    );
  }

  renderUsedOn() {
    const { usedOn } = this.props;

    return (
      <div className="mtb-80">
        <h3 className="title has-text-black ">Usato in</h3>
        <ul style={{ listStyle: "disc" }}>
          {!!usedOn &&
            usedOn.map(s => {
              return (
                <li>
                  <Link
                    className="link-product"
                    style={{ marginTop: "-6px" }}
                    to={s.link}
                  >
                    {s.name}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }

  renderComposition() {
    const { modify, product, filteredProducts } = this.state;

    return (
      <div className="mtb-80">
        <h3 className="title has-text-black ">
          Composizione 1 prodotto finito
        </h3>
        <Columns>
          <Column isSize="1/4">
            <Label>Milligrammi dose giornaliera</Label>
          </Column>
          <Column isSize="1/4">
            <Label>Quantità per prodotto</Label>
          </Column>
          <Column>
            <Label>Materia prima</Label>
          </Column>
        </Columns>
        {!!product.composition &&
          product.composition.map(s => {
            return (
              <div>
                <Columns>
                  <Column isSize="1/4">
                    <Field className="mb-10">
                      <Input
                        className={modify ? "" : "blocked"}
                        name="quantityGiornaliera"
                        type="number"
                        placeholder=""
                        value={s.quantityGiornaliera}
                        onChange={e => {
                          this.handleQuantityGiornalieraChange(
                            s,
                            parseFloat(e.target.value)
                          );
                        }}
                      />
                    </Field>
                  </Column>
                  <Column isSize="1/4">
                    <Field className="mb-10">
                      <Input
                        className={"blocked"}
                        name="quantity"
                        type="number"
                        placeholder=""
                        value={s.quantity}
                        onChange={e => {
                          this.handleQuantityChange(
                            s,
                            parseFloat(e.target.value)
                          );
                        }}
                      />
                    </Field>
                  </Column>
                  <Column>
                    <Field className="mb-10">
                      {modify ? (
                        <Input
                          className={"blocked"}
                          type="text"
                          value={s.name}
                        />
                      ) : (
                        <Link
                          className="link-product greyed"
                          to={"/elementDetails/" + s.uid}
                        >
                          {s.name}
                        </Link>
                      )}
                    </Field>
                  </Column>
                  {modify && (
                    <Column>
                      <Field className="mb-10">
                        <Button onClick={() => this.onDelete(s)}>
                          <FontAwesomeIcon
                            className="icn"
                            icon={["fa", "trash"]}
                          />
                        </Button>
                      </Field>
                    </Column>
                  )}
                </Columns>
              </div>
            );
          })}

        {modify && (
          <div>
            <Columns>
              <Column>
                <Dropdown isActive={this.state.statusDropdownActive && modify}>
                  <DropdownTrigger>
                    <Field className="mb-10">
                      <Input
                        name="product"
                        autoComplete="off"
                        type="text"
                        placeholder="cerca prodotto"
                        value={this.state.searchCode}
                        onChange={e => {
                          this.handleSearchCode(e.target.value);
                        }}
                      />
                    </Field>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownContent className="elements-dropdown">
                      {filteredProducts.map(e => {
                        return (
                          <DropdownItem
                            className="ta-l"
                            onClick={() => this.onSelect(e)}
                            key={e.uid}
                          >
                            {e.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown>
              </Column>
            </Columns>
          </div>
        )}
      </div>
    );
  }

  handleQuantityGiornalieraChange(s, val) {
    let { product } = this.state;
    product.composition.forEach(sub => {
      if (sub.uid === s.uid) {
        sub.quantityGiornaliera = val;
        sub.quantity =
          !!product.formato && !!product.doseGiornaliera
            ? ((product.formato / product.doseGiornaliera) * val) / 1000
            : val;
        if (sub.unitmeasure === "pezzi") sub.quantity = val;
      }
    });
    this.setState({ product });
  }

  handleQuantityChange(s, val) {
    let { product } = this.state;
    product.composition.forEach(sub => {
      if (sub.uid === s.uid) {
        sub.quantity = val;
      }
    });
    this.setState({ product });
  }

  handleSearchCode(text) {
    const filteredProducts = this.props.admin.elements.filter(
      p =>
        String(p.name.toLowerCase()).includes(text.toLowerCase()) ||
        (!!p.barcode &&
          String(p.barcode.toLowerCase()).includes(text.toLowerCase())) ||
        (!!p.code && String(p.code.toLowerCase()).includes(text.toLowerCase()))
    );

    this.setState({
      searchCode: text,
      statusDropdownActive: true,
      filteredProducts
    });
  }

  onSelect(e) {
    let { product } = this.state;
    const el = {
      name: e.name,
      uid: e.uid,
      quantity: 0,
      unitmeasure: e.unitmeasure
    };
    const composition = !!product.composition ? product.composition : [];
    composition.push(el);
    product.composition = composition;
    this.setState({ product, statusDropdownActive: false, searchCode: "" });
  }

  async onDelete(s) {
    let { product } = this.state;
    product.composition = product.composition.filter(sub => sub.uid != s.uid);
    this.setState({ product });
  }

  handleLottoQuantityChange(l, val, i) {
    const { product } = this.state;
    const lotto = product.lotti[i];
    lotto.quantity = val;
    this.setState({ product });
  }

  handleLottoNameChange(l, val, i) {
    const { product } = this.state;
    const lotto = product.lotti[i];
    lotto.name = val;
    this.setState({ product });
  }

  addLotto() {
    const { product } = this.state;
    if (!product.lotti) product.lotti = [];
    product.lotti.push({ name: "nuovo", quantity: 0 });
    this.setState({ product });
  }

  deleteLotto(idx) {
    const { product } = this.state;
    product.lotti.splice(idx, 1);
    this.setState({ product });
  }

  renderLotti() {
    const { product } = this.state;

    return (
      <div className="mtb-80">
        <h3 className="title has-text-black ">Lotti</h3>
        <Columns>
          <Column isSize="1/4">
            <Label>Quantità</Label>
          </Column>
          <Column>
            <Label>Nome</Label>
          </Column>
        </Columns>

        {!!product.lotti &&
          product.lotti.map((l, idx) => {
            return (
              <div>
                <Columns>
                  <Column isSize="1/4">
                    <Field className="mb-10">
                      <Input
                        className={!this.state.modify && "blocked"}
                        name="quantity"
                        type="number"
                        placeholder=""
                        value={l.quantity}
                        onChange={e => {
                          this.handleLottoQuantityChange(
                            l,
                            parseFloat(e.target.value),
                            idx
                          );
                        }}
                      />
                    </Field>
                  </Column>
                  <Column>
                    <Field className="mb-10">
                      <Input
                        className={!this.state.modify && "blocked"}
                        type="text"
                        value={l.name}
                        onChange={e => {
                          this.handleLottoNameChange(l, e.target.value, idx);
                        }}
                      />
                    </Field>
                  </Column>

                  {this.state.modify && (
                    <Column>
                      <Field className="mb-10">
                        <Button onClick={() => this.deleteLotto(idx)}>
                          <FontAwesomeIcon
                            className="icn"
                            icon={["fa", "trash"]}
                          />
                        </Button>
                      </Field>
                    </Column>
                  )}
                </Columns>
              </div>
            );
          })}

        {this.state.modify && (
          <Button
            className=" is-primary  is-fullwidth mt-10 mb-40"
            onClick={() => this.addLotto()}
          >
            Aggiungi Lotto
          </Button>
        )}
      </div>
    );
  }

  handleCodiceFiglioSKUChange(l, val, i) {
    const { product } = this.state;
    const figlio = product.codiciFigli[i];
    figlio.sku = val;
    this.setState({ product });
  }

  handleCodiceFiglioQuantityChange(l, val, i) {
    const { product } = this.state;
    const figlio = product.codiciFigli[i];
    figlio.quantity = val;
    this.setState({ product });
  }

  handleCodiceFiglioNameChange(l, val, i) {
    const { product } = this.state;
    const figlio = product.codiciFigli[i];
    figlio.name = val;
    this.setState({ product });
  }

  addCodiceFiglio() {
    const { product } = this.state;
    if (!product.codiciFigli) product.codiciFigli = [];
    product.codiciFigli.push({ name: "nuovo", quantity: 0, sku: "" });
    this.setState({ product });
  }

  deleteCodiceFiglio(idx) {
    const { product } = this.state;
    product.codiciFigli.splice(idx, 1);
    this.setState({ product });
  }

  renderCodiciFigli() {
    const { product } = this.state;

    return (
      <div className="mtb-80">
        <h3 className="title has-text-black ">Codici figli</h3>
        <Columns>
          <Column isSize="1/4">
            <Label>SKU</Label>
          </Column>
          <Column>
            <Label>Descrizione</Label>
          </Column>
          <Column>
            <Label>Quantità</Label>
          </Column>
        </Columns>

        {!!product.codiciFigli &&
          product.codiciFigli.map((l, idx) => {
            return (
              <div>
                <Columns>
                  <Column isSize="1/4">
                    <Field className="mb-10">
                      <Input
                        className={!this.state.modify && "blocked"}
                        name="sku"
                        type="text"
                        placeholder=""
                        value={l.sku}
                        onChange={e => {
                          this.handleCodiceFiglioSKUChange(
                            l,
                            e.target.value,
                            idx
                          );
                        }}
                      />
                    </Field>
                  </Column>
                  <Column>
                    <Field className="mb-10">
                      <Input
                        className={!this.state.modify && "blocked"}
                        type="text"
                        value={l.name}
                        onChange={e => {
                          this.handleCodiceFiglioNameChange(
                            l,
                            e.target.value,
                            idx
                          );
                        }}
                      />
                    </Field>
                  </Column>
                  <Column>
                    <Field className="mb-10">
                      <Input
                        className={!this.state.modify && "blocked"}
                        name="quantity"
                        type="number"
                        placeholder=""
                        value={l.quantity}
                        onChange={e => {
                          this.handleCodiceFiglioQuantityChange(
                            l,
                            parseFloat(e.target.value),
                            idx
                          );
                        }}
                      />
                    </Field>
                  </Column>
                  {this.state.modify && (
                    <Column>
                      <Field className="mb-10">
                        <Button onClick={() => this.deleteCodiceFiglio(idx)}>
                          <FontAwesomeIcon
                            className="icn"
                            icon={["fa", "trash"]}
                          />
                        </Button>
                      </Field>
                    </Column>
                  )}
                </Columns>
              </div>
            );
          })}

        {this.state.modify && (
          <Button
            className=" is-primary  is-fullwidth mt-10 mb-40"
            onClick={() => this.addCodiceFiglio()}
          >
            Aggiungi Codice
          </Button>
        )}
      </div>
    );
  }

  renderMateriale() {
    const columns = [
      {
        Header: "Numero",
        accessor: "codeNumber",
        style: { minWidth: "100px" }
      },
      {
        Header: "Data",
        accessor: "dateFormatted",
        style: { minWidth: "150px" }
      },
      {
        Header: "Pezzi",
        accessor: "numeroPezzi",
        style: { minWidth: "100px" }
      },
      {
        Header: "Prodotti",
        accessor: "prodottiConcatenati"
      },
      {
        Header: "Tot Costo Acquisto",
        accessor: "totalPriceBuy",
        style: { minWidth: "100px" },
        type: "currency"
      },
      {
        Header: "Tot Prezzo Vendita",
        accessor: "totalPriceSell",
        style: { minWidth: "100px" },
        type: "currency"
      }
    ];

    let docs = this.state.contactDocs || [];
    docs = docs
      .filter(d => d.type === DocumentType.DISTINTA)
      .filter(d => d.causale === "Omaggio");

    let totPezzi = 0;
    let totAcquisto = 0;
    let totVendita = 0;

    docs.forEach(d => {
      let totalPriceSell = 0;
      let totalPriceBuy = 0;
      d.products.forEach(p => {
        const price_sell = p.price_sell || 0;
        totalPriceSell += p.quantity * price_sell;
        const price_buy = p.price_buy || 0;
        totalPriceBuy += p.quantity * price_buy;

        console.log(
          "AA total price buy",
          totalPriceBuy,
          price_buy,
          p.price_buy,
          p.quantity
        );
      });

      d.totalPriceSell = totalPriceSell;
      d.totalPriceBuy = totalPriceBuy;

      totPezzi += d.numeroPezzi;
      totAcquisto += d.totalPriceBuy;
      totVendita += d.totalPriceSell;
    });
    return (
      <div className="admin-panels">
        <h1>Totale pezzi: {totPezzi}</h1>
        <h1>Totale prezzo acquisto: {Utils.stringToCurrency(totAcquisto)}</h1>
        <h1>Totale prezzo vendita: {Utils.stringToCurrency(totVendita)}</h1>
        {
          <MyTable
            data={docs}
            columns={columns}
            onModify={u => this.onModify(u)}
            hideDelete
            {...this.props}
          />
        }
      </div>
    );
  }

  onModify(p) {
    this.props.history.push("/documentDetails/" + p.uid + "/" + p.type);
  }

  isValid() {
    const p = this.state.product;
    const { fields } = this.props;
    const reqFields = fields.filter(f => f.required);

    let valid = true;
    reqFields.forEach(r => {
      if (valid && (!p[r.accessor] || p[r.accessor]?.length === 0))
        valid = false;
    });
    console.log("AA valid", valid, reqFields);
    return valid;
  }

  render() {
    const p = this.state.product;
    const canModify = this.props.canBeModified;
    const { contacts } = this.props;

    if (!p) return <Loader />;

    return (
      <div>
        <Container className="generic-doc">
          <Columns>
            <Column>
              <h1 className="title has-text-black">{p.name}</h1>
            </Column>
            <Column isSize="narrow">
              {this.state.modify ? (
                <Button
                  className=" is-primary float-right is-fullwidth mt-10 mb-40"
                  onClick={() => this.submit()}
                  disabled={!this.isValid()}
                >
                  Salva
                </Button>
              ) : canModify ? (
                <Button
                  className=" is-primary float-right is-fullwidth mt-10 mb-40"
                  onClick={() => this.doModify()}
                >
                  MODIFICA
                </Button>
              ) : null}
            </Column>
          </Columns>

          {contacts && (
            <Tabs className="mt-30">
              <TabList>
                <Tab
                  className={this.state.tab === "anagrafica" ? "is-active" : ""}
                >
                  <TabLink onClick={() => this.setState({ tab: "anagrafica" })}>
                    <span>ANAGRAFICA</span>
                  </TabLink>
                </Tab>
                <Tab
                  className={this.state.tab === "documenti" ? "is-active" : ""}
                >
                  <TabLink onClick={() => this.setState({ tab: "documenti" })}>
                    <span>DOCUMENTI</span>
                  </TabLink>
                </Tab>

                {/* <Tab className={this.state.tab === "contatti" ? "is-active" : ""}>
                  <TabLink onClick={() => this.setState({ tab: "contatti" })}>
                    <span>COMUNICAZIONI</span>
                  </TabLink>
                </Tab>
                {Utils.getTipoCliente(this.state.product, "Affiliato") && (
                  <Tab className={this.state.tab === "coupons" ? "is-active" : ""}>
                    <TabLink onClick={() => this.setState({ tab: "coupons" })}>
                      <span>COUPONS</span>
                    </TabLink>
                  </Tab>
                )}
                {Utils.getTipoCliente(this.state.product, "Affiliato") && (
                  <Tab className={this.state.tab === "commissioni" ? "is-active" : ""}>
                    <TabLink onClick={() => this.setState({ tab: "commissioni" })}>
                      <span>COMMISSIONI</span>
                    </TabLink>
                  </Tab>
                )}
                {Utils.getTipoCliente(this.state.product, "Affiliato") && (
                  <Tab className={this.state.tab === "materiale" ? "is-active" : ""}>
                    <TabLink onClick={() => this.setState({ tab: "materiale" })}>
                      <span>MATERIALE</span>
                    </TabLink>
                  </Tab>
                )}
                {Utils.getTipoCliente(this.state.product, "Affiliato") && (
                  <Tab className={this.state.tab === "report" ? "is-active" : ""}>
                    <TabLink onClick={() => this.setState({ tab: "report" })}>
                      <span>REPORT</span>
                    </TabLink>
                  </Tab>
                )} */}
              </TabList>
            </Tabs>
          )}
          {contacts && this.state.tab === "anagrafica" && this.renderFields()}
          {contacts && this.state.tab === "documenti" && (
            <DContactDocuments
              contact={this.state.product}
              contactDocs={this.state.contactDocs}
              {...this.props}
            />
          )}
          {contacts && this.state.tab === "contatti" && (
            <div>
              <DContactLog contact={this.state.product} {...this.props} />
              <DContactPipeline contact={this.state.product} {...this.props} />
            </div>
          )}
          {contacts && this.state.tab === "coupons" && (
            <DContactCoupons contact={this.state.product} {...this.props} />
          )}
          {contacts && this.state.tab === "commissioni" && (
            <DContactCommissioni contact={this.state.product} {...this.props} />
          )}
          {contacts && this.state.tab === "materiale" && this.renderMateriale()}
          {contacts && this.state.tab === "report" && (
            <DContactReport
              contact={this.state.product}
              docs={this.state.contactDocs}
              reports={this.state.contactReports}
              {...this.props}
            />
          )}

          {!contacts && this.renderFields()}

          {this.props.codiciFigli && this.renderCodiciFigli()}

          {/* {this.props.subcollection && this.renderComposition()} */}

          {this.props.lotti && this.renderLotti()}

          {this.props.usedOn && this.renderUsedOn()}

          {this.state.modify ? (
            <Button
              className=" is-primary is-fullwidth mt-10 mb-40"
              onClick={() => this.submit()}
              disabled={!this.isValid()}
            >
              Salva
            </Button>
          ) : canModify && !this.state.tab === "contatti" ? (
            <Button
              className=" is-primary is-fullwidth mt-10 mb-40"
              onClick={() => this.doModify()}
            >
              MODIFICA
            </Button>
          ) : null}
        </Container>
        {this.renderSubmitModal()}
      </div>
    );
  }
}
export default withRouter(DGenericProduct);
