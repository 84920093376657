import italia from "./italia";

const province = [];
italia.regioni.forEach(r => {
    r.province.forEach(p => {
        province.push({
            code: p.code,
            name: p.nome
        });
    });
});

export default province;