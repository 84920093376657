import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./AppRouter";
import registerServiceWorker, { unregister } from "./registerServiceWorker";
import "./firebase";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter } from "react-router-dom";
import "bulma-extensions/dist/js/bulma-extensions.min";

import { WareProvider } from "./contexts/WarehouseContext";
import { AdminProvider } from "./contexts/AdminContext";
import { ModalsProvider } from "./contexts/ModalsContext";
import { GiacenzeProvider } from "./contexts/GiacenzeContext";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

library.add(fab, fas, far);
const theme = extendTheme({
  colors: {
    green: {
      100: "#61737b",
      200: "#61737b",
      300: "#61737b",
      400: "#61737b",
      500: "#61737b",
      600: "#61737b",
      700: "#61737b",
      800: "#61737b",
      900: "#61737b"
    }
  }
});
// fixme on resize check screen and show desktop, mobile or tablet version
ReactDOM.render(
  <BrowserRouter>
    <WareProvider>
      <AdminProvider>
        <ModalsProvider>
          <GiacenzeProvider>
            <ChakraProvider theme={theme}>
              <AppRouter />
            </ChakraProvider>
          </GiacenzeProvider>
        </ModalsProvider>
      </AdminProvider>
    </WareProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// const unreg = Utils.getParameterByName("clearcache");

// if (!!unreg && unreg !== "refresh") {
//   unregister();
//   window.location.href = getPathFromUrl(window.location.href) + "/?clearcache=refresh";
// } else if (!!unreg && unreg === "refresh") {
//   window.location.href = getPathFromUrl(window.location.href);
// } else {
//   loadSw();
// }

// async function loadSw() {
//   try {
//     const snap = await window.firebase
//       .firestore()
//       .collection("updates")
//       .doc("webapp")
//       .get();
//     const data = snap.data();

//     const date = parseInt(localStorage.getItem("serviceWorkerDate") || new Date().getTime(), 10);

//     if (parseInt(data.date, 10) > date) {
//       localStorage.setItem("serviceWorkerDate", "" + (parseInt(data.date, 10) + 5));
//       window.location.href = getPathFromUrl(window.location.href) + "/?clearcache=true";
//     } else {
//       localStorage.setItem("serviceWorkerDate", "" + date);
//       registerServiceWorker();
//     }
//   } catch (e) {
//     registerServiceWorker();
//   }
// }

// function getPathFromUrl(url) {
//   let nUrl = url.split("?")[0];
//   if (nUrl) {
//     while (nUrl.endsWith("/")) {
//       nUrl = nUrl.substring(0, nUrl.length - 1);
//     }
//   }

//   return nUrl;
// }
