import React, { Component } from "react";
import { withRouter } from "react-router";
import { withModalsCtx } from "../contexts/ModalsContext";
import { withWareCtx } from "../contexts/WarehouseContext";
import { withAdminCtx } from "../contexts/AdminContext";
import DocumentType from "../enums/DocumentType";
import DGenericDoc from "./DGenericDoc";
import firebase from "firebase/compat/app";

import StorageService from "../services/StorageService";

class DProduzioneEntrataDoc extends Component {
  state = {
    fornitori: [],
    clienti: []
  };

  async uploadPDF(doc, file) {
    console.log("AA file", file, doc);
    if (!!file) {
      try {
        await StorageService.postPDF(file, doc.pdfFilename, doc.uid, "pdf");
        alert("PDF caricato correttamente");
      } catch (e) {
        console.error("Error while uploading the pdf: ", e);
        alert(
          "Si è verificato un errore durante il caricamento del pdf: ${e.message}"
        );
      }
    }
  }

  async onSave(doc, file) {
    doc.elements = doc.products;
    delete doc.products;
    console.log("AA doc", doc);

    if (this.props.adding) {
      let docUid = null;
      const docRef = await firebase
        .firestore()
        .collection("documents")
        .add(doc)
        .then(docRef => (doc.uid = docRef.id));

      this.props.admin.changeProduzioneQuantity(doc, false);

      if (!!doc.pdfFilename) {
        this.uploadPDF(doc, file);
      }
    } else {
      const docRef = await firebase
        .firestore()
        .collection("documents")
        .doc(doc.uid)
        .update(doc);
    }

    this.props.history.push("/produzione");
  }

  componentDidMount() {
    console.log("AA add", this.props.adding);
  }

  render() {
    const fields = [
      {
        Header: "Fornitore",
        accessor: "contact",
        subaccessor: "name",
        type: "search",
        values: this.props.admin.fornitori
      },
      {
        Header: "Numero documento fornitore",
        accessor: "docNumberFornitore",
        type: "text"
      },
      {
        Header: "Data Spedizione",
        accessor: "dataSpedizione",
        type: "date"
      },
      {
        Header: "Numero Colli",
        accessor: "colli",
        type: "number"
      },
      {
        Header: "Peso Lordo",
        accessor: "pesoLordo",
        type: "number"
      },
      {
        Header: "Volume",
        accessor: "volume",
        type: "number"
      },
      {
        Header: "PDF Upload",
        accessor: "pdf",
        type: "pdf"
      },
      {
        Header: "Creato da",
        accessor: "creatorName",
        blocked: true
      }
    ];

    return (
      <DGenericDoc
        table="documents"
        elements="elements"
        uid={this.props.match.params.pUid}
        fields={fields}
        onSave={(d, file) => this.onSave(d, file)}
        adding={this.props.adding}
        {...this.props}
      />
    );
  }
}

export default withAdminCtx(
  withModalsCtx(withRouter(withWareCtx(DProduzioneEntrataDoc)))
);
