import React from "react";
import { withAdminCtx } from "../../contexts/AdminContext";
import { Container, Columns, Column } from "bloomer";
import firebase from "firebase/compat/app";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../../Utils";
import { Hero } from "bloomer/lib/layout/Hero/Hero";
import { HeroBody } from "bloomer/lib/layout/Hero/HeroBody";
import { withRouter } from "react-router";
import MyTable from "../../components/admin/MyTable";
import Loader from "../../components/Loader";
import { withWareCtx } from "../../contexts/WarehouseContext";
import "moment/locale/it";
import { withGiacenzeCtx } from "../../contexts/GiacenzeContext";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";

var moment = require("moment");

class DDashboard extends React.Component {
  state = {
    user: null,
    loading: false,
    products: []
  };

  componentDidMount() {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async user => {
        this.setState(user);
        this.forceUpdate();
      });

    this.setState({ unregisterAuthObserver });
  }

  componentWillUnmount() {
    if (this.state.unregisterAuthObserver) this.state.unregisterAuthObserver();
  }
  async doGoogleLogin(e) {
    if (e) e.preventDefault();

    try {
      // const fbProv = new firebase.auth.FacebookAuthProvider();
      const fbProv = new firebase.auth.GoogleAuthProvider();
      console.log("AA fbProv", fbProv);

      fbProv.setCustomParameters({
        display: "popup"
      });

      const auth = firebase.auth();

      console.log("AA firebase.auth", auth);

      await auth.signInWithPopup(fbProv);

      // if (this.onEnd) this.onEnd();
    } catch (error) {
      Utils.handleError(error);
      console.log("AA ERror", error);
      if (error.code === "auth/account-exists-with-different-credential") {
        const providers = await firebase
          .auth()
          .fetchSignInMethodsForEmail(error.email);
        alert(`C'è stato un problema con il login di Facebook, prova ad usare un'altro sistema di autenticazione con la tua email (${
          error.email
        }).
                    \nSistemi disponibili: ${providers.join(", ")}`);
        this.setState({ errorCredentials: error });
      } else {
        console.log("AA error login", error.code, error.message);
        alert(
          `C'è stato un problema con il login di Facebook, errore con codice ${error.code}. ${error.message}`
        );
      }
    }
  }

  async doFbLogin(e) {
    if (e) e.preventDefault();

    try {
      const fbProv = new firebase.auth.FacebookAuthProvider();
      fbProv.setCustomParameters({
        display: "popup"
      });

      const auth = firebase.auth();
      await auth.signInWithPopup(fbProv);

      // if (this.onEnd) this.onEnd();
    } catch (error) {
      Utils.handleError(error);
      console.log("AA ERror", error);
      if (error.code === "auth/account-exists-with-different-credential") {
        const providers = await firebase
          .auth()
          .fetchSignInMethodsForEmail(error.email);
        alert(`C'è stato un problema con il login di Facebook, prova ad usare un'altro sistema di autenticazione con la tua email (${
          error.email
        }).
                    \nSistemi disponibili: ${providers.join(", ")}`);
        this.setState({ errorCredentials: error });
      } else {
        console.log("AA error login", error.code, error.message);
        alert(
          `C'è stato un problema con il login di Facebook, errore con codice ${error.code}. ${error.message}`
        );
      }
    }
  }

  render() {
    const user = firebase.auth().currentUser;

    if (!user) {
      return (
        <div>
          <Hero>
            <HeroBody>
              {/* <Container hasTextAlign="centered"> */}
              <Flex gap={4} alignContent="space-around">
                <Button onClick={this.doFbLogin} colorScheme="blue">
                  <FontAwesomeIcon icon={["fab", "facebook"]} />
                  <span
                    style={{ width: "100%" }}
                    className="ml-15 has-text-centered"
                  >
                    Entra con Facebook
                  </span>
                </Button>
                <Button onClick={this.doGoogleLogin} colorScheme="red">
                  <FontAwesomeIcon icon={["fab", "google"]} />
                  <span
                    style={{ width: "100%" }}
                    className="ml-15 has-text-centered"
                  >
                    Entra con Google
                  </span>
                </Button>
              </Flex>
              {/* </Container> */}
            </HeroBody>
          </Hero>
        </div>
      );
    }

    return (
      <section>
        <Container>
          <Box p={5} bg="#75759047" borderRadius="md">
            <Flex align={"center"} justifyContent="space-between">
              <Heading size="lg">Dashboard</Heading>
              <Flex justify={"end"} gap={5}>
                <Button
                  colorScheme="green"
                  onClick={() => this.props.history.push("/entrataAdd")}
                >
                  REGISTRA ENTRATA PRODOTTI
                </Button>
                <Button
                  colorScheme="green"
                  onClick={() => this.props.history.push("/uscitaAdd")}
                >
                  REGISTRA USCITA PRODOTTI
                </Button>

                <Button
                  colorScheme="green"
                  onClick={() => this.props.history.push("/preventivo")}
                >
                  CALCOLA PREVENTIVO
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Container>
      </section>
    );
  }
}

export default withWareCtx(withRouter(withAdminCtx(DDashboard)));
